import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const initialState = {
  savedReports: [],
  reportsLoaded: false,
  templates: null,
  templatesLoading: true,
  templatesLoaded: false,
  openedId: null,
  blastApprovalRequests: [],
  blastDisApprovalRequests: [],
  blastSettings: {},
  blastApprovals: [],
  blastSmsBody: "",
  blastApprover: false,
  selectedProperties: [],
  selectedProperty: [],
  monthRange: {
    startAt: moment().subtract(30, "day"),
    endAt: moment(),
    label: "Last 30 Days",
  },
  blastRange: null,
  selectedCampaigns: [],
  selectedCampaign: [],
  selectedScheduleArray: null,
  scheduleArray: null,
  selectedCampaignCreatedAt: null,
  marketingAdminSettings: [],
  agentsList: [],
  approversList: [],
  settingsLoaded: false,
};

export const blastSlice = createSlice({
  name: "blast",
  initialState,
  reducers: {
    setTemplates: (state, action) => {
      state.templates = action.payload;
      state.templatesLoaded = true;
    },
    setScheduleArray: (state, action) => {
      state.scheduleArray = action.payload;
    },
    setSelectedScheduleArray: (state, action) => {
      state.selectedScheduleArray = action.payload;
    },
    setTemplatesLoading: (state, action) => {
      state.templatesLoading = action.payload;
    },
    setSavedReports: (state, action) => {
      state.savedReports = action.payload;
      state.reportsLoaded = true;
    },
    initBlastLoad: (state) => {
      state.savedReports = [];
      state.reportsLoaded = false;
      state.templates = null;
      state.templatesLoading = true;
      state.templatesLoaded = false;
    },
    resetReportsLoaded: (state) => {
      state.reportsLoaded = false;
    },
    openItem: (state, action) => {
      state.openedId = action.payload;
    },
    setBlastsApprovalRequests: (state, action) => {
      state.blastApprovalRequests = action.payload;
    },
    setBlastsDisApprovalRequests: (state, action) => {
      state.blastDisApprovalRequests = action.payload;
    },
    setBlastSettings: (state, action) => {
      state.blastSettings = action.payload;
    },
    setBlastApprovals: (state, action) => {
      state.blastApprovals = action.payload;
    },
    setBlastSmsBody: (state, action) => {
      state.blastSmsBody = action.payload;
    },
    setBlastApprover: (state, action) => {
      state.blastApprover = action.payload;
    },
    setSelectedProperties: (state, action) => {
      state.selectedProperties = action.payload;
      if (action.payload.length > 0) {
        state.selectedProperty = [state.selectedProperties[0]];
      }
    },
    setSelectedProperty: (state, action) => {
      state.selectedProperty = action.payload;
      state.selectedProperties = action.payload;
    },
    setMonthRange: (state, action) => {
      state.monthRange = action.payload;
    },
    setBlastRange: (state, action) => {
      state.blastRange = action.payload;
    },
    setSelectedCampaigns: (state, action) => {
      state.selectedCampaigns = action.payload;
      if (action.payload.length > 0) {
        state.selectedCampaign = [state.selectedCampaigns[0]];
      }
    },
    setSelectedCampaign: (state, action) => {
      state.selectedCampaign = action.payload;
      state.selectedCampaigns = action.payload;
    },
    setSelectedCampaignCreatedAt: (state, action) => {
      state.selectedCampaignCreatedAt = action.payload;
    },
    setAgentsList: (state, action) => {
      state.agentsList = action.payload;
    },
    setApproversList: (state, action) => {
      state.approversList = action.payload;
    },
    setMarketingAdminSettings: (state, action) => {
      state.marketingAdminSettings = action.payload;
      state.settingsLoaded = true;
    },
  },
});

export const {
  setTemplates,
  setScheduleArray,
  setSelectedScheduleArray,
  setTemplatesLoading,
  setSavedReports,
  resetReportsLoaded,
  initBlastLoad,
  openItem,
  setBlastsApprovalRequests,
  setBlastsDisApprovalRequests,
  setBlastSettings,
  setBlastSmsBody,
  setBlastApprover,
  setBlastApprovals,
  setSelectedProperties,
  setSelectedProperty,
  setMonthRange,
  setBlastRange,
  setSelectedCampaigns,
  setSelectedCampaign,
  setSelectedCampaignCreatedAt,
  setMarketingAdminSettings,
  setAgentsList,
  setApproversList,
} = blastSlice.actions;

export const getSavedReports = (state) => state.blast.savedReports;
export const getOpenedId = (state) => state.blast.openedId;
export const selectTemplates = (state) => state.blast.templates;
export const selectTemplatesLoading = (state) => state.blast.templatesLoading;
export const selectTemplatesLoaded = (state) => state.blast.templatesLoaded;
export const getBlastsApprovalRequests = (state) => state.blast.blastApprovalRequests;
export const getBlastsDisApprovalRequests = (state) => state.blast.blastDisApprovalRequests;
export const getBlastsSettings = (state) => state.blast.blastSettings;
export const getBlastsApprovals = (state) => state.blast.blastApprovals;
export const getBlastSmsBody = (state) => state.blast.blastSmsBody;
export const getBlastApprover = (state) => state.blast.blastApprover;
export const selectSelectedProperties = (state) => state.blast.selectedProperties;
export const selectSelectedProperty = (state) => state.blast.selectedProperty;
export const selectMonthRange = (state) => state.blast.monthRange;
export const selectBlastRange = (state) => state.blast.blastRange;
export const selectSelectedCampaigns = (state) => state.blast.selectedCampaigns;
export const selectSelectedCampaign = (state) => state.blast.selectedCampaign;
export const getScheduleArray = (state) => state.blast.scheduleArray;
export const getSelectedScheduleArray = (state) => state.blast.selectedScheduleArray;
export const selectSelectedCampaignCreatedAt = (state) => state.blast.selectedCampaignCreatedAt;
export const selectMarketingAdminSettings = (state) => state.blast.marketingAdminSettings;
export const selectSettingsLoaded = (state) => state.blast.settingsLoaded;
export const selectAgentsList = (state) => state.blast.agentsList;
export const selectApproversList = (state) => state.blast.approversList;

export default blastSlice.reducer;
