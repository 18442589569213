import React from "react";
import { InputBase, MenuItem, Select } from "@mui/material";
import { withStyles } from "@mui/styles";
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
} from "@mui/icons-material";

const CustomInputBase = withStyles((theme) => ({
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "transparent",
    fontSize: 14,
    fontWeight: 600,
    paddingLeft: 12,
    "&:focus": {
      outline: "none",
    },
  },
}))(InputBase);

const CustomSelect = withStyles((theme) => ({
  select: {
    paddingRight: 0,
    "&:hover": {
      color: "#26bbed",
    },
  },
  icon: {
    color: "#333",
    "&:hover, &:focus": {
      color: "#26bbed",
    },
  },
}))(Select);

const getRowsCount = (total) => {
  let array = [];
  let roundedNum = Math.ceil(total / 10) * 10;
  array = [roundedNum - 20, roundedNum - 10, roundedNum];

  return array;
};

const paginateOptions = (options, totalCount) => {
  const paginatedOptions = [];
  // Edge case: if totalCount is 25 or less, return empty array
  if (totalCount <= 25) {
    return paginatedOptions;
  }
  for (let i = 0; i < options.length; i++) {
    if (options[i] <= totalCount) {
      paginatedOptions.push(options[i]);
      // Check if totalCount is exactly equal to an option
      if (totalCount === options[i]) {
        break;
      }
      // Check if totalCount is less than the next option
      if (options[i + 1] && totalCount < options[i + 1]) {
        paginatedOptions.push(options[i + 1]);
        break;
      }
    }
  }
  return paginatedOptions;
};

const TablePagination = ({ page, totalCount, rowsLimit, handleChange }) => {
  const options = paginateOptions([25, 50, 75, 100], totalCount);
  // const options = getRowsCount(totalCount);
  const pageCounts = Math.ceil(totalCount / rowsLimit);
  const onPageChange = (newPage, perPage) => {
    handleChange(newPage, perPage);
  };

  const handleSelectChange = (event) => {
    handleChange(1, event.target.value);
  };

  const generatePageNumbers = () => {
    const pageNumbers = [];
    const maxPagesToShow = 3;

    const startPage = Math.max(1, Math.min(page, pageCounts - maxPagesToShow + 1));
    const endPage = Math.min(pageCounts, startPage + maxPagesToShow - 1);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => onPageChange(i, rowsLimit)}
          className={`px-2 py-1 mx-1 bg-transparent hover:!text-hyly-blue hover:!bg-white border-none outline-none ${
            i === page ? "font-semibold !bg-white !border !border-border-2" : ""
          }`}
        >
          {i}
        </button>
      );
    }

    return pageNumbers;
  };

  const iconClasses = "cursor-pointer hover:text-hyly-blue hover:bg-white";

  if (!options[0]) {
    return null;
  }

  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center text-text-3 text-sm">
        <span className="">View</span>
        {options.length > 1 && (
          <CustomSelect value={rowsLimit} onChange={handleSelectChange} input={<CustomInputBase />}>
            {options
              // .filter((v) => v < totalCount)
              .map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
          </CustomSelect>
        )}
        <span className="">of {totalCount} rows per page</span>
      </div>
      <div className="flex items-center mr-[90px]">
        {pageCounts > 3 && page > 3 && (
          <div onClick={() => onPageChange(1, rowsLimit)} className={iconClasses}>
            <KeyboardDoubleArrowLeft />
          </div>
        )}
        {pageCounts > 3 && page > 1 && (
          <div onClick={() => onPageChange(page - 1, rowsLimit)} className={iconClasses}>
            <KeyboardArrowLeft />
          </div>
        )}
        {generatePageNumbers()}
        {pageCounts > 3 && page < pageCounts && (
          <div onClick={() => onPageChange(page + 1, rowsLimit)} className={iconClasses}>
            <KeyboardArrowRight />
          </div>
        )}
        {pageCounts > 3 && page < pageCounts - 1 && (
          <div onClick={() => onPageChange(pageCounts, rowsLimit)} className={iconClasses}>
            <KeyboardDoubleArrowRight />
          </div>
        )}
      </div>
    </div>
  );
};

export default TablePagination;
