import React, { useState, useEffect } from "react";

const TypingAnimation = ({ stringsToType, typingSpeed, pauseDuration }) => {
  const [currentStringIndex, setCurrentStringIndex] = useState(0);
  const [currentTypedText, setCurrentTypedText] = useState("");

  useEffect(() => {
    const currentString = stringsToType[currentStringIndex];
    let timeout;

    if (currentTypedText.length < currentString.length) {
      timeout = setTimeout(() => {
        setCurrentTypedText(currentString.substring(0, currentTypedText.length + 1));
      }, typingSpeed);
    } else {
      timeout = setTimeout(() => {
        setCurrentTypedText("");
        setCurrentStringIndex((prevIndex) => (prevIndex + 1) % stringsToType.length);
      }, pauseDuration);
    }

    return () => clearTimeout(timeout);
  }, [currentStringIndex, currentTypedText, stringsToType, typingSpeed, pauseDuration]);

  return <span>{currentTypedText}</span>;
};

export default TypingAnimation;
