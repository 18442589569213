/**
 * @flow
 * @relayHash 810f8cac3acfabefcd87121b23f49278
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getSelectedSuborgDetailsQueryVariables = {|
  id?: ?string
|};
export type getSelectedSuborgDetailsQueryResponse = {|
  +authorizedSuborgs: $ReadOnlyArray<{|
    +id: string,
    +name: string,
    +region: ?string,
    +address: ?any,
    +propertyLogoUrl: ?string,
    +propertyManagerLogoUrl: ?string,
    +timezone: ?string,
    +timezoneOffset: ?string,
    +timezoneAbbr: ?string,
    +org: {|
      +id: string,
      +name: string,
      +userRole: ?string,
      +hyblastSetting: {|
        +approverEmails: $ReadOnlyArray<string>
      |},
      +hyeventsSetting: {|
        +approverEmails: $ReadOnlyArray<string>
      |},
    |},
    +linkColor: ?string,
    +fontFamily: ?string,
    +textColor: ?string,
    +buttonBorderColor: ?string,
    +buttonFontColor: ?string,
    +buttonBackgroundColor: ?string,
    +hyblastSetting: {|
      +approverEmails: $ReadOnlyArray<string>
    |},
    +hyeventsSetting: {|
      +approverEmails: $ReadOnlyArray<string>
    |},
    +contactUsLink: ?string,
    +tourCtaUrl: ?string,
  |}>
|};
export type getSelectedSuborgDetailsQuery = {|
  variables: getSelectedSuborgDetailsQueryVariables,
  response: getSelectedSuborgDetailsQueryResponse,
|};
*/


/*
query getSelectedSuborgDetailsQuery(
  $id: String
) {
  authorizedSuborgs(orgId: "any", first: 1, propertyId: $id) {
    id
    name
    region
    address
    propertyLogoUrl
    propertyManagerLogoUrl
    timezone
    timezoneOffset
    timezoneAbbr
    org {
      id
      name
      userRole
      hyblastSetting {
        approverEmails
        id
      }
      hyeventsSetting {
        approverEmails
        id
      }
    }
    linkColor
    fontFamily
    textColor
    buttonBorderColor
    buttonFontColor
    buttonBackgroundColor
    hyblastSetting {
      approverEmails
      id
    }
    hyeventsSetting {
      approverEmails
      id
    }
    contactUsLink
    tourCtaUrl
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1
  },
  {
    "kind": "Literal",
    "name": "orgId",
    "value": "any"
  },
  {
    "kind": "Variable",
    "name": "propertyId",
    "variableName": "id"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "region",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "address",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "propertyLogoUrl",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "propertyManagerLogoUrl",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "timezone",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "timezoneOffset",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "timezoneAbbr",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "userRole",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "approverEmails",
  "args": null,
  "storageKey": null
},
v13 = [
  (v12/*: any*/)
],
v14 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "hyblastSetting",
  "storageKey": null,
  "args": null,
  "concreteType": "HyblastSetting",
  "plural": false,
  "selections": (v13/*: any*/)
},
v15 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "hyeventsSetting",
  "storageKey": null,
  "args": null,
  "concreteType": "HyeventsSetting",
  "plural": false,
  "selections": (v13/*: any*/)
},
v16 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "linkColor",
  "args": null,
  "storageKey": null
},
v17 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fontFamily",
  "args": null,
  "storageKey": null
},
v18 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "textColor",
  "args": null,
  "storageKey": null
},
v19 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "buttonBorderColor",
  "args": null,
  "storageKey": null
},
v20 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "buttonFontColor",
  "args": null,
  "storageKey": null
},
v21 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "buttonBackgroundColor",
  "args": null,
  "storageKey": null
},
v22 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "contactUsLink",
  "args": null,
  "storageKey": null
},
v23 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "tourCtaUrl",
  "args": null,
  "storageKey": null
},
v24 = [
  (v12/*: any*/),
  (v2/*: any*/)
],
v25 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "hyblastSetting",
  "storageKey": null,
  "args": null,
  "concreteType": "HyblastSetting",
  "plural": false,
  "selections": (v24/*: any*/)
},
v26 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "hyeventsSetting",
  "storageKey": null,
  "args": null,
  "concreteType": "HyeventsSetting",
  "plural": false,
  "selections": (v24/*: any*/)
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getSelectedSuborgDetailsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "authorizedSuborgs",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "Suborg",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "org",
            "storageKey": null,
            "args": null,
            "concreteType": "Org",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v11/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/)
            ]
          },
          (v16/*: any*/),
          (v17/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/),
          (v20/*: any*/),
          (v21/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v22/*: any*/),
          (v23/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "getSelectedSuborgDetailsQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "authorizedSuborgs",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "Suborg",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "org",
            "storageKey": null,
            "args": null,
            "concreteType": "Org",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v11/*: any*/),
              (v25/*: any*/),
              (v26/*: any*/)
            ]
          },
          (v16/*: any*/),
          (v17/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/),
          (v20/*: any*/),
          (v21/*: any*/),
          (v25/*: any*/),
          (v26/*: any*/),
          (v22/*: any*/),
          (v23/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "getSelectedSuborgDetailsQuery",
    "id": null,
    "text": "query getSelectedSuborgDetailsQuery(\n  $id: String\n) {\n  authorizedSuborgs(orgId: \"any\", first: 1, propertyId: $id) {\n    id\n    name\n    region\n    address\n    propertyLogoUrl\n    propertyManagerLogoUrl\n    timezone\n    timezoneOffset\n    timezoneAbbr\n    org {\n      id\n      name\n      userRole\n      hyblastSetting {\n        approverEmails\n        id\n      }\n      hyeventsSetting {\n        approverEmails\n        id\n      }\n    }\n    linkColor\n    fontFamily\n    textColor\n    buttonBorderColor\n    buttonFontColor\n    buttonBackgroundColor\n    hyblastSetting {\n      approverEmails\n      id\n    }\n    hyeventsSetting {\n      approverEmails\n      id\n    }\n    contactUsLink\n    tourCtaUrl\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'dc45f3647bab9fdf38dc1b3031375aab';
module.exports = node;
