import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";
import environment from "environments/environment";

const mutation = graphql`
  mutation deleteOfferMutation($input: deleteOfferInput!) {
    deleteOffer(input: $input) {
      offer {
        id
      }
    }
  }
`;

function commit(input) {
  return new Promise((res, rej) => {
    commitMutation(environment, {
      mutation,
      variables: { input },
      updater: (store) => {},
      onCompleted: res,
      onError: rej,
    });
  });
}

export default commit;
