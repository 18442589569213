/**
 * @flow
 * @relayHash 6da108e52e190965720c7dde97efad03
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getScheduledReportsQueryVariables = {|
  orgId: string,
  type: string,
|};
export type getScheduledReportsQueryResponse = {|
  +scheduledReports: $ReadOnlyArray<{|
    +active: boolean,
    +day: string,
    +frequency: string,
    +id: string,
    +name: ?string,
    +onceDate: ?any,
    +org: {|
      +id: string
    |},
    +pdfUrl: ?string,
    +propertyIds: ?$ReadOnlyArray<string>,
    +range: string,
    +recipients: $ReadOnlyArray<string>,
    +reportType: ?string,
    +type: string,
  |}>
|};
export type getScheduledReportsQuery = {|
  variables: getScheduledReportsQueryVariables,
  response: getScheduledReportsQueryResponse,
|};
*/


/*
query getScheduledReportsQuery(
  $orgId: String!
  $type: String!
) {
  scheduledReports(orgId: $orgId, type: $type) {
    active
    day
    frequency
    id
    name
    onceDate
    org {
      id
    }
    pdfUrl
    propertyIds
    range
    recipients
    reportType
    type
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "orgId",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "type",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "scheduledReports",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "orgId",
        "variableName": "orgId"
      },
      {
        "kind": "Variable",
        "name": "type",
        "variableName": "type"
      }
    ],
    "concreteType": "ScheduledReport",
    "plural": true,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "active",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "day",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "frequency",
        "args": null,
        "storageKey": null
      },
      (v1/*: any*/),
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "name",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "onceDate",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ]
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "pdfUrl",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "propertyIds",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "range",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "recipients",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "reportType",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "type",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getScheduledReportsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "getScheduledReportsQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "getScheduledReportsQuery",
    "id": null,
    "text": "query getScheduledReportsQuery(\n  $orgId: String!\n  $type: String!\n) {\n  scheduledReports(orgId: $orgId, type: $type) {\n    active\n    day\n    frequency\n    id\n    name\n    onceDate\n    org {\n      id\n    }\n    pdfUrl\n    propertyIds\n    range\n    recipients\n    reportType\n    type\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2adff5186ba60cf727cfc1abfc83ddcc';
module.exports = node;
