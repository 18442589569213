import graphql from "babel-plugin-relay/macro";

export default graphql`
  query getSummarySendsQuery(
    $orgId: String!
    $startDate: String!
    $endDate: String!
    $type: String
    $regionIds: [String!]
  ) {
    summarySends(
      orgId: $orgId
      startDate: $startDate
      endDate: $endDate
      type: $type
      regionIds: $regionIds
    ) {
      id
      propertyId
      propertyName
      sentAt
      name
      subject
      sendsCount
      uniqueOpensRate
      uniqueOpensCount
      uniqueClicksRate
      uniqueClicksCount
    }
  }
`;
