import { getAppPath } from "constants/path";
import { checkIfEmailExist } from "content/utils";
import { transformUrl } from "relay/main";
import { StarIcon } from "components/Icons";

let propertyId = localStorage.getItem("propertyId");
let userEmail = localStorage.getItem("userEmail");

export const stringtoHtml = (string) => {
  var escapedString = string
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;")
    .replace(/ /g, "&nbsp;");

  return escapedString.replace(/\n/g, "<br>");
};

export const setProperty = (property, preventReload) => {
  propertyId = property.id;
  localStorage.setItem("propertyId", propertyId);
  localStorage.setItem("userEmail", property.userEmail);
  localStorage.setItem("userRole", property.userRole);
  if (preventReload) {
    localStorage.setItem("orgLevel", property.orgLevel);
    return;
  }
  window.location.replace(getAppPath(propertyId));
};

export const setPropertyIdLocalStorage = (propertyId) => {
  localStorage.setItem("propertyId", propertyId);
};

export const getUserEmail = () => {
  return userEmail;
};

export const getPropertyId = (ifAppStart) => {
  if (ifAppStart) {
    if (window.location.pathname.split("/")[1]) {
      return window.location.pathname.split("/")[1];
    } else {
      return propertyId || window.location.pathname.split("/")[1];
    }
  } else {
    return window.location.pathname.split("/")[1] || propertyId;
  }
};

export const getDrawerState = () => {
  if (localStorage.getItem("drawer") === "false") return false;
  return true;
};

export const getUserRole = () => {
  return localStorage.getItem("userRole");
};

export const getBanner = (key) => {
  if (localStorage.getItem(key) === "false") return false;
  return true;
};

export const setBanner = (key) => {
  return localStorage.setItem(key, "false");
};

export const getOrgLevel = (key) => {
  if (localStorage.getItem("orgLevel") === "true") return true;
  return false;
};

export const getEventFilters = () => {
  return [
    {
      label: "Draft",
      selectedColor: "#333",
      value: "draft",
    },
    {
      label: "To Be Approved",
      selectedColor: "#EDB526",
      value: "to_be_approved",
    },
    // {
    //   label: "Scheduled For Publish",
    //   selectedColor: "#8757D6",
    //   value: "publish_scheduled",
    // },
    {
      label: "Published",
      selectedColor: "#0BD3BB",
      value: "published",
    },
    {
      label: "Scheduled For Invite",
      selectedColor: "#3F7DDA",
      value: "invite_scheduled",
    },
    {
      label: "Invited",
      selectedColor: "#66D184",
      value: "invited",
    },
    {
      label: "Canceled",
      selectedColor: "#999999",
      value: "canceled",
    },
    {
      label: "Archived",
      selectedColor: "#98673A",
      value: "archived",
    },
  ];
};
export const getBlastFilters = () => [
  {
    label: "Draft",
    selectedColor: "#333",
    value: "draft",
  },
  {
    label: "Sent for Approval",
    selectedColor: "#EDB526",
    value: "approval",
  },
  {
    label: "Scheduled",
    selectedColor: "#66d184",
    value: "scheduled",
  },
  {
    label: "Sent",
    selectedColor: "var(--hylyTheme)",
    value: "sent",
  },

  {
    label: "AI Powered",
    selectedIcon: <StarIcon highlight={false} />,
    selectedColor: "#333",
    value: "aiPowered",
  },
];

export const shouldDoApprove = (event) => {
  let needToApprove = localStorage.getItem("my_approver_requests");
  if (needToApprove) needToApprove = needToApprove.split(",");
  else return false;
  return event.status === "to_be_approved" && needToApprove.indexOf(event.id) > -1;
};

export const saveRecentLogs = (logs) => {
  localStorage.setItem("recent_logs", JSON.stringify(logs));
};

export const getRecentLogs = () => {
  let logs = localStorage.getItem("recent_logs");
  if (logs) {
    return JSON.parse(logs);
  } else {
    return {
      editor: [],
      property: [],
    };
  }
};

export const premiumApps = ["Blasts", "Drips", "Refer-a-Friend", "Events", "Tours", "Alerts"];

//global app to hymail module
export const globalTohyMail = {
  Blasts: {
    name: "Blasts",
    url: "blast",
    orgLevelUrl: "blast/approvals/my-approval",
  },
  Drips: {
    name: "Drips",
    url: "drips",
    orgLevelUrl: "drips/reports/by-property",
  },
  Alerts: {
    name: "Alerts",
    url: "alerts",
    orgLevelUrl: "alerts/reports/by-property",
  },
  Tours: {
    name: "Tours",
    url: "tours",
    orgLevelUrl: "tours",
  },
  Events: {
    name: "Events",
    url: "events",
    orgLevelUrl: "events/approvals/my-approval",
  },
  "Refer-a-Friend": {
    name: "Raf",
    url: "refer-a-friend",
    orgLevelUrl: "refer-a-friend",
  },
};

//Module name based on the string in url
const appNameParsing = {
  blast: "Blasts",
  drips: "Drips",
  alerts: "Alerts",
  "refer-a-friend": "Raf",
  "success-center": "Success Center",
  "success-center-blast": "Success Center",
  "success-center-drips": "Success Center",
  offers: "Offers",
  offer: "Offers",
  home: "dashboard",
  events: "Events",
  event: "Events",
  tours: "Tours",
};

export const getAppName = (path) => {
  let splittedPathName = path.split("/");
  if (splittedPathName.length > 2) {
    return splittedPathName[2] === "unauth"
      ? appNameParsing[splittedPathName[3]]
      : appNameParsing[splittedPathName[2]];
  } else {
    return "dashboard";
  }
};

export const getAccessDetails = (appName, details) => {
  if (appName === "settings") return "authorised";
  if (
    appName == "Offers" ||
    // appName == "Drips" ||
    appName == "dashboard" ||
    appName == "home" ||
    !appName
  )
    return "authorised";

  let moduleDetails = details?.find((app) => app?.title?.includes(appName));
  if (appName === "Hayley" || appName === "HayleyChat") {
    if (!moduleDetails?.isAccessibleToUser && moduleDetails?.isRentOrg) {
      return "hide";
    } else if (!moduleDetails?.authorized) {
      return "unauthorised";
    }
  }
  if (!moduleDetails && ["Journey Freehand", "Journey Designer", "AI Workshop"].includes(appName))
    return "hide";
  if (!moduleDetails) return "unauthorised";
  if (moduleDetails?.premium) return "premium";
  else if (!moduleDetails?.isAccessibleToUser) return "unauthorised";
  else return "authorised";
};

export const parsePath = (p) => {
  if (p.startsWith(getAppPath() + "/blast/settings/email-editor")) {
    return getAppPath() + "/blast/settings/email-editor";
  }
  if (p.startsWith(getAppPath() + "/blast") && p.includes("edit")) {
    return getAppPath() + "/blast";
  }
  if (p.startsWith(getAppPath() + "/blast/reports/property-audit")) {
    return getAppPath() + "/blast/reports/property-audit";
  }
  if (p.startsWith(getAppPath() + "/blast/reports/by-property")) {
    return getAppPath() + "/blast/reports/by-property";
  }
  if (p.startsWith(getAppPath() + "/blast/reports/blast-contact-list")) {
    return getAppPath() + "/blast/reports/blast-contact-list";
  }
  if (p.startsWith(getAppPath() + "/blast/reports/blast-a-b-variant")) {
    return getAppPath() + "/blast/reports/blast-a-b";
  }
  if (p.startsWith(getAppPath() + "/blast/reports/blasts-analytics")) {
    return getAppPath() + "/blast/reports/blasts-analytics";
  }
  if (p.startsWith(getAppPath() + "/blast/reports/by-blast")) {
    return getAppPath() + "/blast/reports/by-blasts";
  }
  if (p.startsWith(getAppPath() + "/blast/reports/by-email-platform")) {
    return getAppPath() + "/blast/reports/by-email-platform";
  }
  // if (p.startsWith(getAppPath() + "/events/approvals")) {
  //   return p;
  // }
  // if (p.startsWith(getAppPath() + "/events/settings")) {
  //   return p;
  // }
  if (p.startsWith(getAppPath() + "/events/")) {
    return p;
  }
  // if (p.startsWith(getAppPath() + "/events/reports-properties")) {
  //   return p;
  // }
  // if (p.startsWith(getAppPath() + "/events/reports-events")) {
  //   return p;
  // }
  // if (p.startsWith(getAppPath() + "/events/reports-invitees")) {
  //   return p;
  // }
  if (p.startsWith(getAppPath() + "/event")) {
    return getAppPath() + "/events";
  }
  // For Offers

  if (p.startsWith(getAppPath() + "/offers/by-property")) {
    return p;
  }
  if (p.startsWith(getAppPath() + "/offers/by-campaign")) {
    return getAppPath() + "/offers/by-campaigns";
  }
  if (p.startsWith(getAppPath() + "/offers/analytics-by-campaign")) {
    return getAppPath() + "/offers/by-analytics";
  }
  if (p.startsWith(getAppPath() + "/offers/by-contact")) {
    return p;
  }
  if (p.startsWith(getAppPath() + "/offer/")) {
    return getAppPath() + "/offers";
  }
  // For hyRaf
  if (p.startsWith(getAppPath() + "/refer-a-friend/reports/by-campaign")) {
    return getAppPath() + "/refer-a-friend/reports/by-campaigns";
  }
  if (p.startsWith(getAppPath() + "/refer-a-friend/reports/by-contact")) {
    return getAppPath() + "/refer-a-friend/reports/by-contact";
  }
  if (p.startsWith(getAppPath() + "/refer-a-friend/reports/by-property")) {
    return getAppPath() + "/refer-a-friend/reports/by-property";
  }
  if (p.startsWith(getAppPath() + "/refer-a-friend/campaign")) {
    return getAppPath() + "/refer-a-friend";
  }
  // for drips
  if (p.startsWith(getAppPath() + "/drips/reports/by-drip")) {
    return getAppPath() + "/drips/reports/by-drips";
  }
  if (p.startsWith(getAppPath() + "/drips/reports/by-email")) {
    return getAppPath() + "/drips/reports/by-emails";
  }
  if (p.startsWith(getAppPath() + "/drips/reports/email-analytics")) {
    return getAppPath() + "/drips/reports/emails-analytics";
  }
  //for alerts
  if (p.startsWith(getAppPath() + "/alerts/reports/by-alert")) {
    return getAppPath() + "/alerts/reports/by-alerts";
  }
  if (p.startsWith(getAppPath() + "/alerts/reports/by-email")) {
    return getAppPath() + "/alerts/reports/by-emails";
  }
  // if (p.startsWith(getAppPath() + "/alerts/reports/email-analytics")) {
  //   return getAppPath() + "/alerts/reports/emails-analytics";
  // }
  return p;
};

const getFirstSubmenuChild = (menu) => {
  if (menu.length > 1) {
    if (menu[1].children && menu[1].children.length > 0) {
      return menu[1].children[0].to;
    } else {
      return menu[1].to;
    }
  }
};

//when user switch between org and suborg, take user to first submenu
export const getDefaultPathOfTheApp = (menu, pathname, suborgId, orgLevel, initialCall, orgId) => {
  let path = getAppPath(orgLevel ? orgId : suborgId);
  if (pathname === "/") return path + "/home";
  let urlDetails = pathname.split("=/");
  if (urlDetails.length === 1) return path + "/home";

  ////////////////////DRIPS///////////////////////////////
  //drips angels data is always dynamic so we choose to navigate to first submenu
  //handle drips angels submenu
  if (pathname.includes("drips") && (pathname.includes("angels") || pathname.includes("edit"))) {
    //user copy paste the url
    if (initialCall) {
      if (!orgLevel) return pathname;
      return path + "/drips/reports/by-property";
    }
    //user navigate with navdrawer
    return getFirstSubmenuChild(menu);
  }

  //handle case when user switches from orglevel reports to suborgLevel reports
  if (
    urlDetails[1].includes("drips") &&
    !orgLevel &&
    urlDetails[1].includes("by-property") &&
    !urlDetails[1].includes("success-center-drips")
  ) {
    return path + "/drips/reports/by-drips";
  }
  ////////////////////Alerts///////////////////////////////
  //alerts angels data is always dynamic so we choose to navigate to first submenu
  //handle alerts angels submenu
  if (pathname.includes("alerts") && (pathname.includes("angels") || pathname.includes("edit"))) {
    //user copy paste the url
    if (initialCall) {
      if (!orgLevel) return pathname;
      return path + "/alerts/reports/by-property";
    }
    //user navigate with navdrawer
    return getFirstSubmenuChild(menu);
  }

  //handle case when user switches from orglevel reports to suborgLevel reports
  if (urlDetails[1].includes("alerts") && !orgLevel && urlDetails[1].includes("by-property")) {
    return path + "/alerts/reports/by-alerts";
  }

  ////////////////////BLASTS///////////////////////////////
  //handle edit of the blast
  if (!orgLevel && pathname.includes("/blast/") && pathname.includes("/edit")) {
    //user copy paste the url
    if (initialCall) return pathname;
    //when user is at edit and change the org-suborg, we will naviagte it to the list page
    return getFirstSubmenuChild(menu);
  }

  //when user is at blast templates or edit and choose the orgLevel
  if (
    orgLevel &&
    (pathname.includes("blast/templates") ||
      pathname.includes("/blast") ||
      pathname.includes("edit")) &&
    !pathname.includes("settings") &&
    !pathname.includes("reports")
  ) {
    return getFirstSubmenuChild(menu);
  }

  //handle case when user switches from orglevel reports to suborgLevel reports
  if (
    urlDetails[1].includes("blast") &&
    !urlDetails[1].includes("success-center-blast") &&
    !orgLevel &&
    (urlDetails[1].includes("by-property") ||
      (!urlDetails[1].includes("hyblast") && urlDetails[1].includes("property-audit")))
  ) {
    return path + "/blast/reports/by-blasts";
  }
  ////////////////////Events///////////////////////////////
  //when user is at org level but url includes create edit of events
  if (orgLevel && pathname.includes("event")) {
    const eventSplit = pathname.split("events/");
    if (eventSplit[1] && eventSplit[1] !== "templates") return pathname;
    else return path + "/events/approvals/my-approval";
  }

  //when user is at edit and change the org-suborg, we will naviagte it to the list page
  if (!initialCall && pathname.includes("/event/")) {
    return path + "/events";
  }
  if (!initialCall && pathname.includes("/refer-a-friend/campaign/")) {
    return path + "/refer-a-friend";
  }
  // if redirection from another app
  if (initialCall && pathname === path + "/drips") return menu[2].to;
  if (initialCall && pathname === path + "/alerts") return menu[3].to;
  //update the suborgId and concatenate the remaining part of url
  if (urlDetails[1]) return path + "/" + urlDetails[1];

  return path;
};

export const deepEqual = (object1, object2) => {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if ((areObjects && !deepEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
      return false;
    }
  }

  return true;
};

export const isObject = (object) => {
  return object != null && typeof object === "object";
};

//decided the approvals submenu tabs based on the userrole and settings
export const getApprovalTablesTabsDetails = (userRole, orgSuborgApproverSettings) => {
  if (userRole !== "property_manager") return { events: "approver", blasts: "approver" };

  const { hyblastSetting, hyeventsSetting, org } = orgSuborgApproverSettings;
  if (userRole == "property_manager") {
    let obj = {};
    if (
      checkIfEmailExist(hyblastSetting.approverEmails, userEmail) ||
      checkIfEmailExist(org.hyblastSetting.approverEmails, userEmail)
    )
      obj.blasts = "approver";

    if (
      checkIfEmailExist(hyeventsSetting.approverEmails, userEmail) ||
      checkIfEmailExist(org.hyeventsSetting.approverEmails, userEmail)
    )
      obj.events = "approver";

    return obj;
  }
  return { events: "agent", blasts: "agent" };
};

export const checkIfAccessOfApprovalProcess = (appName, userRole, apps) => {
  let appDetails = apps.find((app) => app.name == appName);
  if (appDetails)
    return (
      userRole === "admin" || (userRole === "regional_manager" && appDetails.access === "admin")
    );
  return true;
};

export const checkIfAccess = (appName, apps) => {
  let appDetails = apps.find((app) => app.name == appName);
  if (appDetails) return appDetails.access === "admin";
  return true;
};

export const shortExpiration = () => new Date(Date.now() + 20000).toUTCString();

export const modulesName = ["Blasts", "Events", "Raf", "Drips", "Success Center", "Alerts"];

//get accessible screens of settings
export const getAccessibleScreens = (menu, orgLevel, access) => {
  if (orgLevel) {
    if (access) return [menu[0], menu[3]];
    else return menu.slice(-1);
  } else {
    if (!access) return menu.slice(1);
  }
  return menu;
};
export const getAccessibleScreensForBlasts = (menu, orgLevel, access) => {
  if (orgLevel) {
    if (access) return [menu[0], menu[3], menu[4]];
    else return menu.slice(3, 4);
  } else {
    if (!access) return menu.slice(1, 4);
  }
  return menu;
};

export const getTransformUrl = async (url) => {
  try {
    const res = await transformUrl({ in: url });
    return res;
  } catch (err) {
    console.log("Err", err);
  }
};

export const dotLoader = () => {
  return (
    <div className=" inline-block space-x-1">
      <span
        style={{ animation: "bounce 1s infinite 300ms" }}
        className="inline-block w-2 h-2 rounded-full bg-hyly-blue/30"
      ></span>
      <span
        style={{ animation: "bounce 1s infinite 600ms" }}
        className="inline-block w-2 h-2 rounded-full bg-hyly-blue/30"
      ></span>
      <span
        style={{ animation: "bounce 1s infinite 900ms" }}
        className="inline-block w-2 h-2 rounded-full bg-hyly-blue/30"
      ></span>
    </div>
  );
};

export const jaiSwitcherData = [
  "Blasts",
  "Drips",
  "Alerts",
  "Tours",
  "Events",
  "Refer-a-Friend",
  "Success Center",
  "Journey Designer",
  "Journey Freehand",
  "HayleyChat",
  "Success Center",
  "Success Center",
];
