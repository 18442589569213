/**
 * @flow
 * @relayHash d372de9c9bb745f6ccfdbbb73e4f58d4
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type setMascotAnimationInput = {|
  clientMutationId?: ?string,
  propertyId: string,
  animation: string,
|};
export type setMascotAnimationMutationVariables = {|
  input: setMascotAnimationInput
|};
export type setMascotAnimationMutationResponse = {|
  +setMascotAnimation: ?{|
    +response: ?string
  |}
|};
export type setMascotAnimationMutation = {|
  variables: setMascotAnimationMutationVariables,
  response: setMascotAnimationMutationResponse,
|};
*/


/*
mutation setMascotAnimationMutation(
  $input: setMascotAnimationInput!
) {
  setMascotAnimation(input: $input) {
    response
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "setMascotAnimationInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "setMascotAnimation",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "setMascotAnimationPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "response",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "setMascotAnimationMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "setMascotAnimationMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "setMascotAnimationMutation",
    "id": null,
    "text": "mutation setMascotAnimationMutation(\n  $input: setMascotAnimationInput!\n) {\n  setMascotAnimation(input: $input) {\n    response\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8632788472daf23592072cd13a47c05d';
module.exports = node;
