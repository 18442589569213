import moment from "moment";

const setDimensions = (field, value) => {
  localStorage.setItem(field, value);
};

const parseISO = (isoDate, dateFormat) => {
  let x = Date.parse(isoDate);
  let date = new Date(x);
  return moment(date.toUTCString()).format(dateFormat);
};

function strip_html_tags(str) {
  if (str === null || str === "") return false;
  else str = str.toString();
  return decodeHtml(str.replace(/<[^>]*>/g, ""));
}

function getAttributes(str) {
  var regEx = /(width|height)=["']([^"']*)["']/gi;
  var w = false;
  var h = false;
  str.replace(regEx, function (all, type, value) {
    if (type === "width") {
      w = true;
    }
    if (type === "height") {
      h = true;
    }
  });
  return w && h;
}

function fixIframe(str) {
  var attr = getAttributes(str);
  var a = str.replace(
    /<iframe/g,
    attr ? `<div><iframe` : `<div><iframe width="100%" height="340px"`
  );
  var b = a.replace(/iframe>/g, "iframe></div><div><br></div>");
  return b;
}

function fixImg(str) {
  var attr = getAttributes(str);
  var a = str.replace(/<img/g, attr ? `<div><img` : `<div><img width="100%" height="340px"`);
  var b = a.replace(/img>/g, "img></div><div><br></div>");
  return b;
}

function fixSpaceBetweenULandLI(str) {
  var a = str.replace(/ul>[ ]+\<li/g, "ul><li");
  return a;
}

function fixTagsBefore(str) {
  var a = str.replace(/\n/g, "");
  var b = a.replace(/<div><br><\/div>/g, "<br>");
  var c = b.replace(/<div><\/div>/g, "");
  var d = fixIframe(c);
  var e = fixImg(d);
  var f = fixSpaceBetweenULandLI(e);
  return f;
}

function fixTags(str) {
  var a = str; //str.replace(/(<\/?span[^>]*>)/gim, "");
  var b = a.replace(/<p><\/p>/g, "<div><br></div>");
  var c = b.replace(/<p/g, "<div");
  var d = c.replace(/p>/g, "div>");
  var e = d.replace(/<br><\/div>/g, "</div><br>");
  return e;
}

function decodeHtml(str) {
  var map = {
    "&amp;": "&",
    "&lt;": "<",
    "&gt;": ">",
    "&quot;": '"',
    "&#039;": "'",
  };
  return str.replace(/&amp;|&lt;|&gt;|&quot;|&#039;/g, function (m) {
    return map[m];
  });
}

function getFieldNumber(field) {
  return field.match(/\d+/);
}

const selectedTime = (time) => {
  return moment(time).subtract(15, "minutes").format("MM/DD/YYYY, h:mm A");
};

const currentTime = () => {
  let x = Date.now();
  let date = new Date(x);
  return moment(date).format("MM/DD/YYYY, h:mm A");
};

const currentDate = () => {
  let x = Date.now();
  let date = new Date(x);
  return moment(date).format("MM/DD/YYYY");
};

const diffTime = (selectedTime) => {
  var startTime = moment(currentTime(), "MM/DD/YYYY, h:mm A");
  var endTime = moment(selectedTime, "MM/DD/YYYY, h:mm A");
  return endTime.diff(startTime, "minutes");
};

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

function replaceAll(str, term, replacement) {
  return str.replace(new RegExp(escapeRegExp(term), "g"), replacement);
}

function getContactsDetail(sentGroup, contactGroups, mms, mmsStatus) {
  if (sentGroup && contactGroups) {
    let customGroup = [];
    let eBlast = 0;
    let SMS = 0;
    sentGroup &&
      sentGroup.split(", ").map((group, i) => {
        let getContact = contactGroups && contactGroups.find((c, i) => c.name === group);
        if (getContact) {
          if (getContact.count > 0) {
            eBlast += getContact.reachableCount;
            SMS += getContact.mmsReachableCount;
            if (customGroup.length) {
              customGroup.push(" " + formatNumber(getContact.reachableCount) + " " + group);
            } else {
              customGroup.push(formatNumber(getContact.reachableCount) + " " + group);
            }
          }
        }
      });
    let otherDetails =
      mms && mmsStatus && mmsStatus !== "disabled" ? `(Blast: ${eBlast} | SMS: ${SMS})` : "";
    return customGroup.length > 0
      ? `${customGroup.toString()} ${otherDetails}`
      : `0 ${sentGroup} ${otherDetails}`;
  } else {
    return "0 ";
  }
}

function getGroupsCount(sentGroup, contactGroups) {
  if (sentGroup && contactGroups.length > 0) {
    let totalCount = 0;
    sentGroup &&
      sentGroup.split(", ").map((group, i) => {
        let getContact = contactGroups && contactGroups.find((c, i) => c.name === group);
        if (getContact) {
          totalCount += getContact.count;
        }
      });
    return formatNumber(totalCount);
  } else {
    return 0;
  }
}

function getSentGroupCount(sentGroupCount) {
  var r = [];
  for (let [key, value] of Object.entries(JSON.parse(sentGroupCount))) {
    r.push(" " + formatNumber(value) + " " + key);
  }
  return r.toString();
}

function getOnlyCounts(sentGroup, contactGroups, customGroupCount = 0) {
  if (sentGroup && contactGroups.length > 0) {
    let sentGroups = sentGroup.split(", ");
    let count = 0;
    sentGroups.map((group, index) => (count += contactGroups.find((c) => c.name === group).count));
    return count + customGroupCount;
  }

  return customGroupCount;
}

function getImageWidth(dimension, src) {
  let result = dimension.split("x");
  if (result[0] && result[1]) {
    return result[0];
  } else {
    var img = new Image();
    img.src = src;
    img.onload = function () {
      return this.width;
    };
    return 600;
  }
}

function getImageHeight(dimension, src) {
  let result = dimension.split("x");
  if (result[0] && result[1]) {
    return result[1];
  } else {
    var img = new Image();
    img.src = src;
    img.onload = function () {
      return this.height;
    };
    return 340;
  }
}

const createProportionalDimensions = (width, height) => {
  let required_width = 600;
  if (width < 600) {
    return required_width + "x" + height;
  } else {
    let required_height = Math.round((height * 600) / width);
    return required_width + "x" + required_height;
  }
};

function getUrlFromMacrosList(field, macrosList) {
  if (!macrosList) {
    setDimensions(field.field, "600x340");
    return null;
  }
  const filteredData = Object.fromEntries(
    Object.entries(macrosList).filter(([key, value]) => value.name === field.value)
  );

  let extractedValue = null;
  for (const key in filteredData) {
    if (filteredData[key].hasOwnProperty("value")) {
      extractedValue = filteredData[key].value;
      break;
    }
  }
  var img = new Image();
  img.src = extractedValue;
  img.onload = function () {
    let propDimensions = this.width + "x" + this.height;
    setDimensions(field.field, propDimensions);
  };
}

function getDimensionsOfImage(field, macrosList) {
  if (field.value) {
    let result = field.dimension.split("x");
    if (
      !field.dimension.includes("auto") &&
      !field.dimension.includes("%") &&
      result[0] &&
      result[1]
    ) {
      let propDimensions = result[0] + "x" + result[1];
      setDimensions(field.field, propDimensions);
    } else {
      var img = new Image();
      img.src = field.value;
      img.onload = function () {
        let propDimensions = this.width + "x" + this.height;
        setDimensions(field.field, propDimensions);
      };
      img.onerror = function () {
        getUrlFromMacrosList(field, macrosList);
      };
    }
  } else {
    setDimensions(field.field, "600x340");
  }
}

function isURL() {
  const url_rgx =
    "^(?!mailto:)(?:((?:http|https|ftp)://)|www.)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$";
  const phone_rgx_with_dash = "^tel:(([0-9]{3})-|[0-9]{3}-)[0-9]{3}-[0-9]{4}$";
  const phone_rgx_without_dash = "^tel:(([0-9]{3})|[0-9]{3})[0-9]{3}[0-9]{4}$";
  const macro_rgx = "{%([^}]+)%}";
  const email_rgx =
    /^mailto:((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/;
  let email_rgx_str = new RegExp(email_rgx).toString();
  email_rgx_str = email_rgx_str.slice(1, email_rgx_str.length - 1);
  return new RegExp(
    "(" +
      email_rgx_str +
      ")|(" +
      phone_rgx_with_dash +
      ")|(" +
      phone_rgx_without_dash +
      ")|(" +
      url_rgx +
      ")|(" +
      macro_rgx +
      ")",
    "i"
  );
}

function isUrlValid(userInput) {
  if (userInput) {
    var res = userInput.split("?")[0].match(isURL());
    const pattern =
      /(?:https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|(?:https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?|(?:tel:[0-9+\-()\\s]{10,}|mailto:[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})/;
    const validUrl = pattern.test(userInput.split("?")[0]);
    if (validUrl) return true;
    if (res == null) return false;
    else return true;
  } else {
    return false;
  }
}

function camelCase(str) {
  return str
    .replace(/\s(.)/g, function (a) {
      return a.toUpperCase();
    })
    .replace(/\s/g, "")
    .replace(/^(.)/, function (b) {
      return b.toLowerCase();
    });
}

function getThisWeek() {
  var curr = new Date();
  var first = curr.getDate() + 1 - curr.getDay();
  var last = first + 6;
  var firstday = new Date(curr.setDate(first));
  var lastday = new Date(curr.setDate(last));
  var startDate = parseISO(firstday, "YYYY-MM-DD");
  var endDate = parseISO(lastday, "YYYY-MM-DD");
  return startDate + "," + endDate;
}

function getThisMonth() {
  var date = new Date();
  var firstday = new Date(date.getFullYear(), date.getMonth(), 1);
  var lastday = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  var startDate = parseISO(firstday, "YYYY-MM-DD");
  var endDate = parseISO(lastday, "YYYY-MM-DD");
  return startDate + "," + endDate;
}

function getThisQuarter() {
  var date = new Date();
  var quarter = Math.floor((date.getMonth() + 3) / 3);
  var firstday, lastday, startDate, endDate;
  switch (quarter) {
    case 1:
      firstday = new Date(date.getFullYear(), 1, 1);
      lastday = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      startDate = parseISO(firstday, "YYYY-MM-DD");
      endDate = parseISO(lastday, "YYYY-MM-DD");
      return startDate + "," + endDate;
    case 2:
      firstday = new Date(date.getFullYear(), 4 - 1, 1);
      lastday = new Date(date.getFullYear(), date.getMonth() + 3, 0);
      startDate = parseISO(firstday, "YYYY-MM-DD");
      endDate = parseISO(lastday, "YYYY-MM-DD");
      return startDate + "," + endDate;
    case 3:
      firstday = new Date(date.getFullYear(), 7 - 1, 1);
      lastday = new Date(date.getFullYear(), date.getMonth() + 3, 0);
      startDate = parseISO(firstday, "YYYY-MM-DD");
      endDate = parseISO(lastday, "YYYY-MM-DD");
      return startDate + "," + endDate;
    case 4:
      firstday = new Date(date.getFullYear(), 10 - 1, 1);
      lastday = new Date(date.getFullYear(), date.getMonth() + 3, 0);
      startDate = parseISO(firstday, "YYYY-MM-DD");
      endDate = parseISO(lastday, "YYYY-MM-DD");
      return startDate + "," + endDate;
    default:
      firstday = new Date(date.getFullYear(), 1 - 1, 1);
      lastday = new Date(date.getFullYear(), date.getMonth() + 3, 0);
      startDate = parseISO(firstday, "YYYY-MM-DD");
      endDate = parseISO(lastday, "YYYY-MM-DD");
      return startDate + "," + endDate;
  }
}

function getThisYear() {
  var date = new Date();
  var firstday = new Date(date.getFullYear(), 0, 1);
  var lastday = new Date(date.getFullYear(), 12, 0);
  var startDate = parseISO(firstday, "YYYY-MM-DD");
  var endDate = parseISO(lastday, "YYYY-MM-DD");
  return startDate + "," + endDate;
}

function getDropdownSelectionDate(date) {
  return moment(date).format("MMM DD, YYYY");
}

function getConvertedTimeStamp(body) {
  if (body) {
    var indexOftimeStamp = body.indexOf(20);
    var timeStamp = body.substring(indexOftimeStamp, indexOftimeStamp + 20);
    if (timeStamp[timeStamp.length - 1] === "Z" || timeStamp.includes("T")) {
      var convertedTimeStamp = parseISO(timeStamp, "MM/DD/YYYY, h:mm A");
      if (indexOftimeStamp !== -1) {
        if (convertedTimeStamp !== "Invalid date") {
          var newBody = body.split(timeStamp).join(convertedTimeStamp);
          return newBody;
        } else {
          return body;
        }
      } else {
        return body;
      }
    } else {
      return body;
    }
  }
}

function formatNumber(num) {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

function compareValues(key, order = "asc") {
  return function innerSort(a, b) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      return 0;
    }

    const varA = typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
    const varB = typeof b[key] === "string" ? b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return order === "desc" ? comparison * -1 : comparison;
  };
}

function getFeedMessage(str) {
  var msg = str.split("feedback:");
  if (msg.length === 2) {
    return msg[1];
  } else {
    return str;
  }
}

function getFeedUser(str) {
  var r = str.split(" ");
  if (r && r.length > 3) {
    return r[2];
  } else {
    return "vip@hy.ly";
  }
}

function getTimeStamp(str) {
  var r = str && str.split(" ");
  if (r && r.length > 0) {
    var time = r[r.length - 1];
    if (time && time.includes("T") && time.includes("Z")) {
      return parseISO(time, "MM/DD/YYYY, h:mm A");
    } else {
      return parseISO(moment(), "MM/DD/YYYY, h:mm A");
    }
  } else {
    return parseISO(moment(), "MM/DD/YYYY, h:mm A");
  }
}

function getRate(rate, total) {
  let result = rate && total > 0 ? (rate / total) * 100 : 0;
  return result.toFixed(2);
}

export {
  parseISO,
  strip_html_tags,
  fixTagsBefore,
  fixTags,
  getFieldNumber,
  selectedTime,
  diffTime,
  replaceAll,
  getContactsDetail,
  getGroupsCount,
  getSentGroupCount,
  getOnlyCounts,
  getImageWidth,
  getImageHeight,
  createProportionalDimensions,
  getDimensionsOfImage,
  isUrlValid,
  camelCase,
  getThisWeek,
  getThisMonth,
  getThisQuarter,
  getThisYear,
  getDropdownSelectionDate,
  getConvertedTimeStamp,
  formatNumber,
  compareValues,
  getFeedMessage,
  getFeedUser,
  getTimeStamp,
  currentTime,
  currentDate,
  getRate,
};
