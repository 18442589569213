import React, { useEffect, useRef } from "react";
import "./index.css";

const Preview = ({ content, classes }) => {
  const previewRef = useRef(null);
  useEffect(() => {
    if (previewRef.current) {
      const elements = previewRef.current.getElementsByTagName("a");
      for (let element of elements) {
        if (!element.getAttribute("target")) {
          element.setAttribute("target", "_blank");
        }
      }
    }
  }, [previewRef]);
  return (
    <>
      <div
        ref={previewRef}
        id="frame-preview"
        className={classes}
        dangerouslySetInnerHTML={{ __html: content }}
      />
      <div id="hmask"></div>
    </>
  );
};

export default React.memo(Preview);
