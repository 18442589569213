/**
 * @flow
 * @relayHash 7defe4e4341580871a1c0d3676c93096
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getRecentPagesQueryVariables = {|
  orgId: string,
  propertyId?: ?string,
  appName: string,
  objectType?: ?string,
  pageName?: ?string,
|};
export type getRecentPagesQueryResponse = {|
  +recentPages: {|
    +pageInfo: {|
      +endCursor: ?string,
      +startCursor: ?string,
      +hasPreviousPage: boolean,
      +hasNextPage: boolean,
    |},
    +edges: ?$ReadOnlyArray<?{|
      +cursor: string,
      +node: ?{|
        +id: string,
        +tenantId: ?string,
        +propertyId: ?string,
        +pagePath: ?string,
        +pageName: ?string,
        +propertyName: ?string,
        +openedAt: ?any,
        +objectName: ?string,
        +objectData: ?string,
      |},
    |}>,
  |}
|};
export type getRecentPagesQuery = {|
  variables: getRecentPagesQueryVariables,
  response: getRecentPagesQueryResponse,
|};
*/


/*
query getRecentPagesQuery(
  $orgId: ID!
  $propertyId: ID
  $appName: String!
  $objectType: String
  $pageName: String
) {
  recentPages(orgId: $orgId, propertyId: $propertyId, appName: $appName, objectType: $objectType, pageName: $pageName) {
    pageInfo {
      endCursor
      startCursor
      hasPreviousPage
      hasNextPage
    }
    edges {
      cursor
      node {
        id
        tenantId
        propertyId
        pagePath
        pageName
        propertyName
        openedAt
        objectName
        objectData
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "orgId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "propertyId",
    "type": "ID",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "appName",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "objectType",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "pageName",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "recentPages",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "appName",
        "variableName": "appName"
      },
      {
        "kind": "Variable",
        "name": "objectType",
        "variableName": "objectType"
      },
      {
        "kind": "Variable",
        "name": "orgId",
        "variableName": "orgId"
      },
      {
        "kind": "Variable",
        "name": "pageName",
        "variableName": "pageName"
      },
      {
        "kind": "Variable",
        "name": "propertyId",
        "variableName": "propertyId"
      }
    ],
    "concreteType": "RecentPageConnection",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "pageInfo",
        "storageKey": null,
        "args": null,
        "concreteType": "PageInfo",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "endCursor",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "startCursor",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "hasPreviousPage",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "hasNextPage",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "edges",
        "storageKey": null,
        "args": null,
        "concreteType": "RecentPageEdge",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "cursor",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "RecentPage",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "tenantId",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "propertyId",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "pagePath",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "pageName",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "propertyName",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "openedAt",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "objectName",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "objectData",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getRecentPagesQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "getRecentPagesQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "getRecentPagesQuery",
    "id": null,
    "text": "query getRecentPagesQuery(\n  $orgId: ID!\n  $propertyId: ID\n  $appName: String!\n  $objectType: String\n  $pageName: String\n) {\n  recentPages(orgId: $orgId, propertyId: $propertyId, appName: $appName, objectType: $objectType, pageName: $pageName) {\n    pageInfo {\n      endCursor\n      startCursor\n      hasPreviousPage\n      hasNextPage\n    }\n    edges {\n      cursor\n      node {\n        id\n        tenantId\n        propertyId\n        pagePath\n        pageName\n        propertyName\n        openedAt\n        objectName\n        objectData\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b397de01b2b54807cad19e5fc444d155';
module.exports = node;
