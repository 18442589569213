/**
 * @flow
 * @relayHash 050157d4a1fcc50ebe7b05b971f0018f
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type allOrgsGptBlastAnalysisQueryVariables = {|
  allOrgs?: ?boolean,
  endAt?: ?string,
  startAt?: ?string,
  orgIds?: ?$ReadOnlyArray<string>,
|};
export type allOrgsGptBlastAnalysisQueryResponse = {|
  +allOrgsGptBlastAnalysis: any
|};
export type allOrgsGptBlastAnalysisQuery = {|
  variables: allOrgsGptBlastAnalysisQueryVariables,
  response: allOrgsGptBlastAnalysisQueryResponse,
|};
*/


/*
query allOrgsGptBlastAnalysisQuery(
  $allOrgs: Boolean
  $endAt: String
  $startAt: String
  $orgIds: [String!]
) {
  allOrgsGptBlastAnalysis(allOrgs: $allOrgs, endAt: $endAt, startAt: $startAt, orgIds: $orgIds)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "allOrgs",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "endAt",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "startAt",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "orgIds",
    "type": "[String!]",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "allOrgsGptBlastAnalysis",
    "args": [
      {
        "kind": "Variable",
        "name": "allOrgs",
        "variableName": "allOrgs"
      },
      {
        "kind": "Variable",
        "name": "endAt",
        "variableName": "endAt"
      },
      {
        "kind": "Variable",
        "name": "orgIds",
        "variableName": "orgIds"
      },
      {
        "kind": "Variable",
        "name": "startAt",
        "variableName": "startAt"
      }
    ],
    "storageKey": null
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "allOrgsGptBlastAnalysisQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "allOrgsGptBlastAnalysisQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "allOrgsGptBlastAnalysisQuery",
    "id": null,
    "text": "query allOrgsGptBlastAnalysisQuery(\n  $allOrgs: Boolean\n  $endAt: String\n  $startAt: String\n  $orgIds: [String!]\n) {\n  allOrgsGptBlastAnalysis(allOrgs: $allOrgs, endAt: $endAt, startAt: $startAt, orgIds: $orgIds)\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9ade9c4888361589d8a546356decefc2';
module.exports = node;
