/**
 * @flow
 * @relayHash 7e59e784a1e973886ac52b7e3f1a5c40
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type initialValuesQueryQueryVariables = {|
  name?: ?string,
  first?: ?number,
  appName?: ?string,
|};
export type initialValuesQueryQueryResponse = {|
  +authorizedOrgs: $ReadOnlyArray<{|
    +id: string,
    +name: string,
    +regions: ?$ReadOnlyArray<{|
      +id: string,
      +name: string,
    |}>,
    +suborgs: $ReadOnlyArray<{|
      +id: string,
      +name: string,
      +region: ?string,
      +regionId: ?string,
    |}>,
  |}>
|};
export type initialValuesQueryQuery = {|
  variables: initialValuesQueryQueryVariables,
  response: initialValuesQueryQueryResponse,
|};
*/


/*
query initialValuesQueryQuery(
  $name: String
  $first: Int
  $appName: String
) {
  authorizedOrgs(name: $name, first: $first, appName: $appName) {
    id
    name
    regions {
      id
      name
    }
    suborgs {
      id
      name
      region
      regionId
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "name",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "appName",
    "type": "String",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "authorizedOrgs",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "appName",
        "variableName": "appName"
      },
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
      },
      {
        "kind": "Variable",
        "name": "name",
        "variableName": "name"
      }
    ],
    "concreteType": "Org",
    "plural": true,
    "selections": [
      (v1/*: any*/),
      (v2/*: any*/),
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "regions",
        "storageKey": null,
        "args": null,
        "concreteType": "Region",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/)
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "suborgs",
        "storageKey": null,
        "args": null,
        "concreteType": "Suborg",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "region",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "regionId",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "initialValuesQueryQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "initialValuesQueryQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "initialValuesQueryQuery",
    "id": null,
    "text": "query initialValuesQueryQuery(\n  $name: String\n  $first: Int\n  $appName: String\n) {\n  authorizedOrgs(name: $name, first: $first, appName: $appName) {\n    id\n    name\n    regions {\n      id\n      name\n    }\n    suborgs {\n      id\n      name\n      region\n      regionId\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'acf033cb3503c95cc8e9db8409f66a6a';
module.exports = node;
