import * as React from "react";
import { ReactMultiEmail, isEmail } from "components/MultiInput";
import makeStyles from "@mui/styles/makeStyles";
import Icon from "storybookComponents/Icon";
import Typography from "storybookComponents/Typography";
import clsx from "clsx";

const useStyle = makeStyles(() => ({
  container: {
    paddingRight: 20,
    marginTop: 14,
    "&& .react-multi-email": {
      background: "transparent !important",
      border: "none",
      borderBottom: "1px solid #EEEEEE",
      borderRadius: 0,
      padding: 0,
    },
    "&& input": {
      background: "transparent !important",
      fontSize: "16px !important",
      fontSize: "16px !important",
    },
  },
  tag: {
    background: "transparent !important",
    border: "1px solid #333333 !important",
    color: "#333333 !important",
    fontSize: "16px !important",
    borderRadius: "20px !important",
    fontFamily: "Open Sans !important",
    display: "flex",
    alignItems: "center",
  },
}));

export default React.memo(
  ({
    label,
    description,
    keyName,
    values,
    setFieldValue,
    regex,
    mask,
    restrictText,
    className,
  }) => {
    const classes = useStyle();
    const parsePhone = (emails) => {
      let emailLocal = [];
      let reg = /-/g;
      emails.map((email) => {
        let e = email.replace(reg, "");
        emailLocal.push(e);
      });
      return emailLocal;
    };
    return (
      <div className={clsx(classes.container, className && className.container)}>
        <Typography
          type="subtitle"
          color="black"
          font_weight="600"
          className={className && className.label}
        >
          {label}
        </Typography>
        <ReactMultiEmail
          placeholder=""
          emails={values[keyName]}
          onChange={(emails) => {
            if (keyName === "phones") {
              //emails = parsePhone(emails);
            }

            setFieldValue(keyName, emails);
          }}
          validateEmail={(email) => {
            return regex ? regex.test(email) : isEmail(email);
          }}
          getLabel={(email, index, removeEmail) => {
            return (
              <div data-tag key={index} className={classes.tag}>
                <span>{email}&nbsp;&nbsp;</span>
                <Icon type="icon-e-remove" onClick={() => removeEmail(index)} size="sm" />
              </div>
            );
          }}
          mask={mask}
          restrictText={restrictText}
          regex={regex}
        />
        <div>
          <Typography type="subtitle" color="deepGrey">
            {description}
          </Typography>
        </div>
      </div>
    );
  }
);
