// DUCKS pattern
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  orgOrPropertyId: "",
  params: null,
  tabName: "",
  type: "",
  name: "",
};

const favoriteReportSlice = createSlice({
  name: "favoriteReport",
  initialState,
  reducers: {
    resetStates4Fav: () => initialState,
    setStates4Fav: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const getStates4Fav = (state) => state.favoriteReport;

export const { resetStates4Fav, setStates4Fav } = favoriteReportSlice.actions;
export default favoriteReportSlice.reducer;
