const styles = {
  toggleButtonDiv: {
    width: "192px",
    height: "32px",
    border: "1px solid #DADADA",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "20px",
  },
  button: {
    width: "96px",
    height: "30px",
    lineHeight: "20px",
    fontSize: "14px",
    paddingTop: "7px",
    paddingBottom: "5px",
  },
};

export default styles;
