import React from "react";

import { Modal } from "components";
import LaunchIcon from "components/Icons/LaunchIcon";

export default function OrgLevelSwitchModal({
  open,
  handleClose,
  selectedItem,
  handleOpenInNewTab,
  onSwitch,
}) {
  if (!selectedItem) return null;

  const title =
    selectedItem.level === "property" ? "Switch to Property Level" : "Switch to Org Level";
  const isPropertyLevel = selectedItem.level === "property";

  return (
    <Modal
      open={open}
      maxWidth="sm"
      title={title}
      handleClose={handleClose}
      actionChildren={
        <>
          <div className="flex items-center justify-end space-x-[12px]">
            <button
              onClick={handleOpenInNewTab}
              className="border-[1px] group border-[#999999] hover:border-hyly-blue hover:text-hyly-blue border-solid bg-white font-semibold text-[14px] leading-[20px] font-open-sans h-[40px] w-[172px]"
            >
              Open in a New Tab <LaunchIcon className="text-[#999999] group-hover:text-hyly-blue" />
            </button>
            <button
              className="bg-hyly-blue py-[10px] flex justify-center border-0 text-[14px] font-semibold text-white cursor-pointer px-[16px]"
              type="button"
              onClick={onSwitch}
            >
              {isPropertyLevel ? "Yes, Switch To Property Level" : "Yes, Switch To Org Level"}
            </button>
          </div>
        </>
      }
    >
      <div>
        {isPropertyLevel ? (
          <p className="font-normal text-[16px] leading-[24px]">
            <span className="font-semibold">{selectedItem.appName}</span> is only available on the{" "}
            <span className="font-semibold">Property Level</span>, are you sure you want to switch
            to property level?
          </p>
        ) : (
          <p>
            <span className="font-semibold">{selectedItem.appName}</span> is only available on the{" "}
            <span className="font-semibold">Org Level</span>, are you sure you want to switch to org
            level?
          </p>
        )}
      </div>
    </Modal>
  );
}
