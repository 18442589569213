/**
 * @flow
 * @relayHash 9d793a6ea9e961bb7dac3bf50a8a8cbc
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getHayleyCustomizationQueryVariables = {|
  propertyId: string
|};
export type getHayleyCustomizationQueryResponse = {|
  +hayley: {|
    +iconThumbnailUrl: ?string,
    +iconUrl: ?string,
    +id: string,
    +imageData: ?string,
    +position: ?string,
    +widgetTitle: ?string,
    +chatbotTitle: ?string,
    +supportMultiLanguage: ?boolean,
    +hidePriceRangeFilter: ?boolean,
    +promotion: ?string,
    +showFeedbackFilter: ?boolean,
    +iconFileType: ?string,
    +firstMessage: string,
    +disclaimer: string,
    +tourScheduleEnabled: ?boolean,
    +customButtonEnabled: ?boolean,
    +makePhoneNumberRequired: ?boolean,
    +showAllUnits: ?boolean,
  |}
|};
export type getHayleyCustomizationQuery = {|
  variables: getHayleyCustomizationQueryVariables,
  response: getHayleyCustomizationQueryResponse,
|};
*/


/*
query getHayleyCustomizationQuery(
  $propertyId: ID!
) {
  hayley(propertyId: $propertyId) {
    iconThumbnailUrl
    iconUrl
    id
    imageData
    position
    widgetTitle
    chatbotTitle
    supportMultiLanguage
    hidePriceRangeFilter
    promotion
    showFeedbackFilter
    iconFileType
    firstMessage
    disclaimer
    tourScheduleEnabled
    customButtonEnabled
    makePhoneNumberRequired
    showAllUnits
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "propertyId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "hayley",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "propertyId",
        "variableName": "propertyId"
      }
    ],
    "concreteType": "Hayley",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "iconThumbnailUrl",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "iconUrl",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "imageData",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "position",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "widgetTitle",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "chatbotTitle",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "supportMultiLanguage",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "hidePriceRangeFilter",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "promotion",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "showFeedbackFilter",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "iconFileType",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "firstMessage",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "disclaimer",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "tourScheduleEnabled",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "customButtonEnabled",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "makePhoneNumberRequired",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "showAllUnits",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getHayleyCustomizationQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "getHayleyCustomizationQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "getHayleyCustomizationQuery",
    "id": null,
    "text": "query getHayleyCustomizationQuery(\n  $propertyId: ID!\n) {\n  hayley(propertyId: $propertyId) {\n    iconThumbnailUrl\n    iconUrl\n    id\n    imageData\n    position\n    widgetTitle\n    chatbotTitle\n    supportMultiLanguage\n    hidePriceRangeFilter\n    promotion\n    showFeedbackFilter\n    iconFileType\n    firstMessage\n    disclaimer\n    tourScheduleEnabled\n    customButtonEnabled\n    makePhoneNumberRequired\n    showAllUnits\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '908b037691227d0cd389d0c43e42ee40';
module.exports = node;
