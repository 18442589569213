import graphql from "babel-plugin-relay/macro";

export default graphql`
  mutation updateHayleyQualifierButtonMutation($input: updateHayleyQualifierButtonInput!) {
    updateHayleyQualifierButton(input: $input) {
      hayleyQualificationButton
      qualificationButtonEnabled
    }
  }
`;
