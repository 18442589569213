/**
 * @flow
 * @relayHash d9237ff39706c827910abc3a2753e3c1
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type listMailGptBlastsQueryVariables = {|
  tenantId: string,
  startAt?: ?string,
  endAt?: ?string,
  type?: ?string,
  params?: ?any,
|};
export type listMailGptBlastsQueryResponse = {|
  +listMailGptBlasts: $ReadOnlyArray<{|
    +id: string,
    +uxFields: ?any,
    +title: ?{|
      +value: ?string
    |},
  |}>
|};
export type listMailGptBlastsQuery = {|
  variables: listMailGptBlastsQueryVariables,
  response: listMailGptBlastsQueryResponse,
|};
*/


/*
query listMailGptBlastsQuery(
  $tenantId: ID!
  $startAt: String
  $endAt: String
  $type: String
  $params: Json
) {
  listMailGptBlasts(tenantId: $tenantId, startAt: $startAt, endAt: $endAt, type: $type, params: $params) {
    id
    uxFields
    title {
      value
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "tenantId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "startAt",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "endAt",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "type",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "params",
    "type": "Json",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "endAt",
    "variableName": "endAt"
  },
  {
    "kind": "Variable",
    "name": "params",
    "variableName": "params"
  },
  {
    "kind": "Variable",
    "name": "startAt",
    "variableName": "startAt"
  },
  {
    "kind": "Variable",
    "name": "tenantId",
    "variableName": "tenantId"
  },
  {
    "kind": "Variable",
    "name": "type",
    "variableName": "type"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "uxFields",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "value",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "listMailGptBlastsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "listMailGptBlasts",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "GptHistory",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "title",
            "storageKey": null,
            "args": null,
            "concreteType": "GptHistoryField",
            "plural": false,
            "selections": [
              (v4/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "listMailGptBlastsQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "listMailGptBlasts",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "GptHistory",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "title",
            "storageKey": null,
            "args": null,
            "concreteType": "GptHistoryField",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v2/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "listMailGptBlastsQuery",
    "id": null,
    "text": "query listMailGptBlastsQuery(\n  $tenantId: ID!\n  $startAt: String\n  $endAt: String\n  $type: String\n  $params: Json\n) {\n  listMailGptBlasts(tenantId: $tenantId, startAt: $startAt, endAt: $endAt, type: $type, params: $params) {\n    id\n    uxFields\n    title {\n      value\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4cbadb85dd6b318261a6af79a96778e9';
module.exports = node;
