import React from "react";

export default function IntegrationIcon({ className = "text-[#999999]", width = 18, height = 18 }) {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 14.9997L4.62 13.3789"
        stroke="#999999"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.7993 8.59961L6.6001 9.79961"
        stroke="#999999"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.4002 10.1992L8.2002 11.3992"
        stroke="#999999"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.61995 9.41961C4.09482 9.94474 3.7998 10.657 3.7998 11.3996C3.7998 12.1423 4.09482 12.8545 4.61995 13.3796C5.14508 13.9047 5.8573 14.1998 6.59995 14.1998C7.34259 14.1998 8.05482 13.9047 8.57995 13.3796L9.37995 12.5796L5.39995 8.59961L4.61995 9.41961Z"
        stroke="#999999"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.9999 3L13.3799 4.6208"
        stroke="#999999"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3801 8.58092C13.9052 8.0558 14.2003 7.34357 14.2003 6.60092C14.2003 5.85828 13.9052 5.14605 13.3801 4.62092C12.855 4.0958 12.1428 3.80078 11.4001 3.80078C10.6575 3.80078 9.94525 4.0958 9.42012 4.62092L8.62012 5.42092L12.6001 9.40092L13.3801 8.58092Z"
        stroke="#999999"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
