/**
 * @flow
 * @relayHash 19f06132004dbb975ad389de80f0d36c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getSuborgDetailsQueryVariables = {|
  id?: ?string
|};
export type getSuborgDetailsQueryResponse = {|
  +authorizedSuborgs: $ReadOnlyArray<{|
    +id: string,
    +name: string,
    +org: {|
      +id: string,
      +name: string,
    |},
  |}>
|};
export type getSuborgDetailsQuery = {|
  variables: getSuborgDetailsQueryVariables,
  response: getSuborgDetailsQueryResponse,
|};
*/


/*
query getSuborgDetailsQuery(
  $id: String
) {
  authorizedSuborgs(orgId: "any", first: 1, propertyId: $id) {
    id
    name
    org {
      id
      name
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "String",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "authorizedSuborgs",
    "storageKey": null,
    "args": [
      {
        "kind": "Literal",
        "name": "first",
        "value": 1
      },
      {
        "kind": "Literal",
        "name": "orgId",
        "value": "any"
      },
      {
        "kind": "Variable",
        "name": "propertyId",
        "variableName": "id"
      }
    ],
    "concreteType": "Suborg",
    "plural": true,
    "selections": [
      (v1/*: any*/),
      (v2/*: any*/),
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/)
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getSuborgDetailsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "getSuborgDetailsQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "getSuborgDetailsQuery",
    "id": null,
    "text": "query getSuborgDetailsQuery(\n  $id: String\n) {\n  authorizedSuborgs(orgId: \"any\", first: 1, propertyId: $id) {\n    id\n    name\n    org {\n      id\n      name\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'dfbcb6c0ad47b2a83643431c51f4faf3';
module.exports = node;
