/**
 * @flow
 * @relayHash 1f673a26525be0e849b996af2f34a25e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type testEmailInput = {|
  clientMutationId?: ?string,
  id: string,
  recipients: string,
  subject: string,
  bodyHtml: string,
  from?: ?string,
  cc?: ?string,
  bcc?: ?string,
  reply_to?: ?string,
  attachments?: ?string,
  preheader?: ?string,
  prefix?: ?string,
  comment?: ?string,
|};
export type testEblastMutationVariables = {|
  input: testEmailInput
|};
export type testEblastMutationResponse = {|
  +testEmail: ?{|
    +hyblast: ?{|
      +id: string,
      +name: string,
      +editorStatus: string,
      +approvalStatus: ?string,
      +mmsStatus: string,
    |}
  |}
|};
export type testEblastMutation = {|
  variables: testEblastMutationVariables,
  response: testEblastMutationResponse,
|};
*/


/*
mutation testEblastMutation(
  $input: testEmailInput!
) {
  testEmail(input: $input) {
    hyblast {
      id
      name
      editorStatus
      approvalStatus
      mmsStatus
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "testEmailInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "testEmail",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "testEmailPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "hyblast",
        "storageKey": null,
        "args": null,
        "concreteType": "Hyblast",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "editorStatus",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "approvalStatus",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "mmsStatus",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "testEblastMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "testEblastMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "testEblastMutation",
    "id": null,
    "text": "mutation testEblastMutation(\n  $input: testEmailInput!\n) {\n  testEmail(input: $input) {\n    hyblast {\n      id\n      name\n      editorStatus\n      approvalStatus\n      mmsStatus\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'dd1c1e3d78194089c9c9dc7b84307c20';
module.exports = node;
