import * as React from "react";
import withStyles from "@mui/styles/withStyles";
import Icon from "storybookComponents/Icon";
import { drawerBodyForMenuStyles as styles } from "../styles";
import { EmailReport, ExportReport, ScheduleReport } from "./ExportComponent";
import { combineLists } from "content/utils/commonParsing";

const DrawerBody = ({ classes, title, onClose, showNotification, id, ...props }) => {
  const [scheduledEmails, setScheduledEmails] = React.useState(false);
  return (
    <div className={scheduledEmails ? classes.superDrawer : classes.super}>
      <div className={classes.flexBox}>
        {title}
        <div className={classes.closeIcon}>
          <Icon onClick={onClose} type="icon-e-remove" size="lg" />
        </div>
      </div>
      <div className={scheduledEmails ? classes.drawerBodyContainer : classes.bodyContainer}>
        {title === "Email Report" ? (
          <EmailReport
            classes={classes}
            onClose={onClose}
            showNotification={showNotification}
            propertyIds={combineLists(props.selectedProperties)}
            {...props}
          />
        ) : null}
        {title === "Schedule Email" ? (
          <ScheduleReport
            classes={classes}
            onClose={onClose}
            showNotification={showNotification}
            setScheduledEmails={setScheduledEmails}
            {...props}
          />
        ) : null}
        {title === "Export Report" ? (
          <ExportReport
            classes={classes}
            onClose={onClose}
            id={id}
            showNotification={showNotification}
            {...props}
          />
        ) : null}

        {
          //Duplicate Report
        }
      </div>
    </div>
  );
};

export default withStyles(styles)(DrawerBody);
