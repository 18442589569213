import React from "react";
import PropTypes from "prop-types";

import withStyles from "@mui/styles/withStyles";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";

import Icon from "storybookComponents/Icon";
import { SimpleLoader as Loader } from "../../Loader";

//import SearchList from './local_seach_list';

const loader_styles = (theme) => ({
  container: {
    marginTop: 10,
  },
});

const LoaderContainer = React.memo(
  withStyles(loader_styles)(({ classes, Container, input_props }) => (
    <Container handleChange={null} search_value={""} input_props={input_props}>
      <div className={classes.container}>
        <Loader size={30} />
      </div>
    </Container>
  ))
);

const SearchComponent = React.memo(
  ({
    Container,
    ListComponent,
    handleChange,
    suggestions,
    cancel,
    search_value,
    input_props,
    opened_list,
    handle_select,
    ...props
  }) => {
    return (
      <Container
        handleChange={handleChange}
        search_value={(props.selected_value && props.selected_value.name) || search_value}
        input_props={input_props}
        onSelect={handle_select}
        {...props}
      >
        {opened_list ? (
          <ListComponent
            {...props}
            cancel={cancel}
            search_value={search_value}
            suggestions={suggestions}
            onSelect={handle_select}
            handle_select={handle_select}
          />
        ) : null}
      </Container>
    );
  }
);

const SuperficialSearch = ({
  show_on_load,
  suggestions,
  input_props,
  ListComponent,
  Container,
  open: open_list,
  initial_open,
  onSelect,
  cancel,
  forceSetList,
  ...props
}) => {
  const [autocomplete_map] = React.useState(() => new Map());

  const [autocomplete_list, set_autocomplete_list] = React.useState([]);

  const [state, setState] = React.useState({
    search_value: "",
    opened: initial_open,
    loading: true,
  });

  React.useEffect(() => {
    set_autocomplete_list(suggestions);
    autocomplete_map.clear();
    autocomplete_map.set("", suggestions);
    setState({ opened: state.opened, search_value: "", loading: false });
  }, [suggestions]);

  const createNode = React.useCallback((treeNode, value) => {
    return treeNode && treeNode.filter((o) => new RegExp(value, "gi").test(o.name));
  });

  const setNode = React.useCallback((treeNode, value) => {
    autocomplete_map.set(treeNode, value);
    return autocomplete_map.get(treeNode);
  });

  /* set_map */

  const recursive_set_map = React.useCallback((value) => {
    if (!value) {
      return;
    }

    let last_phrase = value.slice(0, value.length - 1);

    const treeNode = autocomplete_map.get(last_phrase);

    if (treeNode) {
      return setNode(value, createNode(treeNode, value));
    }

    return setNode(value, createNode(recursive_set_map(last_phrase), value));
  });

  /* handleChange */
  const handleChange = React.useCallback(({ target: { value } }) => {
    setState({ selected_value: null, search_value: value, opened: true });

    if (props.handleChange) {
      props.handleChange(value);
    }

    if (autocomplete_map.get(value)) {
      set_autocomplete_list(autocomplete_map.get(value));
    } else {
      recursive_set_map(value);
      set_autocomplete_list(autocomplete_map.get(value));
    }
  });

  React.useEffect(() => {
    if (Array.isArray(forceSetList)) {
      set_autocomplete_list(forceSetList);
    }
  }, [forceSetList]);

  const cancel_function = React.useCallback((e) => {
    setState({ search_value: "", opened: false });
    if (typeof cancel === "function") {
      cancel(e);
    }
  });

  const handle_select = React.useCallback((e, item) => {
    if (typeof onSelect === "function") {
      onSelect(e, item);
    }
  });

  const { search_value, opened, loading } = state;

  const opened_list = React.useMemo(() => open_list && opened, [open_list, opened]);

  if (show_on_load && (props.loading || loading)) {
    return <LoaderContainer Container={Container} input_props={input_props} />;
  }

  return (
    <SearchComponent
      {...props}
      Container={Container}
      ListComponent={ListComponent}
      handleChange={handleChange}
      handle_select={handle_select}
      suggestions={autocomplete_list}
      cancel={cancel_function}
      search_value={search_value}
      input_props={input_props}
      opened_list={opened_list}
    />
  );
};

SuperficialSearch.propTypes = {
  suggestions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  Container: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  ListComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  SearchInput: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  open: PropTypes.bool, // set for controlled components
  initial_open: PropTypes.bool, // additional flexibility
};

SuperficialSearch.defaultProps = {
  open: true,
  initial_open: false,
};
/*
SuperficialSearch.defaultProps = {
  ListComponent: ListComponent,
  Container: SearchListContainer,
}*/

export default React.memo(SuperficialSearch);
