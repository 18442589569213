import graphql from "babel-plugin-relay/macro";

export default graphql`
  query getDripEditorNewQuery($id: ID!) {
    thenEmailEditorNew(id: $id) {
      angel {
        name
      }
      name
      subject
      active
      mms
      mmsBody
      mmsMediaUrl
      mmsStatus
      lastTestEmails
      lastTestPhones
      fields {
        editable
        label
        field
        dimension
        value
        type
        display
        sliceId
        sliceName
        elementId
        elementPart
      }
      rawMacrosHtml
      substitutedMacrosHtml
    }
  }
`;
