export default () => ({
  accountIcon: {
    height: "40px",
    width: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "16px",
    color: "rgba(51, 51, 51, 1)",
    fontWeight: 600,
    background: "rgba(249, 249, 249, 1)",
    borderRadius: "20px",
  },
  iconRoot: {
    "&:hover": {
      background: "white",
    },
    "&:hover div": {
      color: "var(--hylyTheme)",
    },
  },
  menuroot: {
    boxShadow: "2px 2px 15px rgba(85, 85, 85, 0.05)",
    background: "white",
    padding: 0,
    width: "200px",
    height: "152px",
    border: "1px solid #DADADA",
    borderRadius: "0px 0px 5px 5px",
  },
  list: {
    padding: 0,
  },
  listbtn: {
    height: 48,
    padding: "12px",
    cursor: "pointer",
    display: "flex",
    "&:hover": {
      backgroundColor: "#ffffff00",
      color: "#26BBED",
    },
    "&:hover div": {
      color: "#26BBED",
    },
    "&:hover path": {
      stroke: "#26BBED",
    },
  },
  listItem: {
    paddingLeft: 8,
    fontSize: 16,
    lineHeight: "24px",
    fontFamily: "Open Sans",
  },
  loaderContainer: {
    height: "40px",
    width: "40px",
    position: "relative",
    "& circle": {
      r: 15,
      strokeWidth: 2.5,
    },
  },
});
