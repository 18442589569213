/**
 * @flow
 * @relayHash 398bfa0f9e38f2a62d3dab8dd8f23ef6
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getFairHousingActQueryVariables = {|
  orgId: string
|};
export type getFairHousingActQueryResponse = {|
  +getFairHousingAct: ?string
|};
export type getFairHousingActQuery = {|
  variables: getFairHousingActQueryVariables,
  response: getFairHousingActQueryResponse,
|};
*/


/*
query getFairHousingActQuery(
  $orgId: ID!
) {
  getFairHousingAct(orgId: $orgId)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "orgId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "getFairHousingAct",
    "args": [
      {
        "kind": "Variable",
        "name": "orgId",
        "variableName": "orgId"
      }
    ],
    "storageKey": null
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getFairHousingActQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "getFairHousingActQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "getFairHousingActQuery",
    "id": null,
    "text": "query getFairHousingActQuery(\n  $orgId: ID!\n) {\n  getFairHousingAct(orgId: $orgId)\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '683dd1b0eceaa7825462d66d4ca81643';
module.exports = node;
