import React from "react";

export default function ReactivateIcon({ className = "text-[#26BBED]", size = 24 }) {
  return (
    <svg
      className={className}
      width={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.6836 5.06689L18.7293 10.0452L13.9824 8.45698"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.02344 11.4481C5.15244 9.81591 5.84962 8.2806 6.99366 7.10933C8.1377 5.93807 9.65619 5.20498 11.2849 5.03764C12.9136 4.8703 14.5494 5.27929 15.9078 6.19345C17.2661 7.10761 18.2609 8.46907 18.7191 10.0409"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.31721 18.9348L5.27148 13.9565L10.0183 15.5447"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.9779 12.5532C18.8489 14.1854 18.1517 15.7207 17.0076 16.892C15.8636 18.0633 14.3451 18.7963 12.7164 18.9637C11.0877 19.131 9.45186 18.722 8.09355 17.8079C6.73524 16.8937 5.74043 15.5323 5.28223 13.9604"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
