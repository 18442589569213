import React from "react";

function Icon({ color }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none" viewBox="0 0 14 14">
      <g
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeOpacity="0.5"
        clipPath="url(#clip0_4120_8145)"
      >
        <path d="M.438 2.188h1.75M.438 5.688h1.75M.438 9.188h1.75M.438 12.688h1.75M4.813 2.188h8.75M4.813 5.688h5.25M4.813 9.188h8.75M4.813 12.688h4.375"></path>
      </g>
      <defs>
        <clipPath id="clip0_4120_8145">
          <path fill="#fff" d="M0 0H14V14H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon;
