/**
 * @flow
 * @relayHash c8c41a9fd07b156203d2a93d51f30264
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getTenantOrCampaignStatTenantQueryVariables = {|
  dashboardId: string,
  params?: ?any,
|};
export type getTenantOrCampaignStatTenantQueryResponse = {|
  +tenantOrCampaignStat: {|
    +tenantStat: ?any
  |}
|};
export type getTenantOrCampaignStatTenantQuery = {|
  variables: getTenantOrCampaignStatTenantQueryVariables,
  response: getTenantOrCampaignStatTenantQueryResponse,
|};
*/


/*
query getTenantOrCampaignStatTenantQuery(
  $dashboardId: ID!
  $params: Json
) {
  tenantOrCampaignStat(dashboardId: $dashboardId, params: $params) {
    tenantStat
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "dashboardId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "params",
    "type": "Json",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "dashboardId",
    "variableName": "dashboardId"
  },
  {
    "kind": "Variable",
    "name": "params",
    "variableName": "params"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "tenantStat",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getTenantOrCampaignStatTenantQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "tenantOrCampaignStat",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "TenantOrCampaignStat",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "getTenantOrCampaignStatTenantQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "tenantOrCampaignStat",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "TenantOrCampaignStat",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "getTenantOrCampaignStatTenantQuery",
    "id": null,
    "text": "query getTenantOrCampaignStatTenantQuery(\n  $dashboardId: ID!\n  $params: Json\n) {\n  tenantOrCampaignStat(dashboardId: $dashboardId, params: $params) {\n    tenantStat\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0d0a03033bf64eb93561734c7833e3a3';
module.exports = node;
