/**
 * @flow
 * @relayHash 42a07d46167d5eb910f5680320ac3ca3
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getDripPreviewQueryVariables = {|
  angelId: string,
  id: string,
|};
export type getDripPreviewQueryResponse = {|
  +thenEmailEditor: {|
    +substitutedMacrosHtml: string
  |}
|};
export type getDripPreviewQuery = {|
  variables: getDripPreviewQueryVariables,
  response: getDripPreviewQueryResponse,
|};
*/


/*
query getDripPreviewQuery(
  $angelId: ID!
  $id: ID!
) {
  thenEmailEditor(angelId: $angelId, id: $id) {
    substitutedMacrosHtml
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "angelId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "angelId",
    "variableName": "angelId"
  },
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "substitutedMacrosHtml",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getDripPreviewQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "thenEmailEditor",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ThenEmailEditor",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "getDripPreviewQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "thenEmailEditor",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ThenEmailEditor",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "getDripPreviewQuery",
    "id": null,
    "text": "query getDripPreviewQuery(\n  $angelId: ID!\n  $id: ID!\n) {\n  thenEmailEditor(angelId: $angelId, id: $id) {\n    substitutedMacrosHtml\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '073073b99a115a94249ba4be44d5cd7f';
module.exports = node;
