import graphql from "babel-plugin-relay/macro";

export default graphql`
  query getOffersQuery($id: ID!, $before: String, $last: Int) {
    offers(orgId: $id, before: $before, last: $last) {
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          title
          active
          propertyCount
          campaignTitle
        }
      }
    }
  }
`;
