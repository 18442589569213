/**
 * @flow
 * @relayHash 0d90531b9d2951bca5a481b5e53c509d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getPlatformsReportsQueryVariables = {|
  dashboardId: string,
  params?: ?any,
|};
export type getPlatformsReportsQueryResponse = {|
  +platformStatsDashboard: {|
    +browserSeries: ?string,
    +deviceSeries: ?string,
    +id: string,
    +osSeries: ?string,
    +total: ?string,
  |}
|};
export type getPlatformsReportsQuery = {|
  variables: getPlatformsReportsQueryVariables,
  response: getPlatformsReportsQueryResponse,
|};
*/


/*
query getPlatformsReportsQuery(
  $dashboardId: ID!
  $params: Json
) {
  platformStatsDashboard(dashboardId: $dashboardId, params: $params) {
    browserSeries
    deviceSeries
    id
    osSeries
    total
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "dashboardId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "params",
    "type": "Json",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "platformStatsDashboard",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "dashboardId",
        "variableName": "dashboardId"
      },
      {
        "kind": "Variable",
        "name": "params",
        "variableName": "params"
      }
    ],
    "concreteType": "PlatformStatsDashboard",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "browserSeries",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "deviceSeries",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "osSeries",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "total",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getPlatformsReportsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "getPlatformsReportsQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "getPlatformsReportsQuery",
    "id": null,
    "text": "query getPlatformsReportsQuery(\n  $dashboardId: ID!\n  $params: Json\n) {\n  platformStatsDashboard(dashboardId: $dashboardId, params: $params) {\n    browserSeries\n    deviceSeries\n    id\n    osSeries\n    total\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4f10c92f32871930f782ee80350e952d';
module.exports = node;
