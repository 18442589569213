import React from "react";

export default function UserConnectIcon({ className = "text-[#333333]", width = 24, height = 24 }) {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0803 12.6138C9.34744 12.6138 7.81028 13.0053 6.80023 13.3458C6.05466 13.598 5.55762 14.3007 5.55762 15.088V17.5229C5.55762 17.5229 11.6682 17.5229 11.694 17.5229"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.0804 12.6136C9.38614 12.6136 8.01221 10.6261 8.01221 8.93182V8.31818C8.01221 6.62393 9.38614 5.25 11.0804 5.25C12.7746 5.25 14.1486 6.62393 14.1486 8.31818V8.93182C14.1486 10.6261 12.7746 12.6136 11.0804 12.6136Z"
        fill="white"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.375 17.125H13.875V18.625H15.375V17.125Z"
        fill="white"
        stroke="currentColor"
        stroke-width="0.75"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.625 14.625C15.0392 14.625 15.375 14.2892 15.375 13.875C15.375 13.4608 15.0392 13.125 14.625 13.125C14.2108 13.125 13.875 13.4608 13.875 13.875C13.875 14.2892 14.2108 14.625 14.625 14.625Z"
        fill="white"
        stroke="currentColor"
        stroke-width="0.75"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.375 13.875H18.375C18.9273 13.875 19.375 14.3227 19.375 14.875V16.875C19.375 17.4273 18.9273 17.875 18.375 17.875H16.375"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
