import graphql from "babel-plugin-relay/macro";

export default graphql`
  query mailGptBlastTypeReportsQuery(
    $orgIds: [String!]
    $allOrgs: Boolean
    $startAt: String
    $endAt: String
    $propertyIds: [String!]
    $blastTypes: [String!]
    $promptTemplates: [String!]
  ) {
    mailGptBlastTypeReports(
      endAt: $endAt
      startAt: $startAt
      allOrgs: $allOrgs
      orgIds: $orgIds
      propertyIds: $propertyIds
      blastTypes: $blastTypes
      promptTemplates: $promptTemplates
    )
  }
`;
