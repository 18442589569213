import SvgIcon from "@mui/material/SvgIcon";

export const PremiumIcon = () => (
  <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.5 0.5H13.5L15.5 4.5L8 13.5L0.5 4.5L2.5 0.5Z"
      stroke="#26BBED"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.5 4.5H12.5"
      stroke="#26BBED"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const UnAuthIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.6667 10.667H7.33333C6.59695 10.667 6 11.2639 6 12.0003V17.3337C6 18.07 6.59695 18.667 7.33333 18.667H16.6667C17.403 18.667 18 18.07 18 17.3337V12.0003C18 11.2639 17.403 10.667 16.6667 10.667Z"
      stroke="#999999"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.9998 16.0007C12.7362 16.0007 13.3332 15.4037 13.3332 14.6673C13.3332 13.9309 12.7362 13.334 11.9998 13.334C11.2635 13.334 10.6665 13.9309 10.6665 14.6673C10.6665 15.4037 11.2635 16.0007 11.9998 16.0007Z"
      stroke="#999999"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.3332 10.5V8.3335C15.3419 7.45832 15.0026 6.61552 14.39 5.99043C13.7775 5.36533 12.9417 5.00914 12.0665 5.00016H11.9998C11.1247 4.99146 10.2819 5.3307 9.65677 5.94329C9.03167 6.55588 8.67548 7.39166 8.6665 8.26683V10.5"
      stroke="#999999"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const DownArrowIcon = (props) => {
  return (
    <SvgIcon
      viewBox="0 0 24 24"
      style={{ width: 24, height: 24, stroke: "#333", fill: "none" }}
      {...props}
    >
      <path d="M18 14.3999L12 8.3999L6 14.3999" strokeMiterlimit="10" strokeLinecap="square" />
    </SvgIcon>
  );
};

export const DotIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 4 4" style={{ width: "4", height: "4", fill: "none" }} {...props}>
      <circle cx="2" cy="2" r="2" fill="#999999" />
    </SvgIcon>
  );
};
