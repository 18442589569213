/**
 * @flow
 * @relayHash 5002e43189822295df0255dd7ab9fdd9
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getAlertsEmailsQueryVariables = {|
  orgId: string,
  propertyId?: ?string,
  first?: ?number,
|};
export type getAlertsEmailsQueryResponse = {|
  +authorizedSuborgs: $ReadOnlyArray<{|
    +id: string,
    +name: string,
    +alerts: $ReadOnlyArray<{|
      +id: string,
      +name: ?string,
      +active: ?boolean,
      +property: ?{|
        +id: string,
        +name: string,
      |},
      +thenEmails: ?$ReadOnlyArray<{|
        +id: string,
        +name: string,
        +active: boolean,
        +mms: boolean,
      |}>,
    |}>,
  |}>
|};
export type getAlertsEmailsQuery = {|
  variables: getAlertsEmailsQueryVariables,
  response: getAlertsEmailsQueryResponse,
|};
*/


/*
query getAlertsEmailsQuery(
  $orgId: ID!
  $propertyId: String
  $first: Int = 1000
) {
  authorizedSuborgs(orgId: $orgId, propertyId: $propertyId, first: $first) {
    id
    name
    alerts {
      id
      name
      active
      property {
        id
        name
      }
      thenEmails {
        id
        name
        active
        mms
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "orgId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "propertyId",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": 1000
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "active",
  "args": null,
  "storageKey": null
},
v4 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "authorizedSuborgs",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
      },
      {
        "kind": "Variable",
        "name": "orgId",
        "variableName": "orgId"
      },
      {
        "kind": "Variable",
        "name": "propertyId",
        "variableName": "propertyId"
      }
    ],
    "concreteType": "Suborg",
    "plural": true,
    "selections": [
      (v1/*: any*/),
      (v2/*: any*/),
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "alerts",
        "storageKey": null,
        "args": null,
        "concreteType": "Angel",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "property",
            "storageKey": null,
            "args": null,
            "concreteType": "Suborg",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/)
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "thenEmails",
            "storageKey": null,
            "args": null,
            "concreteType": "ThenEmail",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "mms",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getAlertsEmailsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v4/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "getAlertsEmailsQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v4/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "getAlertsEmailsQuery",
    "id": null,
    "text": "query getAlertsEmailsQuery(\n  $orgId: ID!\n  $propertyId: String\n  $first: Int = 1000\n) {\n  authorizedSuborgs(orgId: $orgId, propertyId: $propertyId, first: $first) {\n    id\n    name\n    alerts {\n      id\n      name\n      active\n      property {\n        id\n        name\n      }\n      thenEmails {\n        id\n        name\n        active\n        mms\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'fc7e7501d4035c48d6560cf94ef97a66';
module.exports = node;
