import React from "react";

export default function RunIcon(props) {
  return (
    <svg
      className={props.className}
      width={props.width}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.4041 9.09497L14.42 12.1109L16.8328 10.3014"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.8017 6.68216L9.59528 5.47577L7.1825 6.07896"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.8015 12.7142L12.6111 14.5238L8.99194 16.3333"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.0231 7.28549C16.0225 7.28549 16.8327 6.47531 16.8327 5.47591C16.8327 4.4765 16.0225 3.66632 15.0231 3.66632C14.0237 3.66632 13.2135 4.4765 13.2135 5.47591C13.2135 6.47531 14.0237 7.28549 15.0231 7.28549Z"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.16632 16.3335L13.7445 6.75537"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
