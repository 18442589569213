import graphql from "babel-plugin-relay/macro";

export default graphql`
  query getApprovalRequestsQuery(
    $orgId: ID!
    $type: String
    $propertyId: ID
    $first: Int
    $after: String
  ) {
    approvalRequests(
      orgId: $orgId
      type: $type
      propertyId: $propertyId
      first: $first
      after: $after
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          propertyId
          approvedAt
          approvedBy {
            email
          }
          disapprovedBy
          disapprovedAt
          agentEmail
          approvalFeedbacks {
            id
            userEmail
            message
            createdAt
          }
          approverEmails
          propertyName
          eblastId
          eblastStatus
          approved
          eblastName
          mailGpt
          eblastSubject
          scheduledTime
          feedback
          createdAt
          action
          hyevent {
            id
            title
            status
            propertyId
            publishAt
          }
          createdAt
        }
      }
    }
  }
`;
