import React from "react";

export default function NoShowIcon({ className = "text-[#999999]", width = 18, height = 18 }) {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.75 9.75C7.338 9.75 6.0855 10.069 5.2625 10.3465C4.655 10.552 4.25 11.1245 4.25 11.766V13.75C4.25 13.75 9.229 13.75 9.25 13.75"
        stroke="currentColor"
        stroke-miterlimit="10"
      />
      <path
        d="M8.75 9.75C7.3695 9.75 6.25 8.1305 6.25 6.75V6.25C6.25 4.8695 7.3695 3.75 8.75 3.75C10.1305 3.75 11.25 4.8695 11.25 6.25V6.75C11.25 8.1305 10.1305 9.75 8.75 9.75Z"
        stroke="currentColor"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
      <path
        d="M14.25 11.25L11.25 14.25"
        stroke="currentColor"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
      <path
        d="M11.25 11.25L14.25 14.25"
        stroke="currentColor"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
    </svg>
  );
}
