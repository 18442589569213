import graphql from "babel-plugin-relay/macro";

export default graphql`
  query getAssetCategoryIdQuery($propertyId: ID!, $type: String) {
    categories(propertyId: $propertyId, type: $type) {
      id
      name
      type
    }
  }
`;
