import graphql from "babel-plugin-relay/macro";

export default graphql`
  mutation toggleTourSchedularMutation($input: toggleTourSchedularInput!) {
    toggleTourSchedular(input: $input) {
      response {
        tourScheduleEnabled
      }
    }
  }
`;
