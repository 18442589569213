import React from "react";
import { Popover } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { useSelector } from "react-redux";

import "./index.css";
import { styles } from "./style";
import Label from "storybookComponents/InputField/CommonComponents/Label";
import Dot from "storybookComponents/InputField/CommonComponents/DotComponent";
import { Button, Icon } from "storybookComponents";
import { ExternalLinkIcon, DualLinkIcon, PrivacyIcon, InfoIcon } from "icons";
import { selectMacrosList } from "redux/slices/drip-slice";
import { strip_html_tags } from "storybookComponents/utils";
import Emitter from "storybookComponents/Calendar/emitter";

const propertyMacros = [
  "Property Name",
  "Sub-org Name",
  "Property URL",
  "Property Email",
  "Reply To",
  "Time Zone",
  "Phone",
  "Sub-org Email",
];

const MacroEditableField = ({
  classes,
  fieldValue,
  handleUpdateMacro,
  label,
  labelPosition,
  macrosList,
  onChange,
  pre,
  subOrgsUrl,
  type,
  userAccess,
}) => {
  const allMacrosList = useSelector(selectMacrosList);

  const subjectRef = React.useRef(null);
  const anchorEl = React.useRef(null);
  const [open, setOpen] = React.useState(null);
  const [dotId, setDotId] = React.useState("");
  const [propertyInfo, setPropertyInfo] = React.useState("");
  const [tooltipText, setTooltipText] = React.useState("");
  const [inputLabel, setInputLabel] = React.useState("");

  const [focused, setFocused] = React.useState(false);
  const [propertyMacroCheck, setPropertyMacroCheck] = React.useState(null);

  const append_hash = (str) => {
    if (str === null || str === "") return false;

    var tempDiv = document.createElement("div");
    tempDiv.innerHTML = str;

    var spanElementsToModify = tempDiv.querySelectorAll('span[contenteditable="true"]');
    spanElementsToModify.forEach((spanElement) => {
      spanElement.innerHTML = "#" + spanElement.innerText.replace("\u200B", "");
    });

    return tempDiv.innerHTML;
  };

  const handleBlur = (e) => {
    if (!subjectRef.current) {
      return null;
    }
    setFocused(false);
    const updatedContent = subjectRef.current.root.innerHTML;
    var tempDiv = document.createElement("div");
    tempDiv.innerHTML = updatedContent;
    var spansWithHylyMacroKey = tempDiv.querySelectorAll("span[hyly-macro-key]");
    var spanArray = Array.from(spansWithHylyMacroKey);
    var spanTooltip = tempDiv.querySelectorAll("span[class=editor-tooltip-text]");

    spanTooltip.forEach((element) => {
      tempDiv.remove(element);
    });

    for (let i = 0; i < spanArray.length; i++) {
      var span = spanArray[i];
      var macroLabel = span.getAttribute("hyly-macro-key").split("____")[1];
      span.textContent = `{%${macroLabel}%}`;
    }

    const vals = { target: { value: strip_html_tags(append_hash(tempDiv.innerHTML)) } };

    onChange(vals);
  };

  const handleFocus = () => {
    if (dotId !== "interacted") {
      setDotId("interacted");
    }
    setFocused(true);
  };

  window.parent.handleRemoveMacrosSub = function removeMacro(data) {
    const tag = document.querySelectorAll('[hyly-macro-key="' + data.data + '"]');
    const removeAbleTag = Array.from(tag).filter((item) => item.id.split("_")[1] === data.id);

    removeAbleTag[0].removeAttribute("class");
    removeAbleTag[0].removeAttribute("hyly-macro-key");
    removeAbleTag[0].setAttribute("contenteditable", "true");
    if (removeAbleTag[0].firstElementChild) {
      removeAbleTag[0].removeChild(removeAbleTag[0].firstElementChild);
    }
    subjectRef.current.focus();
  };

  const getQuillEditor = (list) => {
    const subjectField = document.getElementById("subjectEdit");
    var Parchment = window.Quill.import("parchment");
    var disabledAttr = new Parchment.Attributor.Attribute("contenteditable", "contenteditable", {
      scope: Parchment.Scope.INLINE,
    });
    window.Quill.register(disabledAttr);

    const customAttribute = new Parchment.Attributor.Attribute("hyly-macro-key", "hyly-macro-key", {
      scope: Parchment.Scope.INLINE,
    });
    window.Quill.register(customAttribute);

    const classAttr = new Parchment.Attributor.Attribute("class-attribute", "class", {
      scope: Parchment.Scope.INLINE,
    });
    window.Quill.register(classAttr);

    const IDAttr = new Parchment.Attributor.Attribute("id", "id", {
      scope: Parchment.Scope.INLINE,
    });
    window.Quill.register(IDAttr);

    if (subjectField) {
      const subjectEditor = new window.Quill("#subjectEdit", {
        theme: "snow",
      });

      const pattern = /{%([^%]+)%}/gm;
      const matches = Array.from(fieldValue.matchAll(pattern));

      // if (matches.length > 0 && Object.keys(list).length > 0) {
      if (Object.keys(list).length > 0) {
        let processedValue = fieldValue;
        const hashRegex = /\{#([^}]+)\}/g;
        let match;

        while ((match = hashRegex.exec(fieldValue)) !== null) {
          const matchedText = match[0];
          const hashText = match[1];

          processedValue = processedValue.replace(
            matchedText,
            `<span contenteditable="false">{</span><span contenteditable="true">${hashText}</span><span contenteditable="false">}</span>`
          );
        }
        for (let i = 0; i < matches.length; i++) {
          let matchChild = matches[i];
          let macrosVal = Object.values(list).filter((item) => item.name === matchChild[0])[0];
          if (macrosVal) {
            const regex = new RegExp(`{%${matchChild[1]}%}`, "g");
            let propMacroCheck = propertyMacros.includes(macrosVal.label);

            processedValue = processedValue.replace(regex, (matchedText, offset) => {
              return `<span id='subjectMacro_${offset}' class='macro-listener ${
                !propMacroCheck &&
                userAccess &&
                !(macrosList[macrosVal.label]?.group == "Contact") &&
                "non-property-macro"
              }' hyly-macro-key='${macrosVal.label}____${matchChild[1]}' contentEditable='false'>${
                macrosVal.value
              }</span>&#8203;`;
            });
          }
        }

        // Update the content of your HTML element here
        subjectEditor.clipboard.dangerouslyPasteHTML(processedValue);
        subjectEditor.blur();
      }

      // Add an input event listener to the Quill editor
      subjectEditor.root.addEventListener("keydown", function (e) {
        subjectEditor.focus();
        if (e.key === "{") {
          e.preventDefault();
          handleMacrosAdd(e.key);
        }
      });

      subjectRef.current = subjectEditor;
      addMacroListeners();

      subjectRef.current.keyboard.addBinding({
        key: "Backspace",
        handler: handleMacrosTags,
      });
      subjectRef.current.keyboard.addBinding({
        key: "Delete",
        handler: (e, ev) => {
          handleMacrosTags(e, ev, true);
        },
      });
    }
  };

  const getMacroLabel = (e) => {
    let macroLabel = e.target.getAttribute("hyly-macro-key").split("__")[0];
    setPropertyMacroCheck(propertyMacros.includes(macroLabel));
  };

  const handleMacrosAdd = (text) => {
    const quill = subjectRef.current;
    const selection = quill.getSelection();
    const index = selection ? selection.index : 0;
    var clipboard = quill.getModule("clipboard");
    clipboard.dangerouslyPasteHTML(
      index,
      '<span contentEditable="false">' +
        text +
        '</span><span contentEditable="true">&#8203;</span>',
      "api"
    );
  };

  const handleMacrosTags = (e, ev, test) => {
    if (ev.format["hyly-macro-key"]) {
      return window.parent.handleMacrosActionsTriggre(
        ev.format["hyly-macro-key"],
        macrosList[ev.format["hyly-macro-key"].split("____")[0]],
        ev.format.id.split("_")[1]
      );
    } else {
      subjectRef.current.deleteText(test ? e.index : e.index - 1, 1);
    }
  };

  const handleClick = (event) => {
    anchorEl.current = event.currentTarget;
    getMacroLabel(event);
    const key = event.target.getAttribute("hyly-macro-key").split("____")[0];
    setInputLabel(key);
    let value = event.target.innerText.replace("Edit Property Info", "");
    setPropertyInfo({ text: value, value: value });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    anchorEl.current = null;
  };

  const addMacroListeners = () => {
    let macroEditTags = subjectRef.current.root.querySelectorAll("[hyly-macro-key]");
    if (macroEditTags.length > 0) {
      for (let i = 0; i < macroEditTags.length; i++) {
        const key = macroEditTags[i].getAttribute("hyly-macro-key").split("____")[0];
        macrosList[key]?.group === "Contact"
          ? setTooltipText("Contact Info")
          : setTooltipText("Edit Property Info");
        const tooltip = document.createElement("span");
        tooltip.innerText =
          macrosList[key]?.group === "Contact" ? "Contact Info" : "Edit Property Info";
        tooltip.className = "editor-tooltip-text";
        const tooltipChild = document.createElement("span");
        tooltipChild.innerText = "Cannot be edited";
        tooltipChild.className = "tooltip-children";
        macroEditTags[i].appendChild(tooltip);
        if (macrosList[key]?.group === "Contact") {
          tooltip.appendChild(tooltipChild);
        }

        if (!(macrosList[key]?.group === "Contact")) {
          macroEditTags[i].addEventListener("click", function refrenceFuntion(e) {
            if (e.target.attributes["hyly-macro-key"]) {
              e.stopPropagation();
              handleClick(e);
            } else {
              macroEditTags[i].removeEventListener("click", refrenceFuntion);
            }
          });
        }
      }
    }
  };

  const changeMacrosVal = () => {
    let key = anchorEl.current.getAttribute("hyly-macro-key");
    let escapedKey = key.replace(/([:.\-\[\]])/g, "\\$1");
    let selector = '[hyly-macro-key="' + escapedKey + '"]';
    let macroEditTags = subjectRef.current.root.querySelectorAll(selector);
    macroEditTags.forEach((element) => {
      element.textContent = propertyInfo.value;
    });
  };

  const resetField = () => {
    setPropertyInfo((pre) => ({ text: pre.value, value: pre.value }));
    changeMacrosVal();
  };

  const redirectToSuborgs = () => {
    window.open(subOrgsUrl, "_blank");
  };

  const handleUpdate = (e) => {
    handleUpdateMacro({ label: inputLabel, value: e.target.value });
    let key = anchorEl.current.getAttribute("hyly-macro-key");
    let escapedKey = key.replace(/([:.\-\[\]])/g, "\\$1");
    let selector = '[hyly-macro-key="' + escapedKey + '"]';
    let macroEditTags = subjectRef.current.root.querySelectorAll(selector);
    macroEditTags.forEach((element) => {
      element.textContent = e.target.value;
    });
  };

  React.useEffect(() => {
    if (fieldValue && Object.keys(allMacrosList).length > 0) {
      getQuillEditor(allMacrosList);
    }
  }, []);

  React.useEffect(() => {
    Emitter.on("SUBJECT-RESET", () => getQuillEditor(allMacrosList));
    return () => {
      Emitter.off("SUBJECT-RESET");
    };
  }, []);

  return (
    <div
      id="hyly-preview-editor"
      className={`${classes.container}  ${labelPosition === "top" && classes.marginEditor}`}
      style={pre && { display: "flex" }}
    >
      <div className={`${labelPosition !== "top" && classes.flexBox}`}>
        <div className={classes.flexLabel}>
          <Dot label={label} dotId={dotId} type={type} setDotId={setDotId} />
          <Label label={label} />
        </div>
      </div>
      <div
        id="macros-editable"
        className={classes.textEditor}
        onBlur={handleBlur}
        onFocus={handleFocus}
      >
        <div id="subjectEdit"></div>
        <div className={classes.bodyPencilIcon}>{!focused && <Icon type="icon-pencil" />}</div>
        {/* POPOVER */}
        <Popover
          classes={{
            paper: classes.popoverPaper,
          }}
          id="subjectMacro"
          open={open}
          anchorEl={anchorEl.current}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <div className={classes.arrowHead}></div>
          <div className={classes.popoverContent}>
            <div className={classes.ctaHeader}>{tooltipText}</div>
            <label className={classes.label}>
              {inputLabel} <DualLinkIcon width="13px" height="12px" stroke="#999999" />
            </label>
            <div className={classes.inputContainer}>
              <input
                onBlur={handleUpdate}
                disabled={propertyMacroCheck || !userAccess}
                value={propertyInfo.text}
                onChange={(e) =>
                  setPropertyInfo((pre) => ({ text: e.target.value, value: pre.value }))
                }
                className={classes.inputStyle}
                type="text"
              ></input>
              {(propertyMacroCheck || !userAccess) && <PrivacyIcon />}
            </div>
            <div className={classes.buttonContainer}>
              <Button
                className={classes.button}
                state="active"
                type="submit"
                variant="secondary"
                size="md"
                onClick={!propertyMacroCheck && userAccess ? resetField : redirectToSuborgs}
              >
                {!propertyMacroCheck && userAccess ? (
                  "Reset"
                ) : (
                  <>
                    <span>Update on Sub-Orgs</span>{" "}
                    <ExternalLinkIcon stroke="#999999" height="14" width="14" />
                  </>
                )}
              </Button>
            </div>
            {!propertyMacroCheck && userAccess && (
              <div className={classes.popupFooterContainer}>
                <div>
                  <InfoIcon />
                </div>
                <div>
                  Updating the text here will also update the property information stored with us in
                  the
                  <span onClick={redirectToSuborgs}>
                    {" "}
                    Sub-Orgs app.
                    <ExternalLinkIcon stroke="#999999" height="14" width="14" />
                  </span>
                </div>
              </div>
            )}
          </div>
        </Popover>
      </div>
    </div>
  );
};

export default withStyles(styles)(MacroEditableField);
