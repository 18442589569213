import React from "react";

export default function EmailSentIcon({ className = "text-[#666666]", width = 18, height = 18 }) {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.7365 9.051L3.375 7.5L14.25 3.75L10.5 14.625L8.25 9.75L10.5 7.5"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
