import React from "react";

function LeftIcon({ className = "text-[#333333]", size = 15 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      className={className}
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M11 2L5 8l6 6"
      ></path>
    </svg>
  );
}

export default LeftIcon;
