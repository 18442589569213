import React from "react";
import { SvgIcon } from "@mui/material";

export default function FlagIcon(props) {
  return (
    <svg
      className={props.className}
      width={props.width}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.4043 4.15459H16.4289V12.171H4.4043"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.4043 8.16267H16.4289"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.4126 4.15459V12.171"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.4204 4.15459V12.171"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.4043 2.81844V17.5151"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
