const styles = {
  headerRoot: {
    height: 60,
    padding: "12px 0px 12px 20px",
    background: "#FFF",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderTop: "1px solid #DADADA",
  },
  select_root: {
    fontSize: 24,
    fontWeight: 400,
    fontFamily: "Open Sans",
    border: "none !important",
    paddingLeft: "0",
    lineHeight: "36px",
  },
  select_menu: {
    fontSize: 16,
    lineHeight: "24px",
    padding: "10px 14px",
  },
  linkIcon: {
    cursor: "pointer !important",
    "&:hover": {
      color: "var(--hylyTheme)",
    },
  },
  headerRightSection: {
    marginLeft: "10px",
    display: "flex",
    alignItems: "center",
    marginRight: "20px",
  },
  favoriteIcon: {
    width: 32,
    height: 32,
  },
  unfavoriteIcon: {
    width: 32,
    height: 32,
    fill: "#26BBED",
    stroke: "#26BBED",
  },
};

export default styles;
