import React from "react";

export default function hyTourIcon({ className = "text-[#999999]", width = 18, height = 18 }) {
  return (
    <svg
      height={height}
      className={className}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3 7H15" stroke="currentColor" stroke-linecap="round" strokeLinejoin="round" />
      <path
        d="M13.8 4.60001H4.2C3.88174 4.60001 3.57652 4.72643 3.35147 4.95148C3.12643 5.17652 3 5.48175 3 5.80001V13C3 13.3183 3.12643 13.6235 3.35147 13.8485C3.57652 14.0736 3.88174 14.2 4.2 14.2H13.8C14.1183 14.2 14.4235 14.0736 14.6485 13.8485C14.8736 13.6235 15 13.3183 15 13V5.80001C15 5.48175 14.8736 5.17652 14.6485 4.95148C14.4235 4.72643 14.1183 4.60001 13.8 4.60001V4.60001Z"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.19995 3V4.6"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path d="M11.8 3V4.6" stroke="currentColor" stroke-linecap="round" strokeLinejoin="round" />
    </svg>
  );
}
