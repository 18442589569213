import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { setDashboardType, resetDashboardType } from "redux/slices";
import { setHideHeaderFav, setShowHeaderFav } from "redux/slices/showHeaderIconsSlice";

const useShowFavorite = (dashboardType = "hyblast_title_header") => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setShowHeaderFav());
    dispatch(setDashboardType(dashboardType));

    return () => {
      dispatch(setHideHeaderFav());
      dispatch(resetDashboardType());
    };
  }, []);
};

export default useShowFavorite;
