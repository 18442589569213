import graphql from "babel-plugin-relay/macro";

export default graphql`
  query getOfferDataQuery($offerId: ID) {
    offerData(offerId: $offerId) {
      campaignTitle
      clicked
      concession
      id
      offerId
      leased
      countLinkedProperties
      nameLinkedProperties
      offerTitle
      toured
      value
      propertyName
      propertyId
      image {
        fileUrl
        id
        thumbnailUrl
      }
    }
  }
`;
