import React from "react";
import withStyles from "@mui/styles/withStyles";
import moment from "moment";
import { numberWithCommas } from "content/utils/commonParsing";

const styles = () => ({
  superContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    marginTop: 12,
  },
  leftPart: {
    background: "#333333",
    padding: "16px",
    color: "#FFFFFF",
    fontFamily: "Open sans",
    width: 265,
  },
  rightContainner: {
    width: "100%",
    display: "flex",
    flexFlow: "column",
    border: "1px solid #EBEBEB",
  },
  infoContainner: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    background: "#fff",
    border: "1px solid #EBEBEB",
    gap: "50px",
    padding: "16px",
  },
  rightPart: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    flex: 1,
  },
  title: {
    color: "#666",
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "20px" /* 142.857% */,
  },
  sendCount: {
    fontSize: 40,
    lineHeight: "48px",
    fontWeight: 600,
    marginTop: 12,
  },
  sendText: {
    fontSize: 12,
    lineHeight: "18px",
    color: "#666",
  },
  valueText: {
    fontSize: 16,
    lineHeight: "24px",
    fontWeight: 400,
    color: "#333",
  },
  cardContainer: {
    padding: "16px 16px 16px 24px",
    color: "#333333",
    width: "100%",
    height: "100%",
    background: "white",
  },
  cardValue: {
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "36px" /* 150% */,
  },
  hoverState: {
    cursor: "pointer",
    "&:hover": {
      color: "var(--hylyTheme)",
    },
  },
  cardBottom: {
    display: "flex",
    gap: "12px",
    alignItems: "baseline",
    marginTop: 6,
  },
  border: {
    borderRight: "1px solid #EBEBEB",
  },
  horizontalLine: {
    width: "100%",
    height: 2,
    background: "#EBEBEB",
  },
  opacity: {
    opacity: "0.5",
  },
  children: {
    background: "white",
  },
});

const Card = ({ item, classes, border, ...props }) => {
  const handleClick = () => {
    if (item.contactId) {
      if (typeof props.onClick == "function") {
        props.onClick(item);
      }
    }
  };

  const value = numberWithCommas(item.value || 0);
  const secondaryValue = numberWithCommas(item.secondaryValue || 0);

  return (
    <div
      className={`${classes.cardContainer} ${item.contactId ? classes.hoverState : ""} ${
        border && classes.border
      }`}
      onClick={handleClick}
    >
      <div className={classes.title}>{item.label}</div>
      <div className={classes.cardBottom}>
        <div className={`${classes.cardValue} ${item.secondaryValue == 0 && "text-text-3"}`}>
          {value}
        </div>
        <div className={`${classes.valueText} ${item.secondaryValue == 0 && "text-text-3"}`}>
          {secondaryValue}
        </div>
        {/* TODO: style to show grey if number is zero: ${item.secondaryValue == 0 && classes.opacity} */}
      </div>
    </div>
  );
};

const OverViewBanner = ({
  classes,
  data,
  showSendInfo = true,
  showSent = true,
  children,
  ...props
}) => {
  const renderData = (item, index) => {
    return <Card item={item} key={index} {...props} classes={classes} border={index !== 3} />;
  };
  if (!data || data.length === 0) {
    return null;
  }

  return (
    <div className={classes.superContainer}>
      <div className={classes.rightContainner}>
        <div className={classes.rightPart}>{data.slice(3).map(renderData)}</div>
      </div>
      <div className={classes.infoContainner}>
        {showSendInfo && (
          <>
            {showSent && (
              <>
                <div>
                  <div className={classes.sendText}>Sent To</div>
                  <div className="flex flex-wrap space-x-1">
                    {data[0].thirdValue?.length
                      ? data[0].thirdValue?.map((audience, i) => (
                          <div className={classes.tableText}>
                            {audience.name} ({audience.receivers})
                            {data[0].thirdValue?.length - 1 === i ? "" : ","}
                          </div>
                        ))
                      : "--"}
                  </div>
                </div>
                <div>
                  <div className={classes.sendText}>Sent At</div>
                  <div className={classes.valueText}>
                    {data[0].secondaryValue
                      ? moment(data[0].secondaryValue.split(",")[0]).format("MMM D, YYYY - h:mma")
                      : "--"}
                  </div>
                </div>
              </>
            )}

            <div>
              <div className={classes.sendText}>Subject</div>
              <div className={classes.valueText}>{data[0].subject ? data[0].subject : "--"}</div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default withStyles(styles)(OverViewBanner);
