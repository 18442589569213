import React from "react";

/** Hook that detects clicks outside of the passed ref */
function useOutsideClickDetect(ref, callback = () => {}) {
  React.useEffect(() => {
    /** Run callback if clicked on outside of element */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }

    document.addEventListener("mousedown", handleClickOutside); // Bind the event listener

    return () => {
      document.removeEventListener("mousedown", handleClickOutside); // Unbind the event listener on clean up
    };
  }, [ref]);
}

export default useOutsideClickDetect;
