import React from "react";

export default function SMSIcon(props) {
  return (
    <svg
      className={props.className}
      width={props.width}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" fill="white" />
      <path
        d="M17.5454 7.09094H6.54541V14.5909H10.0454L12.0454 17.0909L14.0454 14.5909H17.5454V7.09094Z"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.54541 9.59082H14.5454"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.54541 12.0911H12.5454"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="currentColor" />
    </svg>
  );
}
