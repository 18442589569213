import graphql from "babel-plugin-relay/macro";

export default graphql`
  query getAngelsOrgQuery($orgId: ID, $angelType: String) {
    angels(orgId: $orgId, angelType: $angelType) {
      id
      name
      active
      createdAt
      property {
        name
      }
      thenEmails {
        id
        name
        active
        createdAt
        triggerDays
        originalThumbnailUrl
        whenGroupName
        tourType
      }
    }
  }
`;
