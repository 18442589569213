import React from "react";
import { SvgIcon } from "@mui/material";

const CloseIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M9.5 1.5L1.5 9.5" strokeMiterlimit="10" strokeLinecap="square" />
      <path d="M9.5 9.5L1.5 1.5" strokeMiterlimit="10" strokeLinecap="square" />
    </SvgIcon>
  );
};

export default CloseIcon;
