import { getRangeLabel } from "common/date";

export const getValues = (ids, idsValues) => {
  let values = [];
  for (let i = 0; i < ids.length; i++) {
    let idValue = idsValues.find((e) => e.id === ids[i]);
    if (idValue) {
      values.push(idValue.value);
    }
  }
  return values;
};

export const getNameList = (ids, values) => {
  let names = [];
  for (let i = 0; i < ids.length; i++) {
    let value = values.find((e) => e.id === ids[i]);
    if (value) {
      names.push(value.name);
    }
  }
  return names;
};

export const renderParamsLabel = (item, obj, toggleDrawer, properties, orgLevel) => {
  switch (obj.analyzeObject) {
    case "Property Audit":
      return (
        <div className="flex items-center gap-x-1.5">
          {item.label !== "Custom Range"
            ? item.label
            : getRangeLabel(item.start_date, item.end_date)}
        </div>
      );
      break;
    case "By Property":
      return (
        <div className="flex items-center gap-x-1.5">
          <div
            className="cursor-pointer !border-0 !border-b border-solid border-[#DADADA] hover:text-[#26BBED] hover:border-[#26BBED]"
            onClick={toggleDrawer}
          >
            {item.property_ids.length > 1
              ? `${item.property_ids.length} Properties${
                  properties.length === item.property_ids.length ? ` (All)` : ""
                }`
              : properties?.filter((i) => i.id === item.property_ids[0]).pop()?.value}
          </div>
          <div className="w-1 h-1 rounded-full bg-[#DADADA]"></div>
          <div>
            {item.label !== "Custom Range"
              ? item.label
              : getRangeLabel(item.start_date, item.end_date)}
          </div>
        </div>
      );
      break;
    case "By Blast":
      return (
        <div className="flex items-center gap-x-1.5">
          {orgLevel ? (
            <>
              <div>
                {item.property_ids.length > 1
                  ? `${item.property_ids.length} Properties${
                      properties.length === item.property_ids.length ? ` (All)` : ""
                    }`
                  : properties?.filter((i) => i.id === item.property_ids[0]).pop()?.value}
              </div>
              <div className="w-1 h-1 rounded-full bg-[#DADADA]"></div>
              <div
                className="cursor-pointer !border-0 !border-b border-solid border-[#DADADA] hover:text-[#26BBED] hover:border-[#26BBED]"
                onClick={toggleDrawer}
              >
                View all filters
              </div>
            </>
          ) : (
            <>
              <div>
                {item.label !== "Custom Range"
                  ? item.label
                  : getRangeLabel(item.start_date, item.end_date)}
              </div>
              <div className="w-1 h-1 rounded-full bg-[#DADADA]"></div>
              <div
                className="cursor-pointer !border-0 !border-b border-solid border-[#DADADA] hover:text-[#26BBED] hover:border-[#26BBED]"
                onClick={toggleDrawer}
              >
                {item.campaigns_id.length > 1
                  ? `${item.campaigns_id.length} Blasts${
                      item?.total_campaigns?.length === item.campaigns_id.length ? ` (All)` : ""
                    }`
                  : item.campaign_names[0]}
              </div>
            </>
          )}
        </div>
      );
      break;
    case "Blast Analytics":
      return (
        <div className="flex items-center gap-x-1.5">
          {orgLevel ? (
            <>
              <div>
                {item.property_ids.length > 1
                  ? `${item.property_ids.length} Properties${
                      properties.length === item.property_ids.length ? ` (All)` : ""
                    }`
                  : properties?.filter((i) => i.id === item.property_ids[0]).pop()?.value}
              </div>
              <div className="w-1 h-1 rounded-full bg-[#DADADA]"></div>
              <div
                className="cursor-pointer !border-0 !border-b border-solid border-[#DADADA] hover:text-[#26BBED] hover:border-[#26BBED]"
                onClick={toggleDrawer}
              >
                View all filters
              </div>
            </>
          ) : (
            <div className="w-full">
              {/* <div className="max-w-1/2 truncate overflow-hidden whitespace-nowrap"> */}
              {item.campaigns_id.length > 1
                ? `${item.campaigns_id.length} Blasts${
                    item?.total_campaigns?.length === item.campaigns_id.length ? ` (All)` : ""
                  }`
                : item.campaign_names[0]}
              {/* </div> */}
              <div className="w-1 h-1 rounded-full bg-[#DADADA]"></div>
              {/* <div className="max-w-1/2 truncate overflow-hidden whitespace-nowrap"> */}
              {item.blast_date_range && item.blast_date_range.label !== "Custom Range"
                ? item.blast_date_range.label
                : getRangeLabel(
                    item.blast_date_range?.startAt || item.blast_date_range,
                    item.blast_date_range?.endAt || item.blast_date_range
                  )}
              {/* </div> */}
            </div>
          )}
        </div>
      );
      break;
    case "Blast Contact List":
      return (
        <div className="flex items-center gap-x-1.5">
          {orgLevel ? (
            <>
              <div>
                {item.property_ids.length > 1
                  ? `${item.property_ids.length} Properties${
                      properties.length === item.property_ids.length ? ` (All)` : ""
                    }`
                  : properties?.filter((i) => i.id === item.property_ids[0]).pop()?.value}
              </div>
              <div className="w-1 h-1 rounded-full bg-[#DADADA]"></div>
              <div
                className="cursor-pointer !border-0 !border-b border-solid border-[#DADADA] hover:text-[#26BBED] hover:border-[#26BBED]"
                onClick={toggleDrawer}
              >
                View all filters
              </div>
            </>
          ) : (
            <>
              <div>
                {item.label !== "Custom Range"
                  ? item.label
                  : getRangeLabel(item.start_date, item.end_date)}
              </div>
              <div className="w-1 h-1 rounded-full bg-[#DADADA]"></div>
              <div
                className="cursor-pointer !border-0 !border-b border-solid border-[#DADADA] hover:text-[#26BBED] hover:border-[#26BBED]"
                onClick={toggleDrawer}
              >
                {item.campaigns_id.length > 1
                  ? `${item.campaigns_id.length} Blasts${
                      item?.total_campaigns?.length === item.campaigns_id.length ? ` (All)` : ""
                    }`
                  : item.campaign_names[0]}
              </div>
            </>
          )}
        </div>
      );
      break;
    case "By Email Platform":
      return (
        <div className="flex items-center gap-x-1.5">
          {orgLevel && (
            <>
              <div
                className="cursor-pointer !border-0 !border-b border-solid border-[#DADADA] hover:text-[#26BBED] hover:border-[#26BBED]"
                onClick={toggleDrawer}
              >
                {item.property_ids.length > 1
                  ? `${item.property_ids.length} Properties${
                      properties.length === item.property_ids.length ? ` (All)` : ""
                    }`
                  : properties?.filter((i) => i.id === item.property_ids[0]).pop()?.value}
              </div>
              <div className="w-1 h-1 rounded-full bg-[#DADADA]"></div>
            </>
          )}
          <div>
            {item.label !== "Custom Range"
              ? item.label
              : getRangeLabel(item.start_date, item.end_date)}
          </div>
        </div>
      );
      break;
    default:
      return <></>;
  }
};

export const EBLAST_ANALYTICS_PANELS = [
  {
    id: "Overview",
    value: "Blast Overview",
  },
  {
    id: "Breakdown",
    value: "Breakdown Blast",
  },
  {
    id: "Link Clicks",
    value: "Link Clicks",
  },
];

export const EBLAST_PANELS = [
  {
    id: "Overview",
    value: "Blast Overview",
  },
  {
    id: "DrillDown",
    value: "DrillDown Blast",
  },
  {
    id: "Breakdown",
    value: "Breakdown Blast",
  },
];

export const BY_EMAIL_PLATFORM_PANELS = [
  { id: "by-email-client", value: "Open By Mail Client" },
  { id: "by-os", value: "Open By OS" },
  { id: "by-device", value: "Open By Device" },
];

export const DRIPS_EMAIL_ANALYTICS_PANELS = [
  { id: "overview", value: "Email Overview" },
  { id: "drill-down", value: "Drill Donw - Email" },
  { id: "link-clicks", value: "Link Clicks" },
];

export const CONTACTS = [
  {
    id: "mails_sent_contacts",
    value: "Send At",
  },
  {
    id: "mails_opened_contacts",
    value: "Opened At",
  },
  {
    id: "mails_clicks_contacts",
    value: "Clicked At",
  },
  {
    id: "mails_unsubscribed_contacts",
    value: "Unsubscribed At",
  },
  {
    id: "mails_bounced_contacts",
    value: "Bounced At",
  },
  {
    id: "mails_complained_contacts",
    value: "Complained At",
  },
];
