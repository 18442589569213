import React from "react";
import PropTypes from "prop-types";

import withStyles from "@mui/styles/withStyles";

import ListItemIcon from "@mui/material/ListItemIcon";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import Typography from "storybookComponents/Typography";
import Icon from "storybookComponents/Icon";

const styles = (theme) => ({
  root_list_item: {
    cursor: "pointer",
    padding: theme.margins.xs,
    "&:hover": {
      background: "#f5f5f5", //theme.palette.lightGrey,
    },
  },
  selected: {
    fontWeight: theme.fontWeight.bold,
  },
  icon_class_container: {
    marginTop: -2,
  },
});

const ListItem = React.memo(({ item, search_value }) => {
  const typed = React.useMemo(
    () => search_value && item && RegExp(`${search_value}`, "gi").test(item)
  );
  return typed ? <b>{item}</b> : <>{item}</>;
});

const LettersList = React.memo(({ items, search_value }) => {
  return (
    <Typography type={"body1"}>
      {items.map((item, idx) => (
        <ListItem key={`simple-list-${item}-${idx}`} item={item} search_value={search_value} />
      ))}
    </Typography>
  );
});
const ListItemComponent = ({
  classes,
  item,
  key,
  selected_value,
  handle_select,
  icon_type,
  search_value,
}) => {
  const splitted_item = React.useMemo(() => {
    if (!search_value) {
      return [item.name];
    }
    return item.name
      .replace(RegExp(search_value, "gi"), (...args) => {
        const o = args[0],
          i = args[args.length - 2];
        return i === 0 ? `${o}__` : i === item.name.length - 1 ? `__${o}` : `__${o}__`;
      })
      .split("__");
  }, [item.name, search_value]);

  return (
    <MenuItem
      selected={selected_value === item.id}
      onClick={(e) => handle_select(e, item)}
      classes={{
        root: classes.root_list_item,
        selected: classes.selected,
      }}
      value=""
    >
      {icon_type && (
        <ListItemIcon className={classes.icon_class_container}>
          <Icon type={icon_type} />
        </ListItemIcon>
      )}
      <LettersList items={splitted_item} search_value={search_value} />
    </MenuItem>
  );
};

ListItemComponent.propTypes = {
  classes: PropTypes.object,
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  key: PropTypes.string,
  selected_value: PropTypes.string,
  handle_select: PropTypes.func,
  icon_type: PropTypes.string,
  search_value: PropTypes.string,
};

export default React.memo(withStyles(styles)(ListItemComponent));
