import enrollFrom from "../enrollForm/embeded-code";

var emailRegexp =
  /^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

export default (data, preview) => {
  return `
<style>
@import url("https://fonts.googleapis.com/css?family=Raleway");
@import url("https://fonts.googleapis.com/css?family=Open+Sans");

input:focus {outline:0;} 
button:focus {outline:0;} 

.portal-banner{
  max-width:1024px;
  min-height: 675px;
  width:100%;
  height: auto;
  margin: auto;
  background: white;
}

.portal-banner-header{
  background:${data.orgColors ? data.orgColors.buttonBackgroundColor : "#27bbed"};
  color:${data.orgColors ? data.orgColors.buttonFontColor : "white"};
  display: ${preview || data.active ? "flex !important" : "none !important"} ;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 60px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight:600;
}

.portal-banner-header span {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
}

.portal-banner-header .refer-btn{
  // width: 140px;
  // height: 32px;
  padding: 6px 12px;
  right: 190px;
  top: 14px;
  color: #27bbed;
  background: #FFFFFF;
  border:none;
  margin-left: 20px;
  cursor:pointer;
  color:${data.orgColors ? data.orgColors.buttonBackgroundColor : "#27bbed"};
}

.portal-banner-header .close-btn{
  background:transparent;
  border:none;
  font-size: 30px;
  color:white;
  font-weight: 100;
  position: absolute;
  right: 30px;
  
}

.portal-banner .content{
  min-height: 615px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top:none;
  border: ${preview ? "1px solid #DADADA" : "none"};
}

.portal-banner .content .content-wrapper{
  text-align: center;
  width: fit-content;
  margin: auto;
}

.portal-banner .content .text{
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  margin-top: 20px;
}

#enroll-popup {
  display:none;
  position:fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
  margin:auto;
  height:auto;
  background:rgba(27, 27, 27, 0.71);
  z-index:1
}

.img-style{
  max-height:60px;
}

@media only screen and (max-width: 480px) {

  .portal-banner{
    margin-top:30px;
  }

  .portal-banner-header{
    display: ${preview || data.active ? "block !important" : "none !important"} ;
    text-align: center;
    padding: 10px;
    height: auto;
  }

  .portal-banner-header .title{
    text-align: left;
    padding: 0 10px;
  }

  .portal-banner-header .refer-btn{
    margin-top: 10px;
    margin-left:0;
  }

  .portal-banner-header .close-btn{
    top:8px;
    right:0px;
    font-size: 25px;
  }
  body{
    margin:0 !important;
    padding:0!important;
  }
}
</style>


<div id="enroll-popup">
${enrollFrom({ ...data, preview: preview, close: true })}
</div>  
<div class="portal-banner">
  <div class="portal-banner-header">
    <div class="title"> ${data.bannerText} </div>
    <button class="refer-btn" type="button" onclick=${preview ? null : "openEnrollForm()"}>
      ${data.ctaText}
    </button>
    <button class="close-btn">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19 5L5 19" stroke="white" stroke-miterlimit="10" stroke-linecap="square"/>
        <path d="M19 19L5 5" stroke="white" stroke-miterlimit="10" stroke-linecap="square"/>
      </svg>
    </button>
  </div>
  <div class="content">
    <div class="content-wrapper">
      <img class="img-style" src="${data.property.logo}"/>
      <div class="text">Resident Portal</div>
    </div>
  </div>
</div>

`;
};
