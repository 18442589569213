/**
 * @flow
 * @relayHash 502098165111886933e322092fcaef1f
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getGtmIdQueryVariables = {|
  propertyId: string
|};
export type getGtmIdQueryResponse = {|
  +websiteGtmScript: {|
    +id: string,
    +noscript: ?string,
    +script: ?string,
  |}
|};
export type getGtmIdQuery = {|
  variables: getGtmIdQueryVariables,
  response: getGtmIdQueryResponse,
|};
*/


/*
query getGtmIdQuery(
  $propertyId: ID!
) {
  websiteGtmScript(propertyId: $propertyId) {
    id
    noscript
    script
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "propertyId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "websiteGtmScript",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "propertyId",
        "variableName": "propertyId"
      }
    ],
    "concreteType": "WebsiteGtm",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "noscript",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "script",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getGtmIdQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "getGtmIdQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "getGtmIdQuery",
    "id": null,
    "text": "query getGtmIdQuery(\n  $propertyId: ID!\n) {\n  websiteGtmScript(propertyId: $propertyId) {\n    id\n    noscript\n    script\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e122880672c36d94e3c9c079af70db42';
module.exports = node;
