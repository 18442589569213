import React from "react";
import { createFragmentContainer, QueryRenderer } from "react-relay";

import { SimpleLoader as Loader } from "../../Loader";

import { some } from "lodash";

let get_time = () => new Date().getTime();

let last_time;
export default React.memo(
  ({ query, query_name, environment, relay, no_loader, Component, ...search_field_props }) => {
    const variables = React.useMemo(
      () => ({
        name: search_field_props.search_value || "",
        ...search_field_props.variables,
      }),
      [search_field_props]
    );

    const hide_loader = no_loader || !some(Object.values(variables), Boolean);

    return (
      <>
        <QueryRenderer
          environment={environment || (relay && relay.environment)}
          query={query}
          variables={variables}
          render={({ error, props }) => {
            last_time = get_time();
            return error ? (
              <div>{error.message}</div>
            ) : search_field_props.show_on_load ? (
              <Component
                suggestions={props && props[query_name]}
                should_group={props && props[query_name].length > 1}
                {...search_field_props}
                loading={!Boolean(props)}
              />
            ) : props ? (
              <Component
                suggestions={props[query_name]}
                should_group={props[query_name].length > 1}
                {...search_field_props}
              />
            ) : hide_loader ? (
              <></>
            ) : (
              <Loader size={30} />
            );
          }}
        />
      </>
    );
  }
);
