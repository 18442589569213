import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  subject: [],
  preheader: [],
  title: [],
  body: [],
  ctaText: [],
};

export const BlastRegenerateSlice = createSlice({
  name: "gptRegenerate",
  initialState,
  reducers: {
    regenerateText: (state, { payload }) => {
      switch (payload.name) {
        case "subject":
          state.subject.push(payload.value);
          break;
        case "preheader":
          state.preheader.push(payload.value);
          break;
        case "title":
          state.data.title.push(payload.value);
          break;
        case "body":
          state.data.body.push(payload.value);
          break;
        case "ctaText":
          state.data.ctaText.push(payload.value);
          break;

        default:
          break;
      }
    },
  },
});

export const { regenerateText } = BlastRegenerateSlice.actions;
export default BlastRegenerateSlice.reducer;
