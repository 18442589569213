export const isUserApprover = (settings, current_email, userRole) => {
  // if (!settings.approvalRequired) return false;
  if (userRole === "admin") return true;
  return (
    settings.approvalRequired &&
    (checkIfEmailExist(settings.agentApprovalEmails, current_email) ||
      checkIfEmailExist(settings.approverEmails, current_email))
  );
};

export const checkIfEmailExist = (emails, search) => {
  if (!emails || !search) return;
  return emails.findIndex((item) => item.toLowerCase().trim() === search.toLowerCase()) >= 0;
};

export const handleObjStringRes = (jsonString) => {
  if (!jsonString) {
    return null;
  }

  try {
    jsonString = jsonString.replace(/"nil"/g, "null");
    const jsonObject = JSON.parse(jsonString.replace(/=>/g, ":"));
    return jsonObject;
  } catch (error) {
    console.error("Error parsing JSON string:", error);
    return null;
  }
};
