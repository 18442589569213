import makeStyles from "@mui/styles/makeStyles";
import { NAVBAR_WIDTH } from "constants/index";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    height: "100vh",
  },
  contentWrapperShift: {
    marginLeft: NAVBAR_WIDTH,
    width: `calc(100% - ${NAVBAR_WIDTH}px)`,
    height: "100%",
    flex: 1,
    display: "flex",
    flexGrow: 1,
    flexFlow: "column",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  contentWrapperNoShift: {
    width: "100%",
    height: "100%",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    paddingLeft: "20px",
  },
  appBarShift: {
    marginLeft: NAVBAR_WIDTH,
    width: `calc(100% - ${NAVBAR_WIDTH}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    paddingLeft: "20px",
  },
  contentContainer: {
    flex: "1 1 auto",
    height: "calc(100vh - 64px)",
    overflowY: "auto",
    display: "flex",
    flexFlow: "column",
  },
  contentContainerNotificationsBar: { height: "calc(100vh - 114px)" },
}));
