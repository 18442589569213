import React from "react";
import { withRouter } from "react-router-dom";
import Error from "error";
import Preview from "storybookComponents/Preview/dialog";
import Loader from "storybookComponents/HylyLoader";
import * as apis from "relay/drips";
import { unpurchasedAngels } from "services/alertsMenuServices";

class PreviewNewTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: false,
      preview: "",
      preview_open: true,
      previewImage: false,
      url: "",
    };
  }

  componentDidMount() {
    document.title = "Preview < hyAlert";
    const { angelId, emailId } = this.props.match.params;
    this._loadData(angelId, emailId);
  }

  _loadData = async (angelId, emailId) => {
    let preview;
    if (angelId.includes("_")) {
      preview = await this._getUnpurchasedPreview(angelId, emailId);
      if (preview) {
        this.success(preview, true);
      } else {
        this.failed();
      }
    } else {
      const url = `https://${process.env.REACT_APP_MyhylyDomain}/mktg/1/promotions/${emailId}/iframe_preview`;
      // preview = await this._getPreview(angelId, emailId);
      if (url) {
        this.setState({ url: url });
        this.success(preview, false);
      } else {
        this.failed();
      }
    }
  };

  _getUnpurchasedPreview = (angelId, emailId) => {
    let angel, email, preview;
    angel = unpurchasedAngels.find((angel) => angel.id === angelId);
    if (angel) {
      email = angel.thenEmails.find((email) => email.id === emailId);
      preview = email.preview.replace("-min", "");
    }
    return preview;
  };

  _getPreview = (angelId, id) => {
    return new Promise(function (resolve, reject) {
      apis.getDripPreview(angelId, id).then((res, err) => {
        if (err || res.errors || res.error) {
          resolve(false);
        } else {
          resolve(res.thenEmailEditor.substitutedMacrosHtml);
        }
      });
    });
  };

  success = (preview, unpurchased) => {
    this.setState({
      loading: false,
      preview: preview,
      previewImage: unpurchased,
    });
  };

  failed = () => {
    this.setState({
      loading: false,
      error: true,
    });
  };

  render() {
    const { loading, error, preview_open, preview, previewImage, url } = this.state;
    if (loading) {
      return <Loader />;
    } else if (error) {
      return <Error />;
    } else if (previewImage) {
      return (
        <center>
          <img src={preview} alt={preview} />
        </center>
      );
    } else {
      return (
        <iframe
          src={url}
          id="hylyWebIframe"
          style={{ width: "100%", border: "none", height: "96vh" }}
        />
      );
    }
  }
}

export default withRouter(PreviewNewTab);
