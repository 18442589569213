import React from "react";

function RefreshIcon({ className = "text-[#999999]", size = 28 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      width={size}
      className={className}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6.224 6.055l-.04 4.376 4.172-1.396M6 12.97a6.152 6.152 0 10.192-2.542"
      ></path>
    </svg>
  );
}

export default RefreshIcon;
