import React from "react";
import PropTypes from "prop-types";

import withStyles from "@mui/styles/withStyles";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import Icon from "storybookComponents/Icon";

const styles = (theme) => ({
  root: {
    padding: `0 ${theme.margins.xs}px`,
    transition: "width .5s, border-bottom 2s",
  },
  input: {
    ...theme.typography.body1,
    lineHeight: 1,
    fontWeight: 400,
    padding: `${theme.margins.xs}px 0`,
    height: 6,
  },
  select: {},
  disabled: {},
  focused: {},
  error: {},
  underline: {
    "&:before": {
      borderBottom: `1px solid ${theme.palette.lightGrey}`,
    },
    "&:hover:not($disabled):not($focused):not($error):before": {
      borderBottomColor: theme.palette.mediumGrey,
    },
    "&:after": {
      borderBottomColor: theme.palette.deepGrey,
      //borderBottom: `1px solid ${theme.palette.deepGrey}`
    },
  },
  input_auto: {
    width: "100%",
    alignSelf: "flex-end",
  },
  small_input: {
    width: 42,
    alignSelf: "flex-end",
    cursor: "pointer",
    background: "white",
  },
  icon_class: {
    cursor: "pointer",
  },
});

const SearchInput = ({
  classes: { root, input, underline, icon_class },
  disableUnderline,
  iconComponent,
  className,
  input_class,
  value,
  set_ref,
  grow,
  open,
  ...other
}) => {
  return (
    <Input
      classes={{
        root: `${root}`,
        input: `${input} ${input_class || ""}`,
        underline,
      }}
      className={className || ""}
      value={value}
      endAdornment={
        <Icon
          type="icon-zoom"
          size="sm"
          className={icon_class}
          onClick={grow}
          style={{ border: !open && "1px solid #EEE", padding: !open && 12 }}
        />
      }
      onChange={other.onChange}
      onKeyDown={other.onKeyDown}
    />
  );
};

SearchInput.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
    input: PropTypes.string.isRequired,
  }),
  iconComponent: React.isValidElement,
  className: PropTypes.string,
};

SearchInput.defaultProps = {
  iconComponent: null,
  disableUnderline: true,
};

export default React.memo(withStyles(styles)(SearchInput));
