import graphql from "babel-plugin-relay/macro";

export default graphql`
  query contactExpressionCountQuery($propertyId: ID!, $expression: String!, $recipient: String!) {
    contactExpressionCountEmail(
      propertyId: $propertyId
      expression: $expression
      recipient: $recipient
    )
  }
`;
