/**
 * @flow
 * @relayHash 19aa3ac68fe669cfc3d0bfd1c8773054
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getOfferDataQueryVariables = {|
  offerId?: ?string
|};
export type getOfferDataQueryResponse = {|
  +offerData: {|
    +campaignTitle: ?string,
    +clicked: ?string,
    +concession: ?string,
    +id: string,
    +offerId: ?string,
    +leased: ?string,
    +countLinkedProperties: ?string,
    +nameLinkedProperties: ?string,
    +offerTitle: ?string,
    +toured: ?string,
    +value: ?string,
    +propertyName: ?string,
    +propertyId: ?string,
    +image: ?{|
      +fileUrl: string,
      +id: string,
      +thumbnailUrl: ?string,
    |},
  |}
|};
export type getOfferDataQuery = {|
  variables: getOfferDataQueryVariables,
  response: getOfferDataQueryResponse,
|};
*/


/*
query getOfferDataQuery(
  $offerId: ID
) {
  offerData(offerId: $offerId) {
    campaignTitle
    clicked
    concession
    id
    offerId
    leased
    countLinkedProperties
    nameLinkedProperties
    offerTitle
    toured
    value
    propertyName
    propertyId
    image {
      fileUrl
      id
      thumbnailUrl
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "offerId",
    "type": "ID",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "offerData",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "offerId",
        "variableName": "offerId"
      }
    ],
    "concreteType": "OfferSetting",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "campaignTitle",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "clicked",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "concession",
        "args": null,
        "storageKey": null
      },
      (v1/*: any*/),
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "offerId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "leased",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "countLinkedProperties",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "nameLinkedProperties",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "offerTitle",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "toured",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "value",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "propertyName",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "propertyId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "image",
        "storageKey": null,
        "args": null,
        "concreteType": "Asset",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "fileUrl",
            "args": null,
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "thumbnailUrl",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getOfferDataQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "getOfferDataQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "getOfferDataQuery",
    "id": null,
    "text": "query getOfferDataQuery(\n  $offerId: ID\n) {\n  offerData(offerId: $offerId) {\n    campaignTitle\n    clicked\n    concession\n    id\n    offerId\n    leased\n    countLinkedProperties\n    nameLinkedProperties\n    offerTitle\n    toured\n    value\n    propertyName\n    propertyId\n    image {\n      fileUrl\n      id\n      thumbnailUrl\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '77a120d9975317e5ed9bb975e42d1969';
module.exports = node;
