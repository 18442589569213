import React from "react";

export default function EditPencilIconWithCircle({ className = "text-[#26BBED]", size = 25 }) {
  return (
    <svg
      className={className}
      width={size}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="1" y="0.5" width="23" height="23" rx="11.5" fill="white" />
      <path d="M7 17.5H18" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M15 6.5L17 8.5L11 14.5L8 15.5L9 12.5L15 6.5Z"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <rect x="1" y="0.5" width="23" height="23" rx="11.5" stroke="currentColor" />
    </svg>
  );
}
