/**
 * @flow
 * @relayHash 1fc3272f0eb600d00b0682e30bb65d0f
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type updateSuborgMacroInput = {|
  clientMutationId?: ?string,
  campaign_id: string,
  macro_data?: ?any,
  image_data?: ?string,
|};
export type updateSuborgMacroMutationVariables = {|
  input: updateSuborgMacroInput
|};
export type updateSuborgMacroMutationResponse = {|
  +updateSuborgMacro: ?{|
    +clientMutationId: ?string,
    +macro: ?string,
  |}
|};
export type updateSuborgMacroMutation = {|
  variables: updateSuborgMacroMutationVariables,
  response: updateSuborgMacroMutationResponse,
|};
*/


/*
mutation updateSuborgMacroMutation(
  $input: updateSuborgMacroInput!
) {
  updateSuborgMacro(input: $input) {
    clientMutationId
    macro
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "updateSuborgMacroInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateSuborgMacro",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "updateSuborgMacroPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "clientMutationId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "macro",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "updateSuborgMacroMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "updateSuborgMacroMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "updateSuborgMacroMutation",
    "id": null,
    "text": "mutation updateSuborgMacroMutation(\n  $input: updateSuborgMacroInput!\n) {\n  updateSuborgMacro(input: $input) {\n    clientMutationId\n    macro\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5b03dec7e822cf9a2fca6ab8e752e739';
module.exports = node;
