import graphql from "babel-plugin-relay/macro";

export default graphql`
  query getListPublicOffersQuery($offerId: ID,$propertyIds: [String!]){
    listPublicOffers(offerId:$offerId,propertyIds:$propertyIds){
      edges{
        node{
          campaignTitle
          offerTitle
          propertyName
          macroDict
          designOffer
          subTitle
        }
      }
    }
  }
`