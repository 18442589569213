/**
 * @flow
 * @relayHash 3e3720829ca0016aee9da055cb1f194f
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type saveOrgSettingInput = {|
  clientMutationId?: ?string,
  orgId: string,
  propertyId?: ?string,
  enableDisclaimer?: ?boolean,
  disclaimerTitle?: ?string,
  disclaimerMessage?: ?string,
|};
export type saveOrgSettingMutationVariables = {|
  input: saveOrgSettingInput
|};
export type saveOrgSettingMutationResponse = {|
  +saveOrgSetting: ?{|
    +setting: ?{|
      +enableDisclaimer: ?boolean,
      +disclaimerTitle: ?string,
      +disclaimerMessage: ?string,
      +id: string,
    |}
  |}
|};
export type saveOrgSettingMutation = {|
  variables: saveOrgSettingMutationVariables,
  response: saveOrgSettingMutationResponse,
|};
*/


/*
mutation saveOrgSettingMutation(
  $input: saveOrgSettingInput!
) {
  saveOrgSetting(input: $input) {
    setting {
      enableDisclaimer
      disclaimerTitle
      disclaimerMessage
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "saveOrgSettingInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "saveOrgSetting",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "saveOrgSettingPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "setting",
        "storageKey": null,
        "args": null,
        "concreteType": "OrgSetting",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "enableDisclaimer",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "disclaimerTitle",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "disclaimerMessage",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "saveOrgSettingMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "saveOrgSettingMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "saveOrgSettingMutation",
    "id": null,
    "text": "mutation saveOrgSettingMutation(\n  $input: saveOrgSettingInput!\n) {\n  saveOrgSetting(input: $input) {\n    setting {\n      enableDisclaimer\n      disclaimerTitle\n      disclaimerMessage\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '88ba490501f071b316ad469f05c8f6b7';
module.exports = node;
