import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import isEmpty from "lodash/isEmpty";
import Grid from "@mui/material/Grid";
import withStyles from "@mui/styles/withStyles";
import OrgsChanger from "./SearchWithLists";

const styles = (theme) => ({
  container: {
    flexWrap: "nowrap",
    height: "100%",
    overflowY: "scroll",
    overflowX: "hidden",
    maxWidth: "calc(100% + 35px)",
    flex: 1,
    marginLeft: 0,
  },
  container_big_screens: {
    marginLeft: -35,
  },
  arrow: {
    width: 56,
    height: 56,
    zIndex: -1,
    position: "relative",
    marginRight: -1,
    alignSelf: "flex-end",
    justifySelf: "flex-start",
    "&:after": {
      content: '""',
      position: "absolute",
      top: (props) => (props?.type === "feederHeader" ? "-312px" : 11),
      left: (props) => (props?.hideArrow ? "420px" : 42),
      width: 32,
      height: 32,
      background: theme.palette.white,
      transform: "rotate(135deg)",
      boxShadow: "0px 4px 4px rgba(204, 204, 204, 0.25)",
      border: "1px solid #eee",
    },
  },
  arrow_org: {
    width: 56,
    height: 56,
    zIndex: -1,
    position: "relative",
    marginRight: -1,
    alignSelf: "flex-end",
    justifySelf: "flex-start",
    "&:after": {
      content: '""',
      position: "absolute",
      top: (props) => (props?.type === "feederHeader" ? "-312px" : 23),
      left: (props) => (props?.hideArrow ? "420px" : 42),
      width: 32,
      height: 32,
      background: theme.palette.white,
      transform: "rotate(135deg)",
      boxShadow: "0px 4px 4px rgba(204, 204, 204, 0.25)",
      border: "1px solid #eee",
    },
  },
});
let waiter;

export const wait = (time) => new Promise((r) => (waiter = setTimeout(r, time)));

const ChangerComponent = ({
  classes,
  close_bottom_section,
  setDefaultProperty,
  select_org_property,
  ...props
}) => {
  const [temp_org, setTempOrg] = React.useState(props.selected_org);

  const suggestions = React.useMemo(() => {
    if (!props.orgs) {
      return;
    }
    return props.orgs.reduce((a, c) => {
      const cc = Object.assign({}, c);
      if (cc.suborgs) {
        for (let sub of cc.suborgs) {
          a.push({ ...sub, type: "suborg", orgId: cc.id });
        }
        delete cc.suborgs;
      }
      a.push({ ...cc, type: "org" });
      return a;
    }, []);
  }, [props.orgs]);

  const selectOrg = React.useCallback((e, org) => {
    select_org_property(org, "org");
    close_bottom_section();
  });

  const selectProperty = React.useCallback((e, property) => {
    select_org_property([temp_org, property], "property");

    if (!isEmpty(e)) {
      close_bottom_section();
    }
  });

  const matches = useMediaQuery("(min-width:768px)");

  const container_class = classes.container + (matches ? " " + classes.container_big_screens : "");

  const arrow_class = React.useMemo(() => {
    return props.selected_property && props.selected_property.id
      ? classes.arrow
      : classes.arrow_org;
  }, [props.selected_property]);

  return (
    <Grid container direction="row-reverse" className={container_class}>
      <OrgsChanger
        suggestions={suggestions}
        temp_org={temp_org}
        selectOrg={selectOrg}
        changeOrg={setTempOrg}
        selectProperty={selectProperty}
        close_bottom_section={close_bottom_section}
        isLoading={!props.orgs || !props.orgs.length}
        {...props}
      />
      {matches && <div className={arrow_class} />}
    </Grid>
  );
};

export default React.memo(withStyles(styles)(ChangerComponent));
