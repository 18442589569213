/**
 * @flow
 * @relayHash 8ce1b54b208cc3124a94f85279138d7b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type updateMJourneyInput = {|
  clientMutationId?: ?string,
  id: string,
  status?: ?string,
  name?: ?string,
  audienceType: string,
  edges?: ?$ReadOnlyArray<?string>,
  nodes?: ?$ReadOnlyArray<?string>,
  history?: ?$ReadOnlyArray<?string>,
|};
export type updateMJourneyMutationVariables = {|
  input: updateMJourneyInput
|};
export type updateMJourneyMutationResponse = {|
  +updateMJourney: ?{|
    +clientMutationId: ?string,
    +mjourney: ?{|
      +id: string,
      +tenantId: ?string,
      +name: ?string,
      +audienceType: ?string,
      +createdAt: ?any,
      +status: ?string,
      +edges: ?string,
      +nodes: ?string,
    |},
  |}
|};
export type updateMJourneyMutation = {|
  variables: updateMJourneyMutationVariables,
  response: updateMJourneyMutationResponse,
|};
*/


/*
mutation updateMJourneyMutation(
  $input: updateMJourneyInput!
) {
  updateMJourney(input: $input) {
    clientMutationId
    mjourney {
      id
      tenantId
      name
      audienceType
      createdAt
      status
      edges
      nodes
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "updateMJourneyInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateMJourney",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "updateMJourneyPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "clientMutationId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "mjourney",
        "storageKey": null,
        "args": null,
        "concreteType": "MJourney",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "tenantId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "audienceType",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "createdAt",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "status",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "edges",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "nodes",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "updateMJourneyMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "updateMJourneyMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "updateMJourneyMutation",
    "id": null,
    "text": "mutation updateMJourneyMutation(\n  $input: updateMJourneyInput!\n) {\n  updateMJourney(input: $input) {\n    clientMutationId\n    mjourney {\n      id\n      tenantId\n      name\n      audienceType\n      createdAt\n      status\n      edges\n      nodes\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '410a9c50d23ef8c9e04b90ae1d745a9a';
module.exports = node;
