/**
 * @flow
 * @relayHash f61468288fa1773dfab8e6deed729437
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type retrieveFaqItemQueryVariables = {|
  propertyId: string,
  questionId: string,
  itemType: string,
|};
export type retrieveFaqItemQueryResponse = {|
  +retrieveFaqItem: {|
    +id: string,
    +summary: ?any,
    +faqItems: ?$ReadOnlyArray<{|
      +id: string,
      +itemTitle: ?string,
      +image: ?{|
        +fileUrl: string,
        +id: string,
      |},
      +imageId: ?string,
      +itemDescription: ?string,
      +distance: ?string,
      +editable: ?boolean,
      +selected: ?boolean,
      +questionId: ?string,
      +preDefined: ?boolean,
      +section: ?{|
        +id: string,
        +sectionName: ?string,
        +sectionType: ?string,
      |},
    |}>,
    +section: ?$ReadOnlyArray<{|
      +sectionType: ?string,
      +sectionName: ?string,
      +id: string,
    |}>,
  |}
|};
export type retrieveFaqItemQuery = {|
  variables: retrieveFaqItemQueryVariables,
  response: retrieveFaqItemQueryResponse,
|};
*/


/*
query retrieveFaqItemQuery(
  $propertyId: String!
  $questionId: String!
  $itemType: String!
) {
  retrieveFaqItem(propertyId: $propertyId, questionId: $questionId, itemType: $itemType) {
    id
    summary
    faqItems {
      id
      itemTitle
      image {
        fileUrl
        id
      }
      imageId
      itemDescription
      distance
      editable
      selected
      questionId
      preDefined
      section {
        id
        sectionName
        sectionType
      }
    }
    section {
      sectionType
      sectionName
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "propertyId",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "questionId",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "itemType",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "sectionName",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "sectionType",
  "args": null,
  "storageKey": null
},
v4 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "retrieveFaqItem",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "itemType",
        "variableName": "itemType"
      },
      {
        "kind": "Variable",
        "name": "propertyId",
        "variableName": "propertyId"
      },
      {
        "kind": "Variable",
        "name": "questionId",
        "variableName": "questionId"
      }
    ],
    "concreteType": "RetriveFaqType",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "summary",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "faqItems",
        "storageKey": null,
        "args": null,
        "concreteType": "FaqItem",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "itemTitle",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "image",
            "storageKey": null,
            "args": null,
            "concreteType": "Asset",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "fileUrl",
                "args": null,
                "storageKey": null
              },
              (v1/*: any*/)
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "imageId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "itemDescription",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "distance",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "editable",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "selected",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "questionId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "preDefined",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "section",
            "storageKey": null,
            "args": null,
            "concreteType": "FaqSection",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/)
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "section",
        "storageKey": null,
        "args": null,
        "concreteType": "FaqSection",
        "plural": true,
        "selections": [
          (v3/*: any*/),
          (v2/*: any*/),
          (v1/*: any*/)
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "retrieveFaqItemQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v4/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "retrieveFaqItemQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v4/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "retrieveFaqItemQuery",
    "id": null,
    "text": "query retrieveFaqItemQuery(\n  $propertyId: String!\n  $questionId: String!\n  $itemType: String!\n) {\n  retrieveFaqItem(propertyId: $propertyId, questionId: $questionId, itemType: $itemType) {\n    id\n    summary\n    faqItems {\n      id\n      itemTitle\n      image {\n        fileUrl\n        id\n      }\n      imageId\n      itemDescription\n      distance\n      editable\n      selected\n      questionId\n      preDefined\n      section {\n        id\n        sectionName\n        sectionType\n      }\n    }\n    section {\n      sectionType\n      sectionName\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e622138bfe54d2177a99e6856accc190';
module.exports = node;
