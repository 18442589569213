import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";
import environment from "environments/environment.js";

const mutation = graphql`
  mutation sendReportMutation($input: sendReportInput!) {
    sendReport(input: $input) {
      message
    }
  }
`;

export default function (input) {
  return new Promise((res, rej) => {
    commitMutation(environment, {
      mutation,
      variables: { input },
      onCompleted: res,
      onError: rej,
    });
  });
}
