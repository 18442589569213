const queryString = require("query-string");

export const calculateSum = (data, col) => {
  let sum = 0;
  for (let row of data) sum += row[col] || 0;
  return sum;
};

export const getQueryParams = () => {
  const parsed = queryString.parse(window.location.search, {
    arrayFormat: "comma",
  });

  return parsed;
};

export const numberWithCommas = (x) => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
