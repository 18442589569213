import React from "react";

export default function DeleteIcon({ className = "text-[#333333]", size = 28 }) {
  return (
    <svg
      width={size}
      className={className}
      viewBox="0 0 28 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.72949 11.375V16.4792C7.72949 17.2849 8.3821 17.9375 9.18783 17.9375H15.0212C15.8269 17.9375 16.4795 17.2849 16.4795 16.4792V11.375"
        stroke="currentColor"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7 9.1875H17.2083"
        stroke="currentColor"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.6455 9.1875V7H13.5622V9.1875"
        stroke="currentColor"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.1045 12.1042V15.7501"
        stroke="currentColor"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.292 12.1042V15.7501"
        stroke="currentColor"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.91699 12.1042V15.7501"
        stroke="currentColor"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
