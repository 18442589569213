/**
 * @flow
 * @relayHash 2c4a61a70b46fdf49d75d912268e000f
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getOrgLogoQueryVariables = {|
  id?: ?string
|};
export type getOrgLogoQueryResponse = {|
  +authorizedSuborgs: $ReadOnlyArray<{|
    +id: string,
    +name: string,
    +propertyLogoUrl: ?string,
    +propertyManagerLogoUrl: ?string,
    +propertyEqualHousingLogoUrl: ?string,
    +propertyFairHousingLogoUrl: ?string,
    +address: ?any,
    +galleryLink: ?string,
    +floorPlansLink: ?string,
    +amenitiesLink: ?string,
    +contactUsLink: ?string,
    +neighborhoodLink: ?string,
  |}>
|};
export type getOrgLogoQuery = {|
  variables: getOrgLogoQueryVariables,
  response: getOrgLogoQueryResponse,
|};
*/


/*
query getOrgLogoQuery(
  $id: String
) {
  authorizedSuborgs(orgId: "any", first: 1, propertyId: $id) {
    id
    name
    propertyLogoUrl
    propertyManagerLogoUrl
    propertyEqualHousingLogoUrl
    propertyFairHousingLogoUrl
    address
    galleryLink
    floorPlansLink
    amenitiesLink
    contactUsLink
    neighborhoodLink
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "authorizedSuborgs",
    "storageKey": null,
    "args": [
      {
        "kind": "Literal",
        "name": "first",
        "value": 1
      },
      {
        "kind": "Literal",
        "name": "orgId",
        "value": "any"
      },
      {
        "kind": "Variable",
        "name": "propertyId",
        "variableName": "id"
      }
    ],
    "concreteType": "Suborg",
    "plural": true,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "name",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "propertyLogoUrl",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "propertyManagerLogoUrl",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "propertyEqualHousingLogoUrl",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "propertyFairHousingLogoUrl",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "address",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "galleryLink",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "floorPlansLink",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "amenitiesLink",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "contactUsLink",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "neighborhoodLink",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getOrgLogoQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "getOrgLogoQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "getOrgLogoQuery",
    "id": null,
    "text": "query getOrgLogoQuery(\n  $id: String\n) {\n  authorizedSuborgs(orgId: \"any\", first: 1, propertyId: $id) {\n    id\n    name\n    propertyLogoUrl\n    propertyManagerLogoUrl\n    propertyEqualHousingLogoUrl\n    propertyFairHousingLogoUrl\n    address\n    galleryLink\n    floorPlansLink\n    amenitiesLink\n    contactUsLink\n    neighborhoodLink\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5c1cab37b3b4daff2299cfc551706841';
module.exports = node;
