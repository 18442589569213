import moment from "moment";

export const getBanner = () => {
  if (localStorage.getItem("banner")) {
    let c = moment().format("M/D/YYYY");
    let d = moment(localStorage.getItem("banner"));
    let diffDays = d.diff(c, "days");
    if (diffDays <= 0) return false;
    return true;
  } else {
    return false;
  }
};

export const checkVersionIsLatest = (versionA, versionB) => {
  if (versionA && versionB) {
    const versionsA = versionA.split(/\./g);
    const versionsB = versionB.split(/\./g);
    while (versionsA.length || versionsB.length) {
      const a = Number(versionsA.shift());
      const b = Number(versionsB.shift());
      if (a === b) continue;
      return a > b || isNaN(b);
    }
    return false;
  }
};

export const setCurrentDate = () => {
  let c = moment().format("M/D/YYYY");
  localStorage.setItem("banner", c);
};

export const clearAllStorage = () => {
  let banner = localStorage.getItem("banner");
  let version = localStorage.getItem("version");
  let hylybannerExpTime = localStorage.getItem("#hylybanner-exp-time");
  let hylybannerActive = localStorage.getItem("#hylybanner-active");
  // localStorage.clear();
  localStorage.setItem("banner", banner);
  localStorage.setItem("version", version);
  localStorage.setItem("drawer", true);
  if (hylybannerExpTime) localStorage.setItem("#hylybanner-exp-time", hylybannerExpTime);
  if (hylybannerActive) localStorage.setItem("#hylybanner-active", hylybannerActive);
};

export const setUTM = (field, value) => localStorage.setItem(field, value);

export const getUTM = (field) => {
  let utm = localStorage.getItem(field);
  return utm;
};

export const setNotificationId = (id) => {
  localStorage.setItem("notificationId", id);
};

export const getDrawer = () => {
  if (localStorage.getItem("drawer") === "true") return true;
  return false;
};

export const setDrawer = (value) => {
  localStorage.setItem("drawer", value);
};

export const getDimensions = (field) => {
  let dimension = localStorage.getItem(field);
  return dimension;
};

export const setBanner = () => {
  if (localStorage.getItem("banner") && localStorage.getItem("banner") !== "null") return;
  let d = moment().add(1, "M").format("M/D/YYYY");
  localStorage.setItem("banner", d);
};

export const setVersion = (latest, current) => {
  let version = {
    new: latest,
    old: current,
  };
  localStorage.setItem("version", JSON.stringify(version));
};

export const getVersion = () => {
  let versions = JSON.parse(localStorage.getItem("version"));
  if (versions) return versions;
};

export const isLatest = () => {
  let versions = JSON.parse(localStorage.getItem("version"));
  if (versions) {
    const versionsA = versions.new.split(/\./g);
    const versionsB = versions.old.split(/\./g);
    while (versionsA.length || versionsB.length) {
      const a = Number(versionsA.shift());
      const b = Number(versionsB.shift());
      if (a === b) continue;
      return a > b || isNaN(b);
    }
    return false;
  }
  return false;
};

export const getApprovalRequest = () => {
  if (localStorage.getItem("approvalRequest") === "true") return true;
  return false;
};

export const arrDiff = (a1, a2) => {
  let a = [],
    diff = [];
  for (let i = 0; i < a1.length; i++) {
    a[a1[i].name.toUpperCase().replace(/ /g, "").replace(/-/g, "")] = true;
  }
  for (let i = 0; i < a2.length; i++) {
    if (a[a2[i].name.toUpperCase().replace(/ /g, "").replace(/-/g, "")]) {
      delete a[a2[i].name.toUpperCase().replace(/ /g, "").replace(/-/g, "")];
    } else {
      a[a2[i].name.toUpperCase().replace(/ /g, "").replace(/-/g, "")] = true;
    }
  }
  for (let k in a) {
    diff.push(k);
  }
  return diff;
};
