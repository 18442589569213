import React from "react";

export default function TagAddedIcon({ className = "text-[#999999]", width = 18, height = 18 }) {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.371 13.957L13.957 10.371C14.0499 10.2781 14.1236 10.1679 14.1739 10.0465C14.2242 9.92517 14.25 9.79511 14.25 9.66375C14.25 9.53239 14.2242 9.40232 14.1739 9.28097C14.1236 9.15962 14.0499 9.04936 13.957 8.9565L9.25 4.25H4.25V9.25L8.9565 13.9575C9.0494 14.0504 9.15968 14.124 9.28105 14.1743C9.40242 14.2245 9.5325 14.2503 9.66385 14.2503C9.79521 14.2502 9.92527 14.2243 10.0466 14.174C10.1679 14.1237 10.2782 14.0499 10.371 13.957V13.957Z"
        stroke="currentColor"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
      <path
        d="M7.25 8.25C7.80228 8.25 8.25 7.80228 8.25 7.25C8.25 6.69772 7.80228 6.25 7.25 6.25C6.69772 6.25 6.25 6.69772 6.25 7.25C6.25 7.80228 6.69772 8.25 7.25 8.25Z"
        stroke="currentColor"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
    </svg>
  );
}
