import React from "react";
import PropTypes from "prop-types";

import withStyles from "@mui/styles/withStyles";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import Icon from "storybookComponents/Icon";

const styles = (theme) => ({
  root: {
    background: theme.palette.white,
    padding: `0 ${theme.margins.xs}px`,
    border: `1px solid ${theme.palette.lightGrey}`,
    transition: ".2s width",
  },
  input: {
    ...theme.typography.body1,
    lineHeight: 1,
    fontWeight: 400,
    padding: `${theme.margins.xs}px 0`,
  },
  select: {},
  disabled: {},
  focused: {},
  error: {},
  underline: {
    "&:before": {
      borderBottom: `1px solid ${theme.palette.lightGrey}`,
    },
    "&:hover:not($disabled):not($focused):not($error):before": {
      borderBottomColor: theme.palette.mediumGrey,
    },
    "&:after": {
      borderBottom: `1px solid ${theme.palette.deepGrey}`,
    },
  },
});

const SearchInput = ({
  classes: { root, input, underline },
  disableUnderline,
  iconComponent,
  className,
  input_class,
  value,
  set_ref,
  placeholder,
  onKeyDown,
  onChange,
}) => {
  const onKeyDown_ = React.useMemo(
    () => (onKeyDown ? (e) => onKeyDown(e.key) : undefined),
    [onKeyDown]
  );

  return (
    <Input
      classes={{
        root,
        input: `${input} ${input_class || ""}`,
        underline,
      }}
      className={className || ""}
      value={value}
      endAdornment={<Icon type="icon-zoom" size="sm" />}
      onChange={onChange}
      placeholder={placeholder}
      onKeyDown={onKeyDown_}
    />
  );
};

SearchInput.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
    input: PropTypes.string.isRequired,
  }),
  iconComponent: React.isValidElement,
  className: PropTypes.string,
};

SearchInput.defaultProps = {
  iconComponent: null,
  disableUnderline: true,
};

export default React.memo(withStyles(styles)(SearchInput));

////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////
