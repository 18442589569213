import React from "react";
import { createFragmentContainer, QueryRenderer } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import ListUnit from "./ListUnit";

export default createFragmentContainer(ListUnit, {
  item: graphql`
    fragment ListUnit_item on PicklistInterface {
      id
      name
    }
  `,
});
