/**
 * @flow
 * @relayHash 0ca77d7e16d6588c9485863319480eb0
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type gethyblastOverviewQueryVariables = {|
  propertyId: string
|};
export type gethyblastOverviewQueryResponse = {|
  +hyblastOverview: {|
    +hasViewedHyblast: boolean,
    +id: string,
  |}
|};
export type gethyblastOverviewQuery = {|
  variables: gethyblastOverviewQueryVariables,
  response: gethyblastOverviewQueryResponse,
|};
*/


/*
query gethyblastOverviewQuery(
  $propertyId: ID!
) {
  hyblastOverview(propertyId: $propertyId) {
    hasViewedHyblast
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "propertyId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "hyblastOverview",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "propertyId",
        "variableName": "propertyId"
      }
    ],
    "concreteType": "HyblastOverview",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "hasViewedHyblast",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "gethyblastOverviewQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "gethyblastOverviewQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "gethyblastOverviewQuery",
    "id": null,
    "text": "query gethyblastOverviewQuery(\n  $propertyId: ID!\n) {\n  hyblastOverview(propertyId: $propertyId) {\n    hasViewedHyblast\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '46c443013913a90be11e200563383985';
module.exports = node;
