import React from "react";
import Menu from "@mui/material/Menu";

export default React.memo((props) => {
  return (
    <Menu
      PopoverClasses={props.popoverClasses}
      className={props.classes}
      id="simple-submenu"
      anchorEl={props.anchorEl}
      keepMounted
      open={props.open}
      onClose={props.handleClose}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
    >
      {props.children}
    </Menu>
  );
});
