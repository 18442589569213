import React from "react";
import PropTypes from "prop-types";

import withStyles from "@mui/styles/withStyles";

import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import ClickAwayListener from "@mui/material/ClickAwayListener";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";

import Typography from "storybookComponents/Typography";
import Input from "./Inputs/SearchInput";
import Icon from "storybookComponents/Icon";

import QueryRenderer from "../components/QueryRenderer";
import SearchList from "../components/SearchSelect/List";
import SearchListRelay from "../components/SearchSelect/List.relay";
import LocalContainer from "../components/LocalContainer";
import RelayLocalContainer from "../components/LocalContainer.relay";

const styles = (theme) => ({
  list_container: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    minHeight: 0,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    overflowY: "auto",
  },
  icon_class_container: {
    marginTop: -2,
  },
  root_list_item: {
    cursor: "pointer",
    padding: theme.margins.xs,
    "&:hover": {
      background: theme.palette.lightGrey,
    },
  },
  selected: {
    fontWeight: theme.fontWeight.bold,
  },
  input: {
    background: theme.palette.white,
    padding: `0 8px`,
    border: `1px solid ${theme.palette.lightGrey}`,
  },
  paper: {
    borderRadius: 0,
    border: `1px solid ${theme.palette.lightGrey}`,
    boxShadow: "0px 4px 5px #eee",
    flexDirection: "column",
    flex: 1,
    background: "white",
    width: "100%",
    position: "relative",
    zIndex: 999,
  },
  menu_list_container: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
    overflowY: "scroll",
  },
});

const container_styles = (theme) => ({
  input: {
    background: theme.palette.white,
    padding: `0 8px`,
    border: `1px solid ${theme.palette.lightGrey}`,
  },
});

const DropDownSearchContainer = React.memo(
  withStyles(container_styles)(
    ({
      classes,
      children,
      handle_search,
      cancel,
      handle_select,
      selected_search_value,
      search_value,
      handleChange,
      handleKeyDown,
      input_props = {},
      InputSearchComponent,
      ...props
    }) => {
      return (
        <FormControl fullWidth>
          <InputSearchComponent
            value={selected_search_value || search_value}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            search_value={search_value}
            handle_select={handle_select}
            {...input_props}
            className={`${classes.input} ${input_props.input_class || ""}`}
          />
          {children}
        </FormControl>
      );
    }
  )
);

const search_component_styles = (theme) => ({
  paper: {
    borderRadius: 0,
    border: `1px solid ${theme.palette.lightGrey}`,
    boxShadow: "0px 4px 5px #eee",
    flexDirection: "column",
    flex: 1,
    background: "white",
    width: "100%",
    position: "relative",
    zIndex: 999,
  },
});

const QueryDropDownSearchComponent = React.memo(
  withStyles(search_component_styles)(
    ({ classes, search_value, cancel, Component, handle_select, ...props }) => {
      return (
        <ClickAwayListener onClickAway={cancel}>
          <div>
            <QueryRenderer
              ContainerComponent={Paper}
              InnerContainer={MenuList}
              className={classes.paper}
              no_loader={!Boolean(search_value)}
              Component={Component}
              search_value={search_value}
              handle_select={handle_select}
              {...props}
            />
          </div>
        </ClickAwayListener>
      );
    }
  )
);

const DropDownSearchComponent = React.memo(
  withStyles(search_component_styles)(
    ({ classes, search_value, cancel, Component, handle_select, ...props }) => {
      return (
        <ClickAwayListener onClickAway={cancel}>
          <div>
            <Component
              ContainerComponent={Paper}
              InnerContainer={MenuList}
              className={classes.paper}
              no_loader={!Boolean(search_value)}
              search_value={search_value}
              handle_select={handle_select}
              {...props}
            />
          </div>
        </ClickAwayListener>
      );
    }
  )
);

const DropDownSearch = ({
  classes,
  selected_value,
  onSelect,
  icon_type,
  Container,
  InputSearchComponent,
  cancel,
  ...props
}) => {
  const [state, setState] = React.useState({
    search_value: "",
    selected_value: null,
    open: false,
  });

  function handleChange(e) {
    setState({
      open: true,
      selected_value: null,
      search_value: e.target.value,
    });
  }

  function cancel_function(e) {
    setState({
      open: false,
      selected_value: null,
      search_value: "",
    });
    if (cancel && typeof cancel === "function") {
      cancel(e);
    }
  }

  function handle_select(e, item) {
    setState({
      open: false,
      selected_value: item.name,
      search_value: "",
    });
    if (typeof onSelect === "function") {
      onSelect(e, item);
    }
  }

  const Wrapper = React.useMemo(
    () =>
      props.fetch_type === "no-fetch"
        ? LocalContainer
        : (props) => <QueryRenderer {...props} Component={RelayLocalContainer} />,
    [props.fetch_type]
  );

  const DropDownComponent = React.useMemo(() => {
    const ListComponent = props.fetch_type === "no-fetch" ? SearchList : SearchListRelay;

    return React.memo((props) => <DropDownSearchComponent {...props} Component={ListComponent} />);
  }, [props.fetch_type]);

  if (props.fetch_type === "re-fetch") {
    return (
      <DropDownSearchContainer
        handleChange={handleChange}
        handle_select={handle_select}
        selected_search_value={state.selected_value}
        search_value={state.search_value}
        InputSearchComponent={InputSearchComponent}
        input_props={props.input_props}
      >
        {state.open ? (
          <QueryDropDownSearchComponent
            Component={SearchListRelay}
            cancel={cancel_function}
            search_value={state.search_value}
            handle_select={handle_select}
            {...props}
          />
        ) : null}
      </DropDownSearchContainer>
    );
  }

  return (
    <Wrapper
      Container={DropDownSearchContainer}
      ListComponent={DropDownComponent}
      InputSearchComponent={InputSearchComponent}
      cancel={cancel}
      onSelect={handle_select}
      handle_select={handle_select}
      {...props}
    />
  );
};

DropDownSearch.propTypes = {
  classes: PropTypes.object.isRequired,
  input_props: PropTypes.object,
  selected_value: PropTypes.string,
  icon_type: PropTypes.string,
  search_value: PropTypes.string,
  selected_value: PropTypes.string,
  onSelect: PropTypes.func,
  InputSearchComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
};

DropDownSearch.defaultProps = {
  InputSearchComponent: Input,
};

export default React.memo(withStyles(styles)(DropDownSearch));
