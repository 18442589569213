import graphql from "babel-plugin-relay/macro";

export default graphql`
  query getEblastContactsDashboardQuery($dashboardId: ID!, $params: Json) {
    eblastContactsDashboard(dashboardId: $dashboardId, params: $params) {
      id
      campaignName
      createdAt
      entryEmail
      propertyName
      subStatus
      eventIndex
      eventTotal
      entryName
      entryId
      entryUrl
    }
  }
`;
