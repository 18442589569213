import {
  AmenitiesIcon,
  CalendarIcon,
  IntegrationIcon,
  ReportsIcon,
  ReservationIcon,
  TourScheduleIcon,
  TourSiteIcon,
} from "icons/hyTourIcons";
export const hyTourApps = [
  {
    icon: CalendarIcon,
    header: "Agent Calendar",
    name: "Tour Calendars",
  },
  {
    icon: ReservationIcon,
    header: "Amenity Reservations",
    name: "Amenity Calendars",
  },
  {
    icon: TourScheduleIcon,
    header: "Agent Settings: Tour Scheduler",
    name: "Agent Settings: Tour Scheduler",
  },
  {
    icon: AmenitiesIcon,
    header: "Agent Settings: Amenities",
    name: "Agent Settings: Amenities",
  },
  {
    icon: TourScheduleIcon,
    header: "Admin Settings: Tour Scheduler",
    name: "Admin Settings: Tour Scheduler",
  },
  {
    icon: AmenitiesIcon,
    header: "Admin Settings: Amenities",
    name: "Admin Settings: Amenities",
  },
  {
    icon: TourSiteIcon,
    header: "Admin Settings: Tour Site",
    name: "Admin Settings: Tour Site",
  },
  {
    icon: IntegrationIcon,
    header: "Integrations",
    name: "Integrations",
  },
  {
    icon: ReportsIcon,
    header: "Reports",
    name: "Report",
  },
];
