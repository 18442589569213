export const addUTMScript = (noscriptString, scriptString) => {
  if (noscriptString && scriptString) {
    var script = document.createElement("script");
    script.src = "";
    let dataScript = scriptString.split("script>");
    let fun = dataScript[1].split(";</");
    script.innerHTML = fun[0];
    document.head.appendChild(script);
    let rootDiv = document.getElementById("root");
    var noscript = document.createElement("noscript");
    let data = noscriptString.split("iframe");
    noscript.innerHTML = `<iframe` + data[1] + `iframe>`;
    document.body.insertBefore(noscript, rootDiv);
  }
};
