import graphql from "babel-plugin-relay/macro";

export default graphql`
  query getAllMacrosListQuery($propertyId: ID!, $campaignId: ID) {
    macros(propertyId: $propertyId, campaignId: $campaignId) {
      name
      label
      group
      value
      id
    }
  }
`;
