import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  reportsLoading: false,
  reportsLoaded: false,
  propertiesOfOrg: [],
  allCampaigns: [],
  allCampaignsByProperties: [],
  campaignsLoading: false,
  campaignsLoaded: false,
  campaignsMoreLoading: false,
  campaignsPageInfo: null,
  campaigns: [],
  search: "",
  byCampaignsLoading: false,
  byCampaignsLoaded: false,
  byCampaignsData: [],
  byCampaignsRows: [],
  myAnalyticsLoading: false,
  myAnalyticsLoaded: false,
  myAnalyticsReports: [],
  viewData: null,
};

export const rafSlice = createSlice({
  name: "raf",
  initialState,
  reducers: {
    setReportsLoading: (state, action) => {
      state.reportsLoading = action.payload;
    },
    setPropertiesOfOrg: (state, action) => {
      state.propertiesOfOrg = action.payload;
      state.reportsLoaded = true;
    },
    setAllCampaigns: (state, action) => {
      state.allCampaigns = action.payload;
    },
    setAllCampaignsByProperties: (state, action) => {
      state.allCampaignsByProperties = action.payload;
    },

    setCampaignsLoading: (state, action) => {
      state.campaignsLoading = action.payload;
    },
    setCampaignsLoaded: (state, action) => {
      state.campaignsLoaded = action.payload;
    },
    setCampaignsMoreLoading: (state, action) => {
      state.campaignsMoreLoading = action.payload;
    },
    setCampaignsPageInfo: (state, action) => {
      state.campaignsPageInfo = action.payload;
    },
    setCampaigns: (state, action) => {
      state.campaigns = action.payload;
      state.campaignsLoaded = true;
    },
    setSearchGlobal: (state, action) => {
      state.search = action.payload;
    },

    setByCampaignsLoading: (state, action) => {
      state.byCampaignsLoading = action.payload;
    },
    setByCampaignsData: (state, action) => {
      state.byCampaignsData = action.payload;
      state.byCampaignsLoaded = true;
    },
    setByCampaignsRows: (state, action) => {
      state.byCampaignsRows = action.payload;
    },
    setMyAnalyticsLoading: (state, action) => {
      state.myAnalyticsLoading = action.payload;
    },
    setMyAnalyticsReports: (state, action) => {
      state.myAnalyticsReports = action.payload;
      state.myAnalyticsLoaded = true;
    },
    initLoad: (state) => {
      state.reportsLoaded = false;
      state.campaignsLoaded = false;
      state.byCampaignsLoaded = false;
      state.myAnalyticsLoaded = false;
      state.campaignsLoading = true;
    },
    setViewData: (state, action) => {
      state.viewData = action.payload;
    },
  },
});

export const {
  setReportsLoading,
  setPropertiesOfOrg,
  setAllCampaigns,
  setAllCampaignsByProperties,
  setCampaignsLoading,
  setCampaignsLoaded,
  setCampaignsMoreLoading,
  setCampaignsPageInfo,
  setCampaigns,
  setSearchGlobal,
  setByCampaignsLoading,
  setByCampaignsData,
  setByCampaignsRows,
  setMyAnalyticsLoading,
  setMyAnalyticsReports,
  initLoad,
  setViewData,
} = rafSlice.actions;

export const selectReportsLoading = (state) => state.raf.reportsLoading;
export const selectReportsLoaded = (state) => state.raf.reportsLoaded;
export const selectPropertiesOfOrg = (state) => state.raf.propertiesOfOrg;
export const selectAllCampaigns = (state) => state.raf.allCampaigns;
export const selectAllCampaignsByProperties = (state) => state.raf.allCampaignsByProperties;

export const selectCampaignsLoading = (state) => state.raf.campaignsLoading;
export const selectCampaignsLoaded = (state) => state.raf.campaignsLoaded;
export const selectCampaignsMoreLoading = (state) => state.raf.campaignsMoreLoading;
export const selectCampaignsPageInfo = (state) => state.raf.campaignsPageInfo;
export const selectCampaigns = (state) => state.raf.campaigns;
export const selectSearch = (state) => state.raf.search;

export const selectByCampaignsLoading = (state) => state.raf.byCampaignsLoading;
export const selectByCampaignsLoaded = (state) => state.raf.byCampaignsLoaded;
export const selectByCampaignsData = (state) => state.raf.byCampaignsData;
export const selectByCampaignsRows = (state) => state.raf.byCampaignsRows;

export const selectMyAnalyticsLoading = (state) => state.raf.myAnalyticsLoading;
export const selectMyAnalyticsLoaded = (state) => state.raf.myAnalyticsLoaded;
export const selectMyAnalyticsReports = (state) => state.raf.myAnalyticsReports;
export const selectViewData = (state) => state.raf.viewData;

export default rafSlice.reducer;
