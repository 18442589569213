/**
 * @flow
 * @relayHash 8a57d94c99f35a3b7179fbd12270d4e8
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getPreviewQueryVariables = {|
  id: string,
  fields?: ?any,
  subject?: ?string,
|};
export type getPreviewQueryResponse = {|
  +hyblastEditor: {|
    +templateCategoryId: string,
    +templateId: string,
    +propertyId: string,
    +id: string,
    +name: string,
    +subject: string,
    +mms: boolean,
    +mmsBody: string,
    +mmsMediaUrl: ?string,
    +mmsStatus: string,
    +lastTestEmails: string,
    +lastTestPhones: ?string,
    +approvalFeedbacks: ?$ReadOnlyArray<{|
      +id: string,
      +userEmail: string,
      +message: string,
      +createdAt: any,
    |}>,
    +fields: $ReadOnlyArray<{|
      +editable: boolean,
      +dimension: ?string,
      +display: ?string,
      +label: string,
      +field: string,
      +value: ?string,
      +type: string,
    |}>,
    +rawMacrosHtml: string,
    +substitutedMacrosHtml: string,
  |}
|};
export type getPreviewQuery = {|
  variables: getPreviewQueryVariables,
  response: getPreviewQueryResponse,
|};
*/


/*
query getPreviewQuery(
  $id: ID!
  $fields: Json
  $subject: String
) {
  hyblastEditor(id: $id, fields: $fields, subject: $subject) {
    templateCategoryId
    templateId
    propertyId
    id
    name
    subject
    mms
    mmsBody
    mmsMediaUrl
    mmsStatus
    lastTestEmails
    lastTestPhones
    approvalFeedbacks {
      id
      userEmail
      message
      createdAt
    }
    fields {
      editable
      dimension
      display
      label
      field
      value
      type
      id
    }
    rawMacrosHtml
    substitutedMacrosHtml
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "fields",
    "type": "Json",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "subject",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "fields",
    "variableName": "fields"
  },
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  },
  {
    "kind": "Variable",
    "name": "subject",
    "variableName": "subject"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "templateCategoryId",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "templateId",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "propertyId",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "subject",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "mms",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "mmsBody",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "mmsMediaUrl",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "mmsStatus",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lastTestEmails",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lastTestPhones",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "approvalFeedbacks",
  "storageKey": null,
  "args": null,
  "concreteType": "ApprovalFeedback",
  "plural": true,
  "selections": [
    (v5/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "userEmail",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "message",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "createdAt",
      "args": null,
      "storageKey": null
    }
  ]
},
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "editable",
  "args": null,
  "storageKey": null
},
v16 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dimension",
  "args": null,
  "storageKey": null
},
v17 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "display",
  "args": null,
  "storageKey": null
},
v18 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "label",
  "args": null,
  "storageKey": null
},
v19 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "field",
  "args": null,
  "storageKey": null
},
v20 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "value",
  "args": null,
  "storageKey": null
},
v21 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "type",
  "args": null,
  "storageKey": null
},
v22 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rawMacrosHtml",
  "args": null,
  "storageKey": null
},
v23 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "substitutedMacrosHtml",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getPreviewQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "hyblastEditor",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "HyblastEditor",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "fields",
            "storageKey": null,
            "args": null,
            "concreteType": "EditorField",
            "plural": true,
            "selections": [
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/)
            ]
          },
          (v22/*: any*/),
          (v23/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "getPreviewQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "hyblastEditor",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "HyblastEditor",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "fields",
            "storageKey": null,
            "args": null,
            "concreteType": "EditorField",
            "plural": true,
            "selections": [
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/),
              (v5/*: any*/)
            ]
          },
          (v22/*: any*/),
          (v23/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "getPreviewQuery",
    "id": null,
    "text": "query getPreviewQuery(\n  $id: ID!\n  $fields: Json\n  $subject: String\n) {\n  hyblastEditor(id: $id, fields: $fields, subject: $subject) {\n    templateCategoryId\n    templateId\n    propertyId\n    id\n    name\n    subject\n    mms\n    mmsBody\n    mmsMediaUrl\n    mmsStatus\n    lastTestEmails\n    lastTestPhones\n    approvalFeedbacks {\n      id\n      userEmail\n      message\n      createdAt\n    }\n    fields {\n      editable\n      dimension\n      display\n      label\n      field\n      value\n      type\n      id\n    }\n    rawMacrosHtml\n    substitutedMacrosHtml\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4cc67a980873910396d25dd50401b656';
module.exports = node;
