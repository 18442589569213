import React from "react";
import { SvgIcon } from "@mui/material";

export default function PhoneIcon(props) {
  return (
    <svg
      className={props.className}
      width={props.width}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" fill="white" />
      <g clip-path="url(#clip0_1_13245)">
        <path
          d="M12.125 12.125C10.7442 12.125 9.625 11.0058 9.625 9.625V9.125C9.625 7.74425 10.7442 6.625 12.125 6.625C13.5057 6.625 14.625 7.74425 14.625 9.125V9.625C14.625 11.0058 13.5057 12.125 12.125 12.125Z"
          stroke="currentColor"
          stroke-linecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.125 15.839C17.125 15.0637 16.6165 14.382 15.8732 14.161C14.9942 13.8995 13.697 13.625 12.125 13.625C10.553 13.625 9.25575 13.8995 8.37675 14.161C7.6335 14.382 7.125 15.0637 7.125 15.839V17.625H17.125V15.839Z"
          stroke="currentColor"
          stroke-linecap="round"
          strokeLinejoin="round"
        />
      </g>
      <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="currentColor" />
      <defs>
        <clipPath id="clip0_1_13245">
          <rect width="12" height="12" fill="white" transform="translate(6 6)" />
        </clipPath>
      </defs>
    </svg>
  );
}
