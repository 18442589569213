/**
 * @flow
 * @relayHash 1214ef0ad659b80fb82c0adbe9f3e476
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type checkEnrollmentOfHyreferralCampaignQueryVariables = {|
  id: string,
  email: string,
|};
export type checkEnrollmentOfHyreferralCampaignQueryResponse = {|
  +checkEnrollmentOfHyreferralCampaign: {|
    +id: string
  |}
|};
export type checkEnrollmentOfHyreferralCampaignQuery = {|
  variables: checkEnrollmentOfHyreferralCampaignQueryVariables,
  response: checkEnrollmentOfHyreferralCampaignQueryResponse,
|};
*/


/*
query checkEnrollmentOfHyreferralCampaignQuery(
  $id: ID!
  $email: String!
) {
  checkEnrollmentOfHyreferralCampaign(id: $id, email: $email) {
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "email",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "checkEnrollmentOfHyreferralCampaign",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "email",
        "variableName": "email"
      },
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "HyreferralCampaign",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "checkEnrollmentOfHyreferralCampaignQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "checkEnrollmentOfHyreferralCampaignQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "checkEnrollmentOfHyreferralCampaignQuery",
    "id": null,
    "text": "query checkEnrollmentOfHyreferralCampaignQuery(\n  $id: ID!\n  $email: String!\n) {\n  checkEnrollmentOfHyreferralCampaign(id: $id, email: $email) {\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '54adc6718a47c9c6d7a78826c714c835';
module.exports = node;
