import { fetchQuery } from "relay-runtime";
import environment from "environments/environment";

import getcontactGroupsQuery from "./queries/getcontactGroups";
import getCategoryIdQuery from "./queries/getCategoryId";
import getAppsQuery from "./queries/getApps";
import getSettingsQuery from "./queries/getSettings";
import getApprovalRequestsQuery from "./queries/getApprovalRequests";
import setSettingsMutation from "./mutations/setSettings";
import scheduleReportMutation from "./mutations/scheduleReport";
import transformUrl from "./mutations/transformUrl";
import updateSuborgMacro from "./mutations/updateSuborgMacro";
import scheduleReportDelete from "./mutations/scheduleReportDelete";
import addToRecentPageMutation from "./mutations/addToRecentPage";
import deleteRecentPage from "./mutations/deleteRecentPage";
import updateRecentPage from "./mutations/updateRecentPage";
import saveOrgSetting from "./mutations/saveOrgSetting";
import sendReport from "./mutations/sendReport";
import getGtmIdQuery from "./queries/getGtmId";
import getApprovalRequestsQueryV2 from "./queries/getApprovalRequestsV2";
import getSummaryReportsQuery from "./queries/getSummaryReports";
import getApproverSettingsQuery from "./queries/getApproverSettings";
import getOrgLogoQuery from "./queries/getOrgLogo";
import getorgSettingQuery from "./queries/getorgSetting";
import getDripsMenuQuery from "./queries/getDripsMenu";
import getAngelsQuery from "./queries/getAngels";
import getRecentPagesQuery from "./queries/getRecentPages";
import getScheduledReportsQuery from "./queries/getScheduledReports";
import getHygroupLinksQuery from "./queries/getHygroupLinks";
import getListMacrosQuery from "./queries/getListMacros";
import getAllMacrosListQuery from "./queries/getAllMacrosList";
import getaccountsQuery from "./queries/getaccounts";
import setHayleyChatDisclaimer from "./mutations/setHayleyChatDisclaimer";
import gethayleyChatDisclaimerQuery from "./queries/gethayleyChatDisclaimer";
import getFairHousingActQuery from "./queries/getFairHousingAct";
import updateFairHousingAct from "./mutations/updateFairHousingAct";
import resetTenantSetting from "./mutations/resetTenantSetting";
import getAngelsOrgQuery from "./queries/getAngelsOrg";
import getSelectedSuborgDetailsQuery from "./queries/getSelectedSuborgDetail";
import getSelectedSuborgAlertsQuery from "./queries/getSelectedSuborgAlerts";

const getCategoryId = (variables) => fetchQuery(environment, getCategoryIdQuery, variables);

const getcontactGroups = (propertyId, refreshGroupName) => {
  let variables = { propertyId, refreshGroupName };
  return fetchQuery(environment, getcontactGroupsQuery, variables)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

const getApps = (orgId, propertyId) => {
  let variables = { orgId, propertyId };
  return fetchQuery(environment, getAppsQuery, variables)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

const getSettings = (orgId, propertyId) => {
  const variables = { orgId, propertyId };
  return fetchQuery(environment, getSettingsQuery, variables);
};

const getApprovalRequests = (orgId, type, propertyId, first, after) => {
  const variables = { orgId, type, propertyId, first, after };
  return fetchQuery(environment, getApprovalRequestsQuery, variables);
};

const getGtmId = (propertyId) => {
  const variables = { propertyId };
  return fetchQuery(environment, getGtmIdQuery, variables)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

const getApprovalRequestsV2 = (orgId, type, propertyId, first, after) => {
  const variables = { orgId, type, propertyId, first, after };
  return fetchQuery(environment, getApprovalRequestsQueryV2, variables)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

const getSummaryReports = (orgId, type, startDate, endDate, propertyIds, regionIds) => {
  const variables = { orgId, type, startDate, endDate, propertyIds, regionIds };
  return fetchQuery(environment, getSummaryReportsQuery, variables)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

const getRecentPages = (orgId, propertyId, appName, objectType, pageName) => {
  const variables = { orgId, propertyId, appName, objectType, pageName };
  return fetchQuery(environment, getRecentPagesQuery, variables)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

const getScheduledReports = (orgId, type) => {
  const variables = { orgId, type };
  return fetchQuery(environment, getScheduledReportsQuery, variables)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

const getOrgLogo = (id) => {
  let variables = { id };
  return fetchQuery(environment, getOrgLogoQuery, variables)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

const getorgSetting = (orgId, propertyId) => {
  let variables = { orgId, propertyId };
  return fetchQuery(environment, getorgSettingQuery, variables)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

const getApproverSettings = (orgId, propertyId) => {
  return fetchQuery(environment, getApproverSettingsQuery, { orgId, propertyId });
};

const getDripsMenu = (propertyId, angelType, type, orgId) => {
  let variables = { propertyId, angelType, type, orgId };
  return fetchQuery(environment, getDripsMenuQuery, variables)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

const getAngels = (propertyId, id, angelType) => {
  let variables = { propertyId, id, angelType };
  return fetchQuery(environment, getAngelsQuery, variables)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

const getAngelsOrg = (orgId, angelType) => {
  let variables = { orgId, angelType };
  return fetchQuery(environment, getAngelsOrgQuery, variables)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

const getSelectedSuborgDetail = (orgId) => {
  let variables = { orgId };
  return fetchQuery(environment, getSelectedSuborgDetailsQuery, variables)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

const getSelectedSuborgAlerts = (orgId) => {
  let variables = { orgId };
  return fetchQuery(environment, getSelectedSuborgAlertsQuery, variables)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

const getHygroupLinks = (tenantId) => {
  let variables = { tenantId };
  return fetchQuery(environment, getHygroupLinksQuery, variables)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

const getListMacros = (propertyId) => {
  let variables = { propertyId };
  return fetchQuery(environment, getListMacrosQuery, variables)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

const getAccountsList = (orgId, search) => {
  let variables = { orgId, search, readOnly: true };
  return fetchQuery(environment, getaccountsQuery, variables)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

const getAllMacrosList = (propertyId, campaignId) => {
  let variables = { propertyId, campaignId };
  return fetchQuery(environment, getAllMacrosListQuery, variables)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

const gethayleyChatDisclaimer = (orgId, propertyId) => {
  let variables = { orgId, propertyId };
  return fetchQuery(environment, gethayleyChatDisclaimerQuery, variables)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};
const getFairHousingAct = (orgId) => {
  let variables = { orgId };
  return fetchQuery(environment, getFairHousingActQuery, variables)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

export {
  getcontactGroups,
  getCategoryId,
  getApps,
  getSettings,
  setSettingsMutation,
  scheduleReportMutation,
  addToRecentPageMutation,
  sendReport,
  getApprovalRequests,
  getGtmId,
  getApprovalRequestsV2,
  getApproverSettings,
  getOrgLogo,
  getDripsMenu,
  getAngels,
  getSummaryReports,
  getRecentPages,
  deleteRecentPage,
  updateRecentPage,
  getScheduledReports,
  scheduleReportDelete,
  transformUrl,
  getHygroupLinks,
  updateSuborgMacro,
  getListMacros,
  getAccountsList,
  getorgSetting,
  saveOrgSetting,
  getAllMacrosList,
  gethayleyChatDisclaimer,
  getFairHousingAct,
  setHayleyChatDisclaimer,
  updateFairHousingAct,
  resetTenantSetting,
  getAngelsOrg,
  getSelectedSuborgDetail,
  getSelectedSuborgAlerts,
};
