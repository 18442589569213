/**
 * @flow
 * @relayHash 6cd81cd1ef0e20c0cbd98fe2ccc2018b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getHayleyContactsDashboardQueryVariables = {|
  endDate: string,
  startDate: string,
  orgId: string,
  propertyIds: $ReadOnlyArray<string>,
  queryType: string,
|};
export type getHayleyContactsDashboardQueryResponse = {|
  +hayleyContactList: any
|};
export type getHayleyContactsDashboardQuery = {|
  variables: getHayleyContactsDashboardQueryVariables,
  response: getHayleyContactsDashboardQueryResponse,
|};
*/


/*
query getHayleyContactsDashboardQuery(
  $endDate: String!
  $startDate: String!
  $orgId: ID!
  $propertyIds: [String!]!
  $queryType: String!
) {
  hayleyContactList(endDate: $endDate, startDate: $startDate, orgId: $orgId, propertyIds: $propertyIds, queryType: $queryType)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "endDate",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "startDate",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "orgId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "propertyIds",
    "type": "[String!]!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "queryType",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "hayleyContactList",
    "args": [
      {
        "kind": "Variable",
        "name": "endDate",
        "variableName": "endDate"
      },
      {
        "kind": "Variable",
        "name": "orgId",
        "variableName": "orgId"
      },
      {
        "kind": "Variable",
        "name": "propertyIds",
        "variableName": "propertyIds"
      },
      {
        "kind": "Variable",
        "name": "queryType",
        "variableName": "queryType"
      },
      {
        "kind": "Variable",
        "name": "startDate",
        "variableName": "startDate"
      }
    ],
    "storageKey": null
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getHayleyContactsDashboardQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "getHayleyContactsDashboardQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "getHayleyContactsDashboardQuery",
    "id": null,
    "text": "query getHayleyContactsDashboardQuery(\n  $endDate: String!\n  $startDate: String!\n  $orgId: ID!\n  $propertyIds: [String!]!\n  $queryType: String!\n) {\n  hayleyContactList(endDate: $endDate, startDate: $startDate, orgId: $orgId, propertyIds: $propertyIds, queryType: $queryType)\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '023db9aa8a0e3a94be5af3f51717bf0c';
module.exports = node;
