import graphql from "babel-plugin-relay/macro";

export default graphql`
  query getScheduledBlastQuery($propertyId: ID!) {
    scheduledBlast(propertyId: $propertyId) {
      dayData
      hourData
    }
  }
`;
