import React from "react";
import withStyles from "@mui/styles/withStyles";
import { Button } from "storybookComponents";
import { Modal } from "components";
import styles from "./popupStyles";

const DeleteReportPopup = ({
  classes,
  open,
  onRemove,
  onClose,
  name = "",
  header = "Analytics",
}) => {
  return (
    <Modal
      open={open}
      title={<div className={classes.reportHeader}>Remove From My {header}</div>}
      handleClose={onClose}
      className={{
        dialogRoot: classes.dialogRoot,
      }}
      actionChildren={
        <>
          <Button onClick={onClose} variant="tertiary" className={classes.button}>
            Cancel
          </Button>
          <Button variant="secondary" className={classes.removeButton} onClick={onRemove}>
            Remove
          </Button>
        </>
      }
    >
      <div className={classes.reportText}>
        Are you sure you want to Remove “{name}” report from My {header}?
      </div>
    </Modal>
  );
};
export default withStyles(styles)(DeleteReportPopup);
