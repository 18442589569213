import React from "react";

export const EditIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.9417 7.15527L17.4557 10.6693" stroke="#333333" strokeMiterlimit="10" />
    <path
      d="M9.4942 18.6309L5.27734 19.3337L5.98015 15.1169L15.4076 5.68938C15.6712 5.42587 16.0287 5.27783 16.4014 5.27783C16.7741 5.27783 17.1316 5.42587 17.3952 5.68938L18.9217 7.21588C19.1852 7.47947 19.3332 7.83693 19.3332 8.20965C19.3332 8.58237 19.1852 8.93983 18.9217 9.20343L9.4942 18.6309Z"
      stroke="#333333"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
);

export const KeyIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.75 10.75L18.25 12.25L21.25 9.25L19.75 7.75"
      stroke="#333333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.4316 12.5685L20.2501 3.75"
      stroke="#333333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.25 20.25C10.7353 20.25 12.75 18.2353 12.75 15.75C12.75 13.2647 10.7353 11.25 8.25 11.25C5.76472 11.25 3.75 13.2647 3.75 15.75C3.75 18.2353 5.76472 20.25 8.25 20.25Z"
      stroke="#333333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const Logout = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.4377 12H5.125" stroke="#333333" strokeMiterlimit="10" />
    <path
      d="M8.56256 15.409L5.125 11.9999L8.56256 8.59082"
      stroke="#333333"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M10.624 4.5H18.8742V19.5H10.624"
      stroke="#333333"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
);

export const TickIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.5 10.5L6.5 13.5L14.5 4.5"
      stroke="#66D184"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const DotIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="9" cy="10" r="2" fill="#DADADA" />
  </svg>
);

export const Visibility = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.875 10.2085C1.875 10.2085 5.16667 5.2085 10.2083 5.2085C15.2083 5.2085 18.5417 10.2085 18.5417 10.2085C18.5417 10.2085 15.1667 15.2085 10.2083 15.2085C5.20833 15.2085 1.875 10.2085 1.875 10.2085Z"
      stroke="#666666"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M10.2083 11.8752C12.0493 11.8752 13.5417 10.3828 13.5417 8.54183C13.5417 6.70088 12.0493 5.2085 10.2083 5.2085C8.36738 5.2085 6.875 6.70088 6.875 8.54183C6.875 10.3828 8.36738 11.8752 10.2083 11.8752Z"
      stroke="#666666"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
);

export const VisibilityOff = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.5001 7.73193C16.7301 8.89993 17.5001 9.99993 17.5001 9.99993C17.5001 9.99993 14.0001 14.9999 10.0001 14.9999C9.48809 14.9999 8.98509 14.9179 8.49609 14.7749"
      stroke="#666666"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.275 13.725C4.048 12.243 2.5 10 2.5 10C2.5 10 6 5 10 5C11.326 5 12.605 5.553 13.712 6.288"
      stroke="#666666"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 17L17 3"
      stroke="#666666"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const CloseIconRed = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.8125 2.1875L2.1875 11.8125"
      stroke="#E75F5F"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M11.8125 11.8125L2.1875 2.1875"
      stroke="#E75F5F"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
);
