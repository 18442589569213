/**
 * @flow
 * @relayHash 331cab51be777a8a0fcad609945ab46f
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getApproverSettingsQueryVariables = {|
  orgId: string,
  propertyId?: ?string,
|};
export type getApproverSettingsQueryResponse = {|
  +authorizedSuborgs: $ReadOnlyArray<{|
    +id: string,
    +name: string,
    +hyblastSetting: {|
      +approvalRequired: boolean,
      +allowUploadImages: ?boolean,
      +approverEmails: $ReadOnlyArray<string>,
      +agentApprovalEmails: $ReadOnlyArray<string>,
      +id: string,
      +bccEmails: ?$ReadOnlyArray<string>,
      +emailLimits: $ReadOnlyArray<{|
        +groupType: string,
        +weeklyMin: number,
        +weeklyMax: number,
        +monthlyMin: number,
        +monthlyMax: number,
      |}>,
    |},
    +hyeventsSetting: {|
      +approvalRequired: boolean,
      +approverEmails: $ReadOnlyArray<string>,
      +agentApprovalEmails: $ReadOnlyArray<string>,
      +hyeventsBccEmails: ?$ReadOnlyArray<string>,
      +id: string,
    |},
  |}>
|};
export type getApproverSettingsQuery = {|
  variables: getApproverSettingsQueryVariables,
  response: getApproverSettingsQueryResponse,
|};
*/


/*
query getApproverSettingsQuery(
  $orgId: ID!
  $propertyId: String
) {
  authorizedSuborgs(orgId: $orgId, propertyId: $propertyId) {
    id
    name
    hyblastSetting {
      approvalRequired
      allowUploadImages
      approverEmails
      agentApprovalEmails
      id
      bccEmails
      emailLimits {
        groupType
        weeklyMin
        weeklyMax
        monthlyMin
        monthlyMax
        id
      }
    }
    hyeventsSetting {
      approvalRequired
      approverEmails
      agentApprovalEmails
      hyeventsBccEmails
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "orgId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "propertyId",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "orgId",
    "variableName": "orgId"
  },
  {
    "kind": "Variable",
    "name": "propertyId",
    "variableName": "propertyId"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "approvalRequired",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "allowUploadImages",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "approverEmails",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "agentApprovalEmails",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "bccEmails",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "groupType",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "weeklyMin",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "weeklyMax",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "monthlyMin",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "monthlyMax",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "hyeventsSetting",
  "storageKey": null,
  "args": null,
  "concreteType": "HyeventsSetting",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    (v6/*: any*/),
    (v7/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "hyeventsBccEmails",
      "args": null,
      "storageKey": null
    },
    (v2/*: any*/)
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getApproverSettingsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "authorizedSuborgs",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "Suborg",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "hyblastSetting",
            "storageKey": null,
            "args": null,
            "concreteType": "HyblastSetting",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v2/*: any*/),
              (v8/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "emailLimits",
                "storageKey": null,
                "args": null,
                "concreteType": "EmailLimit",
                "plural": true,
                "selections": [
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/)
                ]
              }
            ]
          },
          (v14/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "getApproverSettingsQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "authorizedSuborgs",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "Suborg",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "hyblastSetting",
            "storageKey": null,
            "args": null,
            "concreteType": "HyblastSetting",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v2/*: any*/),
              (v8/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "emailLimits",
                "storageKey": null,
                "args": null,
                "concreteType": "EmailLimit",
                "plural": true,
                "selections": [
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v2/*: any*/)
                ]
              }
            ]
          },
          (v14/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "getApproverSettingsQuery",
    "id": null,
    "text": "query getApproverSettingsQuery(\n  $orgId: ID!\n  $propertyId: String\n) {\n  authorizedSuborgs(orgId: $orgId, propertyId: $propertyId) {\n    id\n    name\n    hyblastSetting {\n      approvalRequired\n      allowUploadImages\n      approverEmails\n      agentApprovalEmails\n      id\n      bccEmails\n      emailLimits {\n        groupType\n        weeklyMin\n        weeklyMax\n        monthlyMin\n        monthlyMax\n        id\n      }\n    }\n    hyeventsSetting {\n      approvalRequired\n      approverEmails\n      agentApprovalEmails\n      hyeventsBccEmails\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '49fe4353f3bdfa0a4b9f808e251fb3b0';
module.exports = node;
