import React from "react";
import withStyles from "@mui/styles/withStyles";
import StyledTable from "components/StyledTable";
import { compare } from "content/utils/commonParsing";
import { Grid } from "@mui/material";

const styles = () => ({
  headerTableMargin: {
    marginTop: 16,
  },
  tableHeader: {
    fontFamily: "Open Sans",
    fontWeight: 600,
    fontSize: 14,
    lineHeight: "20px",
    color: "#333333",
    position: "sticky",
    top: -1,
  },
  tableCell: {
    padding: "22px 16px",
    verticalAlign: "middle",
  },
  tableTextBold: {
    fontSize: 14,
    lineHeight: "20px",
    fontWeight: 600,
    cursor: "pointer",
    width: 638,
    whiteSpace: "noWrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    "&:hover a": {
      color: "var(--hylyTheme)",
    },
    "& a": {
      color: "#333",
      textDecoration: "none",
    },
  },
  tableText: {
    fontSize: 14,
    color: "#333",
    lineHeight: "20px",
  },
  tableParts: {
    flex: "1 1 0px",
  },
  rightSectionContainer: {
    textAlign: "center",
    maxHeight: 400,
    overflowY: "auto",
    "& img": {
      width: "100%",
      height: "auto",
    },
    "@media (min-width: 600px)": {
      paddingLeft: 16,
    },
  },
  tableContainerRoot: {
    borderTop: "0.5px solid #dadada",
    overflowY: "auto",
    borderBottom: "none",
    height: "370px",
  },
});

const csvHeaders = [
  { label: "Link", key: "link" },
  { label: "% Clicks", key: "clicks" },
];

const LinkTable = ({ classes, tableData, columns }) => {
  const [csvData, setCSVData] = React.useState();
  const [rows, setRows] = React.useState();
  const [data, setData] = React.useState(tableData.data);

  React.useEffect(() => {
    handleSort("link", true);
  }, [tableData.data]);

  const getRowsForLinks = (item) => {
    return {
      link: (
        <div className={classes.tableTextBold}>
          <a href={item.link} target="_blank">
            {item.link}
          </a>
        </div>
      ),
      clicks: <div className={classes.tableText}>{item.clicks}</div>,
    };
  };

  const handleSort = (field, order) => {
    let sortedData = data.slice().sort((a, b) => compare(a[field], b[field], !order));
    setCSVData(sortedData);
    setRows(sortedData.map(getRowsForLinks));
  };

  return (
    <Grid container className={classes.headerTableMargin}>
      <Grid item md={8} sm={6} xs={12}>
        <StyledTable
          columns={columns}
          rows={rows}
          sortHandler={handleSort}
          defaultSortBy="link"
          csvHeaders={csvHeaders}
          csvData={csvData}
          fileName={tableData.fileName}
          titlebar={{
            title: tableData.headerTitle,
            export: true,
          }}
        />
      </Grid>
      <Grid item md={4} sm={6} xs={12} className={classes.rightSectionContainer}>
        {tableData.rightSection}
      </Grid>
    </Grid>
  );
};

const LinksTable = ({ classes, data }) => {
  const columns = [
    {
      field: "link",
      fieldName: "Link",
      sortable: true,
      className: classes.tableHeader,
      cellClassName: classes.tableCell,
    },
    {
      field: "clicks",
      fieldName: "% Clicks",
      sortable: true,
      className: classes.tableHeader,
      cellClassName: classes.tableCell,
      width: 100,
    },
  ];

  const renderTable = (tableData, index) => {
    return <LinkTable classes={classes} tableData={tableData} columns={columns} />;
  };

  return <div>{data.map(renderTable)}</div>;
};

export default withStyles(styles)(LinksTable);
