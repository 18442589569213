import React from "react";

function ArrowLeft({ className = "text-[#333333] w-6" }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className={className}>
      <path fill="none" d="M0 0H24V24H0z" transform="matrix(-1 0 0 1 24 0)"></path>
      <path
        stroke="currentColor"
        strokeLinejoin="round"
        d="M15.111 6.556L8.89 11.74l6.222 5.703"
      ></path>
    </svg>
  );
}

export default ArrowLeft;
