import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  defaultGoals: "",
  goalDetailsList: {},
  goalOrgId: "",
  selectedPropertyBlastSort: { field: "propertyName", order: false },
};

export const successCenterSlice = createSlice({
  name: "success_center",
  initialState,
  reducers: {
    setDefaultGoals: (state, action) => {
      state.defaultGoals = action.payload;
    },
    setGoalDetailsList: (state, action) => {
      const { monthYear, data } = action.payload;
      state.goalDetailsList[monthYear] = data;
    },
    setGoalOrgId: (state, action) => {
      state.goalOrgId = action.payload;
    },
    setSelectedPropertyBlastSort: (state, action) => {
      state.selectedPropertyBlastSort = action.payload;
    },
  },
});

export const { setDefaultGoals, setGoalDetailsList, setGoalOrgId, setSelectedPropertyBlastSort } =
  successCenterSlice.actions;

export const selectDefaultGoals = (state) => state.success_center.defaultGoals;
export const selectGoalDetailsList = (state) => state.success_center.goalDetailsList;
export const selectGoalOrgId = (state) => state.success_center.goalOrgId;
export const selectSelectedPropertyBlastSort = (state) =>
  state.success_center.selectedPropertyBlastSort;

export default successCenterSlice.reducer;
