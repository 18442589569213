import React from "react";
import PropTypes from "prop-types";

import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import withStyles from "@mui/styles/withStyles";

import Typography from "../Typography";

import MaskedInput from "react-text-mask";

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={["(", /[1-9]/, /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
      guide
    />
  );
}

const styles = (theme) => ({
  notchedOutline: {
    borderColor: "#ddd !important",
  },
  default_field: {
    marginTop: theme.margins.lg,
    [theme.breakpoints.up("sm")]: {
      // marginTop: theme.margins.xl
      marginTop: theme.margins.lg,
    },
    "&:not(:first-child)": {
      marginTop: theme.margins.xs,
      [theme.breakpoints.up("sm")]: {
        marginTop: theme.margins.sm,
      },
    },
    "& textarea": {
      paddingTop: "0px !important",
    },
  },
  root_boxed: {
    border: `1px solid ${theme.palette.lightGrey}`,
    alignItems: "center",
    marginTop: ".15vmin",
    marginBottom: ".15vmin",
  },
  input_boxed: {
    ...theme.typography.body1,
    padding: theme.spacing_unit.xs,
    flex: 1,
    "&:focus $root_boxed": {
      borderColor: "#26BBED",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
  helperText: {
    ...theme.typography.body2,
    color: theme.palette.deepGrey,
    marginTop: 4,
  },
  error: {
    "&$helperText": {
      ...theme.typography.body2,
      color: theme.palette.errorRed,
    },
    "&$label": {
      color: "#0f0",
    },
  },
  underline: {
    "&:before": {
      borderBottom: `1px solid ${theme.palette.lightGrey}`,
    },
    "&:hover:not($disabled):not($focused):not($error):before": {
      borderBottomColor: "#26BBED",
    },
    "&:after": {
      borderBottom: `1px solid ${theme.palette.deepGrey}`,
    },
  },
  input: {
    ...theme.typography.body1,
    color: theme.palette.black,
    padding: 0, //`2px 0`,
    height: 24,
    paddingTop: 8,
    //display:'inline',
  },
  label: {
    ...theme.subtitle1,
    opacity: 1,
    pointerEvents: "none",
    color: `${theme.palette.deepGrey} !important`,
  },
  positionStart: {
    ...theme.typography.body1,
    color: theme.palette.deepGrey,
    fontSize: 24,
  },
  labelFocused: {},
  disabled: { color: theme.palette.deepGrey },
  focused: {},
  input_root: {
    paddingBottom: 2,
    paddingRight: 2,
    flex: 1,
    alignItems: "start",
  },
  multiline_root: {
    paddingBottom: 4,
  },
  multiline_input: {
    ...theme.typography.body1,
    color: theme.palette.black,
    padding: 0, //`4px 0`,
    display: "inline",
    resize: "vertical",
    minHeight: 112,
  },
});

/*
 * This type of input is unused
 * -----------------------------

const OutlinedInput = React.memo(withStyles(styles)(({ classes, error, ...props  }) => (
  <TextField
      variant = "outlined"
      label = {props.field}
      fullWidth
      error = {Boolean(error)}
      helperText = {error||''}
      InputLabelProps = {{
        shrink: /price/g.test(props.name) || undefined,
      }}
      InputProps = {{
            classes: {
              notchedOutline: classes.notchedOutline,
              input: props.multiline ? classes.multiline_input : undefined,
              multiline: classes.multiline_root
            },
            startAdornment: /price/g.test(props.name) ? (
              <InputAdornment position="start">
                <Typography variant = "body1" color = "deepGrey">$</Typography>
              </InputAdornment>): null,
      }}
      FormHelperTextProps = {{
          classes: {
            root: classes.helperText,
            error: classes.errorClass,
          }
      }}
      {...props}
  />
)));
*/

const StandardInput = React.memo(
  withStyles(styles)(
    ({
      type,
      variant,
      field,
      value,
      error,
      label,
      rows,
      className,
      startAdornment,
      endAdornment,
      description,
      classes: {
        default_field,
        label: labelClass,
        labelFocused,
        input,
        underline,
        disabled,
        focused,
        helperText,
        error: errorClass,
        input_root,
        multiline_root,
        multiline_input,
        positionStart,
      },
      multiline,
      ...other
    }) => {
      const shrink = React.useMemo(
        (name) => (/price/g.test(other.name) && startAdornment !== null ? true : undefined),
        [other.name]
      );
      //placeholder = {variant === "left" && multiline && !/price/g.test(other.name) ? label : undefined }

      return (
        <TextField
          type={type}
          label={variant === "top" ? label : undefined}
          fullWidth
          className={`${default_field} ${className}`}
          InputLabelProps={{
            shrink,
            classes: {
              root: labelClass,
              focused: labelFocused,
            },
            ...other.inputlabelprops,
          }}
          InputProps={{
            classes: {
              input: multiline ? multiline_input : input,
              underline,
              disabled,
              focused,
              multiline: input_root + (multiline ? ` ${multiline_root}` : ""),
            },
            startAdornment:
              startAdornment !== undefined ? (
                startAdornment
              ) : variant === "left" || /^price__|^array_price__/g.test(other.name) ? (
                <InputAdornment position="start" style={{ alignItems: "start" }}>
                  <Typography variant="body1" color="deepGrey">
                    {variant === "left"
                      ? `${/^price__/g.test(other.name) ? "$" : Boolean(label) ? label : ""}`
                      : "$"}
                  </Typography>
                </InputAdornment>
              ) : null,
            endAdornment:
              endAdornment !== undefined ? (
                endAdornment
              ) : /^size__|^array_size__/.test(other.name) && !multiline ? (
                <InputAdornment position="end">
                  {Boolean(endAdornment) ? (
                    React.cloneElement(endAdornment)
                  ) : (
                    <Typography style={{ whiteSpace: "nowrap" }} variant="body1" color="deepGrey">
                      sq. ft
                    </Typography>
                  )}
                </InputAdornment>
              ) : null,
            inputComponent: /^phone__|^array_phone__/.test(other.name) ? TextMaskCustom : undefined,
            ...other.input_props,
          }}
          FormHelperTextProps={{
            classes: {
              root: helperText,
              error: errorClass,
            },
          }}
          error={Boolean(error)}
          multiline={multiline}
          helperText={error || description || ""}
          {...other}
          value={value}
          variant={other.fieldVariant || "standard"}
        />
      );
    }
  )
);

StandardInput.propTypes = {
  variant: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  no_label: PropTypes.bool,
  value: PropTypes.string,
  multiline: PropTypes.bool,
  handleChange: PropTypes.func,
  disabled: PropTypes.bool,
};

StandardInput.defaultProps = {
  variant: "top",
};

export default React.memo((props) => {
  switch (props.variant) {
    //  case "outlined": return <OutlinedInput {...props} />;
    default:
      return <StandardInput {...props} />;
  }
});
