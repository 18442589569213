import React, { useState, useRef, useEffect } from "react";
import { ResponsiveBar } from "@nivo/bar";
import makeStyles from "@mui/styles/makeStyles";
import { useContainerDimensions, getDimensions } from "./lineCards";

const getTextColor = (color) => {
  if (color === "#7E6CEC" || color === "#6B5EFF" || color === "#AA66FF" || color === "#A86761") {
    return "#FFF";
  } else {
    return "#333";
  }
};

const theme = {
  tooltip: {
    container: {
      background: "#fff",
    },
  },
  labels: {
    text: {
      fontWeight: 600,
    },
  },
  background: "#ffffff",
  textColor: "#333333",
  fontFamily: "Open Sans",
  fontSize: 13,
};

const useStyles = makeStyles({
  root: {
    height: "400px",
    paddingBottom: "50px",
    paddingLeft: 15,
  },
  details: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: 25,
    paddingLeft: 25,
    paddingRight: 25,
  },
  toolTipContainer: {
    display: "flex",
    alignItems: "center",
  },
  fullPane: { width: "100%", height: "100%" },
});

const BarCardsDetailsPage = (props) => {
  const componentRef = useRef();
  const { width: deviceWidth, height } = useContainerDimensions(componentRef);

  const classes = useStyles();

  let [width, setWidth] = useState(deviceWidth);

  let [timer, setTimer] = useState(null);
  let [timer2, setTimer2] = useState(null);

  let keys = props.keys;

  useEffect(() => {
    // eslint-disable-next-line
    if (width == deviceWidth) return;

    clearTimeout(timer);
    clearTimeout(timer2);
    setTimer(
      setTimeout(() => {
        setWidth(deviceWidth - 10);
      }, 500)
    );

    setTimer2(
      setTimeout(() => {
        setWidth(deviceWidth);
      }, 1500)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data, props.title, props.x, props.y, deviceWidth, height]);

  window.addEventListener("Drawer_open_close", (e) => {
    if (!componentRef.current) return;
    let { width: deviceWidth } = getDimensions(componentRef);

    // if (width == deviceWidth) return;

    setWidth(deviceWidth);
  });

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  // eslint-disable-next-line
  if (typeof keys == "string") keys = [keys];

  const renderTick = ({
    opacity,
    textAnchor,
    textBaseline,
    textX,
    textY,
    value,
    x,
    y,
    animatedProps,
    tickIndex,
    ...others
  }) => {
    // eslint-disable-next-line
    if (typeof value == "number" && Number(value) % 1 != 0) return null;

    let sampleItem = props.data[0] || {};
    sampleItem = sampleItem[props.indexBy] || "";

    let labelwidth = props.data.length * sampleItem.length * 10;

    // now get the labels to skip
    let skipp = Math.ceil(labelwidth / (width - (props.margin || {}).right || 0));

    // get the length of all items in data

    if (tickIndex % skipp > 0) return null;
    // eslint-disable-next-line
    let showable =
      typeof value == "number"
        ? props.data.length - 1 == tickIndex
          ? Math.ceil(value)
          : parseInt(value)
        : value;

    return (
      <g transform={`translate(${x},${y})`} style={{ opacity: animatedProps.opacity }}>
        <text
          alignmentBaseline={textBaseline}
          textAnchor={textAnchor}
          transform={`translate(${textX},${textY})`}
          style={{ fontSize: 13 }}
        >
          {props.formatLeft ? props.formatLeft(showable) : showable}
        </text>
      </g>
    );
  };

  let defaultExtraProps = {
    axisLeft: props.axisLeft || {
      orient: "left",
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
    },
    axisBottom: props.axisBottom || {
      tickSize: 5,
      tickPadding: 5,
      tickRotation: props.rotation && -30,
    },
  };

  if (props.noDecimalLeft) {
    defaultExtraProps.axisLeft = defaultExtraProps.axisLeft || {};
    defaultExtraProps.axisLeft.renderTick = renderTick;
  }

  if (props.noDecimalBottom) {
    defaultExtraProps.axisBottom = defaultExtraProps.axisBottom || {};
    defaultExtraProps.axisBottom.renderTick = renderTick;
  }

  return (
    <div ref={componentRef} className={classes.fullPane}>
      <ResponsiveBar
        data={props.data}
        tooltip={(point) => {
          return (
            <div className={classes.toolTipContainer}>
              <div
                style={{
                  width: "12px",
                  height: "12px",
                  backgroundColor: `${point.color}`,
                  marginRight: "0.5rem",
                }}
              ></div>{" "}
              {props.title} - {point.indexValue}:
              <strong>
                {" "}
                <span style={{ marginLeft: "0.5rem" }}>{numberWithCommas(point.value)}</span>
              </strong>
            </div>
          );
        }}
        keys={[keys]}
        label={(d) => `${numberWithCommas(d.value)}`}
        indexBy={props.indexby}
        margin={{ top: 20, right: 30, bottom: 50, left: props.left }}
        padding={0.3}
        layout="horizontal"
        colors={
          props.color || [
            "#FFC656",
            "#78D9C9",
            "#C1C35D",
            "#C1B7DB",
            "#FF6F69",
            "#77B1D5",
            "#DDE765",
            "#FFC7E4",
            "#DCD5D5",
            "#54494E",
            "#C872BD",
            "#C3F0C5",
            "#E0C081",
            "#B58194",
            "#90A8B6",
            "#C79898",
            "#F5D656",
            "#94E3F8",
            "#806062",
            "#D4885E",
          ]
        }
        defs={[
          {
            id: "lines",
            type: "patternLines",
            background: "inherit",
            color: props.contrast, //{props.contrast} //eed312
            rotation: -45,
            lineWidth: 2,
            spacing: 12,
          },
        ]}
        fill={[
          {
            match: {
              id: "leads",
            },
            id: "lines",
          },
          {
            match: {
              id: "tours",
            },
            id: "lines",
          },
          {
            match: {
              id: "applications",
            },
            id: "lines",
          },
        ]}
        borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
        axisTop={null}
        axisRight={null}
        theme={theme}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
        }}
        // axisLeft={{
        //   tickSize: 5,
        //   tickPadding: 5,
        //   tickRotation: 0,
        // }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={getTextColor(props.color)}
        animate={true}
        motionStiffness={90}
        motionDamping={15}
        {...defaultExtraProps}
        axisLeft={{
          renderTick: ({ opacity, textAnchor, textBaseline, textX, textY, theme, value, x, y }) => {
            // based on the left property, break the texts
            let maxWidth = props.left || 10;
            // pick the first nth item
            let value2 = value.slice(0, value.length);
            // now split text after the first maxWidth
            // pr
            if (value2.length * 8 > maxWidth && props.allowDoubleLines) {
              let firstLine = value2.slice(0, Math.round(maxWidth / 8));
              let secondLine = value2.slice(Math.round(maxWidth / 8), Math.round(maxWidth / 8) * 2);

              if (secondLine.length + firstLine.length < value2.length)
                secondLine = secondLine.slice(0, secondLine.length - 3) + " ...";

              value2 = [firstLine, secondLine];
            } else {
              let line = value2.slice(0, Math.round(maxWidth / 8));
              if (line.length < value2.length) line = line.slice(0, line.length - 3) + " ...";

              value2 = [line];
            }

            return (
              <g transform={`translate(${x},${y})`} style={{ opacity }}>
                {value2.map((value, i) => {
                  return (
                    <text
                      title={value}
                      alignmentBaseline={textBaseline}
                      style={{ fontSize: 13 }}
                      textAnchor={textAnchor}
                      transform={`translate(${textX},${
                        // eslint-disable-next-line
                        textY + (value2.length == 1 ? 0 : i == 0 ? -10 : 10)
                      })`}
                    >
                      {value}
                    </text>
                  );
                })}
              </g>
            );
          },
        }}
      />
    </div>
  );
};

export default BarCardsDetailsPage;
