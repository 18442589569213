/**
 * @flow
 * @relayHash 9d44488e4153c4452645df50d150cbe4
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type listPropertyCorpusFaqsQueryVariables = {|
  propertyId: string
|};
export type listPropertyCorpusFaqsQueryResponse = {|
  +listPropertyCorpusFaqs: $ReadOnlyArray<{|
    +createdUser: ?string,
    +faqType: ?string,
    +id: string,
    +orgName: ?string,
    +propertyName: ?string,
    +questionId: ?string,
    +subject: ?string,
    +tenantId: ?string,
    +value: ?string,
  |}>
|};
export type listPropertyCorpusFaqsQuery = {|
  variables: listPropertyCorpusFaqsQueryVariables,
  response: listPropertyCorpusFaqsQueryResponse,
|};
*/


/*
query listPropertyCorpusFaqsQuery(
  $propertyId: String!
) {
  listPropertyCorpusFaqs(propertyId: $propertyId) {
    createdUser
    faqType
    id
    orgName
    propertyName
    questionId
    subject
    tenantId
    value
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "propertyId",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "listPropertyCorpusFaqs",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "propertyId",
        "variableName": "propertyId"
      }
    ],
    "concreteType": "PropertyCorpusFaq",
    "plural": true,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "createdUser",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "faqType",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "orgName",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "propertyName",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "questionId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "subject",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "tenantId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "value",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "listPropertyCorpusFaqsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "listPropertyCorpusFaqsQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "listPropertyCorpusFaqsQuery",
    "id": null,
    "text": "query listPropertyCorpusFaqsQuery(\n  $propertyId: String!\n) {\n  listPropertyCorpusFaqs(propertyId: $propertyId) {\n    createdUser\n    faqType\n    id\n    orgName\n    propertyName\n    questionId\n    subject\n    tenantId\n    value\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1d19500cbb565407c272ebc446ffbb35';
module.exports = node;
