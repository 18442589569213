import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedPropertyAuditSort: { field: "days_since_last_send", order: false },
  selectedByPropertySort: { field: "annotation", order: true },
  selectedByBlastSort: { field: "sent_at_percentage", order: false },
  selectedDripsByPropertySort: { field: "annotation", order: true },
};

export const tableSortSlice = createSlice({
  name: "tableSort",
  initialState,
  reducers: {
    setSelectedPropertyAuditSort: (state, action) => {
      state.selectedPropertyAuditSort = action.payload;
    },
    setSelectedByPropertySort: (state, action) => {
      state.selectedByPropertySort = action.payload;
    },
    setSelectedByBlastSort: (state, action) => {
      state.selectedByBlastSort = action.payload;
    },
    setSelectedDripsByPropertySort: (state, action) => {
      state.selectedDripsByPropertySort = action.payload;
    },
  },
});

export const {
  setSelectedPropertyAuditSort,
  setSelectedByPropertySort,
  setSelectedByBlastSort,
  setSelectedDripsByPropertySort,
} = tableSortSlice.actions;

export const selectSelectedPropertyAuditSort = (state) => state.tableSort.selectedPropertyAuditSort;
export const selectSelectedByPropertySort = (state) => state.tableSort.selectedByPropertySort;
export const selectSelectedByBlastSort = (state) => state.tableSort.selectedByBlastSort;
export const selectSelectedDripsByPropertySort = (state) =>
  state.tableSort.selectedDripsByPropertySort;

export default tableSortSlice.reducer;
