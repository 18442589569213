import React from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import MenuProps from "../dropDown/menuProps";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import debounce from "lodash/debounce";
import { DropDownIconComponent, HylyCheckbox } from "../dropDown/icons";
import styles, { analyzeTemplate, select_class_for_menu } from "../dropDown/common_styles";

const SelectCheckboxField = withStyles(styles)(
  ({
    classes: {
      disabled,
      select_field,
      setBold,
      root,
      root_field,
      selected,
      menuItem,
      listItemText,
      focused,
      input_field,
      underline,
      checked,
      box_root,
      colorSecondary,
      ...other_classes
    },
    value,
    values,
    name,
    open,
    handleOpen,
    handleClose,
    select_disabled,
    drop_down_icon_path,
    icon_adornment_size,
    icon_component_path,
    im_hovered_upon,
    onChange,
    selected_field,
    ...props
  }) => {
    return (
      <Select
        multiple
        value={value}
        onChange={onChange}
        disabled={select_disabled}
        IconComponent={(props) => (
          <DropDownIconComponent
            size={"sm"}
            select_disabled={select_disabled}
            drop_down_icon_path={drop_down_icon_path}
            is_field
            {...props}
          />
        )}
        fullWidth
        renderValue={(selected) => analyzeTemplate(selected_field, selected.length)}
        classes={{ root }}
        input={<Input classes={{ input: root_field, underline }} />}
        MenuProps={MenuProps({
          paper_class: select_class_for_menu({
            direction: "left",
            classes: other_classes,
            no_adornment: !Boolean(icon_component_path),
          }),
          direction: "left",
        })}
        name={name}
        onClick={(e) => handleOpen(e, select_disabled)}
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        {...props}
      >
        {values.map((name) => (
          <MenuItem
            key={name}
            value={name}
            classes={{
              root: menuItem,
              selected,
            }}
          >
            <HylyCheckbox checked={value.indexOf(name) > -1} className={box_root} />
            <ListItemText
              primary={name}
              value={name}
              name={name}
              aria-label={name}
              classes={{
                root: listItemText,
                primary: name === value ? setBold : "",
              }}
            />
          </MenuItem>
        ))}
      </Select>
    );
  }
);

SelectCheckboxField.propTypes = {
  classes: PropTypes.shape({
    disabled: PropTypes.string.isRequired,
    select: PropTypes.string.isRequired,
    setBold: PropTypes.string.isRequired,
    root_field: PropTypes.string.isRequired,
    selected: PropTypes.string.isRequired,
    listItemText: PropTypes.string.isRequired,
  }),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]).isRequired,
  values: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  open: PropTypes.bool,
  handleOpen: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  select_disabled: PropTypes.bool,
  drop_down_icon_path: PropTypes.string,
  icon_adornment_size: PropTypes.string,
  icon_component_path: PropTypes.string,
  selected_field: PropTypes.string,
};

SelectCheckboxField.defaultProps = {
  values: [],
  value: [],
  selected_field: "selected state+s",
  drop_down_icon_path: "icon-down-arrow",
};

const SelectField = withStyles(styles)(
  ({
    classes: {
      disabled,
      select_field,
      setBold,
      root,
      root_field,
      selected,
      menuItem,
      listItemText,
      focused,
      input_field,
      underline,
      checked,
      errorClass,
      ...other_classes
    },
    value,
    values,
    handleChange,
    name,
    open,
    handleOpen,
    handleClose,
    select_disabled,
    drop_down_icon_path,
    icon_adornment_size,
    icon_component_path,
    im_hovered_upon,
    onChange,
    selected_field,
    ...props
  }) => {
    return (
      <Select
        value={value}
        onChange={onChange}
        disabled={select_disabled}
        IconComponent={(props) => (
          <DropDownIconComponent
            size={"sm"}
            select_disabled={select_disabled}
            drop_down_icon_path={drop_down_icon_path}
            is_field
            {...props}
          />
        )}
        fullWidth
        renderValue={(selected) => analyzeTemplate(selected_field, selected)}
        classes={{ root }}
        input={<Input classes={{ input: root_field, underline }} />}
        MenuProps={MenuProps({
          paper_class: select_class_for_menu({
            direction: "left",
            classes: other_classes,
            no_adornment: !Boolean(icon_component_path),
          }),
          direction: "left",
        })}
        name={name}
        onClick={(e) => handleOpen(e, select_disabled)}
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        {...props}
      >
        {values.map((name) => (
          <MenuItem
            key={name}
            value={name}
            classes={{
              root: menuItem,
              selected,
            }}
          >
            <ListItemText
              primary={name}
              value={name}
              name={name}
              aria-label={name}
              classes={{
                root: listItemText,
                primary: name === value ? setBold : "",
              }}
            />
          </MenuItem>
        ))}
      </Select>
    );
  }
);

SelectField.propTypes = {
  classes: PropTypes.shape({
    disabled: PropTypes.string.isRequired,
    select: PropTypes.string.isRequired,
    setBold: PropTypes.string.isRequired,
    root_field: PropTypes.string.isRequired,
    selected: PropTypes.string.isRequired,
    listItemText: PropTypes.string.isRequired,
  }),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]).isRequired,
  values: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  open: PropTypes.bool,
  handleOpen: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  select_disabled: PropTypes.bool,
  drop_down_icon_path: PropTypes.string,
  icon_adornment_size: PropTypes.string,
  icon_component_path: PropTypes.string,
  selected_field: PropTypes.string,
};

SelectField.defaultProps = {
  values: [],
  value: "",
  selected_field: "selected",
  drop_down_icon_path: "icon-down-arrow",
};

const form_styles = (theme) => ({
  formControl: {
    width: "100%",
    marginTop: theme.margins.lg,
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.margins.xl,
    },
  },
  formControl_with_border: {
    border: `1px solid ${theme.palette.lightGrey}`,
  },
  labelClass: {
    ...theme.subtitle1,
    color: `${theme.palette.deepGrey} !important`,
  },
  helperText: {
    marginTop: 4,
    ...theme.typography.body2,
    color: theme.palette.errorRed,
  },
});

class HylySelectField extends React.PureComponent {
  state = {
    open: false,
    im_hovered_upon: false,
  };

  componentDidMount() {
    this.hasListeneter = true;
    //  window.addEventListener('resize', debounce(this.handleClose, 300, { leading: true, trailing: false }));
  }

  componentWillUnmount() {
    //  window.removeEventListener('resize', debounce(this.handleClose, 300, { leading: true, trailing: false }));
    this.hasListeneter = false;
  }

  handleClose = () => {
    if (this.state.open && this.hasListeneter) {
      this.setState({ open: false });
    }
  };

  handleOpen = (e, disabled) => {
    if (!this.state.open && !disabled && this.hasListeneter) {
      this.setState({ open: true, im_hovered_upon: false });
    }
  };

  hover = (e, enter, disabled) => {
    if (!disabled && this.hasListeneter) {
      this.setState({ im_hovered_upon: enter });
    }
  };

  get_component = (variant) => {
    switch (variant) {
      case "select-checkbox":
        return SelectCheckboxField;
      case "select":
      default:
        return SelectField;
    }
  };
  render() {
    const {
      variant,
      type,
      className,
      label,
      error,
      classes: { formControl, labelClass, helperText },
      ...props
    } = this.props;

    const Component = this.get_component(variant);

    return (
      <FormControl error={Boolean(error)} className={`${formControl} ${className}`}>
        {Boolean(label) && (
          <InputLabel htmlFor={`label-${label}`} classes={{ formControl: labelClass }}>
            {label}
          </InputLabel>
        )}
        <Component
          {...this.state}
          handleClose={this.handleClose}
          handleOpen={this.handleOpen}
          onMouseEnter={debounce((e) => this.hover(e, true), 150)}
          onMouseLeave={debounce(this.hover, 150)}
          {...props}
        />
        <FormHelperText className={helperText}>{error || ""}</FormHelperText>
      </FormControl>
    );
  }
}

HylySelectField.propTypes = {
  variant: PropTypes.oneOf(["select", "select-checkbox"]).isRequired,
};

HylySelectField.defaultProps = {
  variant: "select",
};

export default withStyles(form_styles)(HylySelectField);
