export default () => ({
  divWidth: {
    width: "inherit",
  },
  dimondIcon: {
    height: 6,
    width: 6,
    backgroundColor: "black",
    transform: "rotate(45deg)",
    "&:hover": {
      backgroundColor: "#26BBED",
    },
  },
  alignMent: {
    textAlign: "-webkit-center !important",
  },
  iconRotate: {
    transform: "rotate(180deg)",
  },
  topContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: "14px",
    height: 24,
  },
  leftContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    fontSize: "14px",
    lineHeight: "20px",
    width: 200,
  },
  rightContainer: {
    display: "flex",
    alignItems: "center",
    // justifyContent: "space-between",
    justifyContent: "center",
    gap: "10px",
    fontSize: "14px",
    width: 80,
  },
  leftMargin: {
    marginRight: 2,
  },
  monthView: {
    display: "flex",
    justifyContent: "left",
    alignItems: "center",
    "&:hover": {
      color: "var(--hylyTheme)",
    },
    "&:hover path": {
      stroke: "var(--hylyTheme)",
    },
  },
  yearView: {
    marginLeft: 8,
    width: 70,
    display: "flex",
    justifyContent: "left",
    alignItems: "center",
    "&:hover": {
      color: "var(--hylyTheme)",
    },
    "&:hover path": {
      stroke: "var(--hylyTheme)",
    },
  },
});
