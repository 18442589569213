import React from "react";

export default function SendUserIcon({ className = "text-[#999999]", width = 18, height = 18 }) {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.25 9.75C6.838 9.75 5.5855 10.069 4.7625 10.3465C4.155 10.552 3.75 11.1245 3.75 11.766V13.75C3.75 13.75 7.729 13.75 7.75 13.75"
        stroke="currentColor"
        stroke-miterlimit="10"
      />
      <path
        d="M8.25 9.75C6.8695 9.75 5.75 8.1305 5.75 6.75V6.25C5.75 4.8695 6.8695 3.75 8.25 3.75C9.6305 3.75 10.75 4.8695 10.75 6.25V6.75C10.75 8.1305 9.6305 9.75 8.25 9.75Z"
        stroke="currentColor"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
      <path
        d="M14.8125 11.8125L12.1875 9.5625V11.0749C10.7696 11.0749 9.1875 11.625 9.1875 14.0625C9.92587 12.867 11.0625 12.5625 12.1875 12.5625V14.0625L14.8125 11.8125Z"
        stroke="currentColor"
        stroke-width="0.75"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
