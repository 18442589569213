import React from "react";

export default function hyFormsIcon({ width, height, className = "text-[#999999]" }) {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 3H3V10.2H15V3Z"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.9999 12.6H9.3999V15H14.9999V12.6Z"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path d="M3 5.39999H15" stroke="currentColor" stroke-linecap="round" strokeLinejoin="round" />
    </svg>
  );
}
