import React from "react";
import PropTypes from "prop-types";
import { createFragmentContainer } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import ListItem from "./ListItem";

export default createFragmentContainer(ListItem, {
  item: graphql`
    fragment ListItem_item on PicklistInterface {
      id
      name
      region
      marketerEmails
      approvalRequired
    }
  `,
});
