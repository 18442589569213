import React from "react";
import withStyles from "@mui/styles/withStyles";
import SelectorComponent from "components/SelectorComponents";
import DateRangePicker from "components/DateRangePicker";

import NormalSearchFilter from "./NormalSearchFilter";

const sortByValue = (a, b, order) => {
  const valueA = a.value.toLowerCase();
  const valueB = b.value.toLowerCase();
  if (order === "asc") {
    return valueA.localeCompare(valueB);
  } else if (order === "dsc") {
    return valueB.localeCompare(valueA);
  }
  return 0;
};

const FilterBar = ({
  classes,
  filterOptions,
  customFilterOptions,
  endComponent,
  startComponent,
  appbarFilter,
}) => {
  const sortedFilterOptions = filterOptions.map((filterOption) => {
    const sortOrder = filterOption.sort;

    if (filterOption.optionValue) {
      return {
        ...filterOption,
        optionValue: [...filterOption.optionValue].sort((a, b) => sortByValue(a, b, sortOrder)),
      };
    }
    return filterOption;
  });

  const renderFilterOptions = () =>
    sortedFilterOptions.map((filterOption, index) => {
      return (
        <div key={index} className={classes.wrapper}>
          {index > 0 && <div className={classes.divider} />}
          {filterOption.type === "selector" && (
            <SelectorComponent
              optionValue={filterOption.optionValue}
              placeholder={filterOption.placeholder}
              searchPlaceHolder={filterOption.searchPlaceHolder}
              onChange={filterOption.onChange}
              multiple={filterOption.multiple}
              singleSelect={filterOption.singleSelect}
              value={filterOption.value}
              selectAllText={filterOption.selectAllText}
              getCustomLabel={filterOption.getCustomLabel}
              getCustomCount={filterOption.getCustomCount}
              disableSearch={filterOption.disableSearch}
              disableAllSelect={filterOption.disableAllSelect}
              width={filterOption.width}
              height={filterOption.height}
              groupBy={filterOption.groupBy}
              classes={filterOption.classes}
              isDisabled={filterOption.isDisabled}
              tooltip_Position={filterOption.tooltip_Position}
              tooltip_Text={filterOption.tooltip_Text}
              isTooltip={filterOption.isTooltip}
            />
          )}
          {filterOption.type === "dateRange" && (
            <DateRangePicker
              timezoneOffset={filterOption.timezoneOffset}
              value={filterOption.value}
              position={filterOption.position}
              handleApply={filterOption.handleApply}
              blockSelector={filterOption.blockSelector}
              disabledDay={filterOption.disabledDay}
              {...filterOption.datePickerProps}
            />
          )}
          {/* This filter type is added for mJAI list page filterbar */}
          {filterOption.type === "normalSearch" && (
            <NormalSearchFilter
              label={filterOption.label}
              placeholder={filterOption.placeholder}
              onFilter={filterOption.onChange}
              value={filterOption.value}
            />
          )}
        </div>
      );
    });

  const renderCustomFilterOptions = () => (
    <>
      <div className={classes.divider} />
      {customFilterOptions}
    </>
  );

  return (
    <div className={appbarFilter ? classes.CustomRoot : classes.root}>
      <div className={classes.flexBox}>
        <div style={{ marginRight: "1rem" }}> {startComponent}</div>
        {!!sortedFilterOptions && renderFilterOptions()}
        {!!customFilterOptions && renderCustomFilterOptions()}
      </div>
      <div>{endComponent}</div>
    </div>
  );
};

const styles = {
  root: {
    background: "white",
    border: "1px solid #DADADA",
    height: 52,
    marginBottom: 12,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0px 16px",
  },
  CustomRoot: {
    background: "white",
    height: 52,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    // padding: 16,
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
  },
  divider: {
    width: 1,
    height: 24,
    margin: "0px 20px",
    background: "#DADADA",
  },
  flexBox: {
    display: "flex",
    alignItems: "center",
  },
};

export default withStyles(styles)(FilterBar);
