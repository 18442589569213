import graphql from "babel-plugin-relay/macro";

export default graphql`
  query getDripEditorPreviewQuery(
    $angelId: ID!
    $id: ID!
    $fields: Json
    $subject: String
    $sliceOrder: String
  ) {
    thenEmailEditor(
      angelId: $angelId
      id: $id
      fields: $fields
      subject: $subject
      sliceOrder: $sliceOrder
    ) {
      angel {
        name
      }
      name
      subject
      active
      mms
      mmsBody
      mmsMediaUrl
      mmsStatus
      lastTestEmails
      lastTestPhones
      fields {
        editable
        label
        field
        dimension
        value
        type
        display
        sliceId
        sliceName
        elementId
        elementPart
      }
      rawMacrosHtml
      substitutedMacrosHtml
    }
  }
`;
