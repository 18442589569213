import React from "react";
import withStyles from "@mui/styles/withStyles";
import Button from "storybookComponents/Button";
import Modal from "../components/modal";
import Field from "../components/textField";
import updateProfile from "relay/common/mutations/updateProfile";
import imageAsset from "relay/common/mutations/imageAsset";
import { getErrorMessage, checkUncatchableError } from "environments/uncatchableErrorStore";

const styles = () => ({
  button: {
    height: 40,
    padding: "10px 24px",
    fontSize: 14,
    lineHeight: "20px",
    fontWeight: 600,
    marginLeft: 12,
    textTransform: "none",
  },
  header: {
    fontFamily: "Open Sans",
    fontSize: 14,
    fontWeight: 600,
    color: "rgba(51, 51, 51, 0.75)",
    lineHeight: "20px",
    margin: "12px 0px",
  },
  bodyContainer: {
    padding: "4px 0px 16px 0px",
  },
  image: {
    height: 60,
    width: 60,
    borderRadius: "30px",
  },
  imageContainer: {
    height: 60,
    width: 60,
    background: "#f9f9f9",
    borderRadius: "30px",
    fontSize: 20,
    fontWeight: 600,
    lineHeight: "20px",
    color: "rgba(51, 51, 51, 1)",
    marginRight: 16,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    "&:hover": {
      color: "var(--hylyTheme)",
    },
  },
  imageAction: {
    fontSize: 14,
    fontFamily: "Open Sans",
    lineHeight: "20px",
    color: "rgba(79, 111, 174, 1)",
    cursor: "pointer",
    "&:hover": {
      color: "var(--hylyTheme)",
    },
  },
  divider: {
    height: "20px",
    width: "1px",
    margin: "0px 12px",
    background: "#dadada",
  },
  flexBox: {
    display: "flex",
    alignItems: "center",
    marginBottom: 8,
  },
  inputStyle: {
    display: "none",
  },
});

const EditAccount = ({ classes, handleToggle, open, details, showNotification, getDetails }) => {
  const [active, setActive] = React.useState("active");
  const [values, setValues] = React.useState({
    email: details.email,
    fullName: details.fullName,
    imageBase64: details.avatarUrl,
    name: "",
  });

  const imageAssets = async () => {
    try {
      let res = await imageAsset({
        name: "name",
        imageData: values.imageBase64,
      });
      if (res && res.uploadUserAvatar && res.uploadUserAvatar && res.uploadUserAvatar.asset) {
        return res.uploadUserAvatar.asset.fileUrl;
      }
      const uncachebleError = getErrorMessage(checkUncatchableError("imageAssetMutation"));
      showNotification(uncachebleError || "Something went wrong.", "error");
      return null;
    } catch (err) {
      console.log("Err :", err);
      const uncachebleError = getErrorMessage(checkUncatchableError("imageAssetMutation"));
      showNotification(uncachebleError || "Something went wrong.", "error");
      return null;
    }
  };

  const handleUpdateAccountInfo = async () => {
    setActive("inactive");
    let payload = {
      email: values.email,
      fullName: values.fullName,
    };
    if (!values.imageBase64) {
      payload.avatarUrl = null;
    } else if (values.imageBase64 !== details.avatarUrl) {
      let fileUrl = await imageAssets();
      if (fileUrl) payload.avatarUrl = fileUrl;
    }

    try {
      let response = await updateProfile(payload);
      if (response.updateProfile) {
        showNotification("Your account info is saved successfully.");
        handleToggle();
        getDetails();
      } else {
        const uncachebleError = getErrorMessage(checkUncatchableError("updateProfileMutation"));
        showNotification(uncachebleError || "Something went wrong.", "error");
      }
    } catch (err) {
      const uncachebleError = getErrorMessage(checkUncatchableError("updateProfileMutation"));
      showNotification(uncachebleError || "Something went wrong.", "error");
      console.log("Err:", err);
    }
    setActive("active");
  };

  const handleChangeValue = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const getButtonState = () => {
    if (!values.fullName) {
      return "inactive";
    } else {
      return active;
    }
  };

  const handleFileChange = async (event) => {
    if (!event.target.files[0]) return null;
    const base64 = await convertBase64(event.target.files[0]);
    setValues({
      ...values,
      imageBase64: base64,
      name: event.target.files[0].name,
    });
  };

  const handleUploadFile = () => {
    let input = document.getElementById("profile-image-uploder");
    if (input) {
      input.click();
    }
  };

  const handleDeleteFile = () => {
    let element = document.getElementById("profile-image-uploder");
    if (element) {
      element.value = null;
    }
    setValues({
      ...values,
      imageBase64: "",
      name: "",
    });
  };

  return (
    <Modal
      open={open}
      title="Edit Account Info"
      handleClose={handleToggle}
      actionChildren={
        <>
          <Button onClick={handleToggle} variant="tertiary" className={classes.button}>
            Cancel
          </Button>
          <Button
            state={getButtonState()}
            className={classes.button}
            onClick={handleUpdateAccountInfo}
          >
            Save
          </Button>
        </>
      }
    >
      <div className={classes.bodyContainer}>
        <div className={classes.header}>Profile Image</div>
        <input
          type="file"
          id="profile-image-uploder"
          className={classes.inputStyle}
          accept="image/*"
          onChange={handleFileChange}
        />
        {values.imageBase64 ? (
          <div className={classes.flexBox}>
            <div className={classes.imageContainer} onClick={handleUploadFile}>
              <img className={classes.image} src={values.imageBase64} />
            </div>
            <div className={classes.imageAction} onClick={handleUploadFile}>
              Replace
            </div>
            <div className={classes.divider}></div>
            <div className={classes.imageAction} onClick={handleDeleteFile}>
              Delete
            </div>
          </div>
        ) : (
          <div className={classes.flexBox}>
            <div className={classes.imageContainer} onClick={handleUploadFile}>
              {details.initialsOfName}
            </div>
            <div className={classes.imageAction} onClick={handleUploadFile}>
              Upload Image
            </div>
          </div>
        )}
        <Field
          label="Full Name"
          value={values.fullName}
          name="fullName"
          onChange={handleChangeValue}
          fullWidth
          variant="standard"
        />
        <Field
          label="Email"
          value={values.email}
          disabled
          name="email"
          onChange={handleChangeValue}
          fullWidth
          variant="standard"
        />
      </div>
    </Modal>
  );
};

export default withStyles(styles)(EditAccount);
