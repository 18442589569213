import React from "react";
import PropTypes from "prop-types";

import withStyles from "@mui/styles/withStyles";
import { groupBy } from "lodash";

import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";

import Typography from "storybookComponents/Typography";

import ListItemSearchComponent from "./ListItem";
import RelayListItemComponent from "./ListItem.relay";
import ListContainer from "./listUtils";

const ListUnitHeader = React.memo(
  withStyles((theme) => ({
    header_list_item: {
      "&:hover": {
        background: "none",
      },
      padding: `${theme.spacing_unit.xs}px ${theme.margins.xs}px
              ${theme.spacing_unit.xs}px ${theme.margins.xs + 1}px`,

      "&:first-child": {
        padding: `${theme.margins.xs}px ${theme.margins.xs}px ${theme.spacing_unit.xs}px ${
          theme.margins.xs + 1
        }px`,
      },
    },
  }))(({ classes, suggestion }) => {
    const header = React.useMemo(
      () =>
        (suggestion && suggestion !== "null" && suggestion !== "undefined" && suggestion) ||
        "Other",
      [suggestion]
    );

    return (
      <ListItem classes={{ root: classes.header_list_item }}>
        <Typography type="body1" font_weight={600}>
          {header}
        </Typography>
      </ListItem>
    );
  })
);

const ListUnitComponent = React.memo(
  withStyles((theme) => ({
    indent_list: {
      paddingLeft: theme.margins.lg,
    },
  }))(({ item, shouldIndent, classes, listItemRef, list_item_props, default_selected, type }) => {
    return (
      <ListItemSearchComponent
        item={item}
        className={shouldIndent && classes.indent_list}
        listItemRef={listItemRef}
        {...list_item_props}
      />
    );
  })
);

const ListUnitComponents = React.memo(
  ({
    suggestions,
    shouldIndent,
    firstGroup,
    list_item_props,
    id,
    type,
    ignore,
    selected_value,
    isRentOrg,
  }) => {
    const { tempOrg, selectedOrg } = list_item_props;
    const selectedListItemRef = React.useRef(null);

    const shouldScrollItem = React.useMemo(
      () => tempOrg.id === selectedOrg.id,
      [selectedOrg, tempOrg]
    );

    React.useEffect(() => {
      if (selectedListItemRef.current && shouldScrollItem) {
        selectedListItemRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, [shouldScrollItem, suggestions]);

    return (
      <>
        {Array.from(suggestions.values())
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((item, index) => {
            if (ignore && item.id === ignore.id) {
              return;
            }
            return (
              <ListUnitComponent
                shouldIndent={shouldIndent}
                default_selected={index === 0 && firstGroup}
                listItemRef={selected_value.id === item.id ? selectedListItemRef : null}
                list_item_props={{
                  ...list_item_props,
                  color: selected_value.id === item.id ? "hylyBlue" : "darkBlack",
                  selected: selected_value.id === item.id,
                }}
                key={`${id}${item.region}-${index}`}
                item={item}
                type={type}
              />
            );
          })}
      </>
    );
  }
);

///<ListUnitComponents suggestions = {grouped_suggestions[suggestion]}
const ListUnit = React.memo(
  withStyles((theme) => ({
    indent_list: {
      paddingLeft: theme.margins.lg,
    },
  }))(
    ({
      suggestion,
      grouped_suggestions,
      should_group,
      firstGroup,
      list_item_props,
      type,
      ignore,
      selected_value,
      isRentOrg,
    }) => {
      const showList = React.useMemo(() => Boolean(suggestion), [suggestion]);

      //return null;
      const commonProps = {
        firstGroup,
        list_item_props,
        shouldIndent: should_group && suggestion,
        type,
        ignore,
        selected_value,
        isRentOrg,
      };

      if (
        should_group &&
        showList &&
        grouped_suggestions[suggestion].length === 1 &&
        grouped_suggestions[suggestion][0].id === ignore.id
      ) {
        return null;
      }

      //return null;
      return (
        <>
          {should_group && <ListUnitHeader suggestion={suggestion} />}
          {should_group && showList && (
            <ListUnitComponents
              id="should-group"
              suggestions={grouped_suggestions[suggestion]}
              {...commonProps}
            />
          )}
          {!should_group && showList && (
            <ListUnitComponents id="un-group" suggestions={suggestion} {...commonProps} />
          )}
        </>
      );
    }
  )
);

const styles = (theme) => ({
  list_container: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    minHeight: 0,
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    width: "100%",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    overflowY: "auto",
    overflowX: "hidden",
    width: "100%",
    padding: `0 0 ${theme.spacing_unit.xs}px`,
  },
});

const SimpleListComponent = React.memo(
  ({
    suggestions: server_suggestions,
    group_suggestions_by,
    default_group_suggestions_by,
    should_group,
    fetch_type,
    selected_value,
    isRentOrg,
    ...list_item_props
  }) => {
    const grouped_suggestions = React.useMemo(() => {
      return should_group && groupBy(server_suggestions, group_suggestions_by);
    }, [group_suggestions_by, should_group, server_suggestions]);

    const suggestions_array = React.useMemo(
      () => (should_group ? Object.keys(grouped_suggestions).sort() : [server_suggestions]),
      [server_suggestions, grouped_suggestions]
    );

    return (
      <>
        {suggestions_array && suggestions_array.length
          ? suggestions_array
              .sort((a, b) => a.localeCompare(b))
              .map((suggestion, idx) => (
                <ListUnit
                  ignore={{}}
                  suggestion={suggestion}
                  grouped_suggestions={grouped_suggestions}
                  key={`list-itme-header-${idx}}`}
                  list_item_props={list_item_props}
                  firstGroup={idx === 0}
                  should_group={should_group}
                  type={fetch_type}
                  selected_value={selected_value}
                  isRentOrg={isRentOrg}
                />
              ))
          : null}
      </>
    );
  }
);

const SimpleList = ({ classes, ...props }) => {
  return (
    <ListContainer
      ContainerComponent={(props) => <div {...props} />}
      container_props={{ className: classes.list_container }}
      InnerContainer={List}
      inner_container_props={{ className: classes.content }}
      ListComponentUnit={SimpleListComponent}
      {...props}
    />
  );
};

SimpleList.propTypes = {
  classes: PropTypes.object.isRequired,
  suggestions: PropTypes.array,
  group_suggestions_by: PropTypes.string,
  default_group_suggestions_by: PropTypes.string,
  fetch_type: PropTypes.oneOf(["fetch", "re-fetch", "no-fetch"]),
  should_group: PropTypes.bool,
  tempOrg: PropTypes.object,
  selectedOrg: PropTypes.object,
};

SimpleList.defaultProps = {
  group_suggestions_by: "region",
  default_group_suggestions_by: "Sub-org Master",
  should_group: true,
};

export default React.memo(withStyles(styles)(SimpleList));
