import graphql from "babel-plugin-relay/macro";

export default graphql`
  query getOrgQuery($appName: String) {
    authorizedOrgs(appName: $appName) {
      id
      name
      userRole
      suborgs {
        id
        name
      }
    }
  }
`;
