/**
 * @flow
 * @relayHash 5e7c92f59edceede7d45c98d80327fa6
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type retrieveHyGptStatusQueryVariables = {|
  tenantId: string
|};
export type retrieveHyGptStatusQueryResponse = {|
  +retrieveHyGptStatus: ?any
|};
export type retrieveHyGptStatusQuery = {|
  variables: retrieveHyGptStatusQueryVariables,
  response: retrieveHyGptStatusQueryResponse,
|};
*/


/*
query retrieveHyGptStatusQuery(
  $tenantId: ID!
) {
  retrieveHyGptStatus(tenantId: $tenantId)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "tenantId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "retrieveHyGptStatus",
    "args": [
      {
        "kind": "Variable",
        "name": "tenantId",
        "variableName": "tenantId"
      }
    ],
    "storageKey": null
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "retrieveHyGptStatusQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "retrieveHyGptStatusQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "retrieveHyGptStatusQuery",
    "id": null,
    "text": "query retrieveHyGptStatusQuery(\n  $tenantId: ID!\n) {\n  retrieveHyGptStatus(tenantId: $tenantId)\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '746937ced293af83562361fe3543609e';
module.exports = node;
