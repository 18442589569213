import React from "react";

export default function EmailClickedIcon({
  className = "text-[#999999]",
  width = 18,
  height = 18,
}) {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.125 13.875H4.125C3.711 13.875 3.375 13.539 3.375 13.125V4.125C3.375 3.711 3.711 3.375 4.125 3.375H13.125C13.539 3.375 13.875 3.711 13.875 4.125V7.125"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.625 8.625L13.875 9.75L12 10.875L14.625 13.5L13.5 14.625L10.875 12L9.75 13.875L8.625 8.625Z"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
