import React from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";

import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";

// components
import SearchList from "../components/SimpleList/SimpleList";
import SearchListRelay from "../components/SimpleList/SimpleList.relay";

// wrappers
import QueryRenderer from "../components/QueryRenderer";
import LocalContainer from "../components/LocalContainer";
import RelayLocalContainer from "../components/LocalContainer.relay";
import Input from "../components/Inputs/SearchInput";
import Icon from "storybookComponents/Icon";

const EmptyContainer = ({ children, ...props }) => React.cloneElement(children, { ...props });

const styles = (theme) => ({
  container_full: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
  },
  container: {
    background: "white",
    border: `1px solid ${theme.palette.divider}`,
  },
  input: {
    padding: `4px 0`,
    lineHeight: 1.5,
  },
  input_container: {
    padding: `${theme.margins.md}px ${theme.margins.xs}px ${theme.margins.xs}px`,
  },
});

const SearchContainer = React.memo(
  withStyles(styles)(
    ({
      classes,
      handleChange,
      search_value,
      default_search_value,
      input_props = {},
      children,
      full,
      onKeyDown,
    }) => (
      <Grid
        direction="column"
        container
        className={classes.container + (full ? ` ${classes.container_full}` : "")}
      >
        <FormControl fullWidth className={classes.input_container}>
          <Input
            onChange={handleChange}
            endAdornment={<Icon type="icon-zoom" size="sm" />}
            input_props={{
              className: `${classes.input} ${input_props.input_class || ""}`,
            }}
            value={search_value}
            placeholder={default_search_value}
            onKeyDown={onKeyDown}
            {...input_props}
          />
        </FormControl>
        {children}
      </Grid>
    )
  )
);

export default React.memo(
  withStyles((theme) => ({
    list_container: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      minHeight: 0,
      position: "relative",
      top: 0,
      left: 0,
      bottom: 0,
      width: "100%",
    },
  }))(({ onSelect, classes, ...props }) => {
    const [search_value, set_value] = React.useState("");
    const [selected_value, set_selected] = React.useState(null);

    function handleChange(e) {
      set_selected(null);
      set_value(e.target.value);
    }

    function handle_select(e, item) {
      set_selected(item.name);
      set_value("");
      onSelect(e, item);
    }

    const ListUnit = React.useMemo(() => {
      switch (props.fetch_type) {
        case "no-fetch":
          return SearchList;
        case "fetch":
          return SearchListRelay;
        case "re-fetch":
          return (props) => <QueryRenderer {...props} Component={SearchListRelay} />;
        default:
          return SearchList;
      }
    }, [props.fetch_type]);

    const Wrapper = React.useMemo(() => {
      switch (props.fetch_type) {
        case "no-fetch":
          return LocalContainer;
        case "fetch":
          return (props) => <QueryRenderer {...props} Component={RelayLocalContainer} />;
        case "re-fetch":
          return EmptyContainer;
        default:
          console.error(
            `Wrong fetch_type prop provided to 'SearchSelect' component. Expect one of ["fetch","re-fetch","no-fetch"] but got "${props.fetch_type}"`
          );
          return EmptyContainer;
      }
    }, [props.fetch_type]);

    return props.fetch_type === "re-fetch" ? (
      <SearchContainer
        handleChange={handleChange}
        search_value={selected_value || search_value}
        {...props}
      >
        <ListUnit
          {...props}
          search_value={search_value}
          onSelect={handle_select}
          container_props={{ className: classes.list_container }}
        />
      </SearchContainer>
    ) : (
      <Wrapper
        Container={SearchContainer}
        ListComponent={ListUnit}
        {...props}
        onSelect={handle_select}
        container_props={{ className: classes.list_container }}
        initial_open
      />
    );
  })
);
