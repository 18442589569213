import React from "react";
import { SvgIcon } from "@mui/material";

import withStyles from "@mui/styles/withStyles";

export const CalendarIconWait = ({ stroke = "#333", fill = "none" }) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.3125 15.5625V17.0625H17.8125"
      stroke="#999999"
      strokeMiterlimit={10}
      strokeLinecap="square"
    />
    <path
      d="M16.3125 20.4375C18.1765 20.4375 19.6875 18.9265 19.6875 17.0625C19.6875 15.1985 18.1765 13.6875 16.3125 13.6875C14.4485 13.6875 12.9375 15.1985 12.9375 17.0625C12.9375 18.9265 14.4485 20.4375 16.3125 20.4375Z"
      stroke="#999999"
      strokeMiterlimit={10}
      strokeLinecap="square"
    />
    <path d="M20.0625 9.1875H4.3125" stroke="#999999" strokeMiterlimit={10} />
    <path
      d="M11.0625 18.9375H5.8125C4.98412 18.9375 4.3125 18.2659 4.3125 17.4375V6.9375C4.3125 6.10912 4.98412 5.4375 5.8125 5.4375H18.9375C19.5589 5.4375 20.0625 5.94113 20.0625 6.5625V12.567"
      stroke="#999999"
      strokeMiterlimit={10}
      strokeLinecap="square"
    />
    <path d="M8.0625 3.9375V6.9375" stroke="#999999" strokeMiterlimit={10} strokeLinecap="square" />
    <path
      d="M16.3125 3.9375V6.9375"
      stroke="#999999"
      strokeMiterlimit={10}
      strokeLinecap="square"
    />
  </svg>
);

export const ExpandChevronIcon = ({ stroke = "#333", fill = "none" }) => (
  <svg width={40} height={20} viewBox="0 0 20 20" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path d="M15 8L10 13L5 8" stroke={stroke} strokeMiterlimit={10} strokeLinecap="square" />
  </svg>
);

export const ExperienceIcon = ({ stroke = "#333", fill = "none" }) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.1299 8.32787H8.28249C7.57419 8.32787 7 8.90205 7 9.61035V13.4578C7 14.1661 7.57419 14.7403 8.28249 14.7403H12.1299C12.8382 14.7403 13.4124 14.1661 13.4124 13.4578V9.61035C13.4124 8.90205 12.8382 8.32787 12.1299 8.32787Z"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.5591 7.90685L16.8385 10.6274C16.3377 11.1283 16.3377 11.9403 16.8385 12.4411L19.5591 15.1617C20.0599 15.6625 20.8719 15.6625 21.3728 15.1617L24.0933 12.4411C24.5942 11.9403 24.5942 11.1283 24.0933 10.6274L21.3728 7.90685C20.8719 7.40601 20.0599 7.40601 19.5591 7.90685Z"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.1299 18.5878H8.28249C7.57419 18.5878 7 19.1619 7 19.8702V23.7177C7 24.426 7.57419 25.0002 8.28249 25.0002H12.1299C12.8382 25.0002 13.4124 24.426 13.4124 23.7177V19.8702C13.4124 19.1619 12.8382 18.5878 12.1299 18.5878Z"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.3897 18.5878H18.5423C17.834 18.5878 17.2598 19.1619 17.2598 19.8702V23.7177C17.2598 24.426 17.834 25.0002 18.5423 25.0002H22.3897C23.098 25.0002 23.6722 24.426 23.6722 23.7177V19.8702C23.6722 19.1619 23.098 18.5878 22.3897 18.5878Z"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ClipboardIcon = ({ stroke = "#333", fill = "#fff" }) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2379_869)">
      <path
        d="M10.9375 1.3125H11.8125C12.0446 1.3125 12.2671 1.40469 12.4312 1.56878C12.5953 1.73288 12.6875 1.95544 12.6875 2.1875V12.6875C12.6875 12.9196 12.5953 13.1421 12.4312 13.3062C12.2671 13.4703 12.0446 13.5625 11.8125 13.5625H2.1875C1.95544 13.5625 1.73288 13.4703 1.56878 13.3062C1.40469 13.1421 1.3125 12.9196 1.3125 12.6875V2.1875C1.3125 1.95544 1.40469 1.73288 1.56878 1.56878C1.73288 1.40469 1.95544 1.3125 2.1875 1.3125H3.0625"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.1875 0.4375H4.8125V2.1875H9.1875V0.4375Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.9375 5.6875H10.0625"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.9375 8.3125H10.0625"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.9375 10.9375H10.0625"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2379_869">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const WriteEmailIcon = ({ stroke = "#333", fill = "#fff" }) => (
  <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.03011 3.4707C4.2018 3.51771 4.28001 3.63041 4.32975 3.78434C4.5329 4.42152 4.7016 5.06887 4.99434 5.67938C5.33832 6.40067 5.7674 7.07166 6.40503 7.6129C6.81073 7.95925 7.27973 8.23737 7.79055 8.43453C8.00269 8.51699 8.09828 8.67505 8.01947 8.83888C7.98621 8.90842 7.91909 8.98539 7.84628 9.0148C6.50781 9.5533 5.66553 10.5234 5.05547 11.6977C4.75583 12.2727 4.59013 12.889 4.39057 13.4948C4.36061 13.5866 4.33304 13.6793 4.30068 13.7697C4.24974 13.9135 4.12899 13.9987 3.98037 14.0006C3.83175 14.0025 3.69931 13.9129 3.65077 13.7661C3.49916 13.3076 3.36282 12.8445 3.19832 12.3898C2.85733 11.4486 2.35604 10.5907 1.58508 9.88095C1.19885 9.52526 0.760478 9.23746 0.253793 9.04284C0.103975 8.98484 -0.000298992 8.89275 6.44091e-07 8.7369C0.000300281 8.58104 0.103975 8.49115 0.255291 8.43205C1.11675 8.09587 1.75707 7.52329 2.27844 6.82674C2.80879 6.11919 3.15098 5.33358 3.39968 4.50948C3.47129 4.27198 3.55579 4.03751 3.62021 3.79836C3.66306 3.63976 3.74486 3.52348 3.91805 3.4707H4.03011Z"
      fill={fill}
    />
    <path
      d="M10.5533 1.55469C10.7312 1.62568 10.8162 1.76003 10.8642 1.93485C11.0093 2.45832 11.1641 2.9788 11.4681 3.44554C11.7904 3.93882 12.1915 4.34851 12.7605 4.58371C12.8319 4.60956 12.8933 4.65605 12.936 4.71677C12.9788 4.77749 13.0009 4.84945 12.9992 4.92273C13.0034 4.99396 12.9846 5.06469 12.9452 5.12521C12.9059 5.18573 12.848 5.23308 12.7795 5.26076C11.8723 5.65486 11.3838 6.38101 11.0732 7.24583C10.9848 7.49164 10.9171 7.74342 10.8387 7.99288C10.7775 8.18695 10.6456 8.29144 10.4628 8.29542C10.2745 8.29874 10.1408 8.19225 10.0714 7.99686C9.91834 7.56363 9.79051 7.11911 9.59878 6.70312C9.34417 6.14814 8.9448 5.69467 8.38721 5.38616C8.30568 5.34104 8.21759 5.30654 8.13329 5.26508C8.06505 5.23602 8.00753 5.18786 7.96826 5.1269C7.929 5.06593 7.90982 4.995 7.91323 4.9234C7.91323 4.77246 7.98543 4.65503 8.12569 4.59465C9.04983 4.19658 9.5359 3.4545 9.85305 2.57907C9.93147 2.36079 9.99849 2.13787 10.0562 1.91362C10.1014 1.73813 10.1944 1.61506 10.374 1.55469L10.5533 1.55469Z"
      fill={fill}
    />
    <path
      d="M6.26033 0C6.33941 0.0382254 6.37718 0.110568 6.39852 0.204702C6.46301 0.48657 6.5318 0.76683 6.66691 1.01815C6.81017 1.28377 6.98843 1.50437 7.24132 1.63101C7.27306 1.64493 7.30031 1.66997 7.31931 1.70266C7.33832 1.73536 7.34813 1.7741 7.34741 1.81356C7.34927 1.85192 7.34089 1.89 7.3234 1.92259C7.30592 1.95518 7.28019 1.98067 7.24976 1.99558C6.84656 2.20778 6.62945 2.59879 6.49142 3.06446C6.45211 3.19682 6.42201 3.3324 6.38716 3.46672C6.35998 3.57122 6.30133 3.62748 6.22011 3.62963C6.13643 3.63141 6.07701 3.57407 6.04614 3.46886C5.97812 3.23558 5.92131 2.99623 5.8361 2.77223C5.72294 2.4734 5.54544 2.22922 5.29762 2.0631C5.26139 2.03881 5.22224 2.02023 5.18477 1.9979C5.15444 1.98226 5.12887 1.95633 5.11142 1.9235C5.09397 1.89067 5.08545 1.85248 5.08696 1.81392C5.08696 1.73265 5.11905 1.66941 5.18139 1.6369C5.59212 1.42256 5.80815 1.02298 5.9491 0.551589C5.98396 0.434055 6.01375 0.31402 6.03939 0.19327C6.0595 0.0987787 6.1008 0.0325094 6.18065 0L6.26033 0Z"
      fill={fill}
    />
  </svg>
);

export const AddFaqSectionIcon = ({ stroke = "#333", fill = "#333" }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2611_12684)">
      <path
        d="M14 0.5H2C1.17157 0.5 0.5 1.17157 0.5 2V14C0.5 14.8284 1.17157 15.5 2 15.5H14C14.8284 15.5 15.5 14.8284 15.5 14V2C15.5 1.17157 14.8284 0.5 14 0.5Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M8 4.5V11.5" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4.5 8H11.5" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_2611_12684">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const DashBoardIcon = ({ stroke = "#333", fill = "none" }) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16 24.4375C20.6599 24.4375 24.4375 20.6599 24.4375 16C24.4375 11.3401 20.6599 7.5625 16 7.5625C11.3401 7.5625 7.5625 11.3401 7.5625 16C7.5625 20.6599 11.3401 24.4375 16 24.4375Z"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 17.6875L13.1875 12.0625"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.9375 17.6875H21.0625"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const DashBoardIconNew = ({ stroke = "#333", fill = "none" }) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.5 7.75H9.25V16.75H14.5V7.75Z"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M14.5 19.75H9.25V24.25H14.5V19.75Z"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M22.75 7.75H17.5V12.25H22.75V7.75Z"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M22.75 15.25H17.5V24.25H22.75V15.25Z"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
);

export const SuccessIcon = ({ stroke = "#333", fill = "none" }) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.5384 15.1864C22.5958 15.5531 22.625 15.93 22.625 16.3125C22.625 20.3512 19.3512 23.625 15.3125 23.625C11.2738 23.625 8 20.3512 8 16.3125C8 12.2738 11.2738 9 15.3125 9C16.5298 9 17.6772 9.297 18.6875 9.8235"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.9375 14.625L15.3125 18L24.3125 9"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const TemplateIcon = ({ stroke = "#333", fill = "none" }) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.4736 12.3394H7.45801V25.355H20.4736V12.3394Z"
      fill="white"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M9.89844 9.8989H22.9141V22.9145"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M12.3389 7.4585H25.3545V20.4741"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
);

export const UsersIcon = ({ stroke = "#333" }) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.7738 16.8184C12.4632 16.8184 10.4137 17.3404 9.06697 17.7945C8.07288 18.1307 7.41016 19.0675 7.41016 20.1173V23.3638C7.41016 23.3638 15.5576 23.3638 15.592 23.3638"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.774 16.8182C12.515 16.8182 10.6831 14.1681 10.6831 11.9091V11.0909C10.6831 8.83191 12.515 7 14.774 7C17.033 7 18.8649 8.83191 18.8649 11.0909V11.9091C18.8649 14.1681 17.033 16.8182 14.774 16.8182Z"
      fill="white"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.5 22.833H18.5V24.833H20.5V22.833Z"
      fill="white"
      stroke={stroke}
      stroke-width="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.5 19.5C20.0523 19.5 20.5 19.0523 20.5 18.5C20.5 17.9477 20.0523 17.5 19.5 17.5C18.9477 17.5 18.5 17.9477 18.5 18.5C18.5 19.0523 18.9477 19.5 19.5 19.5Z"
      fill="white"
      stroke={stroke}
      stroke-width="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.8335 18.5H24.5002C25.2365 18.5 25.8335 19.097 25.8335 19.8333V22.5C25.8335 23.2363 25.2365 23.8333 24.5002 23.8333H21.8335"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const FreehandIcon = ({ stroke = "#333", fill = "none" }) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.625 20.125H8.125V24.625H12.625V20.125Z"
      fill="white"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.375 12.625C11.6176 12.625 12.625 11.6176 12.625 10.375C12.625 9.13236 11.6176 8.125 10.375 8.125C9.13236 8.125 8.125 9.13236 8.125 10.375C8.125 11.6176 9.13236 12.625 10.375 12.625Z"
      fill="white"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.625 10.375H21.625C23.2818 10.375 24.625 11.7182 24.625 13.375V19.375C24.625 21.0318 23.2818 22.375 21.625 22.375H15.625"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const AlertsIcon = ({ stroke = "#333", fill = "none" }) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.6 15V12.6C21.6 11.1148 21.01 9.69041 19.9598 8.6402C18.9096 7.59 17.4852 7 16 7C14.5148 7 13.0904 7.59 12.0402 8.6402C10.99 9.69041 10.4 11.1148 10.4 12.6V15C10.4 17.64 8 18.28 8 19.8C8 21.16 11.12 22.2 16 22.2C20.88 22.2 24 21.16 24 19.8C24 18.28 21.6 17.64 21.6 15Z"
      fill="white"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 23.8C15.1912 23.8 14.4312 23.7728 13.716 23.72C13.8685 24.2064 14.1722 24.6314 14.5831 24.9331C14.9939 25.2348 15.4903 25.3976 16 25.3976C16.5097 25.3976 17.0061 25.2348 17.417 24.9331C17.8278 24.6314 18.1315 24.2064 18.284 23.72C17.5688 23.7728 16.8088 23.8 16 23.8Z"
      fill="white"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const JAIIcon = ({ stroke = "#333" }) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.625 20.125H8.125V24.625H12.625V20.125Z"
      fill="white"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.375 12.625C11.6176 12.625 12.625 11.6176 12.625 10.375C12.625 9.13236 11.6176 8.125 10.375 8.125C9.13236 8.125 8.125 9.13236 8.125 10.375C8.125 11.6176 9.13236 12.625 10.375 12.625Z"
      fill="white"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.625 10.375H21.625C23.2818 10.375 24.625 11.7182 24.625 13.375V19.375C24.625 21.0318 23.2818 22.375 21.625 22.375H15.625"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ReferralIcon = ({
  stroke = "#666",
  fill = "none",
  height = "32",
  width = "32",
  viewBox = "0 0 32 32",
}) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M16 13.5455V16.8182L12.5636 19.3545" stroke={stroke} strokeMiterlimit="10" />
    <path d="M16 16.8182L19.4364 19.3545" stroke={stroke} strokeMiterlimit="10" />
    <path
      d="M16 13.5455C17.8075 13.5455 19.2727 12.0802 19.2727 10.2727C19.2727 8.46525 17.8075 7 16 7C14.1925 7 12.7273 8.46525 12.7273 10.2727C12.7273 12.0802 14.1925 13.5455 16 13.5455Z"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M10.2727 25C12.0802 25 13.5455 23.5347 13.5455 21.7273C13.5455 19.9198 12.0802 18.4545 10.2727 18.4545C8.46525 18.4545 7 19.9198 7 21.7273C7 23.5347 8.46525 25 10.2727 25Z"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M21.7273 25C23.5348 25 25 23.5347 25 21.7273C25 19.9198 23.5348 18.4545 21.7273 18.4545C19.9198 18.4545 18.4546 19.9198 18.4546 21.7273C18.4546 23.5347 19.9198 25 21.7273 25Z"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
);

export const CalendarIcon2 = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M23.0009 9.77832H9.00087C8.14176 9.77832 7.44531 10.4748 7.44531 11.3339V23.0005C7.44531 23.8597 8.14176 24.5561 9.00087 24.5561H23.0009C23.86 24.5561 24.5564 23.8597 24.5564 23.0005V11.3339C24.5564 10.4748 23.86 9.77832 23.0009 9.77832Z"
        fill="white"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M12.1122 16.7783H10.5566V17.5561H12.1122V16.7783Z"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M16.7792 16.7783H15.2236V17.5561H16.7792V16.7783Z"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M12.1122 20.667H10.5566V21.4448H12.1122V20.667Z"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M16.7792 20.667H15.2236V21.4448H16.7792V20.667Z"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M21.4452 16.7783H19.8896V17.5561H21.4452V16.7783Z"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path d="M12.1122 16.7783H10.5566V17.5561H12.1122V16.7783Z" />
      <path d="M16.7792 16.7783H15.2236V17.5561H16.7792V16.7783Z" />
      <path d="M12.1122 20.667H10.5566V21.4448H12.1122V20.667Z" />
      <path d="M16.7792 20.667H15.2236V21.4448H16.7792V20.667Z" />
      <path d="M21.4452 16.7783H19.8896V17.5561H21.4452V16.7783Z" />
      <path d="M11.334 8.22266V10.556" strokeMiterlimit="10" strokeLinecap="square" />
      <path d="M20.667 8.22266V10.556" strokeMiterlimit="10" strokeLinecap="square" />
      <path d="M7.44531 13.667H24.5564" strokeMiterlimit="10" strokeLinecap="square" />
    </SvgIcon>
  );
};

export const CalendarIcon = (props) => (
  <SvgIcon {...props}>
    <path
      d="M18.0003 6.66699H6.00033C5.26395 6.66699 4.66699 7.26395 4.66699 8.00033V18.0003C4.66699 18.7367 5.26395 19.3337 6.00033 19.3337H18.0003C18.7367 19.3337 19.3337 18.7367 19.3337 18.0003V8.00033C19.3337 7.26395 18.7367 6.66699 18.0003 6.66699Z"
      fill="white"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M8.66732 12.667H7.33398V13.3337H8.66732V12.667Z"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M12.6673 12.667H11.334V13.3337H12.6673V12.667Z"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M8.66732 16H7.33398V16.6667H8.66732V16Z"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path d="M12.6673 16H11.334V16.6667H12.6673V16Z" strokeMiterlimit="10" strokeLinecap="square" />
    <path
      d="M16.6673 12.667H15.334V13.3337H16.6673V12.667Z"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path d="M8.66732 12.667H7.33398V13.3337H8.66732V12.667Z" />
    <path d="M12.6673 12.667H11.334V13.3337H12.6673V12.667Z" />
    <path d="M8.66732 16H7.33398V16.6667H8.66732V16Z" />
    <path d="M12.6673 16H11.334V16.6667H12.6673V16Z" />
    <path d="M16.6673 12.667H15.334V13.3337H16.6673V12.667Z" />
    <path d="M8 5.3335V7.3335" strokeMiterlimit="10" strokeLinecap="square" />
    <path d="M16 5.3335V7.3335" strokeMiterlimit="10" strokeLinecap="square" />
    <path d="M4.66699 10H19.3337" strokeMiterlimit="10" strokeLinecap="square" />
  </SvgIcon>
);

export const ClockIcon = ({ stroke = "#666", fill = "none", width = "30", height = "30" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 30 30"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.1667 24.3333C20.2293 24.3333 24.3333 20.2293 24.3333 15.1667C24.3333 10.1041 20.2293 6 15.1667 6C10.1041 6 6 10.1041 6 15.1667C6 20.2293 10.1041 24.3333 15.1667 24.3333Z"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M15.166 10.167V15.167H20.166"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
);

// org size: 24 x 24
export const ReportIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M8.66699 18.0002V9.3335" strokeMiterlimit="10" strokeLinecap="square" />
    <path d="M12 17.9998V12.6665" strokeMiterlimit="10" strokeLinecap="square" />
    <path d="M15.334 18.0002V9.3335" strokeMiterlimit="10" strokeLinecap="square" />
    <path d="M18.667 17.9998V12.6665" strokeMiterlimit="10" strokeLinecap="square" />
    <path d="M5.33301 18V6" strokeMiterlimit="10" strokeLinecap="square" />
  </SvgIcon>
);

// org size: 32 x 32
export const ReportIcon2 = (props) => (
  <SvgIcon {...props}>
    <path d="M11.8749 24.708V12.7913" strokeMiterlimit="10" strokeLinecap="square" />
    <path d="M16.4581 24.708V17.3747" strokeMiterlimit="10" strokeLinecap="square" />
    <path d="M21.0415 24.708V12.7913" strokeMiterlimit="10" strokeLinecap="square" />
    <path d="M25.6249 24.708V17.3747" strokeMiterlimit="10" strokeLinecap="square" />
    <path d="M7.2915 24.708V8.20801" strokeMiterlimit="10" strokeLinecap="square" />
  </SvgIcon>
);

export const JourneyIcon = (props) => (
  <SvgIcon {...props}>
    <path
      d="M12.625 20.125H8.125V24.625H12.625V20.125Z"
      fill="white"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.375 12.625C11.6176 12.625 12.625 11.6176 12.625 10.375C12.625 9.13236 11.6176 8.125 10.375 8.125C9.13236 8.125 8.125 9.13236 8.125 10.375C8.125 11.6176 9.13236 12.625 10.375 12.625Z"
      fill="white"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.625 10.375H21.625C23.2818 10.375 24.625 11.7182 24.625 13.375V19.375C24.625 21.0318 23.2818 22.375 21.625 22.375H15.625"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

// org size: 24 x 24
export const CogIcon = (props) => (
  <SvgIcon {...props}>
    <path
      d="M19.3337 11.9998C19.3337 11.2632 18.737 10.6665 18.0003 10.6665H17.159C17.033 10.1785 16.841 9.7185 16.591 9.2945L17.1857 8.69984C17.7063 8.17917 17.7063 7.33517 17.1857 6.8145C16.665 6.29384 15.821 6.29384 15.3003 6.8145L14.7057 7.40917C14.2817 7.15917 13.8217 6.96717 13.3337 6.84117V5.99984C13.3337 5.26384 12.737 4.6665 12.0003 4.6665C11.2637 4.6665 10.667 5.26317 10.667 5.99984V6.84117C10.179 6.96717 9.71899 7.15917 9.29499 7.40917L8.70033 6.8145C8.17966 6.29384 7.33566 6.29384 6.81499 6.8145C6.29433 7.33517 6.29433 8.17917 6.81499 8.69984L7.40966 9.2945C7.15966 9.7185 6.96766 10.1785 6.84166 10.6665H6.00033C5.26433 10.6665 4.66699 11.2632 4.66699 11.9998C4.66699 12.7365 5.26366 13.3332 6.00033 13.3332H6.84166C6.96766 13.8212 7.15966 14.2812 7.40966 14.7052L6.81499 15.2998C6.29433 15.8205 6.29433 16.6645 6.81499 17.1852C7.33566 17.7058 8.17966 17.7058 8.70033 17.1852L9.29499 16.5905C9.71833 16.8405 10.179 17.0325 10.667 17.1585V17.9998C10.667 18.7358 11.2637 19.3332 12.0003 19.3332C12.737 19.3332 13.3337 18.7365 13.3337 17.9998V17.1585C13.8217 17.0325 14.2817 16.8405 14.7057 16.5905L15.3003 17.1852C15.821 17.7058 16.665 17.7058 17.1857 17.1852C17.7063 16.6645 17.7063 15.8205 17.1857 15.2998L16.591 14.7052C16.841 14.2818 17.033 13.8212 17.159 13.3332H18.0003C18.7363 13.3332 19.3337 12.7365 19.3337 11.9998Z"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14Z"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </SvgIcon>
);

// org size: 32 x 32
export const CogIcon2 = (props) => (
  <SvgIcon {...props}>
    <path
      d="M24.25 16C24.25 15.1712 23.5788 14.5 22.75 14.5H21.8035C21.6618 13.951 21.4457 13.4335 21.1645 12.9565L21.8335 12.2875C22.4192 11.7018 22.4192 10.7523 21.8335 10.1665C21.2477 9.58075 20.2983 9.58075 19.7125 10.1665L19.0435 10.8355C18.5665 10.5542 18.049 10.3383 17.5 10.1965V9.25C17.5 8.422 16.8288 7.75 16 7.75C15.1712 7.75 14.5 8.42125 14.5 9.25V10.1965C13.951 10.3383 13.4335 10.5542 12.9565 10.8355L12.2875 10.1665C11.7018 9.58075 10.7523 9.58075 10.1665 10.1665C9.58075 10.7523 9.58075 11.7018 10.1665 12.2875L10.8355 12.9565C10.5542 13.4335 10.3383 13.951 10.1965 14.5H9.25C8.422 14.5 7.75 15.1712 7.75 16C7.75 16.8288 8.42125 17.5 9.25 17.5H10.1965C10.3383 18.049 10.5542 18.5665 10.8355 19.0435L10.1665 19.7125C9.58075 20.2983 9.58075 21.2477 10.1665 21.8335C10.7523 22.4192 11.7018 22.4192 12.2875 21.8335L12.9565 21.1645C13.4327 21.4457 13.951 21.6618 14.5 21.8035V22.75C14.5 23.578 15.1712 24.25 16 24.25C16.8288 24.25 17.5 23.5788 17.5 22.75V21.8035C18.049 21.6618 18.5665 21.4457 19.0435 21.1645L19.7125 21.8335C20.2983 22.4192 21.2477 22.4192 21.8335 21.8335C22.4192 21.2477 22.4192 20.2983 21.8335 19.7125L21.1645 19.0435C21.4457 18.5673 21.6618 18.049 21.8035 17.5H22.75C23.578 17.5 24.25 16.8288 24.25 16Z"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M15.9995 18.2503C17.2422 18.2503 18.2495 17.2429 18.2495 16.0003C18.2495 14.7576 17.2422 13.7503 15.9995 13.7503C14.7569 13.7503 13.7495 14.7576 13.7495 16.0003C13.7495 17.2429 14.7569 18.2503 15.9995 18.2503Z"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </SvgIcon>
);

export const SuccessHubIcon = (props) => (
  <SvgIcon {...props}>
    <path
      d="M16.375 8.125C18.563 8.125 20.6615 8.99419 22.2086 10.5414C23.7558 12.0885 24.625 14.187 24.625 16.375C24.625 18.563 23.7558 20.6615 22.2086 22.2086C20.6615 23.7558 18.563 24.625 16.375 24.625"
      stroke-miterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M11.875 16.375L14.875 19.375L20.875 13.375"
      stroke-miterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M8.1595 17.0597C8.14075 16.8332 8.125 16.606 8.125 16.375C8.125 16.144 8.14075 15.9167 8.1595 15.6902"
      stroke-miterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M12.0564 9.35498C12.4449 9.11391 12.8528 8.90569 13.2759 8.73248"
      stroke-miterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M9.01685 12.658C9.22401 12.2505 9.46432 11.8606 9.73535 11.4925"
      stroke-miterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M9.72014 21.2373C9.4496 20.8675 9.21028 20.4759 9.00464 20.0665"
      stroke-miterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M12.0422 23.386C12.4315 23.6289 12.8405 23.8389 13.2647 24.0137"
      stroke-miterlimit="10"
      strokeLinecap="square"
    />
  </SvgIcon>
);

export const CloseIcon = ({
  stroke = "black",
  fill = "none",
  width = "24",
  height = "24",
  strokeWidth = "1",
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.5 5.5L5.5 19.5"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M19.5 19.5L5.5 5.5"
      strokeWidth={strokeWidth}
      stroke={stroke}
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
);

export const MultiUserIcon = ({
  stroke = "#999999",
  strokeWidth = "1",
  fill = "none",
  width = "48",
  height = "48",
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 48 48"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.5 22.5C21.4706 22.5 25.5 18.4706 25.5 13.5C25.5 8.52944 21.4706 4.5 16.5 4.5C11.5294 4.5 7.5 8.52944 7.5 13.5C7.5 18.4706 11.5294 22.5 16.5 22.5Z"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={strokeWidth}
    />
    <path
      d="M37.5 19.5C40.8137 19.5 43.5 16.8137 43.5 13.5C43.5 10.1863 40.8137 7.5 37.5 7.5C34.1863 7.5 31.5 10.1863 31.5 13.5C31.5 16.8137 34.1863 19.5 37.5 19.5Z"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={strokeWidth}
    />
    <path
      d="M16.5 28.5C12.5218 28.5 8.70644 30.0804 5.8934 32.8934C3.08035 35.7064 1.5 39.5218 1.5 43.5H31.5C31.5 39.5218 29.9196 35.7064 27.1066 32.8934C24.2936 30.0804 20.4782 28.5 16.5 28.5V28.5Z"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={strokeWidth}
    />
    <path
      d="M32.5225 27C33.9967 26.0191 35.7287 25.4971 37.4995 25.5C39.8864 25.5 42.1756 26.4482 43.8634 28.136C45.5513 29.8239 46.4995 32.1131 46.4995 34.5V37.5H37.4995"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={strokeWidth}
    />
  </svg>
);

export const LetterIcon = ({ stroke = "#999", fill = "none" }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.5 5L8 9L15.5 5"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.5 14.5H1.5C0.948 14.5 0.5 14.052 0.5 13.5V2.5C0.5 1.948 0.948 1.5 1.5 1.5H14.5C15.052 1.5 15.5 1.948 15.5 2.5V13.5C15.5 14.052 15.052 14.5 14.5 14.5Z"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const PhoneCallIcon = ({ stroke = "#999999", fill = "none" }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path
        d="M9.1782 10.529L8.1192 11.854C6.47845 10.8892 5.11098 9.52176 4.1462 7.881L5.4712 6.822C5.62644 6.69772 5.73615 6.52552 5.78318 6.3323C5.83022 6.13909 5.81194 5.93573 5.7312 5.754L4.5242 3.036C4.43768 2.84103 4.28471 2.68312 4.09258 2.59045C3.90046 2.49777 3.68166 2.47635 3.4752 2.53L1.1752 3.13C0.960135 3.18497 0.772967 3.31747 0.649665 3.50206C0.526363 3.68664 0.475625 3.91028 0.507202 4.13C0.911131 7.00121 2.23885 9.66287 4.28964 11.7126C6.34042 13.7623 9.00278 15.0886 11.8742 15.491C12.0939 15.5226 12.3176 15.4718 12.5021 15.3485C12.6867 15.2252 12.8192 15.0381 12.8742 14.823L13.4742 12.523C13.5276 12.3167 13.506 12.0981 13.4134 11.9062C13.3207 11.7143 13.163 11.5615 12.9682 11.475L10.2462 10.27C10.0646 10.1892 9.86129 10.1707 9.66808 10.2176C9.47487 10.2644 9.30262 10.3739 9.1782 10.529V10.529Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.5 4C9.42826 4 10.3185 4.36875 10.9749 5.02513C11.6313 5.6815 12 6.57174 12 7.5"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.5 1C9.35359 1 10.1988 1.16813 10.9874 1.49478C11.7761 1.82144 12.4926 2.30023 13.0962 2.90381C13.6998 3.50739 14.1786 4.22394 14.5052 5.01256C14.8319 5.80117 15 6.64641 15 7.5"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

// org size: 24 x 24
export const ApprovalIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M10.25 7.125L11.5 8.375L14.625 5.25" strokeMiterlimit="10" strokeLinecap="square" />
    <path
      d="M10.875 17.6475C10.875 17.27 10.7094 16.9106 10.4138 16.6762C9.96188 16.3175 9.17812 15.875 8.0625 15.875C6.94688 15.875 6.16313 16.3175 5.71125 16.6762C5.41563 16.9106 5.25 17.27 5.25 17.6475V18.375H10.875V17.6475Z"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M8.0625 14C8.92545 14 9.625 13.3004 9.625 12.4375C9.625 11.5746 8.92545 10.875 8.0625 10.875C7.19956 10.875 6.5 11.5746 6.5 12.4375C6.5 13.3004 7.19956 14 8.0625 14Z"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M19 17.6475C19 17.27 18.8344 16.9106 18.5387 16.6762C18.0869 16.3175 17.3031 15.875 16.1875 15.875C15.0719 15.875 14.2881 16.3175 13.8362 16.6762C13.5406 16.9106 13.375 17.27 13.375 17.6475V18.375H19V17.6475Z"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M16.1875 14C17.0504 14 17.75 13.3004 17.75 12.4375C17.75 11.5746 17.0504 10.875 16.1875 10.875C15.3246 10.875 14.625 11.5746 14.625 12.4375C14.625 13.3004 15.3246 14 16.1875 14Z"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </SvgIcon>
);

// org size: 32 x 32
export const ApprovalIcon2 = (props) => (
  <SvgIcon {...props}>
    <path
      d="M13.6665 9.50002L15.3332 11.1667L19.4998 7.00002"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M14.5 23.53C14.5 23.0267 14.2792 22.5475 13.885 22.235C13.2825 21.7567 12.2375 21.1667 10.75 21.1667C9.2625 21.1667 8.2175 21.7567 7.615 22.235C7.22083 22.5475 7 23.0267 7 23.53V24.5H14.5V23.53Z"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M10.7498 18.6667C11.9004 18.6667 12.8332 17.7339 12.8332 16.5834C12.8332 15.4328 11.9004 14.5 10.7498 14.5C9.59924 14.5 8.6665 15.4328 8.6665 16.5834C8.6665 17.7339 9.59924 18.6667 10.7498 18.6667Z"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M25.3335 23.53C25.3335 23.0267 25.1127 22.5475 24.7185 22.235C24.116 21.7567 23.071 21.1667 21.5835 21.1667C20.096 21.1667 19.051 21.7567 18.4485 22.235C18.0543 22.5475 17.8335 23.0267 17.8335 23.53V24.5H25.3335V23.53Z"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M21.5833 18.6667C22.7339 18.6667 23.6667 17.7339 23.6667 16.5834C23.6667 15.4328 22.7339 14.5 21.5833 14.5C20.4327 14.5 19.5 15.4328 19.5 16.5834C19.5 17.7339 20.4327 18.6667 21.5833 18.6667Z"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </SvgIcon>
);

export const EmbedCodeIcon = ({ fill = "none", stroke = stroke }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path d="M12.5 3.5L7.5 16.5" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M5.5 6.5L2.5 9.5L5.5 12.5"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.5 6.5L17.5 9.5L14.5 12.5"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const HayleyCustomizeIcon = ({ className = "w-[32px] h-[32px] text-[#333333]" }) => {
  return (
    <svg className={className} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.25 16C24.25 15.1712 23.5787 14.5 22.75 14.5H21.8035C21.6618 13.951 21.4457 13.4335 21.1645 12.9565L21.8335 12.2875C22.4192 11.7018 22.4192 10.7523 21.8335 10.1665C21.2478 9.58075 20.2983 9.58075 19.7125 10.1665L19.0435 10.8355C18.5665 10.5542 18.049 10.3383 17.5 10.1965V9.25C17.5 8.422 16.8287 7.75 16 7.75C15.1712 7.75 14.5 8.42125 14.5 9.25V10.1965C13.951 10.3383 13.4335 10.5542 12.9565 10.8355L12.2875 10.1665C11.7018 9.58075 10.7523 9.58075 10.1665 10.1665C9.58075 10.7523 9.58075 11.7018 10.1665 12.2875L10.8355 12.9565C10.5542 13.4335 10.3383 13.951 10.1965 14.5H9.25C8.422 14.5 7.75 15.1712 7.75 16C7.75 16.8287 8.42125 17.5 9.25 17.5H10.1965C10.3383 18.049 10.5542 18.5665 10.8355 19.0435L10.1665 19.7125C9.58075 20.2983 9.58075 21.2478 10.1665 21.8335C10.7523 22.4192 11.7018 22.4192 12.2875 21.8335L12.9565 21.1645C13.4327 21.4457 13.951 21.6618 14.5 21.8035V22.75C14.5 23.578 15.1712 24.25 16 24.25C16.8287 24.25 17.5 23.5787 17.5 22.75V21.8035C18.049 21.6618 18.5665 21.4457 19.0435 21.1645L19.7125 21.8335C20.2983 22.4192 21.2478 22.4192 21.8335 21.8335C22.4192 21.2478 22.4192 20.2983 21.8335 19.7125L21.1645 19.0435C21.4457 18.5673 21.6618 18.049 21.8035 17.5H22.75C23.578 17.5 24.25 16.8287 24.25 16Z"
        fill="white"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M15.9994 18.2505C17.242 18.2505 18.2494 17.2431 18.2494 16.0005C18.2494 14.7578 17.242 13.7505 15.9994 13.7505C14.7567 13.7505 13.7494 14.7578 13.7494 16.0005C13.7494 17.2431 14.7567 18.2505 15.9994 18.2505Z"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  );
};

export const HayleyPreferenceIcon = ({ className = "w-[32px] h-[32px] text-[#333333]" }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <path
        stroke="currentColor"
        strokeLinecap="square"
        strokeMiterlimit="10"
        d="M17.875 10.375h6.75M8.125 10.375h2.25M23.875 16.375h.75M8.125 16.375h8.25M17.875 22.375h6.75M8.125 22.375h2.25"
      ></path>
      <path
        fill="#fff"
        stroke="currentColor"
        strokeLinecap="square"
        strokeMiterlimit="10"
        d="M12.625 12.625a2.25 2.25 0 100-4.5 2.25 2.25 0 000 4.5zM18.625 18.625a2.25 2.25 0 100-4.5 2.25 2.25 0 000 4.5zM12.625 24.625a2.25 2.25 0 100-4.5 2.25 2.25 0 000 4.5z"
      ></path>
    </svg>
  );
};
export const HayleyKnowledgeBaseIcon = ({ className = "w-[32px] h-[32px] text-[#333333]" }) => {
  return (
    <svg className={className} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.125 18.625H12.625"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M12.625 18.625H17.125"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M12.625 9.625H8.125V23.125H12.625V9.625Z"
        fill="white"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M17.125 9.625H12.625V23.125H17.125V9.625Z"
        fill="white"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M19.096 18.7345L23.4873 17.7482"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M21.5149 8.96748L17.1243 9.95298L20.0808 23.1249L24.4714 22.1394L21.5149 8.96748Z"
        fill="white"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
};

export const HayleyEmbedCodeIcon = ({ className = "w-[32px] h-[32px] text-[#333333]" }) => {
  return (
    <svg className={className} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.9167 8.41699L13.0834 23.5837"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.75 11.917L7.25 15.417L10.75 18.917"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.25 11.917L24.75 15.417L21.25 18.917"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const HayleyTourSettingsIcon = ({ className = "w-[32px] h-[32px] text-[#333333]" }) => {
  return (
    <svg className={className} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.4375 25.1875H7.8125C7.26022 25.1875 6.8125 24.7398 6.8125 24.1875V10.4375C6.8125 9.88522 7.26022 9.4375 7.8125 9.4375H25.0625C25.6148 9.4375 26.0625 9.88522 26.0625 10.4375V16.4375"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0625 6.8125V9.4375"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.8125 6.8125V9.4375"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.8125 12.9375H26.0625"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.25 23.875C22.6997 23.875 23.875 22.6997 23.875 21.25C23.875 19.8003 22.6997 18.625 21.25 18.625C19.8003 18.625 18.625 19.8003 18.625 21.25C18.625 22.6997 19.8003 23.875 21.25 23.875Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.25 18.625V16.875"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.1058 19.3939L24.344 18.1558"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.875 21.25H25.625"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.1058 23.106L24.344 24.3441"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.25 23.875V25.625"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.3941 23.106L18.156 24.3441"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.625 21.25H16.875"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.3941 19.3939L18.156 18.1558"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const CopyIcon = ({ fill = "none", stroke = "#333333" }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.2502 7.91663H9.5835V16.25H16.2502V7.91663Z"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.08333 14.5833H3.75V3.75H12.9167V5.41667"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const CopyIconPreview = ({ fill = "none", stroke = "#333333" }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.1003 9.23349C12.4003 10.5335 12.4003 12.6335 11.1003 13.9335L9.23359 15.8335C7.93359 17.1335 5.83359 17.1335 4.53359 15.8335C3.23359 14.5335 3.23359 12.4335 4.53359 11.1335L6.20026 9.53349"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M9.23379 11.1C7.93379 9.79996 7.93379 7.69996 9.23379 6.39996L11.1005 4.49996C12.4005 3.19996 14.5338 3.19996 15.8338 4.49996C17.1338 5.79996 17.1338 7.89996 15.8338 9.19996L14.1671 10.8333"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
);

// org size: 32 x 32
//filled arrowhead icon left
export const BackArrowIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M23.5 16H9.4375" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M14.125 11.3125L9.4375 16L14.125 20.6875"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
//filled arrowhead icon up
export const DownArrowIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M11 6L6 1L1 6" strokeMiterlimit="10" strokeLinecap="square" />
    </SvgIcon>
  );
};

export const FilterIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M6.70352 7.2212C6.26048 6.55665 6.73687 5.6665 7.53557 5.6665H17.1319C17.9306 5.6665 18.407 6.55665 17.9639 7.2212L13.835 13.4146C13.7255 13.5788 13.6671 13.7719 13.6671 13.9693V17.9998C13.6671 18.5521 13.2193 18.9998 12.6671 18.9998H12.0004C11.4481 18.9998 11.0004 18.5521 11.0004 17.9998V13.9693C11.0004 13.7719 10.9419 13.5788 10.8324 13.4146L6.70352 7.2212Z"
        stroke="#333333"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </SvgIcon>
  );
};

export const ConstantIcon = (props) => {
  return (
    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect rx={2} fill="white" fillOpacity="0.06" />
      <path
        d="M12.3999 24L14.7999 8"
        stroke="#333333"
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path d="M18 24L20.4 8" stroke="#333333" strokeMiterlimit={10} strokeLinecap="square" />
      <path
        d="M10.7998 12.8H22.7998"
        stroke="#333333"
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path d="M10 19.2H22" stroke="#333333" strokeMiterlimit={10} strokeLinecap="square" />
    </svg>
  );
};

export const PercentageIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // width="32"
      // height="32"
      viewBox="0 0 32 32"
      fill="none"
      {...props}
    >
      <path
        d="M11.4167 23.9167L21.4167 8.91669"
        stroke="#333333"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
      <path
        d="M11.8334 13.9167C13.4442 13.9167 14.75 12.6109 14.75 11C14.75 9.38921 13.4442 8.08337 11.8334 8.08337C10.2225 8.08337 8.91669 9.38921 8.91669 11C8.91669 12.6109 10.2225 13.9167 11.8334 13.9167Z"
        stroke="#333333"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
      <path
        d="M21 24.7501C22.6109 24.7501 23.9167 23.4442 23.9167 21.8334C23.9167 20.2226 22.6109 18.9167 21 18.9167C19.3892 18.9167 18.0834 20.2226 18.0834 21.8334C18.0834 23.4442 19.3892 24.7501 21 24.7501Z"
        stroke="#333333"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
    </svg>
  );
};

export const FilterIcon2 = (props) => {
  return (
    <svg
      width={props.size}
      {...props}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.70346 7.22145C6.26042 6.55689 6.73681 5.66675 7.53551 5.66675H17.1318C17.9305 5.66675 18.4069 6.55689 17.9639 7.22145L13.8349 13.4148C13.7254 13.5791 13.667 13.7721 13.667 13.9695V18.0001C13.667 18.5524 13.2193 19.0001 12.667 19.0001H12.0003C11.448 19.0001 11.0003 18.5524 11.0003 18.0001V13.9695C11.0003 13.7721 10.9419 13.5791 10.8324 13.4148L6.70346 7.22145Z"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  );
};

export const DownloadCSVIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M18.0026 13.9998V8.6665H14.0026V13.9998H11.3359L16.0026 19.3332L20.6693 13.9998H18.0026Z"
        stroke="#333333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.3346 18V21.3333C23.3346 21.8638 23.1239 22.3725 22.7488 22.7475C22.3738 23.1226 21.8651 23.3333 21.3346 23.3333H10.668C10.1375 23.3333 9.62883 23.1226 9.25376 22.7475C8.87868 22.3725 8.66797 21.8638 8.66797 21.3333V18"
        stroke="#333333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export const AlertIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M0.826402 12.6378L6.76574 1.40976C6.88437 1.18553 7.06189 0.997895 7.2792 0.867021C7.49652 0.736148 7.74539 0.666992 7.99907 0.666992C8.25274 0.666992 8.50162 0.736148 8.71893 0.867021C8.93624 0.997895 9.11376 1.18553 9.2324 1.40976L15.1717 12.6378C15.2832 12.8496 15.3379 13.0868 15.3305 13.3261C15.3231 13.5654 15.2538 13.7987 15.1294 14.0033C15.005 14.2079 14.8297 14.3767 14.6206 14.4934C14.4115 14.6101 14.1758 14.6706 13.9364 14.6691H2.05907C1.81987 14.6701 1.58448 14.6093 1.37578 14.4924C1.16708 14.3755 0.992181 14.2066 0.868091 14.0021C0.744 13.7976 0.674944 13.5645 0.667639 13.3254C0.660334 13.0863 0.715028 12.8494 0.826402 12.6378V12.6378Z"
        stroke="#333333"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M7.99805 5.33594V9.33594"
        stroke="#333333"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M7.99805 12.6689C8.55033 12.6689 8.99805 12.2212 8.99805 11.6689C8.99805 11.1167 8.55033 10.6689 7.99805 10.6689C7.44576 10.6689 6.99805 11.1167 6.99805 11.6689C6.99805 12.2212 7.44576 12.6689 7.99805 12.6689Z"
        fill="#333333"
      />
    </SvgIcon>
  );
};

export const CupIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M7.5 13.5V10.5" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M5.5 13.5H9.5C10.0304 13.5 10.5391 13.7107 10.9142 14.0858C11.2893 14.4609 11.5 14.9696 11.5 15.5H3.5C3.5 14.9696 3.71071 14.4609 4.08579 14.0858C4.46086 13.7107 4.96957 13.5 5.5 13.5V13.5Z"
        stroke="#333333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5 1.5H14.5V4.5C14.5 5.29565 14.1839 6.05871 13.6213 6.62132C13.0587 7.18393 12.2956 7.5 11.5 7.5V7.5"
        stroke="#333333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.5 1.5H0.5V4.5C0.5 5.29565 0.816071 6.05871 1.37868 6.62132C1.94129 7.18393 2.70435 7.5 3.5 7.5"
        stroke="#333333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.5 0.5H11.5V6.5C11.5 7.56087 11.0786 8.57828 10.3284 9.32843C9.57828 10.0786 8.56087 10.5 7.5 10.5C6.43913 10.5 5.42172 10.0786 4.67157 9.32843C3.92143 8.57828 3.5 7.56087 3.5 6.5V0.5Z"
        stroke="#333333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export const PreviewIcon = ({ width, height, viewBox, ...rest }) => {
  return (
    <SvgIcon
      width={width || "19"}
      height={height || "12"}
      viewBox={viewBox || "0 0 19 12"}
      {...rest}
    >
      <path
        d="M0.875 6.20801C0.875 6.20801 4.16667 1.20801 9.20833 1.20801C14.2083 1.20801 17.5417 6.20801 17.5417 6.20801C17.5417 6.20801 14.1667 11.208 9.20833 11.208C4.20833 11.208 0.875 6.20801 0.875 6.20801Z"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M9.20833 7.87468C11.0493 7.87468 12.5417 6.38229 12.5417 4.54134C12.5417 2.70039 11.0493 1.20801 9.20833 1.20801C7.36738 1.20801 5.875 2.70039 5.875 4.54134C5.875 6.38229 7.36738 7.87468 9.20833 7.87468Z"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </SvgIcon>
  );
};

export const AppreciateIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M2.71878 6.5625L1.07481 7.45578C0.752758 7.63077 0.552246 7.96793 0.552246 8.33445V14.8647C0.552246 15.417 0.999961 15.8647 1.55225 15.8647H14.4481C15.0004 15.8647 15.4481 15.417 15.4481 14.8647V8.31599C15.4481 7.95931 15.2581 7.62962 14.9495 7.45078L13.4167 6.5625"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path d="M6.3948 8.18746H4.86206V4.81543H6.3948" strokeMiterlimit="10" />
      <path
        d="M6.39453 4.81539L7.31417 1.44336C7.55808 1.44336 7.79199 1.54025 7.96446 1.71272C8.13693 1.88518 8.23382 2.1191 8.23382 2.363V4.20229H10.5329C10.6655 4.2023 10.7965 4.23097 10.917 4.28635C11.0375 4.34172 11.1445 4.42249 11.2309 4.52312C11.3172 4.62375 11.3807 4.74185 11.4172 4.86934C11.4536 4.99682 11.462 5.13068 11.4418 5.26172L11.1117 7.40755C11.0783 7.62452 10.9684 7.82239 10.8019 7.96541C10.6354 8.10844 10.4232 8.18718 10.2037 8.18741H6.39453V4.81539Z"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path d="M0.6875 8.1875L8 12.25" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M15.3125 8.1875L8 12.25" strokeMiterlimit="10" strokeLinecap="round" />
    </SvgIcon>
  );
};

export const MotivateIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M2.71878 9.5625L1.07481 10.4558C0.752758 10.6308 0.552246 10.9679 0.552246 11.3344V17.8647C0.552246 18.417 0.999961 18.8647 1.55225 18.8647H14.4481C15.0004 18.8647 15.4481 18.417 15.4481 17.8647V11.316C15.4481 10.9593 15.2581 10.6296 14.9495 10.4508L13.4167 9.5625"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path d="M8.01245 1.3125V2.5625" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4.47681 2.77734L5.36056 3.66109" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11.5481 2.77734L10.6643 3.66109" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M10.5124 6.3127C10.5145 5.87336 10.4008 5.44123 10.1827 5.05983C9.96463 4.67843 9.64989 4.36124 9.27019 4.14021C8.8905 3.91917 8.45926 3.8021 8.01992 3.80079C7.58057 3.79948 7.14864 3.91397 6.76763 4.13274C6.38662 4.3515 6.06999 4.6668 5.84964 5.04689C5.62928 5.42698 5.51298 5.85843 5.51245 6.29778C5.51192 6.73712 5.62719 7.16885 5.84663 7.54947C6.06607 7.93008 6.38194 8.24615 6.76242 8.46583V10.6877H9.26242V8.46583C9.64149 8.24829 9.95658 7.93477 10.176 7.55681C10.3954 7.17884 10.5115 6.74975 10.5124 6.3127V6.3127Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M11.7625 6.3125H12.3875" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3.63745 6.3125H4.26245" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M0.6875 11.1875L8 15.25" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M15.3125 11.1875L8 15.25" strokeMiterlimit="10" strokeLinecap="round" />
    </SvgIcon>
  );
};

export const FlagIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M0.5 0.5V15.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M2.5 2.5H13.5V10.5H2.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M2.5 6.5H13.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.5 2.5V10.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.5 2.5V10.5" strokeLinecap="round" strokeLinejoin="round" />
    </SvgIcon>
  );
};

export const DragIcon = ({ stroke = "#333333", fill = "none" }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.33268 13.9998C9.33268 14.7332 8.73268 15.3332 7.99935 15.3332C7.26602 15.3332 6.66602 14.7332 6.66602 13.9998C6.66602 13.2665 7.26602 12.6665 7.99935 12.6665C8.73268 12.6665 9.33268 13.2665 9.33268 13.9998ZM7.99935 8.6665C7.26602 8.6665 6.66602 9.2665 6.66602 9.99984C6.66602 10.7332 7.26602 11.3332 7.99935 11.3332C8.73268 11.3332 9.33268 10.7332 9.33268 9.99984C9.33268 9.2665 8.73268 8.6665 7.99935 8.6665ZM7.99935 4.6665C7.26602 4.6665 6.66602 5.2665 6.66602 5.99984C6.66602 6.73317 7.26602 7.33317 7.99935 7.33317C8.73268 7.33317 9.33268 6.73317 9.33268 5.99984C9.33268 5.2665 8.73268 4.6665 7.99935 4.6665ZM11.9993 7.33317C12.7327 7.33317 13.3327 6.73317 13.3327 5.99984C13.3327 5.2665 12.7327 4.6665 11.9993 4.6665C11.266 4.6665 10.666 5.2665 10.666 5.99984C10.666 6.73317 11.266 7.33317 11.9993 7.33317ZM11.9993 8.6665C11.266 8.6665 10.666 9.2665 10.666 9.99984C10.666 10.7332 11.266 11.3332 11.9993 11.3332C12.7327 11.3332 13.3327 10.7332 13.3327 9.99984C13.3327 9.2665 12.7327 8.6665 11.9993 8.6665ZM11.9993 12.6665C11.266 12.6665 10.666 13.2665 10.666 13.9998C10.666 14.7332 11.266 15.3332 11.9993 15.3332C12.7327 15.3332 13.3327 14.7332 13.3327 13.9998C13.3327 13.2665 12.7327 12.6665 11.9993 12.6665Z"
      fill={stroke}
    />
  </svg>
);

export const DeleteIcon = ({ stroke = "#333333", fill = "none", className = "stroke-[#333]" }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M5.41602 8.75V14.5833C5.41602 15.5042 6.16185 16.25 7.08268 16.25H13.7493C14.6702 16.25 15.416 15.5042 15.416 14.5833V8.75"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.58301 6.25H16.2497"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.75 6.25V3.75H12.0833V6.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.416 9.58325V13.7499"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.916 9.58325V13.7499"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.91602 9.58325V13.7499"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const PencilIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M8.76512 2.5957L11.9597 5.79029" strokeMiterlimit="10" />
      <path
        d="M4.72201 13.0278L0.888504 13.6668L1.52742 9.83325L10.0979 1.2628C10.3375 1.02325 10.6625 0.888672 11.0013 0.888672C11.3401 0.888672 11.6651 1.02325 11.9047 1.2628L13.2925 2.65053C13.532 2.89016 13.6666 3.21513 13.6666 3.55396C13.6666 3.8928 13.532 4.21776 13.2925 4.45739L4.72201 13.0278Z"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </SvgIcon>
  );
};

export const Offers = ({ stroke = "#333333", fill = "none" }) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.4646 18.7714L25.2354 16.0005L22.4646 13.2297V9.53524H18.7701L15.9993 6.7644L13.2285 9.53524H9.53402V13.2297L6.76318 16.0005L9.53402 18.7714V22.4658H13.2285L15.9993 25.2366L18.7701 22.4658H22.4646V18.7714Z"
        stroke="#333333"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M18.7702 13.2296L13.2285 18.7713"
        stroke="#333333"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <svg id="offer-icon-svg">
        <path
          d="M13.6901 15.0769C14.4552 15.0769 15.0755 14.4567 15.0755 13.6915C15.0755 12.9264 14.4552 12.3061 13.6901 12.3061C12.925 12.3061 12.3047 12.9264 12.3047 13.6915C12.3047 14.4567 12.925 15.0769 13.6901 15.0769Z"
          fill="#333333"
        />
        <path
          d="M18.3083 19.695C19.0734 19.695 19.6937 19.0747 19.6937 18.3096C19.6937 17.5444 19.0734 16.9242 18.3083 16.9242C17.5431 16.9242 16.9229 17.5444 16.9229 18.3096C16.9229 19.0747 17.5431 19.695 18.3083 19.695Z"
          fill="#333333"
        />
      </svg>
    </svg>
  );
};

export const ExternalLinkIcon = ({ stroke = "#333333", height = "32", width = "32" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.2497 0.75L6.58301 7.41667"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.41699 0.75H13.2503V6.58333"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.75 8.25V12C10.75 12.3315 10.6183 12.6495 10.3839 12.8839C10.1495 13.1183 9.83152 13.25 9.5 13.25H2C1.66848 13.25 1.35054 13.1183 1.11612 12.8839C0.881696 12.6495 0.75 12.3315 0.75 12V4.5C0.75 4.16848 0.881696 3.85054 1.11612 3.61612C1.35054 3.3817 1.66848 3.25 2 3.25H5.75"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const DuplicateIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M11.6667 3.66602H1V15.666H11.6667V3.66602Z"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path d="M3 1H14.3333V14.3333" strokeMiterlimit="10" strokeLinecap="square" />
      <path d="M3.6665 7H8.99984" strokeMiterlimit="10" strokeLinecap="square" />
      <path d="M3.6665 9.66602H8.99984" strokeMiterlimit="10" strokeLinecap="square" />
      <path d="M3.6665 12.334H6.33317" strokeMiterlimit="10" strokeLinecap="square" />
    </SvgIcon>
  );
};

export const CodeIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M10.5 0.5L5.5 13.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3.5 3.5L0.5 6.5L3.5 9.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.5 3.5L15.5 6.5L12.5 9.5" strokeLinecap="round" strokeLinejoin="round" />
    </SvgIcon>
  );
};
//filled arrow head
export const ExpansionArrowIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M1.59998 1L7.59997 7L1.59998 13" strokeMiterlimit="10" strokeLinecap="square" />
    </SvgIcon>
  );
};

export const PlusIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M7 1.44434V12.5554" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M1.44434 6.99988H12.5554" strokeLinecap="round" strokeLinejoin="round" />
    </SvgIcon>
  );
};

export const RefreshIcon = ({ fill = "none", stroke = "#333333" }) => {
  return (
    <svg width="22" height="22" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.4502 7.65C3.4502 4.9438 5.644 2.75 8.3502 2.75C10.1954 2.75 11.7298 3.8987 12.5502 5.55"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.55 2.75V5.55H9.75"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.2504 8.34961C13.2504 11.0558 11.0566 13.2496 8.35039 13.2496C6.50519 13.2496 4.97079 12.1009 4.15039 10.4496"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.15039 13.2502V10.4502H6.95039"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const Facebook = ({ stroke = "black" }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24 12.073C24 5.446 18.627 0.072998 12 0.072998C5.373 0.072998 0 5.446 0 12.073C0 18.063 4.388 23.027 10.125 23.927V15.542H7.078V12.072H10.125V9.43C10.125 6.423 11.917 4.761 14.658 4.761C15.97 4.761 17.344 4.996 17.344 4.996V7.949H15.83C14.339 7.949 13.874 8.874 13.874 9.823V12.073H17.202L16.67 15.543H13.874V23.928C19.612 23.027 24 18.062 24 12.073Z"
        fill={stroke}
      />
    </svg>
  );
};

export const Twitter = ({ stroke = "black" }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.953 4.57003C23.0545 4.96563 22.1026 5.22677 21.128 5.34503C22.1541 4.72828 22.9224 3.76107 23.291 2.62203C22.34 3.17703 21.286 3.58103 20.164 3.80603C19.4238 3.01446 18.4429 2.48945 17.3736 2.31253C16.3044 2.13561 15.2067 2.31668 14.2509 2.82762C13.2952 3.33856 12.5349 4.15078 12.0881 5.13815C11.6412 6.12552 11.533 7.2328 11.78 8.28803C7.69 8.09503 4.067 6.13003 1.64 3.16203C1.19879 3.91184 0.968664 4.76705 0.974 5.63703C0.974 7.34703 1.844 8.85003 3.162 9.73303C2.38086 9.70816 1.61697 9.49696 0.934 9.11703V9.17703C0.933557 10.3134 1.32627 11.415 2.04551 12.2948C2.76474 13.1746 3.76621 13.7785 4.88 14.004C4.1583 14.1974 3.40241 14.2265 2.668 14.089C2.98412 15.067 3.59766 15.922 4.42301 16.5345C5.24837 17.1471 6.24435 17.4867 7.272 17.506C5.53153 18.872 3.38248 19.6133 1.17 19.611C0.78 19.611 0.391 19.588 0 19.544C2.25571 20.9884 4.87851 21.755 7.557 21.753C16.61 21.753 21.555 14.257 21.555 7.76803C21.555 7.55803 21.555 7.34803 21.54 7.13803C22.506 6.44289 23.3392 5.57985 24 4.59003L23.953 4.57003Z"
        fill={stroke}
      />
    </svg>
  );
};

export const Earth = () => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 24 24"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,24.000000) scale(0.100000,-0.100000)"
        fill="var(--hylyTheme)"
        stroke="none"
      >
        <path
          d="M63 220 c-36 -21 -64 -67 -62 -102 l1 -23 6 23 c9 29 12 30 37 12 25
  -19 45 -54 45 -79 0 -11 6 -23 13 -27 6 -4 9 -4 5 1 -4 4 2 21 13 37 21 28 21
  30 3 43 -11 7 -31 16 -46 19 -27 5 -40 26 -17 26 15 0 53 33 57 48 1 6 -1 11
  -5 11 -20 -3 -34 2 -28 11 15 24 105 0 105 -27 0 -8 -5 -11 -12 -7 -7 4 -8 3
  -4 -4 4 -7 2 -12 -5 -12 -7 0 -9 -3 -6 -7 4 -3 2 -12 -4 -19 -12 -15 -4 -34
  16 -34 14 0 25 -21 25 -50 0 -21 -26 -38 -75 -52 -26 -7 -24 -7 10 -3 52 6 95
  48 101 101 6 48 -11 81 -55 111 -42 28 -76 29 -118 3z m147 -60 c13 -8 13 -10
  -2 -10 -9 0 -20 5 -23 10 -8 13 5 13 25 0z"
        />
        <path
          d="M10 82 c0 -17 55 -72 72 -72 7 1 1 7 -14 16 -15 8 -34 27 -42 42 -9
  15 -15 21 -16 14z"
        />
      </g>
    </svg>
  );
};

export const FindUnit = ({ fill = "none", stroke = "#333333" }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.2419 15.2421L19.6666 19.6667" stroke={stroke} strokeMiterlimit="10" />
      <path
        d="M11 17C14.3137 17 17 14.3137 17 11C17 7.68629 14.3137 5 11 5C7.68629 5 5 7.68629 5 11C5 14.3137 7.68629 17 11 17Z"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M13.6667 9.66663V13H8.33337V9.66663L11 7.66663L13.6667 9.66663Z"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path d="M11 11.6666V13" stroke={stroke} strokeMiterlimit="10" strokeLinecap="square" />
    </svg>
  );
};

export const TourHub = ({ fill = "none", stroke = "#333333" }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.0003 6.66687H6.00033C5.26395 6.66687 4.66699 7.26382 4.66699 8.0002V18.0002C4.66699 18.7366 5.26395 19.3335 6.00033 19.3335H18.0003C18.7367 19.3335 19.3337 18.7366 19.3337 18.0002V8.0002C19.3337 7.26382 18.7367 6.66687 18.0003 6.66687Z"
        fill="white"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M10.0002 14.6669L11.3336 16.0002L14.6669 12.6669"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M8.00024 5.3335V7.3335"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M16.0002 5.3335V7.3335"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M4.66699 10.0001H19.3337"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  );
};

export const TourIcon = ({ fill = "none", stroke = "#333333" }) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_6871_96675)">
        <path
          d="M12.2344 19.7656L14.6094 22.1406L19.3594 17.3906"
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24.7031 14.4219H6.89062"
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.9219 9.67188H8.67188C7.68812 9.67188 6.89062 10.4694 6.89062 11.4531V23.3281C6.89062 24.3119 7.68812 25.1094 8.67188 25.1094H22.9219C23.9056 25.1094 24.7031 24.3119 24.7031 23.3281V11.4531C24.7031 10.4694 23.9056 9.67188 22.9219 9.67188Z"
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.0469 7.89062V11.4531"
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.5469 7.89062V11.4531"
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_6871_96675">
          <rect width="19" height="19" fill="white" transform="translate(6 7)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const ArtieWidget = ({ fill = "none", stroke = "#333333" }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.3337 11.9998C19.3337 11.2632 18.737 10.6665 18.0003 10.6665H17.159C17.033 10.1785 16.841 9.7185 16.591 9.2945L17.1857 8.69984C17.7063 8.17917 17.7063 7.33517 17.1857 6.8145C16.665 6.29384 15.821 6.29384 15.3003 6.8145L14.7057 7.40917C14.2817 7.15917 13.8217 6.96717 13.3337 6.84117V5.99984C13.3337 5.26384 12.737 4.6665 12.0003 4.6665C11.2637 4.6665 10.667 5.26317 10.667 5.99984V6.84117C10.179 6.96717 9.71899 7.15917 9.29499 7.40917L8.70033 6.8145C8.17966 6.29384 7.33566 6.29384 6.81499 6.8145C6.29433 7.33517 6.29433 8.17917 6.81499 8.69984L7.40966 9.2945C7.15966 9.7185 6.96766 10.1785 6.84166 10.6665H6.00033C5.26433 10.6665 4.66699 11.2632 4.66699 11.9998C4.66699 12.7365 5.26366 13.3332 6.00033 13.3332H6.84166C6.96766 13.8212 7.15966 14.2812 7.40966 14.7052L6.81499 15.2998C6.29433 15.8205 6.29433 16.6645 6.81499 17.1852C7.33566 17.7058 8.17966 17.7058 8.70033 17.1852L9.29499 16.5905C9.71833 16.8405 10.179 17.0325 10.667 17.1585V17.9998C10.667 18.7358 11.2637 19.3332 12.0003 19.3332C12.737 19.3332 13.3337 18.7365 13.3337 17.9998V17.1585C13.8217 17.0325 14.2817 16.8405 14.7057 16.5905L15.3003 17.1852C15.821 17.7058 16.665 17.7058 17.1857 17.1852C17.7063 16.6645 17.7063 15.8205 17.1857 15.2998L16.591 14.7052C16.841 14.2818 17.033 13.8212 17.159 13.3332H18.0003C18.7363 13.3332 19.3337 12.7365 19.3337 11.9998Z"
        fill="white"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M11.9998 14.0001C13.1043 14.0001 13.9998 13.1047 13.9998 12.0001C13.9998 10.8956 13.1043 10.0001 11.9998 10.0001C10.8952 10.0001 9.99976 10.8956 9.99976 12.0001C9.99976 13.1047 10.8952 14.0001 11.9998 14.0001Z"
        fill="white"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  );
};

export const HyTours = ({ stroke = "#333333", fill = "none" }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.0002 14.6669L11.3336 16.0002L14.6669 12.6669"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M18.0003 6.66687H6.00033C5.26395 6.66687 4.66699 7.26382 4.66699 8.0002V18.0002C4.66699 18.7366 5.26395 19.3335 6.00033 19.3335H18.0003C18.7367 19.3335 19.3337 18.7366 19.3337 18.0002V8.0002C19.3337 7.26382 18.7367 6.66687 18.0003 6.66687Z"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M8.00024 5.3335V7.3335"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M16.0002 5.3335V7.3335"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M4.66699 10.0001H19.3337"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  );
};

export const HyAlm = ({ stroke = "#333333", fill = "none" }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.6667 9H5V19.6667H15.6667V9Z"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path d="M7 7H17.6667V17.6667" stroke={stroke} strokeMiterlimit="10" strokeLinecap="square" />
      <path d="M9 5H19.6667V15.6667" stroke={stroke} strokeMiterlimit="10" strokeLinecap="square" />
    </svg>
  );
};

export const HyBlast = ({ stroke = "#333333", fill = "none" }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14 17.3333L15.3333 18.6666L18.6667 15.3333"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M10.668 18.6666H4.66797V6.66663H19.3346V12"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M8.66797 4.66663V6.66663"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M15.3359 4.66663V6.66663"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path d="M4.66797 9.33331H19.3346" stroke={stroke} strokeMiterlimit="10" />
    </svg>
  );
};

export const GearIcon = ({ stroke = "#333333", fill = "none" }) => {
  return (
    <svg width="77" height="73" viewBox="0 0 77 73" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M75.1669 36.7117C75.1669 33.17 72.1836 30.3015 68.5003 30.3015H64.2936C63.6636 27.9553 62.7036 25.7438 61.4536 23.7053L64.4269 20.8463C67.0303 18.3431 67.0303 14.2854 64.4269 11.7822C61.8236 9.27904 57.6036 9.27904 55.0003 11.7822L52.0269 14.6412C49.9069 13.4393 47.6069 12.5162 45.1669 11.9104V7.86557C45.1669 4.32712 42.1836 1.45532 38.5003 1.45532C34.8169 1.45532 31.8336 4.32391 31.8336 7.86557V11.9104C29.3936 12.5162 27.0936 13.4393 24.9736 14.6412L22.0003 11.7822C19.3969 9.27904 15.1769 9.27904 12.5736 11.7822C9.97028 14.2854 9.97028 18.3431 12.5736 20.8463L15.5469 23.7053C14.2969 25.7438 13.3369 27.9553 12.7069 30.3015H8.50028C4.82028 30.3015 1.83362 33.17 1.83362 36.7117C1.83362 40.2534 4.81695 43.122 8.50028 43.122H12.7069C13.3369 45.4681 14.2969 47.6797 15.5469 49.7181L12.5736 52.5771C9.97028 55.0803 9.97028 59.138 12.5736 61.6412C15.1769 64.1444 19.3969 64.1444 22.0003 61.6412L24.9736 58.7822C27.0903 59.9841 29.3936 60.9072 31.8336 61.513V65.5578C31.8336 69.0963 34.8169 71.9681 38.5003 71.9681C42.1836 71.9681 45.1669 69.0995 45.1669 65.5578V61.513C47.6069 60.9072 49.9069 59.9841 52.0269 58.7822L55.0003 61.6412C57.6036 64.1444 61.8236 64.1444 64.4269 61.6412C67.0303 59.138 67.0303 55.0803 64.4269 52.5771L61.4536 49.7181C62.7036 47.6829 63.6636 45.4681 64.2936 43.122H68.5003C72.1803 43.122 75.1669 40.2534 75.1669 36.7117Z"
        stroke={stroke}
        strokeOpacity="0.75"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M38.4978 46.3282C44.0206 46.3282 48.4978 42.0232 48.4978 36.7128C48.4978 31.4024 44.0206 27.0974 38.4978 27.0974C32.975 27.0974 28.4978 31.4024 28.4978 36.7128C28.4978 42.0232 32.975 46.3282 38.4978 46.3282Z"
        stroke={stroke}
        strokeOpacity="0.75"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  );
};

export const GrearIconSmall = ({ stroke = "#333333", fill = "none" }) => {
  return (
    <svg width="66" height="63" viewBox="0 0 66 63" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M57.5991 38.8601C58.3734 36.4905 57.0045 33.9682 54.5401 33.2238L51.7256 32.3735C51.817 30.6765 51.6582 29.0028 51.2675 27.3863L53.8818 26.0744C56.1708 24.9258 57.0579 22.211 55.8633 20.01C54.6688 17.809 51.8454 16.9561 49.5563 18.1047L46.942 19.4165C45.7863 18.1839 44.4493 17.1014 42.9492 16.203L43.8335 13.4967C44.607 11.1293 43.2388 8.60486 40.7745 7.86039C38.3101 7.11592 35.687 8.43218 34.9127 10.8018L34.0285 13.508C32.2635 13.4201 30.5229 13.5729 28.8417 13.9485L27.4774 11.4347C26.2828 9.23376 23.4594 8.38082 21.1704 9.52942C18.8814 10.678 17.9943 13.3929 19.1889 15.5938L20.5532 18.1076C19.2713 19.2188 18.1455 20.5044 17.2111 21.9468L14.3966 21.0966C11.9344 20.3528 9.30907 21.6684 8.53482 24.038C7.76057 26.4075 9.12948 28.9298 11.5938 29.6743L14.4084 30.5245C14.317 32.2216 14.4758 33.8952 14.8665 35.5117L12.2521 36.8236C9.96312 37.9722 9.07606 40.687 10.2706 42.888C11.4652 45.089 14.2886 45.9419 16.5776 44.7933L19.1919 43.4815C20.3454 44.7134 21.6846 45.7966 23.1847 46.6951L22.3005 49.4013C21.5269 51.7688 22.8951 54.2931 25.3595 55.0376C27.8238 55.7821 30.447 54.4658 31.2212 52.0962L32.1055 49.39C33.8704 49.4779 35.6111 49.3251 37.2922 48.9495L38.6565 51.4633C39.8511 53.6642 42.6745 54.5172 44.9635 53.3686C47.2526 52.22 48.1396 49.5051 46.9451 47.3042L45.5807 44.7904C46.862 43.6813 47.9885 42.3936 48.9229 40.9512L51.7374 41.8014C54.1995 42.5452 56.8249 41.2296 57.5991 38.8601Z"
        stroke={stroke}
        strokeOpacity="0.75"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M30.9629 37.8826C34.6581 38.9989 38.5946 37.0235 39.7556 33.4705C40.9165 29.9175 38.8621 26.1323 35.167 25.0161C31.4719 23.8998 27.5353 25.8751 26.3744 29.4281C25.2135 32.9811 27.2678 36.7663 30.9629 37.8826Z"
        stroke={stroke}
        strokeOpacity="0.75"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  );
};

export const PreviewUrl = ({ stroke = "#333333", fill = "none" }) => (
  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.7219 14.7219H4.04278V3.04278H9V2H4.52941C3.68059 2 3 2.68824 3 3.52941V14.2353C3 15.0765 3.68059 15.7647 4.52941 15.7647H15.2353C16.0765 15.7647 16.7647 15.0765 16.7647 14.2353V9.7329H15.7219V14.7219ZM11.4118 2V3.04278H14.8877L6.54545 11.385L7.37968 12.2193L15.7219 3.87701V7.35294H16.7647V2H11.4118Z"
      fill={stroke}
    />
  </svg>
);

export const EmbedUrl = ({ stroke = "#333333", fill = "none" }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.5 9.5H11.5V19.5H19.5V9.5Z"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.5 17.5H4.5V4.5H15.5V6.5"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const TickIcon = ({ stroke = "#66D184", fill = "none" }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="2" fill="white" />
    <path
      d="M4.7998 13.68L9.25695 18L20.3998 7.20001"
      stroke="#66D184"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
);

export const MailIcon2 = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M23.793 9.02734H9.02734C8.12125 9.02734 7.38672 9.76188 7.38672 10.668V22.1523C7.38672 23.0584 8.12125 23.793 9.02734 23.793H23.793C24.6991 23.793 25.4336 23.0584 25.4336 22.1523V10.668C25.4336 9.76188 24.6991 9.02734 23.793 9.02734Z"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path d="M7.89062 9.48506L16.4104 17.2305L24.9293 9.48588" strokeMiterlimit="10" />
    </SvgIcon>
  );
};

export const MailIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M18.6673 6.03467H6.03451C5.25929 6.03467 4.63086 6.6631 4.63086 7.43831V17.2638C4.63086 18.039 5.25929 18.6675 6.03451 18.6675H18.6673C19.4425 18.6675 20.071 18.039 20.071 17.2638V7.43831C20.071 6.6631 19.4425 6.03467 18.6673 6.03467Z"
        fill="#fff"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path d="M5.06152 6.42627L12.3507 13.0529L19.6391 6.42697" strokeMiterlimit="10" />
    </SvgIcon>
  );
};

export const LayersIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M15.4754 9.15576H5.36426V19.2669H15.4754V9.15576Z"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path d="M7.25977 7.26025H17.3709V17.3714" strokeMiterlimit="10" strokeLinecap="square" />
      <path d="M9.15625 5.36426H19.2674V15.4754" strokeMiterlimit="10" strokeLinecap="square" />
    </SvgIcon>
  );
};

export const LayersIcon2 = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M19.7424 12.9375H8.85352V23.8264H19.7424V12.9375Z"
        fill="white"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path d="M10.8955 10.8955H21.7844V21.7844" strokeMiterlimit="10" strokeLinecap="square" />
      <path d="M12.9365 8.854H23.8254V19.7429" strokeMiterlimit="10" strokeLinecap="square" />
    </SvgIcon>
  );
};

export const MailTimeIcon2 = (props) => (
  <SvgIcon {...props}>
    <path
      d="M19.388 22.1368H9.57075C8.70325 22.1368 8 21.4335 8 20.566V9.57075C8 8.70325 8.70325 8 9.57075 8H23.7075C24.5751 8 25.2783 8.70325 25.2783 9.57075V15.0684"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M25.2221 24.5492C27.205 24.5492 28.8124 22.9418 28.8124 20.9589C28.8124 18.9761 27.205 17.3687 25.2221 17.3687C23.2393 17.3687 21.6318 18.9761 21.6318 20.9589C21.6318 22.9418 23.2393 24.5492 25.2221 24.5492Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M25.2227 19.1636V20.9587H27.0178" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8.48242 8.43848L16.6394 15.854L24.7955 8.43926" strokeMiterlimit="10" />
  </SvgIcon>
);

export const BinIcon = (props) => (
  <SvgIcon {...props}>
    <path
      d="M6.94727 10.6221V17.0533C6.94727 18.0685 7.76955 18.8908 8.78477 18.8908H16.1348C17.15 18.8908 17.9723 18.0685 17.9723 17.0533V10.6221"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.02832 7.86523H18.8908"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.6221 7.86563V5.10938H14.2971V7.86563"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.4595 11.541V16.1348"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.2158 11.541V16.1348"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.70312 11.541V16.1348"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export const BackRefreshIcon = (props) => (
  <SvgIcon {...props}>
    <path
      d="M8.4375 14.5625C9.42625 14.5625 10.3608 14.328 11.1885 13.9115"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.89258 12.5439C4.43158 13.1406 5.08695 13.6306 5.82283 13.978"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.3125 8.4375C2.3125 9.20837 2.45513 9.946 2.715 10.625"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.0724 12.4415C14.0007 11.3679 14.5625 9.96875 14.5625 8.4375C14.5625 5.05475 11.8202 2.3125 8.4375 2.3125C6.131 2.3125 4.213 3.74837 3.1875 5.8125"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.1875 1.4375V5.8125H7.5625"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export const MegaPhoneIcon = (props) => (
  <SvgIcon {...props}>
    <path
      d="M7.11894 9.71447L8.45722 12.3728C8.53591 12.5264 8.58355 12.6941 8.59741 12.8662C8.61128 13.0383 8.59111 13.2114 8.53804 13.3757C8.48497 13.54 8.40006 13.6922 8.28814 13.8237C8.17623 13.9552 8.03951 14.0633 7.8858 14.1419V14.1419C7.57538 14.3005 7.2147 14.3294 6.88298 14.2223C6.55127 14.1151 6.27564 13.8807 6.11665 13.5705L4.10522 9.67676"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.2858 12.0001L8.59893 9.71435H4.5715C4.19629 9.71435 3.82476 9.64045 3.47812 9.49687C3.13147 9.35328 2.8165 9.14283 2.55119 8.87752C2.01537 8.3417 1.71436 7.61497 1.71436 6.85721V6.85721C1.71436 6.09945 2.01537 5.37273 2.55119 4.83691C3.08701 4.30109 3.81374 4.00007 4.5715 4.00007H8.59893L14.2858 1.71436V12.0001Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export const HouseIcon = (props) => (
  <SvgIcon {...props}>
    <path
      d="M16 8L8.5 14V23.75H13.75V18.5H18.25V23.75H23.5V14L16 8Z"
      // fill='white'
      // stroke='#26BBED'
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
//arrowIcon back
export const BackArrow = ({ stroke = "#333333", fill = "none" }) => (
  <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.5 5.5H0.5"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.5 0.5L0.5 5.5L5.5 10.5"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const MandatoryIcon = ({ stroke = "#333333", fill = "none" }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.9936 6.7002L10.6703 9.67041L13.663 8.83574L13.8585 10.2043L10.9936 10.4374L12.8584 12.8888L11.5651 13.5956L10.2416 10.8735L9.03852 13.5956L7.71509 12.8888L9.53481 10.4374L6.69995 10.2043L6.91802 8.83574L9.85815 9.67041L9.53481 6.7002H10.9936Z"
      fill={stroke}
    />
  </svg>
);

export const SmallPreviewUrl = ({ stroke = "#333333", fill = "none" }) => (
  <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.88128 9.81466H2.09518V2.02856H5.39999V1.33337H2.4196C1.85372 1.33337 1.39999 1.7922 1.39999 2.35298V9.49024C1.39999 10.051 1.85372 10.5098 2.4196 10.5098H9.55686C10.1176 10.5098 10.5765 10.051 10.5765 9.49024V6.48864H9.88128V9.81466ZM7.00784 1.33337V2.02856H9.32513L3.76363 7.59006L4.31978 8.14621L9.88128 2.58471V4.902H10.5765V1.33337H7.00784Z"
      fill={stroke}
    />
  </svg>
);

export const ReschduleIcon = ({ stroke = "#333333", fill = "none" }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.5625 9.1875H20.4375" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M20.4375 9.1875V6.9375C20.4375 6.63913 20.319 6.35298 20.108 6.142C19.897 5.93103 19.6109 5.8125 19.3125 5.8125H4.6875C4.38913 5.8125 4.10298 5.93103 3.892 6.142C3.68103 6.35298 3.5625 6.63913 3.5625 6.9375V19.3125C3.5625 19.6109 3.68103 19.897 3.892 20.108C4.10298 20.319 4.38913 20.4375 4.6875 20.4375H10.3125"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M8.0625 3.5625V5.8125" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M15.9375 3.5625V5.8125" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M15.9375 20.4375C18.4228 20.4375 20.4375 18.4228 20.4375 15.9375C20.4375 13.4522 18.4228 11.4375 15.9375 11.4375C13.4522 11.4375 11.4375 13.4522 11.4375 15.9375C11.4375 18.4228 13.4522 20.4375 15.9375 20.4375Z"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.9375 13.6875V15.9375H18.1875"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const OffersIcon = (props) => (
  <SvgIcon {...props}>
    <path
      d="M22.4646 18.7714L25.2354 16.0005L22.4646 13.2297V9.53524H18.7701L15.9993 6.7644L13.2285 9.53524H9.53402V13.2297L6.76318 16.0005L9.53402 18.7714V22.4658H13.2285L15.9993 25.2366L18.7701 22.4658H22.4646V18.7714Z"
      stroke="#333333"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M18.7702 13.2296L13.2285 18.7713"
      stroke="#333333"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M13.6901 15.0769C14.4552 15.0769 15.0755 14.4567 15.0755 13.6915C15.0755 12.9264 14.4552 12.3061 13.6901 12.3061C12.925 12.3061 12.3047 12.9264 12.3047 13.6915C12.3047 14.4567 12.925 15.0769 13.6901 15.0769Z"
      fill="#333333"
    />
    <path
      d="M18.3083 19.695C19.0734 19.695 19.6937 19.0747 19.6937 18.3096C19.6937 17.5444 19.0734 16.9242 18.3083 16.9242C17.5431 16.9242 16.9229 17.5444 16.9229 18.3096C16.9229 19.0747 17.5431 19.695 18.3083 19.695Z"
      fill="#333333"
    />
  </SvgIcon>
);

export const NoOfferIcon = (props) => (
  <SvgIcon {...props}>
    <path
      d="M16.6663 14.0002L18.6663 12.0002L16.6663 10.0002V7.3335H13.9997L11.9997 5.3335L9.99967 7.3335H7.33301V10.0002L5.33301 12.0002L7.33301 14.0002V16.6668H9.99967L11.9997 18.6668L13.9997 16.6668H16.6663V14.0002Z"
      stroke="#666666"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M13.9996 10.0001L9.99963 14.0001"
      stroke="#666666"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M10.333 11.3335C10.8853 11.3335 11.333 10.8858 11.333 10.3335C11.333 9.78121 10.8853 9.3335 10.333 9.3335C9.78072 9.3335 9.33301 9.78121 9.33301 10.3335C9.33301 10.8858 9.78072 11.3335 10.333 11.3335Z"
      fill="#666666"
    />
    <path
      d="M13.6664 14.6669C14.2187 14.6669 14.6664 14.2192 14.6664 13.6669C14.6664 13.1146 14.2187 12.6669 13.6664 12.6669C13.1141 12.6669 12.6664 13.1146 12.6664 13.6669C12.6664 14.2192 13.1141 14.6669 13.6664 14.6669Z"
      fill="#666666"
    />
  </SvgIcon>
);

// org size: 24 x 24
export const MonitorIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M8.84052 18.9512H15.16" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12.0002 15.1602V18.9518" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M18.9516 6.3125H5.04883V15.1597H18.9516V6.3125Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

// org size: 24 x 24
export const PhoneIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M10.104 16.4238H13.8957" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M15.1595 5.04883H8.84006C8.14203 5.04883 7.57617 5.61469 7.57617 6.31272V17.6877C7.57617 18.3857 8.14203 18.9516 8.84006 18.9516H15.1595C15.8575 18.9516 16.4234 18.3857 16.4234 17.6877V6.31272C16.4234 5.61469 15.8575 5.04883 15.1595 5.04883Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

// org size: 24 x 24
export const SmsIcon = (props) => (
  <SvgIcon {...props}>
    <path
      d="M6.02793 5.62793C5.80702 5.62793 5.62793 5.80702 5.62793 6.02793V14.4964C5.62793 14.7174 5.80702 14.8964 6.02793 14.8964H8.12434C8.34526 14.8964 8.52434 15.0755 8.52434 15.2964V18.3721L13.0519 14.9764C13.1212 14.9245 13.2054 14.8964 13.2919 14.8964H17.9721C18.1931 14.8964 18.3721 14.7174 18.3721 14.4964V6.02793C18.3721 5.80702 18.1931 5.62793 17.9721 5.62793H6.02793Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
export const EditIcon = ({ className = "stroke-[#333]" }) => (
  <svg
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M11.7656 5.59595L14.9602 8.79054" strokeMiterlimit="10" />
    <path
      d="M7.72218 16.0281L3.88867 16.667L4.52759 12.8335L13.098 4.26305C13.3377 4.02349 13.6626 3.88892 14.0015 3.88892C14.3403 3.88892 14.6653 4.02349 14.9049 4.26305L16.2926 5.65078C16.5322 5.89041 16.6668 6.21537 16.6668 6.55421C16.6668 6.89304 16.5322 7.21801 16.2926 7.45764L7.72218 16.0281Z"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
);

export const EditWithRectangle = ({ stroke = "#999999", fill = "none" }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.7654 11.9751V13.1603C12.7654 13.4746 12.6405 13.7761 12.4183 13.9984C12.196 14.2206 11.8946 14.3455 11.5802 14.3455H6.83948C6.52515 14.3455 6.22369 14.2206 6.00143 13.9984C5.77916 13.7761 5.6543 13.4746 5.6543 13.1603V8.41956C5.6543 8.10523 5.77916 7.80377 6.00143 7.58151C6.22369 7.35924 6.52515 7.23438 6.83948 7.23438H8.02467"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.3951 5.6543H14.3457V9.60491"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.3457 5.6543L9.60492 10.395"
      stroke="#999999"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ConfirmIcon = ({ stroke = "#333333", fill = "none" }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" fill="#26BBED" fillOpacity="0.1" />
    <path
      d="M6.5 13.5L9.5 16.5L16.5 7.5"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const CancelIcon = ({ stroke = "#333333", fill = "none", width = "24", height = "24" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width={width} height={height} fill="#F9F9F9" />
    <path d="M7.5 7.5L16.5 16.5" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M16.5 7.5L7.5 16.5" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const SingleCopy = ({ stroke = "#333333", fill = "none" }) => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.4689 3.53125H1.46893V15.9062H12.4689V3.53125Z"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M3.53143 0.78125H15.2189V14.5312"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M4.21863 6.96875H9.71863"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M4.21863 9.71875H9.71863"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M4.21863 12.4688H6.96863"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
);

export const DeskTopIcon = ({ stroke = "#333333", fill = "none" }) => (
  <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.84058 13.9512H11.16" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M8.00024 10.1602V13.9518"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.9516 1.3125H1.04883V10.1597H14.9516V1.3125Z"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const PhoneIconOmni = ({ stroke = "#333333", fill = "none" }) => (
  <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.104 12.4238H6.89567" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M8.15951 1.04883H1.84006C1.14203 1.04883 0.576172 1.61469 0.576172 2.31272V13.6877C0.576172 14.3857 1.14203 14.9516 1.84006 14.9516H8.15951C8.85753 14.9516 9.4234 14.3857 9.4234 13.6877V2.31272C9.4234 1.61469 8.85753 1.04883 8.15951 1.04883Z"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ChatIcon = ({ stroke = "#333333", fill = "none" }) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.02793 0.628906C0.807016 0.628906 0.62793 0.807992 0.62793 1.02891V9.49742C0.62793 9.71834 0.807016 9.89742 1.02793 9.89742H3.12434C3.34526 9.89742 3.52434 10.0765 3.52434 10.2974V13.3731L8.05193 9.97742C8.12117 9.9255 8.20539 9.89742 8.29193 9.89742H12.9721C13.1931 9.89742 13.3721 9.71834 13.3721 9.49742V1.02891C13.3721 0.807992 13.1931 0.628906 12.9721 0.628906H1.02793Z"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
//arrow icon right
export const ArrowIcon = () => (
  <svg width="32" height="8" viewBox="0 0 32 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M31.3536 4.35355C31.5488 4.15829 31.5488 3.84171 31.3536 3.64645L28.1716 0.464466C27.9763 0.269204 27.6597 0.269204 27.4645 0.464466C27.2692 0.659728 27.2692 0.976311 27.4645 1.17157L30.2929 4L27.4645 6.82843C27.2692 7.02369 27.2692 7.34027 27.4645 7.53553C27.6597 7.7308 27.9763 7.7308 28.1716 7.53553L31.3536 4.35355ZM0 4.5H31V3.5H0V4.5Z"
      fill="#DADADA"
    />
  </svg>
);

export const VisibilityIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.875 12.207C3.875 12.207 7.16667 7.20703 12.2083 7.20703C17.2083 7.20703 20.5417 12.207 20.5417 12.207C20.5417 12.207 17.1667 17.207 12.2083 17.207C7.20833 17.207 3.875 12.207 3.875 12.207Z"
      stroke="#333333"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M12.2083 13.8737C14.0493 13.8737 15.5417 12.3813 15.5417 10.5404C15.5417 8.69942 14.0493 7.20703 12.2083 7.20703C10.3674 7.20703 8.875 8.69942 8.875 10.5404C8.875 12.3813 10.3674 13.8737 12.2083 13.8737Z"
      stroke="#333333"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
);

export const DuplicateIcon2 = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.875 7.125H4.875V20.625H16.875V7.125Z"
      stroke="#333333"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M7.125 4.125H19.875V19.125"
      stroke="#333333"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M7.87476 10.875H13.8748"
      stroke="#333333"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M7.87476 13.875H13.8748"
      stroke="#333333"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M7.87476 16.875H10.8748"
      stroke="#333333"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
);

export const NotpadIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.5 6.5H7.5C6.94772 6.5 6.5 6.94772 6.5 7.5V16.5C6.5 17.0523 6.94772 17.5 7.5 17.5H16.5C17.0523 17.5 17.5 17.0523 17.5 16.5V7.5C17.5 6.94772 17.0523 6.5 16.5 6.5Z"
      stroke="#333333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M9.5 10.5H14.5" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9.5 13.5H14.5" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const SingleEvent = ({ stroke = "#333333", fill = "none" }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.6665 4.66675L11.3332 7.33341H9.33317C8.09549 7.33341 6.90851 7.82508 6.03334 8.70025C5.15817 9.57542 4.6665 10.7624 4.6665 12.0001C4.6665 13.2378 5.15817 14.4247 6.03334 15.2999"
      stroke="#999999"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.3332 19.3334L12.6665 16.6667H14.6665C15.9042 16.6667 17.0912 16.175 17.9663 15.2999C18.8415 14.4247 19.3332 13.2377 19.3332 12C19.3332 10.7624 18.8415 9.57537 17.9663 8.7002"
      stroke="#999999"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4 20L20 4"
      stroke="#999999"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const RecurringEvent = ({ stroke = "#333333", fill = "none" }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.6665 4.66675L11.3332 7.33341H9.33317C8.09549 7.33341 6.90851 7.82508 6.03334 8.70025C5.15817 9.57542 4.6665 10.7624 4.6665 12.0001V12.0001C4.6665 13.2378 5.15817 14.4247 6.03334 15.2999C6.90851 16.1751 8.09549 16.6667 9.33317 16.6667H9.99984"
        stroke="#333333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.3332 19.3334L12.6665 16.6667H14.6665C15.9042 16.6667 17.0912 16.175 17.9663 15.2999C18.8415 14.4247 19.3332 13.2377 19.3332 12C19.3332 10.7624 18.8415 9.57538 17.9663 8.70021C17.0912 7.82504 15.9042 7.33337 14.6665 7.33337H13.9998"
        stroke="#333333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ExportImport = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M9.99967 5.99984V0.666504H5.99967V5.99984H3.33301L7.99967 11.3332L12.6663 5.99984H9.99967Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.3332 9.99988V13.3332C15.3332 13.8636 15.1225 14.3724 14.7474 14.7474C14.3723 15.1225 13.8636 15.3332 13.3332 15.3332H2.6665C2.13607 15.3332 1.62736 15.1225 1.25229 14.7474C0.877218 14.3724 0.666504 13.8636 0.666504 13.3332V9.99988"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
//sort arrow Icon
export const ArrowIconSort = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M3.7998 1.2002V10.2002M3.7998 10.2002L0.799805 7.2002M3.7998 10.2002L6.7998 7.2002"
        stroke="#333333"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export const ThumbsDown = ({ stroke = "#333333", fill = "none" }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="2" fill={fill} fillOpacity="0.06" />
    <path
      d="M15.75 5.75065H18.25V11.584H15.75"
      stroke={stroke}
      strokeWidth="0.833333"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.75 11.5833L13.25 18.25H12.4167C11.9567 18.25 11.5833 17.8767 11.5833 17.4167V13.25H7.4175C6.315 13.25 5.51667 12.1992 5.81167 11.1375L6.96917 6.97083C7.16917 6.24917 7.82667 5.75 8.575 5.75H15.75V11.5833Z"
      stroke={stroke}
      strokeWidth="0.833333"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ThumbsUps = ({ stroke = "#333333", fill = "none" }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="2" fill={fill} fillOpacity="0.06" />
    <path
      d="M8.25 18.2493H5.75V12.416H8.25"
      stroke={stroke}
      strokeWidth="0.833333"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.25 12.4167L10.75 5.75H11.5833C12.0433 5.75 12.4167 6.12333 12.4167 6.58333V10.75H16.5825C17.685 10.75 18.4833 11.8008 18.1883 12.8625L17.0308 17.0292C16.8308 17.7508 16.1733 18.25 15.425 18.25H8.25V12.4167Z"
      stroke={stroke}
      strokeWidth="0.833333"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const HyBlastApproverNotificationIcon = ({ stroke = "#333333", fill = "none" }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.5 5.5H17.5C17.7652 5.5 18.0196 5.60536 18.2071 5.79289C18.3946 5.98043 18.5 6.23478 18.5 6.5V18.5C18.5 18.7652 18.3946 19.0196 18.2071 19.2071C18.0196 19.3946 17.7652 19.5 17.5 19.5H6.5C6.23478 19.5 5.98043 19.3946 5.79289 19.2071C5.60536 19.0196 5.5 18.7652 5.5 18.5V6.5C5.5 6.23478 5.60536 5.98043 5.79289 5.79289C5.98043 5.60536 6.23478 5.5 6.5 5.5H7.5"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.5 4.5H9.5V6.5H14.5V4.5Z"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M9 13L11 15L15 11" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
//arrow head down stroke
export const IconUpArrow = ({
  stroke = "#333333",
  fill = "none",
  height = "20",
  width = "20",
  viewBox = "0 0 20 20",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M15 8L10 13L5 8" stroke={stroke} strokeMiterlimit="10" strokeLinecap="square" />
    </svg>
  );
};
//arrow head down stroke small
export const IconDownArrowSM = ({ stroke = "#333333", fill = "none" }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 6.40039L8 10.4004L4 6.40039"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  );
};
//arrow head left stroke large
export const IconLeftArrowLG = ({ stroke = "#333333", fill = "none" }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.3984 6L8.39844 12L14.3984 18"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  );
};

export const CopiedIcon = ({ fill = "none", stroke = "#333333" }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.5 10.7L7.64286 13.5L15.5 6.5"
      stroke="#66D184"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
);

export const InsetLinkIcon = ({
  width = "34",
  height = "34",
  fill = "none",
  stroke = "#333333",
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_7352_45196)">
      <path
        d="M10.8987 22.3277C9.77396 21.0396 9.90672 19.0783 11.1948 17.9536L14.2078 15.3227L12.9581 13.8915L9.94508 16.5224C7.86611 18.3378 7.65216 21.4984 9.46751 23.5774C11.2829 25.6563 14.4435 25.8703 16.5224 24.0549L19.5354 21.424L18.2857 19.9928L15.2727 22.6238C13.9847 23.7485 12.0234 23.6157 10.8987 22.3277ZM14.6447 20.3842L20.6707 15.1223L19.3553 13.6158L13.3293 18.8777L14.6447 20.3842ZM17.4776 9.94509L14.4646 12.576L15.7143 14.0072L18.7273 11.3763C20.0153 10.2515 21.9766 10.3843 23.1013 11.6724C24.226 12.9604 24.0933 14.9217 22.8052 16.0464L19.7922 18.6773L21.0419 20.1085L24.0549 17.4776C26.1339 15.6622 26.3478 12.5016 24.5325 10.4227C22.7171 8.34369 19.5565 8.12974 17.4776 9.94509Z"
        fill={stroke}
      />
    </g>
    <defs>
      <clipPath id="clip0_7352_45196">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0.0682373 15.8539) rotate(-41.1274)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const ArtieIcon = () => (
  <svg
    width="143"
    height="140"
    viewBox="0 0 143 140"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M98.7578 8.48283C98.7578 0.47086 75.6754 0.242188 73.0447 0.242188C70.4143 0.242188 47.3315 0.471195 47.3315 8.48283C47.3315 9.42599 47.6545 10.2637 48.2201 11.0009C49.0233 12.0449 50.3237 12.9013 51.9175 13.597C58.7892 16.605 71.1621 16.7242 73.0447 16.7242C74.9363 16.7242 87.4056 16.6053 94.2617 13.5605C95.8176 12.8695 97.0838 12.0268 97.8699 11.0009C98.4393 10.264 98.7578 9.42632 98.7578 8.48283ZM94.1172 8.37302C94.1681 8.40951 94.2191 8.44634 94.2617 8.48283C92.5535 9.89774 85.3278 12.1453 73.0447 12.1453C60.9664 12.1453 53.7789 9.97073 51.9175 8.55147C51.8877 8.5287 51.8582 8.5056 51.8283 8.48283C51.8709 8.44634 51.9219 8.40951 51.9728 8.37302C53.9148 6.95376 61.0811 4.82036 73.0447 4.82036C85.0089 4.82036 92.1746 6.95376 94.1172 8.37302Z"
      fill="url(#paint0_linear_2705_52702)"
    />
    <path
      d="M94.308 13.5394C96.3124 12.6411 96.3695 9.59771 94.422 8.56415C94.3139 8.5069 94.2545 8.47744 94.261 8.48313C92.5528 9.89803 85.3271 12.1456 73.044 12.1456C60.9657 12.1456 53.7783 9.97102 51.9168 8.55176C51.887 8.529 51.8575 8.5059 51.8276 8.48313C51.8339 8.47744 51.7761 8.50623 51.6704 8.56215C49.7163 9.59838 49.785 12.6471 51.7978 13.5448C51.8373 13.5622 51.8767 13.5796 51.9168 13.597C58.7885 16.6049 71.1614 16.7241 73.044 16.7241C74.9356 16.7241 87.4049 16.6053 94.261 13.5605C94.2769 13.5531 94.2921 13.5464 94.308 13.5394Z"
      fill="url(#paint1_radial_2705_52702)"
    />
    <path
      d="M64.7982 139.329H56.4956C55.5517 139.329 54.7861 138.504 54.7861 137.487C54.7861 136.471 55.5517 135.646 56.4956 135.646H60.5889C56.5363 113.111 60.8857 99.9822 61.0787 99.417C61.4048 98.4625 62.3882 97.9746 63.2737 98.3259C64.1589 98.6767 64.6123 99.7334 64.2875 100.687C64.2251 100.874 59.8275 114.39 64.3544 137.101L64.7982 139.329Z"
      fill="#009BCC"
    />
    <path
      d="M89.5928 139.329H80.98L81.4244 137.101C85.9591 114.345 81.5354 100.821 81.49 100.687C81.1683 99.7328 81.6237 98.6755 82.5085 98.3266C83.3931 97.978 84.374 98.4645 84.6994 99.4177C84.8931 99.9829 89.2419 113.112 85.1892 135.647H89.5928C90.5373 135.647 91.3028 136.471 91.3028 137.488C91.3028 138.505 90.5373 139.329 89.5928 139.329Z"
      fill="#009BCC"
    />
    <path
      d="M110.94 66.1489C110.94 84.9685 107.929 101.5 93.3115 108.777C92.895 108.985 92.4689 109.187 92.0338 109.377C88.8415 110.784 85.1335 111.782 80.8319 112.301C78.5848 112.574 76.1729 112.717 73.5867 112.72H73.5C73.3477 112.72 73.1982 112.717 73.0459 112.713C72.8936 112.717 72.7447 112.72 72.5893 112.72H72.4989C69.9164 112.717 67.5048 112.574 65.2574 112.301C60.9558 111.782 57.2475 110.784 54.0549 109.377C53.6204 109.184 53.194 108.982 52.7778 108.774C38.1608 101.497 35.1484 84.9658 35.1484 66.1489C35.1484 43.7615 46.9936 28.5046 64.6047 25.2422C66.9419 24.8057 69.3783 24.583 71.9055 24.583C72.1048 24.583 72.2996 24.586 72.4986 24.586C72.6819 24.592 72.8622 24.5947 73.0456 24.6008C73.2258 24.5947 73.4061 24.592 73.5864 24.586C73.7856 24.586 73.9845 24.583 74.1831 24.583C76.7103 24.583 79.147 24.8057 81.484 25.2422C99.0953 28.5046 110.94 43.7612 110.94 66.1489Z"
      fill="url(#paint2_radial_2705_52702)"
    />
    <path
      d="M33.506 107.474C32.6914 107.319 32.0147 106.849 31.9371 106.355C29.7884 92.6621 35.5678 83.8249 35.8147 83.4551C36.123 82.9943 37.0903 82.8712 37.9744 83.1837C38.858 83.4941 39.3249 84.1194 39.0176 84.58C38.9595 84.6672 33.2365 93.449 35.314 106.687C35.4 107.235 34.7137 107.605 33.7813 107.514C33.6883 107.504 33.5957 107.491 33.506 107.474Z"
      fill="#009BCC"
    />
    <path
      d="M36.7196 107.175C36.8214 104.886 35.3127 102.756 33.3509 102.417C31.3883 102.078 29.7156 103.659 29.6139 105.948C29.5128 108.236 31.0214 110.366 32.9834 110.704C34.9456 111.044 36.6186 109.463 36.7196 107.175Z"
      fill="#009BCC"
    />
    <path
      d="M114.691 109.717C114.604 109.75 114.515 109.779 114.424 109.805C113.515 110.059 112.773 109.815 112.767 109.26C112.624 95.86 105.49 88.2175 105.418 88.1417C105.036 87.7422 105.396 87.0442 106.22 86.5834C107.047 86.1224 108.023 86.0722 108.405 86.4717C108.712 86.7919 115.913 94.4788 116.06 108.339C116.066 108.839 115.472 109.421 114.691 109.717Z"
      fill="#009BCC"
    />
    <path
      d="M111.48 110.005C111.001 107.774 112.14 105.416 114.023 104.737C115.906 104.058 117.821 105.317 118.3 107.548C118.778 109.778 117.639 112.136 115.756 112.815C113.873 113.493 111.958 112.235 111.48 110.005Z"
      fill="#009BCC"
    />
    <path
      d="M102.618 90.5709C102.618 90.5709 115.772 90.3981 121.107 87.4612C125.661 84.9535 129.281 81.7453 128.522 76.5156C128.522 76.5156 124.242 80.6107 119.633 79.7459C108.035 77.5693 101.861 85.3435 102.618 90.5709Z"
      fill="url(#paint3_radial_2705_52702)"
    />
    <path
      d="M105.601 82.2629C105.601 82.2629 120.884 82.063 127.081 78.6496C132.372 75.7358 136.578 72.0084 135.697 65.9336C135.697 65.9336 130.723 70.6912 125.369 69.6865C111.894 67.1573 104.722 76.1888 105.601 82.2629Z"
      fill="url(#paint4_radial_2705_52702)"
    />
    <path
      d="M106.479 77.3422C106.479 77.3422 125.409 75.2249 132.734 70.2342C138.987 65.9738 143.81 60.8375 142.077 53.4121C142.077 53.4121 136.412 59.9201 129.666 59.3288C112.691 57.8413 104.748 69.9175 106.479 77.3422Z"
      fill="url(#paint5_radial_2705_52702)"
    />
    <path
      d="M40.4916 88.9117C40.4916 88.9117 27.3383 88.7396 22.0029 85.802C17.449 83.2943 13.8283 80.0855 14.5867 74.8564C14.5867 74.8564 18.8681 78.9515 23.4774 78.0867C35.0755 75.9101 41.2497 83.6843 40.4916 88.9117Z"
      fill="url(#paint6_radial_2705_52702)"
    />
    <path
      d="M37.5076 80.6047C37.5076 80.6047 22.2258 80.4041 16.028 76.9914C10.7371 74.0776 6.53123 70.3502 7.41206 64.2754C7.41206 64.2754 12.3856 69.033 17.7399 68.0283C31.215 65.4991 38.3885 74.5306 37.5076 80.6047Z"
      fill="url(#paint7_radial_2705_52702)"
    />
    <path
      d="M36.6296 75.6831C36.6296 75.6831 17.7008 73.5657 10.3747 68.5751C4.12158 64.3146 -0.700892 59.1783 1.03155 51.7529C1.03155 51.7529 6.69728 58.2609 13.4427 57.6697C30.42 56.1821 38.3621 68.2583 36.6296 75.6831Z"
      fill="url(#paint8_radial_2705_52702)"
    />
    <path
      d="M99.3376 95.9031L99.3 96.0122L95.0889 96.8134C95.0699 97.8868 94.8837 104.225 93.04 109.037C92.7979 109.114 92.5527 109.188 92.3037 109.259C94.3768 104.084 94.4672 96.7676 94.4704 96.6721V96.5302L98.7629 95.7139C99.4184 93.4958 99.3311 84.5641 99.328 84.4727L99.9496 84.4707C99.9555 84.8537 100.043 93.8484 99.3376 95.9031Z"
      fill="#ABB1B8"
    />
    <path
      d="M51.618 96.6721C51.6217 96.7676 51.7115 104.082 53.7846 109.257C53.5363 109.186 53.2905 109.112 53.0483 109.035C51.2052 104.223 51.0184 97.8868 51.0001 96.8134L46.7883 96.0122L46.7514 95.9031C46.0455 93.8484 46.1328 84.8537 46.1387 84.4707L46.7603 84.4727C46.7572 84.5641 46.6699 93.4958 47.326 95.7139L51.618 96.5302V96.6721Z"
      fill="#ABB1B8"
    />
    <path
      d="M73.3545 24.5967V112.72H72.7329V24.5967C72.8355 24.5967 72.9412 24.5997 73.0437 24.6027C73.1463 24.5997 73.252 24.5967 73.3545 24.5967Z"
      fill="#ABB1B8"
    />
    <path
      d="M57.0839 89.3999H89.0042C99.9953 89.3999 109.184 80.6795 110.91 69.3449C110.737 62.9052 109.777 57.0192 108.136 51.7815C103.478 41.9977 94.0085 35.2627 83.1489 35.2627H62.9392C52.0762 35.2627 42.6049 42.0024 37.9484 51.7909C36.3095 57.0243 35.3504 62.9042 35.1772 69.3372C36.9016 80.6755 46.091 89.3999 57.0839 89.3999Z"
      fill="#ABB1B8"
    />
    <path
      d="M89.0045 86.7831H57.0842C44.8741 86.7831 34.8838 76.021 34.8838 62.868C34.8838 46.2458 47.5085 32.6455 62.9398 32.6455H83.1495C98.5805 32.6455 111.206 46.2454 111.206 62.868C111.206 76.021 101.216 86.7831 89.0045 86.7831Z"
      fill="url(#paint9_radial_2705_52702)"
    />
    <path
      d="M87.6386 84.4654H58.4507C47.2852 84.4654 38.1499 74.6247 38.1499 62.5974C38.1499 47.3981 49.6943 34.9619 63.8049 34.9619H82.2853C96.3953 34.9619 107.94 47.3981 107.94 62.5974C107.94 74.6247 98.8047 84.4654 87.6386 84.4654Z"
      fill="url(#paint10_linear_2705_52702)"
    />
    <path
      d="M87.836 84.4078H58.2527C47.8438 84.4078 39.3267 75.233 39.3267 64.0199V63.0617C39.3267 48.8916 50.0896 37.2979 63.2443 37.2979H82.8445C95.9997 37.2979 106.761 48.8916 106.761 63.0617V64.0199C106.762 75.2327 98.2462 84.4078 87.836 84.4078Z"
      fill="white"
    />
    <path
      d="M89.8626 72.3233C91.0238 72.3233 91.9746 71.8318 91.9746 71.2304C93.8261 65.4248 93.7829 59.4535 91.9746 53.3275C91.9746 52.7262 91.0238 52.2344 89.8626 52.2344C88.7021 52.2344 87.752 52.7262 87.752 53.3275V71.2304C87.752 71.8318 88.7021 72.3233 89.8626 72.3233Z"
      fill="url(#paint11_radial_2705_52702)"
    />
    <path
      d="M57.4546 72.3233C56.2947 72.3233 55.3439 71.8321 55.3439 71.2301C53.493 65.4238 53.5356 59.4535 55.3439 53.3282C55.3439 52.7259 56.2947 52.2344 57.4546 52.2344C58.6164 52.2344 59.5665 52.7259 59.5665 53.3282V71.2301C59.5665 71.8321 58.6164 72.3233 57.4546 72.3233Z"
      fill="url(#paint12_radial_2705_52702)"
    />
    <g filter="url(#filter0_d_2705_52702)">
      <path
        d="M61.0049 89.182L61.1096 78.8496C61.1162 78.1938 61.8413 77.8565 62.3357 78.2793L69.6921 84.5703C70.1794 84.9871 70.0265 85.7927 69.4372 85.9727C66.6995 86.8088 64.7949 87.8487 62.2661 89.8567C61.7584 90.2598 60.9981 89.8585 61.0049 89.182Z"
        fill="url(#paint13_linear_2705_52702)"
      />
      <path
        d="M82.5032 79.4853L74.6756 84.253C73.9943 84.6681 74.2055 85.9976 74.9424 86.1465C77.8268 86.7293 79.5767 88.3564 82.43 90.7922C82.899 91.1925 83.5694 90.7592 83.5664 90.0598L83.5229 80.1981C83.5201 79.5778 82.9759 79.1974 82.5032 79.4853Z"
        fill="url(#paint14_linear_2705_52702)"
      />
      <ellipse
        rx="2.23664"
        ry="2.11589"
        transform="matrix(0.792099 -0.583864 0.577651 0.797773 71.6428 86.3479)"
        fill="#35B5DF"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_2705_52702"
        x="59.4342"
        y="78.0918"
        width="25.703"
        height="15.995"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1.57073" />
        <feGaussianBlur stdDeviation="0.785366" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2705_52702" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2705_52702"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_2705_52702"
        x1="47.3314"
        y1="8.48304"
        x2="98.758"
        y2="8.48304"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.014" stopColor="#00B0ED" />
        <stop offset="0.1059" stopColor="#77CEF3" />
        <stop offset="0.2637" stopColor="#77CEF3" />
        <stop offset="0.9391" stopColor="#77CEF3" />
        <stop offset="1" stopColor="#00B0ED" />
      </linearGradient>
      <radialGradient
        id="paint1_radial_2705_52702"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(73.0468 12.6031) scale(16.348 17.6104)"
      >
        <stop stopColor="#63C9F3" />
        <stop offset="0.5787" stopColor="#00B0ED" />
        <stop offset="0.8316" stopColor="#00AFEB" />
        <stop offset="0.9226" stopColor="#00ABE5" />
        <stop offset="0.9874" stopColor="#00A5DC" />
        <stop offset="1" stopColor="#009BCC" />
      </radialGradient>
      <radialGradient
        id="paint2_radial_2705_52702"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(71.9811 42.0053) scale(54.4823 69.0364)"
      >
        <stop stopColor="#63C9F3" />
        <stop offset="0.4961" stopColor="#00B0ED" />
        <stop offset="0.7732" stopColor="#00AFEB" />
        <stop offset="0.873" stopColor="#00ABE3" />
        <stop offset="0.9439" stopColor="#00A4D9" />
        <stop offset="1" stopColor="#009BCC" />
      </radialGradient>
      <radialGradient
        id="paint3_radial_2705_52702"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(118.429 85.1844) rotate(170.451) scale(20.0826 9.81487)"
      >
        <stop stopColor="white" />
        <stop offset="0.1486" stopColor="#F9FDFF" />
        <stop offset="0.2832" stopColor="#EDF7FD" />
        <stop offset="0.4125" stopColor="#D9F0FC" />
        <stop offset="0.5386" stopColor="#BEE5FA" />
        <stop offset="0.6623" stopColor="#99D8F7" />
        <stop offset="0.7842" stopColor="#67CAF3" />
        <stop offset="0.9023" stopColor="#00BCF0" />
        <stop offset="1" stopColor="#00B0ED" />
      </radialGradient>
      <radialGradient
        id="paint4_radial_2705_52702"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(123.97 76.0052) rotate(170.451) scale(23.3314 11.4027)"
      >
        <stop stopColor="white" />
        <stop offset="0.1486" stopColor="#F9FDFF" />
        <stop offset="0.2832" stopColor="#EDF7FD" />
        <stop offset="0.4125" stopColor="#D9F0FC" />
        <stop offset="0.5386" stopColor="#BEE5FA" />
        <stop offset="0.6623" stopColor="#99D8F7" />
        <stop offset="0.7842" stopColor="#67CAF3" />
        <stop offset="0.9023" stopColor="#00BCF0" />
        <stop offset="1" stopColor="#00B0ED" />
      </radialGradient>
      <radialGradient
        id="paint5_radial_2705_52702"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(128.599 67.336) rotate(164.849) scale(29.1357 14.1581)"
      >
        <stop stopColor="white" />
        <stop offset="0.1486" stopColor="#F9FDFF" />
        <stop offset="0.2832" stopColor="#EDF7FD" />
        <stop offset="0.4125" stopColor="#D9F0FC" />
        <stop offset="0.5386" stopColor="#BEE5FA" />
        <stop offset="0.6623" stopColor="#99D8F7" />
        <stop offset="0.7842" stopColor="#67CAF3" />
        <stop offset="0.9023" stopColor="#00BCF0" />
        <stop offset="1" stopColor="#00B0ED" />
      </radialGradient>
      <radialGradient
        id="paint6_radial_2705_52702"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(24.6802 83.5256) rotate(9.54871) scale(20.0829 9.81503)"
      >
        <stop stopColor="white" />
        <stop offset="0.1486" stopColor="#F9FDFF" />
        <stop offset="0.2832" stopColor="#EDF7FD" />
        <stop offset="0.4125" stopColor="#D9F0FC" />
        <stop offset="0.5386" stopColor="#BEE5FA" />
        <stop offset="0.6623" stopColor="#99D8F7" />
        <stop offset="0.7842" stopColor="#67CAF3" />
        <stop offset="0.9023" stopColor="#00BCF0" />
        <stop offset="1" stopColor="#00B0ED" />
      </radialGradient>
      <radialGradient
        id="paint7_radial_2705_52702"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(19.1387 74.3467) rotate(9.54871) scale(23.3314 11.4027)"
      >
        <stop stopColor="white" />
        <stop offset="0.1486" stopColor="#F9FDFF" />
        <stop offset="0.2832" stopColor="#EDF7FD" />
        <stop offset="0.4125" stopColor="#D9F0FC" />
        <stop offset="0.5386" stopColor="#BEE5FA" />
        <stop offset="0.6623" stopColor="#99D8F7" />
        <stop offset="0.7842" stopColor="#67CAF3" />
        <stop offset="0.9023" stopColor="#00BCF0" />
        <stop offset="1" stopColor="#00B0ED" />
      </radialGradient>
      <radialGradient
        id="paint8_radial_2705_52702"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(14.5098 65.6766) rotate(15.1511) scale(29.1355 14.158)"
      >
        <stop stopColor="white" />
        <stop offset="0.1486" stopColor="#F9FDFF" />
        <stop offset="0.2832" stopColor="#EDF7FD" />
        <stop offset="0.4125" stopColor="#D9F0FC" />
        <stop offset="0.5386" stopColor="#BEE5FA" />
        <stop offset="0.6623" stopColor="#99D8F7" />
        <stop offset="0.7842" stopColor="#67CAF3" />
        <stop offset="0.9023" stopColor="#00BCF0" />
        <stop offset="1" stopColor="#00B0ED" />
      </radialGradient>
      <radialGradient
        id="paint9_radial_2705_52702"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(73.0448 59.7144) scale(32.3087 34.8035)"
      >
        <stop stopColor="#63C9F3" />
        <stop offset="0.4961" stopColor="#00B0ED" />
        <stop offset="0.7732" stopColor="#00AFEB" />
        <stop offset="0.873" stopColor="#00ABE3" />
        <stop offset="0.9439" stopColor="#00A4D9" />
        <stop offset="1" stopColor="#009BCC" />
      </radialGradient>
      <linearGradient
        id="paint10_linear_2705_52702"
        x1="73.7048"
        y1="34.9464"
        x2="72.9411"
        y2="62.4856"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="5.4e-07" stopColor="#DDDFE3" />
        <stop offset="0.2271" stopColor="#E8E9EB" />
        <stop offset="0.5942" stopColor="#F4F4F5" />
        <stop offset="1" stopColor="#F9F9F9" />
      </linearGradient>
      <radialGradient
        id="paint11_radial_2705_52702"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(90.5496 62.2789) scale(6.88358 7.41515)"
      >
        <stop stopColor="#00B0ED" />
        <stop offset="0.0849" stopColor="#00ABE5" />
        <stop offset="0.2237" stopColor="#00A3D4" />
        <stop offset="0.3991" stopColor="#2995B9" />
        <stop offset="0.6042" stopColor="#4B7C92" />
        <stop offset="0.8315" stopColor="#47565F" />
        <stop offset="1" />
      </radialGradient>
      <radialGradient
        id="paint12_radial_2705_52702"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(56.7689 62.2789) scale(6.88357 7.41515)"
      >
        <stop stopColor="#00B0ED" />
        <stop offset="0.0849" stopColor="#00ABE5" />
        <stop offset="0.2237" stopColor="#00A3D4" />
        <stop offset="0.3991" stopColor="#2995B9" />
        <stop offset="0.6042" stopColor="#4B7C92" />
        <stop offset="0.8315" stopColor="#47565F" />
        <stop offset="1" />
      </radialGradient>
      <linearGradient
        id="paint13_linear_2705_52702"
        x1="60.1963"
        y1="84.6108"
        x2="70.922"
        y2="86.5957"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.15625" stopColor="#1F7D9C" />
        <stop offset="0.553486" stopColor="#7ADDFF" />
      </linearGradient>
      <linearGradient
        id="paint14_linear_2705_52702"
        x1="89.7369"
        y1="84.4847"
        x2="73.5492"
        y2="89.1433"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.3125" stopColor="#1F7D9C" />
        <stop offset="1" stopColor="#7ADDFF" />
      </linearGradient>
    </defs>
  </svg>
);

export const EmptyTableIcon = ({ fill = "none", stroke = "#333333" }) => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.24905 23.5625L3.24987 17.875"
        stroke="#333333"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M9.74905 10.5625V23.5625"
        stroke="#333333"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M16.2646 23.5625L16.25 17.875"
        stroke="#333333"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M22.7491 10.5625V23.5625"
        stroke="#333333"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  );
};

export const DatePickerIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 8.33325H15"
        stroke="#333333"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.3333 6.33325H5.66667C5.48986 6.33325 5.32029 6.40349 5.19526 6.52851C5.07024 6.65354 5 6.82311 5 6.99992V14.3333C5 14.5101 5.07024 14.6796 5.19526 14.8047C5.32029 14.9297 5.48986 14.9999 5.66667 14.9999H14.3333C14.5101 14.9999 14.6797 14.9297 14.8047 14.8047C14.9298 14.6796 15 14.5101 15 14.3333V6.99992C15 6.82311 14.9298 6.65354 14.8047 6.52851C14.6797 6.40349 14.5101 6.33325 14.3333 6.33325V6.33325Z"
        stroke="#333333"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.66666 5V6.33333"
        stroke="#333333"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.3333 5V6.33333"
        stroke="#333333"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ScheduleIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.875 8.625H22.125" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M22.125 8.62501V5.92501C22.125 5.56697 21.9828 5.22359 21.7296 4.97042C21.4764 4.71724 21.133 4.57501 20.775 4.57501H3.225C2.86696 4.57501 2.52358 4.71724 2.27041 4.97042C2.01723 5.22359 1.875 5.56697 1.875 5.92501V20.775C1.875 21.1331 2.01723 21.4764 2.27041 21.7296C2.52358 21.9828 2.86696 22.125 3.225 22.125H9.975"
      stroke="#333333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M7.27539 1.875V4.575" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M16.7246 1.875V4.575" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M16.7252 22.125C19.7075 22.125 22.1252 19.7073 22.1252 16.725C22.1252 13.7427 19.7075 11.325 16.7252 11.325C13.7429 11.325 11.3252 13.7427 11.3252 16.725C11.3252 19.7073 13.7429 22.125 16.7252 22.125Z"
      stroke="#333333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.7246 14.025V16.725H19.4246"
      stroke="#333333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ShareIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.3125 8.5625L15.4375 1.6875V5.8125C11.098 5.8125 5.8125 8.36175 5.8125 15.4375C7.95613 11.9684 12 11.3125 15.4375 11.3125V15.4375L22.3125 8.5625Z"
      stroke="#333333"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.3125 15.4375V20.9375C22.3125 21.6965 21.6965 22.3125 20.9375 22.3125H3.0625C2.3035 22.3125 1.6875 21.6965 1.6875 20.9375V5.8125C1.6875 5.0535 2.3035 4.4375 3.0625 4.4375H5.8125"
      stroke="#333333"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const StatisticsIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.5 10.5L9.5 3.5L15.5 9.5L22.5 2.5"
      stroke="#212121"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path d="M3.5 22.5V19.5" stroke="#212121" strokeMiterlimit="10" strokeLinecap="square" />
    <path d="M9.5 12.5V22.5" stroke="#212121" strokeMiterlimit="10" strokeLinecap="square" />
    <path d="M15.5 22.5V19.5" stroke="#212121" strokeMiterlimit="10" strokeLinecap="square" />
    <path d="M21.5 12.5V22.5" stroke="#212121" strokeMiterlimit="10" strokeLinecap="square" />
  </svg>
);

export const HyMailAILarge = () => (
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="50" height="50" fill="#26BBED" />
    <path
      d="M13.6777 28.691H13.357C12.8987 28.691 12.6696 28.4358 12.6696 27.9253V19.0071C12.6696 17.6258 12.0738 16.9352 10.8823 16.9352H9.23255C7.91885 16.9352 7.262 17.5958 7.262 18.917V27.9253C7.262 28.4358 7.03286 28.691 6.57459 28.691H6.25381C6.0094 28.691 5.82609 28.6009 5.70389 28.4208C5.61223 28.2406 5.56641 28.0754 5.56641 27.9253V11.2149C5.56641 11.0348 5.61223 10.8696 5.70389 10.7195C5.82609 10.5393 6.0094 10.4492 6.25381 10.4492H6.57459C7.03286 10.4492 7.262 10.7045 7.262 11.2149V15.5839C7.96467 15.2837 8.62152 15.1335 9.23255 15.1335H10.8823C11.9516 15.1335 12.807 15.4788 13.4486 16.1695C14.0902 16.8301 14.411 17.776 14.411 19.0071V27.9253C14.411 28.4358 14.1666 28.691 13.6777 28.691Z"
      fill="white"
    />
    <path
      d="M22.9688 27.7001C22.7244 28.4208 22.5105 29.0213 22.3272 29.5018C22.1439 29.9822 21.9758 30.3425 21.8231 30.5827C21.0288 31.8739 19.9595 32.5195 18.6152 32.5195H18.0195C17.8362 32.5195 17.6529 32.4595 17.4695 32.3394C17.2862 32.2493 17.1946 32.0541 17.1946 31.7538V31.4836C17.1946 31.2434 17.271 31.0482 17.4237 30.898C17.5765 30.7779 17.7751 30.7179 18.0195 30.7179H18.6152C19.3179 30.7179 19.8373 30.4476 20.1733 29.9071C20.5094 29.3666 20.7843 28.7811 20.9982 28.1505C20.3872 26.4389 19.7151 24.5472 18.9818 22.4753C18.2486 20.4034 17.5459 18.3765 16.8738 16.3947C16.8127 16.2446 16.8127 16.0494 16.8738 15.8092C16.9655 15.5689 17.164 15.4488 17.4695 15.4488H17.8362C18.1111 15.4488 18.325 15.5839 18.4777 15.8542C18.5388 15.9743 18.5694 16.0344 18.5694 16.0344L21.8231 25.5831L23.9311 19.4125L25.0768 16.0344C25.2295 15.644 25.4892 15.4488 25.8558 15.4488H26.1766C26.5127 15.4488 26.7113 15.5839 26.7724 15.8542C26.8335 16.0944 26.8488 16.2896 26.8182 16.4397L22.9688 27.7001Z"
      fill="white"
    />
    <path
      d="M30.2488 28.691H29.928C29.6836 28.691 29.5003 28.6009 29.3781 28.4208C29.2864 28.2406 29.2406 28.0754 29.2406 27.9253V11.2149C29.2406 11.0348 29.2864 10.8696 29.3781 10.7195C29.5003 10.5393 29.6836 10.4492 29.928 10.4492H30.2488C30.7376 10.4492 30.982 10.7045 30.982 11.2149V27.9253C30.982 28.4358 30.7376 28.691 30.2488 28.691Z"
      fill="white"
    />
    <path
      d="M39.8853 27.7001C39.6409 28.4208 39.427 29.0213 39.2437 29.5018C39.0604 29.9822 38.8924 30.3425 38.7396 30.5827C37.9453 31.8739 36.876 32.5195 35.5317 32.5195H34.936C34.7527 32.5195 34.5694 32.4595 34.3861 32.3394C34.2028 32.2493 34.1111 32.0541 34.1111 31.7538V31.4836C34.1111 31.2434 34.1875 31.0482 34.3402 30.898C34.493 30.7779 34.6916 30.7179 34.936 30.7179H35.5317C36.2344 30.7179 36.7538 30.4476 37.0898 29.9071C37.4259 29.3666 37.7009 28.7811 37.9147 28.1505C37.3037 26.4389 36.6316 24.5472 35.8984 22.4753C35.1651 20.4034 34.4624 18.3765 33.7903 16.3947C33.7292 16.2446 33.7292 16.0494 33.7903 15.8092C33.882 15.5689 34.0806 15.4488 34.3861 15.4488H34.7527C35.0276 15.4488 35.2415 15.5839 35.3943 15.8542C35.4554 15.9743 35.4859 16.0344 35.4859 16.0344L38.7396 25.5831L40.8476 19.4125L41.9933 16.0344C42.1461 15.644 42.4058 15.4488 42.7724 15.4488H43.0931C43.4292 15.4488 43.6278 15.5839 43.6889 15.8542C43.75 16.0944 43.7653 16.2896 43.7347 16.4397L39.8853 27.7001Z"
      fill="white"
    />
    <path
      d="M34.7773 41.5039C34.6875 41.5039 34.6201 41.459 34.5752 41.3691C34.5303 41.2643 34.5078 41.167 34.5078 41.0771V39.8418C34.5078 39.7519 34.5303 39.6621 34.5752 39.5723C34.6201 39.4674 34.6875 39.415 34.7773 39.415H35.2266C35.3164 39.415 35.3838 39.4674 35.4287 39.5723C35.4736 39.6621 35.4961 39.7519 35.4961 39.8418V41.0771C35.4961 41.167 35.4736 41.2643 35.4287 41.3691C35.3838 41.459 35.3164 41.5039 35.2266 41.5039H34.7773Z"
      fill="white"
    />
    <path
      d="M41.3647 41.5039C41.2605 41.5039 41.1824 41.4674 41.1303 41.3945C41.0782 41.3216 41.0418 41.2591 41.0209 41.207L40.5834 39.8789H38.5209L38.0522 41.2383C38.0314 41.3112 37.9897 41.3737 37.9272 41.4258C37.8647 41.4778 37.7918 41.5039 37.7085 41.5039H37.5991C37.4637 41.5039 37.3699 41.4518 37.3178 41.3476C37.2657 41.2435 37.2553 41.1497 37.2866 41.0664L39.0991 35.4727C39.1199 35.431 39.1512 35.3737 39.1928 35.3008C39.2449 35.2279 39.3282 35.1914 39.4428 35.1914H39.6459C39.823 35.1914 39.9428 35.2799 40.0053 35.457L41.8178 41.0664C41.8491 41.1497 41.8387 41.2435 41.7866 41.3476C41.7345 41.4518 41.6407 41.5039 41.5053 41.5039H41.3647ZM39.5366 36.6133C39.4845 36.8008 39.422 37.0091 39.3491 37.2383C39.2866 37.4674 39.2189 37.6914 39.1459 37.9101C39.0834 38.1289 39.0209 38.3372 38.9584 38.5351C38.8959 38.7331 38.8439 38.8997 38.8022 39.0351H40.3022L39.5366 36.6133Z"
      fill="white"
    />
    <path
      d="M43.1664 41.5039C43.0519 41.5039 42.9685 41.4622 42.9164 41.3789C42.8748 41.2956 42.8539 41.2174 42.8539 41.1445V35.5508C42.8539 35.4674 42.8748 35.3893 42.9164 35.3164C42.9685 35.2331 43.0519 35.1914 43.1664 35.1914H43.2914C43.5102 35.1914 43.6196 35.3112 43.6196 35.5508V41.1445C43.6196 41.3841 43.5102 41.5039 43.2914 41.5039H43.1664Z"
      fill="white"
    />
  </svg>
);

export const AttachIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.75">
      <path
        d="M10.5887 8.18273L8.52277 10.2487C8.0532 10.7182 8.0532 11.48 8.52277 11.9502C8.99233 12.4198 9.75408 12.4198 10.2243 11.9502L13.0197 9.15486C13.9595 8.21505 13.9595 6.69155 13.0197 5.75173C12.0799 4.81192 10.5564 4.81192 9.61658 5.75173L6.57852 8.79048C5.16914 10.1999 5.16914 12.4851 6.57852 13.8952C7.98789 15.3045 10.2731 15.3045 11.6832 13.8952L14.4779 11.0998"
        stroke="#333333"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
export const DiamondIcon = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13 11H35L39 19L24 37L9 19L13 11Z"
      stroke="#26BBED"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 19H33"
      stroke="#26BBED"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const DiamondIconSM = () => (
  <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.41667 0.583374H11.5833L13.25 3.91671L7 11.4167L0.75 3.91671L2.41667 0.583374Z"
      stroke="#26BBED"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.25 3.91675H10.75"
      stroke="#26BBED"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const DiamondIconMedium = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.66667 7.3335H23.3333L26 12.6668L16 24.6668L6 12.6668L8.66667 7.3335Z"
      stroke="#26BBED"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 12.667H22"
      stroke="#26BBED"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const SlidesPreviewIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.87267 13.683C1.6301 13.3362 1.5 12.9232 1.5 12.5C1.5 12.0768 1.6301 11.6638 1.87267 11.317C3.44567 9.09 7.31867 4.5 12.4997 4.5C17.6807 4.5 21.5537 9.09 23.1267 11.317C23.3692 11.6638 23.4993 12.0768 23.4993 12.5C23.4993 12.9232 23.3692 13.3362 23.1267 13.683C21.5537 15.91 17.6807 20.5 12.4997 20.5C7.31867 20.5 3.44567 15.91 1.87267 13.683Z"
      stroke="#333333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5 16.5C14.7091 16.5 16.5 14.7091 16.5 12.5C16.5 10.2909 14.7091 8.5 12.5 8.5C10.2909 8.5 8.5 10.2909 8.5 12.5C8.5 14.7091 10.2909 16.5 12.5 16.5Z"
      stroke="#333333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const FavoriteIcon = withStyles({
  root: {
    width: 32,
    height: 32,
    fill: "none",
    stroke: "#333333",
  },
})(({ classes, ...rest }) => {
  return (
    <SvgIcon viewBox="0 0 32 32" className={classes.root} {...rest}>
      <path
        d="M16.4173 8.4917L18.9923 13.7084L24.7507 14.545L20.584 18.6067L21.5673 24.3417L16.4173 21.635L11.2673 24.3417L12.2507 18.6067L8.08398 14.545L13.8423 13.7084L16.4173 8.4917Z"
        stroke={classes.root.stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
});

export const MarkAsAttendeedIcon = () => (
  <svg width="51" height="32" viewBox="0 0 51 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_4504_70)">
      <rect width="50" height="32" fill="white" />
      <path
        d="M20.2383 16.7L22.9594 19.5L29.7621 12.5"
        stroke="#333333"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_4504_70"
        x="0"
        y="0"
        width="51"
        height="32"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.933333 0 0 0 0 0.933333 0 0 0 0 0.933333 0 0 0 1 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4504_70" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4504_70" result="shape" />
      </filter>
    </defs>
  </svg>
);

export const MarkAsUnattendeedIcon = () => (
  <svg width="50" height="32" viewBox="0 0 50 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_4504_70)">
      <rect width="50" height="32" fill="white" />
      <path
        d="M28.75 12.75L21.75 19.75"
        stroke="#333333"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </g>
    <path
      d="M28.75 19.75L21.75 12.75"
      stroke="#333333"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
);

export const NotifyTickIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="23" height="23" fill="white" />
    <rect x="0.5" y="0.5" width="23" height="23" fill="white" />
    <path
      d="M7 13.2L9.85714 16L17 9"
      stroke="#333333"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <rect x="0.5" y="0.5" width="23" height="23" stroke="#DADADA" />
  </svg>
);

export const NotifyCloseIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="23" height="23" fill="white" />
    <rect x="0.5" y="0.5" width="23" height="23" fill="white" />
    <path d="M16 8L8 16" stroke="#333333" strokeMiterlimit="10" strokeLinecap="square" />
    <path d="M16 16L8 8" stroke="#333333" strokeMiterlimit="10" strokeLinecap="square" />
    <rect x="0.5" y="0.5" width="23" height="23" stroke="#DADADA" />
  </svg>
);

export const NoNotificationIcon = () => (
  <svg
    width="105"
    height="108"
    viewBox="0 0 105 108"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M39.0535 93C39.0464 93.1926 39 93.3732 39 93.5676C39 101.538 45.0441 108 52.5 108C59.9559 108 66 101.538 66 93.5676C66 93.3732 65.9536 93.1926 65.9464 93H39.0535Z"
      fill="#E2E5E7"
    />
    <path
      d="M52.4998 99.9995C20.6078 99.9995 0 93.1294 0 82.4996C0 77.4948 3.18969 73.9962 6.56612 70.2951C10.7129 65.7492 14.9999 61.0471 14.9999 52.4997V37.4998C14.9999 16.4721 31.472 0 52.4998 0C73.5275 0 89.9996 16.4721 89.9996 37.4998V52.4997C89.9996 61.0471 94.2867 65.7492 98.4334 70.2951C101.81 73.9962 105 77.4948 105 82.4996C105 93.1294 84.3917 99.9995 52.4998 99.9995Z"
      fill="#EBF0F4"
    />
    <ellipse cx="52.5039" cy="60.0005" rx="2.49999" ry="4.99998" fill="#C8CED9" />
    <path
      d="M42.4999 32.7852C42.4999 33.9403 39.7017 37.5005 36.25 37.5005C32.7982 37.5005 30 33.9403 30 32.7852C30 31.6301 32.7982 34.3632 36.25 34.3632C39.7017 34.3632 42.4999 31.6301 42.4999 32.7852Z"
      fill="#C8CED9"
    />
    <path
      d="M74.996 32.7852C74.996 33.9403 72.1978 37.5005 68.7461 37.5005C65.2943 37.5005 62.4961 33.9403 62.4961 32.7852C62.4961 31.6301 65.2943 34.3632 68.7461 34.3632C72.1978 34.3632 74.996 31.6301 74.996 32.7852Z"
      fill="#C8CED9"
    />
  </svg>
);

export const UnAuthIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.2222 14.2217H9.77778C8.79594 14.2217 8 15.0176 8 15.9995V23.1106C8 24.0924 8.79594 24.8883 9.77778 24.8883H22.2222C23.2041 24.8883 24 24.0924 24 23.1106V15.9995C24 15.0176 23.2041 14.2217 22.2222 14.2217Z"
      stroke="#999999"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.9999 21.3339C16.9818 21.3339 17.7777 20.5379 17.7777 19.5561C17.7777 18.5743 16.9818 17.7783 15.9999 17.7783C15.0181 17.7783 14.2222 18.5743 14.2222 19.5561C14.2222 20.5379 15.0181 21.3339 15.9999 21.3339Z"
      stroke="#999999"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.4441 13.9993V11.1107C20.4557 9.94378 20.0034 8.82004 19.1866 7.98658C18.3698 7.15313 17.2554 6.6782 16.0885 6.66623H15.9996C14.8327 6.65462 13.709 7.10695 12.8755 7.92373C12.0421 8.74052 11.5671 9.85489 11.5552 11.0218V13.9993"
      stroke="#999999"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const UnAuthIconAppSwitcher = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.1111 7.11121H4.88889C4.39797 7.11121 4 7.50918 4 8.00009V11.5557C4 12.0466 4.39797 12.4445 4.88889 12.4445H11.1111C11.602 12.4445 12 12.0466 12 11.5557V8.00009C12 7.50918 11.602 7.11121 11.1111 7.11121Z"
      stroke="#999999"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.99997 10.6672C8.49089 10.6672 8.88886 10.2692 8.88886 9.77829C8.88886 9.28737 8.49089 8.8894 7.99997 8.8894C7.50905 8.8894 7.11108 9.28737 7.11108 9.77829C7.11108 10.2692 7.50905 10.6672 7.99997 10.6672Z"
      stroke="#999999"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.222 6.99992V5.55558C10.2278 4.97213 10.0017 4.41026 9.59328 3.99354C9.18489 3.57681 8.6277 3.33934 8.04425 3.33336H7.99981C7.41636 3.32756 6.85449 3.55372 6.43776 3.96211C6.02103 4.3705 5.78357 4.92769 5.77759 5.51114V6.99992"
      stroke="#999999"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const DiamondIconAppSwitcher = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.33333 3.66663H11.6667L13 6.33329L8 12.3333L3 6.33329L4.33333 3.66663Z"
      stroke="#26BBED"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 6.33325H11"
      stroke="#26BBED"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const AddIcon = ({ stroke = "#333333", fill = "none" }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 4.44434V15.5554" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4.44434 10H15.5554" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export const CalendarWelcome = ({ stroke = "#333333", fill = "none" }) => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="40" height="40" rx="4" fill="#26BBED" fillOpacity="0.06" />
      <path
        d="M10.625 16.875H29.375"
        stroke="#26BBED"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.5 13.125H12.5C12.0027 13.125 11.5258 13.3225 11.1742 13.6742C10.8225 14.0258 10.625 14.5027 10.625 15V26.25C10.625 26.7473 10.8225 27.2242 11.1742 27.5758C11.5258 27.9275 12.0027 28.125 12.5 28.125H27.5C27.9973 28.125 28.4742 27.9275 28.8258 27.5758C29.1775 27.2242 29.375 26.7473 29.375 26.25V15C29.375 14.5027 29.1775 14.0258 28.8258 13.6742C28.4742 13.3225 27.9973 13.125 27.5 13.125V13.125Z"
        stroke="#26BBED"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.625 10.625V13.125"
        stroke="#26BBED"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.375 10.625V13.125"
        stroke="#26BBED"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const UIIcon = ({ stroke = "#333333", fill = "none" }) => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="40" height="40" rx="4" fill="#26BBED" fillOpacity="0.06" />
      <path
        d="M14.375 10.625H10.625V29.375H14.375V10.625Z"
        stroke="#26BBED"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.375 10.625H18.125V14.375H29.375V10.625Z"
        stroke="#26BBED"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.875 18.125H18.125V21.875H21.875V18.125Z"
        stroke="#26BBED"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.375 18.125H25.625V29.375H29.375V18.125Z"
        stroke="#26BBED"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.875 25.625H18.125V29.375H21.875V25.625Z"
        stroke="#26BBED"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const EditorIcon = ({ stroke = "#333333", fill = "none" }) => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="40" height="40" rx="4" fill="#26BBED" fillOpacity="0.06" />
      <path
        d="M10.625 26.8754L13.125 29.3754L24.3588 18.1091L21.8912 15.6091L10.625 26.8754Z"
        stroke="#26BBED"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.3909 18.1091L21.8909 20.6091"
        stroke="#26BBED"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.375 10.625L25.625 14.375"
        stroke="#26BBED"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.625 10.625L29.375 14.375"
        stroke="#26BBED"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.375 23.125L25.625 26.875"
        stroke="#26BBED"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.625 23.125L29.375 26.875"
        stroke="#26BBED"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.125 10.625L14.375 14.375"
        stroke="#26BBED"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.375 10.625L18.125 14.375"
        stroke="#26BBED"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const LibraryIcon = ({ stroke = "#333333", fill = "none" }) => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="40" height="40" rx="4" fill="#26BBED" fillOpacity="0.06" />
      <path d="M12.5 27.5H27.5" stroke="#26BBED" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M15.5 13.5H12.5V24.5H15.5V13.5Z"
        stroke="#26BBED"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.5 13.5H17.5V24.5H20.5V13.5Z"
        stroke="#26BBED"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.3156 13.5004L20.5371 14.6318L24.6855 24.8196L27.464 23.6882L23.3156 13.5004Z"
        stroke="#26BBED"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ReservationIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M14.4329 23.4H8.79999V8H21.4V15.7" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M11.6329 12.2H18.6" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M11.6329 15.7H18.6" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M11.6329 19.2H14.4329" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M22.8329 19.9V23.4H17.2329V19.9L20.0329 17.8L22.8329 19.9Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M20.0329 22V23.4" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
  </SvgIcon>
);

export const TourScheduleIcon = (props) => (
  <SvgIcon {...props}>
    <g clipPath="url(#clip0_6881_94984)">
      <path
        d="M20.0875 21.6465L18.856 21.2965C18.8096 21.2832 18.7679 21.2568 18.7362 21.2205C18.7044 21.1841 18.6839 21.1393 18.677 21.0915L18.5735 20.363C18.8348 20.2436 19.0563 20.0516 19.2117 19.81C19.367 19.5684 19.4498 19.2872 19.45 19V18.313C19.4561 17.9152 19.3082 17.5305 19.0372 17.2392C18.7662 16.9479 18.3932 16.7726 17.996 16.75C17.7952 16.7438 17.5952 16.7781 17.4079 16.8507C17.2205 16.9233 17.0497 17.0328 16.9055 17.1727C16.7613 17.3126 16.6467 17.4801 16.5684 17.6651C16.4902 17.8502 16.4499 18.0491 16.45 18.25V19C16.4503 19.2872 16.533 19.5684 16.6884 19.81C16.8437 20.0516 17.0652 20.2436 17.3265 20.363L17.223 21.0895C17.2162 21.1373 17.1956 21.1821 17.1639 21.2185C17.1321 21.2548 17.0905 21.2812 17.044 21.2945L15.8125 21.6445C15.7081 21.6743 15.6163 21.7374 15.5509 21.8241C15.4855 21.9108 15.4501 22.0164 15.45 22.125V23.25H20.45V22.127C20.45 22.0184 20.4146 21.9128 20.3492 21.8261C20.2838 21.7394 20.1919 21.6763 20.0875 21.6465V21.6465Z"
        stroke="#333333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.45 23.25H22.95V21.6405C22.95 21.529 22.9128 21.4208 22.8442 21.3329C22.7756 21.245 22.6796 21.1826 22.5715 21.1555L21.113 20.791C21.0647 20.7789 21.0211 20.7528 20.9878 20.7158C20.9545 20.6788 20.933 20.6328 20.926 20.5835L20.8235 19.863C21.0848 19.7436 21.3063 19.5517 21.4617 19.31C21.617 19.0684 21.6998 18.7873 21.7 18.5V17.813C21.7061 17.4152 21.5582 17.0305 21.2872 16.7392C21.0162 16.4479 20.6432 16.2726 20.246 16.25C19.9672 16.2414 19.6916 16.3106 19.45 16.45"
        stroke="#333333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <path d="M22.7 12.9H8" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M14.3 22H9.05C8.77152 22 8.50445 21.8894 8.30754 21.6925C8.11062 21.4956 8 21.2285 8 20.95V10.45C8 10.1715 8.11062 9.90448 8.30754 9.70756C8.50445 9.51065 8.77152 9.40002 9.05 9.40002H21.65C21.9285 9.40002 22.1955 9.51065 22.3925 9.70756C22.5894 9.90448 22.7 10.1715 22.7 10.45V16.0542"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M11.5 8V10.8" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M19.2 8V10.8" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
    <defs>
      <clipPath id="clip0_6881_94984">
        <rect width="8" height="8" fill="white" transform="translate(15.2 16)" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export const AmenitiesIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M10.1 16.5V23.5H15.35" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M21.902 13.8743L15.7 8.80005L8 15.1" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M21.4375 22.2966L20.206 21.9466C20.1595 21.9334 20.1179 21.907 20.0861 21.8706C20.0544 21.8342 20.0338 21.7894 20.027 21.7416L19.9235 21.0131C20.1848 20.8937 20.4063 20.7018 20.5616 20.4602C20.717 20.2185 20.7997 19.9374 20.8 19.6501V18.9631C20.8061 18.5653 20.6581 18.1806 20.3872 17.8893C20.1162 17.5981 19.7432 17.4228 19.346 17.4001C19.1452 17.394 18.9452 17.4282 18.7578 17.5008C18.5705 17.5734 18.3997 17.683 18.2555 17.8229C18.1113 17.9628 17.9966 18.1302 17.9184 18.3153C17.8402 18.5003 17.7999 18.6992 17.8 18.9001V19.6501C17.8002 19.9374 17.883 20.2185 18.0383 20.4602C18.1937 20.7018 18.4152 20.8937 18.6765 21.0131L18.573 21.7396C18.5661 21.7874 18.5456 21.8322 18.5138 21.8686C18.4821 21.905 18.4404 21.9314 18.394 21.9446L17.1625 22.2946C17.0581 22.3245 16.9662 22.3875 16.9008 22.4742C16.8354 22.5609 16.8 22.6665 16.8 22.7751V23.9001H21.8V22.7771C21.7999 22.6685 21.7645 22.5629 21.6991 22.4762C21.6337 22.3895 21.5419 22.3265 21.4375 22.2966V22.2966Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.8 23.9001H24.3V22.2906C24.3 22.1792 24.2627 22.0709 24.1942 21.983C24.1256 21.8952 24.0296 21.8327 23.9215 21.8056L22.463 21.4411C22.4147 21.4291 22.3711 21.4029 22.3378 21.3659C22.3045 21.329 22.283 21.2829 22.276 21.2336L22.1735 20.5131C22.4348 20.3937 22.6563 20.2018 22.8116 19.9602C22.967 19.7186 23.0497 19.4374 23.05 19.1501V18.4631C23.0561 18.0654 22.9081 17.6806 22.6372 17.3893C22.3662 17.0981 21.9932 16.9228 21.596 16.9001C21.3172 16.8915 21.0416 16.9608 20.8 17.1001"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export const TourSiteIcon = (props) => (
  <SvgIcon {...props}>
    <path
      d="M14.4 24C14.4 24 8 17.5504 8 13.6C8 9.55035 11.3072 7.19995 14.4 7.19995C17.4928 7.19995 20.8 9.55035 20.8 13.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <g clipPath="url(#clip0_6881_95162)">
      <path
        d="M20.4 20.8667C21.3205 20.8667 22.0667 20.1205 22.0667 19.2C22.0667 18.2795 21.3205 17.5333 20.4 17.5333C19.4795 17.5333 18.7333 18.2795 18.7333 19.2C18.7333 20.1205 19.4795 20.8667 20.4 20.8667Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.4 22.2C18.3747 22.2 17.4 23.4633 17.4 24.2V24.8666H23.4V24.2C23.4 23.4666 22.4254 22.2 20.4 22.2Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <path
      d="M14.4 16C15.7255 16 16.8 14.9254 16.8 13.6C16.8 12.2745 15.7255 11.2 14.4 11.2C13.0745 11.2 12 12.2745 12 13.6C12 14.9254 13.0745 16 14.4 16Z"
      stroke="#333333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <clipPath id="clip0_6881_95162">
        <rect width="8" height="8" fill="white" transform="translate(16.4 17.2)" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export const IntegrationIcon = (props) => (
  <SvgIcon {...props}>
    <path
      d="M16.262 10.8C17.0352 10.8 17.662 10.1732 17.662 9.4C17.662 8.6268 17.0352 8 16.262 8C15.4888 8 14.862 8.6268 14.862 9.4C14.862 10.1732 15.4888 10.8 16.262 10.8Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.2 21.3C10.9732 21.3 11.6 20.6732 11.6 19.9C11.6 19.1268 10.9732 18.5 10.2 18.5C9.42679 18.5 8.79999 19.1268 8.79999 19.9C8.79999 20.6732 9.42679 21.3 10.2 21.3Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.324 21.3C23.0972 21.3 23.724 20.6732 23.724 19.9C23.724 19.1268 23.0972 18.5 22.324 18.5C21.5508 18.5 20.924 19.1268 20.924 19.9C20.924 20.6732 21.5508 21.3 22.324 21.3Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.2573 10.6642C11.015 11.5274 10.0869 12.771 9.61309 14.2076C9.13923 15.6442 9.14522 17.1959 9.63016 18.6288"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.505 21.027C20.5056 22.1663 19.162 22.9487 17.6779 23.2556C16.1938 23.5626 14.6501 23.3773 13.2807 22.728"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.2361 15.8085C23.1079 14.2999 22.494 12.8735 21.4864 11.7433C20.4789 10.6131 19.1321 9.83999 17.648 9.54004"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
export const ReportsIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M11.8748 24.708V12.7914" strokeMiterlimit="10" strokeLinecap="square" />
    <path d="M16.4582 24.708V17.3746" strokeMiterlimit="10" strokeLinecap="square" />
    <path d="M21.0415 24.708V12.7914" strokeMiterlimit="10" strokeLinecap="square" />
    <path d="M25.6248 24.708V17.3746" strokeMiterlimit="10" strokeLinecap="square" />
    <path d="M7.2915 24.708V8.20801" strokeMiterlimit="10" strokeLinecap="square" />
  </SvgIcon>
);

export const DogLeashIcon = ({ stroke = "#333", fill = "none" }) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_134_5829)">
      <path d="M11.299 6.21094L14.859 7.953" stroke={stroke} strokeMiterlimit="10" />
      <path
        d="M14.625 3.375L13.5 2.25L11.1696 6.44456C11.0234 6.70758 10.8096 6.92675 10.5503 7.07936C10.291 7.23196 9.99558 7.31246 9.69469 7.3125H4.5C4.05245 7.3125 3.62322 7.49029 3.30676 7.80676C2.99029 8.12322 2.8125 8.55245 2.8125 9V16.875H5.0625V14.3741C5.0625 14.1702 5.11791 13.9702 5.22281 13.7953L6.75 11.25L10.5188 12.6636C10.7334 12.7438 10.9185 12.8877 11.0492 13.0759C11.1799 13.2642 11.2499 13.4879 11.25 13.7171V16.875H13.5V12.9375L15.1875 6.75H15.75C16.1976 6.75 16.6268 6.57221 16.9432 6.25574C17.2597 5.93928 17.4375 5.51005 17.4375 5.0625V3.9375L14.625 3.375Z"
        stroke={stroke}
        strokeMiterlimit="10"
      />
      <path
        d="M9.5625 5.0625C8.36903 5.0625 7.22443 4.58839 6.38052 3.74448C5.53661 2.90057 5.0625 1.75597 5.0625 0.5625"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M0.5625 5.0625C0.5625 5.65924 0.799553 6.23153 1.22151 6.65349C1.64347 7.07545 2.21576 7.3125 2.8125 7.3125H4.5"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </g>
    <defs>
      <clipPath id="clip0_134_5829">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const DeteachedPropertyIcon = ({ stroke = "#333", fill = "none" }) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_134_5660)">
      <path
        d="M15.375 4.125L9.375 1.875L3.375 4.125"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path d="M4.875 3.5625V9.375" stroke={stroke} strokeMiterlimit="10" />
      <path d="M13.875 3.5625V9.375" stroke={stroke} strokeMiterlimit="10" />
      <path
        d="M7.875 17.625V13.125H10.875V17.625H16.875V9.375H1.875V17.625H7.875Z"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path d="M7.875 5.625V6.375" stroke={stroke} strokeMiterlimit="10" strokeLinecap="square" />
      <path d="M10.875 5.625V6.375" stroke={stroke} strokeMiterlimit="10" strokeLinecap="square" />
      <path d="M4.875 13.125V13.875" stroke={stroke} strokeMiterlimit="10" strokeLinecap="square" />
      <path
        d="M13.875 13.125V13.875"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </g>
    <defs>
      <clipPath id="clip0_134_5660">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const CircledTickIcon = ({ stroke = "#999", fill = "#333" }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 20C4.486 20 0 15.514 0 10C0 4.486 4.486 0 10 0C15.514 0 20 4.486 20 10C20 15.514 15.514 20 10 20Z"
      fill="#72C472"
    />
    <path
      d="M8.00001 15.0002C7.74401 15.0002 7.48801 14.9022 7.29301 14.7072L4.29301 11.7072C3.90201 11.3162 3.90201 10.6842 4.29301 10.2933C4.68401 9.90225 5.31601 9.90225 5.70701 10.2933L8.00001 12.5863L14.293 6.29325C14.684 5.90225 15.316 5.90225 15.707 6.29325C16.098 6.68425 16.098 7.31625 15.707 7.70725L8.70701 14.7072C8.51201 14.9022 8.25601 15.0002 8.00001 15.0002Z"
      fill="white"
    />
  </svg>
);

export const ChevronIcon = ({ stroke = "#333", fill = "none" }) => (
  <svg width="12" height="12" viewBox="0 0 8 8" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_134_5907)">
      <path
        d="M6.47852 2.5L3.74567 5.83333L1.01283 2.5"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </g>
    <defs>
      <clipPath id="clip0_134_5907">
        <rect width="8" height="6.55882" fill="white" transform="matrix(0 1 -1 0 7.16174 0)" />
      </clipPath>
    </defs>
  </svg>
);

export const UploadPhotoIcon = ({ stroke = "#333", fill = "none" }) => (
  <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14 13H2C1.73478 13 1.48043 12.8946 1.29289 12.7071C1.10536 12.5196 1 12.2652 1 12V4C1 3.73478 1.10536 3.48043 1.29289 3.29289C1.48043 3.10536 1.73478 3 2 3H5L6 1H10L11 3H14C14.2652 3 14.5196 3.10536 14.7071 3.29289C14.8946 3.48043 15 3.73478 15 4V12C15 12.2652 14.8946 12.5196 14.7071 12.7071C14.5196 12.8946 14.2652 13 14 13Z"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
);

export const StarRateIcon = ({ stroke = "#333", fill = "none" }) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_134_5897)">
      <path
        d="M9.28125 2.20312L10.9688 7.59356H16.5938L12.1241 10.9523L13.8808 16.359L9.28125 13.0172L4.68169 16.359L6.43838 10.9523L1.96875 7.59356H7.59375L9.28125 2.20312Z"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </g>
    <defs>
      <clipPath id="clip0_134_5897">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const FeederPlaygroundIcon = ({ stroke = "#333", fill = "none" }) => (
  <svg width="20" height="20" viewBox="0 0 16 13" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.5 0.5H2.5"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M0.5 4.5H2.5"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M0.5 8.5H2.5"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M0.5 12.5H2.5"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.5 0.5H15.5"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.5 4.5H11.5"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.5 8.5H15.5"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.5 12.5H10.5"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const FeederConfigrationIcon = ({ stroke = "#333", fill = "none" }) => (
  <svg width="20" height="20" viewBox="0 0 16 16" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 10.5C9.38071 10.5 10.5 9.38071 10.5 8C10.5 6.61929 9.38071 5.5 8 5.5C6.61929 5.5 5.5 6.61929 5.5 8C5.5 9.38071 6.61929 10.5 8 10.5Z"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.5 8C13.5 7.535 13.436 7.087 13.328 6.656L15.245 5.549L13.745 2.951L11.83 4.057C11.186 3.431 10.389 2.964 9.5 2.713V0.5H6.5V2.712C5.612 2.964 4.815 3.431 4.17 4.057L2.25501 2.951L0.755005 5.549L2.672 6.656C2.564 7.087 2.5 7.535 2.5 8C2.5 8.464 2.564 8.913 2.672 9.344L0.755005 10.451L2.25501 13.049L4.171 11.943C4.815 12.569 5.612 13.036 6.501 13.287V15.5H9.50101V13.288C10.39 13.036 11.186 12.569 11.831 11.944L13.747 13.05L15.247 10.452L13.33 9.345C13.436 8.913 13.5 8.464 13.5 8Z"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const FeederManagementIcon = ({ stroke = "#333", fill = "none" }) => (
  <svg width="20" height="20" viewBox="0 0 16 16" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path d="M10.5 3.5H15.5" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M0.5 3.5H2.5" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5.5 12.5H0.5" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M15.5 12.5H13.5" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M5 6C6.38071 6 7.5 4.88071 7.5 3.5C7.5 2.11929 6.38071 1 5 1C3.61929 1 2.5 2.11929 2.5 3.5C2.5 4.88071 3.61929 6 5 6Z"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11 15C12.3807 15 13.5 13.8807 13.5 12.5C13.5 11.1193 12.3807 10 11 10C9.61929 10 8.5 11.1193 8.5 12.5C8.5 13.8807 9.61929 15 11 15Z"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const UploadImageIcon = ({ stroke = "#333", fill = "none" }) => (
  <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.5">
      <path
        d="M39 34H36V31C36 30.4477 35.5527 30 35 30C34.4473 30 34 30.4477 34 31V34H31C30.4473 34 30 34.4478 30 35C30 35.5522 30.4473 36 31 36H34V39C34 39.5522 34.4473 40 35 40C35.5527 40 36 39.5522 36 39V36H39C39.5527 36 40 35.5522 40 35C40 34.4478 39.5527 34 39 34Z"
        fill="white"
      />
      <path
        d="M42 37L37 42H2C0.89543 42 0 41.1046 0 40V2C0 0.89543 0.89543 0 2 0H40C41.1046 0 42 0.89543 42 2V37Z"
        fill="#CFCFCF"
      />
      <path
        d="M18 16C20.2091 16 22 14.2091 22 12C22 9.79086 20.2091 8 18 8C15.7909 8 14 9.79086 14 12C14 14.2091 15.7909 16 18 16Z"
        fill="#727272"
      />
      <path
        d="M40.7928 32.3906L30.7928 19.3906C30.4149 18.8984 29.5858 18.8984 29.2078 19.3906L20.9652 30.1061L14.7811 22.376C14.4022 21.9014 13.5985 21.9014 13.2196 22.376L5.21955 32.376C4.69707 33.028 5.16134 34.0005 6.00031 34.0005H40.0003C40.8293 34.0005 41.2982 33.0478 40.7928 32.3906Z"
        fill="#727272"
      />
      <path
        d="M38 46C42.4183 46 46 42.4183 46 38C46 33.5817 42.4183 30 38 30C33.5817 30 30 33.5817 30 38C30 42.4183 33.5817 46 38 46Z"
        fill="#333333"
      />
      <path
        d="M41 37H39V35C39 34.4477 38.5523 34 38 34C37.4477 34 37 34.4477 37 35V37H35C34.4477 37 34 37.4477 34 38C34 38.5522 34.4477 39 35 39H37V41C37 41.5522 37.4477 42 38 42C38.5523 42 39 41.5522 39 41V39H41C41.5523 39 42 38.5522 42 38C42 37.4477 41.5523 37 41 37Z"
        fill="white"
      />
    </g>
  </svg>
);

export const ParkIcon = ({ stroke = "#333", fill = "none" }) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_134_5767)">
      <path
        d="M0.84375 15.4688H10.9688"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M2.53125 15.4688V17.7188"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M9.28125 15.4688V17.7188"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M14.3438 17.7188V8.71875"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M9.28125 10.4062H2.53125V13.2188H9.28125V10.4062Z"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M16.3216 4.97025C16.5004 4.55522 16.593 4.10817 16.5938 3.65625C16.5938 2.10319 15.5864 0.84375 14.3438 0.84375C13.1012 0.84375 12.0938 2.10319 12.0938 3.65625C12.0946 4.10817 12.1872 4.55522 12.3661 4.97025C11.9171 5.36909 11.5598 5.86033 11.3186 6.41028C11.0774 6.96022 10.9581 7.55584 10.9688 8.15625C10.9688 10.3309 12.4797 12.0938 14.3438 12.0938C16.2079 12.0938 17.7188 10.3309 17.7188 8.15625C17.7295 7.55584 17.6102 6.96022 17.369 6.41028C17.1278 5.86033 16.7705 5.36909 16.3216 4.97025Z"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </g>
    <defs>
      <clipPath id="clip0_134_5767">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const MapIcon = ({ stroke = "#333", fill = "none" }) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_134_5786)">
      <path d="M6.32812 1.26562V14.7656" stroke={stroke} strokeMiterlimit="10" />
      <path d="M11.9531 3.51562V17.0156" stroke={stroke} strokeMiterlimit="10" />
      <path
        d="M0.703125 2.95312L6.32812 1.26562L11.9531 3.51562L17.5781 1.82812V15.3281L11.9531 17.0156L6.32812 14.7656L0.703125 16.4531V2.95312Z"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </g>
    <defs>
      <clipPath id="clip0_134_5786">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const EditPencilIcon = ({ stroke = "#333", fill = "none" }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 1L15 4L5 14L1 15L2 11L12 1Z"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const MascotIconShadow = ({
  stroke = "#333",
  backgroundFill = "#F9CA54",
  frontFill = "#39728E",
  width = "56",
  height = "18",
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 56 18"
    fill="none"
  >
    <g filter="url(#filter0_f_2549_5194)">
      <path
        d="M27.9635 11.1584C40.0931 11.1584 49.9261 10.0275 49.9261 8.63245C49.9261 7.23738 40.0931 6.10645 27.9635 6.10645C15.8339 6.10645 6.00098 7.23738 6.00098 8.63245C6.00098 10.0275 15.8339 11.1584 27.9635 11.1584Z"
        fill="#DDDDDD"
      />
    </g>
    <defs>
      <filter
        id="filter0_f_2549_5194"
        x="0.000976562"
        y="0.106445"
        width="55.9248"
        height="17.0518"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="3" result="effect1_foregroundBlur_2549_5194" />
      </filter>
    </defs>
  </svg>
);

export const PopupWindowIcon = ({ stroke = "#333" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path
      d="M13.6875 4.3125L8.6875 9.3125"
      stroke="#999999"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.3125 4.3125H13.6875V8.6875"
      stroke="#999999"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.8125 9.9375V12.75C11.8125 12.9986 11.7137 13.2371 11.5379 13.4129C11.3621 13.5887 11.1236 13.6875 10.875 13.6875H5.25C5.00136 13.6875 4.7629 13.5887 4.58709 13.4129C4.41127 13.2371 4.3125 12.9986 4.3125 12.75V7.125C4.3125 6.87636 4.41127 6.6379 4.58709 6.46209C4.7629 6.28627 5.00136 6.1875 5.25 6.1875H8.0625"
      stroke="#999999"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const PositionRightIcon = ({
  stroke = "#333",
  backgroundFill = "#999999",
  width = "60",
  height = "36",
  cx = "49",
  cy = "25",
  r = "5",
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 60 36"
    fill="none"
  >
    <rect width="60" height="36" fill="#F2F2F2" />
    {r == "0" ? (
      <rect width="10" height="13" x={cx} y={cy} fill={backgroundFill || "#999999"} />
    ) : (
      <circle cx={cx} cy={cy} r={r} fill={backgroundFill || "#999999"} />
    )}
  </svg>
);

export const TextBubblesIcon = ({
  stroke = "#333",
  backgroundFill = "#F9CA54",
  frontFill = "#39728E",
  width = "60",
  height = "36",
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M7.625 12H16.375" stroke="#999999" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M10.125 17.625H8.25C5.14375 17.625 2.625 15.1062 2.625 12C2.625 8.89375 5.14375 6.375 8.25 6.375H10.125"
      stroke="#999999"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.875 17.625H15.75C18.8562 17.625 21.375 15.1062 21.375 12C21.375 8.89375 18.8562 6.375 15.75 6.375H13.875"
      stroke="#999999"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const TextBubblesMessageIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M4.5 6.5H19.5" stroke="#999999" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11.5 10.5H19.5" stroke="#999999" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4.5 14.5H19.5" stroke="#999999" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11.5 18.5H19.5" stroke="#999999" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export const CustomizeBubbleNoneIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M12 19.5C16.1421 19.5 19.5 16.1421 19.5 12C19.5 7.85786 16.1421 4.5 12 4.5C7.85786 4.5 4.5 7.85786 4.5 12C4.5 16.1421 7.85786 19.5 12 19.5Z"
        stroke="#999999"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.69727 17.3028L17.3033 6.69678"
        stroke="#999999"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const DefaultMascot = () => {
  return (
    <svg width="66" height="60" viewBox="0 0 66 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M46.9904 38.6526C46.9904 38.6526 53.0538 38.5787 55.5131 37.3219C57.612 36.2488 59.2807 34.876 58.9311 32.6382C58.9311 32.6382 56.9578 34.3905 54.8336 34.0204C49.4873 33.089 46.6413 36.4157 46.9904 38.6526Z"
        fill="url(#paint0_radial_776_2887)"
      />
      <path
        d="M48.3659 35.0973C48.3659 35.0973 55.4106 35.0118 58.267 33.5512C60.7058 32.3043 62.6445 30.7093 62.2385 28.1099C62.2385 28.1099 59.9459 30.1457 57.4779 29.7158C51.2668 28.6335 47.9604 32.4982 48.3659 35.0973Z"
        fill="url(#paint1_radial_776_2887)"
      />
      <path
        d="M48.77 32.9914C48.77 32.9914 57.4956 32.0854 60.8723 29.9498C63.7547 28.1267 65.9777 25.9289 65.1791 22.7515C65.1791 22.7515 62.5675 25.5363 59.4581 25.2833C51.6332 24.6468 47.972 29.8143 48.77 32.9914Z"
        fill="url(#paint2_radial_776_2887)"
      />
      <path
        d="M18.3534 37.9431C18.3534 37.9431 12.2903 37.8695 9.83099 36.6124C7.73183 35.5394 6.06289 34.1663 6.41246 31.9287C6.41246 31.9287 8.38599 33.681 10.5107 33.311C15.8569 32.3796 18.7029 35.7063 18.3534 37.9431Z"
        fill="url(#paint3_radial_776_2887)"
      />
      <path
        d="M16.9783 34.3884C16.9783 34.3884 9.93408 34.3025 7.07717 32.8422C4.63832 31.5953 2.69961 30.0003 3.10563 27.4009C3.10563 27.4009 5.39821 29.4367 7.86629 29.0068C14.0777 27.9245 17.3843 31.7892 16.9783 34.3884Z"
        fill="url(#paint4_radial_776_2887)"
      />
      <path
        d="M16.5729 32.2819C16.5729 32.2819 7.8476 31.3759 4.47062 29.2404C1.58821 27.4173 -0.634724 25.2194 0.163853 22.042C0.163853 22.042 2.77549 24.8268 5.88484 24.5738C13.7106 23.9373 17.3715 29.1048 16.5729 32.2819Z"
        fill="url(#paint5_radial_776_2887)"
      />
      <path
        d="M45.2115 3.52626C45.2115 0.0978514 34.5715 0 33.3589 0C32.1464 0 21.5063 0.0979946 21.5063 3.52626C21.5063 3.92984 21.6552 4.2883 21.916 4.60378C22.2862 5.05049 22.8856 5.41697 23.6203 5.71468C26.7878 7.0018 32.4912 7.0528 33.3589 7.0528C34.2309 7.0528 39.9786 7.00194 43.139 5.69906C43.8562 5.40336 44.4399 5.04275 44.8022 4.60378C45.0646 4.28844 45.2115 3.92999 45.2115 3.52626ZM43.0724 3.47927C43.0959 3.49488 43.1194 3.51064 43.139 3.52626C42.3516 4.13171 39.0209 5.09347 33.3589 5.09347C27.7914 5.09347 24.4783 4.16294 23.6203 3.55563C23.6065 3.54589 23.5929 3.536 23.5792 3.52626C23.5988 3.51064 23.6223 3.49488 23.6458 3.47927C24.5409 2.87195 27.8443 1.95905 33.3589 1.95905C38.8739 1.95905 42.1769 2.87195 43.0724 3.47927Z"
        fill="url(#paint6_linear_776_2887)"
      />
      <path
        d="M43.1607 5.69031C44.0846 5.30592 44.111 4.00361 43.2133 3.56134C43.1634 3.53684 43.136 3.52423 43.139 3.52667C42.3516 4.13212 39.0209 5.09388 33.359 5.09388C27.7914 5.09388 24.4784 4.16335 23.6203 3.55604C23.6066 3.5463 23.593 3.53641 23.5792 3.52667C23.5821 3.52423 23.5554 3.53656 23.5067 3.56048C22.606 4.0039 22.6377 5.3085 23.5655 5.6926C23.5837 5.70005 23.6019 5.7075 23.6203 5.71495C26.7879 7.00207 32.4912 7.05307 33.359 7.05307C34.2309 7.05307 39.9787 7.00221 43.139 5.69933C43.1464 5.69618 43.1534 5.69331 43.1607 5.69031Z"
        fill="url(#paint7_radial_776_2887)"
      />
      <path
        d="M29.5571 59.5163H25.73C25.2949 59.5163 24.942 59.1634 24.942 58.7283C24.942 58.2932 25.2949 57.9403 25.73 57.9403H27.6168C25.7488 48.2974 27.7536 42.6794 27.8426 42.4375C27.9929 42.0291 28.4462 41.8203 28.8544 41.9706C29.2624 42.1208 29.4714 42.5729 29.3217 42.9811C29.2929 43.0612 27.2658 48.8446 29.3525 58.5628L29.5571 59.5163Z"
        fill="#009BCC"
      />
      <path
        d="M40.9866 59.5161H37.0165L37.2214 58.5628C39.3116 48.825 37.2725 43.0379 37.2516 42.9805C37.1033 42.5723 37.3132 42.1199 37.7211 41.9706C38.1288 41.8215 38.581 42.0296 38.731 42.4375C38.8202 42.6793 40.8248 48.2973 38.9568 57.9403H40.9866C41.422 57.9403 41.7748 58.2931 41.7748 58.7282C41.7748 59.1633 41.422 59.5161 40.9866 59.5161Z"
        fill="#009BCC"
      />
      <path
        d="M51.9092 45.917C51.8714 45.9237 51.8331 45.9284 51.7941 45.9317C51.4032 45.9632 51.1049 45.7986 51.1278 45.5638C51.6819 39.897 49.0553 36.0782 49.0288 36.0403C48.8876 35.8398 49.07 35.5753 49.435 35.4493C49.8009 35.3236 50.2105 35.3836 50.3517 35.584C50.465 35.7446 53.117 39.5876 52.5438 45.4494C52.5231 45.6607 52.249 45.8569 51.9092 45.917Z"
        fill="#009BCC"
      />
      <path
        d="M50.3448 45.7183C50.242 44.7118 50.8652 43.7893 51.7363 43.6575C52.6078 43.5256 53.3973 44.2346 53.5001 45.2411C53.6026 46.2474 52.9798 47.1699 52.1083 47.3016C51.2371 47.4334 50.4475 46.7245 50.3448 45.7183Z"
        fill="#009BCC"
      />
      <path
        d="M15.5188 45.8342C15.5566 45.8409 15.595 45.8456 15.6339 45.8488C16.0248 45.8804 16.3232 45.7157 16.3003 45.4809C15.7458 39.8142 18.3721 35.9952 18.3986 35.9573C18.5398 35.7568 18.3574 35.4923 17.9924 35.3664C17.6264 35.2406 17.2169 35.3006 17.0757 35.5011C16.9624 35.6617 14.3107 39.5049 14.8842 45.3666C14.9049 45.5779 15.1791 45.7742 15.5188 45.8342Z"
        fill="#009BCC"
      />
      <path
        d="M17.0834 45.6358C17.1861 44.6293 16.5629 43.7069 15.6917 43.5751C14.8203 43.4432 14.0308 44.1524 13.9281 45.1589C13.8256 46.1651 14.4486 47.0876 15.3201 47.2192C16.1912 47.351 16.9808 46.642 17.0834 45.6358Z"
        fill="#009BCC"
      />
      <path
        d="M50.8265 28.202C50.8265 36.2551 49.4384 43.3291 42.7005 46.443C42.5085 46.532 42.3121 46.6185 42.1115 46.6996C40.64 47.3018 38.9308 47.7288 36.948 47.9509C35.9122 48.0678 34.8004 48.1289 33.6083 48.1303H33.5683C33.4981 48.1303 33.4292 48.1289 33.359 48.1274C33.2888 48.1289 33.2202 48.1303 33.1485 48.1303H33.1068C31.9164 48.1289 30.8048 48.0678 29.7688 47.9509C27.786 47.7287 26.0767 47.3018 24.605 46.6996C24.4047 46.6171 24.2082 46.5308 24.0163 46.4419C17.2786 43.3277 15.89 36.2539 15.89 28.202C15.89 18.6222 21.3501 12.0936 29.468 10.6976C30.5453 10.5108 31.6684 10.4155 32.8333 10.4155C32.9252 10.4155 33.015 10.4168 33.1067 10.4168C33.1912 10.4194 33.2743 10.4205 33.3588 10.4231C33.4419 10.4205 33.525 10.4194 33.6081 10.4168C33.7 10.4168 33.7917 10.4155 33.8832 10.4155C35.0481 10.4155 36.1713 10.5108 37.2486 10.6976C45.3666 12.0936 50.8265 18.6221 50.8265 28.202Z"
        fill="url(#paint8_radial_776_2887)"
      />
      <path
        d="M45.4783 40.934L45.4609 40.9807L43.5198 41.3236C43.511 41.7829 43.4252 44.4951 42.5754 46.5543C42.4638 46.5871 42.3507 46.6189 42.236 46.6491C43.1916 44.4346 43.2333 41.3039 43.2347 41.2631V41.2024L45.2134 40.8531C45.5155 39.9039 45.4753 36.082 45.4738 36.0428L45.7604 36.042C45.7631 36.2059 45.8033 40.0548 45.4783 40.934Z"
        fill="#ABB1B8"
      />
      <path
        d="M23.4822 41.2631C23.484 41.3039 23.5254 44.4338 24.481 46.6483C24.3665 46.618 24.2532 46.5861 24.1416 46.5534C23.292 44.4942 23.2059 41.7829 23.1974 41.3236L21.256 40.9807L21.239 40.934C20.9136 40.0548 20.9538 36.2059 20.9566 36.042L21.2431 36.0428C21.2417 36.082 21.2014 39.9039 21.5039 40.8531L23.4822 41.2024V41.2631Z"
        fill="#ABB1B8"
      />
      <path
        d="M33.5019 10.4214V48.1303H33.2153V10.4214C33.2626 10.4214 33.3113 10.4227 33.3586 10.424C33.4059 10.4227 33.4546 10.4214 33.5019 10.4214Z"
        fill="#ABB1B8"
      />
      <path
        d="M26.0017 38.1512H40.7154C45.7818 38.1512 50.0173 34.4197 50.813 29.5695C50.7335 26.8139 50.2908 24.2952 49.5342 22.0539C47.3871 17.8673 43.0222 14.9854 38.0164 14.9854H28.7007C23.6933 14.9854 19.3275 17.8693 17.1811 22.0579C16.4256 24.2973 15.9835 26.8134 15.9037 29.5662C16.6985 34.418 20.9344 38.1512 26.0017 38.1512Z"
        fill="#ABB1B8"
      />
      <path
        d="M40.7158 37.0313H26.002C20.3737 37.0313 15.7687 32.426 15.7687 26.7978C15.7687 19.6849 21.5881 13.8652 28.7012 13.8652H38.0169C45.1299 13.8652 50.9497 19.6848 50.9497 26.7978C50.9496 32.426 46.3447 37.0313 40.7158 37.0313Z"
        fill="url(#paint9_radial_776_2887)"
      />
      <path
        d="M40.0862 36.04H26.6319C21.4851 36.04 17.2742 31.829 17.2742 26.6824C17.2742 20.1785 22.5956 14.8569 29.1 14.8569H37.6185C44.1226 14.8569 49.4443 20.1785 49.4443 26.6824C49.4442 31.829 45.2332 36.04 40.0862 36.04Z"
        fill="url(#paint10_linear_776_2887)"
      />
      <path
        d="M40.1766 36.0153H26.5401C21.742 36.0153 17.816 32.0893 17.816 27.2911V26.8811C17.816 20.8175 22.7773 15.8564 28.841 15.8564H37.8757C43.9397 15.8564 48.9004 20.8175 48.9004 26.8811V27.2911C48.9005 32.0892 44.9752 36.0153 40.1766 36.0153Z"
        fill="white"
      />
      <path
        d="M41.1106 30.8448C41.6459 30.8448 42.0841 30.6344 42.0841 30.3771C42.9376 27.8929 42.9177 25.3377 42.0841 22.7163C42.0841 22.459 41.6459 22.2485 41.1106 22.2485C40.5757 22.2485 40.1377 22.459 40.1377 22.7163V30.3771C40.1377 30.6344 40.5757 30.8448 41.1106 30.8448Z"
        fill="url(#paint11_radial_776_2887)"
      />
      <path
        d="M26.1725 30.8448C25.6378 30.8448 25.1995 30.6346 25.1995 30.377C24.3464 27.8924 24.366 25.3377 25.1995 22.7166C25.1995 22.4589 25.6378 22.2485 26.1725 22.2485C26.708 22.2485 27.146 22.4589 27.146 22.7166V30.377C27.146 30.6346 26.708 30.8448 26.1725 30.8448Z"
        fill="url(#paint12_radial_776_2887)"
      />
      <defs>
        <radialGradient
          id="paint0_radial_776_2887"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(54.2785 36.3476) rotate(171.124) scale(9.23951 4.2079)"
        >
          <stop stopColor="white" />
          <stop offset="0.1486" stopColor="#F9FDFF" />
          <stop offset="0.2832" stopColor="#EDF7FD" />
          <stop offset="0.4125" stopColor="#D9F0FC" />
          <stop offset="0.5386" stopColor="#BEE5FA" />
          <stop offset="0.6623" stopColor="#99D8F7" />
          <stop offset="0.7842" stopColor="#67CAF3" />
          <stop offset="0.9023" stopColor="#00BCF0" />
          <stop offset="1" stopColor="#00B0ED" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_776_2887"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(56.8329 32.4196) rotate(171.124) scale(10.7342 4.88862)"
        >
          <stop stopColor="white" />
          <stop offset="0.1486" stopColor="#F9FDFF" />
          <stop offset="0.2832" stopColor="#EDF7FD" />
          <stop offset="0.4125" stopColor="#D9F0FC" />
          <stop offset="0.5386" stopColor="#BEE5FA" />
          <stop offset="0.6623" stopColor="#99D8F7" />
          <stop offset="0.7842" stopColor="#67CAF3" />
          <stop offset="0.9023" stopColor="#00BCF0" />
          <stop offset="1" stopColor="#00B0ED" />
        </radialGradient>
        <radialGradient
          id="paint2_radial_776_2887"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(58.9663 28.7096) rotate(165.89) scale(13.3667 6.08718)"
        >
          <stop stopColor="white" />
          <stop offset="0.1486" stopColor="#F9FDFF" />
          <stop offset="0.2832" stopColor="#EDF7FD" />
          <stop offset="0.4125" stopColor="#D9F0FC" />
          <stop offset="0.5386" stopColor="#BEE5FA" />
          <stop offset="0.6623" stopColor="#99D8F7" />
          <stop offset="0.7842" stopColor="#67CAF3" />
          <stop offset="0.9023" stopColor="#00BCF0" />
          <stop offset="1" stopColor="#00B0ED" />
        </radialGradient>
        <radialGradient
          id="paint3_radial_776_2887"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(11.0651 35.6383) rotate(8.87552) scale(9.23967 4.20796)"
        >
          <stop stopColor="white" />
          <stop offset="0.1486" stopColor="#F9FDFF" />
          <stop offset="0.2832" stopColor="#EDF7FD" />
          <stop offset="0.4125" stopColor="#D9F0FC" />
          <stop offset="0.5386" stopColor="#BEE5FA" />
          <stop offset="0.6623" stopColor="#99D8F7" />
          <stop offset="0.7842" stopColor="#67CAF3" />
          <stop offset="0.9023" stopColor="#00BCF0" />
          <stop offset="1" stopColor="#00B0ED" />
        </radialGradient>
        <radialGradient
          id="paint4_radial_776_2887"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(8.51109 31.7105) rotate(8.87552) scale(10.7342 4.88862)"
        >
          <stop stopColor="white" />
          <stop offset="0.1486" stopColor="#F9FDFF" />
          <stop offset="0.2832" stopColor="#EDF7FD" />
          <stop offset="0.4125" stopColor="#D9F0FC" />
          <stop offset="0.5386" stopColor="#BEE5FA" />
          <stop offset="0.6623" stopColor="#99D8F7" />
          <stop offset="0.7842" stopColor="#67CAF3" />
          <stop offset="0.9023" stopColor="#00BCF0" />
          <stop offset="1" stopColor="#00B0ED" />
        </radialGradient>
        <radialGradient
          id="paint5_radial_776_2887"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(6.3767 28.0001) rotate(14.1099) scale(13.3666 6.08714)"
        >
          <stop stopColor="white" />
          <stop offset="0.1486" stopColor="#F9FDFF" />
          <stop offset="0.2832" stopColor="#EDF7FD" />
          <stop offset="0.4125" stopColor="#D9F0FC" />
          <stop offset="0.5386" stopColor="#BEE5FA" />
          <stop offset="0.6623" stopColor="#99D8F7" />
          <stop offset="0.7842" stopColor="#67CAF3" />
          <stop offset="0.9023" stopColor="#00BCF0" />
          <stop offset="1" stopColor="#00B0ED" />
        </radialGradient>
        <linearGradient
          id="paint6_linear_776_2887"
          x1="21.5063"
          y1="3.52634"
          x2="45.2116"
          y2="3.52634"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.014" stopColor="#00B0ED" />
          <stop offset="0.1059" stopColor="#77CEF3" />
          <stop offset="0.2637" stopColor="#77CEF3" />
          <stop offset="0.9391" stopColor="#77CEF3" />
          <stop offset="1" stopColor="#00B0ED" />
        </linearGradient>
        <radialGradient
          id="paint7_radial_776_2887"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(33.3603 5.28963) scale(7.53566 7.53566)"
        >
          <stop stopColor="#63C9F3" />
          <stop offset="0.5787" stopColor="#00B0ED" />
          <stop offset="0.8316" stopColor="#00AFEB" />
          <stop offset="0.9226" stopColor="#00ABE5" />
          <stop offset="0.9874" stopColor="#00A5DC" />
          <stop offset="1" stopColor="#009BCC" />
        </radialGradient>
        <radialGradient
          id="paint8_radial_776_2887"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(32.8682 17.8707) scale(25.1138 29.5414)"
        >
          <stop stopColor="#63C9F3" />
          <stop offset="0.4961" stopColor="#00B0ED" />
          <stop offset="0.7732" stopColor="#00AFEB" />
          <stop offset="0.873" stopColor="#00ABE3" />
          <stop offset="0.9439" stopColor="#00A4D9" />
          <stop offset="1" stopColor="#009BCC" />
        </radialGradient>
        <radialGradient
          id="paint9_radial_776_2887"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(33.3591 25.4483) scale(14.8928)"
        >
          <stop stopColor="#63C9F3" />
          <stop offset="0.4961" stopColor="#00B0ED" />
          <stop offset="0.7732" stopColor="#00AFEB" />
          <stop offset="0.873" stopColor="#00ABE3" />
          <stop offset="0.9439" stopColor="#00A4D9" />
          <stop offset="1" stopColor="#009BCC" />
        </radialGradient>
        <linearGradient
          id="paint10_linear_776_2887"
          x1="33.6633"
          y1="14.8503"
          x2="33.3599"
          y2="26.6358"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="5.4e-07" stopColor="#DDDFE3" />
          <stop offset="0.2271" stopColor="#E8E9EB" />
          <stop offset="0.5942" stopColor="#F4F4F5" />
          <stop offset="1" stopColor="#F9F9F9" />
        </linearGradient>
        <radialGradient
          id="paint11_radial_776_2887"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(41.4273 26.5467) scale(3.17302)"
        >
          <stop stopColor="#00B0ED" />
          <stop offset="0.0849" stopColor="#00ABE5" />
          <stop offset="0.2237" stopColor="#00A3D4" />
          <stop offset="0.3991" stopColor="#2995B9" />
          <stop offset="0.6042" stopColor="#4B7C92" />
          <stop offset="0.8315" stopColor="#47565F" />
          <stop offset="1" />
        </radialGradient>
        <radialGradient
          id="paint12_radial_776_2887"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(25.8564 26.5467) scale(3.17301 3.17302)"
        >
          <stop stopColor="#00B0ED" />
          <stop offset="0.0849" stopColor="#00ABE5" />
          <stop offset="0.2237" stopColor="#00A3D4" />
          <stop offset="0.3991" stopColor="#2995B9" />
          <stop offset="0.6042" stopColor="#4B7C92" />
          <stop offset="0.8315" stopColor="#47565F" />
          <stop offset="1" />
        </radialGradient>
      </defs>
    </svg>
  );
};

export const LinkIcon = ({
  width = "32px",
  height = "32px",
  stroke = "#26BBED",
  viewBox = "0 0 32 32",
  className = "stroke-[#333]",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M17.1371 14.8629C18.7072 16.4331 18.7072 18.978 17.1371 20.5482L14.8629 22.8223C13.2928 24.3925 10.7478 24.3925 9.17763 22.8223C7.60746 21.2522 7.60746 18.7072 9.17763 17.137L11.1752 15.1966"
        // stroke={stroke}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.8627 17.1371C13.2925 15.5669 13.2925 13.0219 14.8627 11.4518L17.1368 9.17763C18.707 7.60746 21.2519 7.60746 22.8221 9.17763C24.3923 10.7478 24.3923 13.2928 22.8221 14.8629L20.8245 16.8034"
        // stroke={stroke}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ShareEmbedIcon = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.885 14.509L18.1161 11.8652"
        stroke="#333333"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.885 17.4902L18.1161 20.134"
        stroke="#333333"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.5 13.1875C22.0533 13.1875 23.3125 11.9283 23.3125 10.375C23.3125 8.8217 22.0533 7.5625 20.5 7.5625C18.9467 7.5625 17.6875 8.8217 17.6875 10.375C17.6875 11.9283 18.9467 13.1875 20.5 13.1875Z"
        fill="white"
        stroke="#333333"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.5 24.4375C22.0533 24.4375 23.3125 23.1783 23.3125 21.625C23.3125 20.0717 22.0533 18.8125 20.5 18.8125C18.9467 18.8125 17.6875 20.0717 17.6875 21.625C17.6875 23.1783 18.9467 24.4375 20.5 24.4375Z"
        fill="white"
        stroke="#333333"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5 18.8125C13.0533 18.8125 14.3125 17.5533 14.3125 16C14.3125 14.4467 13.0533 13.1875 11.5 13.1875C9.9467 13.1875 8.6875 14.4467 8.6875 16C8.6875 17.5533 9.9467 18.8125 11.5 18.8125Z"
        fill="white"
        stroke="#333333"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const DualLinkIcon = ({ width = "20px", height = "20px", stroke = "#999999" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_712_29284)">
        <path
          d="M9 6.74994L10.4813 5.23119C11.5065 4.20594 11.5065 2.54394 10.4813 1.51869C9.456 0.493443 7.794 0.493443 6.76875 1.51869L5.25 2.99994"
          stroke={stroke}
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6.74994 9L5.23119 10.4813C4.20594 11.5065 2.54394 11.5065 1.51869 10.4813C0.493443 9.456 0.493443 7.794 1.51869 6.76875L2.99994 5.25"
          stroke={stroke}
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M3.75 8.25L8.25 3.75"
          stroke={stroke}
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_712_29284">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const PrivacyIcon = ({ width = "24px", height = "24px", stroke = "#999999" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.0556 11.4445H8.38892V15.6111H15.0556V11.4445Z"
        stroke={stroke}
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
      <path
        d="M10.0554 11.4444V8.94438C10.0567 8.50275 10.2328 8.0796 10.545 7.76732C10.8573 7.45505 11.2805 7.27903 11.7221 7.27771C12.1637 7.27903 12.5869 7.45505 12.8991 7.76732C13.2114 8.0796 13.3874 8.50275 13.3888 8.94438V11.4444"
        stroke={stroke}
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
      <path
        d="M19.2222 13.1111C19.2222 15.1002 18.432 17.0079 17.0255 18.4144C15.6189 19.8209 13.7113 20.6111 11.7222 20.6111C9.73304 20.6111 7.82539 19.8209 6.41887 18.4144C5.01234 17.0079 4.22217 15.1002 4.22217 13.1111V4.77775L11.7222 3.11108L19.2222 4.77775V13.1111Z"
        stroke={stroke}
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
    </svg>
  );
};

export const InfoIcon = ({ width = "18px", height = "18px", stroke = "#999999" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M9 14.625C12.1066 14.625 14.625 12.1066 14.625 9C14.625 5.8934 12.1066 3.375 9 3.375C5.8934 3.375 3.375 5.8934 3.375 9C3.375 12.1066 5.8934 14.625 9 14.625Z"
        stroke={stroke}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9 6.75C9.41421 6.75 9.75 6.41421 9.75 6C9.75 5.58579 9.41421 5.25 9 5.25C8.58579 5.25 8.25 5.58579 8.25 6C8.25 6.41421 8.58579 6.75 9 6.75Z"
        fill={stroke}
      />
      <path d="M9 12.375V8.25" stroke={stroke} stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};

export const TextBubbleOffersIcon = () => {
  return (
    <svg width={23} height={24} viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.7936 19.5311L19.5311 13.7936C19.6798 13.645 19.7977 13.4686 19.8781 13.2744C19.9586 13.0802 20 12.8721 20 12.662C20 12.4518 19.9586 12.2437 19.8781 12.0495C19.7977 11.8554 19.6798 11.679 19.5311 11.5304L12 4H4V12L11.5304 19.5319C11.679 19.6805 11.8555 19.7984 12.0497 19.8788C12.2438 19.9591 12.452 20.0005 12.6621 20.0004C12.8723 20.0003 13.0804 19.9588 13.2745 19.8783C13.4687 19.7978 13.645 19.6798 13.7936 19.5311Z"
        stroke="#999999"
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M8.79921 10.4002C9.68287 10.4002 10.3992 9.68384 10.3992 8.80019C10.3992 7.91654 9.68287 7.2002 8.79921 7.2002C7.91556 7.2002 7.19922 7.91654 7.19922 8.80019C7.19922 9.68384 7.91556 10.4002 8.79921 10.4002Z"
        stroke="#999999"
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
};

export const MenuIcon = (props) => {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23 8.375H9C8.44772 8.375 8 8.82272 8 9.375V23.375C8 23.9273 8.44772 24.375 9 24.375H23C23.5523 24.375 24 23.9273 24 23.375V9.375C24 8.82272 23.5523 8.375 23 8.375Z"
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path d="M11.1997 16.375H20.7997" strokeMiterlimit={10} strokeLinecap="round" />
      <path d="M11.1997 12.375H20.7997" strokeMiterlimit={10} strokeLinecap="round" />
      <path d="M11.1997 20.375H20.7997" strokeMiterlimit={10} strokeLinecap="round" />
    </svg>
  );
};

export const TableIcon = (props) => {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M8 16H24" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M16 8V24" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M22.8571 24H9.14286C8.512 24 8 23.488 8 22.8571V9.14286C8 8.512 8.512 8 9.14286 8H22.8571C23.488 8 24 8.512 24 9.14286V22.8571C24 23.488 23.488 24 22.8571 24Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const PreviewTemplateIcon = (props) => {
  return (
    <svg
      width={14}
      height={12}
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.15055 6.58667C0.949818 6.22444 0.949818 5.77667 1.15055 5.41333C1.88145 4.09222 3.92255 1 7 1C10.0775 1 12.1185 4.09222 12.8495 5.41333C13.0502 5.77556 13.0502 6.22333 12.8495 6.58667C12.1185 7.90778 10.0775 11 7 11C3.92255 11 1.88145 7.90778 1.15055 6.58667Z"
        stroke="#333333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.99969 8.22325C8.20467 8.22325 9.18151 7.22833 9.18151 6.00103C9.18151 4.77373 8.20467 3.77881 6.99969 3.77881C5.7947 3.77881 4.81787 4.77373 4.81787 6.00103C4.81787 7.22833 5.7947 8.22325 6.99969 8.22325Z"
        stroke="#333333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const EditPenIcon = (props) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="20" height="20" rx="10" fill="#F9F9F9" />
      <path d="M11.1313 6.43701L13.562 8.86762" stroke="#333333" stroke-miterlimit="10" />
      <path
        d="M8.05589 14.375L5.13916 14.8611L5.62528 11.9444L12.1461 5.42358C12.3284 5.24131 12.5757 5.13892 12.8335 5.13892C13.0913 5.13892 13.3385 5.24131 13.5209 5.42358L14.5767 6.47943C14.759 6.66175 14.8614 6.909 14.8614 7.16681C14.8614 7.42461 14.759 7.67186 14.5767 7.85418L8.05589 14.375Z"
        stroke="#333333"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
    </svg>
  );
};
