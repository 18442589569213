import graphql from "babel-plugin-relay/macro";

export default graphql`
  query listPropertyCorpusFaqsQuery($propertyId: String!) {
    listPropertyCorpusFaqs(propertyId: $propertyId) {
      createdUser
      faqType
      id
      orgName
      propertyName
      questionId
      subject
      tenantId
      value
    }
  }
`;
