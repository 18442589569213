import React from "react";
import withStyles from "@mui/styles/withStyles";
import Popover from "./Popover";
import PrevArrowIcon from "./PrevArrowIcon";
import NextArrowIcon from "./NextArrowIcon";
import IconCompoenent from "../../../IconComponent";

const styles = () => ({
  dotContainer: {
    height: 47,
    display: "flex",
    alignItems: "center",
  },
  dot: {
    height: 12,
    width: 12,
    borderRadius: 6,
    background: "#F2BD00",
    cursor: "pointer",
  },
  SubjectDot: {
    height: 12,
    width: 12,
    borderRadius: 6,
    background: "none",
    cursor: "pointer",
  },
  popoverBody: {
    width: 140,
    padding: 10,
    background: "#FFFFFF !important",
    border: "1px solid #EEEEEE !important",
    boxShadow: "2px 4px 10px rgba(204, 204, 204, 0.2)",
  },
  clickEdit: {
    color: "rgba(51, 51, 51, 0.5)",
    fontSize: 12,
  },
  label: {
    color: "#333333",
    fontSize: 16,
    marginTop: 2,
    marginBottom: 12,
  },
  flexBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: 12,
    color: "#333333",
  },
  rightMargin: {
    marginRight: 6,
  },
  iconStyle: {
    cursor: "pointer !important",
    "&:hover": {
      color: "#26BBED !important",
    },
  },
  flexBoxIcon: {
    display: "flex",
    alignItems: "center",
  },
  gotIt: {
    cursor: "pointer !important",
    "&:hover": {
      color: "#26BBED !important",
    },
  },
});

const DotComponent = ({ classes, label, dotId, setDotId, type }) => {
  const Dot = (
    <div className={classes.dotContainer}>
      <div
        className={
          // label === "Subject" && type === "typingEffect" ? classes.SubjectDot : classes.dot
          classes.dot
        }
      ></div>
    </div>
  );

  const getIndexOfCurrent = (elements) => {
    for (var i = 0; i < elements.length; i++) {
      if (elements[i].id === "id") {
        elements[i].id = "interacted";
        return i;
      }
    }
    return -1;
  };

  const getNotinteractedPrevious = (elements, index) => {
    for (var i = index - 1; i >= 0; i--) {
      if (elements[i].id !== "interacted") {
        return i;
      }
    }
    return -1;
  };

  const getNotinteractedNext = (elements, index) => {
    for (var i = index + 1; i < elements.length; i++) {
      if (elements[i].id !== "interacted") {
        return i;
      }
    }
    return -1;
  };

  const handleClick = () => {
    let elements = document.getElementsByName("icon-container");
    let index = getIndexOfCurrent(elements);
    let idx;
    if (index > 0) {
      idx = getNotinteractedPrevious(elements, index);
    } else {
      idx = getNotinteractedPrevious(elements, elements.length);
    }
    if (idx >= 0) {
      elements[idx].click();
      elements[idx].scrollIntoView(false);
    }
    elements[index].click();
  };

  const handleNextClick = () => {
    let elements = document.getElementsByName("icon-container");
    let index = getIndexOfCurrent(elements);
    let idx;
    if (index < elements.length - 1) {
      idx = getNotinteractedNext(elements, index);
    } else {
      idx = getNotinteractedNext(elements, -1);
    }
    if (idx >= 0) {
      elements[idx].click();
      elements[idx].scrollIntoView(false);
    }
    elements[index].click();
  };

  return (
    <Popover icon={Dot} dotId={dotId} setDotId={setDotId}>
      {(onClick) => (
        <div className={classes.popoverBody}>
          <div className={classes.clickEdit}>Click to edit</div>
          <div className={classes.label}>{label}</div>
          <div className={classes.flexBox}>
            <div className={classes.gotIt} onClick={onClick}>
              Got it!
            </div>

            <div className={classes.flexBoxIcon}>
              <IconCompoenent
                onClick={handleClick}
                className={classes.rightMargin}
                Component={PrevArrowIcon}
                onHoverColor="#26BBED"
              />
              <IconCompoenent
                onClick={handleNextClick}
                Component={NextArrowIcon}
                onHoverColor="#26BBED"
              />
            </div>
          </div>
        </div>
      )}
    </Popover>
  );
};

export default withStyles(styles)(DotComponent);
