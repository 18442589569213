/**
 * @flow
 * @relayHash 33bfee0247625c437c6ba66c04def59c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getOfferContactListQueryVariables = {|
  orgId: string,
  propertyIds?: ?$ReadOnlyArray<string>,
  campaignIds?: ?$ReadOnlyArray<string>,
  startAt?: ?string,
  endAt?: ?string,
  concession?: ?$ReadOnlyArray<string>,
  status?: ?string,
|};
export type getOfferContactListQueryResponse = {|
  +offerContactList: $ReadOnlyArray<{|
    +campaignTitle: ?string,
    +clickedAt: ?string,
    +email: ?string,
    +id: string,
    +leasedAt: ?string,
    +offerTitle: ?string,
    +propertyName: ?string,
    +touredAt: ?string,
  |}>
|};
export type getOfferContactListQuery = {|
  variables: getOfferContactListQueryVariables,
  response: getOfferContactListQueryResponse,
|};
*/


/*
query getOfferContactListQuery(
  $orgId: ID!
  $propertyIds: [String!]
  $campaignIds: [String!]
  $startAt: String
  $endAt: String
  $concession: [String!]
  $status: String
) {
  offerContactList(orgId: $orgId, propertyIds: $propertyIds, campaignIds: $campaignIds, startAt: $startAt, endAt: $endAt, concession: $concession, status: $status) {
    campaignTitle
    clickedAt
    email
    id
    leasedAt
    offerTitle
    propertyName
    touredAt
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "orgId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "propertyIds",
    "type": "[String!]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "campaignIds",
    "type": "[String!]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "startAt",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "endAt",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "concession",
    "type": "[String!]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "status",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "offerContactList",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "campaignIds",
        "variableName": "campaignIds"
      },
      {
        "kind": "Variable",
        "name": "concession",
        "variableName": "concession"
      },
      {
        "kind": "Variable",
        "name": "endAt",
        "variableName": "endAt"
      },
      {
        "kind": "Variable",
        "name": "orgId",
        "variableName": "orgId"
      },
      {
        "kind": "Variable",
        "name": "propertyIds",
        "variableName": "propertyIds"
      },
      {
        "kind": "Variable",
        "name": "startAt",
        "variableName": "startAt"
      },
      {
        "kind": "Variable",
        "name": "status",
        "variableName": "status"
      }
    ],
    "concreteType": "OfferContactList",
    "plural": true,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "campaignTitle",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "clickedAt",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "email",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "leasedAt",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "offerTitle",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "propertyName",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "touredAt",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getOfferContactListQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "getOfferContactListQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "getOfferContactListQuery",
    "id": null,
    "text": "query getOfferContactListQuery(\n  $orgId: ID!\n  $propertyIds: [String!]\n  $campaignIds: [String!]\n  $startAt: String\n  $endAt: String\n  $concession: [String!]\n  $status: String\n) {\n  offerContactList(orgId: $orgId, propertyIds: $propertyIds, campaignIds: $campaignIds, startAt: $startAt, endAt: $endAt, concession: $concession, status: $status) {\n    campaignTitle\n    clickedAt\n    email\n    id\n    leasedAt\n    offerTitle\n    propertyName\n    touredAt\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b49780252a08424543ed9a9147889148';
module.exports = node;
