import React from "react";
import Popover from "@mui/material/Popover";
import withStyles from "@mui/styles/withStyles";
import moment from "moment";
import "./Calendar/css/styles.css";
import "./Calendar/css/default.css";
import DateRange from "./Calendar/components/DateRange";
import Icon from "storybookComponents/Icon";
import "./dateRangePicker.css";
import Button from "storybookComponents/Button";
import { ArrowDown, ArrowUp } from "components/Icons";

const styles = () => ({
  list_item_range_picker: {
    fontSize: "14px !important",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    lineHeight: "20px !important",
    padding: "8px 12px",
    cursor: "pointer",
    "&:hover": {
      color: "var(--hylyTheme)",
    },
  },
  selected_range_picker: {
    fontWeight: 600,
  },
  list_Container: {
    background: "white",
    color: "#333333",
  },
  paper: {
    border: "1px solid #eeeeee",
    boxShadow: "none",
    marginTop: 10,
  },
  labelContainer: {
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    "&:hover": {
      color: "var(--hylyTheme) !important",
    },
    "&:hover div": {
      color: "var(--hylyTheme) !important",
    },
  },
  label: {
    fontSize: 14,
    marginRight: 4,
    fontWeight: 600,
    opacity: "0.75",
    color: "#333333",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    lineHeight: "20px",
  },
  labelOpen: {
    opacity: "1",
  },
  bottomContainer: {
    background: "white",
    padding: "0px 14px",
    textAlignLast: "right",
    paddingBottom: 15,
  },
  btnStyle: {
    marginLeft: 12,
    padding: "6px 16px",
    height: 32,
    textTransform: "none",
  },
});

const ranges = {
  Today: [moment().startOf("day"), moment().endOf("day")],
  Yesterday: [
    moment().subtract(1, "days").startOf("day"),
    moment().subtract(1, "days").endOf("day"),
  ],
  "This Week": [moment().subtract(6, "days").startOf("day"), moment().endOf("day")],
  "Last Week": [
    moment().subtract(1, "weeks").startOf("isoWeek"),
    moment().subtract(1, "weeks").endOf("isoWeek"),
  ],
  "This Month": [moment().startOf("month"), moment().endOf("month")],
  "Last Month": [
    moment().subtract(1, "month").startOf("month").toDate(),
    moment().subtract(1, "month").endOf("month").toDate(),
  ],
};

class MyDateRangePicker extends React.Component {
  constructor(props) {
    super(props);
    this.componentRef = React.createRef();
    this.state = {
      anchorEl: null,
      showPicker: false,
      oldLabel: "",
      label: this.props.value ? this.props.value.label : this.props.placeholder || "Select Date",
      calendarRange: [
        {
          startDate:
            this.props.value && this.props.value.label == "Custom Range"
              ? new Date(this.props.value.startAt)
              : "",
          endDate:
            this.props.value && this.props.value.label == "Custom Range"
              ? new Date(this.props.value.endAt)
              : "",
          key: "selection",
        },
      ],
      ranges: this.props.customProvidedRange
        ? this.props.customProvidedRange
        : this.props.type === "haveToday"
        ? this.props.customRange
          ? this.props.customRangeObject
          : ranges
        : {
            "This Month": [moment().startOf("month").toDate(), moment().endOf("month").toDate()],
            "Last 30 Days": [moment().subtract(30, "day").toDate(), moment().toDate()],
            "Last Month": [
              moment().subtract(1, "month").startOf("month").toDate(),
              moment().subtract(1, "month").endOf("month").toDate(),
            ],
            "Last 3 Months": [
              moment().subtract(3, "month").startOf("month").toDate(),
              moment().subtract(1, "month").endOf("month").toDate(),
            ],
            "Last 6 Months": [
              moment().subtract(6, "month").startOf("month").toDate(),
              moment().subtract(1, "month").endOf("month").toDate(),
            ],
            "This Year": [moment().startOf("year").toDate(), moment().endOf("year").toDate()],
            "Last Year": [
              moment().subtract(1, "year").startOf("year").toDate(),
              moment().subtract(1, "year").endOf("year").toDate(),
            ],
          },
    };
  }

  handleClickOutside = (event) => {
    if (
      this.componentRef.current &&
      this.state.showPicker &&
      !this.componentRef.current.contains(event.target)
    ) {
      this.setState({ showPicker: false, label: this.state.oldLabel });
    }
  };

  componentDidMount() {
    document.addEventListener("click", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.type !== prevProps.type) {
      this.setState({
        ranges: this.props.customProvidedRange
          ? this.props.customProvidedRange
          : this.props.type === "haveToday"
          ? this.props.customRange
            ? this.props.customRangeObject
            : ranges
          : {
              "This Month": [moment().startOf("month").toDate(), moment().endOf("month").toDate()],
              "Last 30 Days": [moment().subtract(30, "day").toDate(), moment().toDate()],
              "Last Month": [
                moment().subtract(1, "month").startOf("month").toDate(),
                moment().subtract(1, "month").endOf("month").toDate(),
              ],
              "Last 3 Months": [
                moment().subtract(3, "month").startOf("month").toDate(),
                moment().subtract(1, "month").endOf("month").toDate(),
              ],
              "Last 6 Months": [
                moment().subtract(6, "month").startOf("month").toDate(),
                moment().subtract(1, "month").endOf("month").toDate(),
              ],
              "This Year": [moment().startOf("year").toDate(), moment().endOf("year").toDate()],
              "Last Year": [
                moment().subtract(1, "year").startOf("year").toDate(),
                moment().subtract(1, "year").endOf("year").toDate(),
              ],
            },
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.value.label != nextProps.value.label) {
      this.setState({
        label: nextProps.value.label,
      });
      if (nextProps.value.label === "Custom Range") {
        this.setState({
          calendarRange: [
            {
              startDate:
                nextProps.value && nextProps.value.label === "Custom Range"
                  ? new Date(nextProps.value.startAt)
                  : "",
              endDate:
                nextProps.value && nextProps.value.label === "Custom Range"
                  ? new Date(nextProps.value.endAt)
                  : "",
              key: "selection",
            },
          ],
        });
      }
    }
  }

  handleEvent = (picker) => {
    this.setState(
      {
        label: picker.label,
      },
      () => {
        if (typeof this.props.handleApply === "function") {
          this.props.handleApply({
            startAt: new Date(picker.startDate).toISOString(),
            endAt: new Date(picker.endDate).toISOString(),
            label: picker.label,
          });
        }
      }
    );
  };

  handleClick = (event) => {
    this.setState({ showPicker: false });
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ showPicker: false, anchorEl: null });
  };

  handleItemClick = (picker) => {
    this.handleClose();
    this.handleEvent(picker);
    this.setState({ showPicker: false });
  };

  renderDropDown = (values, index) => {
    const { classes } = this.props;

    const picker = {
      startDate: values[1][0],
      endDate: values[1][1],
      label: values[0],
    };
    return (
      <div
        className={`${classes.list_item_range_picker} ${
          this.state.label === values[0] && classes.selected_range_picker
        }`}
        onClick={this.handleItemClick.bind(this, picker)}
        keys={index}
      >
        {values[0]}
      </div>
    );
  };
  handleClearAll = () => {
    this.setState({
      calendarRange: [
        {
          startDate: "",
          endDate: "",
          key: "selection",
        },
      ],
    });
  };

  handleApply = () => {
    this.handleClose();
    const { calendarRange } = this.state;
    if (typeof this.props.handleApply === "function") {
      this.props.handleApply({
        startAt: new Date(calendarRange[0].startDate).toISOString(),
        endAt: new Date(calendarRange[0].endDate.setHours(23, 59, 59)).toISOString(),
        label: "Custom Range",
      });
      this.setState({
        label: "Custom Range",
      });
    }
  };

  openCalendar = () => {
    const showPicker = this.state.showPicker;
    this.setState({ showPicker: !showPicker, oldLabel: this.state.label });
  };

  handleChange = (item) => {
    this.setState({ calendarRange: [item.selection] });
  };
  render() {
    const {
      position,
      className,
      classes,
      value,
      blockSelector,
      disabledDay = () => {},
      customLabel,
    } = this.props;
    const { anchorEl, ranges, showPicker, calendarRange, label } = this.state;
    const open = Boolean(anchorEl);
    const id = open ? "date-range-picker" : undefined;
    let _label = label;
    let rangeLable = "Custom Range";
    if (
      label === "Custom Range" &&
      (calendarRange[0].startDate !== "" || calendarRange[0].endDate !== "")
    ) {
      let start = moment(calendarRange[0].startDate).format("MMM D, YYYY");
      let end = moment(calendarRange[0].endDate).format("MMM D, YYYY");
      _label = start + " - " + end;
      if (start === end) {
        _label = start;
      }
    } else if (calendarRange[0].startDate !== "" || calendarRange[0].endDate !== "") {
      let start = moment(calendarRange[0].startDate).format("MMM D, YYYY");
      let end = moment(calendarRange[0].endDate).format("MMM D, YYYY");
      rangeLable = start + " - " + end;
      if (start === end) {
        rangeLable = start;
      }
    }

    return (
      <div id="date-range-picker-container" className="flex items-center">
        {blockSelector ? (
          <div className="space-x-2 flex" ref={this.componentRef}>
            {Object.entries(ranges).map((v, i) => {
              const picker = {
                startDate: v[1][0],
                endDate: v[1][1],
                label: v[0],
              };
              return (
                <div
                  className={`border border-solid ${
                    this.state.label === v[0]
                      ? "!border-border-3 font-semibold"
                      : "!border-border-2"
                  } rounded-full text-xs !px-3 !py-[6px] hover:text-hyly-blue cursor-pointer`}
                  key={i}
                  onClick={this.handleItemClick.bind(this, picker)}
                >
                  {v[0]}
                </div>
              );
            })}
            {!this.props.customRange && (
              <div className="relative">
                <div
                  className={`border border-solid ${
                    this.state.label === "Custom Range"
                      ? `border-border-3 font-semibold`
                      : "border-border-2"
                  } rounded-full text-xs !px-3 !py-[6px] hover:text-hyly-blue cursor-pointer`}
                  onClick={this.openCalendar}
                >
                  {this.state.label === "Custom Range" ? _label : "Custom Range"}
                </div>

                {showPicker && (
                  <div className={`absolute bg-white z-50`}>
                    <DateRange
                      editableDateInputs={false}
                      ranges={calendarRange}
                      onChange={this.handleChange}
                      moveRangeOnFirstSelection={false}
                      disabledDay={disabledDay}
                      {...this.props}
                    />
                    <div className={classes.bottomContainer}>
                      {calendarRange[0].startDate === "" &&
                      calendarRange[0].endDate === "" ? null : (
                        <Button
                          variant="tertiary"
                          className={classes.btnStyle}
                          onClick={this.handleClearAll}
                        >
                          Clear
                        </Button>
                      )}

                      <Button
                        onClick={this.handleApply}
                        state={
                          calendarRange[0].startDate === "" || calendarRange[0].endDate === ""
                            ? "inactive"
                            : "active"
                        }
                        className={classes.btnStyle}
                      >
                        Apply
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        ) : (
          <>
            <div
              className={classes.labelContainer}
              aria-describedby={id}
              onClick={this.handleClick}
            >
              <div className={`${classes.label} ${open && classes.labelOpen}`}>
                {customLabel ? customLabel(_label) : showPicker ? rangeLable : _label}
              </div>
              <span className="flex items-center">{open ? <ArrowUp /> : <ArrowDown />}</span>
            </div>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={this.handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: position,
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: position,
              }}
              classes={{
                paper: className ? className.paper : classes.paper,
              }}
            >
              {showPicker ? (
                <>
                  <DateRange
                    editableDateInputs={false}
                    ranges={calendarRange}
                    onChange={this.handleChange}
                    moveRangeOnFirstSelection={false}
                    {...this.props}
                  />
                  <div className={classes.bottomContainer}>
                    {calendarRange[0].startDate === "" && calendarRange[0].endDate === "" ? null : (
                      <Button
                        variant="tertiary"
                        className={classes.btnStyle}
                        onClick={this.handleClearAll}
                      >
                        Clear
                      </Button>
                    )}

                    <Button
                      onClick={this.handleApply}
                      state={
                        calendarRange[0].startDate === "" || calendarRange[0].endDate === ""
                          ? "inactive"
                          : "active"
                      }
                      className={classes.btnStyle}
                    >
                      Apply
                    </Button>
                  </div>
                </>
              ) : (
                <div className={classes.list_Container}>
                  {Object.entries(ranges).map(this.renderDropDown)}
                  {!this.props.customRange && (
                    <div
                      className={`${classes.list_item_range_picker} ${
                        this.state.label === "Custom Range" && classes.selected_range_picker
                      }`}
                      onClick={this.openCalendar}
                    >
                      Custom Range
                    </div>
                  )}
                </div>
              )}
            </Popover>
          </>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(MyDateRangePicker);
