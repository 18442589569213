import clsx from "clsx";

import StyledTableCell from "../StyledTableCell";

const ToggleRowHead = ({ className = "" }) => {
  const defaultClasses =
    "table-header border-y border-solid border-[#DADADA] font-open-sans font-semibold text-sm text-text sticky -top-px bg-white hover:text-hyly-blue z-[1] select-none w-[56px]";

  return <StyledTableCell align="left" className={clsx(defaultClasses, className)} />;
};

export default ToggleRowHead;
