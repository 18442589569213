/**
 * @flow
 * @relayHash af6451540db8a6e4a0f43658823aa080
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type recentPageInput = {|
  clientMutationId?: ?string,
  orgId: string,
  appName: string,
  propertyId?: ?string,
  dashboardId?: ?string,
  objectId?: ?string,
  pagePath?: ?string,
  pageName?: ?string,
  objectType?: ?string,
  favourite?: ?boolean,
  addLogs?: ?boolean,
  objectName?: ?string,
  objectData?: ?string,
|};
export type addToRecentPageMutationVariables = {|
  input: recentPageInput
|};
export type addToRecentPageMutationResponse = {|
  +addToRecentPage: ?{|
    +recentPage: ?{|
      +id: string,
      +tenantId: ?string,
      +appProductId: ?string,
      +appModuleProductId: ?string,
      +pagePath: ?string,
      +pageName: ?string,
      +propertyName: ?string,
      +objectData: ?string,
    |}
  |}
|};
export type addToRecentPageMutation = {|
  variables: addToRecentPageMutationVariables,
  response: addToRecentPageMutationResponse,
|};
*/


/*
mutation addToRecentPageMutation(
  $input: recentPageInput!
) {
  addToRecentPage(input: $input) {
    recentPage {
      id
      tenantId
      appProductId
      appModuleProductId
      pagePath
      pageName
      propertyName
      objectData
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "recentPageInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "addToRecentPage",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "recentPagePayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "recentPage",
        "storageKey": null,
        "args": null,
        "concreteType": "RecentPage",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "tenantId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "appProductId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "appModuleProductId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "pagePath",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "pageName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "propertyName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "objectData",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "addToRecentPageMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "addToRecentPageMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "addToRecentPageMutation",
    "id": null,
    "text": "mutation addToRecentPageMutation(\n  $input: recentPageInput!\n) {\n  addToRecentPage(input: $input) {\n    recentPage {\n      id\n      tenantId\n      appProductId\n      appModuleProductId\n      pagePath\n      pageName\n      propertyName\n      objectData\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd8a25ad3f4bcbe7b9a3bbc8d4af57500';
module.exports = node;
