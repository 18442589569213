/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type ListItem_item$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type SimpleList_suggestions$ref: FragmentReference;
declare export opaque type SimpleList_suggestions$fragmentType: SimpleList_suggestions$ref;
export type SimpleList_suggestions = $ReadOnlyArray<{|
  +region: ?string,
  +$fragmentRefs: ListItem_item$ref,
  +$refType: SimpleList_suggestions$ref,
|}>;
export type SimpleList_suggestions$data = SimpleList_suggestions;
export type SimpleList_suggestions$key = {
  +$data?: SimpleList_suggestions$data,
  +$fragmentRefs: SimpleList_suggestions$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "SimpleList_suggestions",
  "type": "PicklistInterface",
  "metadata": {
    "plural": true
  },
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "region",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "ListItem_item",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '153965a3d3d8460bd774ccf4300c9cea';
module.exports = node;
