import graphql from "babel-plugin-relay/macro";

export default graphql`
  query getOfferContactListQuery(
    $orgId: ID!
    $propertyIds: [String!],
    $campaignIds: [String!]
    $startAt: String
    $endAt: String
    $concession: [String!]
    $status: String){
    offerContactList(
      orgId: $orgId
      propertyIds: $propertyIds
      campaignIds: $campaignIds
      startAt: $startAt
      endAt: $endAt
      concession: $concession
      status: $status
    ){
      campaignTitle
      clickedAt
      email
      id
      leasedAt
      offerTitle
      propertyName
      touredAt
    }
  }`