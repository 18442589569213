import React from "react";
import withStyles from "@mui/styles/withStyles";
import { CSVLink } from "react-csv";
import { FilterIcon, DownloadCSVIcon, PercentageIcon, ConstantIcon } from "icons";
import { CircularProgress } from "@mui/material";
const styles = () => ({
  tableHeading: {
    border: "1px solid #DADADA",
    borderBottom: "none",
    background: "#FFF",
    padding: "12px 16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: 60,
  },
  heading: {
    color: "#000",
    fontSize: 20,
    lineHeight: "32px",
  },
  headingBarActionBox: {
    display: "flex",
    alignItems: "center",
  },
  exportButton: {
    color: "#4F6FAE",
    fontSize: 14,
    lineHeight: "20px",
    cursor: "pointer",
    fontWeight: 600,
    "&:hover": {
      color: "#26BBED",
    },
  },
  filterIcon: {
    width: 24,
    height: 24,
    fill: "none",
    cursor: "pointer",
    "&:hover path": {
      stroke: "var(--hylyTheme)",
    },
  },
  activeFilterIcon: {
    width: 24,
    height: 24,
    fill: "none",
    cursor: "pointer",
    background: "linear-gradient(0deg, rgba(38, 187, 237, 0.1), rgba(38, 187, 237, 0.1)), #FFFFFF",
  },
  downloadIcon: {
    width: 24,
    height: 24,
    marginTop: -8,
    marginLeft: -4,
    fill: "none",
    cursor: "pointer",
    "&:hover path": {
      stroke: "var(--hylyTheme)",
    },
  },
  divider: {
    height: 24,
    width: 1,
    margin: "0px 12px",
    background: "#EEE",
  },
});

const TableHeader = ({
  classes,
  search = false,
  switcher = false,
  onToggleSearch,
  onToggleSwitcher,
  fileName = "file.csv",
  totalRows = 0,
  csvData = [],
  csvHeaders = [],
  titlebar,
  tableData,
}) => {
  return (
    <div className={classes.tableHeading}>
      <div className={classes.heading}>
        {titlebar.heading && <span className="text-text-3">{titlebar.heading} </span>}
        {titlebar.title}
        {titlebar.loadingCount || ""}
      </div>
      {!tableData || tableData?.length > 0 ? (
        <div className={`${classes.headingBarActionBox} space-x-3`}>
          {titlebar.search && (
            <FilterIcon
              className={search ? classes.activeFilterIcon : classes.filterIcon}
              onClick={onToggleSearch}
            />
          )}
          {titlebar.percentage && (
            <>
              {titlebar.search && <div className={classes.divider}></div>}
              {/* <PercentageIcon
                className={switcher ? classes.activeFilterIcon : classes.filterIcon}
                onClick={onToggleSwitcher}
              /> */}
              <ConstantIcon
                className={!switcher ? classes.activeFilterIcon : classes.filterIcon}
                onClick={onToggleSwitcher}
              />
            </>
          )}
          {titlebar.export && (
            <>
              {(titlebar.search || titlebar.percentage) && <div className={classes.divider}></div>}
              {totalRows > 1000 ? (
                <DownloadCSVIcon
                  className={classes.downloadIcon}
                  onClick={titlebar.onExportViaEmail ? titlebar.onExportViaEmail : () => {}}
                />
              ) : titlebar.csvLoader ? (
                <CircularProgress size={16} />
              ) : (
                <CSVLink filename={fileName} data={csvData} headers={csvHeaders}>
                  <DownloadCSVIcon className={classes.downloadIcon} />
                </CSVLink>
              )}
            </>
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
export default withStyles(styles)(TableHeader);
