import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const initialState = {
  loading: false,
  events: [],
  rawEvents: [],
  pageInfo: null,
  broker: null,
  brokerLogs: [],
  brokerLoaded: false,
  studentLogs: [],
  studentLoaded: false,
  eventsLoaded: false,
  reports: [],
  reportsLoaded: false,
  eventApprovalRequests: [],
  eventSettings: {},
  eventApprover: false,
  selectedViewDetails: {},
  monthRange: {
    startAt: moment().subtract(30, "day"),
    endAt: moment(),
    label: "Last 30 Days",
  },
  selectedEvents: [],
  reportLoading: false,
  reportEvents: [],
  reportRawEvents: [],
  reportPageInfo: null,
  campaignTestedId: null,
};

export const eventSlice = createSlice({
  name: "event",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setEvents: (state, action) => {
      state.events = action.payload;
      state.eventsLoaded = true;
    },
    setRawEvents: (state, action) => {
      state.rawEvents = action.payload;
    },
    setPageInfo: (state, action) => {
      state.pageInfo = action.payload;
    },
    setBrokerLogs: (state, action) => {
      const { broker, logs } = action.payload;
      state.broker = broker;
      state.brokerLogs = logs;
      state.brokerLoaded = true;
    },
    setBrokerLoaded: (state, action) => {
      state.brokerLoaded = action.payload;
    },
    setStudentLogs: (state, action) => {
      const { broker, logs } = action.payload;
      state.broker = broker;
      state.studentLogs = logs;
      state.studentLoaded = true;
    },
    setStudentLoaded: (state, action) => {
      state.studentLoaded = action.payload;
    },
    initLoadEvents: (state) => {
      state.eventsLoaded = false;
    },
    setEventReports: (state, action) => {
      state.reports = action.payload;
      state.reportsLoaded = true;
    },
    resetEventReportsLoaded: (state) => {
      state.reportsLoaded = false;
    },
    setEventsApprovalRequests: (state, action) => {
      state.eventApprovalRequests = action.payload;
    },
    setEventSettings: (state, action) => {
      state.eventSettings = action.payload;
    },
    setEventApprover: (state, action) => {
      state.eventApprover = action.payload;
    },
    seteventViewDetails: (state, action) => {
      state.selectedViewDetails = action.payload;
    },
    setMonthRange: (state, action) => {
      state.monthRange = action.payload;
    },
    setSelectedEvents: (state, action) => {
      state.selectedEvents = action.payload;
    },
    setReportLoading: (state, action) => {
      state.reportLoading = action.payload;
    },
    setReportEvents: (state, action) => {
      state.reportEvents = action.payload;
      state.eventsLoaded = true;
    },
    setReportRawEvents: (state, action) => {
      state.reportRawEvents = action.payload;
    },
    setReportPageInfo: (state, action) => {
      state.reportPageInfo = action.payload;
    },
    setCampaignTestedId: (state, action) => {
      state.campaignTestedId = action.payload;
    },
  },
});

export const {
  setLoading,
  setEvents,
  setRawEvents,
  setPageInfo,
  setBrokerLogs,
  setBrokerLoaded,
  setStudentLogs,
  setStudentLoaded,
  setEventReports,
  resetEventReportsLoaded,
  initLoadEvents,
  setEventsApprovalRequests,
  setEventSettings,
  setEventApprover,
  seteventViewDetails,
  setMonthRange,
  setSelectedEvents,
  setReportEvents,
  setReportRawEvents,
  setReportLoading,
  setReportPageInfo,
  setCampaignTestedId,
} = eventSlice.actions;

export const selectLoading = (state) => state.event.loading;
export const selectEvents = (state) => state.event.events;
export const selectRawEvents = (state) => state.event.rawEvents;
export const selectPageInfo = (state) => state.event.pageInfo;
export const selectEventsLoaded = (state) => state.event.eventsLoaded;
export const selectMonthRange = (state) => state.event.monthRange;
export const getSelectedEvents = (state) => state.event.selectedEvents;

export const selectBroker = (state) => state.event.broker;
export const selectBrokerLogs = (state) => state.event.brokerLogs;
export const selectBrokerLoaded = (state) => state.event.brokerLoaded;
export const selectStudentLogs = (state) => state.event.studentLogs;
export const selectStudentLoaded = (state) => state.event.studentLoaded;
export const selectEventReports = (state) => state.event.reports;
export const selectEventReportsLoaded = (state) => state.event.reportsLoaded;
export const getEventsApprovalRequests = (state) => state.event.eventApprovalRequests;
export const getEventsSettings = (state) => state.event.eventSettings;
export const getEventApprover = (state) => state.event.eventApprover;
export const getSelectedViewDetails = (state) => state.event.selectedViewDetails;

export const selectReportLoading = (state) => state.event.reportLoading;
export const selectReportEvents = (state) => state.event.reportEvents;
export const selectReportRawEvents = (state) => state.event.reportRawEvents;
export const selectReportPageInfo = (state) => state.event.reportPageInfo;
export const selectCampaignTestedId = (state) => state.event.campaignTestedId;
export default eventSlice.reducer;
