import React from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import { Button } from "storybookComponents";
import Field from "content/hyRaf/Campaign/ScheduleInvite/multiTagField.js";
import { sendReport } from "relay/main";
import { getErrorMessage, checkUncatchableError } from "environments/uncatchableErrorStore";
import { getMonthRange } from "common/date";
import moment from "moment";
import { getTransformUrl } from "common";

const EmailReport = ({ classes, onClose, showNotification, history, propertyIds, ...props }) => {
  const {
    location: { search },
  } = history;
  const [values, setValues] = React.useState({
    pdf: true,
    csv: true,
    emails: [],
  });
  const [monthRange, setMonthRange] = React.useState();
  const [buttonState, setButtonState] = React.useState(true);
  const [properties, setProperties] = React.useState();

  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.checked,
    });
  };

  const setFieldValue = (keyName, emails) => {
    setValues({
      ...values,
      emails,
    });
  };

  const handleSend = async () => {
    setButtonState(false);
    try {
      const url =
        process.env.REACT_APP_hostName + history.location.pathname + history.location.search;
      const resp =
        props.allRegionsIds.length !== props.regionIds.length && props.type === "property_blast"
          ? await sendReport({
              orgId: props.orgId,
              type: props.type,
              recipients: values.emails.toString(),
              startDate: moment(monthRange.startAt).format("YYYY-MM-DD"),
              endDate: moment(monthRange.endAt).format("YYYY-MM-DD"),
              propertyIds: properties,
              reportType: "jai_report",
              reportUrl: url,
              regionIds: props.regionIds,
            })
          : await sendReport({
              orgId: props.orgId,
              type: props.type,
              recipients: values.emails.toString(),
              startDate: moment(monthRange.startAt).format("YYYY-MM-DD"),
              endDate: moment(monthRange.endAt).format("YYYY-MM-DD"),
              propertyIds: properties,
              reportType: "jai_report",
              reportUrl: url,
            });
      if (resp) {
        showNotification("Report send successfully", "success");
        onClose();
      } else {
        showNotification("Something went wrong", "error");
      }
    } catch {
      const uncachebleError = getErrorMessage(checkUncatchableError("scheduleReportMutation"));
      showNotification(uncachebleError || "Something went wrong.", "error");
      onClose();
    }
    setButtonState(true);
  };

  const init = async () => {
    let params = new URLSearchParams(search);
    // let propertiesParams = params.get("properties")
    //   ? params.get("properties").split(",")
    //   : props.propertyIds;
    let propertiesParams = [];
    if (params.get("properties")) {
      propertiesParams = params.get("properties").split(",");
    } else {
      const resp = await getTransformUrl(propertyIds.toString());
      propertiesParams = [resp.transformUrl.out];
    }

    let regionsParams = params.get("regions") ? params.get("regions").split(",") : props.regionsIds;
    let labelParams = params.get("label")
      ? params.get("label")
      : params.get("startDate")
      ? "Custom Range"
      : "Last 30 Days";
    let startDateParams = params.get("startDate")
      ? moment(params.get("startDate"))
      : moment().subtract(30, "day");
    let endDateParams = params.get("endDate") ? moment(params.get("endDate")) : moment();
    let monthRange;
    if (labelParams === "Custom Range") {
      monthRange = {
        startAt: startDateParams,
        endAt: endDateParams,
        label: labelParams,
      };
    } else {
      monthRange = getMonthRange(labelParams);
    }
    history.push({
      search: `${
        props.type === "property_blast"
          ? `&regions=${regionsParams?.toString()}`
          : `&properties=${propertiesParams?.toString()}&regions=${regionsParams?.toString()}`
      }${
        labelParams === "Custom Range"
          ? `&startDate=${startDateParams.format("DD-MMM-YYYY")}&endDate=${endDateParams.format(
              "DD-MMM-YYYY"
            )}`
          : `&label=${monthRange.label}`
      }`,
    });
    setMonthRange(monthRange);
    setProperties(propertiesParams);
  };

  React.useEffect(() => {
    init();
  }, []);

  return (
    <div>
      <div className={classes.header}>Please enter the detailed below to email this Report.</div>
      {/* <FormControlLabel
        control={<Checkbox checked={values.pdf} name="pdf" onChange={handleChange} />}
        label="PDF"
      />
      <br />
      <FormControlLabel
        control={<Checkbox checked={values.csv} name="csv" onChange={handleChange} />}
        label="CSV"
      />
      <br />
      <div className={classes.subtitle}>Select atleast one format</div> */}
      <Field
        values={values}
        setFieldValue={setFieldValue}
        placeholder="example@example.com"
        keyName="emails"
        label="Email Address"
        className={{
          container: classes.emailFieldContainer,
          label: classes.label,
        }}
      />
      <div className={classes.bottomFlexBox}>
        <Button className={classes.btnStyle} variant="tertiary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          className={classes.btnStyle}
          state={values.emails.length > 0 && buttonState ? "active" : "inactive"}
          onClick={handleSend}
        >
          Send
        </Button>
      </div>
    </div>
  );
};

export default EmailReport;
