/**
 * @flow
 * @relayHash 71ef0e766d47fa055795b40a4fff54d9
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type contactExpressionCountQueryVariables = {|
  propertyId: string,
  expression: string,
  recipient: string,
|};
export type contactExpressionCountQueryResponse = {|
  +contactExpressionCountEmail: any
|};
export type contactExpressionCountQuery = {|
  variables: contactExpressionCountQueryVariables,
  response: contactExpressionCountQueryResponse,
|};
*/


/*
query contactExpressionCountQuery(
  $propertyId: ID!
  $expression: String!
  $recipient: String!
) {
  contactExpressionCountEmail(propertyId: $propertyId, expression: $expression, recipient: $recipient)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "propertyId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "expression",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "recipient",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "contactExpressionCountEmail",
    "args": [
      {
        "kind": "Variable",
        "name": "expression",
        "variableName": "expression"
      },
      {
        "kind": "Variable",
        "name": "propertyId",
        "variableName": "propertyId"
      },
      {
        "kind": "Variable",
        "name": "recipient",
        "variableName": "recipient"
      }
    ],
    "storageKey": null
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "contactExpressionCountQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "contactExpressionCountQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "contactExpressionCountQuery",
    "id": null,
    "text": "query contactExpressionCountQuery(\n  $propertyId: ID!\n  $expression: String!\n  $recipient: String!\n) {\n  contactExpressionCountEmail(propertyId: $propertyId, expression: $expression, recipient: $recipient)\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2df21b8259d54358f62e6757565842d6';
module.exports = node;
