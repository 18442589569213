/**
 * @flow
 * @relayHash 3ae057594a1dfd085c98142548aaa76e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getOrgOverviewQueryVariables = {|
  orgId?: ?string,
  propertyIds?: ?$ReadOnlyArray<string>,
  campaignIds?: ?$ReadOnlyArray<string>,
  startAt?: ?string,
  endAt?: ?string,
  concession?: ?$ReadOnlyArray<string>,
|};
export type getOrgOverviewQueryResponse = {|
  +orgOverview: {|
    +clicked: ?string,
    +id: string,
    +leased: ?string,
    +leasedCount: ?string,
    +topOfferId: ?string,
    +totalCampaigns: ?string,
    +totalOffers: ?string,
    +toured: ?string,
    +touredCount: ?string,
  |}
|};
export type getOrgOverviewQuery = {|
  variables: getOrgOverviewQueryVariables,
  response: getOrgOverviewQueryResponse,
|};
*/


/*
query getOrgOverviewQuery(
  $orgId: ID
  $propertyIds: [String!]
  $campaignIds: [String!]
  $startAt: String
  $endAt: String
  $concession: [String!]
) {
  orgOverview(orgId: $orgId, propertyIds: $propertyIds, campaignIds: $campaignIds, startAt: $startAt, endAt: $endAt, concession: $concession) {
    clicked
    id
    leased
    leasedCount
    topOfferId
    totalCampaigns
    totalOffers
    toured
    touredCount
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "orgId",
    "type": "ID",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "propertyIds",
    "type": "[String!]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "campaignIds",
    "type": "[String!]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "startAt",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "endAt",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "concession",
    "type": "[String!]",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "orgOverview",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "campaignIds",
        "variableName": "campaignIds"
      },
      {
        "kind": "Variable",
        "name": "concession",
        "variableName": "concession"
      },
      {
        "kind": "Variable",
        "name": "endAt",
        "variableName": "endAt"
      },
      {
        "kind": "Variable",
        "name": "orgId",
        "variableName": "orgId"
      },
      {
        "kind": "Variable",
        "name": "propertyIds",
        "variableName": "propertyIds"
      },
      {
        "kind": "Variable",
        "name": "startAt",
        "variableName": "startAt"
      }
    ],
    "concreteType": "OrgOverview",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "clicked",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "leased",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "leasedCount",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "topOfferId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "totalCampaigns",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "totalOffers",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "toured",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "touredCount",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getOrgOverviewQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "getOrgOverviewQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "getOrgOverviewQuery",
    "id": null,
    "text": "query getOrgOverviewQuery(\n  $orgId: ID\n  $propertyIds: [String!]\n  $campaignIds: [String!]\n  $startAt: String\n  $endAt: String\n  $concession: [String!]\n) {\n  orgOverview(orgId: $orgId, propertyIds: $propertyIds, campaignIds: $campaignIds, startAt: $startAt, endAt: $endAt, concession: $concession) {\n    clicked\n    id\n    leased\n    leasedCount\n    topOfferId\n    totalCampaigns\n    totalOffers\n    toured\n    touredCount\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '35b3a49f3ec5bfae2c166af97f5875c4';
module.exports = node;
