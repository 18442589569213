/**
 * @flow
 * @relayHash 6d59c36bbf70fd2e7e7a9e4bb6e01e23
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type mailGptBlastQueryVariables = {|
  id: string
|};
export type mailGptBlastQueryResponse = {|
  +mailGptBlast: {|
    +id: string,
    +availableBodys: ?$ReadOnlyArray<{|
      +feedback: ?{|
        +feedbackableType: ?string,
        +id: string,
        +liked: ?boolean,
      |},
      +fieldType: ?string,
      +id: string,
      +value: ?string,
      +regenerated: ?boolean,
      +humanReplaced: ?boolean,
      +active: ?boolean,
    |}>,
    +availableCtaTexts: ?$ReadOnlyArray<{|
      +feedback: ?{|
        +feedbackableType: ?string,
        +id: string,
        +liked: ?boolean,
      |},
      +fieldType: ?string,
      +id: string,
      +value: ?string,
      +regenerated: ?boolean,
      +humanReplaced: ?boolean,
      +active: ?boolean,
    |}>,
    +availablePreheaders: ?$ReadOnlyArray<{|
      +feedback: ?{|
        +feedbackableType: ?string,
        +id: string,
        +liked: ?boolean,
      |},
      +fieldType: ?string,
      +id: string,
      +value: ?string,
      +regenerated: ?boolean,
      +humanReplaced: ?boolean,
      +active: ?boolean,
    |}>,
    +availableSmsTexts: ?$ReadOnlyArray<{|
      +feedback: ?{|
        +feedbackableType: ?string,
        +id: string,
        +liked: ?boolean,
      |},
      +fieldType: ?string,
      +id: string,
      +value: ?string,
      +regenerated: ?boolean,
      +humanReplaced: ?boolean,
      +active: ?boolean,
    |}>,
    +availableSocialMediaPosts: ?$ReadOnlyArray<{|
      +feedback: ?{|
        +feedbackableType: ?string,
        +id: string,
        +liked: ?boolean,
      |},
      +fieldType: ?string,
      +id: string,
      +value: ?string,
      +regenerated: ?boolean,
      +humanReplaced: ?boolean,
      +active: ?boolean,
    |}>,
    +availableSubjects: ?$ReadOnlyArray<{|
      +feedback: ?{|
        +feedbackableType: ?string,
        +id: string,
        +liked: ?boolean,
      |},
      +fieldType: ?string,
      +id: string,
      +value: ?string,
      +regenerated: ?boolean,
      +humanReplaced: ?boolean,
      +active: ?boolean,
    |}>,
    +availableTitles: $ReadOnlyArray<{|
      +feedback: ?{|
        +feedbackableType: ?string,
        +id: string,
        +liked: ?boolean,
      |},
      +fieldType: ?string,
      +id: string,
      +value: ?string,
      +regenerated: ?boolean,
      +humanReplaced: ?boolean,
      +active: ?boolean,
    |}>,
    +humanReplacedCount: ?string,
    +totalDislikesCount: ?string,
    +totalLikesCount: ?string,
    +totalRegenerates: ?string,
  |}
|};
export type mailGptBlastQuery = {|
  variables: mailGptBlastQueryVariables,
  response: mailGptBlastQueryResponse,
|};
*/


/*
query mailGptBlastQuery(
  $id: ID!
) {
  mailGptBlast(id: $id) {
    id
    availableBodys {
      feedback {
        feedbackableType
        id
        liked
      }
      fieldType
      id
      value
      regenerated
      humanReplaced
      active
    }
    availableCtaTexts {
      feedback {
        feedbackableType
        id
        liked
      }
      fieldType
      id
      value
      regenerated
      humanReplaced
      active
    }
    availablePreheaders {
      feedback {
        feedbackableType
        id
        liked
      }
      fieldType
      id
      value
      regenerated
      humanReplaced
      active
    }
    availableSmsTexts {
      feedback {
        feedbackableType
        id
        liked
      }
      fieldType
      id
      value
      regenerated
      humanReplaced
      active
    }
    availableSocialMediaPosts {
      feedback {
        feedbackableType
        id
        liked
      }
      fieldType
      id
      value
      regenerated
      humanReplaced
      active
    }
    availableSubjects {
      feedback {
        feedbackableType
        id
        liked
      }
      fieldType
      id
      value
      regenerated
      humanReplaced
      active
    }
    availableTitles {
      feedback {
        feedbackableType
        id
        liked
      }
      fieldType
      id
      value
      regenerated
      humanReplaced
      active
    }
    humanReplacedCount
    totalDislikesCount
    totalLikesCount
    totalRegenerates
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "feedback",
    "storageKey": null,
    "args": null,
    "concreteType": "Feedback",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "feedbackableType",
        "args": null,
        "storageKey": null
      },
      (v1/*: any*/),
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "liked",
        "args": null,
        "storageKey": null
      }
    ]
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "fieldType",
    "args": null,
    "storageKey": null
  },
  (v1/*: any*/),
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "value",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "regenerated",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "humanReplaced",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "active",
    "args": null,
    "storageKey": null
  }
],
v3 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "mailGptBlast",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "GptHistory",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "availableBodys",
        "storageKey": null,
        "args": null,
        "concreteType": "GptHistoryField",
        "plural": true,
        "selections": (v2/*: any*/)
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "availableCtaTexts",
        "storageKey": null,
        "args": null,
        "concreteType": "GptHistoryField",
        "plural": true,
        "selections": (v2/*: any*/)
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "availablePreheaders",
        "storageKey": null,
        "args": null,
        "concreteType": "GptHistoryField",
        "plural": true,
        "selections": (v2/*: any*/)
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "availableSmsTexts",
        "storageKey": null,
        "args": null,
        "concreteType": "GptHistoryField",
        "plural": true,
        "selections": (v2/*: any*/)
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "availableSocialMediaPosts",
        "storageKey": null,
        "args": null,
        "concreteType": "GptHistoryField",
        "plural": true,
        "selections": (v2/*: any*/)
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "availableSubjects",
        "storageKey": null,
        "args": null,
        "concreteType": "GptHistoryField",
        "plural": true,
        "selections": (v2/*: any*/)
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "availableTitles",
        "storageKey": null,
        "args": null,
        "concreteType": "GptHistoryField",
        "plural": true,
        "selections": (v2/*: any*/)
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "humanReplacedCount",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "totalDislikesCount",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "totalLikesCount",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "totalRegenerates",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "mailGptBlastQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "mailGptBlastQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "mailGptBlastQuery",
    "id": null,
    "text": "query mailGptBlastQuery(\n  $id: ID!\n) {\n  mailGptBlast(id: $id) {\n    id\n    availableBodys {\n      feedback {\n        feedbackableType\n        id\n        liked\n      }\n      fieldType\n      id\n      value\n      regenerated\n      humanReplaced\n      active\n    }\n    availableCtaTexts {\n      feedback {\n        feedbackableType\n        id\n        liked\n      }\n      fieldType\n      id\n      value\n      regenerated\n      humanReplaced\n      active\n    }\n    availablePreheaders {\n      feedback {\n        feedbackableType\n        id\n        liked\n      }\n      fieldType\n      id\n      value\n      regenerated\n      humanReplaced\n      active\n    }\n    availableSmsTexts {\n      feedback {\n        feedbackableType\n        id\n        liked\n      }\n      fieldType\n      id\n      value\n      regenerated\n      humanReplaced\n      active\n    }\n    availableSocialMediaPosts {\n      feedback {\n        feedbackableType\n        id\n        liked\n      }\n      fieldType\n      id\n      value\n      regenerated\n      humanReplaced\n      active\n    }\n    availableSubjects {\n      feedback {\n        feedbackableType\n        id\n        liked\n      }\n      fieldType\n      id\n      value\n      regenerated\n      humanReplaced\n      active\n    }\n    availableTitles {\n      feedback {\n        feedbackableType\n        id\n        liked\n      }\n      fieldType\n      id\n      value\n      regenerated\n      humanReplaced\n      active\n    }\n    humanReplacedCount\n    totalDislikesCount\n    totalLikesCount\n    totalRegenerates\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8b24576855c42579e02fbe0ad2b55231';
module.exports = node;
