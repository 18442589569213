import React, { Fragment } from "react";
import withStyles from "@mui/styles/withStyles";
import throttle from "lodash/throttle";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "../../Typography";
import Icon from "../../Icon";
import { ListItemUnit } from "../../SearchField/components/SimpleList/ListItem";
import { useSelector } from "react-redux";
import { getDisableOrgLevelClick } from "redux/slices";

const styles = (theme) => ({
  list_container: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    minHeight: 0,
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    width: "100%",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    overflowY: "auto",
    overflowX: "hidden",
    width: "100%",
    padding: 0,
    //trastion:
    //  padding:`${theme.margins.md}px ${theme.margins.xs}px 0`
  },

  selected_icon_container: {
    margin: `-2px auto auto ${theme.margins.xs}px`,
    color: theme.palette.hylyBlue,
  },
  expand_icon_container: {
    margin: "0 0 0 auto",
    minWidth: 10,
  },

  typography: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
});

const itemStyles = (theme) => ({
  root_list_item: {
    cursor: "pointer",
    padding: theme.margins.xs,
    "&:hover": {
      background: theme.palette.whiteGrey,
    },
    "&:first-child": {
      //  padding: `${theme.margins.md}px ${theme.margins.xs}px ${theme.margins.xs}px`
    },
    "&$selected": {
      "&:hover": {
        background: "none",
      },
    },
  },
  selected: {
    fontWeight: theme.fontWeight.bold,
  },
  temp_selection: {
    background: theme.palette.lightGrey,
  },
});

const itemContentStyles = (theme) => ({
  typography: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  expand_icon_container: {
    margin: "0 0 0 auto",
    minWidth: 10,
  },
});

const ListItemContent = React.memo(
  withStyles(itemContentStyles)(
    ({ classes, color, search_value, items, expand_icon_type, is_expanded }) => (
      <Fragment>
        <Typography type="body1" className={classes.typography} font_weight={400} color={color}>
          {items.map((o, idx) => (
            <ListItemUnit key={`simple-list-${o}-${idx}`} item={o} search_value={search_value} />
          ))}
        </Typography>
        {Boolean(is_expanded) && (
          <ListItemIcon className={classes.expand_icon_container}>
            <Icon type={expand_icon_type} size="xs" />
          </ListItemIcon>
        )}
      </Fragment>
    )
  )
);

const ListItemComponent = React.memo(
  withStyles(itemStyles)(
    ({
      classes,
      item,
      onSelect,
      onmouseover_function,
      is_expanded,
      expand_icon_type,
      color = "darkBlack",
      selected,
      temp_selected,
      search_value,
      ignore,
      isRentOrg,
      selectedRef,
    }) => {
      const disableClickOrgLevel = useSelector(getDisableOrgLevelClick);
      const splitted_item = React.useMemo(() => {
        if (!search_value) {
          return [item.name];
        }

        return item.name
          .replace(RegExp(search_value, "gi"), (...args) => {
            const o = args[0],
              i = args[args.length - 2];
            return i === 0 ? `${o}__` : i === item.name.length - 1 ? `__${o}` : `__${o}__`;
          })
          .split("__");
      }, [item.name, search_value]);

      if (ignore) {
        return null;
      }

      return (
        <ListItem
          ref={selectedRef}
          onClick={(e) => {
            if (!disableClickOrgLevel) {
              onSelect(e, item);
            }
          }}
          selected={selected}
          onMouseEnter={throttle(
            (e) => onmouseover_function && onmouseover_function(item, "mouse"),
            500
          )}
          classes={{
            root: classes.root_list_item,
            selected: classes.selected,
          }}
          style={{ background: temp_selected ? "#eee" : undefined }}
        >
          <ListItemContent
            items={splitted_item}
            color={color}
            search_value={search_value}
            expand_icon_type={expand_icon_type}
            is_expanded={is_expanded}
          />
        </ListItem>
      );
    }
  )
);

const UNIT_HEIGHT = 56;
export default React.memo(
  withStyles(styles)(
    ({
      classes,
      suggestions,
      search_value,
      selected_value,
      onSelect,
      selected_icon_type,
      is_expanded,
      expand_icon_type,
      exapnd_icon_action,
      onmouseover_function,
      temp_org,
      tempOrgIndex,
      keyPressed,
      isRentOrg,
    }) => {
      const listRef = React.useRef();
      const selectedRef = React.useRef();
      //const [scrollToView, setScrollToView] = React.useState(0);

      React.useEffect(() => {
        if (selectedRef.current) {
          selectedRef.current.scrollIntoView({ behavior: "instant" });
        }
      }, [suggestions]);

      React.useEffect(() => {
        if (!listRef || !listRef.current) {
          return;
        }
        if (!keyPressed) {
          return;
        }

        const goingUp = keyPressed === "ArrowUp",
          goingDown = ["Enter", "ArrowDown"].indexOf(keyPressed) > -1;

        if (!goingUp && !goingDown) {
          return;
        }

        const addition = goingUp ? -1 : +1,
          { scrollTop, clientHeight } = listRef.current,
          temp_org_pos = UNIT_HEIGHT * (tempOrgIndex + addition);

        if (tempOrgIndex === suggestions.length - 1) {
          listRef.current.scrollTo({ left: 0, top: 0, behavior: "smooth" });
        } else if (temp_org_pos - UNIT_HEIGHT * 2 <= scrollTop) {
          listRef.current.scrollTo({
            left: 0,
            top: scrollTop - UNIT_HEIGHT * 2,
            behavior: "smooth",
          });
        } else if (temp_org_pos + UNIT_HEIGHT * 2 > clientHeight + scrollTop) {
          listRef.current.scrollTo({
            left: 0,
            top: scrollTop + UNIT_HEIGHT * 2,
            behavior: "smooth",
          });
        }
      }, [listRef, keyPressed, tempOrgIndex, suggestions.length]);

      return (
        <div className={classes.list_container}>
          <List className={classes.content} ref={listRef}>
            {suggestions && suggestions.length
              ? suggestions.map((item, index) => (
                  <ListItemComponent
                    key={`${item.id}-${item.name}-suggestion-list-item`}
                    item={item}
                    onSelect={onSelect}
                    onmouseover_function={onmouseover_function}
                    is_expanded={is_expanded}
                    expand_icon_type={expand_icon_type}
                    temp_selected={temp_org && temp_org.id === item.id}
                    isRentOrg={isRentOrg}
                    search_value={search_value}
                    selected={selected_value && selected_value.id === item.id}
                    color={selected_value && selected_value.id === item.id ? "hylyBlue" : null}
                    selectedRef={
                      selected_value && selected_value.id === item.id ? selectedRef : null
                    }
                  />
                ))
              : null}
          </List>
        </div>
      );
    }
  )
);
