import graphql from "babel-plugin-relay/macro";

export default graphql`
  query getHayleyCustomizationQuery($propertyId: ID!) {
    hayley(propertyId: $propertyId) {
      iconThumbnailUrl
      iconUrl
      id
      imageData
      position
      widgetTitle
      chatbotTitle
      supportMultiLanguage
      hidePriceRangeFilter
      promotion
      showFeedbackFilter
      iconFileType
      firstMessage
      disclaimer
      tourScheduleEnabled
      customButtonEnabled
      makePhoneNumberRequired
      showAllUnits
    }
  }
`;
