import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: null,
};

export const ai_WorkshopSlice = createSlice({
  name: "ai_workshop",
  initialState,
  reducers: {
    addOrgsAndProperties: (state, action) => {
      state.data = action.payload;
    },
  },
});
export const { addOrgsAndProperties } = ai_WorkshopSlice.actions;
export default ai_WorkshopSlice.reducer;
