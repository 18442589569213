import inaccessibleImage from "../../images/deactive.png";
// eslint-disable-next-line
export default () => `
<style>
@import url("https://fonts.googleapis.com/css?family=Raleway");
@import url("https://fonts.googleapis.com/css?family=Open+Sans");
.container {
  display:flex;
  height:98vh;
  width:100%;
  align-items:center;
  justify-content:center;
  background:white
}
.container div{
  text-align:center;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  font-family: "Open Sans", sans-serif;
}
</style>
<div class="container">
  <div>
    <img width="170" src=${inaccessibleImage} />
    <div>This Referral campaign is no longer available to enroll</div>
  </div>
 </div>
`;
