/**
 * @flow
 * @relayHash 6036f39a2dad80b06c2a8fc1db0d7072
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type sendReportInput = {|
  clientMutationId?: ?string,
  orgId: string,
  type: string,
  recipients: string,
  startDate: string,
  endDate: string,
  propertyIds?: ?$ReadOnlyArray<?string>,
  reportType?: ?string,
  reportUrl?: ?string,
|};
export type sendReportMutationVariables = {|
  input: sendReportInput
|};
export type sendReportMutationResponse = {|
  +sendReport: ?{|
    +message: ?string
  |}
|};
export type sendReportMutation = {|
  variables: sendReportMutationVariables,
  response: sendReportMutationResponse,
|};
*/


/*
mutation sendReportMutation(
  $input: sendReportInput!
) {
  sendReport(input: $input) {
    message
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "sendReportInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "sendReport",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "sendReportPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "message",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "sendReportMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "sendReportMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "sendReportMutation",
    "id": null,
    "text": "mutation sendReportMutation(\n  $input: sendReportInput!\n) {\n  sendReport(input: $input) {\n    message\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '30093f4fc8f722b812f2cc25dee588ad';
module.exports = node;
