import graphql from "babel-plugin-relay/macro";

export default graphql`
  query getAppsQuery($orgId: ID, $propertyId: ID) {
    apps(orgId: $orgId, propertyId: $propertyId) {
      id
      name
      customName
      url
      authorized
      appOrHub
      modules
      access
      expiredAt
    }
  }
`;
