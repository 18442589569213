import graphql from "babel-plugin-relay/macro";

export default graphql`
  query allOrgsGptFieldsAnalysisQuery(
    $allOrgs: Boolean
    $endAt: String
    $startAt: String
    $orgIds: [String!]
    $blastTypes: [String!]
    $promptTemplates: [String!]
    $propertyIds: [String!]
  ) {
    allOrgsGptFieldsAnalysis(
      allOrgs: $allOrgs
      endAt: $endAt
      startAt: $startAt
      orgIds: $orgIds
      blastTypes: $blastTypes
      promptTemplates: $promptTemplates
      propertyIds: $propertyIds
    )
  }
`;
