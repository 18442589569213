import React from "react";
import { withRouter } from "react-router-dom";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import Paper from "@mui/material/Paper";
import { DrillDown } from "components/Icons";
import * as utils from "./utils";
import TableHeatMap from "./TableHeatmap";
import { tableCardsStyles } from "./styles";
import FullScreenDialog from "./fullscreen/fullscreenPopup";
// import { full_screen_columns, getColors } from "./columns";
import { EmptyTableIcon } from "icons";

const useStyles = makeStyles(tableCardsStyles);

const TableCards = (props) => {
  const classes = useStyles();
  const [openDialog, setOpenDialogue] = React.useState(false);
  const [hovered, setHovered] = React.useState(false);

  const handleToggle = () => {
    setOpenDialogue(!openDialog);
  };

  const isEmpty =
    props.data.length === 0 || props?.data?.filter((item) => item.metric > 0).length === 0;

  return (
    <div>
      <Paper
        elevation={0}
        className={isEmpty ? classes.noDataRoot : classes.root}
        onMouseEnter={() => {}}
        onMouseLeave={() => {}}
      >
        <div className={classes.details} onClick={() => props.history.push(props.onclickroute)}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography className={classes.title} variant="h2">
              <span data-id="table-title">{props.title}:</span>
              <span className={classes.total}>
                {utils.numberWithCommas(utils.calculateSum(props.data, props.sumBy))}
              </span>
            </Typography>
          </div>
        </div>
        <>
          {isEmpty && !props.loading ? (
            <div className={classes.noData}>
              <EmptyTableIcon />
              <div>No data found. Try using different filters.</div>
            </div>
          ) : (
            <TableHeatMap
              loading={props.loading}
              data={props.data}
              halfdata={props.halfdata}
              onClickTable={props.onClickTable}
              onClickOpenDialog={handleToggle}
              title={props.title}
              color={props.color}
              indexby={props.indexby}
              keys={props.keys}
              columns={props.columns}
              heatmapColor={props.heatmapColor}
              limit={props.limit}
              tourInsights={props.tourInsights}
              remainingRowsInfo={props.remainingRowsInfo}
            />
          )}
        </>
      </Paper>
    </div>
  );
};

export default withRouter(TableCards);
