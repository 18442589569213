/**
 * @flow
 * @relayHash 6ee8c50fa77bcd193a968b779909758f
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getAngelsQueryVariables = {|
  propertyId: string,
  id?: ?string,
  angelType?: ?string,
|};
export type getAngelsQueryResponse = {|
  +angels: $ReadOnlyArray<{|
    +id: string,
    +name: ?string,
    +active: ?boolean,
    +thenEmails: ?$ReadOnlyArray<{|
      +id: string,
      +name: string,
      +active: boolean,
      +triggerDays: number,
      +originalThumbnailUrl: ?string,
      +whenGroupName: ?string,
      +tourType: ?string,
    |}>,
  |}>
|};
export type getAngelsQuery = {|
  variables: getAngelsQueryVariables,
  response: getAngelsQueryResponse,
|};
*/


/*
query getAngelsQuery(
  $propertyId: ID!
  $id: String
  $angelType: String
) {
  angels(propertyId: $propertyId, id: $id, angelType: $angelType) {
    id
    name
    active
    thenEmails {
      id
      name
      active
      triggerDays
      originalThumbnailUrl
      whenGroupName
      tourType
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "propertyId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "angelType",
    "type": "String",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "active",
  "args": null,
  "storageKey": null
},
v4 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "angels",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "angelType",
        "variableName": "angelType"
      },
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "propertyId",
        "variableName": "propertyId"
      }
    ],
    "concreteType": "Angel",
    "plural": true,
    "selections": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "thenEmails",
        "storageKey": null,
        "args": null,
        "concreteType": "ThenEmail",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "triggerDays",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "originalThumbnailUrl",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "whenGroupName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "tourType",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getAngelsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v4/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "getAngelsQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v4/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "getAngelsQuery",
    "id": null,
    "text": "query getAngelsQuery(\n  $propertyId: ID!\n  $id: String\n  $angelType: String\n) {\n  angels(propertyId: $propertyId, id: $id, angelType: $angelType) {\n    id\n    name\n    active\n    thenEmails {\n      id\n      name\n      active\n      triggerDays\n      originalThumbnailUrl\n      whenGroupName\n      tourType\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8f64af39a5fbb8cad0e1da97be362494';
module.exports = node;
