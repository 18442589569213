/**
 * @flow
 * @relayHash 80d01852d5a04725cd578356e434dd3c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getListMacrosQueryVariables = {|
  propertyId: string
|};
export type getListMacrosQueryResponse = {|
  +macros: $ReadOnlyArray<{|
    +name: string,
    +label: string,
    +group: string,
  |}>
|};
export type getListMacrosQuery = {|
  variables: getListMacrosQueryVariables,
  response: getListMacrosQueryResponse,
|};
*/


/*
query getListMacrosQuery(
  $propertyId: ID!
) {
  macros(propertyId: $propertyId) {
    name
    label
    group
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "propertyId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "propertyId",
    "variableName": "propertyId"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "label",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "group",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getListMacrosQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "macros",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "Macro",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "getListMacrosQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "macros",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "Macro",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "getListMacrosQuery",
    "id": null,
    "text": "query getListMacrosQuery(\n  $propertyId: ID!\n) {\n  macros(propertyId: $propertyId) {\n    name\n    label\n    group\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '885953dc2f37497db97f3b407f8a3384';
module.exports = node;
