import graphql from "babel-plugin-relay/macro";

export default graphql`
  query getHayleyDefaultQuery($propertyId: ID!) {
    hayleyDefault(propertyId: $propertyId) {
      id
      rentOrg
      iconUrl
      widgetTitle
      chatbotTitle
      welcomeMessage
      animationStyle
      firstMessage
    }
  }
`;
