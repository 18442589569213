import sharePopup from "../sharePreview/embeded-code";
var emailRegexp =
  /^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

// let url = document.getElementById("campaignId").value
// let id = document.getElementById("endpoint").value

// let query = 'mutation{residentEnrollHyreferralCampaign(input:{ email:+'user_email'+,id:' + id + '}){ enrollment{ email id }}}'

let newLineSymbol = "\n";
export default (data) => `

<meta property="og:url"  content="http://www.nytimes.com/2015/02/19/arts/international/when-great-minds-dont-think-alike.html" />
<meta property="og:type" content="article" />
<meta property="og:title" content="When Great Minds Don’t Think Alike" />
<meta property="og:description" content="How much does culture influence creative thinking?" />
<meta property="og:image" content="http://static01.nyt.com/images/2015/02/19/arts/international/19iht-btnumbers19A/19iht-btnumbers19A-facebookJumbo-v2.jpg" />

<style>
@import url("https://fonts.googleapis.com/css?family=Raleway");
@import url("https://fonts.googleapis.com/css?family=Open+Sans");

input:focus {outline:0;} 
button:focus {outline:0;} 
a{
   color: ${data.orgColors ? data.orgColors.linkColor : "#27bbed"} !important;
}
.hyraf-enroll-form{
  max-width:500px;
  width:100%;
  margin: auto;
  background: white;
  margin-top:140px;
}
.hyraf-enroll-form-header{
  height: 80px;
  background:${data.orgColors ? data.orgColors.buttonBackgroundColor : "#27bbed"};
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  color:${data.orgColors ? data.orgColors.buttonFontColor : "white"};
  border:${data.orgColors ? data.orgColors.buttonBorderColor : "#27bbed"};
}
.hyraf-enroll-form-header span{
  font-size: 24px;
  color: white;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
}
.hyraf-enroll-form-header .close-btn{
  background:transparent;
  border:none;
  font-size: 30px;
  color:white;
  font-weight: 100;
  position: absolute;
  right: 10px;
  top:10px;
}


.hyraf-enroll-form .content{
  padding:20px;
  border: 1px solid #DADADA;
  border-top:none;
}

.hyraf-enroll-form .content .content-heading{
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #333333;
  padding-top:8px;
}

.hyraf-enroll-form .content .content-subheading{
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  margin-top:28px;
}

.hyraf-enroll-form .content .content-label{
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: rgba(51, 51, 51, 0.75);
  margin-top:40px;
}

.hyraf-enroll-form .content input{
  border: none;
  border-bottom: 1px solid #dadada;
  width: 100%;
  margin-top: 16px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
}

.hyraf-enroll-form .content input:hover{
  border-bottom: 1px solid var(--hylyTheme);
}

.hyraf-enroll-form .content input:focus{
  border-bottom: 1px solid #999999;
}

.hyraf-enroll-form .content .submit-btn{
  width: 240px;
  height: 48px;
  background:${data.orgColors ? data.orgColors.buttonBackgroundColor : "#27bbed"};
  color:${data.orgColors ? data.orgColors.buttonFontColor : "white"};
  border:${data.orgColors ? data.orgColors.buttonBorderColor : "#27bbed"};
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  cursor:pointer;
}
.hyraf-enroll-form .footer{
  text-align: right;
  margin-top: 24px;
}

@media only screen and (max-width: 480px) {
  .hyraf-enroll-form-header{
    height: 60px;
  }
  .hyraf-enroll-form-header span{
    font-size: 16px;
  }
  .hyraf-enroll-form-header .close-btn{
    right: 0px;
    font-size:14px;
  }
  .hyraf-enroll-form .content .content-heading{
    font-size: 16px;
    line-height: 20px;
  }
  .hyraf-enroll-form .footer{
    text-align: center;
  }
  #snackbar {
    width:315px !important;
    margin-left:-160px !important;
  }
}
#snackbar {
  visibility: hidden;
  margin-left: -250px;
  background: #E75F5F;
  box-shadow: 0px 4px 4px rgba(204, 204, 204, 0.25);
  color: #fff;
  text-align: center;
  border-radius: 2px;
  position: fixed;
  z-index: 1;
  left: 50%;
  top: 30px;
  font-size: 14px;
  width:500px;
  font-weight:600;
  color:#FFFFFF;
  line-height:20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height:64px;
}

#snackbarText {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  width:427px;
  text-align:left;
  padding-left:12px;
}

#snackbar.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 4.6s;
  animation: fadein 0.5s, fadeout 0.5s 4.6s;
}


#snackbar.hide {
  visibility: hidden;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 4.6s;
  animation: fadein 0.5s, fadeout 0.5s 4.6s;
}


@-webkit-keyframes fadein {
  from {top: 0; opacity: 0;} 
  to {top: 30px; opacity: 1;}
}

@keyframes fadein {
  from {top: 0; opacity: 0;}
  to {top: 30px; opacity: 1;}
}

@-webkit-keyframes fadeout {
  from {top: 30px; opacity: 1;} 
  to {top: 0; opacity: 0;}
}

@keyframes fadeout {
  from {top: 30px; opacity: 1;}
  to {top: 0; opacity: 0;}
}
#mdiv {
  width: 15px;
  height: 15px;
  margin-right: 20px;
}

#mdiv:hover {
 cursor: pointer;
}

.mdiv {
  height: 15px;
  width: 1px;
  margin-left: 12px;
  background-color: white;
  transform: rotate(45deg);
  Z-index: 1;
}

.md {
  height: 15px;
  width: 1px;
  background-color: white;
  transform: rotate(90deg);
  Z-index: 2;
}
</style>

<script>

window.onload = function(){
  let element = document.getElementById("enroll-email-input");
  if(element) {
    onEnrollInputChange(element.value);
  }
  if(${data.enrollPath}){
    document.getElementById("btn-close").style.display='none';
  }
};

 
  var user_email = "";
  function isValidEnrollEmail(email) {
    if (!email) return false;
    let _emailParts = email.split('@');

    if(_emailParts.length !== 2) return false

    let _account = _emailParts[0];
    let address = _emailParts[1];

    if(_account.length > 64) return false

    else if(address.length > 255) return false

    let domainParts = address.split('.');
    if (domainParts.some(function (part) {
      return part.length > 63;
    })) return false;


    if (!${emailRegexp}.test(email)) return false;

    return true;
  };

  let enrolled=${data.verified}

  function openEnrollForm(){
    let enrollPopup = document.getElementById("enroll-popup");
    let enrollForm = document.getElementById("enroll-form");
    if(enrollPopup){
      enrollPopup.style.display = "block";
      enrollPopup.style.background = "rgba(27, 27, 27, 0.71)"
    }
    if(enrollForm && !enrolled){
      enrollForm.style.display = "block";
    }
    toggleSubmitDisbale();
  }

  function closeEnrollForm(){
    let enrollPopup = document.getElementById("enroll-popup");
    let enrollForm = document.getElementById("enroll-form");
    if(enrollPopup){
      enrollPopup.style.display = "none";
      enrollPopup.style.background = "transparent"
    }
    if(enrollForm){
      enrollForm.style.display = "none";
    }
  }

  function doEnroll(){
    let url = document.getElementById('endpoint').value;
    let id = document.getElementById('campaignId').value;
    let options = {
       headers: { 'Content-Type': 'application/json' }
    };

    let query = 'mutation{ residentEnrollHyreferralCampaign(input:{email:"'+user_email+'", id:"'+id+'"}){ enrollment{ id email referralCode referralLink shortTourUrl hyrafInviteId inviteEmailUrl inviteFacebookUrl inviteTwitterUrl }}}'
    
    options.body = JSON.stringify({ query });
    options.method = 'POST';
    let btn = document.getElementById("enroll-form-now");
    btn.disabled = true;
    btn.style.opacity = 0.5;
    fetch(url,options).then(res => res.json())
    .then(res => {
      document.getElementById("enroll-email-input").value = "";
      if(res.errors){
        var propertyName=document.getElementById("propertyName").value;
        if(res.errors[0]&&res.errors[0].message){
          showToast("We couldn't identify you as "+propertyName+" resident. Please provide the email you have given on your lease")
        }
      }
      else{
        enrolled=true
        var links=res.data.residentEnrollHyreferralCampaign.enrollment;
        var child = document.createElement("a");
        child.setAttribute('href', links.inviteTwitterUrl);
        child.innerHTML = links.inviteTwitterUrl;
        child.target = "_blank";
        var childFb = document.createElement("a");
        childFb.setAttribute('href', links.inviteFacebookUrl);
        childFb.innerHTML = links.inviteFacebookUrl;
        childFb.target = "_blank";
        document.getElementById("twiteer-message-content").appendChild(child);
        document.getElementById("fb-message-content").appendChild(childFb);
        document.getElementById("referralLink").innerHTML = links.referralLink;
        document.getElementById("referralLink2").innerHTML = links.referralLink;
        document.getElementById("enroll-form").style.display = "none";
        document.getElementById("main-sharemenu").style.display = "block";
        localStorage.setItem('enrollData', JSON.stringify(links));
        var count = 0;
        var getActiveShare;
        if (${data.emailEnabled}) {
          count++;
          getActiveShare = "EmailButton";
        }
        if (${data.smsEnabled}) {
          count++;
          getActiveShare = "SmsButton";
        }
        if (${data.facebookEnabled}) {
          count++;
          getActiveShare = "FbButton";
        }
        if (${data.twitterEnabled}) {
          count++;
          getActiveShare = "TwitterButton";
        }
        if (count === 1) {
          document.getElementById(getActiveShare).click();
        }
      }
    })
    .catch (err => {
  console.log("enrollment error :", err)
  document.getElementById("enroll-email-input").value = "";
  btn.disabled = false;
  btn.style.opacity = 1;
  showToast("Something went wrong");
})
  }

function onEnrollInputChange(value) {
  user_email = value;
  toggleSubmitDisbale();
}

function toggleSubmitDisbale() {
  let btn = document.getElementById("enroll-form-now");
  btn.disabled = !isValidEnrollEmail(user_email);
  btn.style.opacity = isValidEnrollEmail(user_email) ? 1 : 0.5;
}

function showToast(text) {
  var x = document.getElementById("snackbar");
  var y = document.getElementById("snackbarText");
  y.innerHTML = text;
  x.className = "show";
  setTimeout(function () { x.className = x.className.replace("show", "hide"); }, 5000)
}

function onClose() {
  var x = document.getElementById("snackbar");
  x.className = "hide";
}
 
</script >

  <div id="enroll-form" class="hyraf-enroll-form">
    <div id="snackbar">
      <div id="snackbarText">
      </div>
      <div id="mdiv" onclick="onClose()">
        <div class="mdiv">
          <div class="md"></div>
        </div>
      </div>
    </div>
    <div class="hyraf-enroll-form-header">
      <span>${data.residentBannerTitle}</span>
      <button class="close-btn" id="btn-close" onclick=${data.close ? "closeEnrollForm()" : ""}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19 5L5 19" stroke="white" stroke-miterlimit="10" stroke-linecap="square" />
          <path d="M19 19L5 5" stroke="white" stroke-miterlimit="10" stroke-linecap="square" />
        </svg>
      </button>
    </div>
    <div class="content">
      <div class="content-heading"> ${data.residentEnrollHeader} </div>
      <div class="content-subheading"> ${data.pitchMessage} </div>
      <div class="content-label"> ${data.residentEnrollFooter} </div>
      <input value=${data.preEmail || "''"} placeholder="${
  !data.emailValidationStatus ? "<Email>" : "<Email on your Lease>"
}" id="enroll-email-input" type="text" oninput="onEnrollInputChange(this.value)" ${
  data.preview && "disabled"
} />
      <div class="footer">
        <button id="enroll-form-now" disabled="true" style="opacity:0.5" class="submit-btn" onclick=${
          data.preview ? "" : "doEnroll()"
        }>Enroll Now</button>
      </div>
      <input id="endpoint" value="${data.endpoint}" type="hidden" />
      <input id="campaignId" value="${data.id}" type="hidden" />
      <input id="propertyName" value="${data.propertyName}" type="hidden" />

    </div>
  </div>


${sharePopup(data)}

<script>
  if(document.getElementById("main-sharemenu") && !enrolled){
    document.getElementById("main-sharemenu").style.display = "none"
    if(${!data.preview}){
      document.getElementById("enroll-form").style.display = "none"
    }
  }

 
  // document.getElementById("main-sharemenu").style.display = "block"
  // document.getElementById("enroll-form").style.display = "none"
</script>
`;
