import React from "react";

export default function PencilWithStrokeIcon({ className = "text-[#333333]", size = 24 }) {
  return (
    <svg
      className={className}
      width={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="white" />
      <path d="M13.3573 7.72412L16.274 10.6408" stroke="currentColor" strokeMiterlimit="10" />
      <path
        d="M9.6667 17.2498L6.16663 17.8332L6.74997 14.3331L14.575 6.5081C14.7938 6.28937 15.0905 6.1665 15.3998 6.1665C15.7092 6.1665 16.0059 6.28937 16.2247 6.5081L17.4917 7.77512C17.7104 7.99391 17.8333 8.29061 17.8333 8.59997C17.8333 8.90934 17.7104 9.20604 17.4917 9.42482L9.6667 17.2498Z"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  );
}
