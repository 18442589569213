import graphql from "babel-plugin-relay/macro";

export default graphql`
  query getProfileQuery($id: ID) {
    profile(id: $id) {
      email
      firstName
      id
      initials
      lastName
      avatarUrl
    }
  }
`;
