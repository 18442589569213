import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { setHideHeaderCopyLink, setShowHeaderCopyLink } from "redux/slices/showHeaderIconsSlice";

const useCopyHeaderLink = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setShowHeaderCopyLink());

    return () => {
      dispatch(setHideHeaderCopyLink());
    };
  }, []);
};

export default useCopyHeaderLink;
