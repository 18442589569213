import graphql from "babel-plugin-relay/macro";

export default graphql`
  mutation updateSelfGuidedTourMutation($input: updateSelfGuidedTourUrlInput!) {
    updateSelfGuidedTourUrl(input: $input) {
      response {
        selfGuidedTourUrl
      }
    }
  }
`;
