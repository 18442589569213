import graphql from "babel-plugin-relay/macro";

export default graphql`
  query getMJAIEditorPreviewQuery(
    $id: ID!
    $fields: Json
    $subject: String
    $sliceOrder: String
    $isVariant: String
  ) {
    thenEmailEditorNew(
      id: $id
      fields: $fields
      subject: $subject
      sliceOrder: $sliceOrder
      isVariant: $isVariant
    ) {
      angel {
        name
      }
      name
      subject
      active
      mms
      mmsBody
      mmsMediaUrl
      mmsStatus
      lastTestEmails
      lastTestPhones
      fields {
        editable
        label
        field
        dimension
        value
        type
        display
        sliceId
        sliceName
        elementId
        elementPart
      }
      rawMacrosHtml
      substitutedMacrosHtml
    }
  }
`;
