import React from "react";

export default function ImportIcon({ className = "text-[#333]", width = 18, height = 18 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      width={width}
      className={className}
      fill="none"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M6.719 8.177L10 4.531l3.281 3.646h-2.187v4.375H8.906V8.177H6.72z"
      ></path>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M15.469 11.824v2.916a.73.73 0 01-.73.73H5.26a.73.73 0 01-.729-.73v-2.916"
      ></path>
    </svg>
  );
}
