/**
 * @flow
 * @relayHash aed8f56dde7f797634f96c1cc48ae0e8
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getTemplatesQueryVariables = {|
  propertyId: string,
  type?: ?string,
|};
export type getTemplatesQueryResponse = {|
  +categories: $ReadOnlyArray<{|
    +id: string,
    +name: string,
    +createdAt: any,
    +type: string,
    +hasMore: boolean,
    +templates: $ReadOnlyArray<{|
      +id: string,
      +name: string,
      +createdAt: any,
      +largeThumbnailUrl: ?string,
      +smallThumbnailUrl: ?string,
    |}>,
  |}>
|};
export type getTemplatesQuery = {|
  variables: getTemplatesQueryVariables,
  response: getTemplatesQueryResponse,
|};
*/


/*
query getTemplatesQuery(
  $propertyId: ID!
  $type: String
) {
  categories(propertyId: $propertyId, type: $type) {
    id
    name
    createdAt
    type
    hasMore
    templates {
      id
      name
      createdAt
      largeThumbnailUrl
      smallThumbnailUrl
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "propertyId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "type",
    "type": "String",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "createdAt",
  "args": null,
  "storageKey": null
},
v4 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "categories",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "propertyId",
        "variableName": "propertyId"
      },
      {
        "kind": "Variable",
        "name": "type",
        "variableName": "type"
      }
    ],
    "concreteType": "Category",
    "plural": true,
    "selections": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "type",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "hasMore",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "templates",
        "storageKey": null,
        "args": null,
        "concreteType": "Template",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "largeThumbnailUrl",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "smallThumbnailUrl",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getTemplatesQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v4/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "getTemplatesQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v4/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "getTemplatesQuery",
    "id": null,
    "text": "query getTemplatesQuery(\n  $propertyId: ID!\n  $type: String\n) {\n  categories(propertyId: $propertyId, type: $type) {\n    id\n    name\n    createdAt\n    type\n    hasMore\n    templates {\n      id\n      name\n      createdAt\n      largeThumbnailUrl\n      smallThumbnailUrl\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8cc5ff7e80e47b0e3712725684d6c6c8';
module.exports = node;
