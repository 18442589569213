import graphql from "babel-plugin-relay/macro";

export default graphql`
  query getReportDashboardsQuery(
    $orgId: ID!
    $propertyId: ID
    $type: String
    $name: String) {
    reportDashboards(
      orgId:$orgId
      propertyId:$propertyId
      type:$type
      name:$name
    ) {
      id
    }
  }
`;
