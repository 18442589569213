import React from "react";

export default function CreateTaskIcon({ className = "text-[#999999]", width = 18, height = 18 }) {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1683_38338)">
        <path
          d="M12.375 4.125H13.125C13.3239 4.125 13.5147 4.20402 13.6553 4.34467C13.796 4.48532 13.875 4.67609 13.875 4.875V13.875C13.875 14.0739 13.796 14.2647 13.6553 14.4053C13.5147 14.546 13.3239 14.625 13.125 14.625H4.875C4.67609 14.625 4.48532 14.546 4.34467 14.4053C4.20402 14.2647 4.125 14.0739 4.125 13.875V4.875C4.125 4.67609 4.20402 4.48532 4.34467 4.34467C4.48532 4.20402 4.67609 4.125 4.875 4.125H5.625"
          stroke="currentColor"
          stroke-linecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.875 3.375H7.125V4.875H10.875V3.375Z"
          stroke="currentColor"
          stroke-linecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.75 9.75L8.25 11.25L11.25 8.25"
          stroke="currentColor"
          stroke-linecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1683_38338">
          <rect width="12" height="12" fill="white" transform="translate(3 3)" />
        </clipPath>
      </defs>
    </svg>
  );
}
