import React from "react";
import packageJson from "../package.json";
import { checkVersionIsLatest } from "services";
import { clearAllStorage, setNotificationId, setBanner, setVersion, getVersion } from "services";
global.appVersion = packageJson.version;

class CacheBuster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isLatestVersion: false,
      refreshCacheAndReload: () => {
        if (caches) {
          caches.keys().then(async function (names) {
            await Promise.all(names.map((name) => caches.delete(name)));
          });
        }
        this.unregisterService();
      },
    };
    this.reload = true;
  }

  handleLocalStorage = () => {
    clearAllStorage();
    setNotificationId(1);
    setBanner();
    return true;
  };

  async componentDidMount() {
    await this.handleLocalStorage();
    fetch("/meta.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      cache: "no-store",
    })
      .then((response) => response.json())
      .then((meta) => {
        const latestVersion = meta.version;
        const currentVersion = getVersion() ? getVersion().old : global.appVersion;
        const shouldForceRefresh = checkVersionIsLatest(latestVersion, currentVersion);
        if (shouldForceRefresh) {
          setVersion(latestVersion, currentVersion);
          this.setState({ loading: false, isLatestVersion: false });
        } else {
          this.setState({ loading: false, isLatestVersion: true });
        }
      });
  }

  unregisterService = () => {
    var that = this;
    navigator.serviceWorker
      .getRegistrations()
      .then((registrations) => {
        for (let registration of registrations) {
          registration.unregister();
          if (that.reload) {
            //window.location.reload(true);
          } else {
            that.reload = false;
          }
        }
      })
      .catch((err) => {
        console.log("cache bustor error : ", err);
      });
  };

  render() {
    const { loading, isLatestVersion, refreshCacheAndReload } = this.state;
    return this.props.children({
      loading,
      isLatestVersion,
      refreshCacheAndReload,
    });
  }
}

export default CacheBuster;
