import graphql from "babel-plugin-relay/macro";

export default graphql`
  query getContactFieldsQuery($propertyId: ID!) {
    contactFields(propertyId: $propertyId) {
      fieldId
      fieldLabel
      options
      id
    }
  }
`;
