import React from "react";
import withStyles from "@mui/styles/withStyles";
import Dialog from "@mui/material/Dialog";
import MuiDialogTitle from "@mui/material/DialogTitle";
import MuiDialogContent from "@mui/material/DialogContent";
import MuiDialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: 20,
  },
  closeButton: {
    position: "absolute",
    right: 10,
    top: 10,
    color: "#000",
    "&:hover": {
      color: "var(--hylyTheme)",
    },
  },
  dialogRoot: {
    borderRadius: 0,
    width: 500,
    background: "white",
  },
  heading: {
    fontFamily: "Open Sans",
    fontSize: 20,
    lineHeight: "30px",
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, className, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h5" className={classes.heading}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: "0px 20px 0px 20px",
    borderTop: "1px solid #dadada",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: "24px 20px",
  },
}))(MuiDialogActions);

const CustomizedModal = withStyles(styles)(
  ({
    open,
    maxWidth = "md",
    title = "Modal Title",
    children,
    handleClose,
    actionChildren,
    classes,
    className,
  }) => {
    return (
      <Dialog
        onClose={handleClose}
        open={open}
        maxWidth={maxWidth}
        fullWidth
        PaperProps={{
          classes: {
            rounded: className && className.dialogRoot ? className.dialogRoot : classes.dialogRoot,
          },
        }}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {title}
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
        {actionChildren && <DialogActions>{actionChildren}</DialogActions>}
      </Dialog>
    );
  }
);

export default CustomizedModal;
