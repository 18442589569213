import React, { Suspense, lazy } from "react";
import { getAppName, getAccessDetails } from "common";
import { _getApps } from "components/AppSwitcher/apis";
import Loader from "storybookComponents/Loader";
import { getAppPath } from "constants/path";
import { emitCustomEvent } from "react-custom-events";
import { EVENT_CODES } from "constants/index";
import { getSuborgDetails } from "redux/slices";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import OrgLevelSwitchModal from "components/Modal/OrgLevelSwitchModal";
const Slides = lazy(() => import("components/SuccessCenterSlides"));
const AppContent = lazy(() => import("./appContent"));

const DefaultRoute = React.memo(
  ({
    authorizedDetails,
    apps,
    orgName,
    appsLoading,
    isRentOrg,
    fetchOrgSuborgData,
    showOrgPropertySwitchConfirmModal,
    setShowOrgPropertySwitchConfirmModal,
    selectedNavItem4Replace,
    onSwitchLevel,
    handleOpenInNewTab,
    ...props
  }) => {
    const subOrgDetails = useSelector(getSuborgDetails);
    const { pathname, state } = props.history.location;
    const [appName, setAppName] = React.useState("home");
    const [loading, setLoading] = React.useState(true);
    const [authorized, setAuthorized] = React.useState(false);

    const history = useHistory();

    React.useEffect(() => {
      setAppName(state?.moduleName ? state?.moduleName : getAppName(pathname));
    }, [pathname, state?.moduleName]);

    const checkIfAccessible = async () => {
      try {
        if (authorizedDetails) {
          await setLoading(true);
          let accessType = await getAccessDetails(appName, authorizedDetails);
          if (accessType === "premium") {
            setAuthorized(false);
          } else if (accessType === "unauthorised") {
            emitCustomEvent(EVENT_CODES.UNAUTH_USER, appName.toLowerCase());
          } else {
            setAuthorized(true);
          }
          setLoading(false);
        }
      } catch (err) {
        console.log("Err", err);
      }
    };

    const handleClose = () => {
      props.history.push(getAppPath() + "/home");
    };

    React.useEffect(() => {
      checkIfAccessible();
    }, [appName, authorizedDetails]);

    const isHome = history.location.pathname.endsWith(getAppPath() + "/home");

    if (((loading || appsLoading) && !isHome) || !apps?.length) {
      return <Loader />;
    }

    return (
      <Suspense fallback={<Loader />}>
        {appName !== "dashboard" ? (
          <Slides showSlides={!authorized} appName={appName} handleCloseCallBack={handleClose} />
        ) : null}
        <AppContent
          {...props}
          apps={apps}
          orgName={orgName}
          authorizedDetails={authorizedDetails}
          authorized={authorized}
          subOrgDetails={subOrgDetails}
          appName={appName}
          appsLoading={appsLoading}
          isRentOrg={isRentOrg}
          fetchOrgSuborgData={fetchOrgSuborgData}
        />
        <OrgLevelSwitchModal
          open={showOrgPropertySwitchConfirmModal}
          handleClose={() => setShowOrgPropertySwitchConfirmModal(false)}
          selectedItem={selectedNavItem4Replace}
          onSwitch={onSwitchLevel}
          handleOpenInNewTab={handleOpenInNewTab}
        />
      </Suspense>
    );
  }
);

export default DefaultRoute;
