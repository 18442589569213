export const popupHtml = (props) => {
  return `<style type="text/css">
    body {
      margin: 0 0 0 0;
      -webkit-text-size-adjust: none;
      font-family: Open Sans, sans-serif !important
    }

    .super {
      // padding: 20px;
      width:640px;
    }

    .container {
      position: relative;
      padding:0px;
    }

    .cross-container {
      height: 48px;
      width: 48px;
      border-radius: 24px;
      border: 1px solid #dadada;
      position: absolute;
      top: -18px;
      right: -11px;
      text-align: center;
      background: white;
      font-size: 30px;
    }

    .feature-image {
      display: ${props.image && props.image !== "disabled" ? "grid" : "none"};
    }

    .image-style {
      width: 100%;
      height: auto;
    }

    .mid-container {
      padding: 20px !important;
      padding-bottom: 0px !important;
      text-align: center;
      border: 1px solid #dadada;
      background:white;
      display:${(!props.offerCtaText && !props.title) ? "none" : ""}

    }

    .message {
      font-weight: bold;
      font-size: 24px;
      text-align: left;
      margin-bottom: 20px !important;
      word-break: break-word;
      display:${(props.title) ? "block" : "none"};
      margin-bottom:16px !important;
    }

    .subtitle{
      font-size:14px;
      text-align: left;
      line-height:20px;
      color:#333333;
      opacity:0.7;
      display:${(props.subTitle) ? "block" : "none"};
      margin-bottom:16px !important;
      word-break:break-word;
    }

    .message p {
      margin: 0px;
      padding: 0px;
    }

    a {
      text-decoration: none;
    }

    .btn {
      width: 200px;
      min-height: 48px;
      line-height: 24px;
      font-size: 16px;
      font-weight: 600;
      text-transform: capitalize;
      text-decoration: none;
      margin: auto;
      outline: none;
      word-break:break-word;
      cursor: pointer;
      font-size: 16px;
      margin-bottom: 32px !important;
      border-radius:0px;
    }

    .refer-btn-1 {
       background:${props.orgColors ? props.orgColors.buttonBackgroundColor : "#27bbed"};
      color:${props.orgColors ? props.orgColors.buttonFontColor : "white"};
      border-color:${props.orgColors ? props.orgColors.buttonBorderColor : "white"};
      margin-right: 8px;
      display: block;
      border: none;
      display:${props.offerCtaText ? "block" : "none"};
      width:-webkit-fill-available;
    }
    .btn:focus{
      box-shadow:none;
    }

     .refer-btn-1:hover {
        color:${props.orgColors ? props.orgColors.buttonFontColor : "white"};
     }



    @media only screen and (max-width: 350px) {
      .message {
        font-size: 20px !important;
      }
      .refer-btn-1 {
        margin: auto;
      }
      .refer-btn-2 {
        margin: auto;
      }
      .btn {
        width: 250px;
        min-height:48px;
        margin-bottom: 16px !important;
        font-size:16px;
      }

      .super{
        text-align: center;
        padding: 16px;
        position: absolute;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        min-width: -webkit-fill-available;
        width:auto;
      }
     .cross-container{
        height: 20px;
        width:20px;
        border-radius:10px;
        top: -12px;
        right: -12px;
        font-size:15px;
      }
    }
  </style>
    <div class="super">
      <div class="container">
        <div class="cross-container">
          <span>&times;</span>
        </div>
        <div class="feature-image">
          <img
            src="${props.image}"
            alt="banner" class="image-style" />
        </div>

        <div class="mid-container">
          <div class="message">${props.title}</div>
           <div class="subtitle">${props.subTitle}</div>
            <a  href="${props.offer_cta_url}"  target="_blank">
              <button class="btn refer-btn-1" type="button">
                ${props.offerCtaText}
              </button>
            </a>
        </div>
      </div>
    </div>`;
};
