/**
 * @flow
 * @relayHash 8314bd572310beb841972de1fe298cbf
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type retrieveAutofillFaqsByJobIdQueryVariables = {|
  jobId: string
|};
export type retrieveAutofillFaqsByJobIdQueryResponse = {|
  +retrieveAutofillFaqsByJobId: ?any
|};
export type retrieveAutofillFaqsByJobIdQuery = {|
  variables: retrieveAutofillFaqsByJobIdQueryVariables,
  response: retrieveAutofillFaqsByJobIdQueryResponse,
|};
*/


/*
query retrieveAutofillFaqsByJobIdQuery(
  $jobId: String!
) {
  retrieveAutofillFaqsByJobId(jobId: $jobId)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "jobId",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "retrieveAutofillFaqsByJobId",
    "args": [
      {
        "kind": "Variable",
        "name": "jobId",
        "variableName": "jobId"
      }
    ],
    "storageKey": null
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "retrieveAutofillFaqsByJobIdQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "retrieveAutofillFaqsByJobIdQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "retrieveAutofillFaqsByJobIdQuery",
    "id": null,
    "text": "query retrieveAutofillFaqsByJobIdQuery(\n  $jobId: String!\n) {\n  retrieveAutofillFaqsByJobId(jobId: $jobId)\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'fca4b180055fc90fa02cb63d8026076d';
module.exports = node;
