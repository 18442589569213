/**
 * @flow
 * @relayHash dbc590d4e192a44cdd4336001131976f
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getCampaignUtmCodeQueryVariables = {|
  id: string
|};
export type getCampaignUtmCodeQueryResponse = {|
  +campaignUtmCodeUrls: {|
    +directLink: ?string,
    +portalBannerLink: ?string,
  |}
|};
export type getCampaignUtmCodeQuery = {|
  variables: getCampaignUtmCodeQueryVariables,
  response: getCampaignUtmCodeQueryResponse,
|};
*/


/*
query getCampaignUtmCodeQuery(
  $id: ID!
) {
  campaignUtmCodeUrls(campaignId: $id) {
    directLink
    portalBannerLink
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "campaignId",
    "variableName": "id"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "directLink",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "portalBannerLink",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getCampaignUtmCodeQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "campaignUtmCodeUrls",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CampaignUtmCodeUrl",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "getCampaignUtmCodeQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "campaignUtmCodeUrls",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CampaignUtmCodeUrl",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "getCampaignUtmCodeQuery",
    "id": null,
    "text": "query getCampaignUtmCodeQuery(\n  $id: ID!\n) {\n  campaignUtmCodeUrls(campaignId: $id) {\n    directLink\n    portalBannerLink\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f8d40ec55569e812e982078c258442c9';
module.exports = node;
