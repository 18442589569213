import graphql from "babel-plugin-relay/macro";

export default graphql`
  query importLogsQuery($propertyId: ID!, $subStatus: String) {
    importLogs(propertyId: $propertyId, subStatus: $subStatus) {
      edges {
        node {
          id
          createdAt
          fileName
          importedAt
          importedBy
          totalRows
          successCount
          failureCount
          performance
          contactsBeforeLoad
          contactsAfterLoad
        }
      }
    }
  }
`;
