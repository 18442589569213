import { fetchQuery } from "relay-runtime";
import environment from "environments/environment";
import getOffersQuery from "./queries/getOffers";
import getPropertyDataQuery from "./queries/getPropertyData";
import getPropertyDataForPreviewQuery from "./queries/getPropertyDataForPreview";
import getPropertyOfferListQuery from "./queries/getPropertyOfferList";
import toggleOffer from "./mutations/toggleOffer";
import createOffer from "./mutations/createOffer";
import updateOffer from "./mutations/updateOffer";
import deleteOffer from "./mutations/deleteOffer";
import resetOffer from "./mutations/resetOffer";
import updatePropertyData from "./mutations/updatePropertyData";
import uploadOfferCsv from "./mutations/uploadOfferCsv";
import importOfferCsv from "./mutations/importOfferCsv";
import testInviteOffer from "./mutations/testInviteOffer";
import scheduleProspectInviteOffers from "./mutations/scheduleProspectInviteOffers";
import duplicateOffer from "./mutations/duplicateOffer";
import duplicatePropertyData from "./mutations/duplicatePropertyData";
import saveReport from "./mutations/saveReport";
import getOfferQuery from "./queries/getOffer";
import getOrgOverviewQuery from "./queries/getOrgOverview";
import getOfferByPropertiesQuery from "./queries/getOfferByProperties";
import getOfferDataQuery from "./queries/getOfferData";
import getSavedReportsQuery from "./queries/getSavedReports";
import getOfferContactListQuery from "./queries/getOfferContactList";
import getExportDashboardQuery from "./queries/getExportDashboard";
import getListPublicOffersQuery from "./queries/getListPublicOffers";
import getOfferEmailQuery from "./queries/getOfferEmail";

export const getOffer = (id) => {
  let variables = { id };
  return fetchQuery(environment, getOfferQuery, variables)
    .then((res) => {
      return res.offer;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

export const getOffers = (id, last, before) => {
  let variables = { id, last, before };
  return fetchQuery(environment, getOffersQuery, variables)
    .then((res) => {
      return res.offers;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

export const getPropertyData = (
  id,
  offerId,
  propertyIds,
  campaignIds,
  startAt,
  endAt,
  concession,
  after,
  before,
  first,
  last
) => {
  let variables = {
    id,
    offerId,
    propertyIds,
    campaignIds,
    startAt,
    endAt,
    concession,
    after,
    before,
    first,
    last,
  };
  return fetchQuery(environment, getPropertyDataQuery, variables)
    .then((res) => {
      return res.propertyData;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

export const getPropertyDataForPreview = (offerId, concession) => {
  let variables = { offerId, concession };
  return fetchQuery(environment, getPropertyDataForPreviewQuery, variables)
    .then((res) => {
      return res.propertyData;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

export const getPropertyOfferList = (id) => {
  let variables = { id };
  return fetchQuery(environment, getPropertyOfferListQuery, variables)
    .then((res) => {
      return res.propertyOfferList;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

export const getOrgOverview = (orgId, propertyIds, campaignIds, startAt, endAt, concession) => {
  let variables = { orgId, propertyIds, campaignIds, startAt, endAt, concession };
  return fetchQuery(environment, getOrgOverviewQuery, variables)
    .then((res) => {
      return res.orgOverview;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

export const getOfferByProperties = (orgId, propertyIds, startAt, endAt, concession) => {
  let variables = { orgId, propertyIds, startAt, endAt, concession };
  return fetchQuery(environment, getOfferByPropertiesQuery, variables)
    .then((res) => {
      return res.offerByProperties;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

export const getOfferData = (offerId) => {
  let variables = { offerId };
  return fetchQuery(environment, getOfferDataQuery, variables)
    .then((res) => {
      return res.offerData;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

export const getSavedReports = (propertyId, type) => {
  let variables = { propertyId, type };
  return fetchQuery(environment, getSavedReportsQuery, variables)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

export const getOfferContactList = (
  orgId,
  propertyIds,
  campaignIds,
  startAt,
  endAt,
  concession,
  status
) => {
  let variables = { orgId, propertyIds, campaignIds, startAt, endAt, concession, status };
  return fetchQuery(environment, getOfferContactListQuery, variables)
    .then((res) => {
      return res.offerContactList;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

export const getExportDashboard = (dashboardId, type) => {
  let variables = { dashboardId, type };
  return fetchQuery(environment, getExportDashboardQuery, variables)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

export const getListPublicOffers = (offerId, propertyIds) => {
  let variables = { offerId, propertyIds };
  return fetchQuery(environment, getListPublicOffersQuery, variables)
    .then((res) => {
      return res.listPublicOffers;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

export const getOfferEmail = (id, propertyId, offerSettingId) => {
  let variables = { id, propertyId, offerSettingId };
  return fetchQuery(environment, getOfferEmailQuery, variables)
    .then((res) => {
      return res.offer;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

export {
  toggleOffer,
  createOffer,
  updateOffer,
  deleteOffer,
  resetOffer,
  updatePropertyData,
  uploadOfferCsv,
  importOfferCsv,
  testInviteOffer,
  scheduleProspectInviteOffers,
  duplicateOffer,
  duplicatePropertyData,
  saveReport,
};
