import { fetchQuery } from "relay-runtime";
import environment from "environments/environment";
import gethyBlastOverviewQuery from "./queries/gethyblastOverview";
import gethyblastsQuery from "./queries/gethyblasts";
import gethyblastEditorPreviewQuery from "./queries/gethyblastEditorPreview";
import importLogsQuery from "./queries/importLogs";
import getTemplatesQuery from "./queries/getTemplates";
import getPurposesQuery from "./queries/getPurposes";
import getEditorQuery from "./queries/getEditor";
import gethyblastSettingQuery from "./queries/gethyblastSetting";
import gethyblastQuery from "./queries/gethyblast";
import getScheduledBlastQuery from "./queries/getScheduledBlast";
import getPreviewQuery from "./queries/getPreview";
import getExpressionCountQuery from "./queries/getExpressionCount";
import contactExpressionCountQuery from "./queries/contactExpressionCount";
import getContactFieldsQuery from "./queries/getContactFields";
import retrieveHyGptStatusQuery from "./queries/retrieveHyGptStatus";
import getTemplatePreviewQuery from "./queries/getTemplatePreview";
import testEblast from "./mutations/testEblast";
import feedbackStatusQuery from "./queries/getFeedbackStatus";
import getAuditSendsQuery from "./queries/getAuditSends";
import getSummarySendsQuery from "./queries/getSummarySends";
import getReportHistoryQuery from "./queries/getReportHistory";
import getCampaignListQuery from "./queries/getCampaignList";
import getReportDashboardsQuery from "./queries/getReportDashboards";
import getListPropertiesQuery from "./queries/getListProperties";
import getTenantOrCampaignStatQuery from "./queries/getTenantOrCampaignStat";
import getCampaignMetricsQuery from "./queries/getCampaignMetrics";
import getChartSeriesQuery from "./queries/getChartSeries";
import getEblastContactsDashboardQuery from "./queries/getEblastContactsDashboard";
import getDashboardMetricsQuery from "./queries/getDashboardMetrics";
import getCampaignListV2Query from "./queries/getCampaignListV2";
import getTenantOrCampaignStatTenantQuery from "./queries/getTenantOrCampaignStatTenant";
import getTenantOrCampaignStatCampaignQuery from "./queries/getTenantOrCampaignStatCampaign";
import getCampaignListForDropDownQuery from "./queries/getCampaignListForDropDown";
import getMailGptPromptsListQuery from "./queries/getMailGptPromptsList";
import getGptHistoryByJobIdQuery from "./queries/getGptHistoryByJobId";
import getGptHistoryByIdQuery from "./queries/getGptHistoryById";
import listMailGptBlastsQuery from "./queries/listMailGptBlasts";
import mailGptBlastQuery from "./queries/mailGptBlast";
import allOrgsGptBlastAnalysisQuery from "./queries/allOrgsGptBlastAnalysis";
import orgGptBlastAnalysisQuery from "./queries/orgGptBlastAnalysis";
import mailGptBlastTypeReportsQuery from "./queries/mailGptBlastTypeReports";
import allOrgsGptFieldsAnalysisQuery from "./queries/allOrgsGptFieldsAnalysis";
import mailGptPromptTemplatesQuery from "./queries/mailGptPromptTemplates";
import mailGptPromptPurposesQuery from "./queries/mailGptPromptPurposes";

const gethyblasts = (data) => {
  let variables = data;
  return fetchQuery(environment, gethyblastsQuery, variables)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

const gethyblastEditorPreview = (id) => {
  let variables = { id };
  return fetchQuery(environment, gethyblastEditorPreviewQuery, variables)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

const importLogs = (propertyId, subStatus) => {
  let variables = { propertyId };
  if (subStatus) {
    variables["subStatus"] = subStatus;
  }
  return fetchQuery(environment, importLogsQuery, variables);
};

const getTemplates = (variables) => fetchQuery(environment, getTemplatesQuery, variables);

const getPurposes = (variables) => fetchQuery(environment, getPurposesQuery, variables);

const getEditor = (id, appName) => fetchQuery(environment, getEditorQuery, { id, appName });

const gethyblastSetting = (orgId, propertyId) => {
  let variables = { orgId, propertyId };
  return fetchQuery(environment, gethyblastSettingQuery, variables)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

const getHyBlast = (variables) => fetchQuery(environment, gethyblastQuery, variables);

const getScheduledBlast = (variables) => fetchQuery(environment, getScheduledBlastQuery, variables);

const getPreview = (id, fields, subject) => {
  let variables = { id, fields, subject };
  return fetchQuery(environment, getPreviewQuery, variables)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

const getExpressionCount = (propertyId, expression) => {
  let variables = { propertyId, expression };
  return fetchQuery(environment, getExpressionCountQuery, variables)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

const contactExpressionCount = (propertyId, expression, recipient) => {
  let variables = { propertyId, expression, recipient };
  return fetchQuery(environment, contactExpressionCountQuery, variables);
};

const getContactFields = (propertyId) => {
  let variables = { propertyId };
  return fetchQuery(environment, getContactFieldsQuery, variables)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

const retrieveHyGptStatus = (tenantId) => {
  let variables = { tenantId };
  return fetchQuery(environment, retrieveHyGptStatusQuery, variables)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

const getTemplatePreview = (categoryId, propertyId, templateId) => {
  let variables = { categoryId, propertyId, templateId };
  return fetchQuery(environment, getTemplatePreviewQuery, variables)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

const getHyblastOverview = (propertyId) => {
  const variables = { propertyId };
  return fetchQuery(environment, gethyBlastOverviewQuery, variables)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

const feedbackStatus = (productName, featureName) => {
  let variables = { productName, featureName };
  return fetchQuery(environment, feedbackStatusQuery, variables);
};

const getAuditSends = ({ orgId, startDate, endDate, selectedPropertyIds, params, regionIds }) => {
  let variables = { orgId, startDate, endDate, selectedPropertyIds, params, regionIds };
  return fetchQuery(environment, getAuditSendsQuery, variables);
};
const getSummarySend = ({ orgId, startDate, endDate, type, regionIds }) => {
  let variables = { orgId, startDate, endDate, type, regionIds };
  return fetchQuery(environment, getSummarySendsQuery, variables);
};
const getReportHistory = ({ orgId, type, startDate, endDate }) => {
  let variables = { orgId, type, startDate, endDate };
  return fetchQuery(environment, getReportHistoryQuery, variables);
};

const getListProperties = ({ dashboardId, selectedPropertyIds }) => {
  let variables = { dashboardId, selectedPropertyIds, drip: false };
  return fetchQuery(environment, getListPropertiesQuery, variables);
};

const getCampaignList = (dashboardId, selectedPropertyIds, startAt, endAt, drip, type) => {
  const variables = { dashboardId, selectedPropertyIds, startAt, endAt, drip, type };
  return fetchQuery(environment, getCampaignListQuery, variables)
    .then((res) => {
      return res.campaignList;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

const getReportDashboards = (orgId, propertyId, name, type) => {
  let variables = { orgId, propertyId, type, name };
  return fetchQuery(environment, getReportDashboardsQuery, variables)
    .then((res) => {
      return res.reportDashboards;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

const getTenantOrCampaignStat = (dashboardId, params) => {
  let variables = { dashboardId, params };
  return fetchQuery(environment, getTenantOrCampaignStatQuery, variables)
    .then((res) => {
      return res.tenantOrCampaignStat;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

const getGptHistoryByJobId = (jobId) => {
  let variables = { jobId };
  return fetchQuery(environment, getGptHistoryByJobIdQuery, variables)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

const getGptHistoryById = (id) => {
  let variables = { id };
  return fetchQuery(environment, getGptHistoryByIdQuery, variables)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

const getCampaignMetrics = (params) => {
  let variables = { params };
  return fetchQuery(environment, getCampaignMetricsQuery, variables)
    .then((res) => {
      return res.campaignMetrics;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

const getChartSeries = (dashboardId, params) => {
  let variables = { dashboardId, params };
  return fetchQuery(environment, getChartSeriesQuery, variables)
    .then((res) => {
      return res.chartSeries;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

const getEblastContactsDashboard = (dashboardId, params) => {
  let variables = { dashboardId, params };
  return fetchQuery(environment, getEblastContactsDashboardQuery, variables)
    .then((res) => {
      return res.eblastContactsDashboard;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

const getDashboardMetrics = (dashboardId, params) => {
  let variables = { dashboardId, params };
  return fetchQuery(environment, getDashboardMetricsQuery, variables)
    .then((res) => {
      return res.dashboardMetrics;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

const getCampaignListV2 = (dashboardId, selectedPropertyIds, startAt, endAt, drip) => {
  const variables = { dashboardId, selectedPropertyIds, startAt, endAt, drip };
  return fetchQuery(environment, getCampaignListV2Query, variables)
    .then((res) => {
      return res.campaignList;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

const getTenantOrCampaignStatCampaign = (dashboardId, params) => {
  let variables = { dashboardId, params };
  return fetchQuery(environment, getTenantOrCampaignStatCampaignQuery, variables)
    .then((res) => {
      return res.tenantOrCampaignStat;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

const getTenantOrCampaignStatTenant = (dashboardId, params) => {
  let variables = { dashboardId, params };
  return fetchQuery(environment, getTenantOrCampaignStatTenantQuery, variables)
    .then((res) => {
      return res.tenantOrCampaignStat;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

const getCampaignListForDropDown = (
  dashboardId,
  selectedPropertyIds,
  startAt,
  endAt,
  drip,
  sms
) => {
  const variables = {
    dashboardId,
    selectedPropertyIds,
    startAt,
    endAt,
    drip,
    sms,
    type: "blast_report",
  };
  return fetchQuery(environment, getCampaignListForDropDownQuery, variables)
    .then((res) => {
      return res.campaignList;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

const getMailGptPromptsList = (tenantId, promptType) => {
  let variables = { tenantId, promptType };
  return fetchQuery(environment, getMailGptPromptsListQuery, variables)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

const listMailGptBlasts = (tenantId, startAt, endAt, type, params) => {
  let variables = { tenantId, startAt, endAt, type, params };
  return fetchQuery(environment, listMailGptBlastsQuery, variables)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

const mailGptBlast = (id) => {
  let variables = { id };
  return fetchQuery(environment, mailGptBlastQuery, variables)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

const AllOrgsBlastAnalysis = (orgIds, allOrgs, startAt, endAt) => {
  let variables = { orgIds, allOrgs, startAt, endAt };
  return fetchQuery(environment, allOrgsGptBlastAnalysisQuery, variables)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

const orgGptBlastAnalysis = (orgId, startAt, endAt, propertyIds) => {
  let variables = { orgId, startAt, endAt, propertyIds };
  return fetchQuery(environment, orgGptBlastAnalysisQuery, variables)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

const mailGptBlastTypeReports = (
  orgIds,
  startAt,
  endAt,
  allOrgs,
  blastTypes,
  promptTemplates,
  propertyIds
) => {
  let variables = { orgIds, startAt, endAt, allOrgs, blastTypes, promptTemplates, propertyIds };
  return fetchQuery(environment, mailGptBlastTypeReportsQuery, variables)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

const allOrgsGptFieldsAnalysis = (
  orgIds,
  startAt,
  endAt,
  allOrgs,
  blastTypes,
  promptTemplates,
  propertyIds
) => {
  let variables = { orgIds, startAt, endAt, allOrgs, blastTypes, promptTemplates, propertyIds };
  return fetchQuery(environment, allOrgsGptFieldsAnalysisQuery, variables)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

const getMailGptPromptTemplates = (promptType, audienceType, prompt_model_id) => {
  let variables = { promptType, audienceType, prompt_model_id };
  return fetchQuery(environment, mailGptPromptTemplatesQuery, variables)
    .then((res) => {
      return res?.mailGptPromptTemplates || [];
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

const getMailGptPromptPurposes = (propertyId) => {
  let variables = { propertyId };
  return fetchQuery(environment, mailGptPromptPurposesQuery, variables)
    .then((res) => {
      return res?.mailGptPromptPurposes || [];
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

export {
  getPurposes,
  gethyblasts,
  gethyblastEditorPreview,
  orgGptBlastAnalysis,
  importLogs,
  getTemplates,
  getEditor,
  listMailGptBlasts,
  mailGptBlast,
  gethyblastSetting,
  getHyBlast,
  getScheduledBlast,
  getPreview,
  getExpressionCount,
  contactExpressionCount,
  getContactFields,
  retrieveHyGptStatus,
  getTemplatePreview,
  testEblast,
  getHyblastOverview,
  feedbackStatus,
  getAuditSends,
  getSummarySend,
  getReportHistory,
  getCampaignList,
  getReportDashboards,
  getListProperties,
  getTenantOrCampaignStat,
  getCampaignMetrics,
  getChartSeries,
  getEblastContactsDashboard,
  getDashboardMetrics,
  getCampaignListV2,
  getTenantOrCampaignStatCampaign,
  getTenantOrCampaignStatTenant,
  allOrgsGptFieldsAnalysis,
  getCampaignListForDropDown,
  AllOrgsBlastAnalysis,
  mailGptBlastTypeReports,
  getMailGptPromptsList,
  getGptHistoryByJobId,
  getGptHistoryById,
  getMailGptPromptTemplates,
  getMailGptPromptPurposes,
};
