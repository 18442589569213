import React, { useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import TextField from "@mui/material/TextField";

const styles = makeStyles((theme) => ({
  textFieldCss: {
    borderBottom: "1px solid #dadada !important",
    "&:hover": {
      borderBottom: "1px solid var(--hylyTheme) !important",
    },
  },
  focusedCSS: {
    borderBottom: "1px solid #999999 !important",
  },
}));

const InputField = (props) => {
  const classes = styles();
  const [value, setValue] = React.useState(props.value);
  const [isFocused, setIsFocused] = React.useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const onBlur = (evt) => {
    if (typeof props.onBlur === "function") {
      props.onBlur(evt);
    }
    setIsFocused(false);
  };
  const onChange = (evt) => {
    setValue(evt.target.value);
    if (typeof props.onChange === "function") {
      props.onChange(evt);
    }
  };
  return (
    <div className={`${isFocused ? classes.focusedCSS : classes.textFieldCss}`}>
      <TextField
        fullWidth
        InputProps={{ disableUnderline: true }}
        {...props}
        value={value}
        onChange={onChange}
        onFocus={handleFocus}
        onBlur={onBlur}
        variant={props.variant || "standard"}
      />
    </div>
  );
};

export default InputField;
