import * as api from "relay/main";

import wmy from "storybookComponents/images/unpurchased/winback/wmy.png";
import na from "storybookComponents/images/unpurchased/winback/na.png";
import itt from "storybookComponents/images/unpurchased/winback/itt.png";

import a from "storybookComponents/images/unpurchased/resident/a.png";
import moc from "storybookComponents/images/unpurchased/resident/moc.png";
import nr from "storybookComponents/images/unpurchased/resident/nr.png";
import rr from "storybookComponents/images/unpurchased/resident/rr.png";
import rs from "storybookComponents/images/unpurchased/resident/rs.png";
import st from "storybookComponents/images/unpurchased/resident/st.png";

import sa from "storybookComponents/images/unpurchased/prospect/sa.png";
import sf from "storybookComponents/images/unpurchased/prospect/sf.png";
import sn from "storybookComponents/images/unpurchased/prospect/sn.png";

import aa from "storybookComponents/images/unpurchased/new/aa.png";
import d from "storybookComponents/images/unpurchased/new/d.png";
import fas from "storybookComponents/images/unpurchased/new/fas.png";
import pic from "storybookComponents/images/unpurchased/new/pic.png";
import rw from "storybookComponents/images/unpurchased/new/rw.png";
import sit from "storybookComponents/images/unpurchased/new/sit.png";

import { getPropertyId } from "common";
import { arrDiff } from "services";
import { LayersIcon2 } from "icons";

export const unpurchasedAngels = [
  {
    id: "price_drop_angel",
    name: "Price Drop Alert Angel",
    active: false,
    thenEmails: [
      {
        id: "1",
        name: "Price Drop Alert",
        active: false,
        triggerDays: 0,
        originalThumbnailUrl: sa,
        preview: "https://s3.amazonaws.com/m.hy.ly/hyALM/angel-emails/Showcase-Amenities-min.jpg",
        whenGroupName: null,
      },
    ],
  },
  {
    id: "availability_alert_angel",
    name: "Availability Alert Angel",
    active: false,
    thenEmails: [
      {
        id: "1",
        name: "Availability Alert",
        active: false,
        triggerDays: 0,
        originalThumbnailUrl: aa,
        preview: "https://s3.amazonaws.com/m.hy.ly/hyALM/angel-emails/Application-Approved-min.jpg",
        whenGroupName: null,
      },
    ],
  },
];

export const learnLinks = {
  price_drop_angel: "https://hy.ly/multifamily-lifecycle-marketing/angels/prospect-marketing/",
  availability_alert_angel:
    "https://hy.ly/multifamily-lifecycle-marketing/angels/new-resident-email/",
  resident_retention_angel:
    "https://hy.ly/multifamily-lifecycle-marketing/angels/resident-retention-strategy/",
  winback_angel: "https://hy.ly/multifamily-lifecycle-marketing/angels/win-back/",
};

export const contactLinks = {
  mail: "mailto:sales@hy.ly",
};

export const utm_code = `utm_medium=email&utm_source=Hyly&utm_campaign=ALM&utm_content={%email_name_encoded%}`;

export const getAngels = (propertyId, angelType) => {
  return new Promise((resolve, reject) => {
    api.getDripsMenu(propertyId, angelType).then((res, err) => {
      if (err || res.errors || res.error) {
        resolve(false);
      } else {
        resolve(res.angels);
      }
    });
  });
};

export const getFinalMenu = async (propertyId, angels) => {
  let menu;
  let [createdMenu, filteredMenu] = await Promise.all([
    createMenu(propertyId, angels),
    getFilteredMenu(propertyId, angels),
  ]);
  if (angels.length > 0) menu = createdMenu.concat(filteredMenu);
  else menu = createdMenu;

  return menu;
};

export const createMenu = async (propertyId, angels) => {
  let createdMenu;
  if (angels.length > 0) createdMenu = await getServerMenu(propertyId, angels);
  else createdMenu = await getLocalMenu(propertyId);

  return createdMenu;
};

export const getServerMenu = (propertyId, angels) => {
  var menu = angels.reduce((result, currentValue) => {
    result.push({
      to: "/" + propertyId + "/alerts/" + currentValue.id + "/angels",
      path: "/" + propertyId + "/alerts/" + currentValue.id + "/angels",
      title: currentValue.name,
      icon_type: (
        <LayersIcon2
          viewBox="0 0 32 32"
          style={{ width: 32, height: 32, fill: "none", stroke: "#333" }}
        />
      ),
    });
    return result;
  }, []);
  return menu;
};

export const getLocalMenu = (propertyId) => {
  var menu = unpurchasedAngels.reduce((result, currentValue) => {
    result.push({
      to: "/" + propertyId + "/alerts/" + currentValue.id + "/angels",
      path: "/" + propertyId + "/alerts/" + currentValue.id + "/angels",
      title: currentValue.name,
      icon_type: (
        <LayersIcon2
          viewBox="0 0 32 32"
          style={{ width: 32, height: 32, fill: "none", stroke: "#333" }}
        />
      ),
    });
    return result;
  }, []);
  return menu;
};

export const getFilteredMenu = (propertyId, angels) => {
  let filteredMenu = arrDiff(angels, unpurchasedAngels);
  let menu = [];
  for (let i = 0; i < filteredMenu.length; i++) {
    let angel = unpurchasedAngels.find(
      (e) => e.name.toUpperCase().replace(/ /g, "").replace(/-/g, "") === filteredMenu[i]
    );
    if (angel) {
      let localMenu = {
        to: "/" + propertyId + "/alerts/" + angel.id + "/angels",
        path: "/" + propertyId + "/alerts/" + angel.id + "/angels",
        title: angel.name,
        icon_type: (
          <LayersIcon2
            viewBox="0 0 32 32"
            style={{ width: 32, height: 32, fill: "none", stroke: "#333" }}
          />
        ),
      };
      menu.push(localMenu);
    }
  }
  return menu;
};

export const getAlertsMenu = async (propertyId, angelType) => {
  let angels = await getAngels(propertyId || getPropertyId(), angelType);
  let dripsMenu = await getFinalMenu(propertyId || getPropertyId(), angels);

  return dripsMenu;
};
