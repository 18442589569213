import graphql from "babel-plugin-relay/macro";

export default graphql`
  query getAuditSendsQuery(
    $orgId: String!
    $startDate: String!
    $endDate: String!
    $selectedPropertyIds: [String!]
    $params: Json
    $regionIds: [String!]
  ) {
    auditSends(
      orgId: $orgId
      startDate: $startDate
      endDate: $endDate
      selectedPropertyIds: $selectedPropertyIds
      params: $params
      regionIds: $regionIds
    ) {
      id
      propertyName
      lastSentAt
      lastSentEblast
      daysSinceLastSend
      sendsCount
      uniqueOpensRate
      uniqueClicksRate
      lastSentEblastId
      eblastsCount
      propertiesIndex
      propertiesTotal
    }
  }
`;
