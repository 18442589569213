/**
 * @flow
 * @relayHash afb4dfd9e87893d6d99c0e79cd545dca
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type hayleyFaqEnabledQueryVariables = {|
  tenantId: string
|};
export type hayleyFaqEnabledQueryResponse = {|
  +hayleyFaqEnabled: ?$ReadOnlyArray<{|
    +active: ?boolean
  |}>
|};
export type hayleyFaqEnabledQuery = {|
  variables: hayleyFaqEnabledQueryVariables,
  response: hayleyFaqEnabledQueryResponse,
|};
*/


/*
query hayleyFaqEnabledQuery(
  $tenantId: ID!
) {
  hayleyFaqEnabled(tenantId: $tenantId) {
    active
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "tenantId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "tenantId",
    "variableName": "tenantId"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "active",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "hayleyFaqEnabledQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "hayleyFaqEnabled",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "HayleyFaq",
        "plural": true,
        "selections": [
          (v2/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "hayleyFaqEnabledQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "hayleyFaqEnabled",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "HayleyFaq",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "hayleyFaqEnabledQuery",
    "id": null,
    "text": "query hayleyFaqEnabledQuery(\n  $tenantId: ID!\n) {\n  hayleyFaqEnabled(tenantId: $tenantId) {\n    active\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a276899b4b70cea2f9a8355f92c52e81';
module.exports = node;
