import graphql from "babel-plugin-relay/macro";

export default graphql`
  query getHayleyContactsDashboardQuery(
    $endDate: String!
    $startDate: String!
    $orgId: ID!
    $propertyIds: [String!]!
    $queryType: String!
  ) {
    hayleyContactList(
      endDate: $endDate
      startDate: $startDate
      orgId: $orgId
      propertyIds: $propertyIds
      queryType: $queryType
    )
  }
`;
