import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";
import environment from "environments/environment";

const mutation = graphql`
  mutation resetOfferMutation($input: resetHayleyOfferInput!) {
    resetHayleyOffer(input: $input) {
      response {
        promotion
      }
    }
  }
`;

function commit(input) {
  return new Promise((res, rej) => {
    commitMutation(environment, {
      mutation,
      variables: { input },
      updater: (store) => {},
      onCompleted: res,
      onError: rej,
    });
  });
}

export default commit;
