/**
 * @flow
 * @relayHash f862ddecd839c07c7dac4d9331acf0ef
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type resetTenantSettingInput = {|
  orgId: string,
  title: string,
  message?: ?string,
  resetAll?: ?boolean,
|};
export type resetTenantSettingMutationVariables = {|
  input: resetTenantSettingInput
|};
export type resetTenantSettingMutationResponse = {|
  +resetTenantSetting: ?{|
    +fairHousingAct: ?any
  |}
|};
export type resetTenantSettingMutation = {|
  variables: resetTenantSettingMutationVariables,
  response: resetTenantSettingMutationResponse,
|};
*/


/*
mutation resetTenantSettingMutation(
  $input: resetTenantSettingInput!
) {
  resetTenantSetting(input: $input) {
    fairHousingAct
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "resetTenantSettingInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "resetTenantSetting",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "resetTenantSettingPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "fairHousingAct",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "resetTenantSettingMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "resetTenantSettingMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "resetTenantSettingMutation",
    "id": null,
    "text": "mutation resetTenantSettingMutation(\n  $input: resetTenantSettingInput!\n) {\n  resetTenantSetting(input: $input) {\n    fairHousingAct\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '68838c98ac2f3129facb9c3583e7f7f7';
module.exports = node;
