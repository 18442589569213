/**
 * @flow
 * @relayHash a696f374ca3cd7c4fa4067f4c3ae10e1
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getEblastContactsDashboardQueryVariables = {|
  dashboardId: string,
  params?: ?any,
|};
export type getEblastContactsDashboardQueryResponse = {|
  +eblastContactsDashboard: $ReadOnlyArray<{|
    +id: string,
    +campaignName: ?string,
    +createdAt: ?string,
    +entryEmail: ?string,
    +propertyName: ?string,
    +subStatus: ?string,
    +eventIndex: ?string,
    +eventTotal: ?string,
    +entryName: ?string,
    +entryId: ?string,
    +entryUrl: ?string,
  |}>
|};
export type getEblastContactsDashboardQuery = {|
  variables: getEblastContactsDashboardQueryVariables,
  response: getEblastContactsDashboardQueryResponse,
|};
*/


/*
query getEblastContactsDashboardQuery(
  $dashboardId: ID!
  $params: Json
) {
  eblastContactsDashboard(dashboardId: $dashboardId, params: $params) {
    id
    campaignName
    createdAt
    entryEmail
    propertyName
    subStatus
    eventIndex
    eventTotal
    entryName
    entryId
    entryUrl
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "dashboardId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "params",
    "type": "Json",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "eblastContactsDashboard",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "dashboardId",
        "variableName": "dashboardId"
      },
      {
        "kind": "Variable",
        "name": "params",
        "variableName": "params"
      }
    ],
    "concreteType": "EblastContactsDashboard",
    "plural": true,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "campaignName",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "createdAt",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "entryEmail",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "propertyName",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "subStatus",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "eventIndex",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "eventTotal",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "entryName",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "entryId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "entryUrl",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getEblastContactsDashboardQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "getEblastContactsDashboardQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "getEblastContactsDashboardQuery",
    "id": null,
    "text": "query getEblastContactsDashboardQuery(\n  $dashboardId: ID!\n  $params: Json\n) {\n  eblastContactsDashboard(dashboardId: $dashboardId, params: $params) {\n    id\n    campaignName\n    createdAt\n    entryEmail\n    propertyName\n    subStatus\n    eventIndex\n    eventTotal\n    entryName\n    entryId\n    entryUrl\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5ffa02989cf983a0c462c2f4a8abd57e';
module.exports = node;
