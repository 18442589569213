import graphql from "babel-plugin-relay/macro";

export default graphql`
  query getSelectedSuborgDetailsQuery($id: String) {
    authorizedSuborgs(orgId: "any", first: 1, propertyId: $id) {
      id
      name
      region
      address
      propertyLogoUrl
      propertyManagerLogoUrl
      timezone
      timezoneOffset
      timezoneAbbr
      org {
        id
        name
        userRole
        hyblastSetting {
          approverEmails
        }
        hyeventsSetting {
          approverEmails
        }
      }
      linkColor
      fontFamily
      textColor
      buttonBorderColor
      buttonFontColor
      buttonBackgroundColor
      hyblastSetting {
        approverEmails
      }
      hyeventsSetting {
        approverEmails
      }
      contactUsLink
      tourCtaUrl
    }
  }
`;
