/**
 * @flow
 * @relayHash 937acb8c4c108eb83f521ac0c57dd5ce
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getSummarySendsQueryVariables = {|
  orgId: string,
  startDate: string,
  endDate: string,
  type?: ?string,
  regionIds?: ?$ReadOnlyArray<string>,
|};
export type getSummarySendsQueryResponse = {|
  +summarySends: $ReadOnlyArray<{|
    +id: string,
    +propertyId: string,
    +propertyName: string,
    +sentAt: ?any,
    +name: string,
    +subject: string,
    +sendsCount: number,
    +uniqueOpensRate: number,
    +uniqueOpensCount: number,
    +uniqueClicksRate: number,
    +uniqueClicksCount: number,
  |}>
|};
export type getSummarySendsQuery = {|
  variables: getSummarySendsQueryVariables,
  response: getSummarySendsQueryResponse,
|};
*/


/*
query getSummarySendsQuery(
  $orgId: String!
  $startDate: String!
  $endDate: String!
  $type: String
  $regionIds: [String!]
) {
  summarySends(orgId: $orgId, startDate: $startDate, endDate: $endDate, type: $type, regionIds: $regionIds) {
    id
    propertyId
    propertyName
    sentAt
    name
    subject
    sendsCount
    uniqueOpensRate
    uniqueOpensCount
    uniqueClicksRate
    uniqueClicksCount
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "orgId",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "startDate",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "endDate",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "type",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "regionIds",
    "type": "[String!]",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "summarySends",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "endDate",
        "variableName": "endDate"
      },
      {
        "kind": "Variable",
        "name": "orgId",
        "variableName": "orgId"
      },
      {
        "kind": "Variable",
        "name": "regionIds",
        "variableName": "regionIds"
      },
      {
        "kind": "Variable",
        "name": "startDate",
        "variableName": "startDate"
      },
      {
        "kind": "Variable",
        "name": "type",
        "variableName": "type"
      }
    ],
    "concreteType": "SummarySend",
    "plural": true,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "propertyId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "propertyName",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "sentAt",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "name",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "subject",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "sendsCount",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "uniqueOpensRate",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "uniqueOpensCount",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "uniqueClicksRate",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "uniqueClicksCount",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getSummarySendsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "getSummarySendsQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "getSummarySendsQuery",
    "id": null,
    "text": "query getSummarySendsQuery(\n  $orgId: String!\n  $startDate: String!\n  $endDate: String!\n  $type: String\n  $regionIds: [String!]\n) {\n  summarySends(orgId: $orgId, startDate: $startDate, endDate: $endDate, type: $type, regionIds: $regionIds) {\n    id\n    propertyId\n    propertyName\n    sentAt\n    name\n    subject\n    sendsCount\n    uniqueOpensRate\n    uniqueOpensCount\n    uniqueClicksRate\n    uniqueClicksCount\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'edcf1d19d99b217402c0080aeb77d7c4';
module.exports = node;
