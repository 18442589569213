import React from "react";
import { Grid, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import TooltipIfEllipse from "components/TooltipIfEllipse";
import emailPlaceholder from "images/blastPreview.png";
import { getAppPath } from "constants/path";
import { Tooltip } from "components";
const styles = () => ({
  superContainer: {
    display: "flex",
    border: "1px solid #DADADA",
    justifyContent: "center",
  },
  leftPart: {
    background: "#F5F5F5",
    padding: 8,
    color: "#333",
    fontFamily: "Open sans",
    width: 88,
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  imagesContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    height: "100%",
  },
  rightContainner: {
    width: "100%",
    background: "#FFF",
    paddingLeft: "12px",
  },
  rightPart: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    width: "100%",
    background: "white",
    height: "100%",
  },
  title: {
    fontSize: 12,
    lineHeight: "18px",
    color: "#666",
  },
  cardContainer: {
    padding: "10px 0px",
    color: "#333333",
    background: "white",
  },
  hoverState: {
    cursor: "pointer",
    "&:hover": {
      color: "var(--hylyTheme)",
    },
  },
  cardValue: {
    fontSize: 16,
    lineHeight: "24px",
    fontWeight: 600,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: 200,
    display: "block",
  },
  cardPercentValue: {
    fontSize: 16,
    lineHeight: "24px",
    fontWeight: 600,
    // whiteSpace: "nowrap",
    // overflow: "hidden",
    // textOverflow: "ellipsis",
    // maxWidth: 100,
    // display: "block",
  },
  cardValueColor: {
    color: "gray",
    fontSize: 15,
    marginLeft: 16,
    fontWeight: 600,
  },
  valueText: {
    fontSize: 12,
    lineHeight: "18px",
    color: "#333",
    fontWeight: 600,
    marginTop: "6px",
  },
  previewText: {
    color: "#666",
    textAlign: "center",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "18px" /* 150% */,
    marginTop: "6px",
  },
  imageStyle: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  gridStyle: {
    width: "calc(100% + 20px)",
    margin: -10,
  },
  cardStyle: {
    padding: "10px",
  },
  imagesContainerStyle: {
    width: 64,
    // height: 98,
    flex: 1,
    // overflow: "hidden",
    position: "relative",
    // cursor: "pointer",
  },
});

const PreviewImgIcon = (props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.2079 6.7915L11.6523 12.3471"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.3477 6.7915H17.2088V11.6526"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.1253 13.0414V16.1664C15.1253 16.4427 15.0156 16.7076 14.8202 16.903C14.6249 17.0983 14.3599 17.2081 14.0837 17.2081H7.83366C7.55739 17.2081 7.29244 17.0983 7.09709 16.903C6.90174 16.7076 6.79199 16.4427 6.79199 16.1664V9.91642C6.79199 9.64016 6.90174 9.3752 7.09709 9.17985C7.29244 8.9845 7.55739 8.87476 7.83366 8.87476H10.9587"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Card = ({ item, classes, ...props }) => {
  const handleClick = () => {
    // if (
    //   props.percentCheck
    //     ? props.label === "#Unique Sends"
    //       ? item.percentage
    //       : item.percentage !== "0.00%"
    //     : item.valueBeforPercentage > 0
    // ) {
    if (item.contactId && item.callback) {
      if (typeof props.onClick == "function") {
        props.onClick(item, "6");
      }
    }
    // }
  };

  return (
    <div
      className={`${classes.cardContainer} ${
        item.contactId && typeof props.onClick == "function" && item.callback
          ? classes.hoverState
          : // ? props.percentCheck
            //   ? (props.label === "#Unique Sends" ? item.percentage : item.percentage !== "0.00%") &&
            //     classes.hoverState
            //   : item.valueBeforPercentage > 0 && classes.hoverState
            ""
      }`}
      onClick={handleClick}
    >
      <Typography className={classes.title} noWrap>
        {item.label || ""}
      </Typography>
      {item.label === "Sent To" ? (
        item.percentage.split(/\s*(\+)\s*/).length > 1 ? (
          <Tooltip tooltip_text={item.tooltip || item.percentage} showTooltip={item.showTooltip}>
            <div className="flex items-center w-max">
              <span className={classes.cardValue}>
                {item.percentage.split(/\s*(\+)\s*/)[0] || "--"}
              </span>
              <span className={classes.cardValue}>
                {",+" + item.percentage.split(/\s*(\+)\s*/)[2]}
              </span>
            </div>
          </Tooltip>
        ) : (
          <span className={classes.cardValue}>
            {item.percentage.split(/\s*(\+)\s*/)[0] || "--"}
          </span>
        )
      ) : (
        <TooltipIfEllipse text={item.tooltip || item.percentage} showTooltip={item.showTooltip}>
          <span className={classes.cardValue}>
            <span>
              {props.percentCheck
                ? item.percentage || "--"
                : item.valueBeforPercentage === 0
                ? 0
                : item.valueBeforPercentage || item.percentage}
            </span>
          </span>
        </TooltipIfEllipse>
      )}
    </div>
  );
};

const OverViewBanner = ({
  classes,
  data,
  showCount,
  showPreview,
  propertyCallBack = false,
  ...props
}) => {
  const renderData = (item, index) => (
    <Grid item xs={4} className={classes.cardStyle} key={index}>
      <Card item={item} key={index} classes={classes} {...props} />
    </Grid>
  );

  // Set the value before percentage and callback for clickable items
  data.summaryData[2].valueBeforPercentage = data.data.unique_opens_count;
  data.summaryData[3].valueBeforPercentage = data.data.unique_clicks_count;
  data.summaryData[4].valueBeforPercentage = data.data.unsubscribes_count;
  data.summaryData[5].valueBeforPercentage = data.data.bounces_count;
  if (propertyCallBack) data.summaryData[0].callback = true;
  data.summaryData[1].callback = true;
  data.summaryData[2].callback = true;
  data.summaryData[3].callback = true;
  data.summaryData[4].callback = true;
  data.summaryData[5].callback = true;

  const errImageLoaded = (e) => (e.target.src = { emailPlaceholder });

  const renderImages = (item, index) => {
    return (
      <div
        style={{
          filter:
            index === 1
              ? "brightness(0.8) contrast(0.8) blur(2px)"
              : index === 2
              ? "brightness(0.6) blur(4px) contrast(4)"
              : "brightness(1)",
        }}
        className={`group absolute overflow-hidden w-full h-full ${
          item && showPreview && "hover:bg-[#33333360] cursor-pointer"
        } ${
          index === 1
            ? "-top-[3px] left-[3px] bg-[#888888] z-20"
            : index === 2
            ? "-top-[6px] left-[6px] bg-[#888888] z-10"
            : "top-0 left-0 z-30"
        }`}
        onClick={() =>
          showPreview &&
          window.open(
            getAppPath() + "/blast/" + (data.data.campaignId || data.campaignIds[0]) + "/preview",
            "_blank"
          )
        }
      >
        <img
          alt="thumbnail"
          src={item || emailPlaceholder}
          className={`w-full h-full object-cover`}
          onError={errImageLoaded}
        />
        {showPreview && (
          <div className="hidden bg-[#00000080] w-full h-full absolute top-[0%] left-[0%] group-hover:flex justify-content-center align-items-center">
            <PreviewImgIcon />
          </div>
        )}
      </div>
    );
  };

  if (!data) {
    return null;
  }

  return (
    <div className={classes.superContainer}>
      <div className={classes.leftPart}>
        <div className={classes.imagesContainer}>
          <div className={classes.imagesContainerStyle}>
            {!data.thumbnailUrls || data.thumbnailUrls?.length === 0 || data.data.count === 0 ? (
              <div className={`absolute overflow-hidden w-full h-full mb-2 cursor-pointer`}>
                <img
                  alt="thumbnail"
                  src={emailPlaceholder}
                  className={`w-full h-full object-cover`}
                  onError={errImageLoaded}
                />
              </div>
            ) : Array.isArray(data.thumbnailUrls) ? (
              data.thumbnailUrls
                .filter((item) => item !== null)
                .slice(0, 3)
                .map(renderImages)
            ) : (
              <div className={`absolute overflow-hidden w-full h-full mb-2 cursor-pointer`}>
                <img
                  alt="thumbnail"
                  src={data.thumbnailUrls || emailPlaceholder}
                  className={`w-full h-full object-cover`}
                  onError={errImageLoaded}
                />
              </div>
            )}
          </div>
          {showCount ? <div className={classes.valueText}>{data.count}</div> : null}
          {showPreview ? (
            (Array.isArray(data.thumbnailUrls) &&
              data.thumbnailUrls?.filter((item) => item !== null)?.length > 0) ||
            data.thumbnailUrls ? (
              <div className={classes.previewText}>Preview</div>
            ) : null
          ) : null}
        </div>
      </div>
      <div className={classes.rightContainner}>
        <Grid container className={classes.gridStyle}>
          {data.summaryData.map(renderData)}
        </Grid>
      </div>
    </div>
  );
};

export default withStyles(styles)(OverViewBanner);
