import { TableRow } from "@mui/material";

import withStyles from "@mui/styles/withStyles";

const StyledTableRow = withStyles({
  root: {
    border: "1px solid #dadada",
    fontSize: 14,
    fontFamily: "Open Sans",
    backgroundColor: "#fff",
  },
})(TableRow);

export default StyledTableRow;
