import React from "react";

export default function DocumentPencilIcon({
  className = "text-[#999999]",
  width = 18,
  height = 18,
}) {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.125 14.625H4.875C4.67609 14.625 4.48532 14.546 4.34467 14.4053C4.20402 14.2647 4.125 14.0739 4.125 13.875V4.125C4.125 3.92609 4.20402 3.73532 4.34467 3.59467C4.48532 3.45402 4.67609 3.375 4.875 3.375H11.625C11.8239 3.375 12.0147 3.45402 12.1553 3.59467C12.296 3.73532 12.375 3.92609 12.375 4.125V7.125"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.25 14.25L9.375 14.625L9.75 12.75L13.125 9.375L14.625 10.875L11.25 14.25Z"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
