import graphql from "babel-plugin-relay/macro";

export default graphql`
  query gethyblastQuery($propertyId: ID!, $id: String!) {
    hyblasts(propertyId: $propertyId, id: $id) {
      edges {
        node {
          scheduledTime
          sentTime
          abortedTime
          sentGroup
          status
          editorStatus
          approvalStatus
          mmsStatus
          mms
        }
      }
    }
  }
`;
