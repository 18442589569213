import React from "react";

function RightIcon({ className = "text-[#333333]", size = 15 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      className={className}
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M5 2l6 6-6 6"
      ></path>
    </svg>
  );
}

export default RightIcon;
