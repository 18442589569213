import React from "react";

export default function FilterIcon({ className = "text-[#333333]", width = 24, height = 24 }) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.70352 7.22145C6.26048 6.55689 6.73687 5.66675 7.53557 5.66675H17.1319C17.9306 5.66675 18.407 6.55689 17.9639 7.22145L13.835 13.4148C13.7255 13.5791 13.6671 13.7721 13.6671 13.9695V18.0001C13.6671 18.5524 13.2193 19.0001 12.6671 19.0001H12.0004C11.4481 19.0001 11.0004 18.5524 11.0004 18.0001V13.9695C11.0004 13.7721 10.9419 13.5791 10.8324 13.4148L6.70352 7.22145Z"
        stroke="currentColor"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
    </svg>
  );
}
