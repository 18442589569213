import dagre from "dagre";

const HORIZONTAL_SPACE = 150;
const VERTICAL_SPACE = 140;

export const calculateAutomatePosition = (nodes, item, viewport, delta, xDiff, type) => {
  let position;
  let realPosX = (delta.x - viewport.x - xDiff) / viewport.zoom;
  let realPosY = (delta.y - viewport.y - 124) / viewport.zoom;
  position = {
    id: "start",
    x: realPosX,
    y: realPosY,
  };
  return position;
};

export const getIndexOfNodeWithBestDistance = (nodes, extraNode) => {
  const distances = nodes
    .filter((_, index) => index !== nodes.length - 1)
    .map((node, index) => {
      if (nodes[index].type === "antiGoalState" || nodes[index].type === "goalState")
        return Number.MAX_VALUE;
      const distance =
        Math.pow(Math.abs(extraNode.position.x - node.position.x), 2) +
        Math.pow(Math.abs(extraNode.position.y - node.position.y), 2);
      return distance;
    });
  const min = Math.min(...distances);
  return distances.indexOf(min);
};

export const getLayoutElements = (nodes, edges, type, alignType) => {
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));

  let t_nodes = nodes;
  // const isHorizontal = type === "top";
  const rankdir = type === "HORZONTAL" ? "LR" : "TB";
  // const direction = type === "top" ? "LR" : "TB";
  let align;
  switch (alignType) {
    case "LEFT":
      align = "UL";
      break;
    case "RIGHT":
      align = "UR";
      break;
    case "TOP":
      align = "UL";
      break;
    case "BOTTOM":
      align = "UR";
      break;
    default:
      break;
  }

  dagreGraph.setGraph({ rankdir, marginx: 700, align, nodesep: 200 });

  t_nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: node.width, height: node.height });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  let isGraph = false;

  const dagreNodes = dagreGraph.nodes();

  for (let node of dagreNodes) {
    if (dagreGraph.inEdges(node).length > 1) {
      isGraph = true;
    }
    if (dagreGraph.outEdges(node).length > 1) {
      isGraph = true;
    }
  }

  //If the structure is graph cases
  if (isGraph) {
    const firstNode = t_nodes[0];
    const firstNideInDagreGraph = dagreGraph.node(firstNode.id);
    const differenceX = firstNode.position.x - firstNideInDagreGraph.x + firstNode.width / 2;
    const differenceY = firstNode.position.y - firstNideInDagreGraph.y + firstNode.height / 2;

    t_nodes = t_nodes.map((node, index) => {
      const nodeWithPosition = dagreGraph.node(node.id);
      return {
        ...node,
        position: {
          ...node.position,
          x: nodeWithPosition.x + differenceX,
          y: nodeWithPosition.y + differenceY,
        },
      };
    });
    return { nodes: t_nodes };
  } else {
    //If the structure is direct cases
    let result = [];
    if (type === "HORZONTAL") {
      t_nodes = t_nodes.sort((a, b) => a.position.x - b.position.x);
      for (let i = 0; i < t_nodes.length; i++) {
        let node = nodes[i];
        if (i === 0) {
          result.push(node);
        } else {
          const prevItemInResult = result[i - 1];
          let y = prevItemInResult.position.y + prevItemInResult.height - node.height;
          if (prevItemInResult.type === "step" && node.type === "state") y = y - 1.5;
          if (prevItemInResult.type === "state" && node.type === "step") y = y + 1.5;

          if (Math.abs(node.position.x - nodes[i - 1].position.x) < nodes[i - 1].width) {
            result.push({
              ...node,
              position: {
                x: prevItemInResult.position.x + prevItemInResult.width + HORIZONTAL_SPACE,
                y,
              },
            });
          } else {
            result.push({
              ...node,
              position: {
                x:
                  prevItemInResult.position.x + Math.abs(node.position.x - nodes[i - 1].position.x),
                y,
              },
            });
          }
        }
      }
    } else {
      t_nodes = t_nodes.sort((a, b) => a.position.y - b.position.y);

      for (let i = 0; i < t_nodes.length; i++) {
        let node = nodes[i];
        if (i === 0) {
          result.push(node);
        } else {
          const prevItemInResult = result[i - 1];

          let x;
          if (nodes[i - 1].position.x > node.position.x) {
            x = prevItemInResult.position.x + (prevItemInResult.width - node.width) / 2;
          } else {
            x = prevItemInResult.position.x + (prevItemInResult.width - node.width) / 2;
          }

          if (Math.abs(node.position.y - nodes[i - 1].position.y) < nodes[i - 1].height) {
            result.push({
              ...node,
              position: {
                y: prevItemInResult.position.y + prevItemInResult.height + VERTICAL_SPACE,
                x,
              },
            });
          } else {
            result.push({
              ...node,
              position: {
                x,
                y:
                  prevItemInResult.position.y + Math.abs(node.position.y - nodes[i - 1].position.y),
              },
            });
          }
        }
      }
    }
    return { nodes: result };
  }
};

export const getNAMEForNode = (id) => {
  switch (id) {
    case "customThen":
      return "Custom THEN";
    case "step":
      return "THEN";
    case "customWhen":
      return "Custom WHEN";
    case "state":
      return "WHEN";
  }
  return "";
};
