import React from "react";
import withStyles from "@mui/styles/withStyles";
import { Icon } from "storybookComponents";
import { getRangeLabel } from "common/date";
import Expansion from "./Expansion";
import { drawerBodyStyles as styles } from "../styles";
import { getLabelFromName } from "content/Blasts/Reports/utils";
import TooltipIfEllipse from "components/TooltipIfEllipse";

const DrawerBody = ({
  orgLevel,
  classes,
  title,
  onClose,
  label,
  start_date,
  end_date,
  propertiesName,
  dripsName,
  emailsName,
  panelsValues,
  widgetsValues,
  campaign_names,
  angel_names,
  total_campaigns,
  dateRange,
  blast_date_range,
  analyzeObject,
  properties,
  ...rest
}) => {
  const range_label = label !== "Custom Range" ? label : getRangeLabel(start_date, end_date);

  return (
    <div className={classes.super}>
      <div className={classes.flexBox}>
        {/* {title} */}
        All Filters
        <div className={classes.closeIcon}>
          <Icon onClick={onClose} type="icon-e-remove" size="lg" />
        </div>
      </div>
      {/* <div className="p-3">{title}</div> */}
      <div className={classes.drawerTitle}>{title}</div>
      <div className={classes.bodyContainer}>
        {orgLevel && propertiesName.length > 0 ? (
          <Expansion
            title={`Properties ${
              propertiesName.length > 1
                ? properties.length === propertiesName.length
                  ? `(All - ${propertiesName.length})`
                  : `(${propertiesName.length})`
                : ""
            }`}
            itle={`Panels ${panelsValues.length > 1 ? `(${panelsValues.length})` : ""}`}
            list={propertiesName}
            tooltip={true}
            search={true}
          />
        ) : null}
        {analyzeObject === "Drips Contact List" ||
        analyzeObject === "By Drip" ||
        analyzeObject === "By Email" ||
        analyzeObject === "Email Analytics" ? null : (
          <Expansion title={`Date Range`} list={start_date ? [range_label] : [dateRange]} />
        )}
        {dripsName.length > 0 ? (
          <Expansion
            title={`Drips ${dripsName.length > 1 ? `(${dripsName.length})` : ""}`}
            list={dripsName}
            tooltip={true}
          />
        ) : null}
        {emailsName.length > 0 ? (
          <Expansion
            title={`Emails ${emailsName.length > 1 ? `(${emailsName.length})` : ""}`}
            list={emailsName}
            tooltip={true}
          />
        ) : null}
        {analyzeObject === "Blast Contact List" ? (
          propertiesName.length > 1 ? null : campaign_names.length > 0 ? (
            <Expansion
              title={`Blasts ${
                campaign_names.length > 1
                  ? campaign_names.length === total_campaigns?.length
                    ? `(All - ${campaign_names.length})`
                    : `(${campaign_names.length})`
                  : ""
              }`}
              list={campaign_names}
              tooltip={true}
            />
          ) : null
        ) : null}
        {analyzeObject === "Drips Contact List" ? (
          propertiesName.length > 1 ? null : angel_names.length > 0 ? (
            <Expansion
              title={`Drips ${
                angel_names.length > 1
                  ? angel_names.length === rest.total_angels?.length
                    ? `(All - ${angel_names.length})`
                    : `(${angel_names.length})`
                  : ""
              }`}
              list={angel_names}
              tooltip={true}
            />
          ) : null
        ) : null}
        {analyzeObject === "Drips Contact List" ? (
          propertiesName.length > 1 ? null : angel_names.length === 1 ? (
            campaign_names.length > 0 ? (
              <Expansion
                title={`Emails ${
                  campaign_names.length > 1
                    ? campaign_names.length === total_campaigns?.length
                      ? `(All - ${campaign_names.length})`
                      : `(${campaign_names.length})`
                    : ""
                }`}
                list={campaign_names}
                tooltip={true}
              />
            ) : null
          ) : null
        ) : null}
        {(analyzeObject === "By Drip" ||
          analyzeObject === "By Email" ||
          analyzeObject === "Email Analytics") &&
        rest.bot_names.length > 0 ? (
          <Expansion
            title={`Drips ${
              rest.bot_names.length > 1
                ? rest.bot_names.length === rest.total_bots?.length
                  ? `(All - ${rest.bot_names.length})`
                  : `(${rest.bot_names.length})`
                : ""
            }`}
            list={rest.bot_names}
            tooltip={true}
          />
        ) : null}
        {(analyzeObject === "By Email" || analyzeObject === "Email Analytics") &&
        campaign_names.length > 0 ? (
          <Expansion
            title={`Emails ${
              campaign_names.length > 1
                ? campaign_names.length === total_campaigns?.length
                  ? `(All - ${campaign_names.length})`
                  : `(${campaign_names.length})`
                : ""
            }`}
            list={campaign_names}
            tooltip={true}
          />
        ) : null}
        {/* {panelsValues.length > 0 ? (
          <Expansion
            title={`Panels ${panelsValues.length > 1 ? `(${panelsValues.length})` : ""}`}
            list={panelsValues}
            tooltip={true}
          />
        ) : null} */}
        {(analyzeObject === "Drips Contact List" ||
          analyzeObject === "By Drip" ||
          analyzeObject === "By Email" ||
          analyzeObject === "Email Analytics") && (
          <Expansion title={`Date Range`} list={start_date ? [range_label] : [dateRange]} />
        )}
        {widgetsValues.length > 0 ? (
          <Expansion
            title={`Contact List ${widgetsValues.length > 1 ? `(${widgetsValues.length})` : ""}`}
            list={widgetsValues}
            tooltip={true}
          />
        ) : null}
        {dateRange.length > 0 ? <Expansion title={`Data`} list={[blast_date_range]} /> : null}
        {/* <Expansion title={`Status (${status[0]})`} list={status} /> */}
      </div>
    </div>
  );
};

export default withStyles(styles)(DrawerBody);
