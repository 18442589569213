// import React from 'react';
import { createTheme } from "@mui/material/styles";

const white = {
  main: "rgba(255,255,255,1)",
  dark: "#ddd",
  medium: "rgba(0, 0, 0, 0.08)",
  data: "#f8f8f8",
  tabs: "#FCFCFC",
  grey: "#f9f9f9",
};

const blue = {
  main: "#26bbed",
  pastel: "#7addff",
  deep: "#00A2D8",
  deepDark: "#0090D0",
  medium: "#1FB7EA",
  mediumLight: "#37CCFF",
};

const grey = {
  main: "#9a9a9a",
  dark: "#333333",
  medium: "#999999",
  light: "#9e9e9e",
};

const fontWeight = {
  light: 400,
  main: 500,
  bold: 600,
  intense: 900,
};

// const lineHeight = {
//   main: 1.4,
//   big: 1.6,
// }

// const letterSpacing = {
//   main: '.44px',
//   big: '.75px',
// }

// const drawerWidth = 280
// const marginBigUnit = 24
// const marginSmallUnit = 16

const spacing = {
  xs: 8,
  sm: 16,
  md: 24,
  lg: 32,
  xl: 40,
};

const margins = {
  xs: 16,
  sm: 16,
  md: 24,
  lg: 32,
  xl: 40,
};

const openSans = "Open Sans , arial, sans-serif";
const raleway = "Raleway , arial, sans-serif";

const rootElement = document.getElementById("root");

export default createTheme({
  typography: {
    fontFamily: openSans,
    h1: {
      fontFamily: raleway,
      fontSize: 32,
    },
    h2: {
      fontFamily: raleway,
      fontSize: 28,
    },
    h3: {
      fontFamily: raleway,
      fontSize: 24,
    },
    h4: {
      fontFamily: raleway,
      fontSize: 20,
    },
    h5: {
      fontFamily: raleway,
      fontSize: 18,
    },
    subtitle1: {
      fontFamily: openSans,
      fontSize: 12,
    },
    subtitle2: {
      fontFamily: openSans,
    },
    body1: {
      fontSize: 16,
      fontFamily: openSans,
    },
    body2: {
      fontSize: 14,
      fontFamily: openSans,
    },
    h6: {
      fontSize: 16,
      padding: 0,
      margin: 0,
      fontFamily: raleway,
    },
    useNextVariants: true,
  },
  fontWeight,
  // lineHeight,
  // letterSpacing,
  // marginBigUnit,
  // marginSmallUnit,
  spacing_unit: spacing,
  margins,
  palette: {
    white: "#fff",
    whiteGrey: "#F9F9F9",
    black: "#000",
    darkBlack: "#333",
    deepGrey: "#999",
    mediumGrey: "#CCC",
    lightGrey: "#EEE",
    hylyBlue: "#26bbed",
    deepBlue: "#0094C6",
    greenTeal: "#66D184",
    red: "#FF5151",
    errorRed: "#FF0000",
    orange: "#FF9900",
    divider: "#EEE",
    hoverColor: "#f5f5f5",
    background: {
      paper: "#f9f9f9",
      default: white.main,
    },
    default: {
      main: white.main,
    },
    primary: {
      main: "#26BBED",
      contrastText: white.main,
    },
    secondary: {
      main: grey.dark,
      contrastText: white.main,
    },
    action: {
      active: grey.medium,
      selected: "#ffffff00",
    },
    text: {
      secondary: grey.main,
      primary: grey.dark,
    },
    hint: {
      main: blue.main,
    },
    disabled: grey.medium,
    error: {
      main: "#FF0000",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          padding: "10px 32px",
          color: "#fff",
          fontWeight: 600,
          fontSize: 14,
          lineHeight: "20px",
        },
        containedPrimary: {
          color: "#fff",
          "&:hover": { backgroundColor: "#10A8DB" },
        },
        textPrimary: {
          color: "#333",
          "&:hover": { backgroundColor: "#f9f9f9" },
        },
        outlined: {
          padding: "10px 32px",
          fontWeight: 600,
          fontSize: 14,
          lineHeight: "20px",
        },
        text: {
          padding: "10px 32px",
          fontWeight: 600,
          fontSize: 14,
          lineHeight: "20px",
        },
        outlinedPrimary: {
          color: "#26BBED",
          "&:hover": {
            color: "#10A8DB",
            border: "1px solid #10A8DB",
            backgroundColor: "#ffffff00",
          },
        },

        sizeSmall: {
          padding: "6px 12px",
          fontWeight: 600,
          fontSize: 14,
          lineHeight: "20px",
        },
        outlinedSizeSmall: {
          padding: "6px 12px",
          fontWeight: 600,
          fontSize: 14,
          lineHeight: "20px",
        },
        sizeLarge: {
          padding: "14px 52px",
          fontWeight: 600,
          fontSize: 16,
          lineHeight: "20px",
        },
        outlinedSizeLarge: {
          padding: "14px 52px",
          fontWeight: 600,
          fontSize: 16,
          lineHeight: "20px",
        },
        textSizeLarge: {
          padding: "14px 52px",
          fontWeight: 600,
          fontSize: 16,
          lineHeight: "20px",
        },
      },
    },
    MuiPopover: {
      defaultProps: {
        container: rootElement,
      },
    },
    MuiPopper: {
      defaultProps: {
        container: rootElement,
      },
    },
    MuiDialog: {
      defaultProps: {
        container: rootElement,
      },
    },
    MuiDrawer: {
      defaultProps: {
        container: rootElement,
      },
    },
  },
});
