import React from "react";
import { SvgIcon } from "@mui/material";

export default function RouteCloseIcon(props) {
  return (
    <svg
      className={props.className}
      width={props.width}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.0718 10.4168H18.1923"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.64111 10.4168H4.76165"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.4165 16.0717C13.5395 16.0717 16.0712 13.5399 16.0712 10.4169C16.0712 7.29385 13.5395 4.76213 10.4165 4.76213C7.29344 4.76213 4.76172 7.29385 4.76172 10.4169C4.76172 13.5399 7.29344 16.0717 10.4165 16.0717Z"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.00293 9.0032L11.8303 11.8306"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.8303 9.0032L9.00293 11.8306"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
