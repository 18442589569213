var phoneRegexp = /^[(]?\d{3}[)]?[(\s)?.-]\d{3}[\s.-]\d{4}$/;
const brToNl = (str) => {
  if (!str) return "";
  str = str.replace(/<\s*\/?br\s*[\/]?>/gi, "\n");
  return str.replace(/(<([^>]+)>)/gi, "");
};
export default (message, orgColors, data = {}) => `
<style>
@import url("https://fonts.googleapis.com/css?family=Raleway");
@import url("https://fonts.googleapis.com/css?family=Open+Sans");

input:focus {outline:0;} 
textarea:focus {outline:0;} 
button:focus {outline:0;} 
a{
   color: ${orgColors ? orgColors.linkColor : "#27bbed"} !important;
}
.hyraf-sms-share-form{
  max-width:500px;
  width:100%;
  margin: auto;
  background: white;
  margin-top:140px;
}
.hyraf-sms-share-form-header{
  height: 80px;
   background:${orgColors ? orgColors.buttonBackgroundColor : "#27bbed"};
  color:${orgColors ? orgColors.buttonFontColor : "white"};
  border:${orgColors ? orgColors.buttonBorderColor : "#27bbed"};
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
  padding:0 20px;
}
.hyraf-sms-share-form-header span{
  font-size: 24px;
  color: white;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
  display:flex;
  align-items:center;
}
.hyraf-sms-share-form-header .close-btn{
  background:transparent;
  border:none;
  font-size: 30px;
  color:white;
  font-weight: 100;
}

.hyraf-sms-share-form .content{
  padding:20px 20px 30px 20px;
  border: 1px solid #DADADA;
  border-top:none;
}

.hyraf-sms-share-form .content .content-heading{
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
}

.hyraf-sms-share-form .content .content-subheading{
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  margin-top:30px;
}

.hyraf-sms-share-form .content .content-label{
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: rgba(51, 51, 51, 0.75);
  margin-top:30px;
}

.hyraf-sms-share-form .content .content-small-label{
  margin-top:30px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #333333;
}

.hyraf-sms-share-form .content input{
  border: none;
  border-bottom: 1px solid #dadada;
  width: 100%;
  margin-top: 20px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
}

.hyraf-sms-share-form .content textarea{
  border: 1px solid #dadada;
  width: 100%;
  margin-top: 10px;
  height:150px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
}

.sms-text{
  border: 1px solid #dadada;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  padding:10px;
  width:-webkit-fill-available !important;
}


.hyraf-sms-share-form .content .submit-btn{
  width: 240px;
  height: 48px;
 background:${orgColors ? orgColors.buttonBackgroundColor : "#27bbed"};
  color:${orgColors ? orgColors.buttonFontColor : "white"};
  border:${orgColors ? orgColors.buttonBorderColor : "#27bbed"};
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  cursor:pointer;
}
.hyraf-sms-share-form .footer{
  text-align: right;
  margin-top: 20px;
}
.hyraf-copy-link{
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #999999;
  justify-content:space-between;
  border:1px solid #eeeeee;
  height:35px;
  margin-top:10px;
  padding-left:10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.hyraf-copy-link button{
  height: 35px;
  border: none;
  background: transparent;
  width: 80px;
  border-left: 1px solid #eeeeee;
  color: #333333;
  cursor:pointer;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  font-family: "Open Sans", sans-serif;
}

.share-buttons-queue{
  display:flex;
  justify-content:space-between;
  margin-top:10px;
}

.share-buttons-queue button{
  width: 100px;
  height: 86px;
  background: #F9F9F9;
  border-radius: 10px;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor:pointer;
}
.share-buttons-queue button span{
  margin-top:10px;
  color:#333333;
}
.copied-btn{
  background: #66D184 !important;
  box-shadow: inset 1px 0px 0px #DADADA;
  color:white !important;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.copied-btn svg{
  display:block !important;
}
.copy-btn svg{
  display:none;
} 
.share-form-field .guide{
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #999999;
}
.share-form-field label {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
}

.mt-20{
  margin-top:20px;
}
@media only screen and (max-width: 480px) {
  .hyraf-sms-share-form-header{
    height: 60px;
  }
  .hyraf-sms-share-form-header span{
    font-size: 18px;
  }
  .hyraf-sms-share-form-header .close-btn{
    right: 10px;
    font-size:25px;
  }
  .hyraf-sms-share-form .content .content-heading{
    font-size: 16px;
    line-height: 20px;
  }

  .share-buttons-queue{
    display: grid;
    justify-content: space-between;
    margin-top: 10px;
    gap: 10px;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  }
  .share-buttons-queue button{
    width:auto;
    height: 44px;
    background: #F9F9F9;
    border-radius: 10px;
    border: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .share-buttons-queue button span{
    margin-top:0;
    margin-left:5px;
  }
  .hyraf-copy-link{
    font-size: 12px;
  }
  .hyraf-sms-share-form .footer{
    text-align: center;
  }
}

.multi-phone-input{
  margin: 0;
  max-width: 100%;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  outline: 0;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  text-align: left;
  line-height: 1.21428571em;
  background: #fff;
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 0.1s ease, border-color 0.1s ease;
  transition: box-shadow 0.1s ease, border-color 0.1s ease;
  font-size: 13px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: flex-start;
  padding-bottom:10px;
}
.multi-phone-input input{
  margin:0!important;
}
.multi-phone-input > span[data-placeholder] {
  display: none;
  position: absolute;
  left: 0.5em;
  top: 0.4em;
  padding: 0.4em;
  line-height: 1.21428571em;
}
.multi-phone-input.focused {
  border-color: #85b7d9;
  background: #fff;
}

.multi-phone-input.empty > span[data-placeholder] {
  display: inline;
  color: #ccc;
}
.multi-phone-input.focused > span[data-placeholder] {
  display: none;
}

.multi-phone-input > input {
  width: auto !important;
  outline: none !important;
  border: 0 none !important;
  display: inline-block !important;
  line-height: 1;
  vertical-align: baseline !important;
  padding: 0.4em 0.1em !important;
}

.multi-phone-input [data-tag] {
  line-height: 1;
  vertical-align: baseline;
  margin: 0.14285714em;
  background-color: #f3f3f3;
  background-image: none;
  padding: 0.5833em 0.833em;
  color: rgba(0, 0, 0, 0.6);
  text-transform: none;
  font-weight: 600;
  border: 0 solid transparent;
  border-radius: 0.28571429rem;
  -webkit-transition: background 0.1s ease;
  -o-transition: background 0.1s ease;
  transition: background 0.1s ease;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
  gap:5px;
}
.multi-phone-input [data-tag] [data-tag-item] {
  max-width: 100%;
  overflow: hidden;
}
.multi-phone-input [data-tag]:first-child {
  margin-left: 0;
}
.multi-phone-input [data-tag] [data-tag-handle] {
  margin-left: 0.833em;
  cursor: pointer;
}
.phone-tag{
  border: 1px solid #c9c9cb;
  padding: 5px;
  border-radius: 22px;
  display:flex;
  align-items:center;
  justify-content:space-between;
  font-size:14px;
  padding-top:6px;
}
.phone-tag svg{
  width: 16px;
  height: 16px;
  stroke-width: 3px;
  margin-top:2px;
}
.phone-tag:hover{
  border: 1px solid #26BBED;
  cursor:pointer;
  background-color: rgba(0, 0, 0, 0.04);
}
.phone-tag svg:hover path{
  stroke:red;
  cursor:pointer;
}

.error-phone-tag {
  border-color:red;
}
.error-phone-tag:hover {
  border-color:red;
}

</style>

<script>

var tester = ${phoneRegexp}


function format(number) {
  var mask = "XXX-XXX-XXXX"
  var s = '' + number, r = '';
  for (var im = 0, is = 0; im < mask.length && is < s.length; im++) {
    r += mask.charAt(im) == 'X' ? s.charAt(is++) : mask.charAt(im);
  }
  return r;
}

  function isValidPhoneNumber(phoneNumber) {
    return  ${phoneRegexp}.test(phoneNumber);
  }

  function removePhoneNumber(tag, phn){
    let phone = phn || tag.parentElement.previousSibling.innerHTML.trim();
    let all = document.getElementById("all-phone-numbers");
    for(let i =0; i < all.children.length - 1; i++){
      if(all.children[i].children[0].innerHTML == phone){
        all.removeChild(all.childNodes[i]);
        ifAnyInvalidPhone();
        return;
      }
    }
  }

  function removeAllPhones(){
    let all = document.getElementById("all-phone-numbers");
    for(let i =0; i < all.children.length - 1; i++){
      all.removeChild(all.childNodes[i]);
      return;
    }
  }

   function editChipContent(tag,eml){
    let phone=eml||tag.innerHTML;
    removePhoneNumber(this,phone);
    let input=document.getElementById("phones"); 
    input.value=phone;
  }

  function getAllPhoneNumber(){
    let r =[]
    let all = document.getElementById("all-phone-numbers").children;
    for(let i =0; i < all.length - 1; i++){
      r.push(all[i].children[0].innerHTML)
    }
    return r;
  }


  function ifAnyInvalidPhone(){
    let anyInvalidPhone=false;
    let allPhones= getAllPhoneNumber();
     if(allPhones.length===0){
      anyInvalidPhone=true
    }
    for(let i=0;i<allPhones.length;i++){
      if(!isValidPhoneNumber(allPhones[i])){
        anyInvalidPhone=true;
      }
    }
    if(anyInvalidPhone){
      let button=document.getElementById("sms-send-btn");
      button.style.opacity = 0.5;
      button.disabled = true;
    }else{
      let button=document.getElementById("sms-send-btn");
      button.style.opacity = 1;
      button.disabled = false;
    }
  }

  function findPhoneNumber(value, isEnter){
    let inputValue = '';
    const re = /[ ,;]/g;
    const isPhoneNumber = isValidPhoneNumber;
    

    const addPhoneNumber = (phone,isInvalid) => {
      const phones = getAllPhoneNumber();
      for (let i = 0, l = phones.length; i < l; i++) {
        if (phones[i] == phone) {
          return false;
        }
      }

      let all = document.getElementById("all-phone-numbers");
      var newTag = document.createElement("span");
      newTag.className="phone-tag";
      
      var newPhoneNumber = document.createElement("span");
      newPhoneNumber.setAttribute("onclick", "editChipContent(this)");
      newPhoneNumber.innerHTML = phone;
       if(isInvalid){
        newTag.className=newTag.className + " error-phone-tag";
      }


      newTag.append(newPhoneNumber);

      var closeIcon = document.createElement("span");
      closeIcon.innerHTML = '&nbsp; &nbsp;\
        <svg onclick = "removePhoneNumber(this)" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\
          <path d="M19 5L5 19" stroke="#c9c9cb" stroke-miterlimit="10" stroke-linecap="square" />\
          <path d="M19 19L5 5" stroke="#c9c9cb" stroke-miterlimit="10" stroke-linecap="square" />\
        </svg>'.trim();
      newTag.append(closeIcon);

      all.insertBefore(newTag, all.childNodes[all.children.length - 1]);
      all.children[all.children.length-1].value = "";
      ifAnyInvalidPhone();
      return true;
    }

    if (value !== '') {
       let isInvalid=false;
      let masked = format(new String(value).replace(/-/g, ""));
      
      if (re.test(value)) {
        value = masked + value[value.length - 1]
        re.test(value)
      }
      else value = masked
      if (re.test(value)) {
          let splitData = value.split(re).filter(n => {
          return n !== '' && n !== undefined && n !== null;
        });
        const setArr = new Set(splitData);
        let arr = [...setArr];

        do {
          // if (isPhoneNumber('' + arr[0])) {
          //   addPhoneNumber('' + arr.shift());
          // } else {
          //   if (arr.length === 1) {
          //     inputValue = '' + arr.shift();
          //   } else {
          //     arr.shift();
          //   }
          // }
          if (isPhoneNumber('' + arr[0])) {
            isInvalid=false;
          } else {
            isInvalid=true;
          }
          addPhoneNumber('' + arr.shift(),isInvalid);
        } while (arr.length);
      } else {
        console.log("test fail")
        if (isEnter) {
          // if (isPhoneNumber(value)) {
          //   addPhoneNumber(value);
          // } else {
          //   inputValue = value;
          // }
          if (isPhoneNumber(value)) {
            isInvalid=false;
          } else {
            isInvalid=true;
          }
          addPhoneNumber(value,isInvalid);
        } else {
          inputValue = value;
        }
      }
    }
    if(document.getElementById("phones").value != inputValue){
      document.getElementById("phones").value = inputValue;
      findPhoneNumber(inputValue)
    }
  }


  function removeLastPhoneNumber(){
    let inputValue=document.getElementById("phones").value;
    if(!inputValue){
      let all = document.getElementById("all-phone-numbers");
      all.removeChild(all.childNodes[all.children.length - 2]);
    }
  }

  function handlePhoneOnKeyup(e){
    switch (e.which) {
      case 13:
      case 9:
        findPhoneNumber(e.target.value, true);
        break;
      case 8:
        //removeLastPhoneNumber()
        break;
      default:
    }
  }

  handlePhoneOnChange = (e) => {
    findPhoneNumber(e.currentTarget.value);
  }
</script>

<div class="hyraf-sms-share-form">
  <div class="hyraf-sms-share-form-header">
    <button class="close-btn" type="button" onclick="closeSocialPreview('sms-popup')">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M22.5 12.5H2.5" stroke="white" stroke-miterlimit="10"/>
        <path d="M9.5 19.5L2.5 12.5L9.5 5.5" stroke="white" stroke-miterlimit="10" stroke-linecap="square"/>
      </svg>
    </button>
    <span class="title">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20 2H4C2.9 2 2.01 2.9 2.01 4L2 22L6 18H20C21.1 18 22 17.1 22 16V4C22 2.9 21.1 2 20 2ZM13 14H11V12H13V14ZM13 10H11V6H13V10Z" fill="#FFFFFF"/>
      </svg>
      &nbsp;
      SMS
    </span>
    <button class="close-btn" type="button" onclick="closeSocialPreview('sms-popup', 'true')">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19 5L5 19" stroke="white" stroke-miterlimit="10" stroke-linecap="square"/>
        <path d="M19 19L5 5" stroke="white" stroke-miterlimit="10" stroke-linecap="square"/>
      </svg>
    </button>
  </div>
  <div class="content">
    <div class="share-form-field">
      <label>To</label>
      <div class="multi-phone-input" id="all-phone-numbers"><span class="phone-tag">
          <span onclick="editChipContent(this, '333-444-5555')">333-444-5555</span> 
          <span>
            &nbsp;&nbsp;
            <svg onclick="removePhoneNumber(this, '333-444-5555')" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19 5L5 19" stroke="#c9c9cb" stroke-miterlimit="10" stroke-linecap="square"/>
            <path d="M19 19L5 5" stroke="#c9c9cb" stroke-miterlimit="10" stroke-linecap="square"/>
            </svg>
          </span>
        </span>
        <input 
          id="phones"  
          style="border:none !important;"
          onkeyup="handlePhoneOnKeyup(event)"
          oninput="handlePhoneOnChange(event)"
        />
      </div>
      <div class="guide">Enter phone numbers separated by commas</div>
    </div>
    <div class="share-form-field mt-20">
      <label>Message</label>
      <textarea id="sms-body-text" class="sms-text">${brToNl(message)}</textarea>
    </div>
     <input id="endpoint" value="${data.endpoint}" type="hidden"/>
     <input id="campaignId" value="${data.id}" type="hidden"/>
     <input id="enrollId" value="${data.enrollId}" type="hidden"/>
    <div class="footer">
      <button id="sms-send-btn" class="submit-btn" type="button" onclick="sendSMS()">Send SMS</button>
    </div>
  </div>
</div>
<script>

function sendSMS(){
    let url = document.getElementById('endpoint').value;
    let id = document.getElementById('campaignId').value;
    let body = document.getElementById("sms-body-text").value;//innerHTML.replace(/<[^>]*>/g, "");
    console.log("body",body)
    let phone = getAllPhoneNumber();
    if(!phone.length)return;
    let enrollmentId = document.getElementById("enrollId").value;
    if(!enrollmentId || enrollmentId == 'undefined'){
      let enrollData = localStorage.getItem('enrollData');
      if(!enrollData)return;
      enrollmentId = JSON.parse(enrollData).id;
    }
    document.getElementById("sms-send-btn").style.opacity = 0.5;
    document.getElementById("sms-send-btn").style.disabled = true;

    let options = {
       headers: { 'Content-Type': 'application/json' }
    };
    let query = 'mutation{ inviteFriendSmsHyreferralCampaign(input:{ bodyPlain: "'+body+'" , recipients:"'+phone.toString()+'", id:"'+id+'",enrollmentId:"'+enrollmentId+'"}){ status }}'
    
    options.body = JSON.stringify({ query });
    options.method = 'POST';
   
    fetch(url,options).then(res => res.json())
    .then(res => {
      document.getElementById("sms-send-btn").style.opacity = 1;
      document.getElementById("sms-send-btn").style.disabled = false;
      if(res.errors){
        if(res.errors[0]&&res.errors[0].message){
          alert(res.errors[0]&&res.errors[0].message)
        }
      }
      else{
        removeAllPhones();
        closeSocialPreview('sms-popup')
        openSocialPreview("thanks-popup")
      }
    })
  

}
</script>
`;
