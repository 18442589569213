import React from "react";
import Tooltip from "@mui/material/Tooltip";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: "10 !important",
  },

  arrow: {
    "&:before": {
      border: "none",
    },
    fontSize: 11,
    color: "#333333",
  },

  tooltip: {
    marginTop: "6px !important",
    backgroundColor: "#333333",
    border: "none",
    color: "white",
    fontSize: 14,
    borderRadius: "0px",
    lineHeight: "20px",
    padding: "6px 10px",
    maxWidth: (props) => (props.width ? `${props.width}px` : "250px"),
  },
}));

export default function ArrowTooltip({ tooltip_text, children, placement, width }) {
  let classes = useStyles({ width });

  return (
    <Tooltip
      title={tooltip_text}
      placement={placement}
      arrow
      classes={{ root: classes.root, arrow: classes.arrow, tooltip: classes.tooltip }}
    >
      {children}
    </Tooltip>
  );
}
