/**
 * @flow
 * @relayHash 20b0d1ffa925920a5597eaf203abc0a3
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type uploadHayleyAssetInput = {|
  clientMutationId?: ?string,
  propertyId: string,
  name: string,
  imageData: string,
|};
export type uploadHayleyAssetMutationVariables = {|
  input: uploadHayleyAssetInput
|};
export type uploadHayleyAssetMutationResponse = {|
  +uploadHayleyAsset: ?{|
    +asset: ?{|
      +id: string,
      +name: string,
      +fileUrl: string,
      +fileSize: string,
      +fileType: string,
      +thumbnailUrl: ?string,
      +createdAt: any,
      +dimension: ?string,
    |}
  |}
|};
export type uploadHayleyAssetMutation = {|
  variables: uploadHayleyAssetMutationVariables,
  response: uploadHayleyAssetMutationResponse,
|};
*/


/*
mutation uploadHayleyAssetMutation(
  $input: uploadHayleyAssetInput!
) {
  uploadHayleyAsset(input: $input) {
    asset {
      id
      name
      fileUrl
      fileSize
      fileType
      thumbnailUrl
      createdAt
      dimension
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "uploadHayleyAssetInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "uploadHayleyAsset",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "uploadAssetPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "asset",
        "storageKey": null,
        "args": null,
        "concreteType": "Asset",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "fileUrl",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "fileSize",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "fileType",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "thumbnailUrl",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "createdAt",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "dimension",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "uploadHayleyAssetMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "uploadHayleyAssetMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "uploadHayleyAssetMutation",
    "id": null,
    "text": "mutation uploadHayleyAssetMutation(\n  $input: uploadHayleyAssetInput!\n) {\n  uploadHayleyAsset(input: $input) {\n    asset {\n      id\n      name\n      fileUrl\n      fileSize\n      fileType\n      thumbnailUrl\n      createdAt\n      dimension\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'aa79895c4f27d9a966fcd87376bd7392';
module.exports = node;
