import { EditIcon, Logout, KeyIcon } from "./icons";

export const list = [
  {
    type: "edit",
    title: "Edit Account Info",
    Icon: EditIcon,
  },
  {
    type: "password",
    title: "Change Password",
    Icon: KeyIcon,
  },
  {
    type: "signOut",
    title: "Sign Out",
    Icon: Logout,
  },
];

export const regEx = /^[a-zA-Z0-9!”$%&’().@#*:"'<>=?\+,\/;\/-\[\\\]\^_`{|}~]+$/;
