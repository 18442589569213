import React from "react";
import withStyles from "@mui/styles/withStyles";
import Dialog from "@mui/material/Dialog";
import MuiDialogTitle from "@mui/material/DialogTitle";
import MuiDialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Icon from "storybookComponents/Icon";
import FramePreview from "./FramePreview.js";
import Loader from "storybookComponents/HylyLoader";

const styles = (theme) => ({
  root: {
    margin: 0,
    background: "#FFF",
    padding: "0 !important",
  },
  closeButton: {
    position: "absolute",
    right: "10px",
    top: "2px",
    color: "#333",
    background: "#FFF",
  },
  preview: {
    height: "100%",
    [theme.breakpoints.up("md")]: {},
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={onClose}
          size="large"
        >
          <Icon type="icon-e-remove" size="xl" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: "0px",
    background: "#FFF",
    overflow: "hidden",
  },
}))(MuiDialogContent);

class Preview extends React.Component {
  render() {
    const { preview_open, handleClose, preview } = this.props;
    return (
      <div>
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={preview_open}
          fullScreen={true}
        >
          <DialogTitle id="customized-dialog-title" onClose={handleClose} />
          <DialogContent>{preview ? <FramePreview content={preview} /> : <Loader />}</DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(Preview);
