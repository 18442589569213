import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userDetails: {},
  isHayleyFaqEnabled: false,
  blastsUploadAccess: false,
  dripsUploadAccess: false,
};

const userDetailsSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setStates4User: (state, action) => {
      state.userDetails = action.payload;
    },
    setIsHayleyFaqEnabled: (state, action) => {
      state.isHayleyFaqEnabled = action.payload;
    },

    setBlastsUploadAccess: (state, action) => {
      state.blastsUploadAccess = action.payload;
    },
    setDripsUploadAccess: (state, action) => {
      state.dripsUploadAccess = action.payload;
    },
  },
});

export const getStates4User = (state) => state.user.userDetails;
export const getIsHayleyFaqEnabled = (state) => state.user.isHayleyFaqEnabled;
export const getIsBlastsUploadAccess = (state) => state.user.blastsUploadAccess;
export const getIsDripsUploadAccess = (state) => state.user.dripsUploadAccess;

export const {
  setStates4User,
  setIsHayleyFaqEnabled,
  setBlastsUploadAccess,
  setDripsUploadAccess,
} = userDetailsSlice.actions;
export default userDetailsSlice.reducer;
