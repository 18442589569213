import React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { create_path, check_if_selected } from "../utils";
import clsx from "clsx";
import { useLocation } from "react-router-dom";

const ShortSubMenuItem = ({
  to,
  title,
  icon_type,
  children,
  pathname,
  link_component,
  parent_to,
  clear_target,
  classes,
}) => {
  const { subMenuItem, selectedSubMenuItem, subMenuItemText, displayIconPopUp } = classes;
  const pathTo = React.useMemo(() => create_path(to || parent_to), [to, parent_to]);
  const data_selected = React.useMemo(
    () => check_if_selected(children, to, pathname),
    [children, to, pathname]
  );
  const { search } = useLocation();

  return (
    <ListItem
      key={title}
      component={link_component}
      to={{
        ...pathTo,
        search: pathTo.pathname.includes("success-center-blast/property-") && search,
      }}
      onClick={clear_target}
      classes={{
        root: subMenuItem,
      }}
    >
      <div className={displayIconPopUp}>
        <div>{icon_type}</div>
        <ListItemText
          inset
          primary={title}
          classes={{
            root: subMenuItemText,
            primary: clsx(subMenuItemText, data_selected && selectedSubMenuItem),
          }}
        />
      </div>
    </ListItem>
  );
};

export default React.memo(ShortSubMenuItem);
