/**
 * @flow
 * @relayHash ad5ef9bdd9bb92e06d91ac2154118821
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getListPublicOffersQueryVariables = {|
  offerId?: ?string,
  propertyIds?: ?$ReadOnlyArray<string>,
|};
export type getListPublicOffersQueryResponse = {|
  +listPublicOffers: {|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +campaignTitle: ?string,
        +offerTitle: ?string,
        +propertyName: ?string,
        +macroDict: ?any,
        +designOffer: ?string,
        +subTitle: ?string,
      |}
    |}>
  |}
|};
export type getListPublicOffersQuery = {|
  variables: getListPublicOffersQueryVariables,
  response: getListPublicOffersQueryResponse,
|};
*/


/*
query getListPublicOffersQuery(
  $offerId: ID
  $propertyIds: [String!]
) {
  listPublicOffers(offerId: $offerId, propertyIds: $propertyIds) {
    edges {
      node {
        campaignTitle
        offerTitle
        propertyName
        macroDict
        designOffer
        subTitle
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "offerId",
    "type": "ID",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "propertyIds",
    "type": "[String!]",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "offerId",
    "variableName": "offerId"
  },
  {
    "kind": "Variable",
    "name": "propertyIds",
    "variableName": "propertyIds"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "campaignTitle",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "offerTitle",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "propertyName",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "macroDict",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "designOffer",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "subTitle",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getListPublicOffersQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "listPublicOffers",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "PublicOfferSettingConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "PublicOfferSettingEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "PublicOfferSetting",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "getListPublicOffersQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "listPublicOffers",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "PublicOfferSettingConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "PublicOfferSettingEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "PublicOfferSetting",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "id",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "getListPublicOffersQuery",
    "id": null,
    "text": "query getListPublicOffersQuery(\n  $offerId: ID\n  $propertyIds: [String!]\n) {\n  listPublicOffers(offerId: $offerId, propertyIds: $propertyIds) {\n    edges {\n      node {\n        campaignTitle\n        offerTitle\n        propertyName\n        macroDict\n        designOffer\n        subTitle\n        id\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '357c73664db7e7622e0b67aafd247743';
module.exports = node;
