import React from "react";
import { SvgIcon } from "@mui/material";

export default function UndoIcon({ className = "text-[#333333]", size = 16 }) {
  return (
    <svg
      className={className}
      width={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1_7812)">
        <path
          d="M15.3335 6.00004H5.3335C4.09582 6.00004 2.90883 6.49171 2.03366 7.36688C1.15849 8.24205 0.666829 9.42903 0.666829 10.6667C0.666829 11.9044 1.15849 13.0914 2.03366 13.9665C2.90883 14.8417 4.09582 15.3334 5.3335 15.3334H10.0002"
          stroke="currentColor"
          stroke-linecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.6668 10.6667L15.3335 6.00008L10.6668 1.33342"
          stroke="currentColor"
          stroke-linecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_7812">
          <rect width="16" height="16" fill="white" transform="translate(16 16) rotate(-180)" />
        </clipPath>
      </defs>
    </svg>
  );
}
