import React from "react";
import { SvgIcon } from "@mui/material";

const SearchIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M13.2497 13.2502L9.58594 9.58643"
        stroke="currentColor"
        strokeMiterlimit="10"
        fill="#fff"
      />
      <path
        d="M5.92241 11.0948C8.77906 11.0948 11.0948 8.77906 11.0948 5.92241C11.0948 3.06577 8.77906 0.75 5.92241 0.75C3.06577 0.75 0.75 3.06577 0.75 5.92241C0.75 8.77906 3.06577 11.0948 5.92241 11.0948Z"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="square"
        fill="#fff"
      />
    </SvgIcon>
  );
};

export default SearchIcon;
