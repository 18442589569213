import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { useHayley, useWindowSize } from "hooks";
import { getPropertyId } from "common";

const ChatBotIframe = ({ isRentOrg, classes }) => {
  const hayleyIframeRef = useRef(null);
  const refreshValue = useSelector((state) => state.hayley.refreshValue);
  const fetchingData = useSelector((state) => state.hayley.fetchingData);
  const hayleyPosition = useSelector((state) => state.hayley.position);
  const numericPropertyId = window.atob(getPropertyId()).replace(/[^0-9]/g, "");
  const visibleChatbot = useHayley(
    hayleyIframeRef,
    hayleyPosition,
    numericPropertyId,
    isRentOrg,
    true
  );

  const { width } = useWindowSize();
  return (
    <div
      className={`relative w-full mt-[12px] h-[calc(100vh-162px)] bg-white mr-[13px] border border-[#DADADA] px-[20px] text-center`}
    >
      <div className="mt-4 text-sm">
        <span className="text-[#999]">Preview:</span> Property website
      </div>
      <iframe
        title="hayley"
        allowFullScreen="allowFullScreen"
        src={`${process.env.REACT_APP_HAYLEY_LINK}?property_id=${numericPropertyId}&position=${hayleyPosition}&refresh=${refreshValue}&parentWidth=${width}&isjai=1`}
        id="haylay-iframe2"
        className={`${
          hayleyPosition === "bottom-left" ? "absolute" : "fixed"
        } z-[999] border-none translate-y-full transition-all ${
          !fetchingData && visibleChatbot ? "visible" : "invisible"
        }${hayleyPosition !== "sticky-right" ? " transition-all duration-500" : ""}`}
        style={
          hayleyPosition !== "sticky-right"
            ? { transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)", zIndex: 1300 }
            : { zIndex: 1300 }
        }
        ref={hayleyIframeRef}
      />
    </div>
  );
};

export default ChatBotIframe;
