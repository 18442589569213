import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Dialog from "@mui/material/Dialog";
import BarCardsDetailsPage from "./barCardsDetailsPage";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import Grid from "@mui/material/Grid";
import FullScreenLine from "./fullscreenLineGraph";
import Table from "./table";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "fixed",
    backgroundColor: "#fff",
    border: "1px solid #eee",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    color: "#333333",
    fontSize: 20,
  },
  closeicon: {
    color: "#333333",
    "&:hover": {
      color: "#26BBED !important",
    },
  },
  container: { width: "100%", height: "100%" },
  barcontainer: { width: "100%", height: "auto" },
  IconBtn: {
    color: "#333",
    padding: 0,
    marginLeft: 20,
    marginRight: 24,
  },
}));

const FullScreenItem = (props) => {
  if (props.line) {
    return (
      <FullScreenLine
        data={props.data}
        x={props.other.x}
        y={props.other.y}
        color={props.other.color}
        noDecimalLeft
      />
    );
  }

  if (props.table) {
    return (
      <Table
        data={props.data}
        columns={props.columns}
        color={props.color}
        heatmapColor={props.heatmapColor}
        limit={props.limit}
        tourInsights={props.tourInsights}
      />
    );
  }

  return (
    <BarCardsDetailsPage
      data={props.data}
      title={props.title}
      indexby={props.other.indexby}
      keys={props.other.keys}
      left={220}
      duration=""
      color={props.other.color}
      contrast={props.other.contrast}
      noDecimalBottom
    />
  );
};

const FullScreenDialog = (props) => {
  let length = (props.data || []).length;
  const classes = useStyles();
  return (
    <div>
      <Dialog
        fullScreen
        open={props.open}
        onClose={props.handleClose}
        TransitionComponent={Transition}
      >
        <AppBar elevation={0} className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={props.handleClose}
              aria-label="close"
              classes={{ root: classes.IconBtn }}
              size="large"
            >
              <CloseIcon className={classes.closeicon} />
            </IconButton>
            <Typography variant="h2" className={classes.title}>
              {props.title}
            </Typography>
          </Toolbar>
        </AppBar>
        <Grid
          className={props.line ? classes.container : classes.barcontainer}
          style={
            props.line
              ? {}
              : {
                  height: length * (length > 12 ? 40 : 60) + 105,
                  marginTop: 64,
                }
          }
          container
        >
          <FullScreenItem {...props} />
        </Grid>
      </Dialog>
    </div>
  );
};

export default FullScreenDialog;

//design .... either use barcard component or line card component based on a prop
