import React from "react";

export default function HomeEyeIcon({ className = "text-[#999999]", width = 18, height = 18 }) {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 9.55566V15.1112H9"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3668 7.47156L8.44461 3.44434L2.3335 8.44434"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.6875 12.5225C8.62012 12.4262 8.58398 12.3115 8.58398 12.1939C8.58398 12.0763 8.62012 11.9616 8.6875 11.8653C9.12445 11.2467 10.2003 9.97168 11.6394 9.97168C13.0786 9.97168 14.1544 11.2467 14.5914 11.8653C14.6588 11.9616 14.6949 12.0763 14.6949 12.1939C14.6949 12.3115 14.6588 12.4262 14.5914 12.5225C14.1544 13.1411 13.0786 14.4161 11.6394 14.4161C10.2003 14.4161 9.12445 13.1411 8.6875 12.5225Z"
        stroke="currentColor"
        stroke-width="0.75"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6394 13.3052C12.2531 13.3052 12.7505 12.8078 12.7505 12.1941C12.7505 11.5805 12.2531 11.083 11.6394 11.083C11.0258 11.083 10.5283 11.5805 10.5283 12.1941C10.5283 12.8078 11.0258 13.3052 11.6394 13.3052Z"
        stroke="currentColor"
        stroke-width="0.5"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
