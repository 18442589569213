import React from "react";

function Icon({ fill = "#26BBED", className = "text-[#999999]", width = 18, height = 18 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      fill="none"
      viewBox="0 0 12 12"
    >
      <path
        fill={fill}
        d="M12 2.7l-.6-1.2C6.225 3 3.6 6.3 3.6 6.3L1.2 4.5 0 5.7l3.6 4.8C6.375 5.325 12 2.7 12 2.7z"
      ></path>
    </svg>
  );
}

export default Icon;
