import { fetchQuery } from "relay-runtime";
import environment from "environments/environment";

import getDripPreviewQuery from "./queries/getDripPreview";
import getDripEditorQuery from "./queries/getDripEditor";
import getDripEditorNewQuery from "./queries/getDripEditorNew";
import getDripEditorPreviewQuery from "./queries/getDripEditorPreview";
import getPlatformsReportsQuery from "./queries/getPlatformsReports";
import getDripsListQuery from "./queries/getDripsList";
import getDripsListByEmailQuery from "./queries/getDripsListByEmail";
import getReportStatQuery from "./queries/getReportStat";
import getDripsEmailsQuery from "./queries/getDripsEmails";
import getDripContactsDashboardQuery from "./queries/getDripContactsDashboard";
import getMJAIEditorPreviewQuery from "./queries/getMJAIEditorPreview";
import getPropertyReportStatQuery from "./queries/getPropertyReportStat";

const getDripPreview = (angelId, id) => {
  let variables = { angelId, id };
  return fetchQuery(environment, getDripPreviewQuery, variables)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

const getDripEditor = (angelId, id, appName) => {
  let variables = { angelId, id, appName };
  return fetchQuery(environment, getDripEditorQuery, variables)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

const getMJAIEditor = (id) => {
  let variables = { id };
  return fetchQuery(environment, getDripEditorNewQuery, variables)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

const getEditorPreview = (angelId, id, fields, subject, sliceOrder) => {
  let variables = { angelId, id, fields, subject, sliceOrder };
  return fetchQuery(environment, getDripEditorPreviewQuery, variables)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

const getEditorPreviewJAI = (id, fields, subject, sliceOrder, isVariant) => {
  let variables = { id, fields, subject, sliceOrder, isVariant };

  return fetchQuery(environment, getMJAIEditorPreviewQuery, variables)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

const getPlatformReports = ({ dashboardId, params }) =>
  fetchQuery(environment, getPlatformsReportsQuery, { dashboardId, params });

const getDripsList = ({ dashboardId, selectedPropertyIds, startAt, endAt }) =>
  fetchQuery(environment, getDripsListQuery, {
    dashboardId,
    selectedPropertyIds,
    startAt,
    endAt,
    drip: true,
  });

const getDripsListByEmail = (variables) =>
  fetchQuery(environment, getDripsListByEmailQuery, {
    ...variables,
  });

const getReportStat = (dashboardId, params) => {
  let variables = { dashboardId, params };
  return fetchQuery(environment, getReportStatQuery, variables)
    .then((res) => {
      return res.tenantOrCampaignStat;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

const getDripsEmails = (orgId, propertyId) => {
  return fetchQuery(environment, getDripsEmailsQuery, { orgId, propertyId });
};

const getDripContactsDashboard = (dashboardId, params) => {
  let variables = { dashboardId, params };
  return fetchQuery(environment, getDripContactsDashboardQuery, variables)
    .then((res) => {
      return res.eblastContactsDashboard;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

const getPropertyReport = (dashboardId, params) => {
  let variables = { dashboardId, params };
  return fetchQuery(environment, getPropertyReportStatQuery, variables)
    .then((res) => {
      return res.tenantOrCampaignStat;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

export {
  getDripPreview,
  getDripEditor,
  getMJAIEditor,
  getEditorPreview,
  getPlatformReports,
  getDripsList,
  getDripsListByEmail,
  getReportStat,
  getDripsEmails,
  getDripContactsDashboard,
  getEditorPreviewJAI,
  getPropertyReport,
};
