import React from "react";

export default function SearchIcon({ className = "text-[#999999]", size = 16 }) {
  return (
    <svg
      className={className}
      width={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12.9999 12.9999L10.0688 10.0688" stroke="currentColor" strokeMiterlimit="10" />
      <path
        d="M7.13793 11.2759C9.42325 11.2759 11.2759 9.42325 11.2759 7.13793C11.2759 4.85261 9.42325 3 7.13793 3C4.85261 3 3 4.85261 3 7.13793C3 9.42325 4.85261 11.2759 7.13793 11.2759Z"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  );
}
