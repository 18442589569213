import React from "react";
import { Grid, Skeleton } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import DrillDownCard from "./drillDownCard";
import { Collapse } from "reactstrap";
import { IconUpArrow } from "icons";

const styles = () => ({
  super: {
    display: "flex",
    gap: "18px",
    flexWrap: "wrap",
  },
  root: {
    marginTop: 18,
  },
  bar: {
    height: 50,
    width: "100%",
    padding: 14,
    border: "1px solid #dfdfdf",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    lineHeight: "20px",
    marginTop: 16,
    fontWeight: "600",
    cursor: "pointer",
    "&:hover": {
      background: "#26bbed0f",
      color: "var(--hylyTheme)",
    },
    "&:hover path": {
      stroke: "var(--hylyTheme)",
    },
  },
  close: {},
  open: {
    transform: "rotate(180deg)",
  },
  gridStyle: {
    width: "calc(100% + 20px)",
    margin: -10,
  },
  cardStyle: {
    padding: 10,
  },
});

const ExpansionBar = ({ text, show, onClick, classes, open, percentCheck }) => {
  if (!show) return null;
  return (
    <div className={classes.bar} onClick={onClick}>
      {text}{" "}
      <div className={open ? classes.open : classes.close}>
        <IconUpArrow />
      </div>
    </div>
  );
};

const DrillDownView = ({
  classes,
  data,
  showCount,
  showPreview,
  numberTBR = 8,
  loading,
  ...props
}) => {
  const [expanded, setExpanded] = React.useState(false);

  const renderCards = (item, index) => (
    <Grid item xs={12} md={6} className={classes.cardStyle} key={index}>
      <DrillDownCard data={item} showCount={showCount} showPreview={showPreview} {...props} />
    </Grid>
  );

  const handleToogleExpansion = () => setExpanded(!expanded);

  if (loading) {
    return (
      <div className={classes.root}>
        <Grid container className={classes.gridStyle}>
          {[...Array(8)].map((_, rowIndex) => (
            <Grid item xs={12} md={6} className={classes.cardStyle} key={rowIndex}>
              <Skeleton height={145} variant="rectangular" />
            </Grid>
          ))}
        </Grid>
      </div>
    );
  }

  if (!data || data.length === 0) return null;

  let slicedData = expanded ? data : data.slice(0, numberTBR);

  return (
    <div className={classes.root}>
      <Grid container className={classes.gridStyle}>
        {slicedData.map(renderCards)}
      </Grid>
      {data.length > numberTBR ? (
        <div className={classes.root}>
          <ExpansionBar
            show={!expanded}
            classes={classes}
            onClick={handleToogleExpansion}
            text={`Show all (${data.length - numberTBR} Others)`}
            open={expanded}
          />
          <ExpansionBar
            show={expanded}
            classes={classes}
            onClick={handleToogleExpansion}
            text={`Hide (${data.length - numberTBR} Others)`}
            open={expanded}
          />
        </div>
      ) : null}
    </div>
  );
};

export default withStyles(styles)(DrillDownView);
