const styles = () => ({
  reportHeader: {
    fontFamily: "Open Sans",
    fontSize: 20,
    lineHeight: "30px",
  },
  dialogRoot: {
    width: 500,
    background: "white",
  },
  reportText: {
    fontFamily: "Open Sans",
    fontSize: 16,
    lineHeight: "24px",
    marginBottom: 12,
    fontWeight: 400,
  },
  bold: {
    fontWeight: 700,
  },
  button: {
    height: 40,
    padding: "10px 24px",
    fontSize: 14,
    lineHeight: "20px",
    fontWeight: 600,
    marginLeft: 12,
    textTransform: "none",
  },
  removeButton: {
    height: 40,
    padding: "10px 24px",
    fontSize: 14,
    lineHeight: "20px",
    fontWeight: 600,
    marginLeft: 12,
    textTransform: "none",
    color: "#E75F5F",
    border: "1px solid #E75F5F",
    "&:hover": {
      color: "#E75F5F !important",
      border: "1px solid #E75F5F !important",
    },
  },
  label: {
    fontSize: 12,
    color: "#333333",
    opacity: 0.7,
    lineHeight: "24px",
  },
  errTxt: {
    fontSize: 12,
    color: "#f44336",
  },
});

export default styles;
