import React from "react";

function ArrowRight({ className = "text-[#333333] w-6" }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className={className}>
      <path fill="none" d="M0 0H24V24H0z"></path>
      <path
        stroke="currentColor"
        strokeLinejoin="round"
        d="M8.889 6.556l6.222 5.185-6.222 5.703"
      ></path>
    </svg>
  );
}

export default ArrowRight;
