import React, { Fragment } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import partition from "lodash/partition";
import groupBy from "lodash/groupBy";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import SearchField from "../../../SearchField";
import ListComponent from "../ListComponent";
import Loader from "../../../Loader";
import TopNav from "../../../TopNav";
import GroupedList from "../../../SearchField/components/SimpleList/SimpleList";
import makeStyles from "@mui/styles/makeStyles";

import { getDisableOrgLevelClick } from "redux/slices";
import { useSelector } from "react-redux";

const topNavStyle = makeStyles((theme) => ({ static_position: { position: "static" } }));

const TopNavComponent = React.memo((props) => {
  const classes = topNavStyle();
  return <TopNav className={classes.static_position} {...props} />;
});

const containerStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.white,
    boxShadow: "0px 4px 4px rgba(204, 204, 204, 0.25)",
    flex: 1,
    width: "100%",
    height: "100%",
    border: "none", //`1px solid ${theme.palette.divider}`,
    position: "relative",
  },
  divider: {
    position: "relative",
    height: "100%",
    padding: 0,
    "&:last-child": {
      paddingBottom: 0,
    },
  },
  grid_container: {
    height: "100%",
  },
  section: {
    flex: 1,
    height: "100%",
    "&:first-child": {
      borderRight: "1px solid #eee",
    },
  },
  list_section_container: {
    position: "relative",
    width: "100%",
    height: "100%",
  },
}));

const ListsContainer = React.memo(
  ({
    children,
    suggestions,
    temp_org,
    selected_org,
    selected_property,
    selectOrg,
    changeOrg,
    selectProperty,
    full,
    search_value,
    setOrgs,
    selected_orgs,
    tempOrgIndex,
    keyPressed,
    isLoading,
    ...props
  }) => {
    const classes = containerStyles();
    const [store, setStore] = React.useState({});
    const [bigOrgStore] = React.useState(new Map());

    React.useEffect(() => {
      for (const org of props.orgs) {
        bigOrgStore.set(org.id, org);
      }
    }, [props.orgs]);

    React.useEffect(() => {
      const [orgs_, suborgs_] = partition(suggestions, (o) => o.type === "org");
      const suborgs = groupBy(suborgs_, "orgId");
      const orgsStore = groupBy(orgs_, "id");

      let neworgs = [];
      bigOrgStore.forEach((org, orgId) => {
        if (suborgs[orgId] || orgsStore[orgId]) {
          neworgs.push(org);
        }
      });
      setOrgs(neworgs);

      for (let org of orgs_) {
        suborgs[org.id] = bigOrgStore.get(org.id).suborgs;
      }
      setStore(suborgs);
    }, [bigOrgStore, suggestions, props.orgs]);

    const suborgsSuggestions = React.useMemo(() => {
      if (temp_org && store[temp_org.id]) {
        return store[temp_org.id];
      } else if (temp_org && temp_org.name) {
        return [];
      } else if (store[selected_org.id]) {
        return store[selected_org.id];
      }
      return bigOrgStore.get(selected_org.id) && bigOrgStore.get(selected_org.id).suborgs;
    }, [bigOrgStore, store, temp_org, selected_org]);

    return (
      <Card className={classes.root}>
        <CardContent className={classes.divider}>
          <Grid container className={classes.grid_container}>
            {isLoading ? (
              <Loader />
            ) : (
              <>
                <Grid item xs={6} className={classes.section}>
                  <div className={classes.list_section_container}>
                    <ListComponent
                      suggestions={selected_orgs}
                      selected_value={selected_org}
                      onSelect={selectOrg}
                      onmouseover_function={changeOrg}
                      maxSuggestions={1000}
                      search_value={search_value}
                      temp_org={temp_org}
                      tempOrgIndex={tempOrgIndex}
                      keyPressed={keyPressed}
                    />
                  </div>
                </Grid>
                <Grid item xs={6} className={classes.section}>
                  <div className={classes.list_section_container}>
                    {
                      <GroupedList
                        suggestions={suborgsSuggestions}
                        selected_value={selected_property}
                        onSelect={selectProperty}
                        type="no-fetch"
                        maxSuggestions={1000}
                        search_value={search_value}
                        isRentOrg={props.isRentOrg}
                        tempOrg={temp_org}
                        selectedOrg={selected_org}
                      />
                    }
                  </div>
                </Grid>
              </>
            )}
          </Grid>
        </CardContent>
      </Card>
    );
  }
);

let KeyTimer;

export default React.memo(({ close_bottom_section, ...props }) => {
  const [selected_orgs, setOrgs] = React.useState([]);
  const [prevIndex, setPrevIndex] = React.useState();
  const [keyPressed, setKeyPressed] = React.useState(null);

  const matches = useMediaQuery("(min-width:768px)");

  const Container = React.useMemo(() => {
    return matches ? ({ children }) => <Fragment>{children}</Fragment> : TopNavComponent;
  }, [matches]);

  React.useEffect(() => {
    const prev =
      props.temp_org && props.temp_org.id === props.selected_org.id
        ? -1
        : props.temp_org
        ? selected_orgs.findIndex((o) => props.temp_org && o.id === props.temp_org.id)
        : 0;
    setPrevIndex(prev);
  }, [selected_orgs, props.temp_org]);

  const onKeyDown = React.useCallback((key) => {
    clearTimeout(KeyTimer);
    if (!keyPressed || keyPressed !== key) {
      setKeyPressed(key);
    }
    let org_index;
    const goingUp = key === "ArrowUp",
      goingDown = key === "Enter" || key === "ArrowDown";
    if (goingDown) {
      org_index = prevIndex + 1;
    } else if (goingUp) {
      org_index = Math.max(-1, prevIndex - 1);
    }

    const hovered_org = selected_orgs[org_index];
    // skip selected org
    if (hovered_org && hovered_org.id !== props.selected_org.id) {
      props.changeOrg(selected_orgs[org_index]);
    } else if (hovered_org) {
      const addition = goingUp ? -1 : +1;
      if (selected_orgs[org_index + addition]) {
        props.changeOrg(selected_orgs[org_index + addition]);
      } else {
        props.changeOrg(props.selected_org);
      }
    } else {
      props.changeOrg(props.selected_org);
    }

    KeyTimer = setTimeout(() => setKeyPressed(null), 200);
  });

  const changeOrg = React.useCallback((org, type) => {
    if (keyPressed) {
      return;
    }
    props.changeOrg(org);
  });

  return (
    <Container pathnames="" no_shadow no_border toggleDrawer={close_bottom_section}>
      <SearchField
        fetch_type="no-fetch"
        selected_icon_type="icon-check-single"
        ListComponent={ListsContainer}
        suggestions={props.suggestions}
        default_search_value={"Search for an Org or Property..."}
        onKeyDown={onKeyDown}
        full={!matches}
        setOrgs={setOrgs}
        selected_orgs={selected_orgs}
        tempOrgIndex={prevIndex}
        keyPressed={keyPressed}
        initial_open
        {...props}
        changeOrg={changeOrg}
      />
    </Container>
  );
});
