import graphql from "babel-plugin-relay/macro";

export default graphql`
  query retrieveFaqItemsQuery($jobId: String!) {
    retrieveFaqItemsByJobId(jobId: $jobId) {
      answer {
        faqItems {
          id
          image {
            fileUrl
            id
          }
          imageId
          itemDescription
          editable
          selected
          itemTitle
          section {
            sectionName
            sectionType
          }
          tenantId
        }
        id
        section
      }
      id
      question {
        faqType
        id
        orgName
        propertyName
        questionId
        subject
        tenantId
        unorganizedAnswer
        value
      }
    }
  }
`;
