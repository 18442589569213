var emailRegexp =
  /^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

export default (data, endPoint, emailType, id, userToken, subject) => {
  return `

<meta property="og:url"  content="http://www.nytimes.com/2015/02/19/arts/international/when-great-minds-dont-think-alike.html" />
<meta property="og:type" content="article" />
<meta property="og:title" content="When Great Minds Don’t Think Alike" />
<meta property="og:description" content="How much does culture influence creative thinking?" />
<meta property="og:image" content="http://static01.nyt.com/images/2015/02/19/arts/international/19iht-btnumbers19A/19iht-btnumbers19A-facebookJumbo-v2.jpg" />

<style>
@import url("https://fonts.googleapis.com/css?family=Raleway");
@import url("https://fonts.googleapis.com/css?family=Open+Sans");

.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 40%; 
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  width: 500px;
  border: 1px solid #888;
}

@media only screen and (max-width: 400px) {
  .modal-content {
   width:250px;
 }
}

.modal-top{
  padding: 20px;
  display:flex;
  align-items:center;
  justify-content:space-between;
}

.modal-divider{
   width: 100%;
   height:1px;
   background-color:#dadada;
}
.modal-bottom {
  padding: 24px 20px;
}
 

.close {
  color: #aaaaaa;
  font-size: 30px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #26BBED;
  text-decoration: none;
  cursor: pointer;
}
.btn-style {
    color: white;
    cursor: pointer;
    padding: 12px 14px;
    font-size: 14px;
    background: #26BBED;
    align-items: center;
    font-weight: 600;
    border: none;
    outline: none;
    height: 40px;
    width: 155px;
    margin-top:32px;
}
.btn-style:hover {
  background: #10A8DB;
}
.need-access {
  font-size:20px;
  color: #333333;
  font-family: Open Sans;
   font-style: normal;
}
.text-class {
  font-size: 16px;
  color: #333333;
  margin-bottom:20px;
  line-height:24px;
}

.content{

  border-top:none;
}

.content input{
  border: none;
  border-bottom: 1px solid #dadada;
  width: 100%;
  margin-top: 20px;

  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
}

.share-form-field .guide{
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #999999;
}
.share-form-field label {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #333333;
}

.email-text{
  border: 1px solid #dadada;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  padding:10px;
  width:-webkit-fill-available !important;
}

.multi-email-input{
  margin: 0;
  max-width: 100%;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  outline: 0;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  text-align: left;
  line-height: 1.21428571em;
  background: #fff;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 0.1s ease, border-color 0.1s ease;
  transition: box-shadow 0.1s ease, border-color 0.1s ease;
  font-size: 13px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: flex-start;
  padding-bottom:10px;
}


.multi-email-input input{
  margin:0!important;
  border-bottom: 1px solid #dadada;
}

.multi-email-input input:hover{
   border-bottom: 1px solid var(--hylyTheme);
}
.multi-email-input input:focus{
   border-bottom: 1px solid #999999;
}

.multi-email-input > span[data-placeholder] {
  display: none;
  position: absolute;
  left: 0.5em;
  top: 0.4em;
  padding: 0.4em;
  line-height: 1.21428571em;
}
.multi-email-input.focused {
  border-color: #85b7d9;
  background: #fff;
}

.multi-email-input.empty > span[data-placeholder] {
  display: inline;
  color: #ccc;
}
.multi-email-input.focused > span[data-placeholder] {
  display: none;
}

.multi-email-input > input {
  outline: none !important;
  display: inline-block !important;
  line-height: 1;
  vertical-align: baseline !important;
  padding: 0.4em 0.1em !important;
}

.multi-email-input [data-tag] {
  line-height: 1;
  vertical-align: baseline;
  margin: 0.14285714em;
  background-color: #f3f3f3;
  background-image: none;
  padding: 0.5833em 0.833em;
  color: rgba(0, 0, 0, 0.6);
  text-transform: none;
  font-weight: 600;
  border: 0 solid transparent;
  border-radius: 0.28571429rem;
  -webkit-transition: background 0.1s ease;
  -o-transition: background 0.1s ease;
  transition: background 0.1s ease;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
  gap:5px;
}
.multi-email-input [data-tag] [data-tag-item] {
  max-width: 100%;
  overflow: hidden;
}
.multi-email-input [data-tag]:first-child {
  margin-left: 0;
}
.multi-email-input [data-tag] [data-tag-handle] {
  margin-left: 0.833em;
  cursor: pointer;
}
.email-tag{
  border: 1px solid #c9c9cb;
  padding: 5px;
  border-radius: 22px;
  display:flex;
  align-items:center;
  justify-content:space-between;
  font-size:14px;
  padding-top:6px;
}
.email-tag:hover{
  border: 1px solid #26BBED;
  cursor:pointer;
  background-color: rgba(0, 0, 0, 0.04);
}
.email-tag svg{
  width: 16px;
  height: 16px;
  stroke-width: 3px;
  margin-top:2px;
}
.email-tag svg:hover path{
  stroke:red;
  cursor:pointer;
}
.error-email-tag {
  border-color:red;
}
.error-email-tag:hover {
  border-color:red;
}
.button-container {
  width:100%;
  display:flex;
  justify-content:flex-end;
}

#snackToastBar {
  visibility: hidden;
  margin-left: -250px;
  background: #E75F5F;
  box-shadow: 0px 4px 4px rgba(204, 204, 204, 0.25);
  color: #fff;
  text-align: center;
  border-radius: 2px;
  position: fixed;
  z-index: 1;
  left: 50%;
  top: 30px;
  font-size: 14px;
  width:500px;
  font-weight:600;
  color:#FFFFFF;
  line-height:20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height:64px;
}

#snackToastBarText {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  width:427px;
  text-align:left;
  padding-left:12px;
}

#snackToastBar.show-red {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 4.6s;
  animation: fadein 0.5s, fadeout 0.5s 4.6s;
}

#snackToastBar.show-success {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 4.6s;
  animation: fadein 0.5s, fadeout 0.5s 4.6s;
  background:#66D184;
}


#snackToastBar.hide {
  visibility: hidden;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 4.6s;
  animation: fadein 0.5s, fadeout 0.5s 4.6s;
}


@-webkit-keyframes fadein {
  from {top: 0; opacity: 0;} 
  to {top: 30px; opacity: 1;}
}

@keyframes fadein {
  from {top: 0; opacity: 0;}
  to {top: 30px; opacity: 1;}
}

@-webkit-keyframes fadeout {
  from {top: 30px; opacity: 1;} 
  to {top: 0; opacity: 0;}
}

@keyframes fadeout {
  from {top: 30px; opacity: 1;}
  to {top: 0; opacity: 0;}
}

#mtdiv {
  width: 15px;
  height: 15px;
  margin-right: 20px;
}

#mtdiv:hover {
 cursor: pointer;
}

.mtdiv {
  height: 15px;
  width: 1px;
  margin-left: 12px;
  background-color: white;
  transform: rotate(45deg);
  Z-index: 1;
}

.mtd {
  height: 15px;
  width: 1px;
  background-color: white;
  transform: rotate(90deg);
  Z-index: 2;
}
</style>

<script>


function showSnackToast(text,type) {
  var x = document.getElementById("snackToastBar");
  var y = document.getElementById("snackToastBarText");
  y.innerHTML = text;
  x.className =type ==="error" ?"show-red" :"show-success" ;
  setTimeout(function () { x.className = x.className.replace("show", "hide"); }, 5000)
}

 function onClose() {
  var x = document.getElementById("snackToastBar");
  x.className = "hide";
 }

  function onClickLink(){
    return false
  }

  function openToastPoppup(evt) {
    evt.parentElement.removeAttribute("href");
    var modal = document.getElementById("myModal");
    modal.style.display = "block";
  }


  function closeToastPoppup() {
    var modal = document.getElementById("myModal");
   modal.style.display = "none";
  }

  
  function isValidEmail(email) {
    return ${emailRegexp}.test(email);
  }

  function removeEmailFromList(tag, eml){
    let email = eml || tag.parentElement.previousSibling.innerHTML.trim();
    let all = document.getElementById("all-emails");
    for(let i =0; i < all.children.length - 1; i++){
      if(all.children[i].children[0].innerHTML == email){
        all.removeChild(all.childNodes[i]);
        ifAnyInvalidEmail();
        return;
      }
    }
  }

  function editChipEmail(tag,eml){
    let email=eml||tag.innerHTML;
    removeEmailFromList(this,email);
    let input=document.getElementById("email-input"); 
    console.log("email",email,input)
    input.value=email;
  }


  function removeAllEmail(){
    let all = document.getElementById("all-emails");
    for(let i =0; i < all.children.length - 1; i++){
      all.removeChild(all.childNodes[i]);
      return;
    }
  }


  function removeLastEmail(){
    let inputValue=document.getElementById("email-input").value;
    if(!inputValue){
      let all = document.getElementById("all-emails");
      if(all.children.length > 1)
      all.removeChild(all.childNodes[all.children.length - 2]);
    }
  }


  function getAllEmails(){
    let r =[]
    let all = document.getElementById("all-emails").children;
    for(let i =0; i < all.length - 1; i++){
      r.push(all[i].children[0].innerHTML)
    }
    return r;
  }

  function ifAnyInvalidEmail(){
    let anyInvalidEmail=false;
    let allEmails= getAllEmails();
    if(allEmails.length===0){
      anyInvalidEmail=true
    }
    for(let i=0;i<allEmails.length;i++){
      if(!isValidEmail(allEmails[i])){
        anyInvalidEmail=true;
      }
    }
    if(anyInvalidEmail){
      let button=document.getElementById("email-send-button");
      button.style.opacity = 0.5;
      button.disabled = true;
    }else{
      let button=document.getElementById("email-send-button");
      button.style.opacity = 1;
      button.disabled = false;
    }
  }

  function findEmailAddress(value, isEnter){
    let inputValue = '';
    const re = /[ ,;]/g;
    const isEmail = isValidEmail;
    

    const addEmails = (email,isInvalid) => {
      const emails = getAllEmails();
      for (let i = 0, l = emails.length; i < l; i++) {
        if (emails[i] == email) {
          return false;
        }
      }

      let all = document.getElementById("all-emails");
      var newTag = document.createElement("span");
      newTag.className="email-tag";
      if(isInvalid){
        newTag.className=newTag.className + " error-email-tag";
      }

      var newEmail = document.createElement("span");
      newEmail.setAttribute("onclick", "editChipEmail(this)");
      newEmail.innerHTML = email;

      newTag.append(newEmail);

      var closeIcon = document.createElement("span");
      closeIcon.innerHTML = '&nbsp; &nbsp;\
        <svg onclick = "removeEmailFromList(this)" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\
          <path d="M19 5L5 19" stroke="#c9c9cb" stroke-miterlimit="10" stroke-linecap="square" />\
          <path d="M19 19L5 5" stroke="#c9c9cb" stroke-miterlimit="10" stroke-linecap="square" />\
        </svg>'.trim();
      newTag.append(closeIcon);

      all.insertBefore(newTag, all.childNodes[all.children.length - 1]);
      all.children[all.children.length-1].value = ""
      ifAnyInvalidEmail();
      return true;
    }

    if (value !== '') {
      let isInvalid=false;
      if (re.test(value)) {
        let splitData = value.split(re).filter(n => {
          return n !== '' && n !== undefined && n !== null;
        });

        const setArr = new Set(splitData);
        let arr = [...setArr];

        do {
          // if (isEmail('' + arr[0])) {
          //   addEmails('' + arr.shift());
          // } else {
          //   if (arr.length === 1) {
          //     inputValue = '' + arr.shift();
          //   } else {
          //     arr.shift();
          //   }
          // }
          if (isEmail('' + arr[0])) {
            isInvalid=false;
          } else {
            isInvalid=true;
          }
          addEmails('' + arr.shift(),isInvalid);
        } while (arr.length);
      } else {
        if (isEnter) {
          // if (isEmail(value)) {
          //   addEmails(value);
          // } else {
          //   inputValue = value;
          // }
          if (isEmail(value)) {
            isInvalid=false;
          } else {
            isInvalid=true;
          }
           addEmails(value,isInvalid);
        } else {
          inputValue = value;
        }
      }
    }
  }

  function handleOnKeyup(e){
    switch (e.which) {
      case 13:
      case 9:
        findEmailAddress(e.target.value, true);
        break;
      case 8:
        //removeLastEmail()
        break;
      default:
    }
  }

  handleOnChange = (e) => {
    findEmailAddress(e.currentTarget.value);
  }
subjectElement

  function sendTestEmai(){
    let emails = getAllEmails();
    if (!emails) return;
    let button=document.getElementById("email-send-button");
    button.style.opacity = 0.5;
    button.disabled = true;
    let token = document.getElementById('userTokenElement').value;
    let endpoint = document.getElementById('endpointElement').value;
    let id = document.getElementById('campaignIdElement').value;
     let subject = document.getElementById('subjectElement').value;
    let emailType = document.getElementById('emailTypeElement').value;
    let query;
    if(emailType==="ResidentReward"){
      query='mutation{ testResidentRewardEmail(input:{subject:"'+subject+'", recipients:"'+emails.join()+'", id:"'+id+'"}){ hyreferralCampaign{ id }}}'
    }else{
      query='mutation{ testFriendInviteEmail(input:{subject:"'+subject+'", recipients:"'+emails.join()+'", id:"'+id+'"}){ hyreferralCampaign{ id }}}'  
    }
    let options = {
       headers: { 'Content-Type': 'application/json' , Authorization:token}
    };
     options.body = JSON.stringify({ query });
     options.method = 'POST';
      fetch(endpoint, options).then(response => response.json())
      .then(response => {
        if(response.data){
          showSnackToast("You have successfully send the test Email","success");
        }else{
          showSnackToast("Something went wrong","error");
        }

      })
      .catch(err => {
        showSnackToast("Something went wrong","error");
        console.log("response==", err)
      })
    button.style.opacity = 1;
    button.disabled = false;
    closeToastPoppup();
  }

 
</script>

<div>

  <div id="snackToastBar">
      <div id="snackToastBarText">
      </div>
      <div id="mtdiv" onclick="onClose()">
        <div class="mtdiv">
          <div class="mtd"></div>
        </div>
      </div>
    </div>
<div id="myModal" class="modal">

  <div class="modal-content">
    <div class="modal-top">
      <div class="need-access">Need Access?</div>
      <div onclick="closeToastPoppup()" class="close">&times;</div>
     
    </div>    
    <div class="modal-divider">
    </div>
    <div class="modal-bottom">
     <div class="text-class">The link does not work in preview but you can send test email and try the link</div>


   <div class="content">
     <div class="share-form-field">
      <label>Email(s)</label>
      <div class="multi-email-input" id="all-emails"><span class="email-tag">
        <span onclick="editChipEmail(this,'refree@gmail.com')">refree@gmail.com</span> 
          <span>
            &nbsp;&nbsp;
            <svg onclick="removeEmailFromList(this, 'refree@gmail.com')" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19 5L5 19" stroke="#c9c9cb" stroke-miterlimit="10" stroke-linecap="square"/>
            <path d="M19 19L5 5" stroke="#c9c9cb" stroke-miterlimit="10" stroke-linecap="square"/>
            </svg>
          </span>
        </span>
        <input 
          id="email-input"  
          onkeyup="handleOnKeyup(event)"
          oninput="handleOnChange(event)"
        />
      </div>
      <div class="guide">Press Enter/Comma to add multiple emails</div>
    </div>
    </div>
    <div class="button-container">
      <button id="email-send-button"  class="btn-style" type="button"  onclick="sendTestEmai()"  >Send Test Email </button>
    </div>
    </div>
  </div>
</div>
  <input id="endpointElement" value="${endPoint}" type="hidden" />
  <input id="campaignIdElement" value="${id}" type="hidden" />
  <input id="emailTypeElement" value="${emailType}" type="hidden" />
  <input id="userTokenElement" value="${userToken}" type="hidden" />
  <input id="subjectElement" value="${subject}" type="hidden" />
</div>
  ${data.replace(/<button/, '<button type= "button" onclick="openToastPoppup(this)"')}
</div>
`;
};
