/**
 * @flow
 * @relayHash a3dc17c834fc27444367e292a39baa88
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getDripEditorQueryVariables = {|
  angelId: string,
  id: string,
  appName?: ?string,
|};
export type getDripEditorQueryResponse = {|
  +thenEmailEditor: {|
    +angel: {|
      +name: ?string
    |},
    +name: string,
    +subject: string,
    +active: boolean,
    +mms: boolean,
    +mmsBody: string,
    +mmsMediaUrl: ?string,
    +mmsStatus: string,
    +lastTestEmails: string,
    +lastTestPhones: ?string,
    +fields: $ReadOnlyArray<{|
      +editable: boolean,
      +label: string,
      +field: string,
      +dimension: ?string,
      +value: ?string,
      +type: string,
      +display: ?string,
      +sliceId: string,
      +sliceName: string,
      +elementId: string,
      +elementPart: string,
    |}>,
    +rawMacrosHtml: string,
    +substitutedMacrosHtml: string,
  |}
|};
export type getDripEditorQuery = {|
  variables: getDripEditorQueryVariables,
  response: getDripEditorQueryResponse,
|};
*/


/*
query getDripEditorQuery(
  $angelId: ID!
  $id: ID!
  $appName: String
) {
  thenEmailEditor(angelId: $angelId, id: $id, appName: $appName) {
    angel {
      name
      id
    }
    name
    subject
    active
    mms
    mmsBody
    mmsMediaUrl
    mmsStatus
    lastTestEmails
    lastTestPhones
    fields {
      editable
      label
      field
      dimension
      value
      type
      display
      sliceId
      sliceName
      elementId
      elementPart
      id
    }
    rawMacrosHtml
    substitutedMacrosHtml
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "angelId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "appName",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "angelId",
    "variableName": "angelId"
  },
  {
    "kind": "Variable",
    "name": "appName",
    "variableName": "appName"
  },
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "subject",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "active",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "mms",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "mmsBody",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "mmsMediaUrl",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "mmsStatus",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lastTestEmails",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lastTestPhones",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "editable",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "label",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "field",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dimension",
  "args": null,
  "storageKey": null
},
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "value",
  "args": null,
  "storageKey": null
},
v16 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "type",
  "args": null,
  "storageKey": null
},
v17 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "display",
  "args": null,
  "storageKey": null
},
v18 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "sliceId",
  "args": null,
  "storageKey": null
},
v19 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "sliceName",
  "args": null,
  "storageKey": null
},
v20 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "elementId",
  "args": null,
  "storageKey": null
},
v21 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "elementPart",
  "args": null,
  "storageKey": null
},
v22 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rawMacrosHtml",
  "args": null,
  "storageKey": null
},
v23 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "substitutedMacrosHtml",
  "args": null,
  "storageKey": null
},
v24 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getDripEditorQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "thenEmailEditor",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ThenEmailEditor",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "angel",
            "storageKey": null,
            "args": null,
            "concreteType": "Angel",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ]
          },
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "fields",
            "storageKey": null,
            "args": null,
            "concreteType": "EditorField",
            "plural": true,
            "selections": [
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/)
            ]
          },
          (v22/*: any*/),
          (v23/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "getDripEditorQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "thenEmailEditor",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ThenEmailEditor",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "angel",
            "storageKey": null,
            "args": null,
            "concreteType": "Angel",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v24/*: any*/)
            ]
          },
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "fields",
            "storageKey": null,
            "args": null,
            "concreteType": "EditorField",
            "plural": true,
            "selections": [
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/),
              (v24/*: any*/)
            ]
          },
          (v22/*: any*/),
          (v23/*: any*/),
          (v24/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "getDripEditorQuery",
    "id": null,
    "text": "query getDripEditorQuery(\n  $angelId: ID!\n  $id: ID!\n  $appName: String\n) {\n  thenEmailEditor(angelId: $angelId, id: $id, appName: $appName) {\n    angel {\n      name\n      id\n    }\n    name\n    subject\n    active\n    mms\n    mmsBody\n    mmsMediaUrl\n    mmsStatus\n    lastTestEmails\n    lastTestPhones\n    fields {\n      editable\n      label\n      field\n      dimension\n      value\n      type\n      display\n      sliceId\n      sliceName\n      elementId\n      elementPart\n      id\n    }\n    rawMacrosHtml\n    substitutedMacrosHtml\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '791bd04a6e15c747f577c761b3a68469';
module.exports = node;
