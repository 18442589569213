import graphql from "babel-plugin-relay/macro";

export default graphql`
  query getReportStatQuery($dashboardId: ID!, $params: Json) {
    tenantOrCampaignStat(dashboardId: $dashboardId, params: $params) {
      campaignStat
      tenantStat
      angelStat
      data {
        annotation
        audienceCounts
        blacklistedCount
        botId
        bouncesCount
        clicksCount
        complaintsCount
        eblastCount
        id
        linkClicks
        previewUrl
        publishedAt
        sendsCount
        sentAt
        tenantId
        thumbnailUrl
        uniqueClicksCount
        uniqueOpensCount
        unsubscribesCount
        audiences
      }
    }
  }
`;
