import React from "react";

export default function PushLeftIcon({ className = "text-[#333333]", size = 32 }) {
  return (
    <svg
      className={className}
      width={size}
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M16.5 16.5H8.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M12.5 12.5L8.5 16.5L12.5 20.5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.5 23.5L23.5 23.5L23.5 9.5L19.5 9.5L19.5 23.5Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
