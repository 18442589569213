/**
 * @flow
 * @relayHash 0453cae4544e8cd6e9e3fb0da7df548d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getHygroupLinksQueryVariables = {|
  tenantId: string
|};
export type getHygroupLinksQueryResponse = {|
  +hygroupLinks: $ReadOnlyArray<{|
    +id: string,
    +label: ?string,
    +url: ?string,
    +macro: ?string,
  |}>
|};
export type getHygroupLinksQuery = {|
  variables: getHygroupLinksQueryVariables,
  response: getHygroupLinksQueryResponse,
|};
*/


/*
query getHygroupLinksQuery(
  $tenantId: ID!
) {
  hygroupLinks(tenantId: $tenantId) {
    id
    label
    url
    macro
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "tenantId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "hygroupLinks",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "tenantId",
        "variableName": "tenantId"
      }
    ],
    "concreteType": "HygroupLink",
    "plural": true,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "label",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "url",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "macro",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getHygroupLinksQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "getHygroupLinksQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "getHygroupLinksQuery",
    "id": null,
    "text": "query getHygroupLinksQuery(\n  $tenantId: ID!\n) {\n  hygroupLinks(tenantId: $tenantId) {\n    id\n    label\n    url\n    macro\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7fa9fbf6a851639f29acf81986397323';
module.exports = node;
