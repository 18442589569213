import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import logoSrc from "./hylylogo.svg";
import rentlogo from "./rentlogo.png";
import rentFavIcon from "./rentFavIcon.png";
import hylyLogo from "./favicon copy.ico";
import clsx from "clsx";

export default React.memo(
  ({ classes, name, onClick, menu_open, swipeable, selectedOrg, isRentOrg }) => {
    const matchesSwipeable = useMediaQuery("(min-width:1280px)");

    React.useEffect(() => {
      if (isRentOrg) {
        var link = document.querySelector("link[rel~='icon']");
        if (!link) {
          link = document.createElement("link");
          link.rel = "icon";
          document.head.appendChild(link);
        }
        document.title = "Tour Calendar";
        link.href = rentFavIcon;
      } else {
        var link = document.querySelector("link[rel~='icon']");
        if (!link) {
          link = document.createElement("link");
          link.rel = "icon";
          document.head.appendChild(link);
        }
        link.href = hylyLogo;
      }
    }, [isRentOrg]);

    return (
      <ListItem classes={{ root: classes.headerRoot }} onClick={onClick}>
        {isRentOrg === null ? (
          <div></div>
        ) : (
          <ListItemIcon>
            <img
              className={clsx(classes.orgLogo, isRentOrg ? classes.rentLogo : classes.orgLogo)}
              src={isRentOrg ? rentlogo : logoSrc}
              alt="logo"
            />
          </ListItemIcon>
        )}
        {!matchesSwipeable && !menu_open ? (
          ""
        ) : (
          <ListItemText
            primary={isRentOrg ? "Tour Calendar" : swipeable ? name : menu_open ? name : ""}
            classes={{
              primary: classes.list_item,
            }}
          />
        )}
      </ListItem>
    );
  }
);
