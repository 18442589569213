import React from "react";

export default function TourScheduledIcon({
  className = "text-[#999999]",
  width = 18,
  height = 18,
}) {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3 7H15" stroke="currentColor" stroke-linecap="round" strokeLinejoin="round" />
      <path
        d="M15 6.99961V5.39961C15 5.18744 14.9157 4.98395 14.7657 4.83392C14.6157 4.68389 14.4122 4.59961 14.2 4.59961H3.8C3.58783 4.59961 3.38434 4.68389 3.23431 4.83392C3.08429 4.98395 3 5.18744 3 5.39961V14.1996C3 14.4118 3.08429 14.6153 3.23431 14.7653C3.38434 14.9153 3.58783 14.9996 3.8 14.9996H7.8"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path d="M6.2002 3V4.6" stroke="currentColor" stroke-linecap="round" strokeLinejoin="round" />
      <path
        d="M11.7998 3V4.6"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.8001 14.9996C13.5674 14.9996 15.0001 13.5669 15.0001 11.7996C15.0001 10.0323 13.5674 8.59961 11.8001 8.59961C10.0328 8.59961 8.6001 10.0323 8.6001 11.7996C8.6001 13.5669 10.0328 14.9996 11.8001 14.9996Z"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.7998 10.1992V11.7992H13.3998"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
