/**
 * @flow
 * @relayHash 3aaf97ed1608811dffb9e064a3d627d3
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getEnrollmentQueryVariables = {|
  enrollmentId: string
|};
export type getEnrollmentQueryResponse = {|
  +enrollment: {|
    +id: string,
    +email: string,
    +referralLink: ?string,
  |}
|};
export type getEnrollmentQuery = {|
  variables: getEnrollmentQueryVariables,
  response: getEnrollmentQueryResponse,
|};
*/


/*
query getEnrollmentQuery(
  $enrollmentId: ID!
) {
  enrollment(enrollmentId: $enrollmentId) {
    id
    email
    referralLink
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "enrollmentId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "enrollment",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "enrollmentId",
        "variableName": "enrollmentId"
      }
    ],
    "concreteType": "HyreferralEnrollment",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "email",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "referralLink",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getEnrollmentQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "getEnrollmentQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "getEnrollmentQuery",
    "id": null,
    "text": "query getEnrollmentQuery(\n  $enrollmentId: ID!\n) {\n  enrollment(enrollmentId: $enrollmentId) {\n    id\n    email\n    referralLink\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '00f5e9bef9f7bbc79a3425dd7cc0632e';
module.exports = node;
