import graphql from "babel-plugin-relay/macro";

export default graphql`
  query getEditorQuery($id: ID!, $appName: String) {
    hyblastEditor(id: $id, appName: $appName) {
      templateId
      propertyId
      id
      name
      preheader
      gptHistoryId
      subject
      mms
      mmsBody
      mmsStatus
      mmsMediaUrl
      mmsStatus
      approvalStatus
      editorStatus
      status
      sentGroup
      sentTime
      abortedTime
      scheduledTime
      lastTestEmails
      lastTestPhones
      approvalFeedbacks {
        id
        userEmail
        message
        createdAt
      }
      fields {
        editable
        dimension
        display
        label
        field
        value
        type
        id
      }
      document {
        createdAt
        dimension
        fileSize
        fileType
        fileUrl
        id
        name
        thumbnailUrl
      }
      substitutedMacrosHtml
      senderEmail
      senderEmailVerified
    }
  }
`;
