export const CalendarIcon = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.7572 20.5547H12.8381V21.5143H14.7572V20.5547Z"
      stroke="#333333"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M20.5144 20.5547H18.5953V21.5143H20.5144V20.5547Z"
      stroke="#333333"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M14.7572 25.3522H12.8381V26.3118H14.7572V25.3522Z"
      stroke="#333333"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M20.5144 25.3522H18.5953V26.3118H20.5144V25.3522Z"
      stroke="#333333"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M26.2713 20.5547H24.3523V21.5143H26.2713V20.5547Z"
      stroke="#333333"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path d="M14.7572 20.5547H12.8381V21.5143H14.7572V20.5547Z" fill="#333333" />
    <path d="M20.5144 20.5547H18.5953V21.5143H20.5144V20.5547Z" fill="#333333" />
    <path d="M14.7572 25.3522H12.8381V26.3118H14.7572V25.3522Z" fill="#333333" />
    <path d="M20.5144 25.3522H18.5953V26.3118H20.5144V25.3522Z" fill="#333333" />
    <path d="M26.2713 20.5547H24.3523V21.5143H26.2713V20.5547Z" fill="#333333" />
    <path
      d="M28.1903 11.9191H10.919C9.85918 11.9191 9 12.7782 9 13.8381V28.2309C9 29.2907 9.85918 30.1499 10.919 30.1499H28.1903C29.2502 30.1499 30.1094 29.2907 30.1094 28.2309V13.8381C30.1094 12.7782 29.2502 11.9191 28.1903 11.9191Z"
      stroke="#333333"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path d="M13.7975 10V12.8786" stroke="#333333" strokeMiterlimit="10" strokeLinecap="square" />
    <path d="M25.3116 10V12.8786" stroke="#333333" strokeMiterlimit="10" strokeLinecap="square" />
    <path d="M9 16.7166H30.1094" stroke="#333333" strokeMiterlimit="10" strokeLinecap="square" />
  </svg>
);

export const ReservationIcon = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.0411 29.25H11V10H26.75V19.625"
      stroke="#333333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M14.5411 15.25H23.25" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14.5411 19.625H23.25" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14.5411 24H18.0411" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M28.5411 24.875V29.25H21.5411V24.875L25.0411 22.25L28.5411 24.875Z"
      stroke="#333333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M25.0411 27.5V29.25" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
export const TourScheduleIcon = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_6889_96295)">
      <path
        d="M25.1094 27.0582L23.57 26.6207C23.5119 26.6041 23.4599 26.5711 23.4202 26.5256C23.3805 26.4802 23.3548 26.4242 23.3462 26.3644L23.2169 25.4538C23.5435 25.3045 23.8203 25.0646 24.0146 24.7626C24.2088 24.4606 24.3122 24.1091 24.3125 23.75V22.8913C24.3201 22.3941 24.1352 21.9131 23.7965 21.549C23.4578 21.185 22.9915 20.9658 22.495 20.9375C22.244 20.9298 21.994 20.9726 21.7598 21.0634C21.5256 21.1542 21.3121 21.2911 21.1318 21.466C20.9516 21.6408 20.8083 21.8501 20.7105 22.0815C20.6127 22.3128 20.5624 22.5614 20.5625 22.8125V23.75C20.5628 24.1091 20.6662 24.4606 20.8604 24.7626C21.0547 25.0646 21.3315 25.3045 21.6581 25.4538L21.5288 26.3619C21.5202 26.4217 21.4945 26.4777 21.4548 26.5231C21.4151 26.5686 21.3631 26.6016 21.305 26.6182L19.7656 27.0557C19.6351 27.093 19.5203 27.1718 19.4386 27.2801C19.3568 27.3885 19.3126 27.5205 19.3125 27.6563V29.0625H25.5625V27.6588C25.5624 27.523 25.5182 27.391 25.4364 27.2826C25.3547 27.1743 25.2399 27.0955 25.1094 27.0582V27.0582Z"
        stroke="#333333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.8125 29.0625H28.6875V27.0507C28.6875 26.9113 28.6409 26.776 28.5552 26.6662C28.4695 26.5563 28.3495 26.4783 28.2144 26.4444L26.3913 25.9888C26.3309 25.9737 26.2764 25.941 26.2348 25.8948C26.1931 25.8486 26.1662 25.791 26.1575 25.7294L26.0294 24.8288C26.356 24.6795 26.6328 24.4396 26.8271 24.1376C27.0213 23.8356 27.1247 23.4841 27.125 23.125V22.2663C27.1326 21.7691 26.9477 21.2882 26.609 20.9241C26.2703 20.56 25.804 20.3409 25.3075 20.3125C24.959 20.3018 24.6145 20.3883 24.3125 20.5625"
        stroke="#333333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <path d="M28.375 16.125H10" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M17.875 27.5H11.3125C10.9644 27.5 10.6306 27.3617 10.3844 27.1156C10.1383 26.8694 10 26.5356 10 26.1875V13.0625C10 12.7144 10.1383 12.3806 10.3844 12.1344C10.6306 11.8883 10.9644 11.75 11.3125 11.75H27.0625C27.4106 11.75 27.7444 11.8883 27.9906 12.1344C28.2367 12.3806 28.375 12.7144 28.375 13.0625V20.0677"
      stroke="#333333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M14.375 10V13.5" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M24 10V13.5" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
    <defs>
      <clipPath id="clip0_6889_96295">
        <rect width="10" height="10" fill="white" transform="translate(19 20)" />
      </clipPath>
    </defs>
  </svg>
);
export const AmenitiesIcon = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.625 20.625V29.375H19.1875"
      stroke="#333333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.3775 17.3429L19.625 11L10 18.875"
      stroke="#333333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.7969 27.8707L25.2575 27.4332C25.1994 27.4166 25.1474 27.3836 25.1077 27.3381C25.068 27.2927 25.0423 27.2367 25.0337 27.1769L24.9044 26.2663C25.231 26.117 25.5078 25.8771 25.7021 25.5751C25.8963 25.2731 25.9997 24.9216 26 24.5625V23.7038C26.0076 23.2066 25.8227 22.7256 25.484 22.3615C25.1453 21.9975 24.679 21.7783 24.1825 21.75C23.9315 21.7423 23.6815 21.7851 23.4473 21.8759C23.2131 21.9667 22.9996 22.1036 22.8193 22.2785C22.6391 22.4533 22.4958 22.6626 22.398 22.894C22.3002 23.1253 22.2499 23.3739 22.25 23.625V24.5625C22.2503 24.9216 22.3537 25.2731 22.5479 25.5751C22.7422 25.8771 23.019 26.117 23.3456 26.2663L23.2163 27.1744C23.2077 27.2342 23.182 27.2902 23.1423 27.3356C23.1026 27.3811 23.0506 27.4141 22.9925 27.4307L21.4531 27.8682C21.3226 27.9055 21.2078 27.9843 21.1261 28.0926C21.0443 28.201 21.0001 28.333 21 28.4688V29.875H27.25V28.4713C27.2499 28.3355 27.2057 28.2035 27.1239 28.0951C27.0422 27.9868 26.9274 27.908 26.7969 27.8707V27.8707Z"
      stroke="#333333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.5 29.875H30.375V27.8632C30.375 27.7238 30.3284 27.5885 30.2427 27.4787C30.157 27.3688 30.037 27.2908 29.9019 27.2569L28.0788 26.8013C28.0184 26.7862 27.9639 26.7535 27.9223 26.7073C27.8806 26.6611 27.8537 26.6035 27.845 26.5419L27.7169 25.6413C28.0435 25.492 28.3203 25.2521 28.5146 24.9501C28.7088 24.6481 28.8122 24.2966 28.8125 23.9375V23.0788C28.8201 22.5816 28.6352 22.1007 28.2965 21.7366C27.9578 21.3725 27.4915 21.1534 26.995 21.125C26.6465 21.1143 26.302 21.2008 26 21.375"
      stroke="#333333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const TourSiteIcon = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18 30C18 30 10 21.938 10 17C10 11.938 14.134 9 18 9C21.866 9 26 11.938 26 17"
      stroke="#333333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <g clipPath="url(#clip0_6889_96344)">
      <path
        d="M25.5 26.0833C26.6506 26.0833 27.5833 25.1506 27.5833 24C27.5833 22.8494 26.6506 21.9166 25.5 21.9166C24.3494 21.9166 23.4166 22.8494 23.4166 24C23.4166 25.1506 24.3494 26.0833 25.5 26.0833Z"
        stroke="#333333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.5 27.75C22.9683 27.75 21.75 29.3292 21.75 30.25V31.0833H29.25V30.25C29.25 29.3333 28.0317 27.75 25.5 27.75Z"
        stroke="#333333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <path
      d="M18 20C19.6569 20 21 18.6569 21 17C21 15.3431 19.6569 14 18 14C16.3431 14 15 15.3431 15 17C15 18.6569 16.3431 20 18 20Z"
      stroke="#333333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <clipPath id="clip0_6889_96344">
        <rect width="10" height="10" fill="white" transform="translate(20.5 21.5)" />
      </clipPath>
    </defs>
  </svg>
);
export const IntegrationIcon = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.3275 13.5C21.294 13.5 22.0775 12.7165 22.0775 11.75C22.0775 10.7835 21.294 10 20.3275 10C19.361 10 18.5775 10.7835 18.5775 11.75C18.5775 12.7165 19.361 13.5 20.3275 13.5Z"
      stroke="#333333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.75 26.625C13.7165 26.625 14.5 25.8415 14.5 24.875C14.5 23.9085 13.7165 23.125 12.75 23.125C11.7835 23.125 11 23.9085 11 24.875C11 25.8415 11.7835 26.625 12.75 26.625Z"
      stroke="#333333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.905 26.625C28.8715 26.625 29.655 25.8415 29.655 24.875C29.655 23.9085 28.8715 23.125 27.905 23.125C26.9385 23.125 26.155 23.9085 26.155 24.875C26.155 25.8415 26.9385 26.625 27.905 26.625Z"
      stroke="#333333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.3217 13.3302C13.7688 14.4092 12.6088 15.9637 12.0165 17.7594C11.4241 19.5552 11.4316 21.4948 12.0378 23.286"
      stroke="#333333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.8812 26.2837C25.6319 27.7078 23.9524 28.6858 22.0973 29.0695C20.2422 29.4532 18.3125 29.2216 16.6008 28.4099"
      stroke="#333333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.0452 19.7607C28.8849 17.8749 28.1175 16.0918 26.8581 14.6791C25.5987 13.2663 23.9152 12.3 22.0601 11.925"
      stroke="#333333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const ReportsIcon = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.8435 30.885V15.9891"
      stroke="#333333"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M20.5728 30.885V21.7184"
      stroke="#333333"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M26.3019 30.885V15.9891"
      stroke="#333333"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M32.031 30.885V21.7184"
      stroke="#333333"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path d="M9.11438 30.885V10.26" stroke="#333333" strokeMiterlimit="10" strokeLinecap="square" />
  </svg>
);
