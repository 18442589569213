import React, { useState, useEffect } from "react";
import withStyles from "@mui/styles/withStyles";
import Selector from "components/selector";
import styles from "./styles";
import ToggleButton from "components/ToggleButton";
import { NewTooltip } from "storybookComponents";
import { CopiedIcon, FavoriteIcon, LinkIcon } from "icons";
import { useDispatch, useSelector } from "react-redux";
import {
  getIsShowFavorite,
  getDashboardType,
  getStates4Fav,
  selectEventReports as getEventSavedReports,
  setEventReports as setEventSavedReports,
  setStates4Fav,
  getSuborgDetails,
} from "redux/slices";
import {
  getIsShowHeaderCopyLink,
  getIsShowHeaderFavorite,
} from "redux/slices/showHeaderIconsSlice";
import SaveReportPopup from "storybookComponents/Appbar/SaveReportPopup";
import DeleteReportPopup from "storybookComponents/Appbar/DeleteReportPopup";
import { getErrorMessage, checkUncatchableError, environment } from "environments";
import PropTypes from "prop-types";
import moment from "moment";
import clsx from "clsx";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Paper,
  MenuList,
  Grow,
  Divider,
  Popper,
  MenuItem,
  Button,
} from "@mui/material";
// import ClickAwayListener from "@mui/base/ClickAwayListener";
import makeStyles from "@mui/styles/makeStyles";
import { deepEqual } from "common";
import RenameJourneyNameComponent from "content/JourneyFreehand/components/RenameJourneyName";
import {
  selectReports as getDripsSavedReports,
  setReports as setDripsSavedReports,
} from "redux/slices/drip-slice";
import {
  getSavedReports as getBlastSavedReports,
  setSavedReports as setBlastSavedReports,
} from "redux/slices/blast-slice";
import {
  selectMyAnalyticsReports as getRAFSavedReports,
  setMyAnalyticsReports as setRAFSavedReports,
} from "redux/slices/raf-slice";
import { saveReport, getSavedReports, deleteReport } from "relay";
import AnimatedLoader from "storybookComponents/Loader/AnimatedLoader";
import { ShareIcon2 } from "components/Icons";

const Header = ({
  classes,
  index,
  setSubMenu,
  reportOptions,
  setSms,
  sms,
  toggleReports,
  children,
  showNotification,
  orgLevel,
}) => {
  const dispatch = useDispatch();
  const subOrgDetails = useSelector(getSuborgDetails);
  const isShowHeaderCopyLink = useSelector(getIsShowHeaderCopyLink);
  const isShowHeaderFavorite = useSelector(getIsShowHeaderFavorite);
  const dripsFavorites = useSelector(getDripsSavedReports);
  const blastFavorites = useSelector(getBlastSavedReports);
  const eventFavorites = useSelector(getEventSavedReports);
  const rafFavorites = useSelector(getRAFSavedReports);
  const dashboardType = useSelector(getDashboardType);
  const data4Fav = useSelector(getStates4Fav);
  const [isFavorited, setIsFavorited] = useState(false);
  const [openPreFavPopup, setOpenPreFavPopup] = useState(false);
  const [openPreRemovePopup, setOpenPreRemovePopup] = useState(false);
  const [iconStroke, setIconStroke] = React.useState("#333");
  const [favoritedReportName, setFavoritedReportName] = useState("");
  const [copyText, setCopyText] = React.useState("Copy URL");

  const handleChange = (v) => setSubMenu(reportOptions.indexOf(v));
  const handleChangeToggle = () => {
    setSms(!sms);
  };

  const getTooltipTxt = () => {
    return !isFavorited
      ? "This would save the report with filters in My Favorites"
      : "This would remove the report with filters from My Favorites";
  };

  const offPopup = () => {
    setOpenPreFavPopup(false);
    setOpenPreRemovePopup(false);
  };

  const toggleFavorite = () => {
    if (isFavorited) {
      setOpenPreRemovePopup(true);
      return;
    }
    setOpenPreFavPopup(true);
  };

  const copyUrlText = () => {
    const currentURL = window.location.href;

    navigator.clipboard
      .writeText(currentURL)
      .then(() => {
        setCopyText("URL Copied");

        setTimeout(() => {
          setCopyText("Copy URL");
        }, 1500);
      })
      .catch((err) => {
        console.error("Unable to copy URL to clipboard", err);
      });
  };

  const handleFavorite = async (value) => {
    offPopup();
    dispatch(setStates4Fav({ name: value }));
    try {
      const res = await saveReport(environment, {
        saveReport: { ...data4Fav, name: value },
      });
      if (!res.saveReport) {
        const uncachebleError = getErrorMessage(checkUncatchableError("saveReportMutation"));
        showNotification(uncachebleError || "Something went wrong.", "error");
      } else {
        showNotification(`Report “${value}”  has been added to My Favorites`, "success");
      }

      resetSavedReports();
    } catch (err) {
      if (process.env.NODE_ENV !== "production") {
        console.error("ReportsComponents/Header/index.jsx/handleFavorite(): debug:", { err });
      }
      const uncachebleError = getErrorMessage(checkUncatchableError("saveReportMutation"));
      showNotification(uncachebleError || "Something went wrong.", "error");
    }
  };

  const handleUnfavorite = async () => {
    offPopup();
    const savedItem = getFavorites().find((item) => item.name === favoritedReportName);

    try {
      const res = await deleteReport(environment, {
        id: savedItem.id,
      });
      if (!res.deleteSavedReport) {
        const uncachebleError = getErrorMessage(checkUncatchableError("deleteReportMutation"));
        showNotification(uncachebleError || "Something went wrong.", "error");
      } else {
        showNotification(`Report “${savedItem.name}”  has been removed from Favorites`, "success");
      }

      resetSavedReports();
    } catch (err) {
      if (process.env.NODE_ENV !== "production") {
        console.error("SorybookComponents/Appbar/index.jsx/handleUnfavorite(): debug:", { err });
      }
      const uncachebleError = getErrorMessage(checkUncatchableError("deleteReportMutation"));
      showNotification(uncachebleError || "Something went wrong.", "error");
    }
  };

  const resetSavedReports = async () => {
    const res = await getSavedReports(
      orgLevel ? subOrgDetails.org.id : subOrgDetails.id,
      dashboardType
    );

    if (res.savedReports) {
      if (dashboardType === "hydrip_system_dashboard") {
        dispatch(
          setDripsSavedReports(
            orgLevel
              ? res.savedReports
                  .slice()
                  .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
              : res.savedReports
                  .filter((item) => item.analyzeObject !== "By Property")
                  .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          )
        );
        return;
      }
      if (dashboardType === "hyalert_system_dashboard") {
        dispatch(
          setDripsSavedReports(
            orgLevel
              ? res.savedReports
                  .slice()
                  .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
              : res.savedReports
                  .filter((item) => item.analyzeObject !== "By Property")
                  .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          )
        );
        return;
      }

      if (dashboardType === "hyblast_system_dashboard") {
        const filteredReports = res.savedReports
          .filter((ele) => ele.analyzeObject !== "Blast A/B")
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        dispatch(
          setBlastSavedReports(
            orgLevel
              ? filteredReports
              : filteredReports.filter(
                  (item) =>
                    item.analyzeObject !== "By Property" && item.analyzeObject !== "Property Audit"
                )
          )
        );
        return;
      }

      if (dashboardType === "hyevent_system_dashboard") {
        dispatch(
          setEventSavedReports(
            orgLevel
              ? res.savedReports
                  .slice()
                  .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
              : res.savedReports
                  .filter((item) => item.analyzeObject !== "By Property")
                  .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          )
        );
        return;
      }

      if (dashboardType === "hyraf_system_dashboard") {
        dispatch(
          setRAFSavedReports(
            orgLevel
              ? res.savedReports
                  .slice()
                  .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
              : res.savedReports
                  .filter((item) => item.analyzeObject !== "By Property")
                  .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          )
        );
        return;
      }
    }
  };

  const getFavorites = () => {
    if (
      dashboardType === "hydrip_system_dashboard" ||
      dashboardType === "hyalert_system_dashboard"
    ) {
      return dripsFavorites;
    }

    if (dashboardType === "hyblast_system_dashboard") {
      return blastFavorites;
    }

    if (dashboardType === "hyevent_system_dashboard") {
      return eventFavorites;
    }

    if (dashboardType === "hyraf_system_dashboard") {
      return rafFavorites;
    }

    return [];
  };

  const compareParams = (item) => {
    if (!item.params || !data4Fav.params) return false;
    let savedParams = JSON.parse(item.params);
    let currentParams = JSON.parse(data4Fav.params);

    if (savedParams.bot_names && currentParams.bot_names) {
      delete savedParams.bot_names;
      delete currentParams.bot_names;
    }
    if (savedParams.total_bots && currentParams.total_bots) {
      delete savedParams.total_bots;
      delete currentParams.total_bots;
    }
    if (savedParams.campaign_names && currentParams.campaign_names) {
      delete savedParams.campaign_names;
      delete currentParams.campaign_names;
    }
    if (savedParams.total_campaigns && currentParams.total_campaigns) {
      delete savedParams.total_campaigns;
      delete currentParams.total_campaigns;
    }
    if (savedParams.angel_names && currentParams.angel_names) {
      delete savedParams.angel_names;
      delete currentParams.angel_names;
    }
    if (savedParams.total_angels && currentParams.total_angels) {
      delete savedParams.total_angels;
      delete currentParams.total_angels;
    }
    // Match some props format type before comparing
    savedParams = {
      ...savedParams,
      report_type: savedParams.report_type.toLowerCase(),
      end_date: moment(savedParams.end_date).format("YYYY-MM-DD"),
      start_date: moment(savedParams.start_date).format("YYYY-MM-DD"),
    };
    currentParams = {
      ...currentParams,
      report_type: currentParams.report_type.toLowerCase(),
      end_date: moment(currentParams.end_date).format("YYYY-MM-DD"),
      start_date: moment(currentParams.start_date).format("YYYY-MM-DD"),
    };

    // remove unnecessary keys for the comparison
    delete savedParams.range_label;
    delete savedParams.storeParams;

    if (!orgLevel) {
      delete savedParams.property_ids;
      delete currentParams.property_ids;
    }

    return deepEqual(savedParams, currentParams) && item.analyzeObject === data4Fav.tabName;
  };

  useEffect(() => {
    if (getFavorites().length > 0) {
      const savedItem = getFavorites().filter(compareParams);

      setIsFavorited(savedItem.length > 0);
      if (savedItem.length > 0) {
        setFavoritedReportName(savedItem[0].name);
      }
    }
    return () => {
      offPopup();
      setIsFavorited(false);
      setFavoritedReportName("");
    };
  }, [dripsFavorites, blastFavorites, eventFavorites, rafFavorites, data4Fav]);

  return (
    <div className={classes.headerRoot}>
      <Selector
        options={reportOptions}
        value={reportOptions[index]}
        onChange={handleChange}
        className={{
          select_root: classes.select_root,
          select_menu: classes.select_menu,
        }}
      />
      <div className={classes.headerRightSection}>
        {false && (
          <ToggleButton
            handleChangeToggle={handleChangeToggle}
            isSMSActive={sms === true}
            toggleText={["Email", "SMS"]}
          />
        )}
        {isShowHeaderCopyLink && (
          <NewTooltip position="bottom" text={copyText} textLimit={400}>
            <div
              onMouseEnter={() => setIconStroke("#26BBED")}
              onMouseLeave={() => setIconStroke("#333")}
            >
              <IconButton onClick={copyUrlText} size="medium">
                {copyText === "Copy URL" ? (
                  <LinkIcon stroke={iconStroke} fill="none" />
                ) : (
                  <CopiedIcon width="28px" height="28px" />
                )}
              </IconButton>
            </div>
          </NewTooltip>
        )}
        {isShowHeaderFavorite && (
          <NewTooltip position="bottom" text={getTooltipTxt()} textLimit={400}>
            <div>
              <IconButton onClick={toggleFavorite} size="medium">
                {!isFavorited ? (
                  <FavoriteIcon />
                ) : (
                  <FavoriteIcon className={classes.unfavoriteIcon} />
                )}
              </IconButton>
            </div>
          </NewTooltip>
        )}
      </div>
      <SaveReportPopup
        open={openPreFavPopup}
        onConfirm={handleFavorite}
        onClose={offPopup}
        favorites={getFavorites()}
        title={"My Favorites"}
      />
      <DeleteReportPopup
        header="Favorites"
        open={openPreRemovePopup}
        onRemove={handleUnfavorite}
        onClose={offPopup}
        name={favoritedReportName}
        classes={classes}
      />
    </div>
  );
};

export default withStyles(styles)(Header);
