import React from "react";
import { SvgIcon } from "@mui/material";

export default function FormIcon(props) {
  return (
    <svg
      className={props.className}
      width={props.width}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" fill="white" />
      <path
        d="M16.6362 6.54541H7.63623V17.5454H16.6362V6.54541Z"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.63672 9.54541H14.6367"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.63672 12.0453H14.6367"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.63672 14.5455H11.6367"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="currentColor" />
    </svg>
  );
}
