/**
 * @flow
 * @relayHash fe5ea50020d54384821a1ca377898e81
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getaccountsQueryVariables = {|
  orgId: string,
  search?: ?string,
  readOnly?: ?boolean,
|};
export type getaccountsQueryResponse = {|
  +accounts: {|
    +pageInfo: {|
      +endCursor: ?string,
      +hasNextPage: boolean,
    |},
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +name: string,
        +email: string,
        +role: {|
          +name: string
        |},
        +authorizedRegions: $ReadOnlyArray<{|
          +name: string
        |}>,
        +authorizedSuborgs: $ReadOnlyArray<{|
          +name: string
        |}>,
      |}
    |}>,
  |}
|};
export type getaccountsQuery = {|
  variables: getaccountsQueryVariables,
  response: getaccountsQueryResponse,
|};
*/


/*
query getaccountsQuery(
  $orgId: ID!
  $search: String
  $readOnly: Boolean
) {
  accounts(orgId: $orgId, search: $search, readOnly: $readOnly) {
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      node {
        name
        email
        role {
          name
          id
        }
        authorizedRegions {
          name
          id
        }
        authorizedSuborgs {
          name
          id
        }
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "orgId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "search",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "readOnly",
    "type": "Boolean",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "orgId",
    "variableName": "orgId"
  },
  {
    "kind": "Variable",
    "name": "readOnly",
    "variableName": "readOnly"
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  }
],
v2 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "pageInfo",
  "storageKey": null,
  "args": null,
  "concreteType": "PageInfo",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "endCursor",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "hasNextPage",
      "args": null,
      "storageKey": null
    }
  ]
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "email",
  "args": null,
  "storageKey": null
},
v5 = [
  (v3/*: any*/)
],
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v7 = [
  (v3/*: any*/),
  (v6/*: any*/)
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getaccountsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "accounts",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "AccountConnection",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "AccountEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Account",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "role",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Role",
                    "plural": false,
                    "selections": (v5/*: any*/)
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "authorizedRegions",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Region",
                    "plural": true,
                    "selections": (v5/*: any*/)
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "authorizedSuborgs",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Suborg",
                    "plural": true,
                    "selections": (v5/*: any*/)
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "getaccountsQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "accounts",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "AccountConnection",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "AccountEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Account",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "role",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Role",
                    "plural": false,
                    "selections": (v7/*: any*/)
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "authorizedRegions",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Region",
                    "plural": true,
                    "selections": (v7/*: any*/)
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "authorizedSuborgs",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Suborg",
                    "plural": true,
                    "selections": (v7/*: any*/)
                  },
                  (v6/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "getaccountsQuery",
    "id": null,
    "text": "query getaccountsQuery(\n  $orgId: ID!\n  $search: String\n  $readOnly: Boolean\n) {\n  accounts(orgId: $orgId, search: $search, readOnly: $readOnly) {\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n    edges {\n      node {\n        name\n        email\n        role {\n          name\n          id\n        }\n        authorizedRegions {\n          name\n          id\n        }\n        authorizedSuborgs {\n          name\n          id\n        }\n        id\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2737db3ae8ed63ca2f5d70b212e828a1';
module.exports = node;
