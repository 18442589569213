/**
 * @flow
 * @relayHash c061aa1023b89cf7bf167b42a06a7cac
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getSelectedSuborgAlertsQueryVariables = {|
  orgId: string,
  propertyIds?: ?$ReadOnlyArray<string>,
|};
export type getSelectedSuborgAlertsQueryResponse = {|
  +authorizedSuborgs: $ReadOnlyArray<{|
    +id: string,
    +name: string,
    +alerts: $ReadOnlyArray<{|
      +id: string,
      +name: ?string,
      +active: ?boolean,
      +createdAt: ?any,
      +lastActivatedAt: ?any,
      +lastDeactivatedAt: ?any,
      +thenEmails: ?$ReadOnlyArray<{|
        +id: string,
        +name: string,
        +active: boolean,
        +createdAt: any,
        +triggerDays: number,
        +originalThumbnailUrl: ?string,
        +whenGroupName: ?string,
        +tourType: ?string,
      |}>,
    |}>,
  |}>
|};
export type getSelectedSuborgAlertsQuery = {|
  variables: getSelectedSuborgAlertsQueryVariables,
  response: getSelectedSuborgAlertsQueryResponse,
|};
*/


/*
query getSelectedSuborgAlertsQuery(
  $orgId: ID!
  $propertyIds: [String!]
) {
  authorizedSuborgs(orgId: $orgId, propertyIds: $propertyIds) {
    id
    name
    alerts {
      id
      name
      active
      createdAt
      lastActivatedAt
      lastDeactivatedAt
      thenEmails {
        id
        name
        active
        createdAt
        triggerDays
        originalThumbnailUrl
        whenGroupName
        tourType
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "orgId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "propertyIds",
    "type": "[String!]",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "active",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "createdAt",
  "args": null,
  "storageKey": null
},
v5 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "authorizedSuborgs",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "orgId",
        "variableName": "orgId"
      },
      {
        "kind": "Variable",
        "name": "propertyIds",
        "variableName": "propertyIds"
      }
    ],
    "concreteType": "Suborg",
    "plural": true,
    "selections": [
      (v1/*: any*/),
      (v2/*: any*/),
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "alerts",
        "storageKey": null,
        "args": null,
        "concreteType": "Angel",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "lastActivatedAt",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "lastDeactivatedAt",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "thenEmails",
            "storageKey": null,
            "args": null,
            "concreteType": "ThenEmail",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "triggerDays",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "originalThumbnailUrl",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "whenGroupName",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "tourType",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getSelectedSuborgAlertsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v5/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "getSelectedSuborgAlertsQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v5/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "getSelectedSuborgAlertsQuery",
    "id": null,
    "text": "query getSelectedSuborgAlertsQuery(\n  $orgId: ID!\n  $propertyIds: [String!]\n) {\n  authorizedSuborgs(orgId: $orgId, propertyIds: $propertyIds) {\n    id\n    name\n    alerts {\n      id\n      name\n      active\n      createdAt\n      lastActivatedAt\n      lastDeactivatedAt\n      thenEmails {\n        id\n        name\n        active\n        createdAt\n        triggerDays\n        originalThumbnailUrl\n        whenGroupName\n        tourType\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '85531d405c7012f850eaa525cd286040';
module.exports = node;
