/**
 * @flow
 * @relayHash 31e9dc76e3d91d55699aaa4017a36afe
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getCampaignQueryVariables = {|
  id: string
|};
export type getCampaignQueryResponse = {|
  +hyreferralCampaign: {|
    +id: string,
    +enrollUrl: ?string,
    +propertyId: string,
    +title: string,
    +reward: ?number,
    +emailSubject: ?string,
    +emailTitle: ?string,
    +disclaimer: ?string,
    +residentThankYouEmailDisclaimer: ?string,
    +landingPageEmailDisclaimer: ?string,
    +residentInviteEmailDisclaimer: ?string,
    +emailBody: ?string,
    +emailSms: ?string,
    +ctaDesign: ?string,
    +ctaText: ?string,
    +bannerText: ?string,
    +pitchMessage: ?string,
    +residentEnrollHeader: ?string,
    +residentEnrollFooter: ?string,
    +emailEnabled: ?boolean,
    +smsEnabled: ?boolean,
    +facebookEnabled: ?boolean,
    +twitterEnabled: ?boolean,
    +residentInviteEmailSubject: ?string,
    +residentInviteEmailBody: ?string,
    +residentInviteSms: ?string,
    +residentInviteSocialContent: ?string,
    +residentDiscountEmailSubject: ?string,
    +residentDiscountEmailBody: ?string,
    +residentDiscountEmailSms: ?string,
    +tourCtaText: ?string,
    +tourCtaUrl: ?string,
    +contactCtaText: ?string,
    +contactCtaUrl: ?string,
    +active: boolean,
    +inviteScheduleAt: ?string,
    +image: ?{|
      +thumbnailUrl: ?string
    |},
    +residentInviteImage: ?{|
      +thumbnailUrl: ?string
    |},
    +residentDiscountImage: ?{|
      +thumbnailUrl: ?string
    |},
    +shortEnrollUrl: ?string,
    +abortResidentInviteCampaignId: ?string,
    +shortTourCtaUrl: ?string,
    +propertyName: ?string,
    +rewardEmailHeader: ?string,
    +friendInviteEmailHeader: ?string,
    +friendInviteMenuHeader: ?string,
    +friendInviteMenuBody: ?string,
    +residentBannerTitle: ?string,
    +popupMessage: ?string,
    +popupTitle: ?string,
    +residentInviteCampaignTested: ?boolean,
    +residentThankYouEmailSubject: ?string,
    +residentThankYouEmailTitle: ?string,
    +residentThankYouEmailBody: ?string,
    +residentThankYouImage: ?{|
      +thumbnailUrl: ?string
    |},
    +facebookShareUrl: ?string,
    +landingPageContent: ?string,
    +landingPageTitle: ?string,
    +landingPageImage: ?{|
      +thumbnailUrl: ?string
    |},
    +emailValidationStatus: ?boolean,
    +inviteAlreadySent: ?boolean,
    +aborted: ?boolean,
    +isQuarterly: ?boolean,
    +quarterlyEmailDay: ?string,
    +quarterlyEmailTime: ?string,
    +inviteScheduleHistory: ?string,
    +contactUsFormHtml: ?string,
  |}
|};
export type getCampaignQuery = {|
  variables: getCampaignQueryVariables,
  response: getCampaignQueryResponse,
|};
*/


/*
query getCampaignQuery(
  $id: ID!
) {
  hyreferralCampaign(id: $id) {
    id
    enrollUrl
    propertyId
    title
    reward
    emailSubject
    emailTitle
    disclaimer
    residentThankYouEmailDisclaimer
    landingPageEmailDisclaimer
    residentInviteEmailDisclaimer
    emailBody
    emailSms
    ctaDesign
    ctaText
    bannerText
    pitchMessage
    residentEnrollHeader
    residentEnrollFooter
    emailEnabled
    smsEnabled
    facebookEnabled
    twitterEnabled
    residentInviteEmailSubject
    residentInviteEmailBody
    residentInviteSms
    residentInviteSocialContent
    residentDiscountEmailSubject
    residentDiscountEmailBody
    residentDiscountEmailSms
    tourCtaText
    tourCtaUrl
    contactCtaText
    contactCtaUrl
    active
    inviteScheduleAt
    image {
      thumbnailUrl
      id
    }
    residentInviteImage {
      thumbnailUrl
      id
    }
    residentDiscountImage {
      thumbnailUrl
      id
    }
    shortEnrollUrl
    abortResidentInviteCampaignId
    shortTourCtaUrl
    propertyName
    rewardEmailHeader
    friendInviteEmailHeader
    friendInviteMenuHeader
    friendInviteMenuBody
    residentBannerTitle
    popupMessage
    popupTitle
    residentInviteCampaignTested
    residentThankYouEmailSubject
    residentThankYouEmailTitle
    residentThankYouEmailBody
    residentThankYouImage {
      thumbnailUrl
      id
    }
    facebookShareUrl
    landingPageContent
    landingPageTitle
    landingPageImage {
      thumbnailUrl
      id
    }
    emailValidationStatus
    inviteAlreadySent
    aborted
    isQuarterly
    quarterlyEmailDay
    quarterlyEmailTime
    inviteScheduleHistory
    contactUsFormHtml
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "enrollUrl",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "propertyId",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "reward",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "emailSubject",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "emailTitle",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "disclaimer",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "residentThankYouEmailDisclaimer",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "landingPageEmailDisclaimer",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "residentInviteEmailDisclaimer",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "emailBody",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "emailSms",
  "args": null,
  "storageKey": null
},
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ctaDesign",
  "args": null,
  "storageKey": null
},
v16 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ctaText",
  "args": null,
  "storageKey": null
},
v17 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "bannerText",
  "args": null,
  "storageKey": null
},
v18 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "pitchMessage",
  "args": null,
  "storageKey": null
},
v19 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "residentEnrollHeader",
  "args": null,
  "storageKey": null
},
v20 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "residentEnrollFooter",
  "args": null,
  "storageKey": null
},
v21 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "emailEnabled",
  "args": null,
  "storageKey": null
},
v22 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "smsEnabled",
  "args": null,
  "storageKey": null
},
v23 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "facebookEnabled",
  "args": null,
  "storageKey": null
},
v24 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "twitterEnabled",
  "args": null,
  "storageKey": null
},
v25 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "residentInviteEmailSubject",
  "args": null,
  "storageKey": null
},
v26 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "residentInviteEmailBody",
  "args": null,
  "storageKey": null
},
v27 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "residentInviteSms",
  "args": null,
  "storageKey": null
},
v28 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "residentInviteSocialContent",
  "args": null,
  "storageKey": null
},
v29 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "residentDiscountEmailSubject",
  "args": null,
  "storageKey": null
},
v30 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "residentDiscountEmailBody",
  "args": null,
  "storageKey": null
},
v31 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "residentDiscountEmailSms",
  "args": null,
  "storageKey": null
},
v32 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "tourCtaText",
  "args": null,
  "storageKey": null
},
v33 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "tourCtaUrl",
  "args": null,
  "storageKey": null
},
v34 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "contactCtaText",
  "args": null,
  "storageKey": null
},
v35 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "contactCtaUrl",
  "args": null,
  "storageKey": null
},
v36 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "active",
  "args": null,
  "storageKey": null
},
v37 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "inviteScheduleAt",
  "args": null,
  "storageKey": null
},
v38 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "thumbnailUrl",
  "args": null,
  "storageKey": null
},
v39 = [
  (v38/*: any*/)
],
v40 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "shortEnrollUrl",
  "args": null,
  "storageKey": null
},
v41 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "abortResidentInviteCampaignId",
  "args": null,
  "storageKey": null
},
v42 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "shortTourCtaUrl",
  "args": null,
  "storageKey": null
},
v43 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "propertyName",
  "args": null,
  "storageKey": null
},
v44 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rewardEmailHeader",
  "args": null,
  "storageKey": null
},
v45 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "friendInviteEmailHeader",
  "args": null,
  "storageKey": null
},
v46 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "friendInviteMenuHeader",
  "args": null,
  "storageKey": null
},
v47 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "friendInviteMenuBody",
  "args": null,
  "storageKey": null
},
v48 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "residentBannerTitle",
  "args": null,
  "storageKey": null
},
v49 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "popupMessage",
  "args": null,
  "storageKey": null
},
v50 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "popupTitle",
  "args": null,
  "storageKey": null
},
v51 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "residentInviteCampaignTested",
  "args": null,
  "storageKey": null
},
v52 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "residentThankYouEmailSubject",
  "args": null,
  "storageKey": null
},
v53 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "residentThankYouEmailTitle",
  "args": null,
  "storageKey": null
},
v54 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "residentThankYouEmailBody",
  "args": null,
  "storageKey": null
},
v55 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "facebookShareUrl",
  "args": null,
  "storageKey": null
},
v56 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "landingPageContent",
  "args": null,
  "storageKey": null
},
v57 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "landingPageTitle",
  "args": null,
  "storageKey": null
},
v58 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "emailValidationStatus",
  "args": null,
  "storageKey": null
},
v59 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "inviteAlreadySent",
  "args": null,
  "storageKey": null
},
v60 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "aborted",
  "args": null,
  "storageKey": null
},
v61 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isQuarterly",
  "args": null,
  "storageKey": null
},
v62 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "quarterlyEmailDay",
  "args": null,
  "storageKey": null
},
v63 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "quarterlyEmailTime",
  "args": null,
  "storageKey": null
},
v64 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "inviteScheduleHistory",
  "args": null,
  "storageKey": null
},
v65 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "contactUsFormHtml",
  "args": null,
  "storageKey": null
},
v66 = [
  (v38/*: any*/),
  (v2/*: any*/)
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getCampaignQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "hyreferralCampaign",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "HyreferralCampaign",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/),
          (v20/*: any*/),
          (v21/*: any*/),
          (v22/*: any*/),
          (v23/*: any*/),
          (v24/*: any*/),
          (v25/*: any*/),
          (v26/*: any*/),
          (v27/*: any*/),
          (v28/*: any*/),
          (v29/*: any*/),
          (v30/*: any*/),
          (v31/*: any*/),
          (v32/*: any*/),
          (v33/*: any*/),
          (v34/*: any*/),
          (v35/*: any*/),
          (v36/*: any*/),
          (v37/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "image",
            "storageKey": null,
            "args": null,
            "concreteType": "Asset",
            "plural": false,
            "selections": (v39/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "residentInviteImage",
            "storageKey": null,
            "args": null,
            "concreteType": "Asset",
            "plural": false,
            "selections": (v39/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "residentDiscountImage",
            "storageKey": null,
            "args": null,
            "concreteType": "Asset",
            "plural": false,
            "selections": (v39/*: any*/)
          },
          (v40/*: any*/),
          (v41/*: any*/),
          (v42/*: any*/),
          (v43/*: any*/),
          (v44/*: any*/),
          (v45/*: any*/),
          (v46/*: any*/),
          (v47/*: any*/),
          (v48/*: any*/),
          (v49/*: any*/),
          (v50/*: any*/),
          (v51/*: any*/),
          (v52/*: any*/),
          (v53/*: any*/),
          (v54/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "residentThankYouImage",
            "storageKey": null,
            "args": null,
            "concreteType": "Asset",
            "plural": false,
            "selections": (v39/*: any*/)
          },
          (v55/*: any*/),
          (v56/*: any*/),
          (v57/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "landingPageImage",
            "storageKey": null,
            "args": null,
            "concreteType": "Asset",
            "plural": false,
            "selections": (v39/*: any*/)
          },
          (v58/*: any*/),
          (v59/*: any*/),
          (v60/*: any*/),
          (v61/*: any*/),
          (v62/*: any*/),
          (v63/*: any*/),
          (v64/*: any*/),
          (v65/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "getCampaignQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "hyreferralCampaign",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "HyreferralCampaign",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/),
          (v20/*: any*/),
          (v21/*: any*/),
          (v22/*: any*/),
          (v23/*: any*/),
          (v24/*: any*/),
          (v25/*: any*/),
          (v26/*: any*/),
          (v27/*: any*/),
          (v28/*: any*/),
          (v29/*: any*/),
          (v30/*: any*/),
          (v31/*: any*/),
          (v32/*: any*/),
          (v33/*: any*/),
          (v34/*: any*/),
          (v35/*: any*/),
          (v36/*: any*/),
          (v37/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "image",
            "storageKey": null,
            "args": null,
            "concreteType": "Asset",
            "plural": false,
            "selections": (v66/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "residentInviteImage",
            "storageKey": null,
            "args": null,
            "concreteType": "Asset",
            "plural": false,
            "selections": (v66/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "residentDiscountImage",
            "storageKey": null,
            "args": null,
            "concreteType": "Asset",
            "plural": false,
            "selections": (v66/*: any*/)
          },
          (v40/*: any*/),
          (v41/*: any*/),
          (v42/*: any*/),
          (v43/*: any*/),
          (v44/*: any*/),
          (v45/*: any*/),
          (v46/*: any*/),
          (v47/*: any*/),
          (v48/*: any*/),
          (v49/*: any*/),
          (v50/*: any*/),
          (v51/*: any*/),
          (v52/*: any*/),
          (v53/*: any*/),
          (v54/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "residentThankYouImage",
            "storageKey": null,
            "args": null,
            "concreteType": "Asset",
            "plural": false,
            "selections": (v66/*: any*/)
          },
          (v55/*: any*/),
          (v56/*: any*/),
          (v57/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "landingPageImage",
            "storageKey": null,
            "args": null,
            "concreteType": "Asset",
            "plural": false,
            "selections": (v66/*: any*/)
          },
          (v58/*: any*/),
          (v59/*: any*/),
          (v60/*: any*/),
          (v61/*: any*/),
          (v62/*: any*/),
          (v63/*: any*/),
          (v64/*: any*/),
          (v65/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "getCampaignQuery",
    "id": null,
    "text": "query getCampaignQuery(\n  $id: ID!\n) {\n  hyreferralCampaign(id: $id) {\n    id\n    enrollUrl\n    propertyId\n    title\n    reward\n    emailSubject\n    emailTitle\n    disclaimer\n    residentThankYouEmailDisclaimer\n    landingPageEmailDisclaimer\n    residentInviteEmailDisclaimer\n    emailBody\n    emailSms\n    ctaDesign\n    ctaText\n    bannerText\n    pitchMessage\n    residentEnrollHeader\n    residentEnrollFooter\n    emailEnabled\n    smsEnabled\n    facebookEnabled\n    twitterEnabled\n    residentInviteEmailSubject\n    residentInviteEmailBody\n    residentInviteSms\n    residentInviteSocialContent\n    residentDiscountEmailSubject\n    residentDiscountEmailBody\n    residentDiscountEmailSms\n    tourCtaText\n    tourCtaUrl\n    contactCtaText\n    contactCtaUrl\n    active\n    inviteScheduleAt\n    image {\n      thumbnailUrl\n      id\n    }\n    residentInviteImage {\n      thumbnailUrl\n      id\n    }\n    residentDiscountImage {\n      thumbnailUrl\n      id\n    }\n    shortEnrollUrl\n    abortResidentInviteCampaignId\n    shortTourCtaUrl\n    propertyName\n    rewardEmailHeader\n    friendInviteEmailHeader\n    friendInviteMenuHeader\n    friendInviteMenuBody\n    residentBannerTitle\n    popupMessage\n    popupTitle\n    residentInviteCampaignTested\n    residentThankYouEmailSubject\n    residentThankYouEmailTitle\n    residentThankYouEmailBody\n    residentThankYouImage {\n      thumbnailUrl\n      id\n    }\n    facebookShareUrl\n    landingPageContent\n    landingPageTitle\n    landingPageImage {\n      thumbnailUrl\n      id\n    }\n    emailValidationStatus\n    inviteAlreadySent\n    aborted\n    isQuarterly\n    quarterlyEmailDay\n    quarterlyEmailTime\n    inviteScheduleHistory\n    contactUsFormHtml\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd7bc694477a47669e959be1841fa3343';
module.exports = node;
