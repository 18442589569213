import graphql from "babel-plugin-relay/macro";

export default graphql`
  query getHygroupLinksQuery($tenantId: ID!) {
    hygroupLinks(tenantId: $tenantId) {
      id
      label
      url
      macro
    }
  }
`;
