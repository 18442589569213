import graphql from "babel-plugin-relay/macro";

export default graphql`
  query getPreviewQuery($id: ID!, $fields: Json, $subject: String) {
    hyblastEditor(id: $id, fields: $fields, subject: $subject) {
      templateCategoryId
      templateId
      propertyId
      id
      name
      subject
      mms
      mmsBody
      mmsMediaUrl
      mmsStatus
      lastTestEmails
      lastTestPhones
      approvalFeedbacks {
        id
        userEmail
        message
        createdAt
      }
      fields {
        editable
        dimension
        display
        label
        field
        value
        type
      }
      rawMacrosHtml
      substitutedMacrosHtml
    }
  }
`;
