import React from "react";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import withStyles from "@mui/styles/withStyles";

const styles = (theme) => ({
  root: {
    ...theme.margin,
    [theme.breakpoints.up("md")]: {
      ...theme.marginBig,
    },
    height: "100vh",
  },
  dataBackground: {
    background: "blue",
  },
  color: {
    color: "var(--hylyTheme)",
  },
});

const Loader = (props) => {
  const { classes } = props;
  return (
    <Grid
      container
      className={classes.root}
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress
        color="primary"
        classes={{
          colorPrimary: classes.color,
        }}
      />
    </Grid>
  );
};

export default withStyles(styles)(Loader);
