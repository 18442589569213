export const BlastIcon = () => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M47.5859 18.0547H18.0547C16.2425 18.0547 14.7734 19.5238 14.7734 21.3359V44.3047C14.7734 46.1169 16.2425 47.5859 18.0547 47.5859H47.5859C49.3981 47.5859 50.8672 46.1169 50.8672 44.3047V21.3359C50.8672 19.5238 49.3981 18.0547 47.5859 18.0547Z"
      fill="white"
      stroke="#333333"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M15.7803 18.9702L32.8198 34.461L49.8577 18.9719"
      stroke="#333333"
      strokeMiterlimit="10"
    />
  </svg>
);

export const DripsIcon = () => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M40.9473 24.6787H14.916V50.71H40.9473V24.6787Z"
      fill="white"
      stroke="#333333"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M19.7969 19.7979H45.8281V45.8291"
      stroke="#333333"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M24.6777 14.917H50.709V40.9482"
      stroke="#333333"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
);
export const AlertsIcon = () => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_92_1607)">
      <path
        d="M42.0833 30.1667V25.9167C42.0833 23.2866 41.0385 20.7643 39.1788 18.9045C37.3191 17.0448 34.7967 16 32.1667 16C29.5366 16 27.0143 17.0448 25.1545 18.9045C23.2948 20.7643 22.25 23.2866 22.25 25.9167V30.1667C22.25 34.8417 18 35.975 18 38.6667C18 41.075 23.525 42.9167 32.1667 42.9167C40.8083 42.9167 46.3333 41.075 46.3333 38.6667C46.3333 35.975 42.0833 34.8417 42.0833 30.1667Z"
        fill="white"
        stroke="#333333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.1667 45.7499C30.7344 45.7499 29.3886 45.7018 28.1221 45.6083C28.3921 46.4696 28.93 47.2222 29.6575 47.7565C30.385 48.2909 31.264 48.579 32.1667 48.579C33.0693 48.579 33.9484 48.2909 34.6758 47.7565C35.4033 47.2222 35.9412 46.4696 36.2113 45.6083C34.9448 45.7018 33.5989 45.7499 32.1667 45.7499Z"
        fill="white"
        stroke="#333333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_92_1607">
        <rect width="64" height="64" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const EventsIcon = () => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M46.0017 19.5566H18.0017C16.2835 19.5566 14.8906 20.9495 14.8906 22.6678V46.0011C14.8906 47.7193 16.2835 49.1122 18.0017 49.1122H46.0017C47.72 49.1122 49.1128 47.7193 49.1128 46.0011V22.6678C49.1128 20.9495 47.72 19.5566 46.0017 19.5566Z"
      fill="white"
      stroke="#333333"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M24.2244 33.5566H21.1133V35.1122H24.2244V33.5566Z"
      stroke="#333333"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M33.5574 33.5566H30.4463V35.1122H33.5574V33.5566Z"
      stroke="#333333"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M24.2244 41.334H21.1133V42.8895H24.2244V41.334Z"
      stroke="#333333"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M33.5574 41.334H30.4463V42.8895H33.5574V41.334Z"
      stroke="#333333"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M42.8904 33.5566H39.7793V35.1122H42.8904V33.5566Z"
      stroke="#333333"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path d="M24.2244 33.5566H21.1133V35.1122H24.2244V33.5566Z" fill="#333333" />
    <path d="M33.5574 33.5566H30.4463V35.1122H33.5574V33.5566Z" fill="#333333" />
    <path d="M24.2244 41.334H21.1133V42.8895H24.2244V41.334Z" fill="#333333" />
    <path d="M33.5574 41.334H30.4463V42.8895H33.5574V41.334Z" fill="#333333" />
    <path d="M42.8904 33.5566H39.7793V35.1122H42.8904V33.5566Z" fill="#333333" />
    <path
      d="M22.668 16.4453V21.112"
      stroke="#333333"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M41.335 16.4453V21.112"
      stroke="#333333"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M14.8906 27.334H49.1128"
      stroke="#333333"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
);

export const OffersIcon = () => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M44.9292 37.5427L50.4708 32.001L44.9292 26.4594V19.0705H37.5403L31.9986 13.5288L26.4569 19.0705H19.068V26.4594L13.5264 32.001L19.068 37.5427V44.9316H26.4569L31.9986 50.4733L37.5403 44.9316H44.9292V37.5427Z"
      fill="white"
      stroke="#333333"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M37.5404 26.4595L26.457 37.5428"
      stroke="#333333"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <svg id="offer-icon-svg-dashboard">
      <path
        d="M27.3802 30.154C28.9105 30.154 30.151 28.9134 30.151 27.3831C30.151 25.8528 28.9105 24.6123 27.3802 24.6123C25.8499 24.6123 24.6094 25.8528 24.6094 27.3831C24.6094 28.9134 25.8499 30.154 27.3802 30.154Z"
        fill="#333333"
      />
      <path
        d="M36.6165 39.3898C38.1468 39.3898 39.3874 38.1493 39.3874 36.619C39.3874 35.0887 38.1468 33.8481 36.6165 33.8481C35.0862 33.8481 33.8457 35.0887 33.8457 36.619C33.8457 38.1493 35.0862 39.3898 36.6165 39.3898Z"
        fill="#333333"
      />
    </svg>
  </svg>
);

export const HyRafIcon = () => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M31.9997 27.0908V33.6363L25.127 38.709" stroke="#333333" strokeMiterlimit="10" />
    <path d="M32 33.6362L38.8727 38.709" stroke="#333333" strokeMiterlimit="10" />
    <path
      d="M31.9996 27.0909C35.6145 27.0909 38.545 24.1604 38.545 20.5455C38.545 16.9305 35.6145 14 31.9996 14C28.3846 14 25.4541 16.9305 25.4541 20.5455C25.4541 24.1604 28.3846 27.0909 31.9996 27.0909Z"
      fill="white"
      stroke="#333333"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M20.5455 50.0001C24.1604 50.0001 27.0909 47.0696 27.0909 43.4546C27.0909 39.8397 24.1604 36.9092 20.5455 36.9092C16.9305 36.9092 14 39.8397 14 43.4546C14 47.0696 16.9305 50.0001 20.5455 50.0001Z"
      fill="white"
      stroke="#333333"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M43.4546 50.0001C47.0696 50.0001 50.0001 47.0696 50.0001 43.4546C50.0001 39.8397 47.0696 36.9092 43.4546 36.9092C39.8397 36.9092 36.9092 39.8397 36.9092 43.4546C36.9092 47.0696 39.8397 50.0001 43.4546 50.0001Z"
      fill="white"
      stroke="#333333"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
);

export const SuccessIcon = () => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="30.595" cy="32.595" r="15.595" fill="white" />
    <path
      d="M45.9451 30.2647C46.0675 31.0469 46.1299 31.8507 46.1299 32.6665C46.1299 41.2804 39.1476 48.2627 30.5337 48.2627C21.9198 48.2627 14.9375 41.2804 14.9375 32.6665C14.9375 24.0526 21.9198 17.0703 30.5337 17.0703C33.1299 17.0703 35.5773 17.7038 37.7319 18.8267"
      stroke="#333333"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.335 29.0674L30.5332 36.2656L49.7285 17.0703"
      stroke="#333333"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const TourIcon = () => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M46.0017 19.5566H18.0017C16.2835 19.5566 14.8906 20.9495 14.8906 22.6678V46.0011C14.8906 47.7193 16.2835 49.1122 18.0017 49.1122H46.0017C47.72 49.1122 49.1128 47.7193 49.1128 46.0011V22.6678C49.1128 20.9495 47.72 19.5566 46.0017 19.5566Z"
      fill="white"
      stroke="#333333"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M26.1562 37.8438L30.4062 42.0938L38.9062 33.5938"
      stroke="#333333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M48.4688 28.2812H16.5938"
      stroke="#333333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.0312 16.5938V22.9688"
      stroke="#333333"
      stroke-linecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41.0312 16.5938V22.9688"
      stroke="#333333"
      stroke-linecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const FreehandIcon = () => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M25.25 40.25H16.25V49.25H25.25V40.25Z"
      fill="white"
      stroke="#333333"
      stroke-linecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.75 25.25C23.2353 25.25 25.25 23.2353 25.25 20.75C25.25 18.2647 23.2353 16.25 20.75 16.25C18.2647 16.25 16.25 18.2647 16.25 20.75C16.25 23.2353 18.2647 25.25 20.75 25.25Z"
      fill="white"
      stroke="#333333"
      stroke-linecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M31.25 20.75H43.25C46.5635 20.75 49.25 23.4365 49.25 26.75V38.75C49.25 42.0635 46.5635 44.75 43.25 44.75H31.25"
      stroke="#333333"
      stroke-linecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const JAIIcon = () => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M25.25 40.25H16.25V49.25H25.25V40.25Z"
      fill="white"
      stroke="#333333"
      stroke-linecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.75 25.25C23.2353 25.25 25.25 23.2353 25.25 20.75C25.25 18.2647 23.2353 16.25 20.75 16.25C18.2647 16.25 16.25 18.2647 16.25 20.75C16.25 23.2353 18.2647 25.25 20.75 25.25Z"
      fill="white"
      stroke="#333333"
      stroke-linecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M31.25 20.75H43.25C46.5635 20.75 49.25 23.4365 49.25 26.75V38.75C49.25 42.0635 46.5635 44.75 43.25 44.75H31.25"
      stroke="#333333"
      stroke-linecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const HayleyIcon = ({
  viewBox = "0 0 36 36",
  className = "h-16 w-16",
  color = "#33333390",
}) => {
  return (
    <svg className={className} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          d="M22.5506 21.0297L22.4006 20.8494H22.1661L6.27237 20.8494C6.27215 20.8494 6.27193 20.8494 6.27171 20.8494C5.46749 20.847 4.69687 20.5264 4.12819 19.9577C3.5595 19.3891 3.23897 18.6184 3.23657 17.8142V5.87369C3.23956 5.06925 3.5606 4.29862 4.12964 3.72997C4.69873 3.16127 5.46965 2.84077 6.27415 2.83838H26.0348C26.8384 2.84196 27.6082 3.16295 28.1763 3.73144C28.7445 4.30004 29.0649 5.07016 29.0678 5.87398C29.0678 5.87416 29.0678 5.87434 29.0678 5.87452V17.8136C29.0678 17.8138 29.0678 17.814 29.0678 17.8142C29.0654 18.6185 28.7449 19.3891 28.1762 19.9577C27.6074 20.5266 26.8365 20.8471 26.032 20.8494L25.5334 20.8507V21.3494V24.6167L22.5506 21.0297Z"
          stroke={color}
        />
      </g>
      <path
        d="M15.4988 16.831C14.9064 16.7771 12.9752 16.6083 12.8773 15.9131C12.8969 15.5142 13.335 15.7614 13.5798 15.7418C14.8996 15.8772 16.233 15.7778 17.5181 15.4481C18.1431 15.2886 18.7451 15.05 19.3098 14.7382C19.4958 14.633 19.6647 14.4935 19.8507 14.4054C20.0464 14.2979 20.2592 14.225 20.4798 14.19C20.5775 14.1766 20.6771 14.1908 20.7672 14.231C20.8573 14.2712 20.9344 14.3359 20.9896 14.4177C21.0449 14.4994 21.0762 14.595 21.0799 14.6936C21.0837 14.7922 21.0598 14.8899 21.0109 14.9757C20.5605 15.7295 19.6941 16.0477 18.8692 16.3121C17.7934 16.7153 16.646 16.892 15.4988 16.831Z"
        fill={color}
      />
      <path
        d="M12.4956 10.9441C12.006 10.3298 12.0427 9.41922 12.29 8.7094C12.5372 7.99958 13.4453 7.65691 13.7586 8.56499C14.067 9.2895 13.7072 12.0211 12.4956 10.9441Z"
        fill={color}
      />
      <path
        d="M19.2411 11.1892C18.0785 10.92 18.4677 7.97791 19.4394 7.88001C20.2202 7.80168 20.3573 9.04265 20.345 9.59337C20.3769 10.2102 20.0587 11.4071 19.2411 11.1892Z"
        fill={color}
      />
      <path
        d="M11.607 6.84218C11.4234 6.26698 12.7378 6.00019 13.1367 5.89984C13.477 5.86067 14.062 5.63794 14.1966 6.09565C14.2049 6.14597 14.2026 6.19746 14.1899 6.24685C14.1772 6.29624 14.1543 6.34244 14.1228 6.38251C14.0912 6.42257 14.0517 6.45563 14.0067 6.47957C13.9616 6.50351 13.9121 6.5178 13.8612 6.52154C13.6159 6.50471 13.3694 6.51704 13.1269 6.55826C12.7785 6.62284 12.4356 6.71449 12.1014 6.83239C11.9423 6.85932 11.7146 7.02821 11.607 6.84218Z"
        fill={color}
      />
      <path
        d="M20.8053 6.84218C20.9913 6.26698 19.6769 6.00019 19.2779 5.89984C18.9353 5.86067 18.3527 5.63794 18.2157 6.09565C18.2077 6.14611 18.2104 6.19766 18.2234 6.24705C18.2364 6.29644 18.2595 6.3426 18.2912 6.38262C18.3229 6.42264 18.3626 6.45565 18.4078 6.47956C18.4529 6.50347 18.5025 6.51776 18.5534 6.52154C18.7988 6.50483 19.0453 6.51715 19.2877 6.55826C19.6355 6.62254 19.9776 6.7142 20.3109 6.83239C20.4699 6.85932 20.7 7.02821 20.8053 6.84218Z"
        fill={color}
      />
      <defs>
        <filter
          id="filter0_d_7914_33"
          x="0.618925"
          y="0.220732"
          width="31.0666"
          height="27.8969"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="1.05882" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7914_33" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_7914_33"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export const HayleyGPTIcon = ({ viewBox = "0 0 64 64", className = "h-16 w-16" }) => {
  return (
    <svg viewBox={viewBox} className={className} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M41.4493 38.2112C41.4493 38.2112 45.1661 38.1659 46.6736 37.3955C47.9602 36.7377 48.9831 35.8962 48.7688 34.5244C48.7688 34.5244 47.5592 35.5986 46.2571 35.3717C42.9798 34.8008 41.2352 36.84 41.4493 38.2112Z"
        fill="url(#paint0_radial_847_3972)"
      />
      <path
        d="M42.2924 36.0318C42.2924 36.0318 46.6108 35.9794 48.3617 35.084C49.8567 34.3197 51.0451 33.342 50.7962 31.7485C50.7962 31.7485 49.3909 32.9965 47.878 32.7329C44.0706 32.0695 42.0439 34.4385 42.2924 36.0318Z"
        fill="url(#paint1_radial_847_3972)"
      />
      <path
        d="M42.54 34.7404C42.54 34.7404 47.8888 34.185 49.9586 32.8759C51.7255 31.7584 53.0882 30.4111 52.5987 28.4634C52.5987 28.4634 50.9977 30.1705 49.0917 30.0154C44.2952 29.6252 42.0509 32.7928 42.54 34.7404Z"
        fill="url(#paint2_radial_847_3972)"
      />
      <path
        d="M23.895 37.7752C23.895 37.7752 20.1783 37.73 18.6708 36.9595C17.384 36.3017 16.361 35.46 16.5753 34.0884C16.5753 34.0884 17.785 35.1625 19.0874 34.9357C22.3646 34.3648 24.1092 36.404 23.895 37.7752Z"
        fill="url(#paint3_radial_847_3972)"
      />
      <path
        d="M23.0522 35.5967C23.0522 35.5967 18.7341 35.5441 16.9829 34.649C15.4879 33.8846 14.2995 32.9069 14.5484 31.3135C14.5484 31.3135 15.9537 32.5614 17.4666 32.2979C21.2741 31.6345 23.3011 34.0035 23.0522 35.5967Z"
        fill="url(#paint4_radial_847_3972)"
      />
      <path
        d="M22.8036 34.3053C22.8036 34.3053 17.455 33.7499 15.385 32.4409C13.6181 31.3233 12.2554 29.976 12.745 28.0283C12.745 28.0283 14.3459 29.7354 16.2519 29.5803C21.049 29.1901 23.2931 32.3578 22.8036 34.3053Z"
        fill="url(#paint5_radial_847_3972)"
      />
      <path
        d="M40.3588 16.6787C40.3588 14.5771 33.8366 14.5171 33.0933 14.5171C32.3501 14.5171 25.8278 14.5772 25.8278 16.6787C25.8278 16.9261 25.919 17.1458 26.0788 17.3392C26.3058 17.613 26.6732 17.8376 27.1236 18.0201C29.0652 18.8091 32.5614 18.8404 33.0933 18.8404C33.6278 18.8404 37.1511 18.8092 39.0884 18.0106C39.528 17.8293 39.8858 17.6083 40.1079 17.3392C40.2688 17.1459 40.3588 16.9261 40.3588 16.6787ZM39.0476 16.6499C39.062 16.6594 39.0764 16.6691 39.0884 16.6787C38.6057 17.0498 36.564 17.6393 33.0933 17.6393C29.6804 17.6393 27.6495 17.0689 27.1236 16.6967C27.1151 16.6907 27.1068 16.6846 27.0984 16.6787C27.1104 16.6691 27.1248 16.6594 27.1392 16.6499C27.6879 16.2776 29.7128 15.718 33.0933 15.718C36.4739 15.718 38.4987 16.2776 39.0476 16.6499Z"
        fill="url(#paint6_linear_847_3972)"
      />
      <path
        d="M39.1018 18.0047C39.6682 17.7691 39.6843 16.9708 39.134 16.6997C39.1035 16.6846 39.0867 16.6769 39.0885 16.6784C38.6059 17.0495 36.5642 17.6391 33.0934 17.6391C29.6806 17.6391 27.6497 17.0687 27.1237 16.6964C27.1153 16.6904 27.1069 16.6844 27.0985 16.6784C27.1003 16.6769 27.0839 16.6845 27.0541 16.6991C26.5019 16.9709 26.5213 17.7707 27.0901 18.0061C27.1012 18.0107 27.1124 18.0152 27.1237 18.0198C29.0654 18.8088 32.5615 18.8401 33.0934 18.8401C33.6279 18.8401 37.1513 18.8089 39.0885 18.0102C39.093 18.0083 39.0973 18.0065 39.1018 18.0047Z"
        fill="url(#paint7_radial_847_3972)"
      />
      <path
        d="M30.7627 51.0001H28.4167C28.15 51.0001 27.9337 50.7838 27.9337 50.517C27.9337 50.2503 28.15 50.034 28.4167 50.034H29.5734C28.4282 44.123 29.6572 40.6792 29.7118 40.5309C29.8039 40.2806 30.0818 40.1526 30.332 40.2447C30.5821 40.3368 30.7102 40.6139 30.6184 40.8641C30.6008 40.9132 29.3582 44.4584 30.6373 50.4156L30.7627 51.0001Z"
        fill="#009BCC"
      />
      <path
        d="M37.7688 50.9996H35.3352L35.4608 50.4153C36.7421 44.4461 35.4921 40.8987 35.4793 40.8635C35.3884 40.6133 35.5171 40.3359 35.7671 40.2444C36.0171 40.153 36.2942 40.2806 36.3862 40.5306C36.4409 40.6789 37.6697 44.1226 36.5246 50.0337H37.7688C38.0357 50.0337 38.252 50.25 38.252 50.5167C38.252 50.7834 38.0357 50.9996 37.7688 50.9996Z"
        fill="#009BCC"
      />
      <path
        d="M44.4645 42.664C44.4413 42.6681 44.4178 42.671 44.3939 42.673C44.1543 42.6923 43.9714 42.5914 43.9854 42.4475C44.3251 38.9738 42.715 36.6329 42.6988 36.6096C42.6122 36.4868 42.724 36.3246 42.9478 36.2474C43.1721 36.1703 43.4231 36.2071 43.5097 36.3299C43.5792 36.4284 45.2048 38.7841 44.8535 42.3774C44.8408 42.5069 44.6727 42.6272 44.4645 42.664Z"
        fill="#009BCC"
      />
      <path
        d="M43.5055 42.5418C43.4424 41.9248 43.8244 41.3594 44.3584 41.2786C44.8926 41.1977 45.3766 41.6324 45.4396 42.2493C45.5025 42.8662 45.1206 43.4317 44.5864 43.5124C44.0524 43.5932 43.5684 43.1586 43.5055 42.5418Z"
        fill="#009BCC"
      />
      <path
        d="M22.1573 42.6128C22.1805 42.6169 22.204 42.6198 22.2279 42.6218C22.4675 42.6412 22.6504 42.5402 22.6363 42.3963C22.2964 38.9226 23.9064 36.5816 23.9226 36.5584C24.0091 36.4355 23.8973 36.2733 23.6736 36.1962C23.4492 36.1191 23.1982 36.1559 23.1117 36.2787C23.0422 36.3772 21.4167 38.733 21.7683 42.3262C21.781 42.4557 21.9491 42.5761 22.1573 42.6128Z"
        fill="#009BCC"
      />
      <path
        d="M23.1164 42.4915C23.1794 41.8745 22.7974 41.3091 22.2634 41.2283C21.7292 41.1475 21.2453 41.5822 21.1823 42.1992C21.1195 42.816 21.5013 43.3815 22.0355 43.4621C22.5696 43.5429 23.0536 43.1083 23.1164 42.4915Z"
        fill="#009BCC"
      />
      <path
        d="M43.8008 31.8048C43.8008 36.7413 42.9499 41.0776 38.8196 42.9864C38.7019 43.041 38.5815 43.094 38.4586 43.1437C37.5566 43.5128 36.5088 43.7746 35.2934 43.9107C34.6584 43.9824 33.9769 44.0198 33.2462 44.0207H33.2217C33.1786 44.0207 33.1364 44.0198 33.0933 44.0189C33.0503 44.0198 33.0083 44.0207 32.9643 44.0207H32.9388C32.2091 44.0198 31.5277 43.9824 30.8926 43.9107C29.6772 43.7745 28.6294 43.5128 27.7272 43.1437C27.6045 43.0931 27.484 43.0403 27.3664 42.9857C23.2362 41.0767 22.385 36.7406 22.385 31.8048C22.385 25.9325 25.732 21.9305 30.7082 21.0748C31.3686 20.9603 32.057 20.9019 32.7711 20.9019C32.8274 20.9019 32.8825 20.9026 32.9387 20.9026C32.9905 20.9042 33.0414 20.9049 33.0933 20.9065C33.1442 20.9049 33.1951 20.9042 33.2461 20.9026C33.3024 20.9026 33.3586 20.9019 33.4147 20.9019C34.1288 20.9019 34.8173 20.9603 35.4776 21.0748C40.4539 21.9305 43.8008 25.9324 43.8008 31.8048Z"
        fill="url(#paint8_radial_847_3972)"
      />
      <path
        d="M40.5224 39.6096L40.5118 39.6382L39.3219 39.8484C39.3165 40.13 39.2639 41.7925 38.743 43.0548C38.6746 43.0749 38.6053 43.0944 38.5349 43.1129C39.1207 41.7555 39.1462 39.8364 39.1471 39.8113V39.7741L40.36 39.56C40.5452 38.9782 40.5206 36.6353 40.5197 36.6114L40.6953 36.6108C40.697 36.7113 40.7217 39.0707 40.5224 39.6096Z"
        fill="#ABB1B8"
      />
      <path
        d="M27.039 39.8113C27.0401 39.8364 27.0655 41.7549 27.6512 43.1124C27.5811 43.0939 27.5116 43.0743 27.4432 43.0543C26.9224 41.792 26.8696 40.13 26.8645 39.8484L25.6744 39.6382L25.6639 39.6096C25.4645 39.0707 25.4892 36.7113 25.4908 36.6108L25.6665 36.6114C25.6656 36.6353 25.6409 38.9782 25.8263 39.56L27.039 39.7741V39.8113Z"
        fill="#ABB1B8"
      />
      <path
        d="M33.1809 20.9048V44.02H33.0052V20.9048C33.0342 20.9048 33.0641 20.9056 33.0931 20.9064C33.1221 20.9056 33.1519 20.9048 33.1809 20.9048Z"
        fill="#ABB1B8"
      />
      <path
        d="M28.5833 37.9031H37.6027C40.7084 37.9031 43.3047 35.6157 43.7924 32.6426C43.7437 30.9534 43.4723 29.4095 43.0085 28.0356C41.6924 25.4693 39.0167 23.7026 35.9482 23.7026H30.2378C27.1683 23.7026 24.4921 25.4705 23.1763 28.0381C22.7132 29.4108 22.4422 30.9532 22.3933 32.6406C22.8805 35.6147 25.4771 37.9031 28.5833 37.9031Z"
        fill="#ABB1B8"
      />
      <path
        d="M37.6031 37.2167H28.5836C25.1335 37.2167 22.3107 34.3937 22.3107 30.9436C22.3107 26.5835 25.8779 23.0161 30.2382 23.0161H35.9487C40.3089 23.0161 43.8764 26.5835 43.8764 30.9436C43.8763 34.3937 41.0535 37.2167 37.6031 37.2167Z"
        fill="url(#paint9_radial_847_3972)"
      />
      <path
        d="M37.2171 36.6091H28.9697C25.8148 36.6091 23.2335 34.0278 23.2335 30.873C23.2335 26.8861 26.4955 23.624 30.4826 23.624H35.7045C39.6914 23.624 42.9536 26.8861 42.9536 30.873C42.9535 34.0278 40.3722 36.6091 37.2171 36.6091Z"
        fill="url(#paint10_linear_847_3972)"
      />
      <path
        d="M37.2724 36.594H28.9133C25.9722 36.594 23.5656 34.1874 23.5656 31.2462V30.9948C23.5656 27.2779 26.6067 24.2368 30.3237 24.2368H35.862C39.5791 24.2368 42.62 27.2779 42.62 30.9948V31.2462C42.6201 34.1873 40.2139 36.594 37.2724 36.594Z"
        fill="white"
      />
      <path
        d="M37.8451 33.4242C38.1732 33.4242 38.4418 33.2953 38.4418 33.1376C38.965 31.6147 38.9528 30.0484 38.4418 28.4415C38.4418 28.2838 38.1732 28.1548 37.8451 28.1548C37.5171 28.1548 37.2487 28.2838 37.2487 28.4415V33.1376C37.2487 33.2953 37.5171 33.4242 37.8451 33.4242Z"
        fill="url(#paint11_radial_847_3972)"
      />
      <path
        d="M28.688 33.4242C28.3602 33.4242 28.0916 33.2954 28.0916 33.1375C27.5686 31.6145 27.5806 30.0484 28.0916 28.4417C28.0916 28.2837 28.3602 28.1548 28.688 28.1548C29.0163 28.1548 29.2847 28.2837 29.2847 28.4417V33.1375C29.2847 33.2954 29.0163 33.4242 28.688 33.4242Z"
        fill="url(#paint12_radial_847_3972)"
      />
      <defs>
        <radialGradient
          id="paint0_radial_847_3972"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(45.9168 36.7983) rotate(171.124) scale(5.66375 2.57941)"
        >
          <stop stop-color="white" />
          <stop offset="0.1486" stop-color="#F9FDFF" />
          <stop offset="0.2832" stop-color="#EDF7FD" />
          <stop offset="0.4125" stop-color="#D9F0FC" />
          <stop offset="0.5386" stop-color="#BEE5FA" />
          <stop offset="0.6623" stop-color="#99D8F7" />
          <stop offset="0.7842" stop-color="#67CAF3" />
          <stop offset="0.9023" stop-color="#00BCF0" />
          <stop offset="1" stop-color="#00B0ED" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_847_3972"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(47.4827 34.3904) rotate(171.124) scale(6.57999 2.99668)"
        >
          <stop stop-color="white" />
          <stop offset="0.1486" stop-color="#F9FDFF" />
          <stop offset="0.2832" stop-color="#EDF7FD" />
          <stop offset="0.4125" stop-color="#D9F0FC" />
          <stop offset="0.5386" stop-color="#BEE5FA" />
          <stop offset="0.6623" stop-color="#99D8F7" />
          <stop offset="0.7842" stop-color="#67CAF3" />
          <stop offset="0.9023" stop-color="#00BCF0" />
          <stop offset="1" stop-color="#00B0ED" />
        </radialGradient>
        <radialGradient
          id="paint2_radial_847_3972"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(48.7903 32.1157) rotate(165.89) scale(8.19365 3.7314)"
        >
          <stop stop-color="white" />
          <stop offset="0.1486" stop-color="#F9FDFF" />
          <stop offset="0.2832" stop-color="#EDF7FD" />
          <stop offset="0.4125" stop-color="#D9F0FC" />
          <stop offset="0.5386" stop-color="#BEE5FA" />
          <stop offset="0.6623" stop-color="#99D8F7" />
          <stop offset="0.7842" stop-color="#67CAF3" />
          <stop offset="0.9023" stop-color="#00BCF0" />
          <stop offset="1" stop-color="#00B0ED" />
        </radialGradient>
        <radialGradient
          id="paint3_radial_847_3972"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(19.4273 36.3623) rotate(8.87552) scale(5.66385 2.57945)"
        >
          <stop stop-color="white" />
          <stop offset="0.1486" stop-color="#F9FDFF" />
          <stop offset="0.2832" stop-color="#EDF7FD" />
          <stop offset="0.4125" stop-color="#D9F0FC" />
          <stop offset="0.5386" stop-color="#BEE5FA" />
          <stop offset="0.6623" stop-color="#99D8F7" />
          <stop offset="0.7842" stop-color="#67CAF3" />
          <stop offset="0.9023" stop-color="#00BCF0" />
          <stop offset="1" stop-color="#00B0ED" />
        </radialGradient>
        <radialGradient
          id="paint4_radial_847_3972"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(17.8619 33.9552) rotate(8.87552) scale(6.58 2.99669)"
        >
          <stop stop-color="white" />
          <stop offset="0.1486" stop-color="#F9FDFF" />
          <stop offset="0.2832" stop-color="#EDF7FD" />
          <stop offset="0.4125" stop-color="#D9F0FC" />
          <stop offset="0.5386" stop-color="#BEE5FA" />
          <stop offset="0.6623" stop-color="#99D8F7" />
          <stop offset="0.7842" stop-color="#67CAF3" />
          <stop offset="0.9023" stop-color="#00BCF0" />
          <stop offset="1" stop-color="#00B0ED" />
        </radialGradient>
        <radialGradient
          id="paint5_radial_847_3972"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(16.5534 31.6806) rotate(14.1099) scale(8.19359 3.73137)"
        >
          <stop stop-color="white" />
          <stop offset="0.1486" stop-color="#F9FDFF" />
          <stop offset="0.2832" stop-color="#EDF7FD" />
          <stop offset="0.4125" stop-color="#D9F0FC" />
          <stop offset="0.5386" stop-color="#BEE5FA" />
          <stop offset="0.6623" stop-color="#99D8F7" />
          <stop offset="0.7842" stop-color="#67CAF3" />
          <stop offset="0.9023" stop-color="#00BCF0" />
          <stop offset="1" stop-color="#00B0ED" />
        </radialGradient>
        <linearGradient
          id="paint6_linear_847_3972"
          x1="25.8277"
          y1="16.6787"
          x2="40.3589"
          y2="16.6787"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.014" stop-color="#00B0ED" />
          <stop offset="0.1059" stop-color="#77CEF3" />
          <stop offset="0.2637" stop-color="#77CEF3" />
          <stop offset="0.9391" stop-color="#77CEF3" />
          <stop offset="1" stop-color="#00B0ED" />
        </linearGradient>
        <radialGradient
          id="paint7_radial_847_3972"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(33.0942 17.7591) scale(4.6193 4.6193)"
        >
          <stop stop-color="#63C9F3" />
          <stop offset="0.5787" stop-color="#00B0ED" />
          <stop offset="0.8316" stop-color="#00AFEB" />
          <stop offset="0.9226" stop-color="#00ABE5" />
          <stop offset="0.9874" stop-color="#00A5DC" />
          <stop offset="1" stop-color="#009BCC" />
        </radialGradient>
        <radialGradient
          id="paint8_radial_847_3972"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(32.7925 25.4718) scale(15.3946 18.1086)"
        >
          <stop stop-color="#63C9F3" />
          <stop offset="0.4961" stop-color="#00B0ED" />
          <stop offset="0.7732" stop-color="#00AFEB" />
          <stop offset="0.873" stop-color="#00ABE3" />
          <stop offset="0.9439" stop-color="#00A4D9" />
          <stop offset="1" stop-color="#009BCC" />
        </radialGradient>
        <radialGradient
          id="paint9_radial_847_3972"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(33.0935 30.1164) scale(9.12916)"
        >
          <stop stop-color="#63C9F3" />
          <stop offset="0.4961" stop-color="#00B0ED" />
          <stop offset="0.7732" stop-color="#00AFEB" />
          <stop offset="0.873" stop-color="#00ABE3" />
          <stop offset="0.9439" stop-color="#00A4D9" />
          <stop offset="1" stop-color="#009BCC" />
        </radialGradient>
        <linearGradient
          id="paint10_linear_847_3972"
          x1="33.2799"
          y1="23.62"
          x2="33.094"
          y2="30.8444"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="5.4e-07" stop-color="#DDDFE3" />
          <stop offset="0.2271" stop-color="#E8E9EB" />
          <stop offset="0.5942" stop-color="#F4F4F5" />
          <stop offset="1" stop-color="#F9F9F9" />
        </linearGradient>
        <radialGradient
          id="paint11_radial_847_3972"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(38.0392 30.7895) scale(1.94503)"
        >
          <stop stop-color="#00B0ED" />
          <stop offset="0.0849" stop-color="#00ABE5" />
          <stop offset="0.2237" stop-color="#00A3D4" />
          <stop offset="0.3991" stop-color="#2995B9" />
          <stop offset="0.6042" stop-color="#4B7C92" />
          <stop offset="0.8315" stop-color="#47565F" />
          <stop offset="1" />
        </radialGradient>
        <radialGradient
          id="paint12_radial_847_3972"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(28.4942 30.7895) scale(1.94503 1.94503)"
        >
          <stop stop-color="#00B0ED" />
          <stop offset="0.0849" stop-color="#00ABE5" />
          <stop offset="0.2237" stop-color="#00A3D4" />
          <stop offset="0.3991" stop-color="#2995B9" />
          <stop offset="0.6042" stop-color="#4B7C92" />
          <stop offset="0.8315" stop-color="#47565F" />
          <stop offset="1" />
        </radialGradient>
      </defs>
    </svg>
  );
};
