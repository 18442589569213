import React from "react";
import withStyles from "@mui/styles/withStyles";
import { Button } from "storybookComponents";
import { Modal } from "components";
import styles from "./popupStyles";
import { TextField, Typography } from "@mui/material";

const EditReportPopup = ({
  classes,
  open,
  onEdit,
  onClose,
  name = "",
  value = "",
  onChange,
  header = "Tour Schedule URL",
}) => {
  return (
    <Modal
      open={open}
      title={<div className={classes.reportHeader}>Rename : {name}</div>}
      handleClose={onClose}
      className={{
        dialogRoot: classes.dialogRoot,
      }}
      actionChildren={
        <>
          <Button onClick={onClose} variant="tertiary" className={classes.button}>
            Cancel
          </Button>
          <Button variant="primary" onClick={onEdit}>
            Update
          </Button>
        </>
      }
    >
      <div>
        <Typography type="subtitle" color="black" font_weight="600" className={classes.label}>
          {header}
        </Typography>
        <div className={classes.field}>
          <TextField
            fullWidth
            variant="standard"
            placeholder={`Enter ${header}`}
            value={value}
            onChange={onChange}
          />
        </div>
      </div>
    </Modal>
  );
};

export default withStyles(styles)(EditReportPopup);
