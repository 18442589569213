import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isShowHeaderFavorite: false,
  isShowHeaderCopyLink: false,
};

const showHeaderFavoriteSlice = createSlice({
  name: "showHeaderFavorite",
  initialState,
  reducers: {
    setShowHeaderFav: (state) => {
      state.isShowHeaderFavorite = true;
    },
    setHideHeaderFav: (state) => {
      state.isShowHeaderFavorite = false;
    },
    setShowHeaderCopyLink: (state) => {
      state.isShowHeaderCopyLink = true;
    },
    setHideHeaderCopyLink: (state) => {
      state.isShowHeaderCopyLink = false;
    },
  },
});

export const { setShowHeaderFav, setHideHeaderFav, setShowHeaderCopyLink, setHideHeaderCopyLink } =
  showHeaderFavoriteSlice.actions;

export default showHeaderFavoriteSlice.reducer;

export const getIsShowHeaderFavorite = (state) => state.showHeaderFavorite.isShowHeaderFavorite;
export const getIsShowHeaderCopyLink = (state) => state.showHeaderFavorite.isShowHeaderCopyLink;
