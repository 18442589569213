import graphql from "babel-plugin-relay/macro";

export default graphql`
  query getGptHistoryByIdQuery($id: String!) {
    retrieveGptHistory(id: $id) {
      id
      tokenCount
      gptCost
      gptModel
      promptId
      promptTemplate
      uxFields
      promptDescription
      generationTime
      title {
        id
        value
        feedback {
          liked
        }
        fieldType
        tokenCount
        generationTime
        regenerated
        humanReplaced
      }
      disclaimer {
        id
        value
        feedback {
          liked
        }
        fieldType
        tokenCount
        generationTime
        regenerated
        humanReplaced
      }
      subTitle {
        id
        value
        feedback {
          liked
        }
        fieldType
        tokenCount
        generationTime
        regenerated
        humanReplaced
      }
      preheader {
        id
        value
        feedback {
          liked
        }
        fieldType
        tokenCount
        generationTime
        regenerated
        humanReplaced
      }
      subject {
        id
        value
        feedback {
          liked
        }
        fieldType
        tokenCount
        generationTime
        regenerated
        humanReplaced
      }
      body {
        id
        value
        feedback {
          liked
        }
        fieldType
        tokenCount
        generationTime
        regenerated
        humanReplaced
      }
      ctaText {
        id
        value
        feedback {
          liked
        }
        fieldType
        tokenCount
        generationTime
        regenerated
        humanReplaced
      }
    }
  }
`;
