import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  orgSuborgDetails: null,
  subOrgDetails: {},
  dripsMenu: "",
  propertyCount: 0,
  alertsMenu: "",
  toursMenu: "",
  disableOrgLevelClick: false,
};

const orgSuborgDetailsSlice = createSlice({
  name: "orgSuborg",
  initialState,
  reducers: {
    setStates4OrgSuborg: (state, action) => {
      state.orgSuborgDetails = action.payload;
    },
    setSuborgDetails: (state, action) => {
      state.subOrgDetails = action.payload;
    },
    setDripsMenu: (state, action) => {
      state.dripsMenu = action.payload;
    },
    setPropertyCount: (state, action) => {
      state.propertyCount = action.payload;
    },
    setAlertsMenu: (state, action) => {
      state.alertsMenu = action.payload;
    },
    setToursMenu: (state, action) => {
      state.toursMenu = action.payload;
    },
    setOrgLevelClick: (state, action) => {
      state.disableOrgLevelClick = action.payload;
    },
  },
});

export const getStates4OrgSuborg = (state) => state.orgSuborg.orgSuborgDetails;
export const getSuborgDetails = (state) => state.orgSuborg.subOrgDetails;
export const getDripsMenu = (state) => state.orgSuborg.dripsMenu;
export const getPropertyCount = (state) => state.orgSuborg.propertyCount;
export const getAlertsMenu = (state) => state.orgSuborg.alertsMenu;
export const getToursMenu = (state) => state.orgSuborg.toursMenu;
export const getDisableOrgLevelClick = (state) => state.orgSuborg.disableOrgLevelClick;
export const {
  setStates4OrgSuborg,
  setSuborgDetails,
  setDripsMenu,
  setPropertyCount,
  setAlertsMenu,
  setToursMenu,
  setOrgLevelClick,
} = orgSuborgDetailsSlice.actions;
export default orgSuborgDetailsSlice.reducer;
