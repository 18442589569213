import moment from "moment";
import { numberWithCommas } from "content/utils/commonParsing";
import { compare } from "content/utils/commonParsing";
import { Button, SearchBox, SearchAutocomplete } from "storybookComponents";
import { Typography } from "@mui/material";
import * as path from "constants/path";
import { ReactComponent as Like } from "images/Like.svg";
import { ReactComponent as Dislike } from "images/Dislike.svg";
import { ReactComponent as Reload } from "images/Reload.svg";
import { ReactComponent as Account } from "images/Account.svg";
import { getAppPath } from "constants/path";
import { AllOrgsBlastAnalysis, orgGptBlastAnalysis } from "../../../relay/eBlasts/index";
import { mailGptBlastTypeReports } from "relay/eBlasts/index";
import { allOrgsGptFieldsAnalysis } from "relay/eBlasts/index";
import SearchByDate from "storybookComponents/Picker/searchByDate";

export const isEmpty = (obj) => {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      return false; // If the object has any own property, it's not empty
    }
  }
  return true; // If the loop completes without finding any own properties, the object is empty
};

export const filterBySearchText = (text, filterValue) => {
  if (!text) {
    return false;
  }
  return text.toLowerCase().includes(filterValue.toLowerCase());
};

export const filterByGreaterThan = (number, filterNumber) =>
  parseFloat(number) >= parseFloat(filterNumber);

export const filterByGreaterThanDate = (date, filterDate) => new Date(date) >= new Date(filterDate);

export const parseCustomDate = (dateString) => {
  const months = {
    Jan: 0,
    Feb: 1,
    Mar: 2,
    Apr: 3,
    May: 4,
    Jun: 5,
    Jul: 6,
    Aug: 7,
    Sep: 8,
    Oct: 9,
    Nov: 10,
    Dec: 11,
  };

  const parts = dateString.split(/[\s,-]+/);

  // Validate parts length
  if (parts.length !== 4) {
    console.error("Invalid date string format");
    return null;
  }

  const month = months[parts[0]];
  const day = parseInt(parts[1]);
  const year = parseInt(parts[2]);
  const timeParts = parts[3].match(/^(\d+):(\d+)([ap]m)$/i);

  if (!timeParts) {
    console.error("Invalid time format");
    return null;
  }

  let hours = parseInt(timeParts[1]);
  const minutes = parseInt(timeParts[2]);
  const period = timeParts[3].toLowerCase();

  // Validate month, day, year, hours, and minutes
  if (isNaN(month) || isNaN(day) || isNaN(year) || isNaN(hours) || isNaN(minutes)) {
    console.error("Invalid date components");
    return null;
  }

  // Adjust hours for AM/PM
  if (period === "pm" && hours !== 12) {
    hours += 12;
  } else if (period === "am" && hours === 12) {
    hours = 0;
  }

  // Validate the constructed date
  const date = new Date(year, month, day, hours, minutes);
  if (isNaN(date.getTime())) {
    console.error("Invalid date");
    return null;
  }

  return date;
};

export const sortByDateForPropertyAudit = (data, fieldName, order) => {
  return data.slice().sort((a, b) => {
    const dateA = a[fieldName] ? moment(a[fieldName], "YYYY-MM-DD h:mm") : null;
    const dateB = b[fieldName] ? moment(b[fieldName], "YYYY-MM-DD h:mm") : null;

    if (dateA === null && dateB === null) {
      return 0; // If both dates are null, consider them equal
    } else if (dateA === null) {
      return order ? 1 : -1; // Null values come last (or first depending on the order)
    } else if (dateB === null) {
      return order ? -1 : 1; // Null values come last (or first depending on the order)
    } else {
      return order ? dateB - dateA : dateA - dateB;
    }
  });
};

export const sortByDate = (data, fieldName, order) => {
  return data.slice().sort((a, b) => {
    const dateA = moment(a[fieldName], "MMM DD,YYYY h:mm A");
    const dateB = moment(b[fieldName], "MMM DD,YYYY h:mm A");
    return order ? dateA - dateB : dateB - dateA;
  });
};

export const sortByDateForByBlast = (data, fieldName, order) => {
  return data.slice().sort((a, b) => {
    // Get the dates from the fields, handling null or "--" values
    const dateA =
      a[fieldName] && a[fieldName] !== "--" ? moment(a[fieldName], "MMM DD,YYYY h:mm A") : null;
    const dateB =
      b[fieldName] && b[fieldName] !== "--" ? moment(b[fieldName], "MMM DD,YYYY h:mm A") : null;

    // Handle cases where one or both dates are null
    if (!dateA && !dateB) return 0; // If both are null, consider them equal
    if (!dateA) return order ? 1 : -1; // Null is considered greater than any valid date
    if (!dateB) return order ? -1 : 1; // Null is considered greater than any valid date

    // Compare the dates if both are valid
    return order ? dateB - dateA : dateA - dateB;
  });
};

export const getBlastType = () => {
  const filters = [
    {
      id: "Offers",
      value: "Offers",
    },
    {
      id: "Events",
      value: "Events",
    },
    {
      id: "Pre_Leasing",
      value: "Pre-Leasing",
    },
    {
      id: "Seasonals",
      value: "Seasonals",
    },
    {
      id: "Announcements",
      value: "Announcements",
    },
  ];
  return filters;
};

export const getPromptTemplate = () => {
  const filters = [
    {
      id: "Current and Holiday Offers",
      value: "Current Offers",
    },
    {
      id: "General Offers For Prospect",
      value: "General Offers  ",
    },
    {
      id: "Look and Lease Special For Prospect",
      value: "Look and Lease",
    },
    {
      id: "Waived Fee Offer For Prospect",
      value: "Waived Fee Offer",
    },
    {
      id: "Pet Offers For Prospect",
      value: "Pet Offers",
    },
  ];
  return filters;
};

export const reportsOptions = [
  { label: "Property Audit", id: "property-audit" },
  { label: "By Property", id: "by-property" },
  { label: "By Blast", id: "by-blasts" },
  { label: "Blast Analytics", id: "blasts-analytics" },
  { label: "Blasts Contact List", id: "blast-contact-list" },
  // { label: "Blast A/B", id: "blast-a-b" },
  { label: "By Email Platform", id: "by-email-platform" },
  { label: "My Favorites", id: "my-favorites" },
];
export const reportsOptions4SMS = [
  { label: "Property Audit", id: "property-audit" },
  { label: "By Property", id: "by-property" },
  { label: "By SMS", id: "by-blasts" },
  { label: "SMS Analytics", id: "blasts-analytics" },
  { label: "SMS Contact List", id: "blast-contact-list" },
  // { label: "Blast A/B", id: "blast-a-b" },
  { label: "By Email Platform", id: "by-email-platform" },
  { label: "My Favorites", id: "my-favorites" },
];

export const propertyAuditCSVHeader = [
  { label: "Property", key: "property" },
  { label: "Days Since", key: "daysSince" },
  { label: "Last Sent Date", key: "lastSentDate" },
  { label: "Last Sent Blast", key: "lastSentEblast" },
  { label: "Blasts Sent", key: "eblastsCount" },
  { label: "# Sends", key: "sendsNumber" },
  { label: "% Opens", key: "opensPercentage" },
  { label: "% Clicks", key: "clicksPercentage" },
];

export const propertyAuditNumberCSVHeader = [
  { label: "Property", key: "property" },
  { label: "Days Since", key: "daysSince" },
  { label: "Last Sent Date", key: "lastSentDate" },
  { label: "Last Sent Blast", key: "lastSentEblast" },
  { label: "Blasts Sent", key: "eblastsCount" },
  { label: "# Sends", key: "sendsNumber" },
  { label: "# Opens", key: "opens" },
  { label: "# Clicks", key: "clicks" },
];

export const eblastCSVHeaders = [
  { label: "Blast", key: "annotation" },
  { label: "Subject", key: "subject" },
  { label: "# Unique Sends", key: "sends_count" },
  { label: "% Unique Opens", key: "unique_opens_count_percentage" },
  { label: "% Unique Clicks", key: "unique_clicks_count_percentage" },
  { label: "% Unsubscribes", key: "unsubscribes_count_percentage" },
  { label: "% Bounce", key: "bounces_count_percentage" },
];
export const eblastCSVHeaders4SMS = [
  { label: "Blast", key: "annotation" },
  { label: "Subject", key: "subject" },
  { label: "#Sent", key: "sms_sends_count" },
  { label: "% Undelivered", key: "sms_undelivered_count_percentage" },
  { label: "% Clicked", key: "sms_clicks_count_percentage" },
  { label: "% Unsubscribes", key: "sms_unsubscribed_count_percentage" },
  { label: "Sent At", key: "sent_at_percentage" },
];
export const eblastCSVNumberHeaders = [
  { label: "Blast", key: "annotation" },
  { label: "Subject", key: "subject" },
  { label: "# Unique Sends", key: "sends_count" },
  { label: "# Unique Opens", key: "unique_opens_count" },
  { label: "# Unique Clicks", key: "unique_clicks_count" },
  { label: "# Unsubscribes", key: "unsubscribes_count" },
  { label: "# Bounce", key: "bounces_count" },
];
export const eblastCSVNumberHeaders4SMS = [
  { label: "Blast", key: "annotation" },
  { label: "Subject", key: "subject" },
  { label: "#Sent", key: "sms_sends_count" },
  { label: "# Undelivered", key: "sms_undelivered_count" },
  { label: "# Clicked", key: "sms_clicks_count" },
  { label: "# Unsubscribes", key: "sms_unsubscribed_count" },
  { label: "Sent At", key: "sent_at_percentage" },
];

export const propertyCSVHeader = [
  { label: "Property", key: "annotation" },
  { label: "#Blast", key: "count" },
  ...eblastCSVHeaders.slice(2),
];
export const propertyCSVHeader4SMS = [
  { label: "Property", key: "annotation" },
  { label: "#Blast", key: "count" },
  ...eblastCSVHeaders4SMS.slice(2, 6),
];
export const propertyCSVNumberHeader = [
  { label: "Property", key: "annotation" },
  { label: "#Blast", key: "count" },
  ...eblastCSVNumberHeaders.slice(2),
];
export const propertyCSVNumberHeader4SMS = [
  { label: "Property", key: "annotation" },
  { label: "#Blast", key: "count" },
  ...eblastCSVNumberHeaders4SMS.slice(2, 6),
];

export const blastsCSVHeader = [
  { label: "Blast", key: "annotation" },
  { label: "Subject", key: "subject" },
  ...eblastCSVHeaders.slice(2),
  { label: "Sent To", key: "audience_counts_percentage" },
  { label: "Sent At", key: "sent_at_percentage" },
];
export const blastsCSVHeader4SMS = [
  { label: "Blast", key: "annotation" },
  { label: "Subject", key: "subject" },
  ...eblastCSVHeaders4SMS.slice(2, 6),
  { label: "Sent To", key: "audience_counts_percentage" },
  { label: "Sent At", key: "sent_at_percentage" },
];
export const blastsCSVNumberHeader = [
  { label: "Blast", key: "annotation" },
  { label: "Subject", key: "subject" },
  ...eblastCSVNumberHeaders.slice(2),
  { label: "Sent To", key: "audience_counts_percentage" },
  { label: "Sent At", key: "sent_at_percentage" },
];
export const blastsCSVNumberHeader4SMS = [
  { label: "Blast", key: "annotation" },
  { label: "Subject", key: "subject" },
  ...eblastCSVNumberHeaders4SMS.slice(2, 6),
  { label: "Sent To", key: "audience_counts_percentage" },
  { label: "Sent At", key: "sent_at_percentage" },
];

export const contactsCSVHeader = (type, sms, orgLevel) => {
  let label = sms
    ? contacts4SMS.find((e) => e.id == type || "sms_sends_contacts")?.value
    : contacts.find((e) => e.id == type || "mails_sent_contacts")?.value;

  const fields = orgLevel ? [{ label: "Property", key: "propertyName" }] : [];
  return [
    ...fields,
    { label: "Blast", key: "campaignName" },
    { label: "Sent To", key: sms ? "to_phone" : "entryEmail" },
    { label: `${label} At`, key: "createdAt" },
    { label: sms ? "SMS Title" : "Sub-Status", key: sms ? "body" : "subStatus" },
  ];
};

export const reportsCSVHeader = () => {
  return [
    { label: "Sub-org Name", key: "subOrgName" },
    { label: "# Scheduler Page Visits", key: "scheduledPageVisitNo" },
    { label: "# Scheduled", key: "scheduledNo" },
    { label: "% Scheduled", key: "scheduledPercent" },
    { label: "# Toured", key: "touredNo" },
    { label: "% Toured", key: "touredPercent" },
    { label: "# Cancelled", key: "cancelledNo" },
    { label: "% Cancelled", key: "cancelledPercent" },
    { label: "# Rescheduled", key: "rescheduledNo" },
    { label: "% Rescheduled", key: "rescheduledPercent" },
  ];
};

export const navigationWithView = {
  variant: "blast-a-b",
  analytics: "blasts-analytics",
  eblast: "by-blasts",
};

export const steps = {
  0: "property-audit",
  1: "by-property",
  2: "by-blasts",
  3: "blasts-analytics",
  4: "blast-contact-list",
  // 5: "blast-a-b",
  5: "by-email-platform",
  7: "blast-a-b-variant",
  8: "blasts-analytics",
  9: "by-blast",
};

export const previewPath = {
  "Property Audit": 0,
  "By Property": 1,
  "By Blast": 9,
  "Blast Analytics": 8,
  "Blast Contact List": 4,
  "Contact List": 4,
  "Blast A/B": 7,
  "By Email Platform": 5,
};

// functions for property audit reports

export const getPropertyAuditCSVData = (d) =>
  d.map((item) => {
    return {
      property: item.annotation,
      eblastsCount: item.count,
      daysSince: item.days_since_last_send || "--",
      lastSentDate: !!item.last_sent_at
        ? moment(item.last_sent_at).format("MMM DD,YYYY h:mmA")
        : "Never Sent",
      lastSentEblast: item.last_sent_eblast || "--",
      sendsNumber: numberWithCommas(item.sends_count),
      opensPercentage: item.unique_opens_count_percentage,
      clicksPercentage: item.unique_clicks_count_percentage,
      opens: item.unique_opens_count,
      clicks: item.unique_clicks_count,
    };
  });

// functions for reports by Blast

const campaignsTenantsDataIdsForOverview = [
  {
    key: "sendsCount",
    secondaryKey: "sentAt",
    label: "#Sent",
    contactId: "mails_sent_contacts",
  },
  {
    key: "%",
    secondaryKey: "uniqueOpensCount",
    label: "Unique Opens",
    contactId: "mails_opened_contacts",
  },
  {
    key: "%",
    secondaryKey: "uniqueClicksCount",
    label: "Unique Clicks",
    contactId: "mails_clicks_contacts",
  },
  {
    key: "%",
    secondaryKey: "unsubscribesCount",
    label: "Unsubscribes",
    contactId: "mails_unsubscribed_contacts",
  },
  {
    key: "%",
    secondaryKey: "blacklistedCount",
    label: "Blacklisted",
  },
  {
    key: "%",
    secondaryKey: "complaintsCount",
    label: "Complaints",
    contactId: "mails_complained_contacts",
  },
  {
    key: "%",
    secondaryKey: "bouncesCount",
    label: "Bounces",
    contactId: "mails_bounced_contacts",
  },
];

const campaignsTenantsDataIdsForOverview4SMS = [
  {
    key: "smsSendsCount",
    secondaryKey: "sent_at",
    label: "#Sent",
    contactId: "sms_sends_contacts",
  },
  {
    key: "%",
    secondaryKey: "smsClicksCount",
    label: "Click",
    contactId: "sms_clicks_contacts",
  },
  {
    key: "%",
    secondaryKey: "smsUndeliveredCount",
    label: "Undelivered",
    contactId: "sms_undelivered_contacts",
  },
  {
    key: "%",
    secondaryKey: "smsUnsubscribedCount",
    label: "Unsubscribes",
    contactId: "sms_unsubscribed_contacts",
  },
];

const getOriginalValue = (a, b) => {
  return ((b * a) / 100).toFixed(0);
};

const getPercentage = (a, b) => {
  if (a == 0) {
    return "0%";
  }
  return ((b / a) * 100).toFixed(0) + "%";
};

export const parseTenantsCampaignsDataForOverview = (response, sms) => {
  // This is used in Drips
  let campaignsTenantsData = [];
  let campaignsTenantsDataIds = sms
    ? campaignsTenantsDataIdsForOverview4SMS
    : campaignsTenantsDataIdsForOverview;
  for (let i = 0; i < campaignsTenantsDataIds.length; i++) {
    let secondaryValue = response[campaignsTenantsDataIds[i].secondaryKey];
    let value =
      campaignsTenantsDataIds[i].key == "%"
        ? getPercentage(sms ? response.smsSendsCount : response.sendsCount, secondaryValue)
        : numberWithCommas(response[campaignsTenantsDataIds[i].key]);
    let parseObject = {
      label: campaignsTenantsDataIds[i].label,
      value: value,
      secondaryValue: numberWithCommas(secondaryValue),
      contactId: campaignsTenantsDataIds[i].contactId,
    };
    if (campaignsTenantsDataIds[i].key.includes("sendsCount")) {
      let audience_counts = JSON.parse(response.audienceCounts.replaceAll("=>", ":"));
      if (audience_counts.length > 0) {
        parseObject.thirdValue = audience_counts[0].count + " " + audience_counts[0].name;
      }
    }
    campaignsTenantsData.push(parseObject);
  }
  return campaignsTenantsData;
};

const campaignsTenantsCampaignsIdsForOverview = [
  {
    key: "sends_count",
    secondaryKey: "sent_at",
    label: "#Sends",
    contactId: "mails_sent_contacts",
  },
  {
    key: "%",
    secondaryKey: "unique_opens_count",
    label: "Unique Opens",
    contactId: "mails_opened_contacts",
  },
  {
    key: "%",
    secondaryKey: "unique_clicks_count",
    label: "Unique Clicks",
    contactId: "mails_clicks_contacts",
  },
  {
    key: "%",
    secondaryKey: "unsubscribes_count",
    label: "Unsubscribes",
    contactId: "mails_unsubscribed_contacts",
  },
  {
    key: "%",
    secondaryKey: "bounces_count",
    label: "Bounces",
    contactId: "mails_bounced_contacts",
  },
  {
    key: "%",
    secondaryKey: "complaints_count",
    label: "Complaints",
    contactId: "mails_complained_contacts",
  },
  {
    key: "%",
    secondaryKey: "blacklisted_count",
    label: "Blacklists",
  },
];
const campaignsTenantsCampaignsIdsForOverview4Sms = [
  {
    key: "sms_sends_count",
    secondaryKey: "sent_at",
    label: "#Sent",
    contactId: "sms_sends_contacts",
  },
  {
    key: "%",
    secondaryKey: "sms_clicks_count",
    label: "Click",
    contactId: "sms_clicks_contacts",
  },
  {
    key: "%",
    secondaryKey: "sms_undelivered_count",
    label: "Undelivered",
    contactId: "sms_undelivered_contacts",
  },
  {
    key: "%",
    secondaryKey: "sms_unsubscribed_count",
    label: "Unsubscribes",
    contactId: "sms_unsubscribed_contacts",
  },
];

export const parseCampaignStat = (response, sms, cData) => {
  //parse campaign stats data for AB and Eblast analytics
  let campaignsTenantsData = [];
  let chartData = parseChartStat(response, cData);
  let campaignsTenantsIds = sms
    ? campaignsTenantsCampaignsIdsForOverview4Sms
    : campaignsTenantsCampaignsIdsForOverview;
  for (let i = 0; i < campaignsTenantsIds.length; i++) {
    let key = campaignsTenantsIds[i].secondaryKey;
    let secondaryValue = response.hasOwnProperty(key) ? response[key] : "";
    let value =
      campaignsTenantsIds[i].key == "%"
        ? getPercentage(sms ? response.sms_sends_count : response.sends_count, secondaryValue) //ntc
        : numberWithCommas(response[campaignsTenantsIds[i].key]);
    let parseObject = {
      label: campaignsTenantsIds[i].label,
      value: value,
      secondaryValue: key == "sent_at" ? secondaryValue : numberWithCommas(secondaryValue),
      contactId: secondaryValue > 0 && campaignsTenantsIds[i].contactId,
      subject: response.subject,
    };
    if (campaignsTenantsIds[i].key.includes("sends_count")) {
      let audience = [];
      let thirdValue;
      let audience_counts = response.audience_counts ?? [];
      // if (audience_counts.length > 0) {
      //   audience_counts.map((e) => {
      //     audience.push(e.count + " " + e.name);
      //   });
      // }
      // if (audience.length > 0) {
      //   thirdValue = audience.join();
      // }
      parseObject.thirdValue = audience_counts;
    }
    campaignsTenantsData.push(parseObject);
  }
  return { summaryData: campaignsTenantsData, chartData };
};

export const panelsEblast = [
  {
    id: "Overview",
    value: "Blast Overview",
  },
  {
    id: "DrillDown",
    value: "DrillDown Blast",
  },
  {
    id: "Breakdown",
    value: "Breakdown Blast",
  },
];

export const panels = [
  {
    id: "Overview",
    value: "Blast Overview",
  },
  {
    id: "Breakdown",
    value: "Breakdown Blast",
  },
  {
    id: "Link Clicks",
    value: "Link Clicks",
  },
];

export const contacts = [
  {
    id: "mails_sent_contacts",
    value: "Sent",
  },
  {
    id: "mails_opened_contacts",
    value: "Opened",
  },
  {
    id: "mails_clicks_contacts",
    value: "Clicked",
  },
  {
    id: "mails_unsubscribed_contacts",
    value: "Unsubscribed",
  },
  {
    id: "mails_bounced_contacts",
    value: "Bounced",
  },
  {
    id: "mails_complained_contacts",
    value: "Complained",
  },
  {
    id: "blacklisted_count",
    value: "Blacklisted",
  },
];
export const contacts4SMS = [
  {
    id: "sms_sends_contacts",
    value: "Send At",
  },
  {
    id: "sms_undelivered_contacts",
    value: "Undelivered At",
  },
  {
    id: "sms_clicks_contacts",
    value: "Clicked At",
  },
  {
    id: "sms_unsubscribed_contacts",
    value: "Unsubscribed At",
  },
];

export const contactTitle = {
  mails_sent_contacts: "Sent",
  mails_opened_contacts: "Opened",
  mails_clicks_contacts: "Clicked",
  mails_unsubscribed_contact: "Unsubscribed",
  mails_bounced_contacts: "Bounced",
  mails_complained_contacts: "Complained",
  sms_sends_contacts: "Sent",
  sms_undelivered_contacts: "Undelivered",
  sms_clicks_contacts: "Clicked",
  sms_unsubscribed_contacts: "Unsubscribed",
  blacklisted_count: "Blacklisted",
};

export const parsedContactData = (data) => {
  let response = [];
  for (let i = 0; i < data.length; i++) {
    if (data[i].sendList.length > 0) {
      let sendList = JSON.parse(data[i].sendList);
      response.push({
        ...data[i],
        to_phone: sendList[0]?.to_phone,
        body: sendList[0]?.body,
      });
    } else {
      response.push(data[i]);
    }
  }
  return response;
};

const propertyAuditIds = [
  {
    key: "uniqueOpensRate",
    label: "Opens",
    contactId: "sms_sends_contacts",
  },
  {
    key: "uniqueClicksRate",
    label: "Clicks",
    contactId: "sms_undelivered_contacts",
  },
];

const campaignsTenantsTenantIds4SMS = [
  {
    key: "sms_sends_count",
    label: "Sent",
    contactId: "sms_sends_contacts",
  },
  {
    key: "sms_undelivered_count",
    label: "Undelivered",
    contactId: "sms_undelivered_contacts",
  },
  {
    key: "sms_clicks_count",
    label: "Clicked",
    contactId: "sms_clicks_contacts",
  },
  {
    key: "sms_unsubscribed_count",
    label: "Unsubscribes",
    contactId: "sms_unsubscribed_contacts",
  },
];

const campaignsTenantsTenantIds = [
  {
    key: "sends_count",
    label: "#Unique Sends",
    contactId: "mails_sent_contacts",
  },
  {
    key: "unique_opens_count",
    label: "Unique Opens",
    contactId: "mails_opened_contacts",
  },
  {
    key: "unique_clicks_count",
    label: "Unique Clicks",
    contactId: "mails_clicks_contacts",
  },
  {
    key: "unsubscribes_count",
    label: "Unsubscribes",
    contactId: "mails_unsubscribed_contacts",
  },
  {
    key: "bounces_count",
    label: "Bounces",
    contactId: "mails_bounced_contacts",
  },
];

const CHART_DATA = [
  {
    name: "sends_count",
    label: "#Unique Sends",
    color: "#FFE8C6",
  },
  {
    name: "unique_opens_count",
    label: "Unique Opens",
    color: "#B5E960",
  },
  {
    name: "unique_clicks_count",
    label: "Unique Clicks",
    color: "#60E97E",
  },
];

export const parseChartStat = (response, cdata) => {
  const filteredData = [
    {
      name: "mails.total.sends",
      label: "#Unique Sends",
      color: "#FFE8C6",
      value: response.sends_count,
      data: cdata.filter((item) => item.id === "mails.total.sends"),
      link: true,
      contactId: "mails_sent_contacts",
    },
    {
      name: "mails.total.opens",
      label: "Unique Opens",
      color: "#B5E960",
      value: response.unique_opens_count,
      total: response.sends_count,
      data: cdata.filter((item) => item.id === "mails.total.opens"),
      link: true,
      contactId: "mails_opened_contacts",
    },
    {
      name: "mails.total.clicks",
      label: "Unique Clicks",
      color: "#60E97E",
      value: response.unique_clicks_count,
      total: response.sends_count,
      data: cdata.filter((item) => item.id === "mails.total.clicks"),
      link: true,
      contactId: "mails_clicks_contacts",
    },
  ];

  return filteredData;
};

export const parseAuditStat = (response) => {
  let propertyAuditStat = [];

  for (let i = 0; i < response.length; i++) {
    let data = {};
    for (let j = 0; j < propertyAuditIds.length; j++) {
      let key = propertyAuditIds[j].key;
      let value = response[i][key];

      if (!key.includes("sends_count")) {
        value = getOriginalValue(response[i].sendsCount, value);
      } else {
        value = numberWithCommas(value);
      }

      data[key + "_percentage"] = value;
      data[key] = response[i][key];
    }

    propertyAuditStat.push({
      ...response[i],
      label: response[i].propertyName,
      ...data,
    });
  }

  return propertyAuditStat;
};

export const parseTenantStat = (response, sms, cdata) => {
  // This method parse tenant state for By Property
  // and By Blast View view
  let campaignsTenantsTenant = [];
  let data = {};
  let chartData = parseChartStat(response, cdata);
  let campaignsTenantsIds = sms ? campaignsTenantsTenantIds4SMS : campaignsTenantsTenantIds;
  for (let i = 0; i < campaignsTenantsIds.length; i++) {
    let key = campaignsTenantsIds[i].key;
    let value = response[key];
    if (!key.includes("sends_count")) {
      value = getPercentage(sms ? response.sms_sends_count : response.sends_count, value);
    } else {
      value = numberWithCommas(value);
    }
    data[key + "_percentage"] = value;
    data[key] = response[key];
    campaignsTenantsTenant.push({
      label: campaignsTenantsIds[i].label,
      percentage: value,
      value: key.includes("sends_count") ? "" : numberWithCommas(response[key]),
      contactId: campaignsTenantsIds[i].contactId,
    });
  }
  data.annotation = "Total/Average";
  data.count = response.eblast_count;
  return {
    thumbnailUrls: response.thumbnail_url
      ? response.thumbnail_url.filter((item) => item !== null).slice(0, 3)
      : [],
    count: `${response.eblast_count} Blasts`,
    propertyName: response.annotation,
    summaryData: campaignsTenantsTenant,
    chartData,
    data,
  };
};

const campaignsTenantsTenantIdsCampaigns = [
  {
    key: "annotation",
    label: "Blast",
    contactId: "campaign",
  },
  //noc
  ...campaignsTenantsTenantIds,
  {
    key: "audience_counts",
    label: "Sent To",
  },
  {
    key: "sent_at",
    label: "Sent At",
  },
];
const campaignsTenantsTenantIdsCampaignsSms = [
  {
    key: "annotation",
    label: "Blast",
    contactId: "campaign",
  },
  {
    key: "subject",
    label: "SMS Title",
    contactId: "campaign",
  },
  //noc
  ...campaignsTenantsTenantIds4SMS,
  {
    key: "audience_counts",
    label: "Sent To",
  },
  {
    key: "sent_at",
    label: "Sent At",
  },
];

const campaignsTenantsGalleryCampaignsProperty = [
  {
    key: "annotation",
    label: "Property",
    contactId: "subOrg",
  },
  {
    key: "sends_count",
    label: "Unique Sends",
    contactId: "mails_sent_contacts",
  },
  {
    key: "unique_opens_count",
    label: "Unique Opens",
    contactId: "mails_opened_contacts",
  },
  {
    key: "unique_clicks_count",
    label: "Unique Clicks",
    contactId: "mails_clicks_contacts",
  },
  {
    key: "unsubscribes_count",
    label: "Unsubscribes",
    contactId: "mails_unsubscribed_contacts",
  },
  {
    key: "bounces_count",
    label: "Bounces",
    contactId: "mails_bounced_contacts",
  },
];

const campaignsTenantsTenantIdsCampaignsProperty = [
  {
    key: "annotation",
    label: "Property",
    contactId: "subOrg",
  },
  {
    key: "days_since_last_send",
    label: "Days Since",
    contactId: "subOrg",
  },
  {
    key: "last_sent_eblast",
    label: "Last Sent Blast",
    contactId: "subOrg",
  },
  {
    key: "last_sent_at",
    label: "Last Sent Date",
    contactId: "subOrg",
  },
  {
    key: "last_sent_eblast_id",
    label: "Last Sent EblastId",
    contactId: "subOrg",
  },
  {
    key: "campaign_created_at",
    label: "Campaign Created At",
    contactId: "subOrg",
  },
  //noc
  ...campaignsTenantsTenantIds,
];
const campaignsTenantsTenantIdsCampaignsPropertySms = [
  {
    key: "annotation",
    label: "Property Name",
    contactId: "subOrg",
  },
  //noc
  ...campaignsTenantsTenantIds4SMS,
];

export const parseDrillItemData = (response, type, sms, drilldown) => {
  //parse campaign
  let labels = drilldown
    ? campaignsTenantsGalleryCampaignsProperty
    : sms
    ? type == "by-property"
      ? campaignsTenantsTenantIdsCampaignsPropertySms
      : campaignsTenantsTenantIdsCampaignsSms
    : type == "by-property"
    ? campaignsTenantsTenantIdsCampaignsProperty
    : campaignsTenantsTenantIdsCampaigns;
  let campaignsTenantsTenant = [];
  let data = {};
  for (let i = 0; i < labels.length; i++) {
    let key = labels[i].key;
    let value = response[key];
    let tooltip = "";
    if (key == "annotation" || key.includes("sends_count")) {
      if (key.includes("sends_count")) {
        tooltip = labels[i].label + " " + numberWithCommas(value);
        value = numberWithCommas(value);
      } else {
        tooltip = value;
      }
    } else if (key == "sent_at" || key == "campaign_created_at") {
      value = value ? moment(value.split(",")[0]).format("MMM D, YYYY - h:mma") : "--";
      tooltip = labels[i].label + " " + value;
    } else if (key == "audience_counts") {
      let audience = [];
      // if (value.length > 0) {
      //   value.map((e) => {
      //     audience.push(e.name + " (" + e.count + ")");
      //     // audience.push(e.count + " " + e.name);
      //   });
      // }
      let totalCount = 0; // Initialize the variable to store the total count

      if (value.length > 0) {
        value.forEach((e) => {
          audience.push({ name: e.name, receivers: e.receivers });
          totalCount += e.receivers; // Add e.count to totalCount
        });
      }
      if (audience.length > 0) {
        value = audience[0].name + " (" + totalCount + ")";
      }
      if (audience.length > 1) {
        let remainingAudienceCount = audience.length - 1;
        value = audience[0].name + ` + ${remainingAudienceCount}` + " (" + totalCount + ")";
      }
      // tooltip = labels[i].label + " " + audience.join();
      tooltip = audience.map((e) => <div>{e.name + " (" + e.receivers + ")"}</div>);
    } else if (key == "subject" || key == "encoded_campaign_ids") {
      tooltip = value;
    } else {
      //noc
      value = getPercentage(sms ? response.sms_sends_count : response.sends_count, value);
      tooltip = labels[i].label + " " + numberWithCommas(response[key]);
    }
    data[key + "_percentage"] = value;
    // if (key == "sends_count") {
    //   data[key] = numberWithCommas(response[key]);
    // } else {
    data[key] = response[key];
    // }

    let object = {
      label: labels[i].label,
      percentage: value.toString(),
      contactId: labels[i].contactId,
      tooltip,
    };
    if (type == "by-property") {
      object.propertyId = response.property_id;
      data.propertyId = response.property_id;
    } else {
      object.campaignId = response.campaign_id;
      data.campaignId = response.campaign_id;
    }
    campaignsTenantsTenant.push(object);
  }
  data.count = response.eblast_count; //numberWithCommas(response.eblast_count);
  data.subject = response.subject;
  const thumbnailUrls = response.thumbnail_url
    ? Array.isArray(response.thumbnail_url)
      ? response.thumbnail_url
      : [response.thumbnail_url]
    : null;
  return {
    thumbnailUrls: thumbnailUrls,
    summaryData: campaignsTenantsTenant,
    count: `${numberWithCommas(response.eblast_count)} Blasts`,
    data,
    campaignIds: response.encoded_campaign_ids,
  };
};

export const parseDataForDrillDown = (data, campaignsIds, type, sms, drilldown) => {
  // This parse data for DrillDown view
  let blastData = [];
  for (let i = 0; i < campaignsIds.length; i++) {
    let obj = data[campaignsIds[i]];
    blastData.push(parseDrillItemData(obj, type, sms, drilldown));
  }
  return blastData;
};

export const colors = {
  "Unique Sends": "#FFA64C",
  "Unique Opens": "#79B6FD",
};
export const colors4SMS = {
  "Sms Sends": "#FFA64C",
  "SMS Undelivered": "#79B6FD",
};

export const keys = ["Unique Sends", "Unique Opens"];
export const keys4SMS = ["Sms Sends", "SMS Undelivered"];

export const parseChartData = (chartData) => {
  let dataForChart = [];
  for (let i = 0; i < chartData[0].data.length; i++) {
    let date = moment(chartData[0].data[i][0]).format("MMM DD");
    let dataPoint = {
      date: date,
    };
    chartData.forEach((metric) => {
      dataPoint[metric.name] = metric.data[i][1];
    });
    dataForChart.push(dataPoint);
  }
  return dataForChart;
};

export const parseDrilldownChartData = (chartData) => {
  let dataForChart = [];
  dataForChart = chartData.map((item) => ({
    id: item.id,
    name: item.name,
    total: item.total,
    data: item.data.map((idx) => ({
      x: moment.unix(idx[0].toString().slice(0, -3)).format("YYYY-MM-DD HH:mm"),
      y: idx[1],
    })),
  }));

  return dataForChart;
};

export const BY_EMAIL_PLATFORM_PANELS = [
  { id: "by-email-client", value: "Open By Mail Client" },
  { id: "by-os", value: "Open By OS" },
  { id: "by-device", value: "Open By Device" },
];

const getSum = (a, b) => {
  let x = a ? Number(a.slice(0, -1)) : 0;
  let y = b ? Number(b.slice(0, -1)) : 0;
  return x + y;
};

export const calculateTotal = (data) => {
  let total = {
    annotation: "Total/Average",
    type: "Total/Average",
    subject: "Total/Average",
    link_label: "Total/Average",
    count: 0,
    sends_count: 0,
    bounces_count: 0,
    unsubscribes_count: 0,
    unique_opens_count: 0,
    unique_clicks_count: 0,
    bounces_count_percentage: "0%",
    unsubscribes_count_percentage: "0%",
    unique_opens_count_percentage: "0%",
    unique_clicks_count_percentage: "0%",
  };
  for (let i = 0; i < data.length; i++) {
    total.count = total.count + Number(data?.[i]?.count || "0");
    total.sends_count = Number(total.sends_count + data[i].sends_count);
    total.bounces_count = Number(total.bounces_count + data[i].bounces_count);
    total.unsubscribes_count = Number(total.unsubscribes_count + data[i].unsubscribes_count);
    total.unique_opens_count = Number(total.unique_opens_count + data[i].unique_opens_count);
    total.unique_clicks_count = Number(total.unique_clicks_count + data[i].unique_clicks_count);
  }
  if (total.sends_count > 0) {
    total.bounces_count_percentage = getPercentage(total.sends_count, total.bounces_count);
    total.unsubscribes_count_percentage = getPercentage(
      total.sends_count,
      total.unsubscribes_count
    );
    total.unique_opens_count_percentage = getPercentage(
      total.sends_count,
      total.unique_opens_count
    );
    total.unique_clicks_count_percentage = getPercentage(
      total.sends_count,
      total.unique_clicks_count
    );
  }
  if (total.count) {
    total.count = numberWithCommas(total.count);
  }
  return total;
};

export const calculateTotal4SMS = (data) => {
  let total = {
    annotation: "Total/Average",
    type: "Total/Average",
    subject: "Total/Average",
    count: 0,
    sms_sends_count: 0,
    sms_unsubscribed_count: 0,
    sms_undelivered_count: 0,
    sms_clicks_count: 0,
    sms_unsubscribed_count_percentage: "0.00%",
    sms_undelivered_count_percentage: "0.00%",
    sms_clicks_count_percentage: "0.00%",
  };
  for (let i = 0; i < data.length; i++) {
    total.count = total.count + data[i].count;
    total.sms_sends_count = Number(total.sms_sends_count + data[i].sms_sends_count);
    total.sms_unsubscribed_count = Number(
      total.sms_unsubscribed_count + data[i].sms_unsubscribed_count
    );
    total.sms_undelivered_count = Number(
      total.sms_undelivered_count + data[i].sms_undelivered_count
    );
    total.sms_clicks_count = Number(total.sms_clicks_count + data[i].sms_clicks_count);
  }
  if (total.sms_sends_count > 0) {
    total.sms_unsubscribed_count_percentage = getPercentage(
      total.sms_sends_count,
      total.sms_unsubscribed_count
    );
    total.sms_undelivered_count_percentage = getPercentage(
      total.sms_sends_count,
      total.sms_undelivered_count
    );
    total.sms_clicks_count_percentage = getPercentage(
      total.sms_sends_count,
      total.sms_clicks_count
    );
  }
  return total;
};

export const getPercentageCountsOfVariantData = (data) => {
  return data.map((item) => {
    return {
      ...item,
      link_label: item.link_label,
      link_type: item.link_type,
      name: item.name,
      count: numberWithCommas(item.count),
    };
  });
};
export const getPercentageCountsOfVariantData4SMS = (data) => {
  return data.map((item) => {
    return {
      ...item,
      sms_sends_count: numberWithCommas(item.sms_sends_count),
      sms_clicks_count: numberWithCommas(item.sms_clicks_count),
      sms_clicks_count_percentage: getPercentage(item.sms_sends_count, item.sms_clicks_count),
      sms_undelivered_count_percentage: getPercentage(
        item.sms_sends_count,
        item.sms_undelivered_count
      ),
      sms_unsubscribed_count_percentage: getPercentage(
        item.sms_sends_count,
        item.sms_unsubscribed_count
      ),
      sms_send_at: moment(item.sms_sent_at).format("DD/MM/YYYY hh:mm A"),
    };
  });
};

export const linksHeaders = {
  0: {
    headerTitle: "Link Clicks",
    fileName: "Link Clicks.csv",
  },
  1: {
    headerTitle: "Link Clicks for Variant B",
    fileName: "Link Clicks for Variant B.csv",
  },
};

export const getLinksTableData = (variants) => {
  let data = variants.map((e) => {
    return {
      links: e.link_clicks,
      thumbnail_url: e.thumbnail_url,
    };
  });
  let linksTableData = [];
  for (let i = 0; i < data.length; i++) {
    if (data[i].links.length > 0) {
      let linksList = data[i].links.map((e) => {
        return {
          link: e.name,
          clicks: e.count,
        };
      });
      linksTableData.push({
        ...linksHeaders[i],
        rightSection: <img src={data[i].thumbnail_url} />,
        data: linksList,
      });
    }
  }
  return linksTableData;
};

export const getMonthLabel = (monthRange) => {
  let _label = monthRange.label;
  if (monthRange.label === "Custom Range") {
    let start = moment(monthRange.startAt).format("D MMM YYYY");
    let end = moment(monthRange.endAt).format("D MMM YYYY");
    _label = start + " - " + end;
    if (start === end) {
      _label = start;
    }
  }
  return _label;
};

export const getMonthDates = (monthRange) => {
  let start = moment(monthRange.startAt).format("D-MMM-YYYY");
  let end = moment(monthRange.endAt).format("D-MMM-YYYY");
  return start + "_" + end;
};

export const getValues = (ids, idsValues) => {
  let values = [];
  for (let i = 0; i < ids.length; i++) {
    let idValue = idsValues.find((e) => e.id == ids[i]);
    if (idValue) {
      values.push(idValue.value);
    }
  }
  return values;
};

export const parseBrowserSeriesData = (data, skipPercentageSign) => {
  return JSON.parse(data.replaceAll("=>", ":")).map((item) => ({
    label: item.name,
    metric: item.count,
    metric_percentage: item.data[0] + `${skipPercentageSign ? "" : "%"}`,
  }));
};

export const parseTime = (monthRange) => {
  let startAt = moment(monthRange.startAt).format("ddd, DD MMM YYYY");
  let endAt = moment(monthRange.endAt).format("ddd, DD MMM YYYY");
  // let startAtDateTime = moment(monthRange.startAt).format("ddd, DD MMM YYYY HH:mm:ss");
  // let endAtDateTime = moment(monthRange.endAt).format("ddd, DD MMM YYYY HH:mm:ss");
  return {
    startAt,
    endAt,
    startAtDateTime: startAt,
    endAtDateTime: endAt,
  };
};

const parseKeysOfCampaigns = (item) => {
  return {
    propertyId: item.propertyId,
    createdAt: item.createdAt,
    value: item.name,
    id: item.id,
  };
};

export const parseCampaigns = (data) => {
  return data.map(parseKeysOfCampaigns);
};

export { compare };

const FeedbackData = [
  {
    title: "A off first months rent",
    offerType: "$1000 off first month's rent",
    startDate: "January 21, 2022",
    endDate: "January 1, 2023",
  },
  {
    title: "R off first months rent",
    offerType: "$4000 off first month's rent",
    startDate: "March 21, 2022",
    endDate: "March 1, 2023",
  },
  {
    title: "N off first months rent",
    offerType: "$2000 off first month's rent",
    startDate: "June 21, 2022",
    endDate: "June 1, 2023",
  },
  {
    title: "L off first months rent",
    offerType: "$3000 off first month's rent",
    startDate: "April 21, 2022",
    endDate: "April 1, 2023",
  },
  {
    title: "B off first months rent",
    offerType: "$5000 off first month's rent",
    startDate: "December 21, 2022",
    endDate: "December 1, 2023",
  },
];

export const getFeedbackColumns = (classes) => {
  return [
    {
      field: "title",
      fieldName: "E-blast Title",
      sortable: true,
      className: classes.tableHeader,
      cellClassName: classes.tableCellInvitee,
      filterable: false,
    },
    {
      field: "offerType",
      fieldName: "Offer Type",
      sortable: true,
      className: classes.tableHeader,
      cellClassName: classes.tableCellInvitee,
      filterable: false,
    },
    {
      field: "startDate",
      fieldName: "Start Date",
      sortable: true,
      className: classes.tableHeader,
      cellClassName: classes.tableCellInvitee,
      filterable: false,
    },
    {
      field: "endDate",
      fieldName: "End Date",
      sortable: true,
      className: classes.tableHeader,
      cellClassName: classes.tableCellInvitee,
      filterable: false,
    },
    {
      field: "viewReport",
      sortable: false,
      className: classes.tableHeader,
      cellClassName: classes.tableCellInvitee,
      filterable: false,
    },
  ];
};

export const getFeedbackRows = (classes, data, history, purpose) => {
  return data.map((item) => {
    return {
      title: (
        <Typography
          className={`${classes.tableText} ${classes.capitalizeText}`}
          style={{ fontWeight: "600" }}
        >
          {item.title.value || "N/A"}
        </Typography>
      ),
      offerType: (
        <Typography className={`${classes.tableText} ${classes.capitalizeText}`}>
          {item.uxFields.ux_offer_type || "N/A"}
        </Typography>
      ),
      startDate: (
        <Typography className={`${classes.tableText} ${classes.capitalizeText}`}>
          {purpose === "Events" && item.uxFields.ux_event_date
            ? moment(item.uxFields.ux_event_date).format("MMMM DD, YYYY")
            : moment(item.uxFields.ux_start_date).format("MMMM DD, YYYY")}
        </Typography>
      ),
      endDate: (
        <Typography className={`${classes.tableText} ${classes.capitalizeText}`}>
          {purpose === "Events" && item.uxFields.ux_event_date
            ? moment(item.uxFields.ux_event_date).format("MMMM DD, YYYY")
            : moment(item.uxFields.ux_end_date).format("MMMM DD, YYYY")}
        </Typography>
      ),
      viewReport: (
        <Button
          variant="secondary"
          size="sm"
          color="primary"
          className={classes.btn}
          onClick={() =>
            history.push({
              pathname: path.getAppPath() + "/blast/reports/feedback-reports/view",
              state: { id: item.id, title: item.title.value },
            })
          }
        >
          View Report
        </Button>
      ),
    };
  });
};

export const CARDSCONTENT = (data) => {
  const CardsData = data
    ? [
        {
          header: "#GPT Blast Sent",
          count: data.gpt_blasts_sent,
        },
        {
          header: "#Fields Generated",
          count: data.fields_generated,
        },
        {
          header: "#Fields Liked",
          count: data.fields_liked,
        },
        {
          header: "#Fields Disliked",
          count: data.fields_disliked,
        },
        {
          header: "#Human Replacements",
          count: data.human_replacements,
        },
      ]
    : [];
  return CardsData;
};
export const CARDSCONTENTBYBLAST = (data) => {
  const CardsData = [
    {
      header: "Most used blast type",
      count: data.most_used_blast_type,
      subheading: `${data.most_blasts_sent_count} Blasts sent`,
    },
    {
      header: "Most liked blast type",
      count: data.most_liked_blast_type,
      subheading: `${data.most_blasts_liked_count} Likes`,
    },
    {
      header: "Most disliked blast type",
      count: data.most_disliked_blast_type,
      subheading: `${data.most_blasts_disliked_count} Dislikes`,
    },
    {
      header: "Most regenerated blast type",
      count: data.most_regerated_blast_type,
      subheading: `${data.most_blasts_regenerated_count} Regenerations`,
    },
    {
      header: "Most human replaced blast type",
      count: data.most_human_replaced_blast_type,
      subheading: `${data.most_blasts_human_replaced_count} Human Replacements`,
    },
  ];
  return CardsData;
};

function handleRegenerateFieldsCounts(type, field) {
  if (type === "regenerate") {
    let regenerate = 0;
    field.map((item) => {
      if (item.regenerated) {
        regenerate = regenerate + 1;
      }
    });
    return regenerate;
  }
  if (type === "like") {
    let like = 0;
    field.map((item) => {
      if (item.feedback === true) {
        like = like + 1;
      }
    });
    return like;
  }
  if (type === "dislike") {
    let dislike = 0;
    field.map((item) => {
      if (item.feedback === false) {
        dislike = dislike + 1;
      }
    });
    return dislike;
  }
  if (type === "human_replaceds") {
    let humanReplaced = 0;
    field.map((item) => {
      if (item.humanReplaced) {
        humanReplaced = humanReplaced + 1;
      }
    });
    return humanReplaced;
  }
}

function handleGenerateFields(heading, field) {
  let item = {
    heading: heading,
    subheading: heading === "Body" ? "" : field[0].value,
    like: 0,
    dislike: 0,
    regenerated: 0,
    tableContent: [],
  };
  item.tableContent = field.map((val) => {
    item.like = val?.feedback === true ? item.like + 1 : item.like + 0;
    item.dislike = val?.feedback === false ? item.dislike + 1 : item.dislike + 0;
    item.regenerated = val?.regenerated === true ? item.regenerated + 1 : item.regenerated + 0;
    return {
      Result: val.value,
      Response: val.feedback === true ? "Yes" : val.feedback === false ? "No" : "",
      Regenerated: val.regenerated === true ? "Yes" : "No",
      Human_Replaced: val?.humanReplaced || "",
      active: val.active,
    };
  });
  return item;
}

export const TABLESCONTENT = (data) => {
  return [
    {
      org: "Appartment",
      gpt_blasts_sent: 2,
      fields_generated: 177,
      fields_liked: 3,
      fields_disliked: 1,
      human_replacements: 6,
    },
    {
      org: "Bozzuto",
      gpt_blasts_sent: 65,
      fields_generated: 43,
      fields_liked: 1,
      fields_disliked: 15,
      human_replacements: 9,
    },
    {
      org: "Grand Peaks",
      gpt_blasts_sent: 45,
      fields_generated: "0",
      fields_liked: 199,
      fields_disliked: 100,
      human_replacements: 87,
    },
  ];
};

export const getDetailsColumnsForOrgs = (classes, handleSearch) => {
  return [
    {
      field: "org",
      fieldName: "Org Name",
      sortable: true,
      className: classes.tableHeader,
      cellClassName: classes.tableCellDetails,
      filterable: true,
      filterField: (
        <div style={{ display: "flex", alignItems: "center", marginTop: 8 }}>
          <SearchBox
            onSearch={(v) => handleSearch("org", v)}
            searchType="auto"
            placeholder="Search Org Name"
          />
        </div>
      ),
    },
    {
      field: "gpt_blasts_sent",
      fieldName: "#GPT Blast Sent",
      sortable: true,
      className: classes.tableHeader,
      cellClassName: classes.tableCellDetails,
      filterable: true,
      filterField: (
        <div style={{ display: "flex", alignItems: "center", marginTop: 8 }}>
          <SearchBox
            onSearch={(v) => handleSearch("gpt_blasts_sent", v)}
            searchType="auto"
            placeholder="Search GPT Blast Sent"
          />
        </div>
      ),
    },
    {
      field: "fields_generated",
      fieldName: "#Fields Generated",
      sortable: true,
      className: classes.tableHeader,
      cellClassName: classes.tableCellDetails,
      filterable: true,
      filterField: (
        <div style={{ display: "flex", alignItems: "center", marginTop: 8 }}>
          <SearchBox
            onSearch={(v) => handleSearch("fields_generated", v)}
            searchType="auto"
            placeholder="Search Fields Generated"
          />
        </div>
      ),
    },
    {
      field: "fields_liked",
      fieldName: "#Fields Like",
      sortable: true,
      className: classes.tableHeader,
      cellClassName: classes.tableCellDetails,
      filterable: true,
      filterField: (
        <div style={{ display: "flex", alignItems: "center", marginTop: 8 }}>
          <SearchBox
            onSearch={(v) => handleSearch("fields_liked", v)}
            searchType="auto"
            placeholder="Search Fields Like"
          />
        </div>
      ),
    },
    {
      field: "fields_disliked",
      fieldName: "#Fields Dislike",
      sortable: true,
      className: classes.tableHeader,
      cellClassName: classes.tableCellDetails,
      filterable: true,
      filterField: (
        <div style={{ display: "flex", alignItems: "center", marginTop: 8 }}>
          <SearchBox
            onSearch={(v) => handleSearch("fields_disliked", v)}
            searchType="auto"
            placeholder="Search Fields Dislike"
          />
        </div>
      ),
    },
    {
      field: "human_replacements",
      fieldName: "#Human Replacements",
      sortable: true,
      className: classes.tableHeader,
      cellClassName: classes.tableCellDetails,
      filterable: true,
      filterField: (
        <div style={{ display: "flex", alignItems: "center", marginTop: 8 }}>
          <SearchBox
            onSearch={(v) => handleSearch("human_replacements", v)}
            searchType="auto"
            placeholder="Search Human Replacements"
          />
        </div>
      ),
    },
  ];
};

export const csvHeadersOrgsFeedback = [
  { label: "Org", key: "org" },
  { label: "GPT Blast Sent", key: "gpt_blasts_sent" },
  { label: "Fields Generated", key: "fields_generated" },
  { label: "Fields Liked", key: "fields_liked" },
  { label: "Fields Dislike", key: "fields_disliked" },
  { label: "Human Replacement", key: "human_replacements" },
];

export const csvHeadersPropertyFeedback = [
  { label: "Property", key: "property" },
  { label: "GPT Blast Sent", key: "gpt_blasts_sent" },
  { label: "Fields Generated", key: "fields_generated" },
  { label: "Fields Liked", key: "fields_liked" },
  { label: "Fields Dislike", key: "fields_disliked" },
  { label: "Human Replacement", key: "human_replacements" },
];

export const getDetailsColumnsForProperty = (classes, handleSearch) => {
  return [
    {
      field: "property",
      fieldName: "Property",
      sortable: true,
      className: classes.tableHeader,
      cellClassName: classes.tableCellDetails,
      filterable: true,
      filterField: (
        <div style={{ display: "flex", alignItems: "center", marginTop: 8 }}>
          <SearchBox
            onSearch={(v) => handleSearch("property", v)}
            searchType="auto"
            placeholder="Search Org Name"
          />
        </div>
      ),
    },
    {
      field: "gpt_blasts_sent",
      fieldName: "#GPT Blast Sent",
      sortable: true,
      className: classes.tableHeader,
      cellClassName: classes.tableCellDetails,
      filterable: true,
      filterField: (
        <div style={{ display: "flex", alignItems: "center", marginTop: 8 }}>
          <SearchBox
            onSearch={(v) => handleSearch("gpt_blasts_sent", v)}
            searchType="auto"
            placeholder="Search GPT Blast Sent"
          />
        </div>
      ),
    },
    {
      field: "fields_generated",
      fieldName: "#Fields Generated",
      sortable: true,
      className: classes.tableHeader,
      cellClassName: classes.tableCellDetails,
      filterable: true,
      filterField: (
        <div style={{ display: "flex", alignItems: "center", marginTop: 8 }}>
          <SearchBox
            onSearch={(v) => handleSearch("fields_generated", v)}
            searchType="auto"
            placeholder="Search Fields Generated"
          />
        </div>
      ),
    },
    {
      field: "fields_liked",
      fieldName: "#Fields Like",
      sortable: true,
      className: classes.tableHeader,
      cellClassName: classes.tableCellDetails,
      filterable: true,
      filterField: (
        <div style={{ display: "flex", alignItems: "center", marginTop: 8 }}>
          <SearchBox
            onSearch={(v) => handleSearch("fields_liked", v)}
            searchType="auto"
            placeholder="Search Fields Like"
          />
        </div>
      ),
    },
    {
      field: "fields_disliked",
      fieldName: "#Fields Dislike",
      sortable: true,
      className: classes.tableHeader,
      cellClassName: classes.tableCellDetails,
      filterable: true,
      filterField: (
        <div style={{ display: "flex", alignItems: "center", marginTop: 8 }}>
          <SearchBox
            onSearch={(v) => handleSearch("fields_disliked", v)}
            searchType="auto"
            placeholder="Search Fields Dislike"
          />
        </div>
      ),
    },
    {
      field: "human_replacements",
      fieldName: "#Human Replacements",
      sortable: true,
      className: classes.tableHeader,
      cellClassName: classes.tableCellDetails,
      filterable: true,
      filterField: (
        <div style={{ display: "flex", alignItems: "center", marginTop: 8 }}>
          <SearchBox
            onSearch={(v) => handleSearch("human_replacements", v)}
            searchType="auto"
            placeholder="Search Human Replacements"
          />
        </div>
      ),
    },
  ];
};

export const TABLESCONTENTBYBLAST = (data) => {
  return [
    {
      BlastType: "Offers",
      PromptTemplate: "Holiday Offers",
      GPTBlastSent: 53,
      FieldsGenerated: 177,
      FieldsLike: 45,
      FieldsDislike: 1,
      Human_Replaced: 14,
    },
    {
      BlastType: "Offers",
      PromptTemplate: "Holiday Offers",
      GPTBlastSent: 53,
      FieldsGenerated: 177,
      FieldsLike: 45,
      FieldsDislike: 1,
      Human_Replaced: 14,
    },
    {
      BlastType: "Offers",
      PromptTemplate: "Holiday Offers",
      GPTBlastSent: 53,
      FieldsGenerated: 177,
      FieldsLike: 45,
      FieldsDislike: 1,
      Human_Replaced: 14,
    },
    {
      BlastType: "Offers",
      PromptTemplate: "Holiday Offers",
      GPTBlastSent: 53,
      FieldsGenerated: 177,
      FieldsLike: 45,
      FieldsDislike: 1,
      Human_Replaced: 14,
    },
    {
      BlastType: "Offers",
      PromptTemplate: "Holiday Offers",
      GPTBlastSent: 53,
      FieldsGenerated: 177,
      FieldsLike: 45,
      FieldsDislike: 1,
      Human_Replaced: 14,
    },
    {
      BlastType: "Offers",
      PromptTemplate: "Holiday Offers",
      GPTBlastSent: 53,
      FieldsGenerated: 177,
      FieldsLike: 45,
      FieldsDislike: 1,
      Human_Replaced: 14,
    },
  ];
};

export const TABLESCONTENTBYFIELDS = (data) => {
  return [
    {
      org: "1Abe Residential",
      Property: "Avenue at york",
      BlastType: "Offers",
      PromptTemplate: "Holiday Offers",
      like: 2,
      dislike: "0",
      regenerate: 2,
      Human_Replaced: 2,
    },
    {
      org: "1Abe Residential",
      Property: "Avenue at york",
      BlastType: "Offers",
      PromptTemplate: "Holiday Offers",
      like: 2,
      dislike: "0",
      regenerate: 2,
      Human_Replaced: 2,
    },
    {
      org: "1Abe Residential",
      Property: "Avenue at york",
      BlastType: "Offers",
      PromptTemplate: "Holiday Offers",
      like: 2,
      dislike: "0",
      regenerate: 2,
      Human_Replaced: 2,
    },
    {
      org: "1Abe Residential",
      Property: "Avenue at york",
      BlastType: "Offers",
      PromptTemplate: "Holiday Offers",
      like: 2,
      dislike: "0",
      regenerate: 2,
      Human_Replaced: 2,
    },
    {
      org: "1Abe Residential",
      Property: "Avenue at york",
      BlastType: "Offers",
      PromptTemplate: "Holiday Offers",
      like: 2,
      dislike: "0",
      regenerate: 2,
      Human_Replaced: 2,
    },
    {
      org: "1Abe Residential",
      Property: "Avenue at york",
      BlastType: "Offers",
      PromptTemplate: "Holiday Offers",
      like: 2,
      dislike: "0",
      regenerate: 2,
      Human_Replaced: 2,
    },
  ];
};

const getColumnListValues = (data, column) => {
  let columnList = [];
  (data || []).map((item) => {
    //  return array of object with label and value with unique values
    if (columnList.findIndex((e) => e.label === item[column]) === -1 && item[column] !== "") {
      columnList.push({
        label: item[column],
        value: item[column],
      });
    }
  });
  return columnList;
};

export const csvHeadersForByFields = [
  { label: "Org", key: "org_name" },
  { label: "Property", key: "property_name" },
  { label: "Blast Type", key: "blast_type" },
  { label: "Prompt Template", key: "prompt_template" },
  { label: "Liked", key: "liked" },
  { label: "Dislike", key: "disliked" },
  { label: "Regenerate", key: "regenerated" },
  { label: "Human Replaced", key: "human_replaced" },
];

export const getReportByFieldsColumns = (classes, handleSearch, tableData) => {
  return [
    {
      field: "org_name",
      fieldName: "Org",
      sortable: true,
      className: classes.tableHeader,
      cellClassName: classes.tableCellDetails,
      filterable: true,
      filterField: (
        <div style={{ display: "flex", alignItems: "center", marginTop: 8 }}>
          <SearchAutocomplete
            onSearch={(v) => handleSearch("org_name", v)}
            searchType="auto"
            placeholder="Search Org"
            options={getColumnListValues(tableData, "org_name")}
          />
        </div>
      ),
    },
    {
      field: "property_name",
      fieldName: "Property",
      sortable: true,
      className: classes.tableHeader,
      cellClassName: classes.tableCellDetails,
      filterable: true,
      filterField: (
        <div style={{ display: "flex", alignItems: "center", marginTop: 8 }}>
          <SearchAutocomplete
            onSearch={(v) => handleSearch("property_name", v)}
            searchType="auto"
            placeholder="Search Org"
            options={getColumnListValues(tableData, "property_name")}
          />
        </div>
      ),
    },
    {
      field: "blast_type",
      fieldName: "Blast Type",
      sortable: true,
      className: classes.tableHeader,
      cellClassName: classes.tableCellDetails,
      filterable: true,
      filterField: (
        <div style={{ display: "flex", alignItems: "center", marginTop: 8 }}>
          <SearchAutocomplete
            onSearch={(v) => handleSearch("blast_type", v)}
            searchType="auto"
            placeholder="Search Org"
            options={getColumnListValues(tableData, "blast_type")}
          />
        </div>
      ),
    },
    {
      field: "prompt_template",
      fieldName: "Prompt Template",
      sortable: true,
      className: classes.tableHeader,
      cellClassName: classes.tableCellDetails,
      filterable: true,
      filterField: (
        <div style={{ display: "flex", alignItems: "center", marginTop: 8 }}>
          <SearchAutocomplete
            onSearch={(v) => handleSearch("prompt_template", v)}
            searchType="auto"
            placeholder="Search Org"
            options={getColumnListValues(tableData, "prompt_template")}
          />
        </div>
      ),
    },
    {
      field: "fields",
      fieldName: "Fields Feedback",
      sortable: true,
      className: classes.tableHeader,
      cellClassName: classes.tableCellDetails,
      filterable: true,
      filterField: (
        <div style={{ display: "flex", alignItems: "center", marginTop: 8 }}>
          <SearchAutocomplete
            onSearch={(v) => handleSearch("feedback", v)}
            searchType="auto"
            placeholder="Search Org"
            options={["Like", "Dislike", "Regenerate", "Human Replaced"].map((item) => ({
              label: item,
              value: item,
            }))}
          />
        </div>
      ),
    },
  ];
};

export const csvHeadersByBlastFeedback = [
  { label: "Blast Type", key: "blast_type" },
  { label: "Prompt Template", key: "prompt_template" },
  { label: "GPT Blast Sent", key: "gpt_blasts_sent" },
  { label: "Fields Generated", key: "fields_generated" },
  { label: "Fields Liked", key: "fields_liked" },
  { label: "Fields Dislike", key: "fields_disliked" },
  { label: "Human Replacement", key: "human_replacements" },
];
export const getReportByBlastColumns = (classes, handleSearch) => {
  return [
    {
      field: "blast_type",
      fieldName: "Blast Type",
      sortable: true,
      className: classes.tableHeader,
      cellClassName: classes.tableCellDetails,
      filterable: true,
      filterField: (
        <div style={{ display: "flex", alignItems: "center", marginTop: 8 }}>
          <SearchBox
            onSearch={(v) => handleSearch("blast_type", v)}
            searchType="auto"
            placeholder="Search Blast Type"
          />
        </div>
      ),
    },
    {
      field: "prompt_template",
      fieldName: "Prompt Template",
      sortable: true,
      className: classes.tableHeader,
      cellClassName: classes.tableCellDetails,
      filterable: true,
      filterField: (
        <div style={{ display: "flex", alignItems: "center", marginTop: 8 }}>
          <SearchBox
            onSearch={(v) => handleSearch("prompt_template", v)}
            searchType="auto"
            placeholder="Search Prompt Template"
          />
        </div>
      ),
    },
    {
      field: "gpt_blasts_sent",
      fieldName: "#GPT Blast Sent",
      sortable: true,
      className: classes.tableHeader,
      cellClassName: classes.tableCellDetails,
      filterable: true,
      filterField: (
        <div style={{ display: "flex", alignItems: "center", marginTop: 8 }}>
          <SearchBox
            onSearch={(v) => handleSearch("gpt_blasts_sent", v)}
            searchType="auto"
            placeholder="Search GPT Blast Sent"
          />
        </div>
      ),
    },
    {
      field: "fields_generated",
      fieldName: "#Fields Generated",
      sortable: true,
      className: classes.tableHeader,
      cellClassName: classes.tableCellDetails,
      filterable: true,
      filterField: (
        <div style={{ display: "flex", alignItems: "center", marginTop: 8 }}>
          <SearchBox
            onSearch={(v) => handleSearch("fields_generated", v)}
            searchType="auto"
            placeholder="Search Fields Generated"
          />
        </div>
      ),
    },
    {
      field: "fields_liked",
      fieldName: "#Fields Like",
      sortable: true,
      className: classes.tableHeader,
      cellClassName: classes.tableCellDetails,
      filterable: true,
      filterField: (
        <div style={{ display: "flex", alignItems: "center", marginTop: 8 }}>
          <SearchBox
            onSearch={(v) => handleSearch("fields_liked", v)}
            searchType="auto"
            placeholder="Search Fields Like"
          />
        </div>
      ),
    },
    {
      field: "fields_disliked",
      fieldName: "#Fields Dislike",
      sortable: true,
      className: classes.tableHeader,
      cellClassName: classes.tableCellDetails,
      filterable: true,
      filterField: (
        <div style={{ display: "flex", alignItems: "center", marginTop: 8 }}>
          <SearchBox
            onSearch={(v) => handleSearch("fields_disliked", v)}
            searchType="auto"
            placeholder="Search Fields Dislike"
          />
        </div>
      ),
    },
    {
      field: "human_replacements",
      fieldName: "#Human Replacements",
      sortable: true,
      className: classes.tableHeader,
      cellClassName: classes.tableCellDetails,
      filterable: true,
      filterField: (
        <div style={{ display: "flex", alignItems: "center", marginTop: 8 }}>
          <SearchBox
            onSearch={(v) => handleSearch("human_replacements", v)}
            searchType="auto"
            placeholder="Search Human Replacements"
          />
        </div>
      ),
    },
  ];
};

export const getDetailsRowsForOrgs = (classes, data) => {
  return data?.map((item) => {
    return {
      org: <Typography className={`${classes.tableText}`}>{item.org}</Typography>,
      gpt_blasts_sent: (
        <Typography className={`${classes.tableText}`}>{item.gpt_blasts_sent}</Typography>
      ),
      fields_generated: (
        <Typography className={`${classes.tableText}`}>{item.fields_generated}</Typography>
      ),
      fields_liked: <Typography className={`${classes.tableText}`}>{item.fields_liked}</Typography>,
      fields_disliked: (
        <Typography className={`${classes.tableText}`}>{item.fields_disliked}</Typography>
      ),
      human_replacements: (
        <Typography className={`${classes.tableText}`}>{item.human_replacements}</Typography>
      ),
    };
  });
};

export const getDetailsRowsForProperties = (classes, data) => {
  return data
    ? data.map((item) => {
        return {
          property: <Typography className={`${classes.tableText}`}>{item.property}</Typography>,
          gpt_blasts_sent: (
            <Typography className={`${classes.tableText}`}>{item.gpt_blasts_sent}</Typography>
          ),
          fields_generated: (
            <Typography className={`${classes.tableText}`}>{item.fields_generated}</Typography>
          ),
          fields_liked: (
            <Typography className={`${classes.tableText}`}>{item.fields_liked}</Typography>
          ),
          fields_disliked: (
            <Typography className={`${classes.tableText}`}>{item.fields_disliked}</Typography>
          ),
          human_replacements: (
            <Typography className={`${classes.tableText}`}>{item.human_replacements}</Typography>
          ),
        };
      })
    : [];
};

export const getReportByBlastRows = (classes, data) => {
  return data.map((item) => {
    return {
      blast_type: <Typography className={`${classes.tableText}`}>{item.blast_type}</Typography>,
      prompt_template: (
        <Typography className={`${classes.tableText}`}>{item.prompt_template}</Typography>
      ),
      gpt_blasts_sent: (
        <Typography className={`${classes.tableText}`}>{item.gpt_blasts_sent}</Typography>
      ),
      fields_generated: (
        <Typography className={`${classes.tableText}`}>{item.fields_generated}</Typography>
      ),
      fields_liked: <Typography className={`${classes.tableText}`}>{item.fields_liked}</Typography>,
      fields_disliked: (
        <Typography className={`${classes.tableText}`}>{item.fields_disliked}</Typography>
      ),
      human_replacements: (
        <Typography className={`${classes.tableText}`}>{item.human_replacements}</Typography>
      ),
    };
  });
};

const formatFieldsData = (data, key) => {
  return (data?.fields?.[key] || []).map((item) => ({
    ...item,
    blast_type: data.blast_type,
    prompt_template: data.prompt_template,
  }));
};

export const getReportByFieldsRows = (classes, data, history) => {
  return data.map((item) => {
    return {
      org_name: <Typography className={`${classes.tableText}`}>{item.org_name}</Typography>,
      property_name: (
        <Typography className={`${classes.tableText}`}>{item.property_name}</Typography>
      ),
      blast_type: <Typography className={`${classes.tableText}`}>{item.blast_type}</Typography>,
      prompt_template: (
        <Typography className={`${classes.tableText}`}>{item.prompt_template}</Typography>
      ),

      fields: (
        <Typography className={`${classes.tableText} ${classes.clickAbleField}`}>
          <span
            className={classes.FieldsTableIcon}
            onClick={() => {
              history.push({
                pathname: getAppPath() + "/blast/reports/feedback-fields/liked",
                state: { data: formatFieldsData(item, "fields_liked") },
              });
            }}
          >
            <span className={classes.icons}>
              <Like />
            </span>
            {item.fields.fields_liked_count}
          </span>
          <span
            className={classes.FieldsTableIcon}
            onClick={() => {
              history.push({
                pathname: getAppPath() + "/blast/reports/feedback-fields/dislike",
                state: { data: formatFieldsData(item, "fields_disliked") },
              });
            }}
          >
            <span className={classes.icons}>
              <Dislike />
            </span>
            {item.fields.fields_disliked_count}
          </span>
          <span
            className={classes.FieldsTableIcon}
            onClick={() => {
              history.push({
                pathname: getAppPath() + "/blast/reports/feedback-fields/regenerate",
                state: { data: formatFieldsData(item, "fields_generated") },
              });
            }}
          >
            <span className={classes.icons}>
              <Reload />
            </span>
            {item.fields.fields_generated_count}
          </span>
          <span
            className={classes.FieldsTableIcon}
            onClick={() => {
              history.push({
                pathname: getAppPath() + "/blast/reports/feedback-fields/human_replaced",
                state: { data: formatFieldsData(item, "fields_human_replaced") },
              });
            }}
          >
            <span className={classes.icons}>
              <Account />
            </span>
            {item.fields.fields_human_replaced_count}
          </span>
        </Typography>
      ),
    };
  });
};

export const getReportByFieldLikeColumns = (classes, tableData, handleSearch) => {
  return [
    {
      field: "UX_OfferType",
      fieldName: "UX_Offer Type",
      sortable: true,
      className: classes.tableHeader,
      cellClassName: classes.tableCellDetails,
      filterable: true,
      filterField: (
        <div style={{ display: "flex", alignItems: "center", marginTop: 8 }}>
          <SearchBox
            onSearch={(v) => handleSearch("UX_OfferType", v)}
            searchType="auto"
            placeholder="UX_Offer Type"
          />
        </div>
      ),
    },
    {
      field: "UX_StartDate",
      fieldName: "UX_Start Date",
      sortable: true,
      className: classes.tableHeader,
      cellClassName: classes.tableCellDetails,
      filterable: true,
      filterField: (
        <div style={{ display: "flex", alignItems: "center", marginTop: 8 }}>
          <SearchByDate
            handleChange={(v) => handleSearch("UX_StartDate", v.toDate())}
            type="date"
            placeholder="UX_Start Date"
            allowPast={true}
          />
        </div>
      ),
    },
    {
      field: "UX_EndDate",
      fieldName: "UX_End Date",
      sortable: true,
      className: classes.tableHeader,
      cellClassName: classes.tableCellDetails,
      filterable: true,
      filterField: (
        <div style={{ display: "flex", alignItems: "center", marginTop: 8 }}>
          <SearchByDate
            format={true}
            allowPast={true}
            handleChange={(v) => handleSearch("UX_EndDate", v.toDate())}
            type="date"
            placeholder="UX_End Date"
          />
        </div>
      ),
    },
    {
      field: "FieldName",
      fieldName: "Field Name",
      sortable: true,
      className: classes.tableHeader,
      cellClassName: classes.tableCellDetails,
      filterable: true,
      filterField: (
        <div style={{ display: "flex", alignItems: "center", marginTop: 8 }}>
          <SearchAutocomplete
            onSearch={(v) => handleSearch("FieldName", v)}
            searchType="auto"
            placeholder="Search Field Name"
            options={getColumnListValues(tableData, "FieldName")}
          />
        </div>
      ),
    },
    {
      field: "GeneratedValue",
      fieldName: "Generated Value",
      sortable: true,
      className: classes.tableHeader,
      cellClassName: classes.tableCellDetails,
      filterable: true,
      filterField: (
        <div style={{ display: "flex", alignItems: "center", marginTop: 8 }}>
          <SearchBox
            onSearch={(v) => handleSearch("GeneratedValue", v)}
            searchType="auto"
            placeholder="Search Generated Value"
          />
        </div>
      ),
    },
  ];
};

export const TABLESCONTENTBYFIELDSLIKE = (data) => {
  return [
    {
      UX_OfferType: "$1000 off first month's ren",
      UX_StartDate: "December 21, 2022",
      UX_EndDate: "December 1, 2023",
      FieldName: "Subject",
      GeneratedValue: "Hey! Get $1000 off on first month rent",
      subRows: [
        {
          UX_OfferType: "Time Stamp",
          UX_StartDate: "Generated Values",
        },
        {
          UX_OfferType: "$1000 off first month's ren",
          UX_StartDate: "December 21, 2022",
        },
        {
          UX_OfferType: "$1000 off first month's ren",
          UX_StartDate: "December 21, 2022",
        },
        {
          UX_OfferType: "$1000 off first month's ren",
          UX_StartDate: "December 21, 2022",
        },
      ],
    },
    {
      UX_OfferType: "$1000 off first month's ren",
      UX_StartDate: "December 21, 2022",
      UX_EndDate: "December 1, 2023",
      FieldName: "Pre Header",
      GeneratedValue: "Get $100 Off",
    },
    {
      UX_OfferType: "$1000 off first month's ren",
      UX_StartDate: "December 21, 2022",
      UX_EndDate: "December 1, 2023",
      FieldName: "Subject",
      GeneratedValue: "Hey! Get $1000 off on first month rent",
      subRows: [
        {
          UX_OfferType: "Time Stamp",
          UX_StartDate: "Generated Values",
        },
        {
          UX_OfferType: "$1000 off first month's ren",
          UX_StartDate: "December 21, 2022",
        },
        {
          UX_OfferType: "$1000 off first month's ren",
          UX_StartDate: "December 21, 2022",
        },
        {
          UX_OfferType: "$1000 off first month's ren",
          UX_StartDate: "December 21, 2022",
        },
      ],
    },
    {
      UX_OfferType: "$1000 off first month's ren",
      UX_StartDate: "December 21, 2022",
      UX_EndDate: "December 1, 2023",
      FieldName: "Body",
      GeneratedValue:
        "Exciting news! For a limited time, we are waiving all administrative fees for multifamily transactions. This special offer is our way of thanking you for your support and loyalty. Enjoy a streamlined and cost-effective experience with us. Act fast, this promotion ends on [Expiration Date]. Contact us for more details.",
    },
  ];
};

export const csvHeadersForReportsDetials = [
  { label: "UX_Offer Type", key: "UX_OfferType" },
  { label: "UX_Start Date", key: "UX_StartDate" },
  { label: "UX_End Date", key: "UX_EndDate" },
  { label: "Field Name", key: "FieldName" },
  { label: "Generated Value", key: "GeneratedValue" },
];

export const getReportByFieldLikeRows = (classes, data, historyText) => {
  return data?.map((item, idx) => {
    return {
      UX_OfferType: <Typography className={`${classes.tableText}`}>{item.UX_OfferType}</Typography>,
      UX_StartDate: <Typography className={`${classes.tableText}`}>{item.UX_StartDate}</Typography>,
      UX_EndDate: <Typography className={`${classes.tableText}`}>{item.UX_EndDate}</Typography>,
      FieldName: <Typography className={`${classes.tableText}`}>{item.FieldName}</Typography>,
      GeneratedValue: (
        <Typography className={`${classes.tableText}`}>{item.GeneratedValue} </Typography>
      ),
      subRows: item?.subRows?.length ? item.subRows : null,
    };
  });
};

export const BottomTextForOrgs = (classes, data) => {
  return (
    <ul>
      <li className={classes.listitem}>
        {data && data?.most_gpt_blasts_sent_by_org && (
          <Typography sx={{ color: "rgba(0, 0, 0, 0.5) !important" }}>
            Most <strong>GPT Blasts</strong> were sent by{" "}
            <strong>{data.most_gpt_blasts_sent_by_org}</strong>
          </Typography>
        )}
      </li>
      <li className={classes.listitem}>
        {data && data?.most_fields_generated_by_org && (
          <Typography sx={{ color: "rgba(0, 0, 0, 0.5) !important" }}>
            Most <strong>Fields</strong> were generated by{" "}
            <strong>{data.most_fields_generated_by_org}</strong>
          </Typography>
        )}
      </li>
      <li className={classes.listitem}>
        {data && data?.most_fields_liked_by_org && (
          <Typography sx={{ color: "rgba(0, 0, 0, 0.5) !important" }}>
            Most Fields were <strong>liked</strong> by{" "}
            <strong>{data.most_fields_liked_by_org}</strong>
          </Typography>
        )}
      </li>
      <li className={classes.listitem}>
        {data && data?.most_fields_disliked_by_org && (
          <Typography sx={{ color: "rgba(0, 0, 0, 0.5) !important" }}>
            Most Fields were <strong>disliked</strong> by{" "}
            <strong>{data.most_fields_disliked_by_org}</strong>
          </Typography>
        )}
      </li>
      <li className={classes.listitem}>
        {data && data?.most_human_replacements_by_org && (
          <Typography sx={{ color: "rgba(0, 0, 0, 0.5) !important" }}>
            Most <strong>human replacements</strong> were done by{" "}
            <strong>{data.most_human_replacements_by_org}</strong>
          </Typography>
        )}
      </li>
    </ul>
  );
};

export const BottomTextForProperty = (classes, data) => {
  return (
    <ul>
      <li className={classes.listitem}>
        {data && data?.most_gpt_blasts_sent_by_property && (
          <Typography sx={{ color: "rgba(0, 0, 0, 0.5) !important" }}>
            Most <strong>GPT Blasts</strong> were sent by{" "}
            <strong>{data.most_gpt_blasts_sent_by_property}</strong>
          </Typography>
        )}
      </li>
      <li className={classes.listitem}>
        {data && data?.most_fields_generated_by_property && (
          <Typography sx={{ color: "rgba(0, 0, 0, 0.5) !important" }}>
            Most <strong>Fields</strong> were generated by{" "}
            <strong>{data.most_fields_generated_by_property}</strong>
          </Typography>
        )}
      </li>
      <li className={classes.listitem}>
        {data && data?.most_fields_liked_by_property && (
          <Typography sx={{ color: "rgba(0, 0, 0, 0.5) !important" }}>
            Most Fields were <strong>liked</strong> by{" "}
            <strong>{data.most_fields_liked_by_property}</strong>
          </Typography>
        )}
      </li>
      <li className={classes.listitem}>
        {data && data?.most_fields_disliked_by_property && (
          <Typography sx={{ color: "rgba(0, 0, 0, 0.5) !important" }}>
            Most Fields were <strong>disliked</strong> by{" "}
            <strong>{data.most_fields_disliked_by_property}</strong>
          </Typography>
        )}
      </li>
      <li className={classes.listitem}>
        {data && data?.most_human_replacements_by_property && (
          <Typography sx={{ color: "rgba(0, 0, 0, 0.5) !important" }}>
            Most <strong>human replacements</strong> were done by{" "}
            <strong>{data.most_human_replacements_by_property}</strong>
          </Typography>
        )}
      </li>
    </ul>
  );
};

export const setCustomDate = (setMonthRange, dispatch) => {
  if (moment(Date()).day() < 5) {
    const difference = 5 - moment(Date()).day();
    const start = moment(Date()).add(difference, "day");
    const end = moment(Date()).add(difference + 6, "day");
    dispatch(setMonthRange({ startAt: start, endAt: end, label: "Custom Range" }));
  } else if (moment(Date()).day() == 5) {
    const start = moment(Date());
    const end = moment(Date()).add(6, "day");
    dispatch(setMonthRange({ startAt: start, endAt: end, label: "Custom Range" }));
  } else {
    const start = moment(Date()).add(5, "day");
    const end = moment(Date()).add(11, "day");
    dispatch(setMonthRange({ startAt: start, endAt: end, label: "Custom Range" }));
  }
};

export const getInitialDataByOrgs = async (classes, monthRange, storedOrgs, storedProperties) => {
  let { startAt, endAt } = monthRange;
  startAt = moment(startAt).format("YYYY-MM-DD");
  endAt = moment(endAt).format("YYYY-MM-DD");
  let listData;
  let cardsData;
  let tableData;
  if (storedOrgs && storedOrgs?.Orgs?.length > 1 && storedProperties.length == 0) {
    const res = await AllOrgsBlastAnalysis(
      storedOrgs && storedOrgs.Orgs.length === storedOrgs.TotalOrgs
        ? []
        : storedOrgs.Orgs.map((item) => item.id),
      storedOrgs && storedOrgs.Orgs.length === storedOrgs.TotalOrgs ? true : false,
      startAt,
      endAt
    );
    if (res && res?.allOrgsGptBlastAnalysis) {
      listData = res?.allOrgsGptBlastAnalysis;
      cardsData = CARDSCONTENT(res?.allOrgsGptBlastAnalysis);
      tableData = res?.allOrgsGptBlastAnalysis?.drill_down_by_org;
      return { listData, tableData, cardsData, isOrg: true };
    }
  } else {
    const res = await orgGptBlastAnalysis(
      storedOrgs?.Orgs[0]?.id?.toString(),
      startAt,
      endAt,
      storedProperties
    );
    if (res && res?.orgGptBlastAnalysis) {
      listData = res?.orgGptBlastAnalysis;
      cardsData = CARDSCONTENT(res?.orgGptBlastAnalysis);
      tableData = res?.orgGptBlastAnalysis?.drill_down_by_property;
      return { listData, cardsData, tableData, isOrg: false };
    }
  }
};

export const getInitialDataByBlast = async (
  classes,
  monthRange,
  storedOrgs,
  storedProperties,
  purposesList,
  propmtList
) => {
  try {
    let { startAt, endAt } = monthRange;
    let cardsData;
    let tableData;
    startAt = moment(startAt).format("YYYY-MM-DD");
    endAt = moment(endAt).format("YYYY-MM-DD");
    const response = await mailGptBlastTypeReports(
      storedOrgs && storedOrgs.Orgs.length === storedOrgs.TotalOrgs
        ? []
        : storedOrgs.Orgs.map((item) => item.id),
      startAt,
      endAt,
      storedOrgs && storedOrgs.Orgs.length === storedOrgs.TotalOrgs ? true : false,
      purposesList.map((purpose) => purpose.id),
      propmtList.map((item) => item.value),
      storedProperties
    );
    if (response && Object.keys(response.mailGptBlastTypeReports).length > 0) {
      cardsData = CARDSCONTENTBYBLAST(response?.mailGptBlastTypeReports);
      tableData = response?.mailGptBlastTypeReports?.detailed_blast_type_data;
      return { tableData, cardsData };
    } else {
      return { tableData: [], cardsData: [] };
    }
  } catch (err) {
    console.log("error", err);
  }
};

export const getInitDataByFields = async (
  classes,
  history,
  monthRange,
  storedOrgs,
  storedProperties,
  purposesList,
  propmtList
) => {
  try {
    let { startAt, endAt } = monthRange;
    let tableData = [];
    startAt = moment(startAt).format("YYYY-MM-DD");
    endAt = moment(endAt).format("YYYY-MM-DD");
    const response = await allOrgsGptFieldsAnalysis(
      storedOrgs && storedOrgs?.Orgs?.length === storedOrgs.TotalOrgs
        ? []
        : storedOrgs?.Orgs?.map((item) => item.id),
      startAt,
      endAt,
      storedOrgs && storedOrgs?.Orgs?.length === storedOrgs.TotalOrgs ? true : false,
      purposesList.map((purpose) => purpose.id),
      propmtList.map((item) => item.value),
      storedProperties
    );
    if (response && response?.allOrgsGptFieldsAnalysis.length) {
      tableData = response?.allOrgsGptFieldsAnalysis;
      return tableData;
    } else {
      return tableData;
    }
  } catch (err) {
    console.log("error", err);
  }
};

export const getLabelFromName = (str) => {
  return str.split("-")[1];
  // const lastIndex = str.lastIndexOf("-");

  // if (lastIndex !== -1) {
  //   let result = str.substring(lastIndex + 1);
  //   result = result.replace(/\([^()]*\)/g, "");
  //   return result;
  // } else {
  //   return;
  // }
};

export const getNameList = (ids, values) => {
  let names = [];
  for (let i = 0; i < ids.length; i++) {
    let value = values.find((e) => e.id === ids[i]);
    if (value) {
      names.push(value.name);
    }
  }
  return names;
};

export const filterCommonValues = (array1, array2) => {
  let commonValues = [];

  for (let i = 0; i < array1.length; i++) {
    if (array2.includes(array1[i])) {
      commonValues.push(array1[i]);
    }
  }

  return commonValues;
};
