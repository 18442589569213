import React from "react";

function ReloadIcon({ className = "text-[#333]", size = 15 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      className={className}
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M3 8a5.2 5.2 0 019.582-2.8M12.6 2v3.2H9.4M13.4 8a5.2 5.2 0 01-9.582 2.8"
      ></path>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M3.8 14v-3.2H7"
      ></path>
    </svg>
  );
}

export default ReloadIcon;
