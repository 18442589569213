import React from "react";

export default function PriceDropIcon({ className = "text-[#999999]", width = 18, height = 18 }) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.5 3.30078V14.5508"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.125 5.17528C6.625 4.41928 2.5 4.07953 2.5 6.67528C2.5 9.31903 8.5 8.17903 8.5 11.1753C8.5 13.7155 4.213 13.5753 2.5 12.6753"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 6.61328V12.2383"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.375 10.3633L12.5 12.2383L10.625 10.3633"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
