import * as React from "react";
import { Button } from "storybookComponents";
import Icon from "storybookComponents/Icon";

const DeleteScheduledEmail = ({ classes, handleDeleteCallback, delSchedulEmail, buttonState }) => {
  return (
    <>
      <div onClick={handleDeleteCallback.bind(this, true)} className={classes.backbtnStyle}>
        <Icon type="icon-arrow-left" size="sm" />
        <span>Back</span>
      </div>
      <div className={classes.deletePannel}>
        Are you sure you want to delete the{" "}
        <b>
          {delSchedulEmail.item.propertyName} {delSchedulEmail.item.name}
        </b>{" "}
        ?
      </div>
      <div className={classes.buttonContainer}>
        <Button variant="tertiary" onClick={handleDeleteCallback.bind(this, true)}>
          Cancel
        </Button>
        <Button
          negative
          variant="secondary"
          onClick={handleDeleteCallback}
          state={buttonState ? "active" : "inactive"}
        >
          Delete
        </Button>
      </div>
    </>
  );
};

export default DeleteScheduledEmail;
