import React from "react";

export default function SimpleCopyIcon({ className = "text-[#333333]", size = 12 }) {
  return (
    <svg
      className={className}
      width={size}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_683_33165)">
        <path
          d="M9.25 2.75H1.25V11.75H9.25V2.75Z"
          stroke="currentColor"
          stroke-miterlimit="10"
          stroke-linecap="square"
        />
        <path
          d="M2.75 0.75H11.25V10.75"
          stroke="currentColor"
          stroke-miterlimit="10"
          stroke-linecap="square"
        />
        <path
          d="M3.25 5.25H7.25"
          stroke="currentColor"
          stroke-miterlimit="10"
          stroke-linecap="square"
        />
        <path
          d="M3.25 7.25H7.25"
          stroke="currentColor"
          stroke-miterlimit="10"
          stroke-linecap="square"
        />
        <path
          d="M3.25 9.25H5.25"
          stroke="currentColor"
          stroke-miterlimit="10"
          stroke-linecap="square"
        />
      </g>
      <defs>
        <clipPath id="clip0_683_33165">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
