import React from "react";

export default function WebsiteIcon({ className = "text-[#999999]", width = 18, height = 18 }) {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3 5.40039H15" stroke="currentColor" stroke-linecap="round" strokeLinejoin="round" />
      <path
        d="M7 5.40039V15.0004"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.2 3H3.8C3.35817 3 3 3.35817 3 3.8V14.2C3 14.6418 3.35817 15 3.8 15H14.2C14.6418 15 15 14.6418 15 14.2V3.8C15 3.35817 14.6418 3 14.2 3Z"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
