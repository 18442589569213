import React from "react";

export default function ClockIcon({ className, size = 32 }) {
  return (
    <svg
      width={size}
      className={className}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5 24C19.6421 24 23 20.6421 23 16.5C23 12.3579 19.6421 9 15.5 9C11.3579 9 8 12.3579 8 16.5C8 20.6421 11.3579 24 15.5 24Z"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5 12.2143V16.5H19.7857"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
