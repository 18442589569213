import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  past: [],
  present: {
    focusedJourney: null,
    journeyData: null,
    selectedNode: null,
    selectedVariantNode: null,
    actionItems: null,
    // Filtered options from actionItems for the specific purposes: allWhens, allThens
    allWhens: null,
    allThens: null,
    allWhensWithCategories: null,
    allThensWithCategories: null,
    showTriggerList: false,
    showHistoryPanel: false,
    selectedBotId: null,
    deletedBotIds: [],
    focusedElementId: "",
  },
  future: [],
  templates: null,
  isAngelAI: false,
  // For global usage inside mJAI
  orgId: null,
  orgLevel: null,
  macroKey: "",
  // Value for determining data fetching on the diagram page
  dataFetchEnabled: true,
  datakey4EmailEditor: "",
};

const mJAISlice = createSlice({
  name: "mJAI",
  initialState,
  reducers: {
    setJourneyData: (state, action) => {
      if (action.payload.type === "keep_history") {
        let present = Object.assign({}, state.present);
        state.past = [...state.past, present];
      }
      state.present.journeyData = action.payload.data;
    },
    setFocusedJourney: (state, action) => {
      if (action.payload.type === "keep_history") {
        let present = Object.assign({}, state.present);
        state.past = [...state.past, present];
      }
      state.present.focusedJourney = action.payload.data;
    },
    setJAISelectedNode: (state, action) => {
      if (action.payload.type === "keep_history") {
        let present = Object.assign({}, state.present);
        state.past = [...state.past, present];
      }
      state.present.selectedNode = action.payload.data;
    },
    setSelectedBotId: (state, action) => {
      state.present.selectedBotId = action.payload;
    },
    setDeletedBotIds: (state, action) => {
      state.present.deletedBotIds = action.payload;
    },
    setJAISelectedVariantNode: (state, action) => {
      if (action.payload.type === "keep_history") {
        let present = Object.assign({}, state.present);
        state.past = [...state.past, present];
      }
      state.present.selectedVariantNode = action.payload.data;
    },
    setJourneyActionItems: (state, action) => {
      /**
       * if no payload passed, format the actionItems as null
       */
      if (!action.payload) {
        state.present.actionItems = null;

        return;
      }

      if (action.payload.type === "keep_history") {
        let present = Object.assign({}, state.present);
        state.past = [...state.past, present];
      }
      state.present.actionItems = action.payload.data;
      state.present.allWhens = JSON.parse(action.payload.data["allOptions"][0])[1];
      state.present.allThens = JSON.parse(action.payload.data["allOptions"][1])[1];
      state.present.allWhensWithCategories = JSON.parse(
        action.payload.data["allOptionsCategories"][0]
      )[1];
      state.present.allThensWithCategories = JSON.parse(
        action.payload.data["allOptionsCategories"][1]
      )[1];
    },
    redo: (state, action) => {
      const next = state.future[0];
      const newFuture = state.future.slice(1);
      return {
        past: [...state.past, state.present],
        present: next,
        future: newFuture,
      };
    },
    undo: (state, action) => {
      const previous = state.past[state.past.length - 1];
      const newPast = state.past.slice(0, state.past.length - 1);
      return {
        past: newPast,
        present: { ...previous },
        future: [state.present, ...state.future],
      };
    },
    toggleShowTriggerList: (state, action) => {
      if (action.payload) {
        state.showTriggerList = action.payload;
      } else {
        state.showTriggerList = !state.showTriggerList;
      }
    },
    toggleShowHistoryPannel: (state, action) => {
      if (typeof action.payload === "boolean") {
        state.showHistoryPanel = action.payload;
      } else {
        state.showHistoryPanel = !state.showHistoryPanel;
      }
    },
    setTemplates: (state, action) => {
      state.templates = action.payload;
    },
    setIsAngelAI: (state, action) => {
      state.isAngelAI = action.payload;
    },
    setOrgId: (state, action) => {
      state.orgId = action.payload;
    },
    setOrgLevel: (state, action) => {
      state.orgLevel = action.payload;
    },
    setMacroKey: (state, action) => {
      state.macroKey = action.payload;
    },
    setFocusedElementId: (state, action) => {
      state.present.focusedElementId = action.payload;
    },
    setDataFetchEnabled: (state, action) => {
      state.dataFetchEnabled = action.payload;
    },
    setDatakey4EmailEditor: (state, action) => {
      state.datakey4EmailEditor = action.payload;
    },
  },
});

export const getCurrentData = (state) => state.mJAI.present;
export const getJourneyData = (state) => state.mJAI.present.journeyData;
export const getFocusedJourney = (state) => state.mJAI.present.focusedJourney;
export const getJAISelectedNode = (state) => state.mJAI.present.selectedNode;
export const getJAISelectedBotID = (state) => state.mJAI.present.selectedBotId;
export const getJAISelectedVariantNode = (state) => state.mJAI.present.selectedVariantNode;
export const getJourneyActionItems = (state) => state.mJAI.present.actionItems;
export const getAllWhens = (state) => state.mJAI.present.allWhens;
export const getAllThens = (state) => state.mJAI.present.allThens;
export const getAllWhensWithCategories = (state) => state.mJAI.present.allWhensWithCategories;
export const getAllThensWithCategories = (state) => state.mJAI.present.allThensWithCategories;
export const getDeletedBotIds = (state) => state.mJAI.present.deletedBotIds;
export const getFocusedElementId = (state) => state.mJAI.present.focusedElementId;
export const getPastStates = (state) => state.mJAI.past;
export const getFutureStates = (state) => state.mJAI.future;
export const getShowTriggerList = (state) => state.mJAI.showTriggerList;
export const getShowHistoryPannel = (state) => state.mJAI.showHistoryPanel;
export const getAllMJAITemplates = (state) => state.mJAI.templates;
export const getLifecycleMJAITemplates = (state) => state.mJAI.templates?.lifecycle ?? [];
export const getReactiveMJAITemplates = (state) => state.mJAI.templates?.reactive ?? [];
export const getAlertMJAITemplates = (state) => state.mJAI.templates?.alert ?? [];
export const getIsAngelAI = (state) => state.mJAI.isAngelAI;
export const getOrgId = (state) => state.mJAI.orgId;
export const getOrgLevel = (state) => state.mJAI.orgLevel;
export const getMacroKey = (state) => state.mJAI.macroKey;
export const getDataFetchEnabled = (state) => state.mJAI.dataFetchEnabled;
export const getDatakey4EmailEditor = (state) => state.mJAI.datakey4EmailEditor;

export const {
  setJourneyData,
  setFocusedJourney,
  setJAISelectedNode,
  setJAISelectedVariantNode,
  setJourneyActionItems,
  redo,
  undo,
  toggleShowTriggerList,
  toggleShowHistoryPannel,
  setTemplates,
  setSelectedBotId,
  setIsAngelAI,
  setDeletedBotIds,
  setOrgId,
  setOrgLevel,
  setMacroKey,
  setFocusedElementId,
  setDataFetchEnabled,
  setDatakey4EmailEditor,
} = mJAISlice.actions;

export default mJAISlice.reducer;
