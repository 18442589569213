import React from "react";

function RocketIcon({ className = "text-[#999999]", size = 28 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      fill="none"
      viewBox="0 0 28 28"
      className={className}
    >
      <path
        stroke="#26BBED"
        strokeMiterlimit="10"
        d="M17.053 6.487l4.869 4.869M15.08 7.517c-2.844-1.03-5.812-.414-8.312 2.893L9.37 13.02M15.41 19.057l2.608 2.603c3.307-2.5 3.923-5.469 2.893-8.313"
      ></path>
      <path
        stroke="#26BBED"
        strokeLinecap="square"
        strokeMiterlimit="10"
        d="M13.018 20.41l-5-5S12.076 5.593 23.428 5c-.642 11.303-10.41 15.41-10.41 15.41z"
      ></path>
      <path
        stroke="#26BBED"
        strokeLinecap="square"
        strokeMiterlimit="10"
        d="M15.83 14.16a1.562 1.562 0 100-3.125 1.562 1.562 0 000 3.125zM6.067 19.71a1.875 1.875 0 012.651 2.65C7.986 23.094 5 23.429 5 23.429s.334-2.987 1.067-3.719z"
      ></path>
    </svg>
  );
}

export default RocketIcon;
