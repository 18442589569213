import { fetchQuery } from "relay-runtime";
import environment from "environments/environment";

import getAlertsEmailsQuery from "./queries/getAlertsEmails";

const getAlertsEmails = (orgId, propertyId) => {
  return fetchQuery(environment, getAlertsEmailsQuery, { orgId, propertyId });
};

export { getAlertsEmails };
