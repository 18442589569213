import graphql from "babel-plugin-relay/macro";

export default graphql`
  query getaccountsQuery($orgId: ID!, $search: String, $readOnly: Boolean) {
    accounts(orgId: $orgId, search: $search, readOnly: $readOnly) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          name
          email
          role {
            name
          }
          authorizedRegions {
            name
          }
          authorizedSuborgs {
            name
          }
        }
      }
    }
  }
`;
