import React from "react";
import PropTypes from "prop-types";

import FormikField from "./formik_field";

import HylyField from "./HylyField";
import HylySelectField from "./HylySelectField";
import HylySelectorField from "./HylySelectorField";

const HylyFormikField = ({ label, name, type, no_label, multiline, ...props }) => {
  function getComponent(variant) {
    switch (variant) {
      case "top":
      case "left":
      case "outlined":
        return HylyField;
      case "select":
      case "select-checkbox":
        return HylySelectField;
      case "selector-checkbox":
      case "selector-radio":
        return HylySelectorField;
      default:
        return HylyField;
    }
  }

  return (
    <FormikField
      name={name}
      label={no_label ? undefined : label || type}
      Component={getComponent(props.variant)}
      type={type}
      multiline={props.variant !== "select" ? multiline : undefined}
      {...props}
    />
  );
};

HylyFormikField.propTypes = {
  variant: PropTypes.oneOf([
    "array",
    "top",
    "left",
    "outlined",
    "select",
    "select-checkbox",
    "selector-checkbox",
    "selector-radio",
  ]).isRequired,
  field: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["email", "text", "phone", "url", "number", "price", "address", "size"]),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
  error: PropTypes.string,
  required: PropTypes.bool,
};

HylyFormikField.defaultProps = {
  variant: "top",
  field: "text",
};

export default HylyFormikField;

export { create_validation } from "./validation";
