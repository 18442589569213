/**
 * @flow
 * @relayHash e6f9510cc19e3f06d4eea6bb98347a8f
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getOfferByPropertiesQueryVariables = {|
  orgId?: ?string,
  propertyIds?: ?$ReadOnlyArray<string>,
  startAt?: ?string,
  endAt?: ?string,
  concession?: ?$ReadOnlyArray<string>,
|};
export type getOfferByPropertiesQueryResponse = {|
  +offerByProperties: $ReadOnlyArray<{|
    +campaignCount: ?string,
    +id: string,
    +propertyId: ?string,
    +propertyName: ?string,
  |}>
|};
export type getOfferByPropertiesQuery = {|
  variables: getOfferByPropertiesQueryVariables,
  response: getOfferByPropertiesQueryResponse,
|};
*/


/*
query getOfferByPropertiesQuery(
  $orgId: ID
  $propertyIds: [String!]
  $startAt: String
  $endAt: String
  $concession: [String!]
) {
  offerByProperties(orgId: $orgId, propertyIds: $propertyIds, startAt: $startAt, endAt: $endAt, concession: $concession) {
    campaignCount
    id
    propertyId
    propertyName
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "orgId",
    "type": "ID",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "propertyIds",
    "type": "[String!]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "startAt",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "endAt",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "concession",
    "type": "[String!]",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "offerByProperties",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "concession",
        "variableName": "concession"
      },
      {
        "kind": "Variable",
        "name": "endAt",
        "variableName": "endAt"
      },
      {
        "kind": "Variable",
        "name": "orgId",
        "variableName": "orgId"
      },
      {
        "kind": "Variable",
        "name": "propertyIds",
        "variableName": "propertyIds"
      },
      {
        "kind": "Variable",
        "name": "startAt",
        "variableName": "startAt"
      }
    ],
    "concreteType": "OfferByProperties",
    "plural": true,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "campaignCount",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "propertyId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "propertyName",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getOfferByPropertiesQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "getOfferByPropertiesQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "getOfferByPropertiesQuery",
    "id": null,
    "text": "query getOfferByPropertiesQuery(\n  $orgId: ID\n  $propertyIds: [String!]\n  $startAt: String\n  $endAt: String\n  $concession: [String!]\n) {\n  offerByProperties(orgId: $orgId, propertyIds: $propertyIds, startAt: $startAt, endAt: $endAt, concession: $concession) {\n    campaignCount\n    id\n    propertyId\n    propertyName\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5a710eaca46b0403788af563de2209d4';
module.exports = node;
