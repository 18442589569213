import React from "react";
import { Button } from "storybookComponents";
import { SelectorComponent } from "components";
import Field from "content/hyRaf/Campaign/ScheduleInvite/multiTagField.js";
import { scheduleReportMutation, getScheduledReports, scheduleReportDelete } from "relay/main";
import { getErrorMessage, checkUncatchableError } from "environments/uncatchableErrorStore";
import { dateFilters, frequencyFilterValue, frequencyDayFilters, rangeFilters } from "./common";
import TabBar from "storybookComponents/TabBar";
import Icon from "storybookComponents/Icon";
import { DeleteScheduledEmail } from ".";
import TextField from "components/InputField";
import Typography from "storybookComponents/Typography";
import {
  combineLists,
  filterRegionsByIds,
  groupByRegions,
  groupedSelectedProperties,
} from "content/utils/commonParsing";
import { getSuborgDetails, getStates4OrgSuborg } from "redux/slices";
import { useSelector } from "react-redux";
import { getTransformUrl } from "common";

const scheduledCardTitle = [
  "1Abe Residential - Drips - By Property - Last 3 months",
  "1Abe Residential - Drips - By Property - Last 30 days",
];
const ScheduleReport = ({
  classes,
  onClose,
  showNotification,
  setScheduledEmails,
  history,
  ...props
}) => {
  const [values, setValues] = React.useState({
    pdf: true,
    csv: true,
    emails: [],
  });
  const {
    location: { search },
  } = history;
  const subOrgDetails = useSelector(getSuborgDetails);
  const orgs = useSelector(getStates4OrgSuborg);

  const [frequency, setFrequency] = React.useState(["weekly"]);
  const [dayFrequency, setDayFrequency] = React.useState(["monday"]);
  const [range, setRange] = React.useState(["last_30_days"]);
  const [tabIndex, setTabIndex] = React.useState(0);
  const [delSchedulEmail, setDelSchedulEmail] = React.useState({});
  const [scheduledReports, setScheduledReports] = React.useState([]);
  const [reportName, setReportName] = React.useState("");
  const [buttonState, setButtonState] = React.useState(true);
  const [buttonText, setButtonText] = React.useState("Start Schedule");
  const [objectToUpdate, setObjectToUpdate] = React.useState(null);
  const [changesDetected, setchangesDetected] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [propertyOptions, setPropertyOptions] = React.useState(props.allPropertyIds);
  const [properties, setProperties] = React.useState(props.propertyIds);
  const [propertiesParams, setPropertiesParams] = React.useState([]);
  const [regions, setRegions] = React.useState(props.regionIds);

  const getUrl = () => {
    let params = new URLSearchParams(search);
    const rangeParams = rangeFilters.filter((d) => d.id == range);
    const url =
      process.env.REACT_APP_hostName +
      history.location.pathname +
      "?&properties=" +
      params.get("properties") +
      "&regions=" +
      params.get("regions") +
      "&label=" +
      rangeParams[0].value;
    return url;
  };

  const handleSchedule = async () => {
    setButtonState(false);
    try {
      let resp;
      if (objectToUpdate) {
        resp =
          props.allRegionsIds.length !== regions.length && props.type === "property_blast"
            ? await scheduleReportMutation({
                srId: objectToUpdate,
                orgId: props.orgId,
                type: props.type,
                day: frequency[0] === "daily" ? "" : dayFrequency[0],
                recipients: values.emails,
                range: range[0],
                frequency: frequency[0],
                propertyIds: combineLists(properties),
                reportUrl: getUrl(),
                reportType: "jai_report",
                name: reportName,
                regionIds: regions,
              })
            : await scheduleReportMutation({
                srId: objectToUpdate,
                orgId: props.orgId,
                type: props.type,
                day: frequency[0] === "daily" ? "" : dayFrequency[0],
                recipients: values.emails,
                range: range[0],
                frequency: frequency[0],
                propertyIds: combineLists(properties),
                reportUrl: getUrl(),
                reportType: "jai_report",
                name: reportName,
              });
      } else {
        resp = await scheduleReportMutation({
          orgId: props.orgId,
          type: props.type,
          day: frequency[0] === "daily" ? "" : dayFrequency[0],
          recipients: values.emails,
          range: range[0],
          frequency: frequency[0],
          propertyIds: combineLists(properties),
          reportUrl: getUrl(),
          reportType: "jai_report",
          name: reportName,
          regionIds: regions,
        });
      }
      if (resp) {
        showNotification(
          objectToUpdate ? "Email updated successfully" : "Email scheduled successfully",
          "success"
        );
        onClose();
      } else {
        showNotification("Something went wrong.", "error");
      }
    } catch {
      const uncachebleError = getErrorMessage(checkUncatchableError("scheduleReportMutation"));
      console.log("err", uncachebleError);
      showNotification(uncachebleError || "Something went wrong.", "error");
      onClose();
    }
    setButtonState(true);
  };

  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.checked,
    });
  };

  const setFieldValue = (keyName, emails) => {
    setchangesDetected(true);
    setValues({
      ...values,
      emails,
    });
  };

  const getCustomLabelForFrequency = (selected, count, name, open) => {
    // if (!name) {
    //   return <div className={`${classes.placeholder} ${open && classes.openOpacity}`}>Contact</div>;
    // }
    return (
      <div className={`${classes.selectedPlaceholder} ${open && classes.openOpacity}`}>{name}</div>
    );
  };

  const getCustomLabelForRegions = (selected, count, name, open) => {
    if (selected.length === 0) {
      return <div>Select Regions</div>;
    }
    if (selected.length === 1) {
      return <div>{props.allRegionsIds.filter((d) => d.id == selected[0])[0].value}</div>;
    }
    if (selected.length === props.allRegionsIds.length) {
      return <div>All Regions ({selected.length})</div>;
    } else {
      return (
        <div>
          {props.allRegionsIds.filter((d) => d.id == selected[0]).length > 0 &&
            props.allRegionsIds.filter((d) => d.id === selected[0])[0].value}
          ,<b className="mr-2 font-semibold"> +{selected.length - 1} more</b>
        </div>
      );
    }
  };

  const getCustomCountForProperties = (count, selected) => {
    return combineLists(selected).length;
  };

  const getCustomLabelForProperties = (selected, count, name, open) => {
    const selectedVal = combineLists(selected);
    if (selectedVal.length === 0) {
      return <div>Select Properties</div>;
    }
    if (selectedVal.length === 1) {
      return (
        <div>
          {combineLists(props.allPropertyIds).filter((d) => d.id == selectedVal[0])[0].value}
        </div>
      );
    }
    if (selectedVal.length === combineLists(props.allPropertyIds).length) {
      return <div>All Properties ({selectedVal.length})</div>;
    } else {
      return (
        <div>
          {combineLists(props.allPropertyIds).some((d) => d.id === selectedVal[0]) &&
            combineLists(props.allPropertyIds).find((d) => d.id === selectedVal[0]).value}
          ,<b className="mr-2 font-semibold"> +{selectedVal.length - 1} more</b>
        </div>
      );
    }
  };

  const handleFrequencyChange = (values) => {
    setchangesDetected(true);
    setFrequency(values);
    setDayFrequency(values[0] == "weekly" ? ["monday"] : ["1"]);
  };

  const handleChangeRegion = (value) => {
    setLoading(true);
    setchangesDetected(true);
    setRegions(value);
    const properties = groupByRegions(orgs, subOrgDetails.org.id);
    const filteredProperties = properties.filter(
      (obj) => value.includes(obj.id) || obj.list.some((item) => value.includes(item.id))
    );
    const filteredSelectedProperties = filteredProperties.map((item) => ({
      id: item.id,
      list: item.list.map((idx) => idx.id),
    }));
    setPropertyOptions(filteredProperties);
    setProperties(filteredSelectedProperties);
    setTimeout(() => setLoading(false), 100);
  };

  const handleChangeProperty = async (value) => {
    const resp = await getTransformUrl(combineLists(value).toString());
    setPropertiesParams([resp.transformUrl.out]);
    setchangesDetected(true);
    setProperties(value);
  };

  const handleFrequencyDayChange = (values) => {
    setchangesDetected(true);
    setDayFrequency(values);
  };
  const handleChangeRangeChange = (values) => {
    setchangesDetected(true);
    setRange(values);
  };

  const handleDeleteItem = (item, index) => {
    setDelSchedulEmail({ item: item, index: index });
  };

  const handleDeleteCallback = async (v, cancel) => {
    if (cancel) return setDelSchedulEmail();
    setButtonState(false);
    try {
      const response = await scheduleReportDelete({
        srId: delSchedulEmail.item.id,
        orgId: props.orgId,
      });
      if (response && response.scheduleReportDelete.success) {
        let data = [...scheduledReports];
        data.splice(delSchedulEmail.index, 1);
        setScheduledReports(data);
        showNotification("Report deleted successfully", "success");
      } else {
        showNotification("Something went wrong", "error");
        setDelSchedulEmail();
      }
    } catch {
      const uncachebleError = getErrorMessage(checkUncatchableError("deleteRecentPageMutation"));
      console.log("err", uncachebleError);
      showNotification(uncachebleError || "Something went wrong.", "error");
      setDelSchedulEmail();
    }
    setDelSchedulEmail();
    setButtonState(true);
  };

  const getScheduledReportsData = async () => {
    try {
      const response = await getScheduledReports(props.orgId, props.type);
      if (response) {
        setScheduledReports(response.scheduledReports);
      } else {
        showNotification("Something went wrong.", "error");
      }
    } catch {
      const uncachebleError = getErrorMessage(checkUncatchableError("getRecentPagesQuery"));
      console.log("err", uncachebleError);
      showNotification(uncachebleError || "Something went wrong.", "error");
    }
  };

  const handleDetail = (item) => {
    setchangesDetected(false);
    setFrequency([item.frequency]);
    setDayFrequency([item.day]);
    setRange([item.range]);
    setReportName(item.name);
    setValues({ emails: item.recipients });
    handleTabChange(0, true);
    setObjectToUpdate(item.id);
    setButtonText("Update Schedule");

    const propertiesData = groupByRegions(orgs, subOrgDetails.org.id);
    const filteredPropertiesData = groupedSelectedProperties(propertiesData, item.propertyIds);
    const filteredRegions = filterRegionsByIds(propertiesData, item.propertyIds);

    setProperties(filteredPropertiesData);
    setRegions(filteredRegions);
  };

  const init = async () => {
    let params = new URLSearchParams(search);
    let propertiesParams = [];
    if (params.get("properties")) {
      propertiesParams = params.get("properties").split(",");
    } else {
      const resp = await getTransformUrl(combineLists(props.propertyIds).toString());
      propertiesParams = [resp.transformUrl.out];
    }
    setPropertiesParams(propertiesParams);
  };

  React.useEffect(() => {
    if (props.propertyIds) init();
  }, [props.propertyIds]);

  React.useEffect(() => {
    getScheduledReportsData();
  }, []);

  const handleNameChange = (name) => {
    setchangesDetected(true);
    setButtonState(/[^\s-]/.test(name.target.value));
    setReportName(name.target.value);
  };

  const renderContent = () => {
    switch (tabIndex) {
      case 0:
        return (
          <div className={classes.scheduleContainer}>
            <div>
              <Typography type="subtitle" color="black" font_weight="600" className={classes.label}>
                Report Name
              </Typography>
              <TextField onChange={handleNameChange} value={reportName} />
            </div>
            <Field
              values={values}
              setFieldValue={setFieldValue}
              placeholder="example@example.com"
              keyName="emails"
              label="Email Address"
              className={{
                container: classes.emailFieldContainer,
                label: classes.label,
              }}
            />
            <div className={classes.subtitle}>Report will be sent out at 8AM EST</div>
            {props.propertyIds && (
              <div className={classes.selectFieldContainer}>
                <div className={classes.label}>Region(s)</div>
                <SelectorComponent
                  type="selector"
                  optionValue={props.allRegionsIds}
                  placeholder="Region"
                  searchPlaceHolder="Enter region name here..."
                  onChange={handleChangeRegion}
                  multiple={true}
                  singleSelect={false}
                  value={regions}
                  selectAllText="Select all regions"
                  getCustomLabel={getCustomLabelForRegions}
                />
              </div>
            )}
            {props.propertyIds && (
              <div className={classes.selectFieldContainer}>
                <div className={classes.label}>Property(s)</div>
                <SelectorComponent
                  type="selector"
                  optionValue={propertyOptions}
                  placeholder="Property"
                  searchPlaceHolder="Enter property name here..."
                  onChange={handleChangeProperty}
                  multiple={true}
                  singleSelect={false}
                  value={properties}
                  selectAllText="Select all properties"
                  getCustomLabel={getCustomLabelForProperties}
                  getCustomCount={getCustomCountForProperties}
                  groupBy={true}
                />
              </div>
            )}
            <div className={classes.selectFieldContainer}>
              <div className={classes.label}>Frequency of Reports</div>
              <SelectorComponent
                optionValue={frequencyFilterValue}
                onChange={handleFrequencyChange}
                singleSelect={true}
                value={frequency}
                getCustomLabel={getCustomLabelForFrequency}
                disableSearch={true}
                disableAllSelect={true}
                height="auto"
                width={436}
                skipMargin
              />
            </div>
            {frequency == "daily" ? null : (
              <div className={classes.selectFieldContainer}>
                <div className={classes.label}>Send Every:</div>
                {frequency == "weekly" ? (
                  <SelectorComponent
                    optionValue={frequencyDayFilters}
                    onChange={handleFrequencyDayChange}
                    singleSelect={true}
                    value={dayFrequency}
                    getCustomLabel={getCustomLabelForFrequency}
                    disableSearch={true}
                    disableAllSelect={true}
                    height={145}
                    width={436}
                    skipMargin
                  />
                ) : (
                  <SelectorComponent
                    optionValue={dateFilters}
                    onChange={handleFrequencyDayChange}
                    singleSelect={true}
                    value={dayFrequency}
                    getCustomLabel={getCustomLabelForFrequency}
                    disableSearch={true}
                    disableAllSelect={true}
                    height={145}
                    width={436}
                    skipMargin
                  />
                )}
              </div>
            )}
            <div className={classes.selectFieldContainer}>
              <div className={classes.label}>Report Date Range</div>
              <SelectorComponent
                optionValue={rangeFilters}
                onChange={handleChangeRangeChange}
                singleSelect={true}
                value={range}
                getCustomLabel={getCustomLabelForFrequency}
                disableSearch={true}
                disableAllSelect={true}
                height="auto"
                width={436}
                skipMargin
              />
            </div>
            <div className={classes.bottomFlexBox}>
              <Button className={classes.btnStyle} variant="tertiary" onClick={onClose}>
                Cancel
              </Button>
              <Button
                className={classes.btnStyle}
                state={
                  values.emails.length > 0 && reportName && buttonState && changesDetected
                    ? "active"
                    : "inactive"
                }
                onClick={props.schedule ? handleSchedule : onClose}
              >
                {buttonText}
              </Button>
            </div>
          </div>
        );
      case 1:
        return (
          <div>
            <div className={classes.scheduledPadding}>Select from already created Schedules</div>
            <div className={classes.cardContainer}>
              <div className={classes.cardView}>
                {scheduledReports.map((item, index) => (
                  <div className={classes.scheduledCard} key={index}>
                    <span className={classes.reportDetail} onClick={handleDetail.bind(this, item)}>
                      {item.name}
                    </span>
                    <Icon
                      span_class={classes.deleteIcon}
                      onClick={handleDeleteItem.bind(this, item, index)}
                      type="icon-bin"
                      size="sm"
                    />
                  </div>
                ))}
              </div>
              <div className={classes.scheduledPadding}>
                <div className={classes.italicStyle}>Or</div>
                <Button
                  className={classes.btnStyle}
                  variant="primary"
                  onClick={handleTabChange.bind(this, 0)}
                >
                  + Create New Schedule
                </Button>
              </div>
            </div>
          </div>
        );
    }
  };

  const handleTabChange = (index, clearField) => {
    if (clearField) {
      setTabIndex(index);
      setScheduledEmails(index === 1 ? true : false);
    } else {
      setTabIndex(index);
      setScheduledEmails(index === 1 ? true : false);
      setFrequency(["weekly"]);
      setDayFrequency(["monday"]);
      setRange(["last_30_days"]);
      setReportName("");
      setProperties(props.propertyIds);
      setRegions(props.propertyIds);
      setValues({
        pdf: true,
        csv: true,
        emails: [],
      });
      setObjectToUpdate(null);
      setButtonText("Start Schedule");
    }
  };

  if (loading) {
    return null;
  }

  return (
    <>
      {delSchedulEmail?.item ? (
        <DeleteScheduledEmail
          classes={classes}
          delSchedulEmail={delSchedulEmail}
          handleDeleteCallback={handleDeleteCallback}
          buttonState={buttonState}
        />
      ) : (
        <div>
          {/* <div className={classes.header}>Select Format of Email Report</div>
      <FormControlLabel
        control={<Checkbox checked={values.pdf} name="pdf" onChange={handleChange} />}
        label="PDF"
      />
      <br />
      <FormControlLabel
        control={<Checkbox checked={values.csv} name="csv" onChange={handleChange} />}
        label="CSV"
      />
      <br />
      <div className={classes.subtitle}>Select atleast one format</div> */}
          <div
            style={{ borderBottom: "1px solid #ccc" }}
            className={tabIndex == 0 ? classes.tabMargin : classes.drawerTabMargin}
          >
            <TabBar
              tabs={["Schedule Email", "Scheduled Emails"]}
              onChange={handleTabChange}
              index={tabIndex}
            />
          </div>
          {renderContent()}
        </div>
      )}
    </>
  );
};

export default ScheduleReport;
