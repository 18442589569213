/**
 * @flow
 * @relayHash 078d480ad61e19fa43760d35bf17afc3
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getGptHistoryByIdQueryVariables = {|
  id: string
|};
export type getGptHistoryByIdQueryResponse = {|
  +retrieveGptHistory: {|
    +id: string,
    +tokenCount: ?string,
    +gptCost: ?string,
    +gptModel: ?string,
    +promptId: ?string,
    +promptTemplate: ?string,
    +uxFields: ?any,
    +promptDescription: ?string,
    +generationTime: ?string,
    +title: ?{|
      +id: string,
      +value: ?string,
      +feedback: ?{|
        +liked: ?boolean
      |},
      +fieldType: ?string,
      +tokenCount: ?string,
      +generationTime: ?string,
      +regenerated: ?boolean,
      +humanReplaced: ?boolean,
    |},
    +disclaimer: ?{|
      +id: string,
      +value: ?string,
      +feedback: ?{|
        +liked: ?boolean
      |},
      +fieldType: ?string,
      +tokenCount: ?string,
      +generationTime: ?string,
      +regenerated: ?boolean,
      +humanReplaced: ?boolean,
    |},
    +subTitle: ?{|
      +id: string,
      +value: ?string,
      +feedback: ?{|
        +liked: ?boolean
      |},
      +fieldType: ?string,
      +tokenCount: ?string,
      +generationTime: ?string,
      +regenerated: ?boolean,
      +humanReplaced: ?boolean,
    |},
    +preheader: ?{|
      +id: string,
      +value: ?string,
      +feedback: ?{|
        +liked: ?boolean
      |},
      +fieldType: ?string,
      +tokenCount: ?string,
      +generationTime: ?string,
      +regenerated: ?boolean,
      +humanReplaced: ?boolean,
    |},
    +subject: ?{|
      +id: string,
      +value: ?string,
      +feedback: ?{|
        +liked: ?boolean
      |},
      +fieldType: ?string,
      +tokenCount: ?string,
      +generationTime: ?string,
      +regenerated: ?boolean,
      +humanReplaced: ?boolean,
    |},
    +body: ?{|
      +id: string,
      +value: ?string,
      +feedback: ?{|
        +liked: ?boolean
      |},
      +fieldType: ?string,
      +tokenCount: ?string,
      +generationTime: ?string,
      +regenerated: ?boolean,
      +humanReplaced: ?boolean,
    |},
    +ctaText: ?{|
      +id: string,
      +value: ?string,
      +feedback: ?{|
        +liked: ?boolean
      |},
      +fieldType: ?string,
      +tokenCount: ?string,
      +generationTime: ?string,
      +regenerated: ?boolean,
      +humanReplaced: ?boolean,
    |},
  |}
|};
export type getGptHistoryByIdQuery = {|
  variables: getGptHistoryByIdQueryVariables,
  response: getGptHistoryByIdQueryResponse,
|};
*/


/*
query getGptHistoryByIdQuery(
  $id: String!
) {
  retrieveGptHistory(id: $id) {
    id
    tokenCount
    gptCost
    gptModel
    promptId
    promptTemplate
    uxFields
    promptDescription
    generationTime
    title {
      id
      value
      feedback {
        liked
        id
      }
      fieldType
      tokenCount
      generationTime
      regenerated
      humanReplaced
    }
    disclaimer {
      id
      value
      feedback {
        liked
        id
      }
      fieldType
      tokenCount
      generationTime
      regenerated
      humanReplaced
    }
    subTitle {
      id
      value
      feedback {
        liked
        id
      }
      fieldType
      tokenCount
      generationTime
      regenerated
      humanReplaced
    }
    preheader {
      id
      value
      feedback {
        liked
        id
      }
      fieldType
      tokenCount
      generationTime
      regenerated
      humanReplaced
    }
    subject {
      id
      value
      feedback {
        liked
        id
      }
      fieldType
      tokenCount
      generationTime
      regenerated
      humanReplaced
    }
    body {
      id
      value
      feedback {
        liked
        id
      }
      fieldType
      tokenCount
      generationTime
      regenerated
      humanReplaced
    }
    ctaText {
      id
      value
      feedback {
        liked
        id
      }
      fieldType
      tokenCount
      generationTime
      regenerated
      humanReplaced
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "tokenCount",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "gptCost",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "gptModel",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "promptId",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "promptTemplate",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "uxFields",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "promptDescription",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "generationTime",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "value",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "liked",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fieldType",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "regenerated",
  "args": null,
  "storageKey": null
},
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "humanReplaced",
  "args": null,
  "storageKey": null
},
v16 = [
  (v2/*: any*/),
  (v11/*: any*/),
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "feedback",
    "storageKey": null,
    "args": null,
    "concreteType": "Feedback",
    "plural": false,
    "selections": [
      (v12/*: any*/)
    ]
  },
  (v13/*: any*/),
  (v3/*: any*/),
  (v10/*: any*/),
  (v14/*: any*/),
  (v15/*: any*/)
],
v17 = [
  (v2/*: any*/),
  (v11/*: any*/),
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "feedback",
    "storageKey": null,
    "args": null,
    "concreteType": "Feedback",
    "plural": false,
    "selections": [
      (v12/*: any*/),
      (v2/*: any*/)
    ]
  },
  (v13/*: any*/),
  (v3/*: any*/),
  (v10/*: any*/),
  (v14/*: any*/),
  (v15/*: any*/)
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getGptHistoryByIdQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "retrieveGptHistory",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "GptHistory",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "title",
            "storageKey": null,
            "args": null,
            "concreteType": "GptHistoryField",
            "plural": false,
            "selections": (v16/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "disclaimer",
            "storageKey": null,
            "args": null,
            "concreteType": "GptHistoryField",
            "plural": false,
            "selections": (v16/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "subTitle",
            "storageKey": null,
            "args": null,
            "concreteType": "GptHistoryField",
            "plural": false,
            "selections": (v16/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "preheader",
            "storageKey": null,
            "args": null,
            "concreteType": "GptHistoryField",
            "plural": false,
            "selections": (v16/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "subject",
            "storageKey": null,
            "args": null,
            "concreteType": "GptHistoryField",
            "plural": false,
            "selections": (v16/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "body",
            "storageKey": null,
            "args": null,
            "concreteType": "GptHistoryField",
            "plural": false,
            "selections": (v16/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "ctaText",
            "storageKey": null,
            "args": null,
            "concreteType": "GptHistoryField",
            "plural": false,
            "selections": (v16/*: any*/)
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "getGptHistoryByIdQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "retrieveGptHistory",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "GptHistory",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "title",
            "storageKey": null,
            "args": null,
            "concreteType": "GptHistoryField",
            "plural": false,
            "selections": (v17/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "disclaimer",
            "storageKey": null,
            "args": null,
            "concreteType": "GptHistoryField",
            "plural": false,
            "selections": (v17/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "subTitle",
            "storageKey": null,
            "args": null,
            "concreteType": "GptHistoryField",
            "plural": false,
            "selections": (v17/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "preheader",
            "storageKey": null,
            "args": null,
            "concreteType": "GptHistoryField",
            "plural": false,
            "selections": (v17/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "subject",
            "storageKey": null,
            "args": null,
            "concreteType": "GptHistoryField",
            "plural": false,
            "selections": (v17/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "body",
            "storageKey": null,
            "args": null,
            "concreteType": "GptHistoryField",
            "plural": false,
            "selections": (v17/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "ctaText",
            "storageKey": null,
            "args": null,
            "concreteType": "GptHistoryField",
            "plural": false,
            "selections": (v17/*: any*/)
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "getGptHistoryByIdQuery",
    "id": null,
    "text": "query getGptHistoryByIdQuery(\n  $id: String!\n) {\n  retrieveGptHistory(id: $id) {\n    id\n    tokenCount\n    gptCost\n    gptModel\n    promptId\n    promptTemplate\n    uxFields\n    promptDescription\n    generationTime\n    title {\n      id\n      value\n      feedback {\n        liked\n        id\n      }\n      fieldType\n      tokenCount\n      generationTime\n      regenerated\n      humanReplaced\n    }\n    disclaimer {\n      id\n      value\n      feedback {\n        liked\n        id\n      }\n      fieldType\n      tokenCount\n      generationTime\n      regenerated\n      humanReplaced\n    }\n    subTitle {\n      id\n      value\n      feedback {\n        liked\n        id\n      }\n      fieldType\n      tokenCount\n      generationTime\n      regenerated\n      humanReplaced\n    }\n    preheader {\n      id\n      value\n      feedback {\n        liked\n        id\n      }\n      fieldType\n      tokenCount\n      generationTime\n      regenerated\n      humanReplaced\n    }\n    subject {\n      id\n      value\n      feedback {\n        liked\n        id\n      }\n      fieldType\n      tokenCount\n      generationTime\n      regenerated\n      humanReplaced\n    }\n    body {\n      id\n      value\n      feedback {\n        liked\n        id\n      }\n      fieldType\n      tokenCount\n      generationTime\n      regenerated\n      humanReplaced\n    }\n    ctaText {\n      id\n      value\n      feedback {\n        liked\n        id\n      }\n      fieldType\n      tokenCount\n      generationTime\n      regenerated\n      humanReplaced\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e8465b903aa6d37fd80746585234ec87';
module.exports = node;
