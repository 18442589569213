/**
 * @flow
 * @relayHash 7c6e9e952109870b0223f363aee07ec3
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type deleteRecentPageInput = {|
  clientMutationId?: ?string,
  id: string,
|};
export type deleteRecentPageMutationVariables = {|
  input: deleteRecentPageInput
|};
export type deleteRecentPageMutationResponse = {|
  +deleteRecentPage: ?{|
    +recentPage: ?{|
      +id: string,
      +tenantId: ?string,
      +appProductId: ?string,
      +appModuleProductId: ?string,
      +pagePath: ?string,
      +pageName: ?string,
      +propertyName: ?string,
    |}
  |}
|};
export type deleteRecentPageMutation = {|
  variables: deleteRecentPageMutationVariables,
  response: deleteRecentPageMutationResponse,
|};
*/


/*
mutation deleteRecentPageMutation(
  $input: deleteRecentPageInput!
) {
  deleteRecentPage(input: $input) {
    recentPage {
      id
      tenantId
      appProductId
      appModuleProductId
      pagePath
      pageName
      propertyName
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "deleteRecentPageInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "deleteRecentPage",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "deleteRecentPagePayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "recentPage",
        "storageKey": null,
        "args": null,
        "concreteType": "RecentPage",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "tenantId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "appProductId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "appModuleProductId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "pagePath",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "pageName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "propertyName",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "deleteRecentPageMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "deleteRecentPageMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "deleteRecentPageMutation",
    "id": null,
    "text": "mutation deleteRecentPageMutation(\n  $input: deleteRecentPageInput!\n) {\n  deleteRecentPage(input: $input) {\n    recentPage {\n      id\n      tenantId\n      appProductId\n      appModuleProductId\n      pagePath\n      pageName\n      propertyName\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6aa1110947da7143eff5ec8de2ffdc57';
module.exports = node;
