import React from "react";

const ThreeDotIcon = ({ size = 5, className = "text-[#333333]" }) => {
  return (
    <svg
      className={className}
      width={size}
      viewBox="0 0 5 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.24984 8.41732C4.24984 7.49684 3.50364 6.75065 2.58317 6.75065C1.6627 6.75065 0.916504 7.49684 0.916504 8.41732C0.916504 9.33779 1.6627 10.084 2.58317 10.084C3.50364 10.084 4.24984 9.33779 4.24984 8.41732Z"
        fill="currentColor"
      />
      <path
        d="M4.24984 2.16732C4.24984 1.24684 3.50364 0.50065 2.58317 0.50065C1.6627 0.50065 0.916504 1.24684 0.916504 2.16732C0.916504 3.08779 1.6627 3.83398 2.58317 3.83398C3.50364 3.83398 4.24984 3.08779 4.24984 2.16732Z"
        fill="currentColor"
      />
      <path
        d="M4.24984 14.6673C4.24984 13.7468 3.50364 13.0007 2.58317 13.0007C1.6627 13.0007 0.916504 13.7468 0.916504 14.6673C0.916504 15.5878 1.6627 16.334 2.58317 16.334C3.50364 16.334 4.24984 15.5878 4.24984 14.6673Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ThreeDotIcon;
