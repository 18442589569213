import { getPropertyId } from "common/";

export const APP = "/:propertyId",
  HOME = APP + "/home",
  // Blast paths
  BLAST = APP + "/blast",
  TEMPLATES = APP + "/blast/templates",
  APPROVALS = APP + "/blast/approvals/:step?",
  BLAST_REPORTS_PROPERTY_AUDIT = APP + "/blast/reports/property-audit/:step?",
  BLAST_REPORTS_BY_PROPERTY = APP + "/blast/reports/by-property/:step?",
  BLAST_REPORTS_BY_EBLASTS = APP + "/blast/reports/by-blasts",
  BLAST_REPORTS_BY_EBLAST = APP + "/blast/reports/by-blast/:step?",
  BLAST_REPORTS_EBLASTS_ANALYTICS = APP + "/blast/reports/blasts-analytics",
  BLAST_REPORTS_EBLAST_ANALYTICS = APP + "/blast/reports/blasts-analytics/:step?",
  BLAST_REPORTS_EBLAST_FEEDBACK_REPORT_By_ORGS = APP + "/blast/reports/feedback-org-property",
  BLAST_REPORTS_EBLAST_FEEDBACK_REPORT_By_BLAST = APP + "/blast/reports/feedback-blast-type",
  BLAST_REPORTS_EBLAST_FEEDBACK_REPORT_By_FIELDS = APP + "/blast/reports/feedback-fields",
  BLAST_REPORTS_EBLAST_FEEDBACK_REPORT_By_FIELDS_Detials =
    APP + "/blast/reports/feedback-fields/:step",
  BLAST_REPORTS_EBLAST_FEEDBACK_REPORTS = APP + "/blast/reports/feedback-reports/:step",
  BLAST_REPORTS_MY_ANALYTICS = APP + "/blast/reports/my-analytics",
  BLAST_REPORTS_MY_FAVORITES = APP + "/blast/reports/my-favorites",
  BLAST_REPORTS_BLAST_AB = APP + "/blast/reports/blast-a-b",
  BLAST_REPORTS_BLAST_AB_VARIANT = APP + "/blast/reports/blast-a-b-variant/:step?",
  BLAST_REPORTS_BLAST_CONTACT_LIST = APP + "/blast/reports/blast-contact-list/:step?",
  BLAST_REPORTS_BY_EMAIL_PLATFORM = APP + "/blast/reports/by-email-platform/:step?",
  SETTINGS = APP + "/blast/settings/:step?",
  EDIT = APP + "/blast/:id/edit/:step?/:tab?",
  // Drips paths
  DRIPS_REPORTS_BY_PROPERTY = APP + "/drips/reports/by-property/:step?",
  DRIPS_REPORTS_BY_DRIPS = APP + "/drips/reports/by-drips",
  DRIPS_REPORTS_BY_DRIP = APP + "/drips/reports/by-drip/:step?",
  DRIPS_REPORTS_BY_EMAILS = APP + "/drips/reports/by-emails",
  DRIPS_REPORTS_BY_EMAIL = APP + "/drips/reports/by-email/:step?",
  DRIPS_REPORTS_EMAILS_ANALYTICS = APP + "/drips/reports/emails-analytics",
  DRIPS_REPORTS_EMAIL_ANALYTICS = APP + "/drips/reports/email-analytics/:step?",
  DRIPS_REPORTS_DRIPS_CONTACT_LIST = APP + "/drips/reports/drips-contact-list/:step?",
  DRIPS_REPORTS_BY_EMAIL_PLATFORM = APP + "/drips/reports/by-platform/:step?",
  DRIPS_REPORTS_MY_ANALYTICS = APP + "/drips/reports/my-favorites",
  ANGELS = APP + "/drips/:angelId/angels",
  DRIPS_EDIT = APP + "/drips/:angelId/:emailId/edit/:step?/:tab?",
  DRIPS_SETTINGS = APP + "/drips/success_hub",
  //Alerts paths
  ALERTS_REPORTS_BY_PROPERTY = APP + "/alerts/reports/by-property/:step?",
  ALERTS_REPORTS_BY_ALERTS = APP + "/alerts/reports/by-alerts",
  ALERTS_REPORTS_BY_ALERT = APP + "/alerts/reports/by-alert/:step?",
  ALERTS_REPORTS_BY_EMAILS = APP + "/alerts/reports/by-emails",
  ALERTS_REPORTS_BY_EMAIL = APP + "/alerts/reports/by-email/:step?",
  ALERTS_SETTINGS = APP + "/alerts/success_hub",
  // ALERTS_REPORTS_EMAILS_ANALYTICS = APP + "/alerts/reports/emails-analytics",
  ALERTS_REPORTS_EMAIL_ANALYTICS = APP + "/alerts/reports/email-analytics/:step?",
  ALERTS_REPORTS_DRIPS_CONTACT_LIST = APP + "/alerts/reports/alerts-contact-list/:step?",
  ALERTS_REPORTS_BY_EMAIL_PLATFORM = APP + "/alerts/reports/by-platform/:step?",
  ALERTS_REPORTS_MY_ANALYTICS = APP + "/alerts/reports/my-analytics",
  ALERTS_ANGELS = APP + "/alerts/:angelId/angels",
  // ALERTS_EDIT = APP + "/alerts/:angelId/:emailId/edit/:step?/:tab?",
  // Offers path
  OFFERS = APP + "/offers",
  OFFERS_EDIT = APP + "/offer/:id?/:step?",
  OFFERS_REPORT_PROPERTY = APP + "/offers/by-property/:step?",
  OFFERS_REPORT_CAMPAIGNS = APP + "/offers/by-campaigns/:step?",
  OFFERS_REPORT_CAMPAIGN = APP + "/offers/by-campaign/:step?",
  OFFERS_REPORT_CONTACT = APP + "/offers/by-contact/:step?",
  OFFERS_REPORT_ANALYTICS_CAMPAIGNS = APP + "/offers/by-analytics/:step?",
  OFFERS_REPORT_ANALYTICS_CAMPAIGN = APP + "/offers/analytics-by-campaign/:step?",
  OFFERS_REPORT_ANALYTICS = APP + "/offers/my-analytics",
  // success center paths
  SUCCESS_BLAST_ANALYSIS = APP + "/success-center-blast/analysis",
  SUCCESS_BLAST_REPORTS = APP + "/success-center-blast/reports",
  SUCCESS_BLAST_GOALS = APP + "/success-center-blast/goals",
  SUCCESS_BLAST_PROPERTY_AUDIT = APP + "/success-center-blast/property-audit/:step?",
  SUCCESS_BLAST_PROPERTY_BLAST = APP + "/success-center-blast/property-blast/:step?",
  SUCCESS_DRIPS_ANALYSIS = APP + "/success-center-drips/analysis",
  SUCCESS_DRIPS_REPORTS = APP + "/success-center-drips/reports",
  SUCCESS_DRIPS_GOALS = APP + "/success-center-drips/goals",
  SUCCESS_DRIPS_BY_PROPERTY = APP + "/success-center-drips/by-property/:step?",
  // Events path
  EVENTS = APP + "/events",
  CREATE_EVENT = APP + "/event/:actiontype/:id",
  TEMPLATES_EVENT = APP + "/events/templates",
  PREVIEW = "preview",
  PUBLISH = "publish",
  EDIT_EVENT = APP + "/event/:actiontype/:id/:step?/:tab?",
  EVENT_RSVP = APP + "/events/rsvp/:id",
  APPROVALS_EVENTS = APP + "/events/approvals/:step?",
  SETTINGS_EVENTS = APP + "/events/settings/:step?",
  // REPORTS_OVERVIEW = APP + "/events/reports-overview",
  REPORTS_PROPERTIES = APP + "/events/by-property/:step?",
  REPORTS_EVENTS = APP + "/events/by-events",
  REPORTS_EVENT = APP + "/events/by-event/:step?",
  REPORTS_EVENTS_NEW = APP + "/events/by-events-new",
  REPORTS_EVENT_NEW = APP + "/events/by-events-new/view",
  REPORTS_EMAIL = APP + "/events/by-email/:step?",
  REPORTS_EMAIL_ANALYTICS = APP + "/events/email-analytics",
  REPORTS_CONTACT = APP + "/events/event-contact-list/:step?",
  REPORTS_CONTACTLIST_BY_EMAILS = APP + "/events/contact-list-by-emails/:step?",
  REPORTS_FAVORITES = APP + "/events/favorites",
  SCHEDULE = "schedule",
  //hyRaf path
  LIST_CAMPAIGN = APP + "/refer-a-friend",
  CREATE_CAMPAIGN = APP + "/refer-a-friend/campaign",
  EDIT_CAMPAIGN = APP + "/refer-a-friend/campaign/:id/:step?",
  PREVIEW_CAMPAIGN = "preview",
  PUBLISH_CAMPAIGN = "publish",
  SCHEDULE_CAMPAIGN = "schedule",
  REPORT_PROPERTY_CAMPAIGN = APP + "/refer-a-friend/reports/by-property/:step?",
  REPORT_BY_CAMPAIGNS_CAMPAIGN = APP + "/refer-a-friend/reports/by-campaigns/:step?",
  REPORT_BY_CAMPAIGN_CAMPAIGN = APP + "/refer-a-friend/reports/by-campaign/:step?",
  REPORT_CONTACT_CAMPAIGN = APP + "/refer-a-friend/reports/by-contact/:step?",
  REPORT_ANALYTICS_CAMPAIGN = APP + "/refer-a-friend/reports/my-analytics",
  SETTINGS_RAF = APP + "/refer-a-friend/settings",
  //hyTour path
  TOURS = APP + "/tours",
  JOURNEY_FREEHAND = APP + "/journey-freehand",
  JOURNEY_FREEHAND_NEW = APP + "/journey-freehand/:journeyID/new",
  JOURNEY_FREEHAND_TEMPLATES = APP + "/journey-freehand/templates",
  EMPTY = "/empty",
  // Journey Designer
  JOURNEY_TEMPLATES_PAGE = APP + "/jai/templates",
  JOURNEY_SEARCH_USER_JOURNEYS_PAGE = APP + "/jai/search-user-journeys",
  JOURNEY_LANDING_PAGE = APP + "/jai/:jaiType?",
  JOURNEY_CREATE_PAGE = APP + "/jai/:jaiType/create",
  JOURNEY_DIAGRAM_PAGE = APP + "/jai/:jaiType/:id",
  // Hayley
  HAYLEY_MASCOT = APP + "/hayley/mascot",
  HAYLEY_POSITION = APP + "/hayley/position",
  HAYLEY_SHARE = APP + "/hayley/code/:step?",
  HAYLEY_TOUR = APP + "/hayley/tour",
  HAYLEY_FAQS = APP + "/hayley/ama",
  HAYLEY_PREFERENCE = APP + "/hayley/preference",
  HAYLEY_KNOWLEDGE_BASE = APP + "/hayley/knowledge-base",
  HAYLEY_REPORT_PROPERTY = APP + "/hayley/reports/by-property/:step?",
  HAYLEY_REPORT_CONTACT_CAMPAIGN = APP + "/hayley/reports/by-contact/:step?",
  HAYLEY_REPORT_ANALYTICS_CAMPAIGN = APP + "/hayley/reports/my-analytics",
  HAYLEY_REPORTS_ANALYTICS = APP + "/hayley/reports/hayley-analytics/:step?",
  // AI Workshop
  FEEDERS_PLAYGORUND = APP + "/ai-workshop/feeders-playground",
  FEEDERS_CONFIGURATION = APP + "/ai-workshop/feeders-configuration",
  FEEDERS_MANAGEMENT = APP + "/ai-workshop/feeders-management",
  FAQS = APP + "/ama",
  GLOBAL_SETTINGS = APP + "/settings";

export const getAppPath = (propertyId) => {
  return APP.replace(":propertyId", propertyId || getPropertyId());
};

export const getOfferEditPath = (id) =>
  OFFERS_EDIT.replace(":id", id).replace(":propertyId", getPropertyId());

export const getEventsPath = (propertyId) => {
  return EVENTS.replace(":propertyId", propertyId || getPropertyId());
};

export const getCreateEventPath = (propertyId, id) => {
  return CREATE_EVENT.replace(":propertyId", propertyId || getPropertyId())
    .replace(":actiontype", "create")
    .replace(":id", id);
};

export const getEditEventPath = (event, preview) => {
  let id = event.id;
  let path = EDIT_EVENT.replace(":id", id);
  path = path
    .replace(":propertyId", getPropertyId())
    .replace("/:tab?", "")
    .replace(":actiontype", "edit");

  if (preview) return path.replace(":step?", PREVIEW);

  switch (event.status) {
    case "published":
      return path.replace(":step?", SCHEDULE);
    case "publish_scheduled":
      return path.replace(":step?", PUBLISH);
    default:
      return path.replace(":step?", "");
  }
};

export const getEventRSVPPath = (event) => {
  let id = event.id;
  let path = EVENT_RSVP.replace(":id", id);
  return path.replace(":propertyId", getPropertyId()).replace("/:tab?", "");
};

export const getEventEditPath = (event) => {
  let path = EDIT_EVENT.replace(":id", event.id);
  path = path.replace(":propertyId", getPropertyId());
  return path.replace("/:step?", "").replace("/:tab?", "").replace(":actiontype", "edit");
};

export const getPublishPath = (event) => {
  let path = EDIT_EVENT.replace(":id", event.id);
  path = path.replace(":propertyId", getPropertyId());
  return path.replace(":step?", PUBLISH).replace("/:tab?", "").replace(":actiontype", "edit");
};

export const getScheduleInvitePath = (event) => {
  let path = EDIT_EVENT.replace(":id", event.id);
  path = path.replace(":propertyId", getPropertyId());
  return path.replace(":step?", SCHEDULE).replace("/:tab?", "").replace(":actiontype", "edit");
};

export const getQueryParams = () => {
  let params = {};
  window.location.search
    .substr(1)
    .split("&")
    .forEach((key_value) => {
      if (key_value.split("=")[0] === "properties")
        params[key_value.split("=")[0]] = key_value.substr(11);
      else
        params[key_value.split("=")[0]] = key_value.split("=")[1]
          ? key_value.split("=")[1].replace(/%20/g, " ").trim()
          : "";
    });
  return params;
};

export const getEventRoutesParameters = () => {
  const route = window.location.pathname.split("/").filter((i) => i);
  const actiontype = route[2];
  const id = route[3];
  const step = route[4];
  return { id, step, actiontype };
};

export const getEventRSVPRoutesParameters = () => {
  const route = window.location.pathname.split("/").filter((i) => i);
  const id = route[3];
  return { id };
};

export const getPreviewUrl = (id) => {
  return `https://${process.env.REACT_APP_MyhylyDomain}/mktg/hyevents/${id}/landing_page?hyevent_preview=true`;
};

export const getUpcomingEventUrl = () => {
  return `https://${
    process.env.REACT_APP_MyhylyDomain
  }/mktg/tenants/${getPropertyId()}/upcoming_events`;
};

export const getUpcomingEventCode = () => {
  return `<iframe src="https://${
    process.env.REACT_APP_MyhylyDomain
  }/mktg/tenants/${getPropertyId()}/upcoming_events" style="width:100%;height:100vh;"/>`;
};

export const getCampaignEditPath = (id) => {
  let path = EDIT_CAMPAIGN.replace(":id", id);
  path = path.replace(":propertyId", getPropertyId());
  return path.replace(":step?", "");
};

export const getCreateCampaignPath = (propertyId) => {
  return CREATE_CAMPAIGN.replace(":propertyId", propertyId || getPropertyId());
};

export const getHyRafRoutesParameters = () => {
  const route = window.location.pathname.split("/").filter((i) => i);
  const id = route[3];
  const step = route[4];
  return { id, step };
};

export const getAppNameFromPath = (url) => {
  const APP = "/:propertyId";
  const appsData = [
    {
      appName: "HayleyChat",
      url: APP + "/hayley/mascot",
      menu: "Customization Settings",
    },
    {
      appName: "HayleyChat",
      url: APP + "/hayley/position",
      menu: "Customization Settings",
    },
    {
      appName: "HayleyChat",
      url: APP + "/hayley/code/:step?",
      menu: "Share Options",
    },
    {
      appName: "HayleyChat",
      url: APP + "/hayley/tour",
      menu: "Tour Settings",
    },
    {
      appName: "HayleyChat",
      url: APP + "/hayley/ama",
      menu: "AMA Settings",
    },
    {
      appName: "HayleyChat",
      url: APP + "/hayley/preference",
      menu: "Preference Center",
    },
    {
      appName: "HayleyChat",
      url: APP + "/hayley/reports/by-property/:step?",
      menu: "Reports",
    },
    {
      appName: "HayleyChat",
      url: APP + "/hayley/reports/by-contact/:step?",
      menu: "Reports",
    },
    {
      appName: "HayleyChat",
      url: APP + "/hayley/reports/my-analytics",
      menu: "Reports",
    },
    {
      appName: "HayleyChat",
      url: APP + "/hayley/reports/hayley-analytics/:step?",
      menu: "Reports",
    },
    {
      appName: "Blasts",
      url: APP + "/blast",
      menu: "Blasts",
    },
    {
      appName: "Blasts",
      url: APP + "/blast/templates",
      menu: "Templates",
    },
    {
      appName: "Blasts",
      url: APP + "/blast/approvals/:step?",
      menu: "Approvals",
    },
    {
      appName: "Blasts",
      url: APP + "/blast/reports/property-audit/:step?",
      menu: "Reports",
    },
    {
      appName: "Blasts",
      url: APP + "/blast/reports/by-property/:step?",
      menu: "Reports",
    },
    {
      appName: "Blasts",
      url: APP + "/blast/reports/by-blasts",
      menu: "Reports",
    },
    {
      appName: "Blasts",
      url: APP + "/blast/reports/by-blast/:step?",
      menu: "Reports",
    },
    {
      appName: "Blasts",
      url: APP + "/blast/reports/blasts-analytics",
      menu: "Reports",
    },
    {
      appName: "Blasts",
      url: APP + "/blast/reports/blasts-analytics/:step?",
      menu: "Reports",
    },
    {
      appName: "Blasts",
      url: APP + "/blast/reports/feedback-org-property",
      menu: "Reports",
    },
    {
      appName: "Blasts",
      url: APP + "/blast/reports/feedback-blast-type",
      menu: "Reports",
    },
    {
      appName: "Blasts",
      url: APP + "/blast/reports/feedback-fields",
      menu: "Reports",
    },
    {
      appName: "Blasts",
      url: APP + "/blast/reports/feedback-fields/:step",
      menu: "Reports",
    },
    {
      appName: "Blasts",
      url: APP + "/blast/reports/feedback-reports/:step",
      menu: "Reports",
    },
    {
      appName: "Blasts",
      url: APP + "/blast/reports/my-analytics",
      menu: "Reports",
    },
    {
      appName: "Blasts",
      url: APP + "/blast/reports/my-favorites",
      menu: "Reports",
    },
    {
      appName: "Blasts",
      url: APP + "/blast/reports/blast-a-b",
      menu: "Reports",
    },
    {
      appName: "Blasts",
      url: APP + "/blast/reports/blast-a-b-variant/:step?",
      menu: "Reports",
    },
    {
      appName: "Blasts",
      url: APP + "/blast/reports/blast-contact-list/:step?",
      menu: "Reports",
    },
    {
      appName: "Blasts",
      url: APP + "/blast/reports/by-email-platform/:step?",
      menu: "Reports",
    },
    {
      appName: "Blasts",
      url: APP + "/blast/settings/:step?",
      menu: "Settings",
    },
    {
      appName: "Blasts",
      url: APP + "/blast/:id/edit/:step?/:tab?",
      menu: "Blasts",
    },
    {
      appName: "Drips",
      url: APP + "/blast/:id/edit/:step?/:tab?",
      menu: "Reports",
    },
    {
      appName: "Drips",
      url: APP + "/drips/reports/by-drips",
      menu: "Reports",
    },
    {
      appName: "Drips",
      url: APP + "/drips/reports/by-drip/:step?",
      menu: "Reports",
    },
    {
      appName: "Drips",
      url: APP + "/drips/reports/by-emails",
      menu: "Reports",
    },
    {
      appName: "Drips",
      url: APP + "/drips/reports/by-email/:step?",
      menu: "Reports",
    },
    {
      appName: "Drips",
      url: APP + "/drips/reports/emails-analytics",
      menu: "Reports",
    },
    {
      appName: "Drips",
      url: APP + "/drips/reports/email-analytics/:step?",
      menu: "Reports",
    },
    {
      appName: "Drips",
      url: APP + "/drips/reports/drips-contact-list/:step?",
      menu: "Reports",
    },
    {
      appName: "Drips",
      url: APP + "/drips/reports/by-platform/:step?",
      menu: "Reports",
    },
    {
      appName: "Drips",
      url: APP + "/drips/reports/my-favorites",
      menu: "Reports",
    },
    {
      appName: "Drips",
      url: APP + "/drips/:angelId/angels",
      menu: "Drips",
    },
    {
      appName: "Drips",
      url: APP + "/drips/:angelId/:emailId/edit/:step?/:tab?",
      menu: "Drips",
    },
    {
      appName: "Drips",
      url: APP + "/drips/success_hub",
      menu: "Success Hub",
    },
    {
      appName: "Alerts",
      url: APP + "/alerts/reports/by-property/:step?",
      menu: "Reports",
    },
    {
      appName: "Alerts",
      url: APP + "/alerts/reports/by-alerts",
      menu: "Reports",
    },
    {
      appName: "Alerts",
      url: APP + "/alerts/reports/by-alert/:step?",
      menu: "Reports",
    },
    {
      appName: "Alerts",
      url: APP + "/alerts/reports/by-emails",
      menu: "Reports",
    },
    {
      appName: "Alerts",
      url: APP + "/alerts/reports/by-email/:step?",
      menu: "Reports",
    },
    {
      appName: "Alerts",
      url: APP + "/alerts/reports/email-analytics/:step?",
      menu: "Reports",
    },
    {
      appName: "Alerts",
      url: APP + "/alerts/reports/alerts-contact-list/:step?",
      menu: "Reports",
    },
    {
      appName: "Alerts",
      url: APP + "/alerts/reports/by-platform/:step?",
      menu: "Reports",
    },
    {
      appName: "Alerts",
      url: APP + "/alerts/reports/my-analytics",
      menu: "Reports",
    },
    {
      appName: "Alerts",
      url: APP + "/alerts/:angelId/angels",
      menu: "Alerts",
    },
    {
      appName: "Alerts",
      url: APP + "/alerts/success_hub",
      menu: "Success Hub",
    },
    {
      appName: "Success Center",
      url: APP + "/success-center-blast/analysis",
      menu: "Blasts",
    },
    {
      appName: "Success Center",
      url: APP + "/success-center-blast/reports",
      menu: "Blasts",
    },
    {
      appName: "Success Center",
      url: APP + "/success-center-blast/goals",
      menu: "Blasts",
    },
    {
      appName: "Success Center",
      url: APP + "/success-center-blast/property-audit/:step?",
      menu: "Blasts",
    },
    {
      appName: "Success Center",
      url: APP + "/success-center-blast/property-blast/:step?",
      menu: "Blasts",
    },
    {
      appName: "Success Center",
      url: APP + "/success-center-drips/analysis",
      menu: "Drips",
    },
    {
      appName: "Success Center",
      url: APP + "/success-center-drips/reports",
      menu: "Drips",
    },
    {
      appName: "Success Center",
      url: APP + "/success-center-drips/goals",
      menu: "Drips",
    },
    {
      appName: "Success Center",
      url: APP + "/success-center-drips/by-property/:step?",
      menu: "Drips",
    },
    {
      appName: "Events",
      url: APP + "/events",
      menu: "Events",
    },
    {
      appName: "Events",
      url: APP + "/event/:actiontype/:id",
      menu: "Events",
    },
    {
      appName: "Events",
      url: APP + "/events/templates",
      menu: "Templates",
    },
    {
      appName: "Events",
      url: APP + "/event/:actiontype/:id/:step?/:tab?",
      menu: "Events",
    },
    {
      appName: "Events",
      url: APP + "/events/rsvp/:id",
      menu: "RSVP List",
    },
    {
      appName: "Events",
      url: APP + "/events/approvals/:step?",
      menu: "Approvals",
    },
    {
      appName: "Events",
      url: APP + "/events/settings/:step?",
      menu: "Settings",
    },
    {
      appName: "Events",
      url: APP + "/events/by-property/:step?",
      menu: "Property",
    },
    {
      appName: "Events",
      url: APP + "/events/by-property/:step?",
      menu: "Property",
    },
    {
      appName: "Events",
      url: APP + "/events/by-events-new",
      menu: "Reports",
    },
    {
      appName: "Events",
      url: APP + "/events/by-events-new/view",
      menu: "Reports",
    },
    {
      appName: "Events",
      url: APP + "/events/by-events-new/view",
      menu: "Reports",
    },
    {
      appName: "Events",
      url: APP + "/events/event-contact-list/:step?",
      menu: "Reports",
    },
    {
      appName: "Events",
      url: APP + "/events/contact-list-by-emails/:step?",
      menu: "Reports",
    },
    {
      appName: "Events",
      url: APP + "/events/favorites",
      menu: "Reports",
    },
    {
      appName: "Refer-a-friend",
      url: APP + "/refer-a-friend",
      menu: "Refer-a-friend",
    },
    {
      appName: "Refer-a-friend",
      url: APP + "/refer-a-friend/campaign",
      menu: "Referrel-campaigns",
    },

    {
      appName: "Refer-a-friend",
      url: APP + "/refer-a-friend/campaign/:id/:step?",
      menu: "Referrel Campaigns",
    },
    {
      appName: "Refer-a-friend",
      url: APP + "/refer-a-friend/reports/by-property/:step?",
      menu: "Reports",
    },
    {
      appName: "Refer-a-friend",
      url: APP + "/refer-a-friend/reports/by-campaigns/:step?",
      menu: "Reports",
    },
    {
      appName: "Refer-a-friend",
      url: APP + "/refer-a-friend/reports/by-campaign/:step?",
      menu: "Reports",
    },
    {
      appName: "Refer-a-friend",
      url: APP + "/refer-a-friend/reports/by-contact/:step?",
      menu: "Reports",
    },
    {
      appName: "Refer-a-friend",
      url: APP + "/refer-a-friend/reports/my-analytics",
      menu: "Reports",
    },
    {
      appName: "Refer-a-friend",
      url: APP + "/refer-a-friend/settings",
      menu: "Settings",
    },
    {
      appName: "Tours",
      url: APP + "/tours",
      menu: "Tours",
    },
    {
      appName: "Journey Freehand",
      url: APP + "/journey-freehand",
      menu: "Journey Freehand",
    },
    {
      appName: "Journey Freehand",
      url: APP + "/journey-freehand/:journeyID/new",
      menu: "mjourneys",
    },
    {
      appName: "Journey Freehand",
      url: APP + "/journey-freehand/templates",
      menu: "Templates",
    },
    {
      appName: "Journey Designer",
      url: APP + "/journey-freehand/templates",
      menu: "Templates",
    },
    {
      appName: "Journey Designer",
      url: APP + "/jai/search-user-journeys",
      menu: "Search User Journeys",
    },
    {
      appName: "Journey Designer",
      url: APP + "/jai/search-user-journeys",
      menu: "Search User Journeys",
    },
  ];

  // JOURNEY_TEMPLATES_PAGE = APP + "/jai/templates",
  // JOURNEY_SEARCH_USER_JOURNEYS_PAGE = APP + "/jai/search-user-journeys",
  // JOURNEY_LANDING_PAGE = APP + "/jai/:jaiType?",
  // JOURNEY_CREATE_PAGE = APP + "/jai/:jaiType/create",
  // JOURNEY_DIAGRAM_PAGE = APP + "/jai/:jaiType/:id",

  // TOURS = APP + "/tours",
  // JOURNEY_FREEHAND = APP + "/journey-freehand",
  // JOURNEY_FREEHAND_NEW = APP + "/journey-freehand/:journeyID/new",
  // JOURNEY_FREEHAND_TEMPLATES = APP + "/journey-freehand/templates",
  // EMPTY = "/empty",

  // LIST_CAMPAIGN = APP + "/refer-a-friend",
  // CREATE_CAMPAIGN = APP + "/refer-a-friend/campaign",
  // EDIT_CAMPAIGN = APP + "/refer-a-friend/campaign/:id/:step?",
  // PREVIEW_CAMPAIGN = "preview",
  // PUBLISH_CAMPAIGN = "publish",
  // SCHEDULE_CAMPAIGN = "schedule",
  // REPORT_PROPERTY_CAMPAIGN = APP + "/refer-a-friend/reports/by-property/:step?",
  // REPORT_BY_CAMPAIGNS_CAMPAIGN = APP + "/refer-a-friend/reports/by-campaigns/:step?",
  // REPORT_BY_CAMPAIGN_CAMPAIGN =  APP + "/refer-a-friend/reports/by-campaign/:step?",
  // REPORT_CONTACT_CAMPAIGN = APP + "/refer-a-friend/reports/by-contact/:step?",
  // REPORT_ANALYTICS_CAMPAIGN = APP + "/refer-a-friend/reports/my-analytics",
  // SETTINGS_RAF = APP + "/refer-a-friend/settings",

  // EVENTS = APP + "/events",
  // CREATE_EVENT = APP + "/event/:actiontype/:id",
  // TEMPLATES_EVENT = APP + "/events/templates",
  // PREVIEW = "preview",
  // PUBLISH = "publish",
  // EDIT_EVENT = APP + "/event/:actiontype/:id/:step?/:tab?",
  // EVENT_RSVP = APP + "/events/rsvp/:id",
  // APPROVALS_EVENTS = APP + "/events/approvals/:step?",
  // SETTINGS_EVENTS = APP + "/events/settings/:step?",
  // REPORTS_PROPERTIES = APP + "/events/by-property/:step?",
  // REPORTS_EVENTS = APP + "/events/by-events",
  // REPORTS_EVENT = APP + "/events/by-event/:step?",
  // REPORTS_EVENTS_NEW = APP + "/events/by-events-new",
  // REPORTS_EVENT_NEW = APP + "/events/by-events-new/view",
  // REPORTS_EMAIL = APP + "/events/by-email/:step?",
  // REPORTS_EMAIL_ANALYTICS = APP + "/events/email-analytics",
  // REPORTS_CONTACT = APP + "/events/event-contact-list/:step?",
  // REPORTS_CONTACTLIST_BY_EMAILS = APP + "/events/contact-list-by-emails/:step?",
  // REPORTS_FAVORITES = APP + "/events/favorites",
  // SCHEDULE = "schedule",

  // SUCCESS_BLAST_ANALYSIS = APP + "/success-center-blast/analysis",
  // SUCCESS_BLAST_REPORTS = APP + "/success-center-blast/reports",
  // SUCCESS_BLAST_GOALS = APP + "/success-center-blast/goals",
  // SUCCESS_BLAST_PROPERTY_AUDIT = APP + "/success-center-blast/property-audit/:step?",
  // SUCCESS_BLAST_PROPERTY_BLAST = APP + "/success-center-blast/property-blast/:step?",
  // SUCCESS_DRIPS_ANALYSIS = APP + "/success-center-drips/analysis",
  // SUCCESS_DRIPS_REPORTS = APP + "/success-center-drips/reports",
  // SUCCESS_DRIPS_GOALS = APP + "/success-center-drips/goals",
  // SUCCESS_DRIPS_BY_PROPERTY = APP + "/success-center-drips/by-property/:step?",

  // ALERTS_REPORTS_EMAIL_ANALYTICS = APP + "/alerts/reports/email-analytics/:step?",
  // ALERTS_REPORTS_DRIPS_CONTACT_LIST = APP + "/alerts/reports/alerts-contact-list/:step?",
  // ALERTS_REPORTS_BY_EMAIL_PLATFORM = APP + "/alerts/reports/by-platform/:step?",
  // ALERTS_REPORTS_MY_ANALYTICS = APP + "/alerts/reports/my-analytics",
  // ALERTS_ANGELS = APP + "/alerts/:angelId/angels",

  // ALERTS_REPORTS_BY_PROPERTY = APP + "/alerts/reports/by-property/:step?",
  // ALERTS_REPORTS_BY_ALERTS = APP + "/alerts/reports/by-alerts",
  // ALERTS_REPORTS_BY_ALERT = APP + "/alerts/reports/by-alert/:step?",
  // ALERTS_REPORTS_BY_EMAILS = APP + "/alerts/reports/by-emails",
  // ALERTS_REPORTS_BY_EMAIL = APP + "/alerts/reports/by-email/:step?",

  // {
  //   Drips: [
  //     APP + "/drips/reports/by-property/:step?",
  //     APP + "/drips/reports/by-drips",
  //     APP + "/drips/reports/by-drip/:step?",
  //     APP + "/drips/reports/by-emails",
  //     APP + "/drips/reports/by-email/:step?",
  //     APP + "/drips/reports/emails-analytics",
  //     APP + "/drips/reports/email-analytics/:step?",
  //     APP + "/drips/reports/drips-contact-list/:step?",
  //     APP + "/drips/reports/by-platform/:step?",
  //     APP + "/drips/reports/my-favorites",
  //     APP + "/drips/:angelId/angels",
  //     APP + "/drips/:angelId/:emailId/edit/:step?/:tab?",
  //   ],
  // },
  // {
  //   Blasts: [
  //     APP + "/blast",
  //     APP + "/blast/templates",
  //     APP + "/blast/approvals/:step?",
  //     APP + "/blast/reports/property-audit/:step?",
  //     APP + "/blast/reports/by-property/:step?",
  //     APP + "/blast/reports/by-blasts",
  //     APP + "/blast/reports/by-blast/:step?",
  //     APP + "/blast/reports/blasts-analytics",
  //     APP + "/blast/reports/blasts-analytics/:step?",
  //     APP + "/blast/reports/feedback-org-property",
  //     APP + "/blast/reports/feedback-blast-type",
  //     APP + "/blast/reports/feedback-fields",
  //     APP + "/blast/reports/feedback-fields/:step",
  //     APP + "/blast/reports/feedback-reports/:step",
  //     APP + "/blast/reports/my-analytics",
  //     APP + "/blast/reports/my-favorites",
  //     APP + "/blast/reports/blast-a-b",
  //     APP + "/blast/reports/blast-a-b-variant/:step?",
  //     APP + "/blast/reports/blast-contact-list/:step?",
  //     APP + "/blast/reports/by-email-platform/:step?",
  //     APP + "/blast/settings/:step?",
  //     APP + "/blast/:id/edit/:step?/:tab?",
  //   ],
  // },

  // ];

  for (const appData of appsData) {
    const regexPath = appData.url.replace(/:\w+\?/g, ".*").replace(/:\w+/g, "[^/]+");
    const regex = new RegExp(`^${regexPath}$`);
    if (regex.test(url)) {
      return appData;
    }
  }
};
