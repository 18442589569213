import React from "react";
import PropTypes from "prop-types";

import withStyles from "@mui/styles/withStyles";

import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import ClickAwayListener from "@mui/material/ClickAwayListener";

import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";

import DropDownSearch from "./DropDownSearch";

import Typography from "storybookComponents/Typography";
import SearchInput from "./Inputs/SearchInput";
import Icon from "storybookComponents/Icon";
import Button from "storybookComponents/Button";
import Input from "@mui/material/Input";

import SearchSelect from "./SearchSelect";
import { Formik, Form, Field, FieldArray } from "formik";
import { create_validation } from "storybookComponents/HylyField/validation";
import HylyField from "storybookComponents/HylyField";

const SearchSelectCreateNewField = ({
  fields,
  errors,
  initialValues,
  add_fields_label,
  array_fields_name,
  error_label,

  new_field_props,
  classes,
  Container,
  values,
  change_fields_action,
  ...other
}) => {
  const [field_status, set_field_status] = React.useState(false);

  function add_fields_action() {
    set_field_status(true);
    if (change_fields_action) {
      change_fields_action();
    }
  }

  return field_status ? (
    <HylyField className={other.fieldClassName || ""} {...new_field_props} />
  ) : (
    <SearchSelect
      has_add_fields
      add_fields_label={add_fields_label}
      add_fields_action={add_fields_action}
      {...other}
    />
  );
};

SearchSelectCreateNewField.propTypes = {
  input_props: PropTypes.object,
  selected_value: PropTypes.string,
  icon_type: PropTypes.string,
  search_value: PropTypes.string,
  selected_value: PropTypes.string,
  onSelect: PropTypes.func,

  // fields addition types
  add_fields_label: PropTypes.string.isRequired,
  new_field_props: PropTypes.object,
  change_fields_action: PropTypes.func,
};

SearchSelectCreateNewField.defaultProps = {
  add_fields_label: "add fields",
  field_type: "text",
  array_fields_name: "select-add-fields",
};

export default React.memo(SearchSelectCreateNewField);
