import React from "react";

export default function TableArrowIcon({ size = 16, className }) {
  return (
    <svg
      className={className}
      width={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.7998 12.7998V3.7998"
        stroke="#333333"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.7998 6.7998L7.7998 3.7998L10.7998 6.7998"
        stroke="#333333"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
