import graphql from "babel-plugin-relay/macro";

export default graphql`
  query getReportHistoryQuery($orgId: ID!, $type: String!, $startDate: String!, $endDate: String!) {
    scheduledReportLogs(orgId: $orgId, type: $type, startDate: $startDate, endDate: $endDate) {
      nodes {
        id
        createdAt
        recipients
        reportMetrics
      }
    }
  }
`;
