/**
 * @flow
 * @relayHash f8dfb95ab769ce6d09d5ca6d85aed630
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getApprovalRequestsQueryVariables = {|
  orgId: string,
  type?: ?string,
  propertyId?: ?string,
  first?: ?number,
  after?: ?string,
|};
export type getApprovalRequestsQueryResponse = {|
  +approvalRequests: {|
    +pageInfo: {|
      +endCursor: ?string,
      +hasNextPage: boolean,
    |},
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +propertyId: string,
        +approvedAt: ?any,
        +approvedBy: ?{|
          +email: string
        |},
        +disapprovedBy: ?string,
        +disapprovedAt: ?any,
        +agentEmail: ?string,
        +approvalFeedbacks: ?$ReadOnlyArray<{|
          +id: string,
          +userEmail: string,
          +message: string,
          +createdAt: any,
        |}>,
        +approverEmails: string,
        +propertyName: string,
        +eblastId: string,
        +eblastStatus: string,
        +approved: boolean,
        +eblastName: string,
        +mailGpt: ?boolean,
        +eblastSubject: string,
        +scheduledTime: ?any,
        +feedback: ?string,
        +createdAt: any,
        +action: string,
        +hyevent: ?{|
          +id: string,
          +title: string,
          +status: string,
          +propertyId: string,
          +publishAt: ?any,
        |},
      |}
    |}>,
  |}
|};
export type getApprovalRequestsQuery = {|
  variables: getApprovalRequestsQueryVariables,
  response: getApprovalRequestsQueryResponse,
|};
*/


/*
query getApprovalRequestsQuery(
  $orgId: ID!
  $type: String
  $propertyId: ID
  $first: Int
  $after: String
) {
  approvalRequests(orgId: $orgId, type: $type, propertyId: $propertyId, first: $first, after: $after) {
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      node {
        id
        propertyId
        approvedAt
        approvedBy {
          email
          id
        }
        disapprovedBy
        disapprovedAt
        agentEmail
        approvalFeedbacks {
          id
          userEmail
          message
          createdAt
        }
        approverEmails
        propertyName
        eblastId
        eblastStatus
        approved
        eblastName
        mailGpt
        eblastSubject
        scheduledTime
        feedback
        createdAt
        action
        hyevent {
          id
          title
          status
          propertyId
          publishAt
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "orgId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "type",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "propertyId",
    "type": "ID",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "after",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "orgId",
    "variableName": "orgId"
  },
  {
    "kind": "Variable",
    "name": "propertyId",
    "variableName": "propertyId"
  },
  {
    "kind": "Variable",
    "name": "type",
    "variableName": "type"
  }
],
v2 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "pageInfo",
  "storageKey": null,
  "args": null,
  "concreteType": "PageInfo",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "endCursor",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "hasNextPage",
      "args": null,
      "storageKey": null
    }
  ]
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "propertyId",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "approvedAt",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "email",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "disapprovedBy",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "disapprovedAt",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "agentEmail",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "createdAt",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "approvalFeedbacks",
  "storageKey": null,
  "args": null,
  "concreteType": "ApprovalFeedback",
  "plural": true,
  "selections": [
    (v3/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "userEmail",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "message",
      "args": null,
      "storageKey": null
    },
    (v10/*: any*/)
  ]
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "approverEmails",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "propertyName",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "eblastId",
  "args": null,
  "storageKey": null
},
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "eblastStatus",
  "args": null,
  "storageKey": null
},
v16 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "approved",
  "args": null,
  "storageKey": null
},
v17 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "eblastName",
  "args": null,
  "storageKey": null
},
v18 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "mailGpt",
  "args": null,
  "storageKey": null
},
v19 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "eblastSubject",
  "args": null,
  "storageKey": null
},
v20 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "scheduledTime",
  "args": null,
  "storageKey": null
},
v21 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "feedback",
  "args": null,
  "storageKey": null
},
v22 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "action",
  "args": null,
  "storageKey": null
},
v23 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "hyevent",
  "storageKey": null,
  "args": null,
  "concreteType": "Hyevent",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "title",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "status",
      "args": null,
      "storageKey": null
    },
    (v4/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "publishAt",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getApprovalRequestsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "approvalRequests",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ApprovalRequestConnection",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "ApprovalRequestEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "ApprovalRequest",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "approvedBy",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Account",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/)
                    ]
                  },
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/),
                  (v21/*: any*/),
                  (v10/*: any*/),
                  (v22/*: any*/),
                  (v23/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "getApprovalRequestsQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "approvalRequests",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ApprovalRequestConnection",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "ApprovalRequestEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "ApprovalRequest",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "approvedBy",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Account",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v3/*: any*/)
                    ]
                  },
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/),
                  (v21/*: any*/),
                  (v10/*: any*/),
                  (v22/*: any*/),
                  (v23/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "getApprovalRequestsQuery",
    "id": null,
    "text": "query getApprovalRequestsQuery(\n  $orgId: ID!\n  $type: String\n  $propertyId: ID\n  $first: Int\n  $after: String\n) {\n  approvalRequests(orgId: $orgId, type: $type, propertyId: $propertyId, first: $first, after: $after) {\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n    edges {\n      node {\n        id\n        propertyId\n        approvedAt\n        approvedBy {\n          email\n          id\n        }\n        disapprovedBy\n        disapprovedAt\n        agentEmail\n        approvalFeedbacks {\n          id\n          userEmail\n          message\n          createdAt\n        }\n        approverEmails\n        propertyName\n        eblastId\n        eblastStatus\n        approved\n        eblastName\n        mailGpt\n        eblastSubject\n        scheduledTime\n        feedback\n        createdAt\n        action\n        hyevent {\n          id\n          title\n          status\n          propertyId\n          publishAt\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e1144e8c6d00c6d300818de0e49d0d57';
module.exports = node;
