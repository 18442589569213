/**
 * @flow
 * @relayHash 5ecbb81e05617afd5f1aa3e5ec8ca3c6
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getSummaryReportsQueryVariables = {|
  orgId: string,
  type: string,
  startDate: string,
  endDate: string,
  propertyIds?: ?$ReadOnlyArray<string>,
  regionIds?: ?$ReadOnlyArray<string>,
|};
export type getSummaryReportsQueryResponse = {|
  +summaryReports: {|
    +id: string,
    +sendsCount: number,
    +uniqueOpensRate: number,
    +uniqueClicksRate: number,
    +bouncesRate: number,
    +unsubscribesRate: number,
    +propertyEmailReports: $ReadOnlyArray<{|
      +id: string,
      +name: string,
      +sendsCount: number,
      +uniqueOpensCount: number,
    |}>,
    +thenEmailReports: $ReadOnlyArray<{|
      +id: string,
      +property: {|
        +id: string,
        +name: string,
      |},
      +angel: {|
        +id: string,
        +name: ?string,
      |},
      +sendsCount: number,
      +uniqueOpensCount: number,
      +uniqueClicksCount: number,
    |}>,
  |}
|};
export type getSummaryReportsQuery = {|
  variables: getSummaryReportsQueryVariables,
  response: getSummaryReportsQueryResponse,
|};
*/


/*
query getSummaryReportsQuery(
  $orgId: ID!
  $type: String!
  $startDate: String!
  $endDate: String!
  $propertyIds: [ID!]
  $regionIds: [String!]
) {
  summaryReports(orgId: $orgId, type: $type, startDate: $startDate, endDate: $endDate, propertyIds: $propertyIds, regionIds: $regionIds) {
    id
    sendsCount
    uniqueOpensRate
    uniqueClicksRate
    bouncesRate
    unsubscribesRate
    propertyEmailReports {
      id
      name
      sendsCount
      uniqueOpensCount
    }
    thenEmailReports {
      id
      property {
        id
        name
      }
      angel {
        id
        name
      }
      sendsCount
      uniqueOpensCount
      uniqueClicksCount
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "orgId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "type",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "startDate",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "endDate",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "propertyIds",
    "type": "[ID!]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "regionIds",
    "type": "[String!]",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "sendsCount",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "uniqueOpensCount",
  "args": null,
  "storageKey": null
},
v5 = [
  (v1/*: any*/),
  (v3/*: any*/)
],
v6 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "summaryReports",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "endDate",
        "variableName": "endDate"
      },
      {
        "kind": "Variable",
        "name": "orgId",
        "variableName": "orgId"
      },
      {
        "kind": "Variable",
        "name": "propertyIds",
        "variableName": "propertyIds"
      },
      {
        "kind": "Variable",
        "name": "regionIds",
        "variableName": "regionIds"
      },
      {
        "kind": "Variable",
        "name": "startDate",
        "variableName": "startDate"
      },
      {
        "kind": "Variable",
        "name": "type",
        "variableName": "type"
      }
    ],
    "concreteType": "SummaryReport",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      (v2/*: any*/),
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "uniqueOpensRate",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "uniqueClicksRate",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "bouncesRate",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "unsubscribesRate",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "propertyEmailReports",
        "storageKey": null,
        "args": null,
        "concreteType": "PropertyEmailReport",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v3/*: any*/),
          (v2/*: any*/),
          (v4/*: any*/)
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "thenEmailReports",
        "storageKey": null,
        "args": null,
        "concreteType": "ThenEmailReport",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "property",
            "storageKey": null,
            "args": null,
            "concreteType": "Suborg",
            "plural": false,
            "selections": (v5/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "angel",
            "storageKey": null,
            "args": null,
            "concreteType": "Angel",
            "plural": false,
            "selections": (v5/*: any*/)
          },
          (v2/*: any*/),
          (v4/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "uniqueClicksCount",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getSummaryReportsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v6/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "getSummaryReportsQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v6/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "getSummaryReportsQuery",
    "id": null,
    "text": "query getSummaryReportsQuery(\n  $orgId: ID!\n  $type: String!\n  $startDate: String!\n  $endDate: String!\n  $propertyIds: [ID!]\n  $regionIds: [String!]\n) {\n  summaryReports(orgId: $orgId, type: $type, startDate: $startDate, endDate: $endDate, propertyIds: $propertyIds, regionIds: $regionIds) {\n    id\n    sendsCount\n    uniqueOpensRate\n    uniqueClicksRate\n    bouncesRate\n    unsubscribesRate\n    propertyEmailReports {\n      id\n      name\n      sendsCount\n      uniqueOpensCount\n    }\n    thenEmailReports {\n      id\n      property {\n        id\n        name\n      }\n      angel {\n        id\n        name\n      }\n      sendsCount\n      uniqueOpensCount\n      uniqueClicksCount\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '62209b66c84cf2de04e808cf75cce0dd';
module.exports = node;
