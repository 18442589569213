import React from "react";

export default function DuplicateIcon({ className = "text-[#333333]", size = 15 }) {
  return (
    // <div className={className}>
    //   <svg width={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    //     <path
    //       d="M19.3335 10H11.3335V19.3333H19.3335V10Z"
    //       stroke="currentColor"
    //       strokeLinecap="round"
    //       strokeLinejoin="round"
    //     />
    //     <path
    //       d="M15.3337 7.33335V4.66669H4.66699V18H8.66699"
    //       stroke="currentColor"
    //       strokeLinecap="round"
    //       strokeLinejoin="round"
    //     />
    //   </svg>
    // </div>
    <svg
      className={className}
      width={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.9998 10.6362H11.5454V16.9996H16.9998V10.6362Z"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.2725 8.81812V7H7V16.0906H9.72718"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
