import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import withStyles from "@mui/styles/withStyles";
import { getStates4Fav } from "redux/slices";
import { Button } from "storybookComponents";
import { Modal, CommonTextField as Field } from "components";
import styles from "./popupStyles";

const SaveReport = ({ classes, open, favorites, onConfirm, onClose, title }) => {
  const data4Fav = useSelector(getStates4Fav);
  const [value, setValue] = useState("");
  const [errTxt, setErrTxt] = useState("");
  const [visibleErr, setVisibleErr] = useState(false);

  const handleChangeValue = (e) => {
    setVisibleErr(false);
    setValue(e.target.value);
  };

  const handleClose = () => {
    onClose();
    setValue(data4Fav.name);
    setErrTxt("");
    setVisibleErr(false);
  };

  const handleConfirm = () => {
    if (favorites.filter((item) => item.name === value).length > 0) {
      setVisibleErr(true);
      setErrTxt("Report name already exists. Please try another.");
      return;
    }

    onConfirm(value.trim());
  };

  const invalidValue = () => {
    if (!value.trim().length) {
      return true;
    }
  };

  useEffect(() => {
    if (data4Fav) {
      setValue(data4Fav.name);
    }
  }, [data4Fav]);

  return (
    <Modal
      open={open}
      title={<div className={classes.reportHeader}>Favorite Report</div>}
      handleClose={handleClose}
      className={{
        dialogRoot: classes.dialogRoot,
      }}
      actionChildren={
        <>
          <Button onClick={handleClose} variant="tertiary" className={classes.button}>
            Cancel
          </Button>
          <Button
            state={invalidValue() ? "inactive" : "active"}
            className={classes.button}
            onClick={handleConfirm}
          >
            Confirm
          </Button>
        </>
      }
    >
      <div className={classes.reportText}>
        This Report will be added in&nbsp;
        <span className={classes.bold}>{title || "My Analytics"}</span>
      </div>
      <div className={classes.label}>Report Name</div>
      <Field
        variant="standard"
        onChange={handleChangeValue}
        value={value}
        fullWidth
        placeholder="Type your report name"
      />
      {visibleErr && <span className={classes.errTxt}>{errTxt}</span>}
    </Modal>
  );
};

export default withStyles(styles)(SaveReport);
