/**
 * @flow
 * @relayHash 016f9a3fda0c501161024ef517a0e279
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type mailGptBlastTypeReportsQueryVariables = {|
  orgIds?: ?$ReadOnlyArray<string>,
  allOrgs?: ?boolean,
  startAt?: ?string,
  endAt?: ?string,
  propertyIds?: ?$ReadOnlyArray<string>,
  blastTypes?: ?$ReadOnlyArray<string>,
  promptTemplates?: ?$ReadOnlyArray<string>,
|};
export type mailGptBlastTypeReportsQueryResponse = {|
  +mailGptBlastTypeReports: any
|};
export type mailGptBlastTypeReportsQuery = {|
  variables: mailGptBlastTypeReportsQueryVariables,
  response: mailGptBlastTypeReportsQueryResponse,
|};
*/


/*
query mailGptBlastTypeReportsQuery(
  $orgIds: [String!]
  $allOrgs: Boolean
  $startAt: String
  $endAt: String
  $propertyIds: [String!]
  $blastTypes: [String!]
  $promptTemplates: [String!]
) {
  mailGptBlastTypeReports(endAt: $endAt, startAt: $startAt, allOrgs: $allOrgs, orgIds: $orgIds, propertyIds: $propertyIds, blastTypes: $blastTypes, promptTemplates: $promptTemplates)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "orgIds",
    "type": "[String!]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "allOrgs",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "startAt",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "endAt",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "propertyIds",
    "type": "[String!]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "blastTypes",
    "type": "[String!]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "promptTemplates",
    "type": "[String!]",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "mailGptBlastTypeReports",
    "args": [
      {
        "kind": "Variable",
        "name": "allOrgs",
        "variableName": "allOrgs"
      },
      {
        "kind": "Variable",
        "name": "blastTypes",
        "variableName": "blastTypes"
      },
      {
        "kind": "Variable",
        "name": "endAt",
        "variableName": "endAt"
      },
      {
        "kind": "Variable",
        "name": "orgIds",
        "variableName": "orgIds"
      },
      {
        "kind": "Variable",
        "name": "promptTemplates",
        "variableName": "promptTemplates"
      },
      {
        "kind": "Variable",
        "name": "propertyIds",
        "variableName": "propertyIds"
      },
      {
        "kind": "Variable",
        "name": "startAt",
        "variableName": "startAt"
      }
    ],
    "storageKey": null
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "mailGptBlastTypeReportsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "mailGptBlastTypeReportsQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "mailGptBlastTypeReportsQuery",
    "id": null,
    "text": "query mailGptBlastTypeReportsQuery(\n  $orgIds: [String!]\n  $allOrgs: Boolean\n  $startAt: String\n  $endAt: String\n  $propertyIds: [String!]\n  $blastTypes: [String!]\n  $promptTemplates: [String!]\n) {\n  mailGptBlastTypeReports(endAt: $endAt, startAt: $startAt, allOrgs: $allOrgs, orgIds: $orgIds, propertyIds: $propertyIds, blastTypes: $blastTypes, promptTemplates: $promptTemplates)\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '588291a8b0e4bcf18790ec4dbc317231';
module.exports = node;
