import React from "react";
import { Drawer } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import TooltipIfEllipse from "components/TooltipIfEllipse";
import { Button } from "storybookComponents";
import { deleteReport, editSavedReport } from "relay";
import { getErrorMessage, checkUncatchableError, environment } from "environments";
import { getRangeLabel } from "common/date";
import reportImage from "images/analytics_view.png";
import DrawerBody from "./DrawerBody";
import VerticalMenu from "./VerticalMenu";
import { mainStyles as styles } from "./styles";
import {
  getValues,
  getNameList,
  EBLAST_ANALYTICS_PANELS,
  EBLAST_PANELS,
  BY_EMAIL_PLATFORM_PANELS,
  CONTACTS,
  DRIPS_EMAIL_ANALYTICS_PANELS,
  renderParamsLabel,
} from "./utils";
import DeleteReportPopup from "storybookComponents/Appbar/DeleteReportPopup";
import { CopiedIcon, DeleteIcon, EditIcon, LinkIcon } from "icons";
import EditReportPopup from "storybookComponents/Appbar/EditReportPopup";
import * as path from "constants/path";
import { contacts, getLabelFromName } from "content/Blasts/Reports/utils";
import clsx from "clsx";
import { Tooltip } from "components";

const Report = ({
  orgLevel,
  report,
  classes,
  handlePreviewCallback,
  properties,
  subOrgDetails,
  showNotification,
  allDripsEmailsData,
  onUpdate = () => {},
  setSms,
  onCopyLinkCallback,
  setSelectedItem,
}) => {
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [reportData, setReportData] = React.useState();
  const [storeParamsPayload, setStoreParamsPayload] = React.useState();
  const [visibleActions, setVisibleActions] = React.useState(false);
  const [openPreRemovePopup, setOpenPreRemovePopup] = React.useState(false);
  const [openEditNamePopup, setOpenEditNamePopup] = React.useState(false);
  const [reportName, setReportName] = React.useState("");
  const [copyText, setCopyText] = React.useState("Copy URL");

  React.useEffect(() => {
    init();
  }, [report]);

  const init = () => {
    try {
      if (!report.params) return;
      let params = JSON.parse(report.params);
      if (params.storeParams) {
        let payload = JSON.parse(params.storeParams);
        if (!payload.tenant_id) {
          payload.tenant_id = payload.tenant_ids[0];
        }
        setStoreParamsPayload(payload);
      }
      let propertiesName = [];
      let dripsName = [];
      let emailsName = [];
      let panelsValues = [];
      let widgetsValues = [];
      let dateRange = {};
      let blast_date_range = {};

      if (params.property_ids) {
        propertiesName = getValues(params.property_ids, properties);
      }

      if (params.isDrip) {
        // if property_ids length is 1 ...
        if (params.property_ids?.length === 1) {
          const dripsData = allDripsEmailsData.filter(
            (item) => item.id === params.property_ids[0]
          )[0];

          if (params.bot_ids) {
            dripsName = getNameList(params.bot_ids, dripsData.angels);
            // if bot_ids length is 1 ...
            if (params.bot_ids?.length === 1) {
              const campaignsData = dripsData.angels.filter(
                (item) => item.id === params.bot_ids[0]
              )[0];

              if (params.campaign_ids) {
                emailsName = getNameList(params.campaign_ids, campaignsData.thenEmails);
              }
            }
          }
        }
      }
      if (params.isAlert) {
        // if property_ids length is 1 ...
        if (params.property_ids?.length === 1) {
          const dripsData = allDripsEmailsData.filter(
            (item) => item.id === params.property_ids[0]
          )[0];

          if (params.bot_ids) {
            dripsName = getNameList(params.bot_ids, dripsData.alerts);
            // if bot_ids length is 1 ...
            if (params.bot_ids?.length === 1) {
              const campaignsData = dripsData.alerts.filter(
                (item) => item.id === params.bot_ids[0]
              )[0];

              if (params.campaign_ids) {
                emailsName = getNameList(params.campaign_ids, campaignsData.thenEmails);
              }
            }
          }
        }
      }

      if (params.panels) {
        panelsValues = getValues(params.panels, EBLAST_ANALYTICS_PANELS);
      }

      if (params.panelsEblast) {
        panelsValues = getValues(params.panelsEblast, EBLAST_PANELS);
      }

      if (params.dripsEAPanels) {
        panelsValues = getValues(params.dripsEAPanels, DRIPS_EMAIL_ANALYTICS_PANELS);
      }

      if (params.emailPlatformPanels) {
        panelsValues = getValues(params.emailPlatformPanels, BY_EMAIL_PLATFORM_PANELS);
      }

      if (params.widgets) {
        widgetsValues = getValues(params.widgets, CONTACTS);
      }

      if (params.date_range) {
        dateRange =
          params.date_range.label !== "Custom Range"
            ? params.date_range.label
            : getRangeLabel(params.date_range.startAt, params.date_range.endAt);
      }

      if (params.blast_date_range) {
        blast_date_range =
          params.blast_date_range.label !== "Custom Range"
            ? params.blast_date_range.label
            : getRangeLabel(params.blast_date_range.startAt, params.blast_date_range.endAt);
      }

      setReportName(report.name);

      setReportData({
        ...report,
        ...params,
        campaign_names: params.campaign_names || [],
        propertiesName,
        dripsName,
        emailsName,
        panelsValues,
        widgetsValues,
        dateRange,
        blast_date_range,
      });
    } catch (err) {
      if (process.env.NODE_ENV !== "production") {
        console.error("Components/Report/index.jsx/init(): debug:", err);
      }
    }
  };

  const handleReportClick = () => {
    let param = JSON.parse(report.params);
    // param.report_type == "mms_dashboard" ? setSms(true) : setSms(false);
    handlePreviewCallback(reportData);
  };

  const handleRemove = async () => {
    setOpenPreRemovePopup(false);
    try {
      const res = await deleteReport(environment, {
        id: reportData.id,
      });
      if (!res.deleteSavedReport) {
        const uncachebleError = getErrorMessage(checkUncatchableError("deleteReportMutation"));
        showNotification(uncachebleError || "Something went wrong.", "error");
      } else {
        showNotification(`Report “${reportData.name}”  has been removed from Favorites`, "success");
      }

      onUpdate();
    } catch (err) {
      if (process.env.NODE_ENV !== "production") {
        console.error("Components/Report/index.jsx/handleRemove(): debug:", { err });
      }
      const uncachebleError = getErrorMessage(checkUncatchableError("deleteReportMutation"));
      showNotification(uncachebleError || "Something went wrong.", "error");
    }
  };

  const handleEdit = async () => {
    setOpenEditNamePopup(false);
    try {
      const res = await editSavedReport(environment, {
        id: reportData.id,
        name: reportName,
      });
      if (!res.editSavedReport) {
        const uncachebleError = getErrorMessage(checkUncatchableError("editSavedReportMutation"));
        showNotification(uncachebleError || "Something went wrong.", "error");
      } else {
        showNotification(`Report “${reportData.name}”  has been edited`, "success");
      }
    } catch (err) {
      if (process.env.NODE_ENV !== "production") {
        console.error("Components/Report/index.jsx/handleEdit(): debug:", { err });
      }
      const uncachebleError = getErrorMessage(checkUncatchableError("editSavedReportMutation"));
      showNotification(uncachebleError || "Something went wrong.", "error");
    }
  };

  const copyToClipboard = () => {
    onCopyLinkCallback(reportData, showNotification, setSelectedItem);
    setCopyText("URL Copied");
    setTimeout(() => {
      setCopyText("Copy URL");
    }, 1500);
  };

  const handleChange = (e) => {
    setReportName(e.target.value);
  };

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  if (!reportData) {
    return null;
  }

  const subDetailsText =
    reportData.propertiesName.length === 0
      ? "None"
      : reportData.propertiesName.length === 1
      ? reportData.propertiesName[0]
      : `${reportData.propertiesName.length} Properties`;

  return (
    <div className={classes.super}>
      <div
        className={classes.imgWrapper}
        onMouseEnter={() => setVisibleActions(true)}
        onMouseLeave={() => setVisibleActions(false)}
      >
        <img src={reportImage} className={classes.imageStyle} alt="" />
        <div className={classes.actionsBox} style={{ display: visibleActions ? "flex" : "none" }}>
          <Button variant="secondary" onClick={handleReportClick} className="viewBtn">
            <span>View Report</span>
          </Button>
          <div className="flex items-center gap-x-3 mt-[20px]">
            <Tooltip position="bottom" tooltip_text="Edit">
              <div
                onClick={setOpenEditNamePopup.bind(this, true)}
                className="group cursor-pointer w-6 flex h-6 rounded-full justify-center items-center bg-white -right-[12px] -top-[12px] z-[10]"
              >
                <EditIcon className="stroke-[#333] group-hover:stroke-[#26BBED]" />
              </div>
            </Tooltip>
            <Tooltip position="bottom" tooltip_text="Copy URL">
              <div
                onClick={copyToClipboard}
                className="group cursor-pointer w-6 flex h-6 rounded-full justify-center items-center bg-white -right-[12px] -top-[12px] z-[10]"
              >
                {copyText === "Copy URL" ? (
                  <LinkIcon className="stroke-[#333] group-hover:stroke-[#26BBED]" />
                ) : (
                  <CopiedIcon width="28px" height="28px" />
                )}
              </div>
            </Tooltip>
            <Tooltip position="bottom" tooltip_text="Delete">
              <div
                onClick={setOpenPreRemovePopup.bind(this, true)}
                className="group cursor-pointer w-6 flex h-6 rounded-full justify-center items-center bg-white -right-[12px] -top-[12px] z-[10]"
              >
                <DeleteIcon className="stroke-[#333] group-hover:stroke-[#E75F75]" />
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
      <div className={classes.details}>
        <div className={classes.menuIcon}>
          {/* <VerticalMenu
            reportData={reportData}
            subOrgDetails={subOrgDetails}
            showNotification={showNotification}
            storeParamsPayload={storeParamsPayload}
          /> */}
        </div>
        <TooltipIfEllipse text={reportData.name}>
          <div className={classes.reportDetails}>{reportData.name}</div>
        </TooltipIfEllipse>
        <div className={classes.filterContainer}>
          {renderParamsLabel(
            JSON.parse(reportData.params),
            reportData,
            toggleDrawer,
            properties,
            orgLevel
          )}
          {/* <TooltipIfEllipse text={subDetailsText}>
            <div
              className={clsx(classes.subDetailsProperty, {
                "cursor-pointer !border-0 !border-b border-solid border-[#DADADA]":
                  reportData.propertiesName.length !== 1,
              })}
              onClick={toggleDrawer}
            >
              {subDetailsText}
            </div>
          </TooltipIfEllipse>
          <div className={classes.divider}></div>
          <div className={classes.subDetails}>
            {reportData.propertiesName.length === 1 ? (
              <div
                className="cursor-pointer leading-3	!border-0 !border-b border-solid border-[#DADADA]"
                onClick={toggleDrawer}
              >
                View all filters
              </div>
            ) : (
              getLabelFromName(reportData.name) ??
              getRangeLabel(reportData.start_date, reportData.end_date)
            )}
          </div> */}
        </div>
        <div className={classes.reportName}>{reportData.analyzeObject}</div>
        {/* <div className={`${classes.subDetails} ${classes.seeAll}`} onClick={toggleDrawer}>
          See All
        </div> */}
      </div>
      <Drawer anchor={"right"} open={openDrawer} onClose={toggleDrawer}>
        <DrawerBody
          properties={properties}
          orgLevel={orgLevel}
          title={reportData?.name}
          onClose={toggleDrawer}
          {...reportData}
        />
      </Drawer>
      <DeleteReportPopup
        header="Favorites"
        open={openPreRemovePopup}
        onRemove={handleRemove}
        onClose={() => setOpenPreRemovePopup(false)}
        name={reportData.name}
      />
      <EditReportPopup
        header="Report Name"
        open={openEditNamePopup}
        onEdit={handleEdit}
        onChange={handleChange}
        onClose={() => setOpenEditNamePopup(false)}
        name={reportData.name}
        value={reportName}
      />
    </div>
  );
};

export default withStyles(styles)(Report);
