/**
 * @flow
 * @relayHash 52fb5daafc1ce2bee7f75a7e0eba05c8
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getAssetCategoryIdQueryVariables = {|
  propertyId: string,
  type?: ?string,
|};
export type getAssetCategoryIdQueryResponse = {|
  +categories: $ReadOnlyArray<{|
    +id: string,
    +name: string,
    +type: string,
  |}>
|};
export type getAssetCategoryIdQuery = {|
  variables: getAssetCategoryIdQueryVariables,
  response: getAssetCategoryIdQueryResponse,
|};
*/


/*
query getAssetCategoryIdQuery(
  $propertyId: ID!
  $type: String
) {
  categories(propertyId: $propertyId, type: $type) {
    id
    name
    type
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "propertyId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "type",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "categories",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "propertyId",
        "variableName": "propertyId"
      },
      {
        "kind": "Variable",
        "name": "type",
        "variableName": "type"
      }
    ],
    "concreteType": "Category",
    "plural": true,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "name",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "type",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getAssetCategoryIdQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "getAssetCategoryIdQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "getAssetCategoryIdQuery",
    "id": null,
    "text": "query getAssetCategoryIdQuery(\n  $propertyId: ID!\n  $type: String\n) {\n  categories(propertyId: $propertyId, type: $type) {\n    id\n    name\n    type\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a7b580c3cfb31aba8f854246dca9e29b';
module.exports = node;
