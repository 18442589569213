import React from "react";

export default function EditPencilUnderlineIcon({ className = "text-[#999999]", size = 18 }) {
  return (
    <svg
      className={className}
      width={size}
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4 14.5H15" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M12 3.5L14 5.5L8 11.5L5 12.5L6 9.5L12 3.5Z"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
