import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import environment from "environments/environment.js";

const mutation = graphql`
  mutation updateMJourneyMutation($input: updateMJourneyInput!) {
    updateMJourney(input: $input) {
      clientMutationId
      mjourney {
        id
        tenantId
        name
        audienceType
        createdAt
        status
        edges
        nodes
      }
    }
  }
`;

function commit(input) {
  return new Promise((res, rej) => {
    commitMutation(environment, {
      mutation,
      variables: { input },
      onCompleted: res,
      onError: rej,
    });
  });
}

export default commit;
