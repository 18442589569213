/**
 * @flow
 * @relayHash 46fbf2e377dcc525ac7378c49f97dda0
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getPurposesQueryVariables = {|
  propertyId: string,
  type?: ?string,
|};
export type getPurposesQueryResponse = {|
  +categories: $ReadOnlyArray<{|
    +name: string,
    +type: string,
  |}>
|};
export type getPurposesQuery = {|
  variables: getPurposesQueryVariables,
  response: getPurposesQueryResponse,
|};
*/


/*
query getPurposesQuery(
  $propertyId: ID!
  $type: String
) {
  categories(propertyId: $propertyId, type: $type) {
    name
    type
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "propertyId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "type",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "propertyId",
    "variableName": "propertyId"
  },
  {
    "kind": "Variable",
    "name": "type",
    "variableName": "type"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "type",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getPurposesQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "categories",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "Category",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "getPurposesQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "categories",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "Category",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "getPurposesQuery",
    "id": null,
    "text": "query getPurposesQuery(\n  $propertyId: ID!\n  $type: String\n) {\n  categories(propertyId: $propertyId, type: $type) {\n    name\n    type\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '41622ff0901dad01bace882e1a49f859';
module.exports = node;
