/**
 * @flow
 * @relayHash c55d9c9feb82cf0eb47436ff895e5321
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getExportDashboardQueryVariables = {|
  dashboardId: string,
  type: string,
|};
export type getExportDashboardQueryResponse = {|
  +exportDashboard: {|
    +filePath: ?string,
    +id: string,
  |}
|};
export type getExportDashboardQuery = {|
  variables: getExportDashboardQueryVariables,
  response: getExportDashboardQueryResponse,
|};
*/


/*
query getExportDashboardQuery(
  $dashboardId: ID!
  $type: String!
) {
  exportDashboard(dashboardId: $dashboardId, type: $type) {
    filePath
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "dashboardId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "type",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "exportDashboard",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "dashboardId",
        "variableName": "dashboardId"
      },
      {
        "kind": "Variable",
        "name": "type",
        "variableName": "type"
      }
    ],
    "concreteType": "ExportDashboard",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "filePath",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getExportDashboardQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "getExportDashboardQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "getExportDashboardQuery",
    "id": null,
    "text": "query getExportDashboardQuery(\n  $dashboardId: ID!\n  $type: String!\n) {\n  exportDashboard(dashboardId: $dashboardId, type: $type) {\n    filePath\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8343adc00765395cdb3c20262fb707cb';
module.exports = node;
