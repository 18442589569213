/**
 * @flow
 * @relayHash cd122d05e35bfe1ea66f9a538c60cd3f
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type gethayleyChatDisclaimerQueryVariables = {|
  orgId: string,
  propertyId: string,
|};
export type gethayleyChatDisclaimerQueryResponse = {|
  +hayleyChatDisclaimer: ?{|
    +orgLevelDisclaimer: ?string,
    +orgLevelDisclaimerEnabled: ?boolean,
    +propertyLevelDisclaimer: ?any,
    +propertyLevelDisclaimerEnabled: ?boolean,
    +disclaimerType: ?string,
  |}
|};
export type gethayleyChatDisclaimerQuery = {|
  variables: gethayleyChatDisclaimerQueryVariables,
  response: gethayleyChatDisclaimerQueryResponse,
|};
*/


/*
query gethayleyChatDisclaimerQuery(
  $orgId: ID!
  $propertyId: ID!
) {
  hayleyChatDisclaimer(orgId: $orgId, propertyId: $propertyId) {
    orgLevelDisclaimer
    orgLevelDisclaimerEnabled
    propertyLevelDisclaimer
    propertyLevelDisclaimerEnabled
    disclaimerType
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "orgId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "propertyId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "hayleyChatDisclaimer",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "orgId",
        "variableName": "orgId"
      },
      {
        "kind": "Variable",
        "name": "propertyId",
        "variableName": "propertyId"
      }
    ],
    "concreteType": "HayleyChatDisclaimer",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "orgLevelDisclaimer",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "orgLevelDisclaimerEnabled",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "propertyLevelDisclaimer",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "propertyLevelDisclaimerEnabled",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "disclaimerType",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "gethayleyChatDisclaimerQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "gethayleyChatDisclaimerQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "gethayleyChatDisclaimerQuery",
    "id": null,
    "text": "query gethayleyChatDisclaimerQuery(\n  $orgId: ID!\n  $propertyId: ID!\n) {\n  hayleyChatDisclaimer(orgId: $orgId, propertyId: $propertyId) {\n    orgLevelDisclaimer\n    orgLevelDisclaimerEnabled\n    propertyLevelDisclaimer\n    propertyLevelDisclaimerEnabled\n    disclaimerType\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f28eea372f5b066cdec4b1234662cebf';
module.exports = node;
