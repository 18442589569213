export const mainStyles = {
  super: {
    // marginTop: "25px",
    // marginRight: "25px",
  },
  imgWrapper: {
    width: "256px",
    height: "221px",
    overflow: "hidden",
    position: "relative",
  },
  actionsBox: {
    background: "rgba(255, 255, 255, 0.9)",
    backdropFilter: "blur(50px)",
    position: "absolute",
    top: 0,
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    flexFlow: "column",
    "& .viewBtn": {
      width: 190,
    },
    "& .rmvBtn": {
      marginTop: 18,
      "&:hover": {
        color: "#E75F5F",
      },
    },
  },
  imageStyle: {
    width: "100%",
    height: "100%",
    cursor: "pointer",
  },
  reportDetails: {
    fontSize: 16,
    lineHeight: "20px",
    fontWeight: 600,
    marginTop: 8,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: 208,
  },
  filterContainer: {
    display: "flex",
    alignItems: "center",
    gap: "6px",
    fontSize: 14,
    color: "#666",
  },
  reportName: {
    fontSize: 12,
    lineHeight: "18px",
    color: "#666",
    marginTop: 2,
  },
  subDetails: {
    color: "#666",
    fontSize: 14,
    lineHeight: "20px",
    fontWeight: 400,
    marginTop: 2,
  },
  divider: {
    width: "4px",
    height: "4px",
    backgroundColor: "#DADADA",
    borderRadius: "50%",
  },
  subDetailsProperty: {
    color: "#666",
    fontSize: 14,
    lineHeight: "12px",
    fontWeight: 400,
    marginTop: 2,
    // whiteSpace: "nowrap",
    // overflow: "hidden",
    // textOverflow: "ellipsis",
    // width: 208,
  },
  seeAll: {
    textDecoration: "underline",
    cursor: "pointer",
    "&:hover": {
      color: "var(--hylyTheme)",
    },
  },
  menuIcon: {
    position: "absolute",
    right: 0,
    top: -12,
  },
  details: {
    position: "relative",
  },
};

export const drawerBodyStyles = {
  super: {
    width: "300px",
    background: "white",
    height: "100vh",
  },
  flexBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "16px 20px",
    fontSize: 16,
    lineHeight: "30px",
    color: "rgba(0, 0, 0, 1)",
    borderBottom: "1px solid #dadada",
  },
  closeIcon: {
    cursor: "pointer",
    height: "24px",
    "&:hover": {
      color: "var(--hylyTheme)",
    },
  },
  drawerTitle: {
    padding: "16px",
    // whiteSpace: "nowrap",
    // overflow: "hidden",
    // textOverflow: "ellipsis",
    // width: 300,
  },
  bodyContainer: {
    padding: "0px 16px",
  },
};

export const verticalMenuStyles = {
  menuicon: {
    color: "#333",
    "&:hover": {
      color: "var(--hylyTheme)",
    },
  },
  menuroot: {
    boxShadow: "0px 4px 10px rgba(204, 204, 204, 0.5)",
    background: "white",
    padding: 0,
    width: "229px",
  },
  listbtn: {
    height: 48,
    "&:hover": {
      backgroundColor: "#ffffff00",
      color: "#eee",
    },

    "&:hover p": {
      color: "#26BBED",
    },
    "&:hover div span i": {
      color: "#26BBED",
    },
  },
  menutext: {
    fontWeight: 400,
    fontSize: 16,
    lineHeight: "24px",
    letterSpacing: "0.44px",
    color: "#333333",
    padding: "10px 14px",
    cursor: "pointer",
    "&:hover": {
      color: "#26BBED",
    },
  },
  list: {
    padding: 0,
  },
  border: {
    borderTop: "1px solid #dadada",
  },
};

export const expansionStyles = {
  super: {
    marginBottom: "20px",
  },
  header: {
    background: "rgba(196, 196, 196, 0.2)",
    color: "#666",
    padding: "8px",
    fontSize: "12px",
    lineHeight: "24px",
    fontWeight: "600",
    fontFamily: "Open Sans",
    height: "32px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: "2px",
  },
  cursor: {
    cursor: "pointer",
  },
  ellipsis: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: 225,
  },
  listItem: {
    color: "#333",
    margin: "6px 0px",
    fontSize: "14px",
    lineHeight: "24px",
    padding: "0px 8px",
    fontFamily: "Open Sans",
  },
  listContainer: {
    maxHeight: "333px",
    overflowY: "scroll",
    visibility: "visible",
  },
};

export const drawerBodyForMenuStyles = {
  super: {
    width: "500px",
    background: "white",
    height: "100vh",
  },
  superDrawer: {
    width: "500px",
    background: "white",
    height: "100vh",
  },
  flexBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "15px 20px",
    fontSize: 20,
    lineHeight: "30px",
    color: "rgba(0, 0, 0, 1)",
    borderBottom: "1px solid #dadada",
  },
  closeIcon: {
    cursor: "pointer",
    "&:hover": {
      color: "var(--hylyTheme)",
    },
  },
  bodyContainer: {
    padding: "0px 20px",
  },
  drawerBodyContainer: {
    padding: "0px",
  },
  bottom: {
    position: "absolute",
    padding: "24px 20px",
    width: "100%",
    bottom: 0,
    textAlign: "right",
  },
  btnStyle: {
    height: 40,
    padding: "10px 24px",
    fontSize: 14,
    lineHeight: "20px",
    fontWeight: 600,
    textTransform: "none",
    width: "auto",
  },
  header: {
    fontSize: 14,
    color: "#333333",
    opacity: "0.7",
    marginBottom: "14px",
    paddingTop: 20,
  },
  subtitle: {
    fontSize: 12,
    color: "#333333",
    opacity: "0.7",
    fontStyle: "italic",
  },
  bottomFlexBox: {
    width: "100%",
    textAlign: "right",
    marginTop: "24px",
    display: "flex",
    marginLeft: "-20px",
  },
  label: {
    fontSize: 12,
    color: "#333333",
    opacity: 0.7,
    lineHeight: "24px",
    fontFamily: "Open Sans",
  },
  emailFieldContainer: {
    paddingRight: "0px",
    marginTop: "14px",
  },
  selectedPlaceholder: {
    marginTop: 2,
    fontSize: 14,
    fontWeight: 600,
    color: "#333333",
    opacity: "0.75",
    width: 430,
  },
  placeholder: {
    color: "#333333",
    fontSize: 14,
    marginTop: 2,
    fontWeight: 600,
    opacity: "0.75",
  },
  openOpacity: {
    opacity: "1",
  },
  selectFieldContainer: {
    width: "100%",
    borderBottom: "1px solid #dadada",
    marginTop: 24,
    marginBottom: 10,
  },
  tabMargin: {
    marginLeft: "-30px",
  },
  drawerTabMargin: {
    padding: "0px",
  },
  scheduledContainer: {
    paddingTop: "20px",
  },
  cardContainer: {
    marginTop: "5px",
  },
  scheduledCard: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    lineHeight: "24px",
    height: "56px",
    borderBottom: "1px solid #DADADA",
    padding: "17px 20px",
  },
  reportDetail: {
    "&:hover": {
      color: "var(--hylyTheme)",
      cursor: "pointer",
    },
  },
  deleteIcon: {
    "&:hover": {
      color: "red",
      cursor: "pointer",
    },
  },
  scheduledPadding: {
    padding: "20px",
  },
  backbtnStyle: {
    display: "flex",
    alignItems: "center",
    gap: 6,
    height: 40,
    width: "100%",
    fontSize: 14,
    paddingLeft: 12,
    borderBottom: "1px solid #DADADA",
    textTransform: "none",
    "&:hover span": {
      cursor: "pointer",
      color: "var(--hylyTheme)",
    },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "end",
    gap: "4px",
    marginRight: 20,
  },
  deleteBtnStyle: {
    height: "32px",
    width: "76px",
    padding: "6px 16px",
  },
  deleteColor: {
    background: "#E75F5F",
    color: "white !important",
    border: "none !important",
    "&:hover": {
      background: "#E75F5F !important",
      color: "white !important",
      border: "none",
    },
  },
  italicStyle: {
    fontStyle: "italic",
    padding: "4px 20px 24px 20px",
    lineHeight: "20px",
    color: "rgba(51, 51, 51, 0.75)",
    fontSize: "14px",
  },
  deletePannel: {
    padding: "32px 20px",
    fontSize: "14px",
  },
  scheduleContainer: {
    marginTop: "10px",
  },
  cardView: {
    maxHeight: "650px",
    overflowY: "auto",
  },
};
