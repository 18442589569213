import React from "react";

export default function PlusIcon({ className = "text-[#333333]", size = 10 }) {
  return (
    <svg
      width={size}
      className={className}
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_197_11661)">
        <path d="M5 0V10" stroke="currentColor" stroke-miterlimit="10" stroke-linecap="square" />
        <path d="M10 5H0" stroke="currentColor" stroke-miterlimit="10" stroke-linecap="square" />
      </g>
      <defs>
        <clipPath id="clip0_197_11661">
          <rect width="10" height="10" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
