import React, { useEffect, useRef, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as utils from "./utils";
import { tableHeatmapStyles } from "./styles";
import showAll from "images/showAll.svg";
import { numberWithCommas } from "content/utils/commonParsing";
import { Box, Skeleton } from "@mui/material";
const qs = require("qs");

const useStyles = makeStyles(tableHeatmapStyles);

const HtmlTooltip = withStyles((props) => ({
  tooltip: {
    background: "#FFFFFF",
    border: "1px solid #DADADA",
    boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.06)",
    borderRadius: 12,
    padding: 12,
    color: "#333",
    display: (props) => (props.show ? "block" : "none"),
  },
}))(Tooltip);

const TestComponent = ({
  classes,
  data,
  columns,
  i,
  ind,
  column,
  colors,
  // maxMetricValue,
  getRouteParameters,
  heatmaps,
  totals,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const columnZeroRef = useRef(null);

  useEffect(() => {
    if (i === 0 && columnZeroRef !== null) {
      if (columnZeroRef.current.scrollWidth > columnZeroRef.current.clientWidth) {
        setShowTooltip(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnZeroRef]);

  return (
    <HtmlTooltip
      placement="bottom-start"
      show={showTooltip}
      title={
        <>
          <Typography className={classes.tooltiptext}>
            {numberWithCommas(data[column.value])}
          </Typography>
        </>
      }
    >
      <div key={"column" + i} className={`${i == 0 || i == columns.length - 1 ? "w-1/3" : ""}`}>
        {!column.parseValue && (
          <div>
            <div
              ref={columnZeroRef}
              onClick={(e) => e.stopPropagation()}
              style={{
                whiteSpace: "nowrap",
                width: column.showBar ? `${data[column.value]}%` : "100%",
                height: 22,
                backgroundColor: column.showBar ? column.color : "inherit",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {
                // eslint-disable-next-line
                column.metric === "1" ? (
                  column.showBar ? (
                    ""
                  ) : (
                    utils.numberWithCommas(data[column.value])
                  )
                ) : (
                  <Link
                    to={`/attribution_analysis_drilldown?${getRouteParameters(
                      data[column.value],
                      column.tag,
                      column.dimension
                    )}`}
                    className={classes.clickable}
                  >
                    {numberWithCommas(column.showBar ? "" : data[column.value])}
                  </Link>
                )
              }
            </div>
          </div>
        )}
        {column.parseValue && (
          <div className="flex justify-between items-center w-fit">
            <span onClick={(e) => e.stopPropagation()}>
              {
                // eslint-disable-next-line
                column.metric === "1" ? (
                  column.parseValue(data[column.value], data, heatmaps, totals, false)
                ) : (
                  <Link
                    to={`/attribution_analysis_drilldown?${getRouteParameters(
                      data[column.labelValue],
                      column.tag,
                      column.dimension
                    )}`}
                    className={classes.clickable}
                  >
                    {column.parseValue(data[column.value], data, heatmaps, totals, false)}
                  </Link>
                )
              }
            </span>
            <span style={{ opacity: 0.5, color: "#333" }}>
              {column.parsePercentage(data[column.value], data, heatmaps, totals, false)}
            </span>
          </div>
        )}
      </div>
    </HtmlTooltip>
  );
};

const TableHeatmap = (props) => {
  const classes = useStyles();

  const [showAll, setShowAll] = useState(false);

  let columns = props.columns;
  let heatmaps = {};
  let totals = {};
  let maximum = {};

  const getRouteParameters = (value, tag, dimension) => {
    let queryparam = { ...utils.getQueryParams() };
    queryparam.value = value;
    queryparam.tag = tag;
    queryparam.dimension = dimension;
    let result = qs.stringify(queryparam, { arrayFormat: "comma" });
    return result;
  };

  const calculateSum = () => {
    let data = props.data;
    let others = {};

    for (let i in data) {
      let row = data[i];

      for (let heatmap of Object.keys(heatmaps)) {
        let val = row[heatmap] || 0;
        heatmaps[heatmap] += val;
        if (!maximum[heatmap]) maximum[heatmap] = 0;

        if (row[heatmap] > maximum[heatmap]) maximum[heatmap] = row[heatmap];

        if (props.limit && parseInt(i) + 1 > parseInt(props.limit) - 1) {
          if (!others[heatmap]) others[heatmap] = 0;

          others[heatmap] += row[heatmap];

          if (others[heatmap] > maximum[heatmap]) maximum[heatmap] = others[heatmap];
        }
      }
    }
  };

  const renderRow = (data, ind) => {
    const colors = [...props.heatmapColor].reverse();
    return columns.map((column, i) => {
      if (column.heatmap || column.sum) {
        if (totals[column.value] === undefined) {
          totals[column.value] = {
            ...column,
            [column.value]: data[column.value],
          };
        } else totals[column.value][column.value] += data[column.value];
      }
      if (column.show === false) return null;
      return (
        <TestComponent
          totals={totals}
          heatmaps={heatmaps}
          getRouteParameters={getRouteParameters}
          colors={colors}
          column={column}
          classes={classes}
          data={data}
          columns={columns}
          i={i}
          ind={ind}
        />
      );
    });
  };

  const dataArr = props.data.slice(0, props.limit && !showAll ? props.limit : props.data.length);

  const renderRows = () => {
    calculateSum();

    return dataArr.map((row, i) => {
      return (
        <TableRow key={"obj.label" + i}>
          <div className={`flex items-center justify-between ${classes.tableCell} p-3`}>
            {renderRow(row, i)}
          </div>
        </TableRow>
      );
    });
  };

  return props.loading ? (
    <Box display="grid" padding={"16px"} gap={"16px"}>
      <Skeleton variant="rectangular" />
      <Skeleton variant="rectangular" />
      <Skeleton variant="rectangular" />
    </Box>
  ) : (
    <>
      <Table onClick={props.onClickTable} aria-label="customized table">
        <TableBody>{renderRows()}</TableBody>
      </Table>
      {props.data.length > props.limit ? (
        <div
          className="m-0 p-3 cursor-pointer text-sm text-hyly-link hover:text-hyly-blue"
          onClick={() => setShowAll(!showAll)}
        >
          <span className="font-semibold">{showAll ? "See less" : `See all`}</span>
          {!showAll && ` (${props.data.length - props.limit} more)`}
        </div>
      ) : null}
    </>
  );
};

export default withRouter(TableHeatmap);
