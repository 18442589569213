import graphql from "babel-plugin-relay/macro";

export default graphql`
  mutation toggleHayleyFeedbackMutation($input: toggleHayleyFeedbackInput!) {
    toggleHayleyFeedback(input: $input) {
      response {
        showFeedbackFilter
      }
    }
  }
`;
