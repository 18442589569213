import emailInputCss from "./lib/email-input-css";
import emailInputJs from "./lib/emails-input";
import emailInputUtil from "./lib/utils";
import emailShareForm from "./email-popup-embed-code";
import smsShareForm from "./sms-popup-embed-code";
import fbShareForm from "./fb-popup-embed-code";
import twitterShareForm from "./twitter-popup-embed-code";
import thanksPopup from "./thanks-popup-embed-code";

function brToNl(str) {
  if (!str) return "";
  str = str.replace(/<\s*\/?br\s*[\/]?>/gi, "\n");
  return str.replace(/(<([^>]+)>)/gi, "");
}
function linkify(inputText) {
  if (!inputText) return inputText;

  const httpsurlregexp = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
  const httpurlregexp = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
  const emailegexp = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
  const hashtagregexp = /#(\w+)/g;

  var replacedText, replacePattern1, replacePattern2, replacePattern3, replacePattern4;

  replacePattern1 = httpsurlregexp;
  replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');

  replacePattern2 = httpurlregexp;
  replacedText = replacedText.replace(
    replacePattern2,
    '$1<a href="http://$2" target="_blank">$2</a>'
  );

  replacePattern3 = emailegexp;
  replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');

  replacePattern4 = hashtagregexp;
  replacedText = replacedText.replace(replacePattern4, '<a href="#">#$1</a>');

  return replacedText;
}

function callOnRender(data) {
  let count = 0;
  if (data.emailEnabled) {
    count++;
  }
  if (data.smsEnabled) {
    count++;
  }
  if (data.facebookEnabled) {
    count++;
  }
  if (data.twitterEnabled) {
    count++;
  }
  return count;
}

const emailButton = `<button id="EmailButton" onclick="openSocialPreview('email-popup')">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M20 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM20 8L12 13L4 8V6L12 11L20 6V8Z" fill="#333333" />
            </svg>
            <span>Email</span>
          </button>`;
const smsButton = `<button id="SmsButton"  onclick="openSocialPreview('sms-popup')">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M20 2H4C2.9 2 2.01 2.9 2.01 4L2 22L6 18H20C21.1 18 22 17.1 22 16V4C22 2.9 21.1 2 20 2ZM13 14H11V12H13V14ZM13 10H11V6H13V10Z" fill="#333333" />
            </svg>
            <span>SMS</span>
          </button>`;
const fbButton = `<button id="FbButton"  onclick="openSocialPreview('fb-popup')">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M24 12.073C24 5.446 18.627 0.072998 12 0.072998C5.373 0.072998 0 5.446 0 12.073C0 18.063 4.388 23.027 10.125 23.927V15.542H7.078V12.072H10.125V9.43C10.125 6.423 11.917 4.761 14.658 4.761C15.97 4.761 17.344 4.996 17.344 4.996V7.949H15.83C14.339 7.949 13.874 8.874 13.874 9.823V12.073H17.202L16.67 15.543H13.874V23.928C19.612 23.027 24 18.062 24 12.073Z" fill="#333333" />
            </svg>
            <span>Facebook</span>
          </button>`;
const twitterButton = `<button id="TwitterButton"  onclick="openSocialPreview('twitter-popup')">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M23.953 4.57003C23.0545 4.96563 22.1026 5.22677 21.128 5.34503C22.1541 4.72828 22.9224 3.76107 23.291 2.62203C22.34 3.17703 21.286 3.58103 20.164 3.80603C19.4238 3.01446 18.4429 2.48945 17.3736 2.31253C16.3044 2.13561 15.2067 2.31668 14.2509 2.82762C13.2952 3.33856 12.5349 4.15078 12.0881 5.13815C11.6412 6.12552 11.533 7.2328 11.78 8.28803C7.69 8.09503 4.067 6.13003 1.64 3.16203C1.19879 3.91184 0.968664 4.76705 0.974 5.63703C0.974 7.34703 1.844 8.85003 3.162 9.73303C2.38086 9.70816 1.61697 9.49696 0.934 9.11703V9.17703C0.933557 10.3134 1.32627 11.415 2.04551 12.2948C2.76474 13.1746 3.76621 13.7785 4.88 14.004C4.1583 14.1974 3.40241 14.2265 2.668 14.089C2.98412 15.067 3.59766 15.922 4.42301 16.5345C5.24837 17.1471 6.24435 17.4867 7.272 17.506C5.53153 18.872 3.38248 19.6133 1.17 19.611C0.78 19.611 0.391 19.588 0 19.544C2.25571 20.9884 4.87851 21.755 7.557 21.753C16.61 21.753 21.555 14.257 21.555 7.76803C21.555 7.55803 21.555 7.34803 21.54 7.13803C22.506 6.44289 23.3392 5.57985 24 4.59003L23.953 4.57003Z" fill="#333333" />
            </svg>
            <span>Twitter</span>
          </button>`;

export default ({
  residentInviteEmailBody,
  residentInviteEmailSubject,
  residentInviteSms,
  residentInviteImage,
  image,
  reward,
  tourCtaUrl,
  friendInviteMenuHeader,
  friendInviteMenuBody,
  ...data
}) => {
  const count = callOnRender(data);
  return `
<style>
@import url("https://fonts.googleapis.com/css?family=Raleway");
@import url("https://fonts.googleapis.com/css?family=Open+Sans");

input:focus {outline:0;} 
button:focus {outline:0;} 
${emailInputCss}

.hyraf-share-form{
  max-width:500px;
  width:100%;
  margin: auto;
  background: white;
  margin-top:140px;
}
.hyraf-share-form-header{
  height: 80px;
  background:${data.orgColors ? data.orgColors.buttonBackgroundColor : "#27bbed"};
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
   color:${data.orgColors ? data.orgColors.buttonFontColor : "white"};
}
.hyraf-share-form-header span{
  font-size: 24px;
  color: white;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
}
.hyraf-share-form-header .close-btn{
  background:transparent;
  border:none;
  font-size: 30px;
  color:white;
  font-weight: 100;
  position: absolute;
  right: 30px;
}

.hyraf-share-form .content{
  padding:20px 20px 30px 20px;
  border: 1px solid #DADADA;
  border-top:none;
}

.hyraf-share-form .content .content-heading{
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
}

.hyraf-share-form .content .content-subheading{
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  margin-top:30px;
}

.hyraf-share-form .content .content-label{
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: rgba(51, 51, 51, 0.75);
  margin-top:30px;
}

.hyraf-share-form .content .content-small-label{
  margin-top:30px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #333333;
}

.hyraf-share-form .content input{
  border: none;
  border-bottom: 1px solid #dadada;
  width: 100%;
  margin-top: 20px;
}

.hyraf-share-form .content .submit-btn{
  width: 240px;
  height: 48px;
  background: #26BBED;
  color:white;
  border:none;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  cursor:pointer;
}
.hyraf-share-form .footer{
  text-align: right;
  margin-top: 20px;
}
.hyraf-copy-link{
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #999999;
  justify-content:space-between;
  border:1px solid #eeeeee;
  height:35px;
  margin-top:10px;
  padding-left:10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.hyraf-copy-link button{
  height: 35px;
  border: none;
  background: transparent;
  width: 80px;
  border-left: 1px solid #eeeeee;
  color: #333333;
  cursor:pointer;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  font-family: "Open Sans", sans-serif;
}

.share-buttons-queue-style{
  display:flex;
  justify-content:${count < 3 ? "space-around" : "space-between"};
  margin-top:10px;
}

.share-buttons-queue-style button{
  width: 146px;
  height: 86px;
  background: #F9F9F9;
  border-radius: 10px;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor:pointer;
}
.share-buttons-queue-style button span{
  margin-top:10px;
  color:#333333;
}
.copied-btn{
  background: #66D184 !important;
  box-shadow: inset 1px 0px 0px #DADADA;
  color:white !important;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.copied-btn svg{
  display:block !important;
}
.copy-btn svg{
  display:none;
} 
@media only screen and (max-width: 480px) {
  .hyraf-share-form-header{
    height: 60px;
  }
  .hyraf-share-form-header span{
    font-size: 18px;
  }
  .hyraf-share-form-header .close-btn{
    right: 10px;
    font-size:25px;
  }
  .hyraf-share-form .content .content-heading{
    font-size: 16px;
    line-height: 20px;
  }

  .share-buttons-queue-style{
    display: grid;
    justify-content: space-between;
    margin-top: 10px;
    gap: 10px;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  }
  .share-buttons-queue-style button{
    width:auto;
    height: 44px;
    background: #F9F9F9;
    border-radius: 10px;
    border: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .share-buttons-queue-style button span{
    margin-top:0;
    margin-left:5px;
  }
  .hyraf-copy-link{
    font-size: 12px;
  }
}

#main-sharemenu{
  display:flex;
}

.hylypopup{
  position :fixed;
  left:0;
  right:0;
  top:0;
  bottom:0;
  padding: 16px;
  display:none;
  align-items:center;
  justify-content:center;
  z-index:1;
}
  
  .copy-url{
    max-width: calc(100% - 85px);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display:flex;
  }

   .copy-url-icon{
    margin-top:2px;
    margin-right: 4px;
  }

</style>
<script>
${emailInputUtil}
</script>
<script>
${emailInputJs}
</script>

<script>



  function copyToClipboard(btn){
    let text = document.getElementById("referralLink").innerText;
    if(text === "https://referlink") return null;
    setTimeout(() => {
      btn.children[1].innerHTML = "Copy";
      btn.className="copy-btn"
    }, 2000);
    navigator.clipboard.writeText(text).then(function () {
      btn.children[1].innerHTML = "Copied";
      btn.className="copied-btn"
    }, function (err) {
      console.error('Async: Could not copy text: ', err);
    });
  }

  function openSocialPreview(id){
    if(${data.preview}) return;
    if(id==='email-popup'){
      let subject = document.getElementById('emailSubjectHyRaf').value;
      let smsBody = document.getElementById('emailBodyHyRaf').value;
      let localData=JSON.parse(localStorage.getItem("enrollData"));
      smsBody = smsBody.replace('Your referral link will be available after enrollment',localData.inviteEmailUrl)
      window.open("https://mail.google.com/mail/u/0/?fs=1&su="+subject+"&body="+smsBody+"&tf=cm")
    }else{
      let popup = document.getElementById(id);
      popup.style.zIndex = 1;
      popup.style.display = "flex";
      document.getElementById("main-sharemenu").style.display = "none";
      if(id !== "thanks-popup")
      document.getElementById("thanks-popup").style.display = "none";
    }
  }

  function closeSocialPreview(id, closeAll){
    let popup = document.getElementById(id);
    popup.style.zIndex = -1;
    popup.style.display = "none";
    popup.style.background = "transparent"
    if(closeAll){
      document.getElementById("main-sharemenu").style.display = "none";
      document.getElementById("main-sharemenu").style.background = "transparent";
      document.getElementById("main-sharemenu").style.zIndex = -1;
      if(closeEnrollForm)
        closeEnrollForm()
    }
    else{
      document.getElementById("main-sharemenu").style.display = "block";
    }
    
  }

</script>


<div id="email-popup" class="hylypopup">
  ${emailShareForm(
    linkify(residentInviteEmailBody),
    residentInviteEmailSubject,
    data.orgColors,
    "test@test.com",
    data
  )}
</div>
<div id="thanks-popup" class="hylypopup">
  ${thanksPopup(reward, tourCtaUrl, data)}
</div>
<div id="twitter-popup" class="hylypopup">
 ${twitterShareForm(linkify(residentInviteSms), image || "", data.orgColors)}
</div >
  <div id="fb-popup" class="hylypopup">
${fbShareForm(linkify(residentInviteSms), image || "", data.orgColors, data.facebookShareUrl || "")}
</div>
  <div id="sms-popup" class="hylypopup">
    ${smsShareForm(linkify(residentInviteSms), data.orgColors, data)}
  </div>
  <div id="main-sharemenu" class="hylypopup" >
    <div class="hyraf-share-form">
      <div class="hyraf-share-form-header">
        <span>${friendInviteMenuHeader}</span>
        <button class="close-btn" onclick="closeEnrollForm()">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19 5L5 19" stroke="white" stroke-miterlimit="10" stroke-linecap="square" />
            <path d="M19 19L5 5" stroke="white" stroke-miterlimit="10" stroke-linecap="square" />
          </svg>
        </button>
      </div>
      <div  class="content">
        <div class="content-heading"> Congratulations! You’ve been successfully enrolled.  </div>
        <div class="content-subheading">${friendInviteMenuBody.replace(/\n/g, "<br/>")}</div>
        <div class="content-small-label">Share Via</div>
        <div id="share-button-container" class="share-buttons-queue-style">
         ${data.emailEnabled ? emailButton : ""}
          ${
            ""
            //data.smsEnabled ?
            //smsButton : ""
          }
         ${data.facebookEnabled ? fbButton : ""}
         ${data.twitterEnabled ? twitterButton : ""}
        </div>

        <div class="content-label">Choose one of the platforms above or copy the link below to share via your preferred method</div>
        <div class="hyraf-copy-link">
          <span class="copy-url" id="referralLink">
          <span class="copy-url-icon">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.1003 9.23349C12.4003 10.5335 12.4003 12.6335 11.1003 13.9335L9.23359 15.8335C7.93359 17.1335 5.83359 17.1335 4.53359 15.8335C3.23359 14.5335 3.23359 12.4335 4.53359 11.1335L6.20026 9.53349" stroke="#333333" stroke-miterlimit="10" stroke-linecap="square" />
              <path d="M9.23379 11.1C7.93379 9.79996 7.93379 7.69996 9.23379 6.39996L11.1005 4.49996C12.4005 3.19996 14.5338 3.19996 15.8338 4.49996C17.1338 5.79996 17.1338 7.89996 15.8338 9.19996L14.1671 10.8333" stroke="#333333" stroke-miterlimit="10" stroke-linecap="square" />
              </svg> 
            </span>
            ${data.enrollment ? data.enrollment.referralLink : "https://referlink"}</span>
          <button class="copy-btn" onclick="copyToClipboard(this)">
            <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4 8.5L6 10.5L11 5.5" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="square" />
            </svg>
            <span>Copy</span>
          </button>
        </div>
      </div>
      <input id="emailSubjectHyRaf" value="${residentInviteEmailSubject}" type="hidden"/>
     <input id="emailBodyHyRaf" value="${brToNl(residentInviteEmailBody)}" type="hidden"/>
      <input id="contactCTAURL" value="${data.contactCtaUrl}" type="hidden"/>
      <input id="tourCTAURL" value="${tourCtaUrl}" type="hidden"/>
    </div>
  </div>

`;
};
