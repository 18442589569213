var errors = {};

export const storeError = (query, error) => {
  errors[query] = error;
};

export const checkUncatchableError = (query) => {
  return errors[query];
};

export const getErrorMessage = (errors, index) => {
  return errors ? (errors[index || 0] ? errors[index || 0].message : null) : null;
};
