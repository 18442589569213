import React from "react";

export default function SimpleCheckIcon({ className = "text-[#333333]", size = 24 }) {
  return (
    <svg
      width={size}
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" fill="white" />
      <rect width="24" height="24" fill="white" />
      <path
        d="M7 13.2L9.85714 16L17 9"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  );
}
