import React from "react";
import graphql from "babel-plugin-relay/macro";
import { QueryRenderer } from "react-relay";
import NavDrawer from "./NavDrawer";
import Loader from "../Loader";

const Changer = ({ classes, initialIDValue, setOriginNavItems, isRentOrg, ...other }) => {
  if (!initialIDValue) {
    return null;
  }
  return (
    <QueryRenderer
      environment={other.environment || (other.relay && other.relay.environment)}
      query={graphql`
        query NavDrawerQuery($id: String) {
          authorizedSuborgs(orgId: "any", first: 1, propertyId: $id) {
            id
            name
            region
            org {
              id
              name
            }
          }
        }
      `}
      variables={{
        id: initialIDValue,
      }}
      render={({ error, props }) => {
        if (Boolean(error)) {
          return <div>{error.message}</div>;
        } else if (props) {
          return (
            <NavDrawer
              {...other}
              initialValue={props.authorizedSuborgs[0]}
              initialIDValue={initialIDValue}
              setOriginNavItems={setOriginNavItems}
              isRentOrg={isRentOrg}
            />
          );
        } else {
          return <Loader />;
        }
      }}
    />
  );
};

export default React.memo(Changer);
