import unauthorized from "images/unauth-property.png";
import Artie from "images/imageArtie.png";

export const widgetHtml = (props) => {
  return `
  <html>
  <style>
    body {
      font-family: Open Sans, sans-serif !important
    }
    .widget-popup{
      width:900px;
      background:white;
      border: 1px solid #DADADA;
      height:540px;
      display:flex;
      align-items:center;
      justify-content:center;
      position:relative;
    }

    .image-widget {
      width: 48px;
      border-radius: 24px;
      box-shadow: 0.6px 1.2px 3px rgba(221, 221, 221, 0.4);
      position: absolute;
      bottom: 16px;
      right: 16px;
    }
    .widget-popup-mid{
      width:450px;
    }
     @media only screen and (max-width: 500px) {
      .widget-popup{ 
        width:auto;
        background:white;
        border: 0px solid #DADADA;
        height:700px;
    }
     }

         body {
      margin: 0 0 0 0;
      -webkit-text-size-adjust: none;
    }

    .super {
      padding: 20px
    }

    .container {
      position: relative;
    }

    .cross-container {
        height: 32px;
        width: 32px;
        border-radius: 16px;
        font-size: 20px;
        border: 1px solid #dadada;
        position: absolute;
        top: -18px;
        right: 2px;
        text-align: center;
        background: white;
    }


    .feature-image {
       display:${props.image && props.image !== "disabled" ? "grid" : "none"};
    }

    .image-style {
      width: 100%;
      height: auto;
    }

    .mid-container {
      padding: 20px !important;
      padding-bottom: 0px !important;
      text-align: center;
      border: 1px solid #dadada;
      display:${(!props.offerCtaText && !props.title) ? "none" : ""}

    }

    .message {
      font-weight: bold;
      text-align: left;
      word-break:break-word;
      display:${(props.title) ? "block" : "none"};
      font-size: 16px;
      margin-bottom: 13px;
    }

    .subtitle{
      font-size:14px;
      text-align: left;
      line-height:20px;
      color:#333333;
      opacity:0.7;
      display:${(props.subTitle) ? "block" : "none"};
      margin-bottom:16px !important;
      word-break:break-word;
    }

    .message p {
      margin: 0px;
      padding: 0px;

    }

    .button-flex-box {
      display: flex;
      justify-content: center
    }

    a {
      text-decoration: none;
    }

    .btn {
      font-weight: 600 !important;
      text-transform: capitalize;
      text-decoration: none;
      margin: auto;
      outline: none;
      word-break:break-word;
      cursor: pointer;
        width: 133px ;
        min-height: 26px;
        line-height: 24px ;
        font-weight: 500;
        font-size: 14px;
        margin-bottom: 21px;
         border-radius:0px
    }

    .refer-btn-1 {
       background:${props.orgColors ? props.orgColors.buttonBackgroundColor : "#27bbed"};
      color:${props.orgColors ? props.orgColors.buttonFontColor : "white"};
      border-color:${props.orgColors ? props.orgColors.buttonBorderColor : "white"};
      margin-right: 8px;
      display: block;
      border: none;
      display:${props.offerCtaText ? "block" : "none"};
      width:-webkit-fill-available;
    }
     .refer-btn-1:hover {
        color:${props.orgColors ? props.orgColors.buttonFontColor : "white"};
     }
 .btn:focus{
      box-shadow:none;
    }


    @media only screen and (max-width: 350px) {
     .button-flex-box {
        display: block;
        justify-content: center
      }

      .message {
        font-size: 20px !important;
      }
       .refer-btn-1 {
         margin: auto;
       }
        .refer-btn-2 {
         margin: auto;
       }
       .btn {
         width:250px; 
         min-height:48px; 
         margin-bottom: 16px !important;
         font-size:16px;
       }

      .super{
        text-align: center;
        padding: 16px;
        position: absolute;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        min-width: -webkit-fill-available
      }
     .cross-container{
        height:20px;
        width:20px;
        border-radius:10px;
        top: -12px;
        right: -12px;
        font-size:15px;
      }
    }
  </style>
   <div class="widget-popup">
    <div class="widget-popup-mid">
     <div class="super">
    <div class="container">
      <div class="cross-container">
        <span>&times;</span>
      </div>
      <div class="feature-image">
        <img
          src="${props.image}"
          alt="banner" class="image-style" />
      </div>

      <div class="mid-container">
        <div class="message">${props.title}</div>
        <div class="subtitle">${props.subTitle}</div>
          <a href="${props.offer_cta_url}" target="_blank">
            <button class="btn refer-btn-1" type="button">
            ${props.offerCtaText}
            </button>
          </a>
      </div>
    </div>
  </div>
    </div>
    <div class="image-widget">
    <img src=${Artie} width="48px" style="display:block;" alt="error" />
    </div>
  </div>
  </html>`;
};
