/**
 * @flow
 * @relayHash 58a3fb985cda1d20b90827c70cd2f38e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getListPropertiesQueryVariables = {|
  dashboardId: string,
  selectedPropertyIds?: ?$ReadOnlyArray<string>,
  drip?: ?boolean,
|};
export type getListPropertiesQueryResponse = {|
  +listProperties: $ReadOnlyArray<{|
    +id: string,
    +name: ?string,
    +campaignsCount: ?string,
    +region: ?string,
  |}>
|};
export type getListPropertiesQuery = {|
  variables: getListPropertiesQueryVariables,
  response: getListPropertiesQueryResponse,
|};
*/


/*
query getListPropertiesQuery(
  $dashboardId: ID!
  $selectedPropertyIds: [String!]
  $drip: Boolean
) {
  listProperties(dashboardId: $dashboardId, selectedPropertyIds: $selectedPropertyIds, drip: $drip) {
    id
    name
    campaignsCount
    region
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "dashboardId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "selectedPropertyIds",
    "type": "[String!]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "drip",
    "type": "Boolean",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "listProperties",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "dashboardId",
        "variableName": "dashboardId"
      },
      {
        "kind": "Variable",
        "name": "drip",
        "variableName": "drip"
      },
      {
        "kind": "Variable",
        "name": "selectedPropertyIds",
        "variableName": "selectedPropertyIds"
      }
    ],
    "concreteType": "PropertySummary",
    "plural": true,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "name",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "campaignsCount",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "region",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getListPropertiesQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "getListPropertiesQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "getListPropertiesQuery",
    "id": null,
    "text": "query getListPropertiesQuery(\n  $dashboardId: ID!\n  $selectedPropertyIds: [String!]\n  $drip: Boolean\n) {\n  listProperties(dashboardId: $dashboardId, selectedPropertyIds: $selectedPropertyIds, drip: $drip) {\n    id\n    name\n    campaignsCount\n    region\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a486fc13b8f1ed601d61ac2ef0a0d3e3';
module.exports = node;
