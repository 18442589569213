/**
 * @flow
 * @relayHash 2491961dbadbeaa4ea5bc64b81af924b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getcontactGroupsQueryVariables = {|
  propertyId: string,
  refreshGroupName?: ?string,
|};
export type getcontactGroupsQueryResponse = {|
  +contactGroups: $ReadOnlyArray<{|
    +id: string,
    +name: string,
    +status: string,
    +count: number,
    +createdAt: any,
    +reachableCount: number,
    +mmsCount: number,
    +mmsReachableCount: number,
    +expression: ?any,
    +customId: string,
  |}>
|};
export type getcontactGroupsQuery = {|
  variables: getcontactGroupsQueryVariables,
  response: getcontactGroupsQueryResponse,
|};
*/


/*
query getcontactGroupsQuery(
  $propertyId: ID!
  $refreshGroupName: String
) {
  contactGroups(propertyId: $propertyId, refreshGroupName: $refreshGroupName) {
    id
    name
    status
    count
    createdAt
    reachableCount
    mmsCount
    mmsReachableCount
    expression
    customId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "propertyId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "refreshGroupName",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "contactGroups",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "propertyId",
        "variableName": "propertyId"
      },
      {
        "kind": "Variable",
        "name": "refreshGroupName",
        "variableName": "refreshGroupName"
      }
    ],
    "concreteType": "ContactGroup",
    "plural": true,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "name",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "status",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "count",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "createdAt",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "reachableCount",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "mmsCount",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "mmsReachableCount",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "expression",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "customId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getcontactGroupsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "getcontactGroupsQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "getcontactGroupsQuery",
    "id": null,
    "text": "query getcontactGroupsQuery(\n  $propertyId: ID!\n  $refreshGroupName: String\n) {\n  contactGroups(propertyId: $propertyId, refreshGroupName: $refreshGroupName) {\n    id\n    name\n    status\n    count\n    createdAt\n    reachableCount\n    mmsCount\n    mmsReachableCount\n    expression\n    customId\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '33e26a5c53da0b74ca31eb65f4049ade';
module.exports = node;
