/**
 * @flow
 * @relayHash 4b51a315ce7ff9a692fefc642c7bba83
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type setHayleyChatDisclaimerInput = {|
  orgId: string,
  propertyId: string,
  disclaimer: any,
  disclaimerEnabled: boolean,
  disclaimerType?: ?string,
|};
export type setHayleyChatDisclaimerMutationVariables = {|
  input: setHayleyChatDisclaimerInput
|};
export type setHayleyChatDisclaimerMutationResponse = {|
  +setHayleyChatDisclaimer: {|
    +disclaimer: ?any,
    +disclaimerEnabled: ?boolean,
  |}
|};
export type setHayleyChatDisclaimerMutation = {|
  variables: setHayleyChatDisclaimerMutationVariables,
  response: setHayleyChatDisclaimerMutationResponse,
|};
*/


/*
mutation setHayleyChatDisclaimerMutation(
  $input: setHayleyChatDisclaimerInput!
) {
  setHayleyChatDisclaimer(input: $input) {
    disclaimer
    disclaimerEnabled
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "setHayleyChatDisclaimerInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "setHayleyChatDisclaimer",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "HayleyChatDisclaimerPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "disclaimer",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "disclaimerEnabled",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "setHayleyChatDisclaimerMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "setHayleyChatDisclaimerMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "setHayleyChatDisclaimerMutation",
    "id": null,
    "text": "mutation setHayleyChatDisclaimerMutation(\n  $input: setHayleyChatDisclaimerInput!\n) {\n  setHayleyChatDisclaimer(input: $input) {\n    disclaimer\n    disclaimerEnabled\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6049e498c5ddc4b7699de65315faf81b';
module.exports = node;
