import React from "react";
import PropTypes from "prop-types";
import SimpleList from "./SearchList/SimpleList";
import DropDownSearch from "./SearchList/DropDownSearch";
import SearchSelect from "./SearchList/SearchSelect";
import SearchSelectCreateNewField from "./SearchList/SearchSelectCreateNewField";
import IconSelect from "./SearchList/IconSelect";

const SearchListComponent = ({ form, field, type, selected_value_type, ...other }) => {
  const Component = React.useMemo(() => {
    switch (type) {
      case "search":
        return DropDownSearch;
      case "icon-search":
        return IconSelect;
      case "search-select-field":
        return SearchSelect;
      case "search-select-button":
        return SearchSelectCreateNewField;
      default:
        return SimpleList;
    }
  }, [type]);

  return <Component {...other} />;
};

SearchListComponent.propTypes = {
  input_props: PropTypes.object,
  icon_type: PropTypes.string,
  search_value: PropTypes.string,
  selected_value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  onSelect: PropTypes.func,

  // formik implementation
  name: PropTypes.string.isRequired,
  selected_value_type: PropTypes.string.isRequired,
};

SearchListComponent.defaultProps = {
  name: "search-list-field",
  selected_value_type: "name",
};

//export default React.memo(SearchListComponent);
export default React.memo(SearchListComponent);
