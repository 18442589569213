import RelayQueryResponseCache from "relay-runtime/lib/RelayQueryResponseCache";
import { Auth } from "aws-amplify";
import { storeError } from "./uncatchableErrorStore";
import { emitCustomEvent } from "react-custom-events";
import { EVENT_CODES } from "constants/index";

const changeIds = (obj, listOfKeys) => {
  let keys = listOfKeys || Object.keys(obj);
  let o = obj;
  keys.forEach((key, index) => {
    if (o[key]) {
      if (key == "org") {
        o[key] = changeIds(obj[key], listOfKeys);
      } else {
        if (o[key].id) o[key].id = index + "-" + o[key].id;
      }
    }
  });
  return o;
};

const fixDuplicacy = (list = [], listOfKeys) => {
  return list.map((obj) => {
    return changeIds(obj, listOfKeys);
  });
};

const { Environment, Network, RecordSource, Store } = require("relay-runtime");

// let users = {
//   dev: "userid___1688612088738358931",
//   qa: "userid___1688612088738358931",
//   stg: "userid___1690752509700806004",
//   production: "userid___1690752509700806004",
// };

async function get_myhyly_token(operation) {
  if (
    operation === "gethyblastEditorPreviewQuery" ||
    operation === "getTemplatePreviewQuery" ||
    operation === "getDripPreviewQuery"
  ) {
    return "myhyly-hyblast-sync";
  }
  if (operation === "getOmichannelPreviewsQuery") return "";
  if (operation === "checkEnrollmentOfHyreferralCampaignQuery") return "";
  if (operation === "getEnrollmentQuery") return "";
  if (operation === "getPublicOffersQuery") return "";
  if (operation === "getListPublicOffersQuery") return "";
  if (window.location.hostname === "localhost") {
    return "userid___1504795551925462670";
  }

  const user = await Auth.currentAuthenticatedUser();
  return user.signInUserSession.idToken.jwtToken;
}

const cache = new RelayQueryResponseCache({
  size: 250,
  ttl: 60 * 5 * 1000,
});

const store = new Store(new RecordSource());

const network = Network.create(async (operation, variables) => {
  const token = await get_myhyly_token(operation.name);
  console.log(`qa: token: ${token}`);

  let queryId = operation.name;

  if (queryId === "getEventsQuery") {
    cache.clear();
  }

  let cacheResponse = cache.get(queryId, variables);

  if (cacheResponse) return cacheResponse;
  const response = await fetch(process.env.REACT_APP_MyhylyEndpoint, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify({
      query: operation.text,
      variables,
    }),
  });

  const data = await response.json();

  if (
    queryId === "getApprovalRequestsQuery" ||
    queryId === "getApproverSettingsQuery" ||
    queryId === "getSuborgDetailsQuery" ||
    queryId === "getSelectedSuborgDetailsQuery"
  ) {
    data.data.authorizedSuborgs = fixDuplicacy(data.data.authorizedSuborgs, [
      "hyblastSetting",
      "hyeventsSetting",
      "org",
    ]);
  }

  if (response.status === 401) {
    emitCustomEvent(EVENT_CODES.UNAUTH_USER_TOAST);
  }
  storeError(queryId, data.errors, response, variables);

  if (operation.operationKind === "mutation") {
    cache.clear();
  }
  return data;
});

export const getToken = async () => {
  if (window.location.hostname === "localhost") {
    return "userid___1504795551925462670";
  }

  const user = await Auth.currentAuthenticatedUser();
  return user.signInUserSession.idToken.jwtToken;
};

export const CreateGptFieldContent = async (variables, updatedValue, token) => {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    const graphql = JSON.stringify({
      query: `mutation{\n  createGptHistoryField(input: {\n    id: "${variables.id}",\n    type: "${variables.type}",\n    value: "${variables.value}"\n    \n  }) {\n  	gptHistoryField{\n      id\n	  	value\n      fieldType\n    }\n  }\n}`,
      variables: variables,
    });
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: graphql,
      redirect: "follow",
    };

    const response = await fetch(process.env.REACT_APP_MyhylyEndpoint, requestOptions);
    const result = await response.json();
    return result?.data?.createGptHistoryField?.gptHistoryField;
  } catch (err) {
    console.log("errr", err);
  }
};

export const saveGpthistoryFeedback = async (variables, updatedValue, token) => {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    const graphql = JSON.stringify({
      query: `mutation {\n saveGptHistoryFieldFeedback({\n id:"${variables.id}",\n value:"${variables.value}",\n}) {\n  gptHistoryField {\n feedback\n fieldType\n id\n value\n }\n }\n } `,
      variables: variables,
    });
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: graphql,
      redirect: "follow",
    };

    const response = await fetch(process.env.REACT_APP_MyhylyEndpoint, requestOptions);
    const result = await response.json();
    return result?.data?.saveGptHistoryFieldFeedback?.gptHistoryField;
  } catch (err) {
    console.log("errr", err);
  }
};

const environment = new Environment({
  network,
  store,
});

export default environment;
