/**
 * @flow
 * @relayHash 0c5aa8cadc3fbfd4ff3d9369acc27002
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type scheduleReportInput = {|
  clientMutationId?: ?string,
  orgId: string,
  type: string,
  recipients?: ?$ReadOnlyArray<?string>,
  day: string,
  range: string,
  frequency: string,
  reportUrl?: ?string,
  reportType?: ?string,
  name?: ?string,
|};
export type scheduleReportMutationVariables = {|
  input: scheduleReportInput
|};
export type scheduleReportMutationResponse = {|
  +scheduleReport: ?{|
    +scheduledReport: ?{|
      +id: string,
      +type: string,
      +org: {|
        +id: string,
        +name: string,
        +userRole: ?string,
      |},
      +recipients: $ReadOnlyArray<string>,
      +day: string,
      +range: string,
    |}
  |}
|};
export type scheduleReportMutation = {|
  variables: scheduleReportMutationVariables,
  response: scheduleReportMutationResponse,
|};
*/


/*
mutation scheduleReportMutation(
  $input: scheduleReportInput!
) {
  scheduleReport(input: $input) {
    scheduledReport {
      id
      type
      org {
        id
        name
        userRole
      }
      recipients
      day
      range
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "scheduleReportInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "scheduleReport",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "scheduleReportPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "scheduledReport",
        "storageKey": null,
        "args": null,
        "concreteType": "ScheduledReport",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "type",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "org",
            "storageKey": null,
            "args": null,
            "concreteType": "Org",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "name",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "userRole",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "recipients",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "day",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "range",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "scheduleReportMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "scheduleReportMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "scheduleReportMutation",
    "id": null,
    "text": "mutation scheduleReportMutation(\n  $input: scheduleReportInput!\n) {\n  scheduleReport(input: $input) {\n    scheduledReport {\n      id\n      type\n      org {\n        id\n        name\n        userRole\n      }\n      recipients\n      day\n      range\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '18212d556b13b53d3051f5b4dbcaf431';
module.exports = node;
