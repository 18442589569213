import graphql from "babel-plugin-relay/macro";

export default graphql`
  query getContactListQuery(
    $orgId: ID!
    $campaignIds: [String!]!
    $type: String
    $startAt: String
    $endAt: String
    $referred: String
    $leased: String
  ) {
    contactList(
      orgId: $orgId
      campaignIds: $campaignIds
      type: $type
      startAt: $startAt
      endAt: $endAt
      referred: $referred
      leased: $leased
    ) {
      campaignActive
      campaignId
      campaignName
      createdAt
      friend
      friendCrmUrl
      friendEmail
      friendPhone
      friendsCount
      leased
      leasedCount
      leasedDate
      propertyId
      propertyName
      referredStatus
      resident
      residentCrmUrl
      residentEmail
      residentPhone
      residentsCount
      utmAssist
      utmCampaign
      utmChannel
      utmMedium
      utmMethod
      utmSource
    }
  }
`;
