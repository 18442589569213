import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Collapse from "@mui/material/Collapse";
import ListItemIcon from "@mui/material/ListItemIcon";
import ShortMenu from "./shortMenu";
import "./tooltip.css";
import { PremiumIcon, UnAuthIcon, DownArrowIcon, DotIcon } from "../icons";
import Submenu from "./submenuItem";
import ShortSubmenu from "./shortSubmenu";
import { is_parent, create_path, check_if_selected, check_if_child_selecetd } from "../utils";
import clsx from "clsx";
// import Tooltip from "@mui/material/Tooltip";
import Tooltip from "storybookComponents/Tooltip2";
import _ from "lodash";
import { setSelectedCampaigns, setSelectedProperties } from "redux/slices/blast-slice";
import { useDispatch } from "react-redux";

const MenuItem = ({
  link_component,
  is_online,
  menu_open,
  pathname,
  onClose,
  swipeable,
  to: parent_to,
  moduleName,
  icon_type,
  title,
  disabled_when_offline,
  children,
  transfer,
  org,
  customStyle,
  accessType,
  classes, // custom style prop for the special cases
  data_open,
  currentTarget,
  clear_target,
  open_data,
  matchesSwipeable,
  tour_menu,
  back,
  setOriginNavItems,
  orgLevel,
  header,
  level,
  setShowOrgPropertySwitchConfirmModal,
  setSelectedNavItem4Replace,
}) => {
  const {
    icon_class_container,
    listItem,
    listItemSelected,
    submenuPopover,
    textItem,
    selected,
    drop_down_icon_open,
    drop_down_icon_normal,
    drop_down_icon_left,
    submenu,
    textStyle,
    iconTypeAlign,
    textHidden,
    marginStyle,
    dotIcon,
    marginLeftSyle,
    customWidth,
    headerListItem,
  } = classes;
  const dispatch = useDispatch();

  const isParent = React.useMemo(() => is_parent(children), [children]);
  const pathTo = React.useMemo(() => create_path(parent_to, moduleName), [parent_to, moduleName]);

  const child_selected = React.useMemo(
    () => check_if_child_selecetd(children, pathname),
    [children, pathname]
  );
  const parent_selected =
    check_if_selected(children, parent_to, pathname, "strict") || child_selected;

  const renderSubmenu = (item, index) => (
    <Submenu
      key={index}
      {...item}
      pathname={pathname}
      link_component={link_component}
      parent_to={parent_to}
      clear_target={clear_target}
      parent_selected={parent_selected}
      menu_open={menu_open}
      swipeable={swipeable}
      onClose={onClose}
      classes={classes}
      onMenuItemClick={onMenuItemClick}
    />
  );

  const renderShortSubmenu = (item, index) => (
    <ShortSubmenu
      key={index}
      {...item}
      pathname={pathname}
      link_component={link_component}
      parent_to={parent_to}
      clear_target={() => {
        dispatch(setSelectedCampaigns([]));
        dispatch(setSelectedProperties([]));
        clear_target();
      }}
      classes={classes}
    />
  );

  const onMenuItemClick = (e, item) => {
    dispatch(setSelectedCampaigns([]));
    dispatch(setSelectedProperties([]));
    if (item.level === "property") {
      if (orgLevel) {
        e.preventDefault();
        const param = {
          appName: item.title,
          level: "property",
          to: item.to,
        };
        setSelectedNavItem4Replace(param);
        setShowOrgPropertySwitchConfirmModal(true);
        return;
      }
    }
    if (item.level === "org") {
      if (!orgLevel) {
        e.preventDefault();
        const param = {
          appName: item.title,
          level: "org",
          to: item.to,
        };
        setSelectedNavItem4Replace(param);
        setShowOrgPropertySwitchConfirmModal(true);
        return;
      }
    }
  };

  const handleMenuClick = (e) => {
    if (accessType === "unauthorised") {
      window.location.href = "/unauthorized/" + title;
    }
    if (level === "property" && !_.isArray(children)) {
      if (orgLevel) {
        e.preventDefault();
        const item = {
          appName: title,
          level: "property",
          to: pathTo,
        };
        setSelectedNavItem4Replace(item);
        setShowOrgPropertySwitchConfirmModal(true);
        return;
      }
    }
    if (level === "org" && !_.isArray(children)) {
      if (!orgLevel) {
        e.preventDefault();
        const item = {
          appName: title,
          level: "org",
          to: pathTo,
        };
        setSelectedNavItem4Replace(item);
        setShowOrgPropertySwitchConfirmModal(true);
        return;
      }
    }
    if (back) {
      e.preventDefault();
      if (header) {
        if (e.target.nodeName == "svg") {
          setOriginNavItems(moduleName, orgLevel);
        } else {
          return null;
        }
      }
      setOriginNavItems(moduleName, orgLevel);
      return;
    }
    if (tour_menu) {
      e.preventDefault();
      window.open(tour_menu);
      return;
    }
    return isParent && Boolean(is_online || !disabled_when_offline)
      ? open_data(title, e)
      : swipeable
      ? onClose(e)
      : null;
  };

  return (
    <>
      <Tooltip
        customClasses={classes}
        position="right"
        text={!matchesSwipeable || menu_open || title === "" ? "" : title}
      >
        <ListItem
          className="n-drawer-tooltip"
          selected={parent_selected}
          component={isParent || back ? "div" : link_component}
          to={pathTo}
          onClick={handleMenuClick}
          classes={{
            root: clsx(listItem, header && headerListItem),
            selected: listItemSelected,
          }}
        >
          <ListItemIcon className={icon_class_container}>
            <div className="n-icon-container n-drawer-tooltip">
              <div className={clsx(!menu_open && !org && iconTypeAlign)}>{icon_type}</div>
            </div>
          </ListItemIcon>
          <div
            className={clsx(
              textStyle,
              customStyle?.label,
              parent_selected && !child_selected ? selected : textItem,
              !matchesSwipeable && !menu_open && textHidden,
              menu_open ? marginLeftSyle : customWidth
            )}
          >
            {/* title of menu submenu */}
            {swipeable || menu_open ? title : ""}
            {/* unauth and premium icons */}
            {menu_open && (
              <span className={marginStyle}>
                {accessType === "premium" ? (
                  <PremiumIcon />
                ) : accessType === "unauthorised" ? (
                  <UnAuthIcon />
                ) : null}
              </span>
            )}
          </div>
          {isParent &&
            (menu_open ? (
              <DownArrowIcon
                className={clsx(!data_open[title] ? drop_down_icon_left : drop_down_icon_normal)}
              />
            ) : (
              <DotIcon className={dotIcon} />
            ))}
          {transfer &&
            (menu_open ? (
              <DownArrowIcon className={drop_down_icon_open} />
            ) : (
              <DotIcon className={dotIcon} />
            ))}
        </ListItem>
      </Tooltip>
      {isParent && (
        <>
          <Collapse in={menu_open && data_open[title]} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {children.map(renderSubmenu)}
            </List>
          </Collapse>
          <ShortMenu
            open={Boolean(!menu_open && currentTarget[title] && !swipeable)}
            classes={submenu}
            popoverClasses={{ paper: submenuPopover }}
            anchorEl={currentTarget[title]}
            handleClose={clear_target}
          >
            <List component="div" disablePadding>
              {children.map(renderShortSubmenu)}
            </List>
          </ShortMenu>
        </>
      )}
    </>
  );
};

export default React.memo(MenuItem);
