import React from "react";

const ClosePopupIcon = () => {
  return (
    <svg width={17} height={17} viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.5 15.5L1.50003 1.5"
        stroke="black"
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
};

export default ClosePopupIcon;
