import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";
import environment from "environments/environment.js";

const mutation = graphql`
  mutation setHayleyChatDisclaimerMutation($input: setHayleyChatDisclaimerInput!) {
    setHayleyChatDisclaimer(input: $input) {
      disclaimer
      disclaimerEnabled
    }
  }
`;

export default function (input) {
  return new Promise((res, rej) => {
    commitMutation(environment, {
      mutation,
      variables: {
        input: {
          orgId: input.orgId,
          propertyId: input.propertyId,
          disclaimer: input.disclaimer,
          disclaimerEnabled: input.disclaimerEnabled,
          disclaimerType: input.disclaimerType,
        },
      },
      onCompleted: res,
      onError: rej,
    });
  });
}
