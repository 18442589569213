/**
 * @flow
 * @relayHash d2614dbb66680cdd3cc8e86eca7aa603
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getDashboardMetricsQueryVariables = {|
  dashboardId: string,
  params?: ?any,
|};
export type getDashboardMetricsQueryResponse = {|
  +dashboardMetrics: {|
    +browserSeries: ?string,
    +countrySeries: ?string,
    +osSeries: ?string,
  |}
|};
export type getDashboardMetricsQuery = {|
  variables: getDashboardMetricsQueryVariables,
  response: getDashboardMetricsQueryResponse,
|};
*/


/*
query getDashboardMetricsQuery(
  $dashboardId: ID!
  $params: Json
) {
  dashboardMetrics(dashboardId: $dashboardId, params: $params) {
    browserSeries
    countrySeries
    osSeries
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "dashboardId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "params",
    "type": "Json",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "dashboardId",
    "variableName": "dashboardId"
  },
  {
    "kind": "Variable",
    "name": "params",
    "variableName": "params"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "browserSeries",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "countrySeries",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "osSeries",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getDashboardMetricsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "dashboardMetrics",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "PlatformStatsDashboard",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "getDashboardMetricsQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "dashboardMetrics",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "PlatformStatsDashboard",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "getDashboardMetricsQuery",
    "id": null,
    "text": "query getDashboardMetricsQuery(\n  $dashboardId: ID!\n  $params: Json\n) {\n  dashboardMetrics(dashboardId: $dashboardId, params: $params) {\n    browserSeries\n    countrySeries\n    osSeries\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '844b374b234f597f7d2e524a35ac14a2';
module.exports = node;
