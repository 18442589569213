/**
 * @flow
 * @relayHash 1878579a979b13b31846aa194369948f
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type updateOfferInput = {|
  clientMutationId?: ?string,
  updateOffer?: ?OfferInput,
|};
export type OfferInput = {|
  campaignTitle?: ?string,
  title?: ?string,
  id?: ?string,
  orgId?: ?string,
  subTitle?: ?string,
  image?: ?string,
  description?: ?string,
  offerCtaText?: ?string,
  offerCtaUrl?: ?string,
  designOffer?: ?string,
  emailTitle?: ?string,
  emailSubTitle?: ?string,
  emailSubject?: ?string,
  emailDescription?: ?string,
  active?: ?string,
|};
export type updateOfferMutationVariables = {|
  input: updateOfferInput
|};
export type updateOfferMutationResponse = {|
  +updateOffer: ?{|
    +clientMutationId: ?string,
    +offer: ?{|
      +active: ?boolean,
      +deletedAt: ?any,
      +description: ?string,
      +designOffer: ?string,
      +id: string,
      +image: ?{|
        +fileUrl: string,
        +id: string,
        +thumbnailUrl: ?string,
      |},
      +offerCtaText: ?string,
      +offerCtaUrl: ?string,
      +orgId: string,
      +orgName: ?string,
      +shortTourUrl: ?string,
      +subTitle: ?string,
      +title: ?string,
      +propertyCount: string,
      +campaignTitle: ?string,
      +prospectInviteEmailCampaignHtml: string,
      +prospectInviteEmailCampaignId: string,
      +prospectInviteEmailSubject: string,
      +abortProspectInviteEmailCampaignId: ?string,
      +facebookSharedCount: string,
      +emailScheduledCount: ?string,
      +isSent: ?boolean,
    |},
  |}
|};
export type updateOfferMutation = {|
  variables: updateOfferMutationVariables,
  response: updateOfferMutationResponse,
|};
*/


/*
mutation updateOfferMutation(
  $input: updateOfferInput!
) {
  updateOffer(input: $input) {
    clientMutationId
    offer {
      active
      deletedAt
      description
      designOffer
      id
      image {
        fileUrl
        id
        thumbnailUrl
      }
      offerCtaText
      offerCtaUrl
      orgId
      orgName
      shortTourUrl
      subTitle
      title
      propertyCount
      campaignTitle
      prospectInviteEmailCampaignHtml
      prospectInviteEmailCampaignId
      prospectInviteEmailSubject
      abortProspectInviteEmailCampaignId
      facebookSharedCount
      emailScheduledCount
      isSent
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "updateOfferInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateOffer",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "updateOfferPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "clientMutationId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "offer",
        "storageKey": null,
        "args": null,
        "concreteType": "Offer",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "active",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "deletedAt",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "description",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "designOffer",
            "args": null,
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "image",
            "storageKey": null,
            "args": null,
            "concreteType": "Asset",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "fileUrl",
                "args": null,
                "storageKey": null
              },
              (v1/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "thumbnailUrl",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "offerCtaText",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "offerCtaUrl",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "orgId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "orgName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "shortTourUrl",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "subTitle",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "title",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "propertyCount",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "campaignTitle",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "prospectInviteEmailCampaignHtml",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "prospectInviteEmailCampaignId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "prospectInviteEmailSubject",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "abortProspectInviteEmailCampaignId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "facebookSharedCount",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "emailScheduledCount",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "isSent",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "updateOfferMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "updateOfferMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "updateOfferMutation",
    "id": null,
    "text": "mutation updateOfferMutation(\n  $input: updateOfferInput!\n) {\n  updateOffer(input: $input) {\n    clientMutationId\n    offer {\n      active\n      deletedAt\n      description\n      designOffer\n      id\n      image {\n        fileUrl\n        id\n        thumbnailUrl\n      }\n      offerCtaText\n      offerCtaUrl\n      orgId\n      orgName\n      shortTourUrl\n      subTitle\n      title\n      propertyCount\n      campaignTitle\n      prospectInviteEmailCampaignHtml\n      prospectInviteEmailCampaignId\n      prospectInviteEmailSubject\n      abortProspectInviteEmailCampaignId\n      facebookSharedCount\n      emailScheduledCount\n      isSent\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b19743000c13541c829c70f638035e08';
module.exports = node;
