import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userList: [],
  regionalMList: [],
};

export const accountListSlice = createSlice({
  name: "accountList",
  initialState,
  reducers: {
    setAccountList: (state, action) => {
      state.userList = action.payload;
    },
    setRegionalMList: (state, action) => {
      state.regionalMList = action.payload;
    },
  },
});

export const { setAccountList, setRegionalMList } = accountListSlice.actions;

export const selectRegionalMList = (state) => state.accountList.regionalMList;

export default accountListSlice.reducer;
