import React from "react";

export default function TriggerListIcon({ className = "text-[#333333]", width = 32, height = 32 }) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5 23.5H8.5V8.5H21.5V13.5"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5 11.5H18.5"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5 14.5H15.5"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5 17.5H13.5"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5 20.5H13.5"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.5 23.5C21.7091 23.5 23.5 21.7091 23.5 19.5C23.5 17.2909 21.7091 15.5 19.5 15.5C17.2909 15.5 15.5 17.2909 15.5 19.5C15.5 21.7091 17.2909 23.5 19.5 23.5Z"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.5 17.5V19.5H21.5"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
