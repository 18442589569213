import React from "react";
import Frame from "react-frame-component";
import Preview from "./index";

const FramePreview = ({ content, classes }) => {
  return (
    <Frame style={{ width: "100%", height: "100vh", border: "none" }}>
      <Preview content={content} />
    </Frame>
  );
};

export default React.memo(FramePreview);
