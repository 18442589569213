import React from "react";

function PreviewNo({ className = "text-[#333]", width = 24, height = 24, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width
      height
      fill="none"
      viewBox="0 0 32 32"
      className
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M21.5 13.732a16.79 16.79 0 012 2.268S20 21 16 21a5.35 5.35 0 01-1.504-.225M12.275 19.725C10.048 18.243 8.5 16 8.5 16s3.5-5 7.5-5c1.326 0 2.605.553 3.712 1.288M9 23L23 9"
      ></path>
    </svg>
  );
}

export default PreviewNo;
