/**
 * @flow
 * @relayHash 3c3426262ac52604e58740f1daff6ef7
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type gethyblastSettingQueryVariables = {|
  orgId: string,
  propertyId?: ?string,
|};
export type gethyblastSettingQueryResponse = {|
  +hyblastSetting: {|
    +hyblastTemplatesUrl: ?string,
    +mediaLibraryUrl: ?string,
    +contactGroupsUrl: ?string,
    +hyblastAudienceUrl: ?string,
  |}
|};
export type gethyblastSettingQuery = {|
  variables: gethyblastSettingQueryVariables,
  response: gethyblastSettingQueryResponse,
|};
*/


/*
query gethyblastSettingQuery(
  $orgId: ID!
  $propertyId: ID
) {
  hyblastSetting(orgId: $orgId, propertyId: $propertyId) {
    hyblastTemplatesUrl
    mediaLibraryUrl
    contactGroupsUrl
    hyblastAudienceUrl
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "orgId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "propertyId",
    "type": "ID",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "orgId",
    "variableName": "orgId"
  },
  {
    "kind": "Variable",
    "name": "propertyId",
    "variableName": "propertyId"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "hyblastTemplatesUrl",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "mediaLibraryUrl",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "contactGroupsUrl",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "hyblastAudienceUrl",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "gethyblastSettingQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "hyblastSetting",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "HyblastSetting",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "gethyblastSettingQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "hyblastSetting",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "HyblastSetting",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "gethyblastSettingQuery",
    "id": null,
    "text": "query gethyblastSettingQuery(\n  $orgId: ID!\n  $propertyId: ID\n) {\n  hyblastSetting(orgId: $orgId, propertyId: $propertyId) {\n    hyblastTemplatesUrl\n    mediaLibraryUrl\n    contactGroupsUrl\n    hyblastAudienceUrl\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'efd762c9ec44d3fc2ee8805753e9cbe3';
module.exports = node;
