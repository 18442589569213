/**
 * @flow
 * @relayHash 314a6efeaf8ed848836063cb03edfd8c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getContactFieldsQueryVariables = {|
  propertyId: string
|};
export type getContactFieldsQueryResponse = {|
  +contactFields: $ReadOnlyArray<{|
    +fieldId: ?string,
    +fieldLabel: ?string,
    +options: ?any,
    +id: string,
  |}>
|};
export type getContactFieldsQuery = {|
  variables: getContactFieldsQueryVariables,
  response: getContactFieldsQueryResponse,
|};
*/


/*
query getContactFieldsQuery(
  $propertyId: ID!
) {
  contactFields(propertyId: $propertyId) {
    fieldId
    fieldLabel
    options
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "propertyId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "contactFields",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "propertyId",
        "variableName": "propertyId"
      }
    ],
    "concreteType": "ContactField",
    "plural": true,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "fieldId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "fieldLabel",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "options",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getContactFieldsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "getContactFieldsQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "getContactFieldsQuery",
    "id": null,
    "text": "query getContactFieldsQuery(\n  $propertyId: ID!\n) {\n  contactFields(propertyId: $propertyId) {\n    fieldId\n    fieldLabel\n    options\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '929bdef6cae1fb436fb916128417b332';
module.exports = node;
