/**
 * @flow
 * @relayHash 0bc7b05abc71bf96626c511481187fe0
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getSavedReportsQueryVariables = {|
  propertyId: string,
  type?: ?string,
|};
export type getSavedReportsQueryResponse = {|
  +savedReports: $ReadOnlyArray<{|
    +analyzeObject: ?string,
    +createdAt: ?any,
    +id: string,
    +name: ?string,
    +params: ?string,
  |}>
|};
export type getSavedReportsQuery = {|
  variables: getSavedReportsQueryVariables,
  response: getSavedReportsQueryResponse,
|};
*/


/*
query getSavedReportsQuery(
  $propertyId: ID!
  $type: String
) {
  savedReports(propertyId: $propertyId, type: $type) {
    analyzeObject
    createdAt
    id
    name
    params
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "propertyId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "type",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "savedReports",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "propertyId",
        "variableName": "propertyId"
      },
      {
        "kind": "Variable",
        "name": "type",
        "variableName": "type"
      }
    ],
    "concreteType": "Reports",
    "plural": true,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "analyzeObject",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "createdAt",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "name",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "params",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getSavedReportsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "getSavedReportsQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "getSavedReportsQuery",
    "id": null,
    "text": "query getSavedReportsQuery(\n  $propertyId: ID!\n  $type: String\n) {\n  savedReports(propertyId: $propertyId, type: $type) {\n    analyzeObject\n    createdAt\n    id\n    name\n    params\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c5ec861808910ef697a687d8ff6a82a6';
module.exports = node;
