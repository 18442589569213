/**
 * @flow
 * @relayHash 2a96d2e0431e4314d5bddddccbfc55a4
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type setMascotFirstMessageInput = {|
  firstMessage: string,
  propertyId: string,
|};
export type setMascotFirstMessageMutationVariables = {|
  input: setMascotFirstMessageInput
|};
export type setMascotFirstMessageMutationResponse = {|
  +setMascotFirstMessage: ?{|
    +response: ?string
  |}
|};
export type setMascotFirstMessageMutation = {|
  variables: setMascotFirstMessageMutationVariables,
  response: setMascotFirstMessageMutationResponse,
|};
*/


/*
mutation setMascotFirstMessageMutation(
  $input: setMascotFirstMessageInput!
) {
  setMascotFirstMessage(input: $input) {
    response
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "setMascotFirstMessageInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "setMascotFirstMessage",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "setMascotFirstMessagePayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "response",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "setMascotFirstMessageMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "setMascotFirstMessageMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "setMascotFirstMessageMutation",
    "id": null,
    "text": "mutation setMascotFirstMessageMutation(\n  $input: setMascotFirstMessageInput!\n) {\n  setMascotFirstMessage(input: $input) {\n    response\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e5da9a09bb9885909120d63bc962cb70';
module.exports = node;
