import React from "react";
import Button from "storybookComponents/Button";
import withStyles from "@mui/styles/withStyles";
import styles from "./styles";
const ToggleButton = ({ classes, handleChangeToggle, isSMSActive, toggleText }) => {
  const renderButton = (item, index) => (
    <Button
      className={classes.button}
      key={index}
      variant={item == "SMS" ? (isSMSActive ? "md" : "tertiary") : !isSMSActive ? "md" : "tertiary"}
    >
      {item}
    </Button>
  );
  return (
    <div className={classes.toggleButtonDiv} onClick={handleChangeToggle}>
      {toggleText.map(renderButton)}
    </div>
  );
};

export default withStyles(styles)(ToggleButton);
