import {
  MailIcon2,
  LayersIcon2,
  // MailTimeIcon2,
  CalendarIcon,
  CalendarIcon2,
  CogIcon,
  CogIcon2,
  ApprovalIcon,
  ApprovalIcon2,
  ReportIcon2,
  SuccessIcon,
  HouseIcon,
  BackArrowIcon,
  // Offers,
  ReferralIcon,
  MailIcon,
  ReservationIcon,
  ReportsIcon,
  TourSiteIcon,
  AmenitiesIcon,
  IntegrationIcon,
  TourScheduleIcon,
  TourIcon,
  FreehandIcon,
  TemplateIcon,
  JAIIcon,
  AlertsIcon,
  UsersIcon,
  HayleyCustomizeIcon,
  HayleyEmbedCodeIcon,
  HayleyTourSettingsIcon,
  FeederPlaygroundIcon,
  FeederConfigrationIcon,
  FeederManagementIcon,
  ShareEmbedIcon,
  HayleyPreferenceIcon,
  HayleyKnowledgeBaseIcon,
  SuccessHubIcon,
} from "icons";
import { HayleyGPTIcon, HayleyIcon } from "icons/dashboardIcons";
import {
  checkIfAccess,
  getAccessDetails,
  getAccessibleScreens,
  getAccessibleScreensForBlasts,
  getUserRole,
} from "common";
import { SunIcon } from "components/Icons";
import { VALID_USERS_FOR_AI_WORKSHOP } from "constants/index";

const currentUser = localStorage.getItem("userEmail");
const _userRole = getUserRole();

const getBlastSettingsSubmenu = (path, orgLevel, approvalProccessAccess) => {
  const menu = [
    {
      title: "Approval Process",
      to: path + "/blast/settings/approval-process",
      moduleName: "Blasts",
    },
    {
      title: "Broker Upload",
      to: path + "/blast/settings/broker-upload",
      moduleName: "Blasts",
    },
    {
      title: "Student Upload",
      to: path + "/blast/settings/student-upload",
      moduleName: "Blasts",
    },
    {
      title: "Universal BCC",
      to: path + "/blast/settings/bcc-list",
      moduleName: "Blasts",
    },
    {
      title: "Marketing Admin",
      to: path + "/blast/settings/marketing-admin",
      moduleName: "Blasts",
    },
  ];
  return getAccessibleScreensForBlasts(menu, orgLevel, approvalProccessAccess);
};

const getBlastApprovalsSubmenu = (path, isApprover) => {
  return [
    {
      title: isApprover ? "Pending Requests" : "Sent For Approval",
      to: path + "/blast/approvals/my-approval",
      moduleName: "Blasts",
    },
    {
      title: isApprover ? "All Requests" : "Not Approved",
      to: path + "/blast/approvals/all-approval",
      moduleName: "Blasts",
    },
    {
      title: isApprover ? "Approved Requests" : "Approved",
      to: path + "/blast/approvals/approval-history",
      moduleName: "Blasts",
    },
  ];
};

const getEventsApprovalSubmenu = (path, isApprover) => {
  return [
    {
      title: isApprover ? "My Approvals" : "My Requests",
      to: path + "/events/approvals/my-approval",
      moduleName: "Events",
    },
    {
      title: "All Approvals",
      to: path + "/events/approvals/all-approval",
      moduleName: "Events",
    },
    {
      title: isApprover ? "Approval History" : "Request History",
      to: path + "/events/approvals/approval-history",
      moduleName: "Events",
    },
  ];
};

const getEventsReportsSubmenu = (path) => {
  return [
    // {
    //   title: "By Property",
    //   to: path + "/events/by-property",
    //   path: path + "/events/by-property",
    // },
    // {
    //   title: "By Event",
    //   to: path + "/events/by-events",
    //   path: path + "/events/by-events",
    // },
    // {
    //   title: "By Event Email",
    //   to: path + "/events/by-email",
    //   path: path + "/events/by-email",
    // },
    // {
    //   title: "Email Analytics",
    //   to: path + "/events/email-analytics",
    //   path: path + "/events/email-analytics",
    // },
    {
      title: "By Event",
      to: path + "/events/by-events-new",
      path: path + "/events/by-events-new",
    },
    {
      title: "Event Contact List",
      to: path + "/events/event-contact-list",
      path: path + "/events/event-contact-list",
    },
    {
      title: "My Analytics",
      to: path + "/events/favorites",
      path: path + "/events/favorites",
      showBorderLine: true,
    },
  ];
};

const getEventsSettingsSubmenu = (path, orgLevel, approvalProccessAccess) => {
  const menu = [
    {
      title: "Approval Process",
      to: path + "/events/settings/approval-process",
      moduleName: "Events",
    },
    {
      title: "Broker Upload",
      to: path + "/events/settings/broker-upload",
      moduleName: "Events",
    },
    {
      title: "Student Upload",
      to: path + "/events/settings/student-upload",
      moduleName: "Events",
    },
    {
      title: "Universal BCC",
      to: path + "/events/settings/bcc-list",
      moduleName: "Events",
    },
  ];
  return getAccessibleScreens(menu, orgLevel, approvalProccessAccess);
};

const getOriginNavItems = async (
  path,
  orgLevel,
  authorizedDetails,
  orgName,
  dripsMenu,
  alertsMenu,
  rentCheck,
  suborgDetails,
  appDetails
) => {
  const propertyId = path.replace("/", "");

  let blastsAccessType = getAccessDetails("Blasts", authorizedDetails);
  let dripsAccessType = getAccessDetails("Drips", authorizedDetails);
  let alertsAccessType = getAccessDetails("Alerts", authorizedDetails);
  let eventsAccessType = getAccessDetails("Events", authorizedDetails);
  let rafAccessType = getAccessDetails("Raf", authorizedDetails);
  let successAccessType = getAccessDetails("Success Center", authorizedDetails);
  let toursAccessType = getAccessDetails("Tours", authorizedDetails);
  let freehandAccessType = getAccessDetails("Journey Freehand", authorizedDetails);
  let jaiAccessType = getAccessDetails("Journey Designer", authorizedDetails);
  const hayleyAccessType = getAccessDetails("Hayley", authorizedDetails);
  let blastsUploadAccess = checkIfAccess("Blasts", appDetails);
  let dripsUploadAccess = checkIfAccess("Drips", appDetails);

  const menuItems = [
    {
      icon_type: (
        <HouseIcon
          viewBox="0 0 32 32"
          style={{ width: 32, height: 32, fill: "#fff", stroke: "#333" }}
        />
      ),
      title: "Home",
      to: path + "/home",
      path: path + "/home",
      org: true,
      moduleName: "dashboard",
    },
    {
      icon_type: (
        <MailIcon2
          viewBox="0 0 32 32"
          style={{ width: 32, height: 32, fill: "none", stroke: "#333" }}
        />
      ),
      title: "Blasts",
      to:
        blastsAccessType !== "authorised"
          ? path + "/unauth/blast"
          : orgLevel
          ? path + "/blast/approvals/my-approval"
          : path + "/blast",
      transfer: true,
      org: true,
      accessType: blastsAccessType,
      moduleName: "Blasts",
      back: true,
    },
    {
      icon_type: (
        <LayersIcon2
          viewBox="0 0 32 32"
          style={{ width: 32, height: 32, fill: "none", stroke: "#333" }}
        />
      ),
      title: "Drips",
      to:
        dripsAccessType !== "authorised"
          ? path + "/unauth/drips"
          : orgLevel
          ? path + "/drips/reports/by-property"
          : dripsMenu?.[0]?.to,
      transfer: true,
      org: true,
      accessType: dripsAccessType,
      moduleName: "Drips",
      back: true,
    },
    {
      icon_type: (
        <AlertsIcon
          viewBox="0 0 32 32"
          style={{ width: 32, height: 32, fill: "none", stroke: "#333" }}
        />
      ),
      title: "Alerts",
      to:
        alertsAccessType !== "authorised"
          ? path + "/unauth/alerts"
          : orgLevel
          ? path + "/alerts/reports/by-property"
          : alertsMenu[0].to,
      transfer: true,
      org: true,
      accessType: alertsAccessType,
      moduleName: "Alerts",
      back: true,
    },
    // {
    //   icon_type: (
    //     <MailTimeIcon2
    //       viewBox="0 0 32 32"
    //       style={{ width: 32, height: 32, fill: "none", stroke: "#333" }}
    //     />
    //   ),
    //   title: "Triggered Emails",
    //   to: path + "/triggered-emails",
    //   path: path + "/triggered-emails",
    //   org: true,
    // },
    {
      icon_type: <TourIcon viewBox="0 0 32 32" style={{ width: 32, height: 32, fill: "none" }} />,
      title: "Tours",
      to: toursAccessType !== "authorised" ? path + "/unauth/tours" : path + "/tours",
      transfer: true,
      org: true,
      accessType: toursAccessType,
      moduleName: "Tours",
      back: true,
    },
    {
      icon_type: (
        <CalendarIcon2
          viewBox="0 0 32 32"
          style={{ width: 32, height: 32, fill: "#333", stroke: "#333" }}
        />
      ),
      title: "Events",
      to:
        eventsAccessType !== "authorised"
          ? path + "/unauth/events"
          : orgLevel
          ? path + "/events/approvals/my-approval"
          : path + "/events",
      transfer: true,
      org: true,
      accessType: eventsAccessType,
      moduleName: "Events",
      back: true,
    },
    // {
    //   icon_type: <Offers viewBox="0 0 32 32" style={{ width: 32, height: 32, fill: "none" }} />,
    //   title: "Offers",
    //   to: path + "/offers",
    //   path: path + "/offers",
    //   transfer: true,
    //   org: true,
    //   accessType: getAccessDetails("Offers", authorizedDetails, orgLevel),
    // },
    {
      icon_type: (
        <ReferralIcon viewBox="0 0 32 32" style={{ width: 32, height: 32, fill: "none" }} />
      ),
      title: "Refer a Friend",
      to:
        rafAccessType !== "authorised" ? path + "/unauth/refer-a-friend" : path + "/refer-a-friend",
      transfer: true,
      org: true,
      accessType: rafAccessType,
      moduleName: "Raf",
      back: true,
    },
    {
      icon_type: (
        <SuccessIcon
          viewBox="0 0 32 32"
          style={{ width: 32, height: 32, fill: "#333", stroke: "#333" }}
        />
      ),
      title: "Success Center",
      to:
        successAccessType !== "authorised"
          ? path + "/unauth/success-center"
          : blastsAccessType == "authorised"
          ? path + "/success-center-blast/analysis"
          : path + "/success-center-drips/by-property",
      transfer: true,
      org: true,
      accessType: successAccessType,
      moduleName: "Success Center",
      back: true,
    },
    {
      icon_type: <JAIIcon />,
      title: "Journey Designer",
      to: successAccessType !== "authorised" ? path + "/unauth/jai" : path + "/jai",
      org: true,
      transfer: true,
      accessType: jaiAccessType,
      moduleName: "Journey Designer",
      back: true,
    },
    {
      icon_type: (
        <div className="w-[32px] h-[32px] flex justify-center items-center">
          <SunIcon size={25} className="text-[#333]" />
        </div>
      ),
      title: "Journey Freehand",
      to:
        successAccessType !== "authorised"
          ? path + "/unauth/journey-freehand"
          : path + "/journey-freehand",
      org: false,
      transfer: true,
      accessType: freehandAccessType,
      moduleName: "Journey Freehand",
      back: true,
    },
    {
      icon_type: <HayleyIcon className="h-8 w-8 pt-1" color="#333333" />,
      title: appDetails?.find((item) => item.name === "HayleyChat")?.customName || "HayleyChat",
      name: "Hayley",
      to:
        hayleyAccessType !== "authorised"
          ? path + "/unauth/hayley/mascot"
          : path + "/hayley/mascot",
      org: false,
      transfer: true,
      accessType: hayleyAccessType,
      moduleName: "Hayley",
      back: true,
    },
  ];

  let items = menuItems;

  if (freehandAccessType === "hide") {
    items = items.filter((item) => item.title !== "Journey Freehand");
  }

  if (jaiAccessType === "hide") {
    items = items.filter((item) => item.title !== "Journey Designer");
  }

  if (hayleyAccessType === "hide") {
    items = items.filter((item) => item.name !== "Hayley");
  }

  // if (process.env.NODE_ENV === "development" || VALID_USERS_FOR_AI_WORKSHOP.includes(currentUser)) {
  //   items.push({
  //     icon_type: <HayleyGPTIcon className="h-10 w-10 hayley-icon" />,
  //     title: "GPT",
  //     to: path + "/hayley/mascot",
  //     org: false,
  //     transfer: true,
  //     accessType: hayleyAccessType,
  //     moduleName: "AI Workshop",
  //     back: true,
  //   });
  // }
  if (orgLevel || blastsUploadAccess || dripsUploadAccess) {
    items.push({
      icon_type: (
        <CogIcon2
          viewBox="0 0 32 32"
          style={{ width: 32, height: 32, fill: "#fff", stroke: "#333" }}
        />
      ),
      title: "Settings",
      to: path + "/settings",
      path: path + "/settings",
      org: true,
      moduleName: "settings",
      accessType: true,
    });
  }
  if (rentCheck && orgName !== "Rent_Demo") {
    return items.filter(
      (d) =>
        d.title === "Tours" ||
        d.name === "Hayley" ||
        (d.moduleName === "Drips" && d.accessType === "authorised") ||
        (d.moduleName === "Alerts" && d.accessType === "authorised") ||
        d.title === "Settings"
    );
  }
  return items;
};

const getBlastNavItems = (path, isApprover, orgLevel, approvalProccessAccess, sms, appDetails) => {
  const settingsSubmenu = getBlastSettingsSubmenu(path, orgLevel, approvalProccessAccess);
  const approvalsSubmenu = getBlastApprovalsSubmenu(path, isApprover);
  const reportMenuItems = [
    {
      title: "Property Audit",
      to: path + "/blast/reports/property-audit",
      moduleName: "Blasts",
    },
    {
      title: "By Property",
      to: path + "/blast/reports/by-property",
      moduleName: "Blasts",
    },
    {
      title: sms ? "By SMS" : "By Blast",
      to: path + "/blast/reports/by-blasts",
      moduleName: "Blasts",
    },
    {
      title: sms ? "SMS Analytics" : "Blast Analytics",
      to: path + "/blast/reports/blasts-analytics",
      moduleName: "Blasts",
    },
    {
      title: sms ? "SMS Contact List" : "Blasts Contact List",
      to: path + "/blast/reports/blast-contact-list",
      moduleName: "Blasts",
    },
    // {
    //   title: "Blast A/B",
    //   to: path + "/blast/reports/blast-a-b",
    //   path: path + "/blast/reports/blast-a-b",
    // },
    {
      title: "By Email Platform",
      to: path + "/blast/reports/by-email-platform",
      moduleName: "Blasts",
    },
    {
      title: "My Favorites",
      to: path + "/blast/reports/my-favorites",
      moduleName: "Blasts",
      showBorderLine: true,
    },
    // {
    //   title: "My Analytics",
    //   to: path + "/blast/reports/my-analytics",
    //   moduleName: "Blasts",
    //   showBorderLine: true,
    // },
  ];
  let gptAccess;
  appDetails.map((item) => {
    if (item.name === "BlastGPT") {
      gptAccess = item.authorized;
    }
  });
  const feedbackReportsMenu = [
    {
      title: "Analysis By Org/Property",
      to: path + "/blast/reports/feedback-org-property",
      moduleName: "Blasts",
    },
    {
      title: "Analysis By Blast Type",
      to: path + "/blast/reports/feedback-blast-type",
      moduleName: "Blasts",
    },
    {
      title: "Analysis By Fields",
      to: path + "/blast/reports/feedback-fields",
      moduleName: "Blasts",
    },
  ];

  const arr = sms ? reportMenuItems.splice(5, 1) : reportMenuItems;

  const finalOptions = [
    {
      icon_type: (
        <BackArrowIcon
          viewBox="0 0 32 32"
          style={{ width: 32, height: 32, stroke: "#212121", fill: "none" }}
        />
      ),
      title: "Blasts",
      to: path + "/home",
      moduleName: "dashboard",
      customStyle: {
        label: {
          fontWeight: 600,
        },
      },
      back: true,
      header: true,
    },
    {
      icon_type: (
        <MailIcon2
          viewBox="0 0 32 32"
          style={{ width: 32, height: 32, fill: "none", stroke: "#333" }}
        />
      ),
      title: "Blasts",
      to: path + "/blast",
      moduleName: "Blasts",
    },
    {
      icon_type: (
        <LayersIcon2
          viewBox="0 0 32 32"
          style={{ width: 32, height: 32, fill: "none", stroke: "#333" }}
        />
      ),
      title: "Templates",
      to: path + "/blast/templates",
      moduleName: "Blasts",
    },
    {
      icon_type: (
        <ApprovalIcon2
          viewBox="0 0 32 32"
          style={{ width: 32, height: 32, fill: "none", stroke: "#333" }}
        />
      ),
      title: "Approval Requests",
      // children: isApprover ? approvalsSubmenu : [approvalsSubmenu[0], approvalsSubmenu[2]],
      children: approvalsSubmenu,
    },
    {
      icon_type: (
        <ReportIcon2
          viewBox="0 0 32 32"
          style={{ width: 32, height: 32, fill: "none", stroke: "#333" }}
        />
      ),
      title: "Reports",
      children: orgLevel ? reportMenuItems : reportMenuItems.slice(2),
    },
    {
      icon_type: (
        <ReportIcon2
          viewBox="0 0 32 32"
          style={{ width: 32, height: 32, fill: "none", stroke: "#333" }}
        />
      ),
      title: "Feedback Reports",
      children: feedbackReportsMenu,
    },
    {
      icon_type: (
        <CogIcon2
          viewBox="0 0 32 32"
          style={{ width: 32, height: 32, fill: "none", stroke: "#333" }}
        />
      ),
      title: "Settings",
      children: settingsSubmenu,
    },
  ];
  if (gptAccess) {
    return finalOptions;
  } else {
    finalOptions.splice(5, 1);
    return finalOptions;
  }
};

const getDripsMenu = (path, orgLevel, sms, dripsMenu, rentCheck) => {
  const reportMenuItems = [
    {
      title: "By Property",
      to: path + "/drips/reports/by-property",
      moduleName: "Drips",
    },
    {
      title: "By Drip",
      to: path + "/drips/reports/by-drips",
      moduleName: "Drips",
    },
    {
      title: sms ? "By SMS" : "By Email",
      to: path + "/drips/reports/by-emails",
      moduleName: "Drips",
    },
    {
      title: sms ? "SMS Analytics" : "Email Analytics",
      to: path + "/drips/reports/emails-analytics",
      moduleName: "Drips",
    },
    {
      title: "Drips Contact List",
      to: path + "/drips/reports/drips-contact-list",
      moduleName: "Drips",
    },
    {
      title: "By Email Platform",
      to: path + "/drips/reports/by-platform",
      moduleName: "Drips",
    },
    {
      title: "My Favorites",
      to: path + "/drips/reports/my-favorites",
      moduleName: "Drips",
      showBorderLine: true,
    },
  ];

  const arr = sms ? reportMenuItems.splice(5, 1) : reportMenuItems;

  const filteredMenu = rentCheck
    ? dripsMenu.filter((item) => !item.title.includes("Resident"))
    : dripsMenu;

  const data = [
    {
      icon_type: (
        <BackArrowIcon
          viewBox="0 0 32 32"
          style={{ width: 32, height: 32, stroke: "#212121", fill: "none" }}
        />
      ),
      title: "Drips",
      to: path + "/home",
      moduleName: "dashboard",
      customStyle: {
        label: {
          fontWeight: 600,
        },
      },
      back: true,
      header: true,
    },
    ...filteredMenu,
    {
      icon_type: (
        <ReportIcon2
          viewBox="0 0 32 32"
          style={{ width: 32, height: 32, fill: "none", stroke: "#333" }}
        />
      ),
      title: "Reports",
      children: orgLevel ? reportMenuItems : reportMenuItems.slice(1),
    },
  ];

  if (orgLevel && _userRole !== "property_manager") {
    data.push({
      icon_type: (
        <SuccessHubIcon
          viewBox="0 0 32 32"
          style={{ width: 32, height: 32, fill: "none", stroke: "#333" }}
        />
      ),
      title: "Success Hub",
      to: path + "/drips/success_hub",
      moduleName: "Drips",
    });
  }
  return data;
};
const getAlertsMenu = (path, orgLevel, sms, alertsMenu) => {
  const reportMenuItems = [
    {
      title: "By Property",
      to: path + "/alerts/reports/by-property",
      moduleName: "Alerts",
    },
    {
      title: "By Alert",
      to: path + "/alerts/reports/by-alerts",
      moduleName: "Alerts",
    },
    {
      title: sms ? "By SMS" : "By Email",
      to: path + "/alerts/reports/by-emails",
      moduleName: "Alerts",
    },
    // {
    //   title: sms ? "SMS Analytics" : "Email Analytics",
    //   to: path + "/alerts/reports/emails-analytics",
    //   moduleName: "Alerts",
    // },
    {
      title: "Alerts Contact List",
      to: path + "/alerts/reports/alerts-contact-list",
      moduleName: "Alerts",
    },
    {
      title: "By Email Platform",
      to: path + "/alerts/reports/by-platform",
      moduleName: "Alerts",
    },
    {
      title: "My Analytics",
      to: path + "/alerts/reports/my-analytics",
      moduleName: "Alerts",
      showBorderLine: true,
    },
  ];

  const arr = sms ? reportMenuItems.splice(5, 1) : reportMenuItems;
  const data = [
    {
      icon_type: (
        <BackArrowIcon
          viewBox="0 0 32 32"
          style={{ width: 32, height: 32, stroke: "#212121", fill: "none" }}
        />
      ),
      title: "Alerts",
      to: path + "/home",
      moduleName: "dashboard",
      customStyle: {
        label: {
          fontWeight: 600,
        },
      },
      back: true,
      header: true,
    },
    ...alertsMenu,
    {
      icon_type: (
        <ReportIcon2
          viewBox="0 0 32 32"
          style={{ width: 32, height: 32, fill: "none", stroke: "#333" }}
        />
      ),
      title: "Reports",
      children: orgLevel ? reportMenuItems : reportMenuItems.slice(1),
    },
  ];
  if (orgLevel && _userRole !== "property_manager") {
    data.push({
      icon_type: (
        <SuccessHubIcon
          viewBox="0 0 32 32"
          style={{ width: 32, height: 32, fill: "none", stroke: "#333" }}
        />
      ),
      title: "Success Hub",
      to: path + "/alerts/success_hub",
      moduleName: "Alerts",
    });
  }
  return data;
};

// const getOffersNavItems = (path) => [
//   {
//     icon_type: (
//       <BackArrowIcon
//         viewBox="0 0 32 32"
//         style={{ width: 32, height: 32, stroke: "#212121", fill: "none" }}
//       />
//     ),
//     title: "Campaigns",
//     to: path + "/home",
//     path: path + "/home",
//     customStyle: {
//       label: {
//         fontWeight: 600,
//       },
//     },
//   },
//   {
//     icon_type: <Offers viewBox="0 0 32 32" style={{ width: 32, height: 32, fill: "none" }} />,
//     title: "Campaigns",
//     to: path + "/offers",
//     path: path + "/offers",
//   },
//   // {
//   //   icon_type: (
//   //     <ApprovalIcon
//   //       viewBox="0 0 24 24"
//   //       style={{ width: 24, height: 24, fill: "none", stroke: "#666" }}
//   //     />
//   //   ),
//   //   title: "Approvals",
//   //   children: [
//   //     {
//   //       title: "My Approvals",
//   //       to: path + "/offers/approvals/my-approval",
//   //       path: path + "/offers/approvals/my-approval",
//   //     },
//   //     {
//   //       title: "All Approvals",
//   //       to: path + "/offers/approvals/all-approval",
//   //       path: path + "/offers/approvals/all-approval",
//   //     },
//   //     {
//   //       title: "Approval History",
//   //       to: path + "/offers/approvals/approval-history",
//   //       path: path + "/offers/approvals/approval-history",
//   //     },
//   //   ],
//   // },
//   {
//     icon_type: (
//       <ReportIcon2
//         viewBox="0 0 32 32"
//         style={{ width: 32, height: 32, fill: "none", stroke: "#333" }}
//       />
//     ),
//     title: "Reports",
//     children: [
//       {
//         title: "By Property",
//         to: path + "/offers/by-property",
//         path: path + "/offers/by-property",
//       },
//       {
//         title: "By Campaign",
//         to: path + "/offers/by-campaigns",
//         path: path + "/offers/by-campaigns",
//       },
//       {
//         title: "Campaign Analytics",
//         to: path + "/offers/by-analytics",
//         path: path + "/offers/by-analytics",
//       },
//       {
//         title: "Offers Contact List",
//         to: path + "/offers/by-contact",
//         path: path + "/offers/by-contact",
//       },
//       {
//         title: "My Analytics",
//         to: path + "/offers/my-analytics",
//         path: path + "/offers/my-analytics",
//         showBorderLine: true,
//       },
//     ],
//   },
//   // {
//   //   icon_type: (
//   //     <CogIcon
//   //       viewBox="0 0 24 24"
//   //       style={{ width: 24, height: 24, fill: "none", stroke: "#666" }}
//   //     />
//   //   ),
//   //   title: "Settings",
//   //   children: [
//   //     {
//   //       title: "Approval Process",
//   //       to: path + "/offers/settings/approval-process",
//   //       path: path + "/offers/settings/approval-process",
//   //     },
//   //     {
//   //       title: "Broker Upload",
//   //       to: path + "/offers/settings/broker-upload",
//   //       path: path + "/offers/approvals/broker-upload",
//   //     },
//   //     {
//   //       title: "Universal BCC",
//   //       to: path + "/offers/approvals/bcc-list",
//   //       path: path + "/blast/approvals/bcc-list",
//   //     },
//   //   ],
//   // },
// ];

const getHyEventMenu = (path, isApprover, orgLevel, approvalProccessAccess) => {
  const approvalsSubmenu = getEventsApprovalSubmenu(path, isApprover);
  const reportsSubmenu = getEventsReportsSubmenu(path);
  const settingsSubmenu = getEventsSettingsSubmenu(path, orgLevel, approvalProccessAccess);
  return [
    {
      icon_type: (
        <BackArrowIcon
          viewBox="0 0 32 32"
          style={{ width: 32, height: 32, stroke: "#212121", fill: "none" }}
        />
      ),
      title: "Events",
      to: path + "/home",
      moduleName: "dashboard",
      customStyle: {
        label: {
          fontWeight: 600,
        },
      },
      back: true,
      header: true,
    },
    {
      icon_type: (
        <CalendarIcon
          viewBox="0 0 24 24"
          style={{ width: 24, height: 24, fill: "#333", stroke: "#333" }}
        />
      ),
      title: "Events",
      to: path + "/events",
      moduleName: "Events",
    },
    {
      icon_type: (
        <LayersIcon2
          viewBox="0 0 32 32"
          style={{ width: 32, height: 32, fill: "none", stroke: "#333" }}
        />
      ),
      title: "Templates",
      to: path + "/events/templates",
      moduleName: "Events",
    },
    {
      icon_type: (
        <ApprovalIcon
          viewBox="0 0 24 24"
          style={{ width: 24, height: 24, fill: "none", stroke: "#666" }}
        />
      ),
      title: "Approvals",
      children: isApprover ? approvalsSubmenu : [approvalsSubmenu[0], approvalsSubmenu[2]],
    },
    {
      icon_type: (
        <ReportIcon2
          viewBox="0 0 32 32"
          style={{ width: 32, height: 32, fill: "none", stroke: "#333" }}
        />
      ),
      title: "Reports",
      // children: orgLevel ? reportsSubmenu : reportsSubmenu.slice(1),
      children: reportsSubmenu,
    },
    {
      icon_type: (
        <CogIcon
          viewBox="0 0 24 24"
          style={{ width: 24, height: 24, fill: "none", stroke: "#666" }}
        />
      ),
      title: "Settings",
      children: settingsSubmenu,
    },
  ];
};

const getSuccessCenter = (path, authorizedDetails) => {
  const blastsAccessType = getAccessDetails("Blasts", authorizedDetails);
  const dripsAccessType = getAccessDetails("Drips", authorizedDetails);
  return [
    {
      icon_type: (
        <BackArrowIcon
          viewBox="0 0 32 32"
          style={{ width: 32, height: 32, stroke: "#212121", fill: "none" }}
        />
      ),
      title: "Success Center",
      to: path + "/home",
      moduleName: "dashboard",
      customStyle: {
        label: {
          fontWeight: 600,
        },
      },
      back: true,
      header: true,
    },
    {
      icon_type: (
        <MailIcon2
          viewBox="0 0 32 32"
          style={{ width: 32, height: 32, fill: "none", stroke: "#333" }}
        />
      ),
      title: "Blasts",
      accessType: blastsAccessType,
      children:
        blastsAccessType === "authorised"
          ? [
              {
                title: "Success Analysis",
                to: path + "/success-center-blast/analysis",
                moduleName: "Success Center",
                level: "org",
              },
              {
                title: "Success Reports",
                to: path + "/success-center-blast/reports",
                moduleName: "Success Center",
                level: "org",
              },
              {
                title: "Set Goal",
                to: path + "/success-center-blast/goals",
                moduleName: "Success Center",
                level: "org",
              },
              {
                title: "Property Audit",
                to: path + "/success-center-blast/property-audit",
                moduleName: "Success Center",
                level: "org",
              },
              {
                title: "Property Blasts",
                to: path + "/success-center-blast/property-blast",
                moduleName: "Success Center",
                level: "org",
              },
            ]
          : null,
      to:
        blastsAccessType !== "authorised"
          ? path + "/unauth/success-center-blast"
          : path + "/success-center-blast/analysis",
    },
    {
      icon_type: (
        <LayersIcon2
          viewBox="0 0 32 32"
          style={{ width: 32, height: 32, fill: "none", stroke: "#333" }}
        />
      ),
      title: "Drips",
      level: "org",
      children:
        dripsAccessType === "authorised"
          ? [
              // {
              //   title: "Success Analysis",
              //   to: path + "/success-center-drips/analysis",
              //   path: path + "/success-center-drips/analysis",
              // },
              // {
              //   title: "Success Reports",
              //   to: path + "/success-center-drips/reports",
              //   path: path + "/success-center-drips/reports",
              // },
              // {
              //   title: "Set Goal",
              //   to: path + "/success-center-drips/goals",
              //   path: path + "/success-center-drips/goals",
              // },
              {
                title: "By Property",
                to: path + "/success-center-drips/by-property",
                moduleName: "Success Center",
                level: "org",
              },
            ]
          : null,
      accessType: dripsAccessType,
      to:
        dripsAccessType !== "authorised"
          ? path + "/unauth/success-center-drips"
          : path + "/success-center-drips/by-property",
    },
  ];
};

const getJourneyFreehandMenu = (path) => {
  return [
    {
      icon_type: (
        <BackArrowIcon
          viewBox="0 0 32 32"
          style={{ width: 32, height: 32, stroke: "#212121", fill: "none" }}
        />
      ),
      title: "Journey Freehand",
      to: path + "/home",
      moduleName: "dashboard",
      customStyle: {
        label: {
          fontWeight: 600,
        },
      },
      back: true,
      header: true,
    },
    {
      icon_type: (
        <FreehandIcon
          viewBox="0 0 32 32"
          style={{ width: 32, height: 32, fill: "#333", stroke: "#333" }}
        />
      ),
      title: "mJourneys",
      to: path + "/journey-freehand",
      level: "property",
    },
    {
      icon_type: (
        <TemplateIcon
          viewBox="0 0 32 32"
          style={{ width: 32, height: 32, fill: "#333", stroke: "#333" }}
        />
      ),
      title: "Templates",
      to: path + "/journey-freehand/templates",
      level: "property",
    },
  ];
};

const getHyRafMenu = (path, orgLevel) => {
  return [
    {
      icon_type: (
        <BackArrowIcon
          viewBox="0 0 32 32"
          style={{ width: 32, height: 32, stroke: "#212121", fill: "none" }}
        />
      ),
      title: "Refer-a-Friend",
      to: path + "/home",
      moduleName: "dashboard",
      customStyle: {
        label: {
          fontWeight: 600,
        },
      },
      back: true,
      header: true,
    },
    {
      icon_type: <ReferralIcon />,
      title: "Referral Campaigns",
      to: path + "/refer-a-friend",
      moduleName: "Raf",
    },
    {
      icon_type: (
        <ReportIcon2
          viewBox="0 0 32 32"
          style={{ width: 32, height: 32, fill: "none", stroke: "#333" }}
        />
      ),
      title: "Reports",
      children: [
        {
          title: orgLevel ? "By Property" : "By Campaign",
          to: path + "/refer-a-friend/reports/by-property",
          moduleName: "Raf",
        },
        {
          title: "Campaign Analytics",
          to: path + "/refer-a-friend/reports/by-campaigns",
          moduleName: "Raf",
        },
        {
          title: "Campaign Contact List",
          to: path + "/refer-a-friend/reports/by-contact",
          moduleName: "Raf",
        },
        {
          title: "My Analytics",
          to: path + "/refer-a-friend/reports/my-analytics",
          moduleName: "Raf",
          showBorderLine: true,
        },
      ],
    },
    {
      icon_type: (
        <CogIcon2
          viewBox="0 0 32 32"
          style={{ width: 32, height: 32, fill: "none", stroke: "#333" }}
        />
      ),
      title: "Settings",
      to: path + "/refer-a-friend/settings",
      moduleName: "Raf",
    },
  ];
};

const getHyToursMenu = (path, apps = []) => {
  const toursMenu = [
    {
      icon_type: (
        <BackArrowIcon
          viewBox="0 0 32 32"
          style={{ width: 32, height: 32, stroke: "#212121", fill: "none" }}
        />
      ),
      title: "Tours",
      to: path,
      moduleName: "dashboard",
      customStyle: {
        label: {
          fontWeight: 600,
        },
      },
      back: true,
      header: true,
    },
    {
      icon_type: (
        <HouseIcon
          viewBox="0 0 32 32"
          style={{ width: 32, height: 32, fill: "white", stroke: "var(--hylyTheme)" }}
        />
      ),
      title: "Home",
      to: path + "/tours",
      moduleName: "Tours",
    },
    {
      icon_type: (
        <CalendarIcon2
          viewBox="0 0 32 32"
          style={{ width: 32, height: 32, fill: "none", stroke: "#333" }}
        />
      ),
      title: "Agent Calendar",
      moduleName: "Tours",
    },
    {
      icon_type: (
        <ReservationIcon
          viewBox="0 0 32 32"
          style={{ width: 32, height: 32, fill: "none", stroke: "#333" }}
        />
      ),
      title: "Amenity Reservations",
      moduleName: "Tours",
    },
    {
      icon_type: (
        <TourScheduleIcon
          viewBox="0 0 32 32"
          style={{ width: 32, height: 32, fill: "none", stroke: "#333" }}
        />
      ),
      title: "Agent Settings: Tour Scheduler",
      moduleName: "Tours",
    },
    {
      icon_type: (
        <AmenitiesIcon
          viewBox="0 0 32 32"
          style={{ width: 32, height: 32, fill: "none", stroke: "#333" }}
        />
      ),
      title: "Agent Settings: Amenities",
      moduleName: "Tours",
    },
    {
      icon_type: (
        <TourScheduleIcon
          viewBox="0 0 32 32"
          style={{ width: 32, height: 32, fill: "none", stroke: "#333" }}
        />
      ),
      title: "Admin Settings: Tour Scheduler",
      moduleName: "Tours",
    },
    {
      icon_type: (
        <AmenitiesIcon
          viewBox="0 0 32 32"
          style={{ width: 32, height: 32, fill: "none", stroke: "#333" }}
        />
      ),
      title: "Admin Settings: Amenities",
      moduleName: "Tours",
    },
    {
      icon_type: (
        <TourSiteIcon
          viewBox="0 0 32 32"
          style={{ width: 32, height: 32, fill: "none", stroke: "#333" }}
        />
      ),
      title: "Admin Settings: Tour Site",
      moduleName: "Tours",
    },
    {
      icon_type: (
        <IntegrationIcon
          viewBox="0 0 32 32"
          style={{ width: 32, height: 32, fill: "none", stroke: "#333" }}
        />
      ),
      title: "Integrations",
      moduleName: "Tours",
    },
    {
      icon_type: (
        <ReportsIcon
          viewBox="0 0 32 32"
          style={{ width: 32, height: 32, fill: "#333", stroke: "#333" }}
        />
      ),
      title: "Reports",
      moduleName: "Tours",
    },
  ];
  let menus = [];
  if (apps.length === 0) return toursMenu;
  toursMenu.forEach((menu) => {
    const product = apps.find((product) => product.header === menu.title);
    if (menu.title === "" || menu.title === "Tours") {
      menus.push(menu);
    } else if (product) {
      menus.push({
        ...menu,
        to: product.mount,
        tour_menu: product.mount,
      });
    }
  });
  return menus;
};

const getJAIMenu = (path) => {
  return [
    {
      icon_type: (
        <BackArrowIcon
          viewBox="0 0 32 32"
          style={{ width: 32, height: 32, stroke: "#212121", fill: "none" }}
        />
      ),
      title: "Journey Designer",
      to: path + "/home",
      moduleName: "dashboard",
      customStyle: {
        label: {
          fontWeight: 600,
        },
      },
      back: true,
      header: true,
    },
    {
      icon_type: <JAIIcon />,
      title: "mJourney AIs",
      children: [
        {
          title: "Lifecycle AIs",
          to: path + "/jai/lifecycle",
          moduleName: "Journey Designer",
        },
        {
          title: "Reactive AIs",
          to: path + "/jai/reactive",
          moduleName: "Journey Designer",
        },
        {
          title: "Alert AIs",
          to: path + "/jai/alert",
          moduleName: "Journey Designer",
        },
      ],
    },
    {
      icon_type: (
        <TemplateIcon
          viewBox="0 0 32 32"
          style={{ width: 32, height: 32, fill: "#333", stroke: "#333" }}
        />
      ),
      title: "Templates",
      to: path + "/jai/templates",
    },
    {
      icon_type: (
        <UsersIcon
          viewBox="0 0 32 32"
          style={{ width: 32, height: 32, fill: "#333", stroke: "#333" }}
        />
      ),
      title: "Search User Journeys",
      to: path + "/jai/search-user-journeys",
    },
  ];
};

const getHayleyMenu = (path, apps = [], isHayleyFaqEnabled, appDetails, orgLevel) => {
  const submenu = [
    {
      title: "By Property",
      to: path + "/hayley/reports/by-property",
      moduleName: "Hayley",
    },
    {
      title: `${
        appDetails?.find((item) => item.name === "HayleyChat")?.customName || "HayleyChat"
      } Analytics`,
      to: path + "/hayley/reports/hayley-analytics",
      moduleName: "Hayley",
    },
    {
      title: `${
        appDetails?.find((item) => item.name === "HayleyChat")?.customName || "HayleyChat"
      } Contact List`,
      to: path + "/hayley/reports/by-contact",
      moduleName: "Hayley",
    },
    // {
    //   title: "My Analytics",
    //   to: path + "/hayley/reports/my-analytics",
    //   moduleName: "Hayley",
    //   showBorderLine: true,
    // },
  ];

  let menus = [
    {
      icon_type: (
        <BackArrowIcon
          viewBox="0 0 32 32"
          style={{ width: 32, height: 32, stroke: "#212121", fill: "none" }}
        />
      ),
      title: appDetails?.find((item) => item.name === "HayleyChat")?.customName || "HayleyChat",
      to: path + "/home",
      moduleName: "dashboard",
      customStyle: { label: { fontWeight: 600 } },
      back: true,
      header: true,
    },
    {
      icon_type: <HayleyCustomizeIcon />,
      title: "Customization Settings",
      to: path + "/hayley/mascot",
      // children: [
      //   {
      //     title: "Hayley Mascot",
      //     to: path + "/hayley/mascot",

      //     level: "property",
      //   },
      //   {
      //     title: "Position",
      //     to: path + "/hayley/position",
      //     level: "property",
      //   },
      // ],
    },
    {
      icon_type: <ShareEmbedIcon />,
      title: "Share Options",
      level: "property",
      children: [
        {
          title: "Embed Code",
          to: path + "/hayley/code/embed",
          moduleName: "Hayley",
        },
        {
          title: "Share URL",
          to: path + "/hayley/code/url",
          moduleName: "Hayley",
        },
      ],
    },
    {
      icon_type: <HayleyTourSettingsIcon />,
      title: "Tour Settings",
      to: path + "/hayley/tour",
      level: "property",
    },
    {
      icon_type: <HayleyPreferenceIcon />,
      title: "Preference Center",
      to: path + "/hayley/preference",
      level: "property",
    },
    {
      icon_type: (
        <ReportIcon2
          viewBox="0 0 32 32"
          style={{ width: 32, height: 32, fill: "none", stroke: "#333" }}
        />
      ),
      title: "Reports",
      children: orgLevel
        ? submenu.filter((_, index) => index !== 1)
        : submenu.filter((_, index) => index !== 0),
    },
  ];
  if (window.location.hostname === "localhost" || isHayleyFaqEnabled) {
    menus.push({
      icon_type: <HayleyKnowledgeBaseIcon />,
      title: "Knowledge Base",
      to: path + "/hayley/knowledge-base",
      level: "property",
    });
  }
  // if (window.location.hostname === "localhost" || isHayleyFaqEnabled) {
  //   menus.push({
  //     icon_type: <HayleyCustomizeIcon />,
  //     title: "AMA Settings",
  //     to: path + "/hayley/ama",
  //     level: "property",
  //   });
  // }

  return menus;
};

const getAIWorkshopMenu = (path) => {
  return [
    {
      icon_type: (
        <BackArrowIcon
          viewBox="0 0 32 32"
          style={{ width: 32, height: 32, stroke: "#212121", fill: "none" }}
        />
      ),
      title: "GPT",
      to: path + "/home",
      moduleName: "dashboard",
      customStyle: { label: { fontWeight: 600 } },
      back: true,
      header: true,
    },
    {
      icon_type: <FeederPlaygroundIcon />,
      title: "Feeders Playground",
      to: path + "/ai-workshop/feeders-playground",
    },
    {
      icon_type: <FeederConfigrationIcon />,
      title: "Feeders Configuration",
      to: path + "/ai-workshop/feeders-configuration",
    },
    {
      icon_type: <FeederManagementIcon />,
      title: "Feeders Management",
      to: path + "/ai-workshop/feeders-management",
    },
  ];
};

export const getNavDrawerMenu = async (
  type,
  path,
  isApprover,
  orgLevel,
  authorizedDetails,
  orgName,
  approvalProccessAccess,
  apps,
  sms,
  dripsMenu,
  alertsMenu,
  rentCheck,
  appDetails,
  suborgDetails,
  isHayleyFaqEnabled,
  userRole
) => {
  switch (type) {
    case "Blasts":
      const blastMenu = getBlastNavItems(
        path,
        isApprover,
        orgLevel,
        approvalProccessAccess,
        sms,
        appDetails
      );
      return orgLevel ? [blastMenu[0], ...blastMenu.slice(3)] : blastMenu;
    case "Drips":
      return getDripsMenu(path, orgLevel, sms, dripsMenu, rentCheck, userRole);
    case "Alerts":
      return getAlertsMenu(path, orgLevel, sms, alertsMenu);
    // case "Offers":
    //   return getOffersNavItems(path);
    case "Events":
      const eventMenu = getHyEventMenu(path, isApprover, orgLevel, approvalProccessAccess);
      return orgLevel ? [eventMenu[0], ...eventMenu.slice(3)] : eventMenu;
    case "Raf":
      return getHyRafMenu(path, orgLevel);
    case "Success Center":
      return getSuccessCenter(path, authorizedDetails);
    case "Tours":
      return getHyToursMenu(path, apps);
    case "Journey Freehand":
      return getJourneyFreehandMenu(path, apps);
    case "Journey Designer":
      return getJAIMenu(path, apps);
    case "Hayley":
      const hayleyMenu = getHayleyMenu(path, apps, isHayleyFaqEnabled, appDetails, orgLevel);
      return orgLevel ? [hayleyMenu[0], hayleyMenu[5]] : hayleyMenu;
    case "AI Workshop":
      if (VALID_USERS_FOR_AI_WORKSHOP.includes(currentUser)) {
        return getAIWorkshopMenu(path);
      }
      break;
    default:
      return getOriginNavItems(
        path,
        orgLevel,
        authorizedDetails,
        orgName,
        dripsMenu,
        alertsMenu,
        rentCheck,
        suborgDetails,
        appDetails
      );
  }
};
