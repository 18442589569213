import graphql from "babel-plugin-relay/macro";

export default graphql`
  query getOfferByPropertiesQuery($orgId: ID,$propertyIds: [String!],$startAt: String,$endAt: String,$concession: [String!]){
    offerByProperties(orgId: $orgId,propertyIds:$propertyIds,startAt: $startAt,endAt: $endAt,concession:$concession){
      campaignCount
      id
      propertyId
      propertyName
    }
  }`