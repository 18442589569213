import graphql from "babel-plugin-relay/macro";

export default graphql`
  query getTemplatePreviewQuery($categoryId: ID!, $propertyId: ID, $templateId: ID) {
    templates(categoryId: $categoryId, propertyId: $propertyId, templateId: $templateId) {
      edges {
        node {
          substitutedMacrosHtml
        }
      }
    }
  }
`;
