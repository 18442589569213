import graphql from "babel-plugin-relay/macro";

export default graphql`
  query getReportsStatQuery(
    $endDate: String!
    $startDate: String!
    $orgId: ID!
    $propertyIds: [String!]
  ) {
    hayleyReports(
      endDate: $endDate
      startDate: $startDate
      orgId: $orgId
      propertyIds: $propertyIds
      )
  }
`;
