import React from "react";

export default function AntiGoalIcon({ className = "text-[#333333]", size = 14 }) {
  return (
    <svg
      className={className}
      width={size}
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="1" width="12" height="12" rx="2" fill="white" fill-opacity="0.05" />
      <path
        d="M11.5536 6.31201H13.144"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1.48071 6.31201H3.07111"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.31224 10.5534C9.65452 10.5534 11.5533 8.65464 11.5533 6.31236C11.5533 3.97008 9.65452 2.07129 7.31224 2.07129C4.96996 2.07129 3.07117 3.97008 3.07117 6.31236C3.07117 8.65464 4.96996 10.5534 7.31224 10.5534Z"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.25195 5.2522L8.37249 7.37273"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.37249 5.2522L6.25195 7.37273"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
