/**
 * @flow
 * @relayHash da0033f2b55d8458c32584f47d5fd4b1
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getAuditSendsQueryVariables = {|
  orgId: string,
  startDate: string,
  endDate: string,
  selectedPropertyIds?: ?$ReadOnlyArray<string>,
  params?: ?any,
  regionIds?: ?$ReadOnlyArray<string>,
|};
export type getAuditSendsQueryResponse = {|
  +auditSends: $ReadOnlyArray<{|
    +id: string,
    +propertyName: string,
    +lastSentAt: ?any,
    +lastSentEblast: ?string,
    +daysSinceLastSend: ?number,
    +sendsCount: number,
    +uniqueOpensRate: number,
    +uniqueClicksRate: number,
    +lastSentEblastId: ?string,
    +eblastsCount: number,
    +propertiesIndex: ?string,
    +propertiesTotal: ?string,
  |}>
|};
export type getAuditSendsQuery = {|
  variables: getAuditSendsQueryVariables,
  response: getAuditSendsQueryResponse,
|};
*/


/*
query getAuditSendsQuery(
  $orgId: String!
  $startDate: String!
  $endDate: String!
  $selectedPropertyIds: [String!]
  $params: Json
  $regionIds: [String!]
) {
  auditSends(orgId: $orgId, startDate: $startDate, endDate: $endDate, selectedPropertyIds: $selectedPropertyIds, params: $params, regionIds: $regionIds) {
    id
    propertyName
    lastSentAt
    lastSentEblast
    daysSinceLastSend
    sendsCount
    uniqueOpensRate
    uniqueClicksRate
    lastSentEblastId
    eblastsCount
    propertiesIndex
    propertiesTotal
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "orgId",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "startDate",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "endDate",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "selectedPropertyIds",
    "type": "[String!]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "params",
    "type": "Json",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "regionIds",
    "type": "[String!]",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "auditSends",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "endDate",
        "variableName": "endDate"
      },
      {
        "kind": "Variable",
        "name": "orgId",
        "variableName": "orgId"
      },
      {
        "kind": "Variable",
        "name": "params",
        "variableName": "params"
      },
      {
        "kind": "Variable",
        "name": "regionIds",
        "variableName": "regionIds"
      },
      {
        "kind": "Variable",
        "name": "selectedPropertyIds",
        "variableName": "selectedPropertyIds"
      },
      {
        "kind": "Variable",
        "name": "startDate",
        "variableName": "startDate"
      }
    ],
    "concreteType": "AuditSend",
    "plural": true,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "propertyName",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "lastSentAt",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "lastSentEblast",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "daysSinceLastSend",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "sendsCount",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "uniqueOpensRate",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "uniqueClicksRate",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "lastSentEblastId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "eblastsCount",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "propertiesIndex",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "propertiesTotal",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getAuditSendsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "getAuditSendsQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "getAuditSendsQuery",
    "id": null,
    "text": "query getAuditSendsQuery(\n  $orgId: String!\n  $startDate: String!\n  $endDate: String!\n  $selectedPropertyIds: [String!]\n  $params: Json\n  $regionIds: [String!]\n) {\n  auditSends(orgId: $orgId, startDate: $startDate, endDate: $endDate, selectedPropertyIds: $selectedPropertyIds, params: $params, regionIds: $regionIds) {\n    id\n    propertyName\n    lastSentAt\n    lastSentEblast\n    daysSinceLastSend\n    sendsCount\n    uniqueOpensRate\n    uniqueClicksRate\n    lastSentEblastId\n    eblastsCount\n    propertiesIndex\n    propertiesTotal\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9d3160b940dcb0d52c9f6e77eb3facc5';
module.exports = node;
