import graphql from "babel-plugin-relay/macro";

export default graphql`
  query getPropertiesEditRafQuery($orgId: ID!, $first: Int) {
    authorizedSuborgs(orgId: $orgId, first: $first) {
      id
      name
      propertyLogoUrl
      propertyManagerLogoUrl
      contactUsLink
      tourCtaUrl
    }
  }
`;
