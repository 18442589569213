import React from "react";
import { createFragmentContainer, QueryRenderer } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import List from "./List";

export default createFragmentContainer(List, {
  suggestions: graphql`
    fragment List_suggestions on PicklistInterface @relay(plural: true) {
      ...ListUnit_item
    }
  `,
});
