import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import environment from "environments/environment";

const mutation = graphql`
  mutation updateProfileMutation($input: updateProfileInput!) {
    updateProfile(input: $input) {
      clientMutationId
      response
    }
  }
`;

function commit(input) {
  return new Promise((res, rej) => {
    commitMutation(environment, {
      mutation,
      variables: { input },
      updater: (store) => {},
      onCompleted: res,
      onError: rej,
    });
  });
}

export default commit;
