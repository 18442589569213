import React from "react";
import { Collapse } from "reactstrap";
import withStyles from "@mui/styles/withStyles";
import { Icon, NewTooltip as Tooltip } from "storybookComponents";
import { expansionStyles as styles } from "../styles";
import SearchBox from "components/SearchBox";

const Expansion = ({ classes, title, list, tooltip, search }) => {
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState(list);

  const handleClick = () => {
    setOpen(!open);
  };

  const renderListItem = (item, index) => {
    return (
      <div className={classes.listItem} key={index}>
        {item}
      </div>
    );
  };

  const handleFilter = (val) => {
    let filteredData = list.filter((item) => {
      const itemValue = item.toString().toLowerCase();
      const filterValue = val.toString().toLowerCase();

      return itemValue.includes(filterValue);
    });

    setData(filteredData);
  };

  if (list.length === 1) {
    // const element = (
    //   <div className={`${classes.header} ${classes.super}`}>
    //     <div className={classes.ellipsis}>{title}</div>
    //   </div>
    // );

    // if (tooltip === true) {
    return (
      // <Tooltip text={data[0]} position="top">
      //   {element}
      // </Tooltip>
      <>
        <div className={`${classes.header} ${classes.super}`}>
          <div className={classes.ellipsis}>{title}</div>
        </div>
        <div className="px-2 mb-[20px] -mt-[12px] text-[#333] text-[14px]">{list[0]}</div>
      </>
    );
    // } else {
    //   return element;
    // }
  }

  return (
    <div className={classes.super}>
      <div className={`${classes.header} ${classes.cursor}`} onClick={handleClick}>
        {title}
        <Icon type={open ? "icon-up-arrow" : "icon-down-arrow"} size="xs" />
      </div>
      <Collapse isOpen={open}>
        {search && (
          <div className="py-[12px]">
            <SearchBox
              onSearch={handleFilter}
              searchType="auto"
              placeholder="Search Property"
              className="!border-0 !border-b border-solid border-[#DADADA]"
            />
          </div>
        )}
        <div className={classes.listContainer}>{data.map(renderListItem)}</div>
      </Collapse>
    </div>
  );
};

export default withStyles(styles)(Expansion);
