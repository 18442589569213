import React from "react";
import clsx from "clsx";
import { Box, InputBase, IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import CloseIcon from "./Icons/CloseIcon";
import SearchIcon from "./Icons/SearchIcon";

const useStyles = makeStyles((theme) => ({
  root: {
    height: 32,
    position: "relative",
    backgroundColor: "#fff",
    // width: '100%',
    display: "flex",
    "&:hover": {
      border: "1px solid var(--hylyTheme)",
    },
    border: ({ focusInput }) => {
      return focusInput ? "1px solid #999999" : "1px solid #dadada";
    },
  },
  searchIcon: {
    paddingRight: 8,
    height: "100%",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#fff",
  },
  closeIcon: {
    paddingRight: 8,
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
    background: "#fff",
  },
  inputInput: {
    fontFamily: "Open Sans",
    fontSize: 12,
    paddingLeft: 8,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
  icon: {
    width: 10,
    height: 10,
  },
}));

const SearchBox = ({
  onSearch,
  className,
  placeholder,
  searchType, // available values: "key", "auto",
}) => {
  const [searchText, setSearchText] = React.useState("");
  const [isFocused, setIsFocused] = React.useState(false);
  const [isClear, setClear] = React.useState(false);
  const classes = useStyles({ focusInput: isFocused });
  const [iconStroke, setIconStroke] = React.useState("black");

  const handleChange = (event) => {
    if (!event.target.value) setClear(!Boolean(event.target.value));
    setSearchText(event.target.value);
    if (searchType === "auto") onSearch(event.target.value);
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = (evt) => {
    setIsFocused(false);
    if (isClear) {
      onSearch(evt.target.value);
      setClear(false);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      onSearch(searchText);
    }
  };

  const handleClickClear = () => {
    setSearchText("");
    onSearch("");
  };

  return (
    <Box className={clsx(classes.root, className)}>
      <InputBase
        placeholder={placeholder}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{ "aria-label": "search" }}
        value={searchText}
        onChange={handleChange}
        onKeyPress={handleKeyPress}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      {searchText !== "" ? (
        <IconButton
          size="small"
          onClick={handleClickClear}
          className={classes.closeIcon}
          onMouseEnter={() => setIconStroke("#26BBED")}
          onMouseLeave={() => setIconStroke("#333")}
        >
          <CloseIcon viewBox="0 0 13 13" style={{ width: 13, height: 13, stroke: iconStroke }} />
        </IconButton>
      ) : (
        <SearchIcon viewBox="0 0 24 10" style={{ width: 24, height: 24, fill: "none" }} />
      )}
    </Box>
  );
};

SearchBox.propTypes = {
  onSearch: PropTypes.func.isRequired,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  searchType: PropTypes.string,
};

SearchBox.defaultProps = {
  onSearch: () => {},
  className: undefined,
  placeholder: "Search",
  searchType: "key",
};

export default SearchBox;
