/**
 * @flow
 * @relayHash 1b04094ab30ab7cb86583ee72d28852e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getGptHistoryByJobIdQueryVariables = {|
  jobId: string
|};
export type getGptHistoryByJobIdQueryResponse = {|
  +retrieveGptHistoryByJobId: {|
    +id: string,
    +title: ?{|
      +feedback: ?{|
        +feedbackableType: ?string,
        +id: string,
        +liked: ?boolean,
      |},
      +fieldType: ?string,
      +value: ?string,
      +id: string,
    |},
    +subTitle: ?{|
      +feedback: ?{|
        +feedbackableType: ?string,
        +id: string,
        +liked: ?boolean,
      |},
      +fieldType: ?string,
      +value: ?string,
      +id: string,
    |},
    +preheader: ?{|
      +feedback: ?{|
        +feedbackableType: ?string,
        +id: string,
        +liked: ?boolean,
      |},
      +fieldType: ?string,
      +value: ?string,
      +id: string,
    |},
    +subject: ?{|
      +feedback: ?{|
        +feedbackableType: ?string,
        +id: string,
        +liked: ?boolean,
      |},
      +fieldType: ?string,
      +value: ?string,
      +id: string,
    |},
    +body: ?{|
      +feedback: ?{|
        +feedbackableType: ?string,
        +id: string,
        +liked: ?boolean,
      |},
      +fieldType: ?string,
      +value: ?string,
      +id: string,
    |},
    +ctaText: ?{|
      +feedback: ?{|
        +feedbackableType: ?string,
        +id: string,
        +liked: ?boolean,
      |},
      +fieldType: ?string,
      +value: ?string,
      +id: string,
    |},
    +disclaimer: ?{|
      +feedback: ?{|
        +feedbackableType: ?string,
        +id: string,
        +liked: ?boolean,
      |},
      +fieldType: ?string,
      +value: ?string,
      +id: string,
    |},
  |}
|};
export type getGptHistoryByJobIdQuery = {|
  variables: getGptHistoryByJobIdQueryVariables,
  response: getGptHistoryByJobIdQueryResponse,
|};
*/


/*
query getGptHistoryByJobIdQuery(
  $jobId: String!
) {
  retrieveGptHistoryByJobId(jobId: $jobId) {
    id
    title {
      feedback {
        feedbackableType
        id
        liked
      }
      fieldType
      value
      id
    }
    subTitle {
      feedback {
        feedbackableType
        id
        liked
      }
      fieldType
      value
      id
    }
    preheader {
      feedback {
        feedbackableType
        id
        liked
      }
      fieldType
      value
      id
    }
    subject {
      feedback {
        feedbackableType
        id
        liked
      }
      fieldType
      value
      id
    }
    body {
      feedback {
        feedbackableType
        id
        liked
      }
      fieldType
      value
      id
    }
    ctaText {
      feedback {
        feedbackableType
        id
        liked
      }
      fieldType
      value
      id
    }
    disclaimer {
      feedback {
        feedbackableType
        id
        liked
      }
      fieldType
      value
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "jobId",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "feedback",
    "storageKey": null,
    "args": null,
    "concreteType": "Feedback",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "feedbackableType",
        "args": null,
        "storageKey": null
      },
      (v1/*: any*/),
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "liked",
        "args": null,
        "storageKey": null
      }
    ]
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "fieldType",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "value",
    "args": null,
    "storageKey": null
  },
  (v1/*: any*/)
],
v3 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "retrieveGptHistoryByJobId",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "jobId",
        "variableName": "jobId"
      }
    ],
    "concreteType": "GptHistory",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "title",
        "storageKey": null,
        "args": null,
        "concreteType": "GptHistoryField",
        "plural": false,
        "selections": (v2/*: any*/)
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "subTitle",
        "storageKey": null,
        "args": null,
        "concreteType": "GptHistoryField",
        "plural": false,
        "selections": (v2/*: any*/)
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "preheader",
        "storageKey": null,
        "args": null,
        "concreteType": "GptHistoryField",
        "plural": false,
        "selections": (v2/*: any*/)
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "subject",
        "storageKey": null,
        "args": null,
        "concreteType": "GptHistoryField",
        "plural": false,
        "selections": (v2/*: any*/)
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "body",
        "storageKey": null,
        "args": null,
        "concreteType": "GptHistoryField",
        "plural": false,
        "selections": (v2/*: any*/)
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "ctaText",
        "storageKey": null,
        "args": null,
        "concreteType": "GptHistoryField",
        "plural": false,
        "selections": (v2/*: any*/)
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "disclaimer",
        "storageKey": null,
        "args": null,
        "concreteType": "GptHistoryField",
        "plural": false,
        "selections": (v2/*: any*/)
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getGptHistoryByJobIdQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "getGptHistoryByJobIdQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "getGptHistoryByJobIdQuery",
    "id": null,
    "text": "query getGptHistoryByJobIdQuery(\n  $jobId: String!\n) {\n  retrieveGptHistoryByJobId(jobId: $jobId) {\n    id\n    title {\n      feedback {\n        feedbackableType\n        id\n        liked\n      }\n      fieldType\n      value\n      id\n    }\n    subTitle {\n      feedback {\n        feedbackableType\n        id\n        liked\n      }\n      fieldType\n      value\n      id\n    }\n    preheader {\n      feedback {\n        feedbackableType\n        id\n        liked\n      }\n      fieldType\n      value\n      id\n    }\n    subject {\n      feedback {\n        feedbackableType\n        id\n        liked\n      }\n      fieldType\n      value\n      id\n    }\n    body {\n      feedback {\n        feedbackableType\n        id\n        liked\n      }\n      fieldType\n      value\n      id\n    }\n    ctaText {\n      feedback {\n        feedbackableType\n        id\n        liked\n      }\n      fieldType\n      value\n      id\n    }\n    disclaimer {\n      feedback {\n        feedbackableType\n        id\n        liked\n      }\n      fieldType\n      value\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '40f3ac759fa17767b2dd6661f49ecd05';
module.exports = node;
