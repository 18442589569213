/**
 * @flow
 * @relayHash 50f206bddbe26e5ee255082a1aef162c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getHyreferralCampaignsOverviewQueryVariables = {|
  orgId: string,
  propertyId?: ?string,
  startAt: string,
  endAt: string,
|};
export type getHyreferralCampaignsOverviewQueryResponse = {|
  +hyreferralCampaignsOverview: {|
    +campaignsCount: ?number,
    +enrollmentsCount: ?number,
    +leasedCount: ?number,
    +propertiesCount: ?number,
    +referredCount: ?number,
    +activeCampaignsCount: ?number,
    +inactiveCampaignsCount: ?number,
  |}
|};
export type getHyreferralCampaignsOverviewQuery = {|
  variables: getHyreferralCampaignsOverviewQueryVariables,
  response: getHyreferralCampaignsOverviewQueryResponse,
|};
*/


/*
query getHyreferralCampaignsOverviewQuery(
  $orgId: ID!
  $propertyId: ID
  $startAt: String!
  $endAt: String!
) {
  hyreferralCampaignsOverview(orgId: $orgId, propertyId: $propertyId, startAt: $startAt, endAt: $endAt) {
    campaignsCount
    enrollmentsCount
    leasedCount
    propertiesCount
    referredCount
    activeCampaignsCount
    inactiveCampaignsCount
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "orgId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "propertyId",
    "type": "ID",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "startAt",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "endAt",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "endAt",
    "variableName": "endAt"
  },
  {
    "kind": "Variable",
    "name": "orgId",
    "variableName": "orgId"
  },
  {
    "kind": "Variable",
    "name": "propertyId",
    "variableName": "propertyId"
  },
  {
    "kind": "Variable",
    "name": "startAt",
    "variableName": "startAt"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "campaignsCount",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "enrollmentsCount",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "leasedCount",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "propertiesCount",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "referredCount",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "activeCampaignsCount",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "inactiveCampaignsCount",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getHyreferralCampaignsOverviewQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "hyreferralCampaignsOverview",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "HyreferralOverviewStat",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "getHyreferralCampaignsOverviewQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "hyreferralCampaignsOverview",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "HyreferralOverviewStat",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "getHyreferralCampaignsOverviewQuery",
    "id": null,
    "text": "query getHyreferralCampaignsOverviewQuery(\n  $orgId: ID!\n  $propertyId: ID\n  $startAt: String!\n  $endAt: String!\n) {\n  hyreferralCampaignsOverview(orgId: $orgId, propertyId: $propertyId, startAt: $startAt, endAt: $endAt) {\n    campaignsCount\n    enrollmentsCount\n    leasedCount\n    propertiesCount\n    referredCount\n    activeCampaignsCount\n    inactiveCampaignsCount\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '135236bd23d51d8b4b5991a4a12412cb';
module.exports = node;
