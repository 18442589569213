import graphql from "babel-plugin-relay/macro";

export default graphql`
  query mascotDefaultColorsQuery($propertyId: ID!) {
    mascotDefaultColors(propertyId: $propertyId) {
      primary
      secondary
      tertiary
    }
  }
`;
