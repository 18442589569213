import React from "react";
import PropTypes from "prop-types";

import withStyles from "@mui/styles/withStyles";

import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";

import Typography from "storybookComponents/Typography";
import Icon from "storybookComponents/Icon";

const styles = (theme) => ({
  root_list_item: {
    cursor: "pointer",
    whiteSpace: "nowrap",
    minWidth: 0,
    padding: `${theme.margins.xs}px ${theme.margins.xs + 1}px`,
    "&:hover": {
      background: "#f5f5f5", //theme.palette.lightGrey,
    },
  },
  selected: {
    fontWeight: theme.fontWeight.bold,
  },
  selected_icon_container: {
    margin: `-2px auto auto ${theme.margins.xs}px`,
    color: theme.palette.hylyBlue,
  },
  expand_icon_container: {
    margin: "0 0 0 auto",
  },
  typography: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
});

export const ListItemUnit = React.memo(({ item, search_value }) => {
  const typed = React.useMemo(
    () => search_value && item && RegExp(`${search_value}`, "gi").test(item)
  );
  return typed ? <b>{item}</b> : <>{item}</>;
});

const ListItemComponent = ({
  classes,
  item,
  selected,
  search_value,
  color = "darkBlack",
  onSelect,
  selected_icon_type,
  is_expanded,
  expand_icon_type,
  default_selected,
  className,
  listItemRef = null,
}) => {
  const splitted_item = React.useMemo(() => {
    if (!search_value) {
      return [item.name];
    }

    return item.name
      .replace(RegExp(search_value, "gi"), (...args) => {
        const o = args[0],
          i = args[args.length - 2];
        return i === 0 ? `${o}__` : i === item.name.length - 1 ? `__${o}` : `__${o}__`;
      })
      .split("__");
  }, [item.name, search_value]);

  return (
    <ListItem
      onClick={(e) => !selected && onSelect(e, item)}
      selected={selected}
      classes={{
        root: classes.root_list_item + (className ? ` ${className}` : ""),
        selected: classes.selected,
      }}
      name={item.name}
      id={item.id}
      ref={listItemRef}
    >
      <Typography type={"body1"} className={classes.typography} color={color}>
        {splitted_item.map((o, idx) => (
          <ListItemUnit key={`simple-list-${o}-${idx}`} item={o} search_value={search_value} />
        ))}
      </Typography>
      {Boolean(is_expanded) ? (
        <ListItemIcon className={classes.expand_icon_container}>
          <Icon type={expand_icon_type} />
        </ListItemIcon>
      ) : null}
    </ListItem>
  );
};

ListItemComponent.propTypes = {
  classes: PropTypes.object,
  item: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    region: PropTypes.string,
  }),
  search_value: PropTypes.string,
  onSelect: PropTypes.func,
  selected_icon_type: PropTypes.string,
  is_expanded: PropTypes.bool,
  expand_icon_type: PropTypes.string,
};

ListItemComponent.defaultProps = {
  selected_icon_type: "icon-check-single",
};
export default React.memo(withStyles(styles)(ListItemComponent));
