/**
 * @flow
 * @relayHash a7c901e386aa5799091beeb2d2271f72
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getHayleyQualificationQueryVariables = {|
  tenantId: string
|};
export type getHayleyQualificationQueryResponse = {|
  +hayleyQualifierButton: ?{|
    +qualificationButton: ?string,
    +qualificationButtonEnabled: ?boolean,
  |}
|};
export type getHayleyQualificationQuery = {|
  variables: getHayleyQualificationQueryVariables,
  response: getHayleyQualificationQueryResponse,
|};
*/


/*
query getHayleyQualificationQuery(
  $tenantId: ID!
) {
  hayleyQualifierButton(tenantId: $tenantId) {
    qualificationButton
    qualificationButtonEnabled
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "tenantId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "hayleyQualifierButton",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "tenantId",
        "variableName": "tenantId"
      }
    ],
    "concreteType": "HayleyQualificationButton",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "qualificationButton",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "qualificationButtonEnabled",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getHayleyQualificationQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "getHayleyQualificationQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "getHayleyQualificationQuery",
    "id": null,
    "text": "query getHayleyQualificationQuery(\n  $tenantId: ID!\n) {\n  hayleyQualifierButton(tenantId: $tenantId) {\n    qualificationButton\n    qualificationButtonEnabled\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9143a39adf7ae9276d9ac7560e81637e';
module.exports = node;
