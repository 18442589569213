import graphql from "babel-plugin-relay/macro";

export default graphql`
  query getDripContactsDashboardQuery($dashboardId: ID!, $params: Json) {
    eblastContactsDashboard(dashboardId: $dashboardId, params: $params) {
      id
      campaignName
      botName
      createdAt
      entryEmail
      propertyName
      subStatus
      eventIndex
      eventTotal
      entryId
      entryUrl
    }
  }
`;
