import React from "react";
import Modal from "components/Modal";
import Button from "storybookComponents/Button";
import moment from "moment";
import { useSelector } from "react-redux";
import { getSuborgDetails } from "redux/slices";

const CancelBlastModal = ({
  data,
  open,
  handleClose,
  handleClickCancel,
  isLoading = false,
  type,
}) => {
  const subOrgDetails = useSelector(getSuborgDetails);

  const title = type === "approval" ? "Cancel Approval Request" : "Cancel Blast";
  const body =
    type === "approval"
      ? "Are you sure you want to cancel the approval request of the blast"
      : "Are you sure you want to cancel the schedule of the blast";
  const btnText = type === "approval" ? "Yes, Cancel Request" : "Yes, Cancel Blast";

  const renderCancelActions = () => (
    <>
      <Button color="darkBlack" variant="tertiary" size="md" onClick={handleClose}>
        Cancel
      </Button>
      <Button
        color="darkBlack"
        type="submit"
        variant={"secondary"}
        size="md"
        onClick={handleClickCancel}
        disabled={isLoading}
        negative={true}
      >
        {btnText}
      </Button>
    </>
  );

  return (
    <Modal
      title={title}
      open={open}
      handleClose={handleClose}
      actionChildren={renderCancelActions()}
    >
      <div className="flex flex-col">
        <span>
          {body} <strong>{data?.name}</strong> ?
        </span>
        <span className="mt-4">
          <span className="text-text-6 text-sm">Schedule To:</span>{" "}
          <span className="text-sm">
            {data?.sentGroup} ({data?.sendsCount})
          </span>
        </span>
        <span className="mt-3">
          <span className="text-text-6 text-sm">Schedule For:</span>{" "}
          <span className="text-sm">
            {moment(data?.scheduledTime)
              ?.tz(subOrgDetails.timezoneString)
              ?.format("MMM DD, YYYY - h:mm a")}
          </span>
        </span>
      </div>
    </Modal>
  );
};

export default CancelBlastModal;
