/**
 * @flow
 * @relayHash df6be2eebe98433f3cbb65bb38119ecf
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type allOrgsGptFieldsAnalysisQueryVariables = {|
  allOrgs?: ?boolean,
  endAt?: ?string,
  startAt?: ?string,
  orgIds?: ?$ReadOnlyArray<string>,
  blastTypes?: ?$ReadOnlyArray<string>,
  promptTemplates?: ?$ReadOnlyArray<string>,
  propertyIds?: ?$ReadOnlyArray<string>,
|};
export type allOrgsGptFieldsAnalysisQueryResponse = {|
  +allOrgsGptFieldsAnalysis: any
|};
export type allOrgsGptFieldsAnalysisQuery = {|
  variables: allOrgsGptFieldsAnalysisQueryVariables,
  response: allOrgsGptFieldsAnalysisQueryResponse,
|};
*/


/*
query allOrgsGptFieldsAnalysisQuery(
  $allOrgs: Boolean
  $endAt: String
  $startAt: String
  $orgIds: [String!]
  $blastTypes: [String!]
  $promptTemplates: [String!]
  $propertyIds: [String!]
) {
  allOrgsGptFieldsAnalysis(allOrgs: $allOrgs, endAt: $endAt, startAt: $startAt, orgIds: $orgIds, blastTypes: $blastTypes, promptTemplates: $promptTemplates, propertyIds: $propertyIds)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "allOrgs",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "endAt",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "startAt",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "orgIds",
    "type": "[String!]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "blastTypes",
    "type": "[String!]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "promptTemplates",
    "type": "[String!]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "propertyIds",
    "type": "[String!]",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "allOrgsGptFieldsAnalysis",
    "args": [
      {
        "kind": "Variable",
        "name": "allOrgs",
        "variableName": "allOrgs"
      },
      {
        "kind": "Variable",
        "name": "blastTypes",
        "variableName": "blastTypes"
      },
      {
        "kind": "Variable",
        "name": "endAt",
        "variableName": "endAt"
      },
      {
        "kind": "Variable",
        "name": "orgIds",
        "variableName": "orgIds"
      },
      {
        "kind": "Variable",
        "name": "promptTemplates",
        "variableName": "promptTemplates"
      },
      {
        "kind": "Variable",
        "name": "propertyIds",
        "variableName": "propertyIds"
      },
      {
        "kind": "Variable",
        "name": "startAt",
        "variableName": "startAt"
      }
    ],
    "storageKey": null
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "allOrgsGptFieldsAnalysisQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "allOrgsGptFieldsAnalysisQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "allOrgsGptFieldsAnalysisQuery",
    "id": null,
    "text": "query allOrgsGptFieldsAnalysisQuery(\n  $allOrgs: Boolean\n  $endAt: String\n  $startAt: String\n  $orgIds: [String!]\n  $blastTypes: [String!]\n  $promptTemplates: [String!]\n  $propertyIds: [String!]\n) {\n  allOrgsGptFieldsAnalysis(allOrgs: $allOrgs, endAt: $endAt, startAt: $startAt, orgIds: $orgIds, blastTypes: $blastTypes, promptTemplates: $promptTemplates, propertyIds: $propertyIds)\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '53be4458c090268d4b956843da1b3035';
module.exports = node;
