import React from "react";

export default function SunIcon({ className = "text-[#333333]", size = 34 }) {
  return (
    <svg
      className={className}
      width={size}
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.9998 22.819C20.2131 22.819 22.818 20.2141 22.818 17.0008C22.818 13.7875 20.2131 11.1826 16.9998 11.1826C13.7865 11.1826 11.1816 13.7875 11.1816 17.0008C11.1816 20.2141 13.7865 22.819 16.9998 22.819Z"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17 1V5.36364"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M26.7456 7.25488L25.291 8.70943"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M26.7456 26.7456L25.291 25.291"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.25488 26.7456L8.70943 25.291"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.25488 7.25488L8.70943 8.70943"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M32.9999 17H28.6362"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17 33.0004V28.6367"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1 17H5.36364"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
