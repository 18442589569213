import graphql from "babel-plugin-relay/macro";

export default graphql`
  query getTenantOrCampaignStatQuery($dashboardId: ID!, $params: Json) {
    tenantOrCampaignStat(dashboardId: $dashboardId, params: $params) {
      campaignStat
      tenantStat
    }
  }
`;
