import React from "react";

import { useOutsideClickDetect } from "hooks";

const NormalSearchFilter = ({
  label = "Normal Search",
  placeholder = "Input to search...",
  width = 240,
  onFilter = () => {},
  value = "",
}) => {
  const rootRef = React.useRef(null);

  const [visibleInput, setVisibleInput] = React.useState(false);
  const [visibleClear, setVisibleClear] = React.useState(false);

  const toggleSearchInput = () => {
    setVisibleInput((prev) => !prev);
  };

  const onChange = (e) => {
    onFilter(e.target.value);
  };

  const onClearIconClick = () => {
    onFilter("");
  };

  useOutsideClickDetect(rootRef, () => {
    setVisibleInput(false);
  });

  React.useEffect(() => {
    setVisibleClear(value !== "");
  }, [value]);

  return (
    <div className="relative" ref={rootRef}>
      <div
        className={`space-x-1 select-none cursor-pointer ${
          visibleInput ? "text-text" : "text-text/75"
        } hover:text-hyly-blue`}
        onClick={toggleSearchInput}
      >
        <span className="font-semibold text-sm leading-5 text-inherit min-w-[120px]">{label}</span>
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={`text-inherit ${visibleInput ? "rotate-180" : ""}`}
        >
          <path
            d="M15 8L10 13L5 8"
            stroke="currentColor"
            strokeOpacity="0.75"
            stroke-miterlimit="10"
            stroke-linecap="square"
          />
        </svg>
      </div>
      {visibleInput && (
        <div
          className="absolute border-[1px] border-solid border-border rounded shadow-sm py-[16px] px-[12px] bg-white -bottom-[16px] -left-[16px] translate-y-full flex items-center justify-between"
          style={{ width }}
        >
          <input
            type="text"
            className="border-none text-sm leading-5 font-normal text-text placeholder:text-text/50 w-4/5"
            value={value}
            placeholder={placeholder}
            onChange={onChange}
          />

          {visibleClear ? (
            <svg
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="w-4 h-4 cursor-pointer"
              onClick={onClearIconClick}
            >
              <path
                d="M17.0313 4.53125L4.53125 17.0313"
                stroke="#333333"
                stroke-miterlimit="10"
                stroke-linecap="square"
              />
              <path
                d="M17.0313 17.0313L4.53125 4.53125"
                stroke="#333333"
                stroke-miterlimit="10"
                stroke-linecap="square"
              />
            </svg>
          ) : (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M16.2497 16.2497L12.5859 12.5859" stroke="#999999" stroke-miterlimit="10" />
              <path
                d="M8.92241 14.0948C11.7791 14.0948 14.0948 11.7791 14.0948 8.92241C14.0948 6.06577 11.7791 3.75 8.92241 3.75C6.06577 3.75 3.75 6.06577 3.75 8.92241C3.75 11.7791 6.06577 14.0948 8.92241 14.0948Z"
                stroke="#999999"
                stroke-miterlimit="10"
                stroke-linecap="square"
              />
            </svg>
          )}
        </div>
      )}
    </div>
  );
};

export default NormalSearchFilter;
