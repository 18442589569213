import React from "react";
import PropTypes from "prop-types";

import withStyles from "@mui/styles/withStyles";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";

import Select from "@mui/material/Select";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Menu from "@mui/material/Menu";
import Paper from "@mui/material/Paper";

import SearchInput from "../Inputs/SearchInput";
import Icon from "storybookComponents/Icon";
import Input from "@mui/material/Input";

import menuprops from "storybookComponents/dropDown/menuProps";

const search_list_container_styles = (theme) => ({
  container: {
    width: "100%",
  },
  list_container: {
    paddingBottom: 0,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    overflowY: "auto",
  },
  icon_class_container: {
    marginTop: -2,
  },
  root_list_item: {
    cursor: "pointer",
    padding: theme.margins.xs,
    "&:hover": {
      background: theme.palette.lightGrey,
    },
  },
  search_list_item: {
    height: "auto !important",
    //padding: theme.margins.xs,
    textAlign: "center",
    "&:hover, &:focus": {
      background: theme.palette.white,
    },
  },
  selected: {
    fontWeight: theme.fontWeight.bold,
  },
  input: {
    background: theme.palette.white,
    padding: `0 8px`,
    border: `1px solid ${theme.palette.lightGrey}`,
  },
  paper: {
    borderRadius: 0,
    border: `1px solid ${theme.palette.lightGrey}`,
    boxShadow: "0px 4px 5px #eee",
    background: theme.palette.white,
    width: "100%",
    maxWidth: "var(--container-width)",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "var(--container-width-lg)",
    },
  },
  disabled: {},
  focused: {},
  error: {},
  search_input_root: {
    lineHeight: 1.5,
    fontSize: 16,
  },
  underline: {
    "&:before": {
      borderBottom: `1px solid ${theme.palette.lightGrey}`,
    },
    "&:hover:not($disabled):not($focused):not($error):before": {
      borderBottomColor: theme.palette.mediumGrey,
    },
    "&:after": {
      borderBottom: `1px solid ${theme.palette.deepGrey}`,
    },
  },
  list_container: {
    paddingBottom: 0,
  },
  add_field: {
    padding: `${theme.spacing_unit.xs / 2}px ${theme.margins.xs}px`,
    borderTop: `1px solid ${theme.palette.lightGrey}`,
  },
  label: {
    //  marginTop: 6
  },
});

export const MenuSearchInput = React.memo(
  withStyles((theme) => ({
    search_list_item: {
      height: "auto !important",
      //padding: theme.margins.xs,
      textAlign: "center",
      "&:hover, &:focus": {
        background: theme.palette.white,
      },
    },
    input: {
      background: theme.palette.white,
      padding: `0 8px`,
      border: `1px solid ${theme.palette.lightGrey}`,
    },
  }))(({ classes, input_props, handleChange, search_value }) => {
    return (
      <MenuItem classes={{ root: classes.search_list_item }}>
        <FormControl fullWidth>
          <SearchInput
            onChange={handleChange}
            value={search_value}
            className={`${classes.input} ${input_props.input_class || ""}`}
          />
        </FormControl>
      </MenuItem>
    );
  })
);

export const SearchListContainer = ({
  classes,
  default_search_value,
  search_value,

  container_width,
  container_class,

  handleChange,

  has_add_fields,
  add_fields_label,
  add_fields_action,

  input_props = {},
  children,
  onSelect,
  DropDownIconComponent,
}) => {
  const [selected_search_value, set_selected_search_value] = React.useState("");
  const [select_ref, set_ref] = React.useState(null);
  const [menuprops_] = React.useState(() =>
    menuprops({ direction: "left", style: { maxHeight: 800 } })
  );

  const handle_select = React.useCallback(function (e, item) {
    set_selected_search_value(item.name);
    handleChange({ target: { value: "" } }); // clear the selection
    set_ref(null);
    if (typeof onSelect === "function") {
      onSelect(e, item);
    }
  });

  const cancel = React.useCallback(function () {
    handleChange({ target: { value: "" } }); // clear the selection
    set_ref(null);
  });

  const invoke_add_fields_action = React.useCallback(function (e) {
    cancel();
    add_fields_action(e);
  });

  return (
    <>
      <FormControl fullWidth onClick={(e) => set_ref(e.currentTarget)}>
        <InputLabel htmlFor={`${default_search_value}`} className={classes.label}>
          {default_search_value}
        </InputLabel>
        <Select
          open={false}
          renderValue={() => selected_search_value}
          value={selected_search_value}
          IconComponent={DropDownIconComponent}
          input={
            <Input
              classes={{
                input: classes.search_input_root,
                underline: classes.underline,
              }}
            />
          }
        />
      </FormControl>
      <Menu
        anchorEl={select_ref}
        keepMounted
        open={Boolean(select_ref)}
        onClose={cancel}
        value={search_value}
        marginThreshold={0}
        {...menuprops_}
        MenuListProps={{
          classes: { root: has_add_fields ? classes.list_container : "" },
        }}
        style={{ "--container-width": `${container_width}px` }}
        classes={{
          paper: classes.paper + (container_class ? " " + container_class : ""),
        }}
      >
        <div>
          <MenuSearchInput
            input_props={input_props}
            handleChange={handleChange}
            search_value={search_value}
          />
        </div>
        {children ? React.cloneElement(children, { handle_select }) : null}
        {has_add_fields && (
          <MenuItem className={classes.add_field} onClick={invoke_add_fields_action}>
            {add_fields_label}
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

SearchListContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  input_props: PropTypes.object,
  icon_type: PropTypes.string,
  search_value: PropTypes.string,
  selected_value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  onSelect: PropTypes.func,
  default_search_value: PropTypes.string,
  Container: PropTypes.func,
};

SearchListContainer.defaultProps = {
  default_search_value: "Select",
};

export default React.memo(withStyles(search_list_container_styles)(SearchListContainer));
