/**
 * @flow
 * @relayHash 319e02cea2421da3d6fef8c008872c90
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type gethyblastQueryVariables = {|
  propertyId: string,
  id: string,
|};
export type gethyblastQueryResponse = {|
  +hyblasts: {|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +scheduledTime: ?any,
        +sentTime: ?any,
        +abortedTime: ?any,
        +sentGroup: ?string,
        +status: string,
        +editorStatus: string,
        +approvalStatus: ?string,
        +mmsStatus: string,
        +mms: boolean,
      |}
    |}>
  |}
|};
export type gethyblastQuery = {|
  variables: gethyblastQueryVariables,
  response: gethyblastQueryResponse,
|};
*/


/*
query gethyblastQuery(
  $propertyId: ID!
  $id: String!
) {
  hyblasts(propertyId: $propertyId, id: $id) {
    edges {
      node {
        scheduledTime
        sentTime
        abortedTime
        sentGroup
        status
        editorStatus
        approvalStatus
        mmsStatus
        mms
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "propertyId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  },
  {
    "kind": "Variable",
    "name": "propertyId",
    "variableName": "propertyId"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "scheduledTime",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "sentTime",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "abortedTime",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "sentGroup",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "editorStatus",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "approvalStatus",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "mmsStatus",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "mms",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "gethyblastQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "hyblasts",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "HyblastConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "HyblastEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Hyblast",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "gethyblastQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "hyblasts",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "HyblastConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "HyblastEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Hyblast",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "id",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "gethyblastQuery",
    "id": null,
    "text": "query gethyblastQuery(\n  $propertyId: ID!\n  $id: String!\n) {\n  hyblasts(propertyId: $propertyId, id: $id) {\n    edges {\n      node {\n        scheduledTime\n        sentTime\n        abortedTime\n        sentGroup\n        status\n        editorStatus\n        approvalStatus\n        mmsStatus\n        mms\n        id\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2f3e2ea2930d32ae926004c98919d481';
module.exports = node;
