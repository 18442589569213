import graphql from "babel-plugin-relay/macro";

export default graphql`
  query getOmichannelPreviewsQuery($id: ID!) {
    hyreferralCampaign(id: $id) {
      id
      reward
      active
      residentInviteEmailCampaignHtml
      residentRewardInviteEmailCampaignHtml
      friendInviteEmailCampaignHtml
      residentEnrollEmailCampaignHtml
      emailSubject
      residentInviteEmailSubject
      residentDiscountEmailSubject
      residentThankYouEmailSubject
      residentInviteEmailBody
      residentInviteSms
      residentInviteImage {
        fileUrl
      }
      image {
        fileUrl
      }
      residentDiscountImage {
        fileUrl
      }
      emailSms
      residentDiscountEmailSms
      tourCtaUrl
      contactCtaUrl
      propertyId
      emailEnabled
      facebookEnabled
      smsEnabled
      twitterEnabled
      bannerText
      ctaText
      pitchMessage
      residentEnrollHeader
      residentEnrollFooter
      shortEnrollUrl
      propertyName
      shortTourCtaUrl
      linkColor
      fontFamily
      textColor
      buttonBorderColor
      buttonFontColor
      buttonBackgroundColor
      noscript
      script
      rewardEmailHeader
      friendInviteEmailHeader
      friendInviteMenuHeader
      friendInviteMenuBody
      residentBannerTitle
      popupMessage
      popupTitle
      contactCtaUrl
      facebookShareUrl
      propertyLogoUrl
      emailValidationStatus
    }
  }
`;
