import React from "react";
import { Link } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import { withRouter } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
const qs = require("qs");

const queryString = require("query-string");

export const getQueryParams = () => {
  const parsed = queryString.parse(window.location.search, {
    arrayFormat: "comma",
  });

  return parsed;
};

const useStyles = makeStyles({
  clickable: {
    textDecoration: "none",
    color: "#4F6FAE",
    "&:hover": {
      color: "#26BBED",
    },
  },
  TableRoot: {
    marginLeft: 20,
    marginRight: 20,
  },
  tableCell: {
    borderBottom: "1px solid #eee",
    wordBreak: "break-all",
  },
  tableCelltd: {
    border: "1px solid #eee",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  tabelHeadCell: {
    display: "flex",
    fontSize: 16,
    fontWeight: 600,
  },
  headRow: {
    backgroundColor: "#F8F8F8",
  },
});

const TableComponent = (props) => {
  const classes = useStyles();

  // let { metric } = props.match.params;

  let columns = props.columns;
  let heatmaps = {};
  let totals = {};
  let maximum = {};
  let hiddenColumns = [];

  // let metricValues = props.data.map((item) => item.metric);
  // let metricValues;

  // if (props.tourInsights) {
  //   metricValues = props.data.map((item) => item[metric]).sort((a, b) => b - a);
  // } else {
  //   metricValues = props.data.map((item) => item.metric);
  // }

  // let maxMetricValue = Math.max(...metricValues);

  const getRouteParameters = (value, tag, dimension) => {
    let queryparam = { ...getQueryParams() };
    queryparam.value = value;
    queryparam.tag = tag;
    queryparam.dimension = dimension;
    let result = qs.stringify(queryparam, { arrayFormat: "comma" });
    return result;
  };

  const renderColumns = () => {
    return (
      <TableHead>
        <TableRow className={classes.headRow}>
          {columns.map((column, i) => {
            if (column.heatmap || column.sum) heatmaps[column.value] = 0;

            if (column.show === false) {
              hiddenColumns.push(i);
              return null;
            }

            return (
              <TableCell
                key={i + column.label}
                className={classes.tableCell}
                width={column.width || "auto"}
              >
                <span className={classes.tabelHeadCell}>{column.label}</span>
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
    );
  };

  const calculateSum = () => {
    let data = props.data;
    let others = {};

    for (let i in data) {
      let row = data[i];

      for (let heatmap of Object.keys(heatmaps)) {
        let val = row[heatmap] || 0;
        heatmaps[heatmap] += val;
        if (!maximum[heatmap]) maximum[heatmap] = 0;

        if (row[heatmap] > maximum[heatmap]) maximum[heatmap] = row[heatmap];

        if (props.limit && parseInt(i) + 1 > parseInt(props.limit) - 1) {
          if (!others[heatmap]) others[heatmap] = 0;

          others[heatmap] += row[heatmap];

          if (others[heatmap] > maximum[heatmap]) maximum[heatmap] = others[heatmap];
        }
      }
    }
  };

  const calculateColor = (column, row, log) => {
    // now the heatmap is categorized into the number of colors
    let noOfColors = props.heatmapColor.length - 1; // e.g 6
    let eachDiv = maximum[column.value] / noOfColors; // 300 / 6 = 50
    let currentValue = row[column.value]; // e.g 120
    let index = Math.floor(currentValue / eachDiv);

    // if (row[column.value] == 0) index = 0;

    return props.heatmapColor[index];
  };

  const renderRow = (data) => {
    //const reverseColor = [..props.]
    return columns.map((column, i) => {
      if (column.heatmap || column.sum) {
        if (totals[column.value] === undefined) {
          totals[column.value] = {
            ...column,
            [column.value]: data[column.value],
          };
        } else totals[column.value][column.value] += data[column.value];
      }
      if (column.show === false) return null;

      return (
        <TableCell
          key={"column" + i}
          style={{
            backgroundColor:
              column.heatmap === true ? `${calculateColor(column, data)}` : "inherit",
          }}
          className={classes.tableCell}
        >
          {!column.parseValue && (
            <div
              style={{
                display: "flex",
                // marginLeft: "auto",
                width: column.showBar ? `${data[column.value]}%` : "inherit",
                height: 22,
                backgroundColor: column.showBar ? column.color : "inherit",
              }}
            >
              {
                // eslint-disable-next-line
                column.metric == "1" || column.tourAnalysis ? (
                  column.showBar ? (
                    ""
                  ) : (
                    data[column.value]
                  )
                ) : (
                  <Link
                    to={`/attribution_analysis_drilldown?${getRouteParameters(
                      data[column.value],
                      column.tag,
                      column.dimension
                    )}`}
                    className={classes.clickable}
                  >
                    {column.showBar ? "" : data[column.value]}
                  </Link>
                )
              }
            </div>
          )}
          {column.parseValue && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span>
                {
                  // eslint-disable-next-line
                  column.metric == "1" || column.tourAnalysis ? (
                    column.parseValue(data[column.value], data, heatmaps, totals, false)
                  ) : (
                    <Link
                      to={`/attribution_analysis_drilldown?${getRouteParameters(
                        data[column.labelValue],
                        column.tag,
                        column.dimension
                      )}`}
                      className={classes.clickable}
                    >
                      {column.parseValue(data[column.value], data, heatmaps, totals, false)}
                    </Link>
                  )
                }
              </span>
              <span style={{ opacity: 0.5, color: "#333" }}>
                {column.parsePercentage(data[column.value], data, heatmaps, totals, false)}
              </span>
            </div>
          )}
        </TableCell>
      );
    });
  };

  const renderOtherRows = () => {
    if (!props.limit) return null;

    let summableCols = Object.keys(totals);
    let footer = [];
    for (let col of summableCols) {
      let value = totals[col][col];

      if (totals[col].show === false) continue;

      footer.push(
        <TableCell
          key={"column" + col}
          style={{
            backgroundColor:
              totals[col].heatmap === true
                ? `${calculateColor(
                    { value: col },
                    { ...totals[col], [col]: heatmaps[col] - value },
                    true
                  )}`
                : "inherit",
          }}
          className={classes.tableCelltd}
        >
          <span style={{ display: "inline-block" }}>
            {!totals[col].parseValue
              ? heatmaps[col] - value
              : totals[col].parseValue(heatmaps[col] - value, null, heatmaps, totals, true)}
          </span>

          <span style={{ display: "inline-block" }}>
            {!totals[col].parsePercentage
              ? heatmaps[col] - value
              : totals[col].parsePercentage(heatmaps[col] - value, null, heatmaps, totals, true)}
          </span>
        </TableCell>
      );
    }

    if (props.data.length > props.limit - 1)
      return (
        <TableRow>
          <TableCell rowSpan={columns.length - footer.length}>Others</TableCell>
          {footer}
        </TableRow>
      );
    return null;
  };

  const renderRows = () => {
    calculateSum();

    return props.data.slice(0, props.limit ? props.limit - 1 : props.data.length).map((row, i) => {
      return <TableRow key={"obj.label" + i}>{renderRow(row)}</TableRow>;
    });
  };

  return (
    <Table classes={{ root: classes.TableRoot }} aria-label="customized table">
      {renderColumns()}
      <TableBody>
        {renderRows()}
        {renderOtherRows()}
      </TableBody>
    </Table>
  );
};

export default withRouter(TableComponent);
