import graphql from "babel-plugin-relay/macro";

export default graphql`
  query getCampaignMetricsQuery($params: Json) {
    campaignMetrics(params: $params) {
      breakdown
      id
      total
    }
  }
`;
