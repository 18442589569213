/**
 * @flow
 * @relayHash 4cb8a977ab271b97866635e8b4f6b7da
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getReportDashboardsQueryVariables = {|
  orgId: string,
  propertyId?: ?string,
  type?: ?string,
  name?: ?string,
|};
export type getReportDashboardsQueryResponse = {|
  +reportDashboards: $ReadOnlyArray<{|
    +id: string
  |}>
|};
export type getReportDashboardsQuery = {|
  variables: getReportDashboardsQueryVariables,
  response: getReportDashboardsQueryResponse,
|};
*/


/*
query getReportDashboardsQuery(
  $orgId: ID!
  $propertyId: ID
  $type: String
  $name: String
) {
  reportDashboards(orgId: $orgId, propertyId: $propertyId, type: $type, name: $name) {
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "orgId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "propertyId",
    "type": "ID",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "type",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "name",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "reportDashboards",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "name",
        "variableName": "name"
      },
      {
        "kind": "Variable",
        "name": "orgId",
        "variableName": "orgId"
      },
      {
        "kind": "Variable",
        "name": "propertyId",
        "variableName": "propertyId"
      },
      {
        "kind": "Variable",
        "name": "type",
        "variableName": "type"
      }
    ],
    "concreteType": "Dashboard",
    "plural": true,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getReportDashboardsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "getReportDashboardsQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "getReportDashboardsQuery",
    "id": null,
    "text": "query getReportDashboardsQuery(\n  $orgId: ID!\n  $propertyId: ID\n  $type: String\n  $name: String\n) {\n  reportDashboards(orgId: $orgId, propertyId: $propertyId, type: $type, name: $name) {\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1a6601026d5878af8d6cfe1cbd8e722c';
module.exports = node;
