import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import makeStyles from "@mui/styles/makeStyles";
import List from "@mui/material/List";
import Menu from "./Menu";
import _ from "lodash";

const styles = makeStyles((theme) => ({
  listItem: {
    padding: "16px !important",
    lineHeight: 1,
    cursor: "pointer",
    color: "#333333",
    background: "rgba(255, 255, 255, 0.06)",
    display: "flex",
    alignItems: "center",
    "&:hover span": {
      color: theme.palette.hylyBlue,
    },
    "&:hover i": {
      color: theme.palette.hylyBlue,
    },
    "&:hover svg": {
      stroke: theme.palette.hylyBlue,
    },
    "&:hover path": {
      stroke: theme.palette.hylyBlue,
    },
    "&:hover div": {
      color: theme.palette.hylyBlue,
    },
  },
  headerListItem: {
    "&:hover div": {
      color: "#333333 !important",
    },
  },
  listItemSelected: {
    padding: "16px !important",
    cursor: "pointer",
    backgroundColor: "var(--hylyThemeBg) !important",
    alignItems: "center",
    whiteSpace: "break-spaces",
    "&:hover span": {
      color: theme.palette.hylyBlue,
    },
    "&:hover div": {
      color: theme.palette.hylyBlue,
    },
    "& i": {
      color: theme.palette.hylyBlue,
    },
    "& svg": {
      stroke: theme.palette.hylyBlue,
    },
    "& path": {
      stroke: theme.palette.hylyBlue,
    },
    "& $textItem": {
      fontWeight: "bold",
    },
  },
  subItemSelected: {
    backgroundColor: "#f9f9f9 !important",
    "& i": {
      color: "#666",
    },
    "& svg": {
      stroke: "#666",
    },
    "& path": {
      stroke: "#666",
    },
  },
  textItem: {
    color: theme.palette.darkBlack,
    fontWeight: theme.fontWeight.main,
    textOverflow: "ellipsis !important",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  nestedText: {
    color: "#666",
    fontWeight: theme.fontWeight.main,
    cursor: "pointer",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  remove_padding: {
    paddingLeft: "8px !important",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  selected: {
    fontWeight: theme.fontWeight.bold,
    color: "#333333",
    lineHeight: "24px !important",
  },
  list: {
    paddingBottom: 0,
    maxHeight: "calc(100vh - 200px)",
    overflowY: "auto",
  },
  icon_class_container: {
    minWidth: 30,
  },
  drop_down_icon_open: {
    webkitTransform: "rotate(90deg)",
    transform: "rotate(90deg)",
  },
  drop_down_icon_normal: {
    webkitTransform: "rotate(0deg)",
    transform: "rotate(0deg)",
  },
  drop_down_icon_left: {
    webkitTransform: "rotate(-180deg)",
    transform: "rotate(-180deg)",
  },
  submenu: {
    marginLeft: 63,
  },
  subMenuItem: {
    cursor: "pointer",
    "&:hover span": {
      color: theme.palette.hylyBlue,
    },
    "&:hover div": {
      color: theme.palette.hylyBlue,
    },
    "& i": {
      color: theme.palette.hylyBlue,
    },
    "&:hover svg": {
      stroke: theme.palette.hylyBlue,
    },
    "&:hover path": {
      stroke: theme.palette.hylyBlue,
    },
    backgroundColor: theme.palette.white,
    padding: "10px 20px;",
  },
  selectedSubMenuItem: {
    color: `${theme.palette.hylyBlue} !important;`,
  },
  subMenuItemText: {
    paddingLeft: "0 !important",
    color: theme.palette.darkBlack,
    marginTop: "0px !important",
    marginBottom: "0px !important",
    marginLeft: "5px !important",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  submenuPopover: {
    boxShadow: "none !important",
    backgroundColor: theme.palette.white,
    minWidth: 180,
    left: "17px !important",
  },
  textStyle: {
    fontSize: 16,
    fontFamily: "Open Sans, arial, sans-serif",
    lineHeight: "32px",
    width: "100%",
  },
  iconTypeAlign: {
    marginLeft: 6,
  },
  textHidden: {
    display: "none",
  },
  displayIcon: {
    display: "flex",
    alignItems: "center",
    marginLeft: 36,
  },
  displayIconPopUp: {
    display: "flex",
    alignItems: "end",
    height: 30,
  },
  subItemSelectedSVG: {
    "& svg": {
      stroke: theme.palette.hylyBlue,
    },
    "& path": {
      stroke: theme.palette.hylyBlue,
    },
  },
  borderLine: {
    borderTop: "1px solid #dadada",
  },
  marginStyle: {
    marginLeft: 15,
  },
  dotIcon: { margin: "auto 2px", width: "4px", height: "4px" },
  marginLeftSyle: {
    marginLeft: 12,
  },
  customWidth: { width: "auto !important" },
  tooltip: {
    fontSize: 14,
    background: "#333",
    borderRadius: 0,
    padding: "6px 10px",
    maxWidth: 200,
    background: "white",
    border: "1px solid #939090",
    color: "#333",
  },
  arrow: {
    fontSize: 16,
    width: 17,
    "&::before": {
      border: "1px solid #939090",
      backgroundColor: "#fff",
      boxSizing: "border-box",
    },
  },
}));

const NavListWithCollapse = ({ menu, setOriginNavItems, ...props }) => {
  const [data_open, set_data] = React.useState({});
  const [currentTarget, setCurrentTarget] = React.useState({});
  const matchesSwipeable = useMediaQuery("(min-width:1280px)");
  const classes = styles();

  React.useEffect(() => {
    let flag = false;
    if (!props.menu_open && !_.isEmpty(currentTarget)) setCurrentTarget({});
    if (menu && props.menu_open) {
      for (let i = 0; i < menu.length; i++) {
        if (menu[i].children) {
          for (let j = 0; j < menu[i].children.length; j++) {
            let path = window.location.pathname.split("/");
            if (path[path.length - 1] == "view") {
              path.splice(-1);
            }
            if (path.join("/") == menu[i].children[j].to) {
              set_data({ [menu[i].title]: true });
              flag = true;
              break;
            }
            if (
              path[path.length - 1] == "liked" &&
              path.join("/").includes(menu[i].children[j].to)
            ) {
              set_data({ [menu[i].title]: true });
              flag = true;
              break;
            }
            if (
              path[path.length - 1] == "dislike" &&
              path.join("/").includes(menu[i].children[j].to)
            ) {
              set_data({ [menu[i].title]: true });
              flag = true;
              break;
            }
            if (
              path[path.length - 1] == "regenerate" &&
              path.join("/").includes(menu[i].children[j].to)
            ) {
              set_data({ [menu[i].title]: true });
              flag = true;
              break;
            }
            if (
              path[path.length - 1] == "human_replaced" &&
              path.join("/").includes(menu[i].children[j].to)
            ) {
              set_data({ [menu[i].title]: true });
              flag = true;
              break;
            }
          }
          if (flag) break;
        } else {
          set_data({});
        }
      }
    }
  }, [props.menu_open, menu, window.location.pathname]);

  const open_data = (property, e) => {
    set_data({ [property]: !data_open[property] });
    setCurrentTarget({ [property]: e.currentTarget });
  };
  const clear_target = React.useCallback(() => setCurrentTarget({}), []);
  const renderMenu = (item, index) => (
    <Menu
      {...item}
      {...props}
      matchesSwipeable={matchesSwipeable}
      data_open={data_open}
      currentTarget={currentTarget}
      classes={classes}
      key={index}
      clear_target={clear_target}
      open_data={open_data}
      setOriginNavItems={setOriginNavItems}
      orgLevel={props.orgLevel}
    />
  );

  return (
    <List className={classes.list}>{Boolean(menu && menu.length) && menu.map(renderMenu)}</List>
  );
};

export default React.memo(NavListWithCollapse);
