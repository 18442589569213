import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import PencilWithStrokeIcon from "components/Icons/PencilWithStrokeIcon";
import { getJourneyInfo, setJourneyInfo } from "redux/slices/journeyFreehandSlice";
import SimpleCrossIcon from "components/Icons/SimpleCrossIcon";
import SimpleCheckIcon from "components/Icons/SimpleCheckIcon";

import updateMJourney from "relay/JourneyFreehand/mutations/updateMJourney";

export default function ({ showNotification }) {
  const [editable, setEditable] = useState(false);
  const [name, setName] = useState("");

  const dispatch = useDispatch();
  const journeyInfo = useSelector(getJourneyInfo);

  useEffect(() => {
    if (journeyInfo) {
      setName(journeyInfo.name);
    }
  }, [journeyInfo]);

  const handleCancel = () => {
    setName(journeyInfo?.name);
    setEditable(false);
  };

  const handleChangeName = async () => {
    const params = {
      id: journeyInfo.id,
      name,
    };
    let result = await updateMJourney(params);
    if (result?.updateMJourney?.mjourney) {
      dispatch(setJourneyInfo(result.updateMJourney.mjourney));
      showNotification(`${name} updated Successfully.`);
      setEditable(false);
    }
  };

  return (
    <div className="flex items-center group cursor-pointer text-[#333333] -ml-3 text-[16px] space-x-[8px]">
      {editable ? (
        <div>
          <input
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="w-[190px] leading-[24px] border-x-0 border-t-0 border-b-[1px] border-[#DADADA] pb-[8px] pt-[10px] px-[6px] text-[#333333] text-[16px]"
          />
        </div>
      ) : (
        <p className="mb-0 group-hover:text-hyly-blue">{name}</p>
      )}
      {editable ? (
        <div className="flex">
          <div onClick={handleChangeName}>
            <SimpleCheckIcon size={24} className="text-[#333333] hover:text-hyly-blue" />
          </div>
          <div onClick={handleCancel}>
            <SimpleCrossIcon size={24} className="text-[#333333] hover:text-hyly-blue" />
          </div>
        </div>
      ) : (
        <div
          onClick={() => {
            setEditable((prev) => !prev);
          }}
        >
          <PencilWithStrokeIcon className="group-hover:text-hyly-blue text-[#333333]" />
        </div>
      )}
    </div>
  );
}
