import React from "react";
import Grid from "@mui/material/Grid";
import withStyles from "@mui/styles/withStyles";
import Typography from "storybookComponents/Typography";
import ErrorImage from "storybookComponents/images/404-image.svg";

const styles = (theme) => ({
  root: {
    ...theme.margin,
    [theme.breakpoints.up("md")]: {
      ...theme.marginBig,
    },
    marginTop: 0,
  },
  node: {
    background: theme.palette.white,
    padding: "16px",
    [theme.breakpoints.up("md")]: {
      padding: "24px",
    },
  },
  flexShrink: {
    minWidth: 0,
    minHeight: 0,
    flexShrink: 1,
    width: "100%",
    height: "400px",
  },
  text: {
    display: "flex",
    justifyContent: "center",
  },
});

export default withStyles(styles)(({ isRoot, classes: { root, node, flexShrink, text } }) => {
  const className = isRoot ? root : node;

  return (
    <Grid container className={className}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <img src={ErrorImage} className={flexShrink} alt="error" />
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Typography color="deepGrey" variant="body1" className={text}>
          We are working to fix this problem, please try again soon.
        </Typography>
      </Grid>
    </Grid>
  );
});
