import graphql from "babel-plugin-relay/macro";

export default graphql`
  query getTemplatesQuery($propertyId: ID!, $type: String) {
    categories(propertyId: $propertyId, type: $type) {
      id
      name
      createdAt
      type
      hasMore
      templates {
        id
        name
        createdAt
        largeThumbnailUrl
        smallThumbnailUrl
      }
    }
  }
`;
