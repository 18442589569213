/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import errorImage from "images/unauth-property.png";

const style = {
  container: {
    height: "100vh",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    textAlign: "center",
  },
  heading: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "28px",
    lineHeight: "48px",
    textAlign: "center",
    color: "#333333",
  },
  subHeading: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "20px",
    lineHeight: "30px",
    textAlign: "center",
    color: "rgba(51, 51, 51, 0.75)",
  },
  text: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "24px",
    textAlign: "center",
    color: "rgba(51, 51, 51, 0.75)",
  },
  link: {
    color: "#27bbed",
  },
  appName: {
    textTransform: "capitalize",
  },
};

var count = 10;

export default () => {
  const linkMyHyly = "https://" + process.env.REACT_APP_MyhylyHost;
  const [timer, setTimer] = React.useState(count);
  const [appName, setAppName] = React.useState("Journey AI");

  React.useEffect(() => {
    const splittedPath = window.location.pathname.split("/");
    if (splittedPath.length === 3) {
      setAppName(splittedPath[2]);
    }
    let countDown = setInterval(async () => {
      if (count === 0) {
        clearInterval(countDown);
        window.location.href = linkMyHyly;
      } else {
        count = count - 1;
        await setTimer(count);
      }
    }, 1000);
  }, []);

  return (
    <div style={style.container}>
      <div style={style.content}>
        <img src={errorImage} alt="error" />
        <div style={style.heading}>
          You do not have access to{" "}
          <span style={style.appName}>{appName.replaceAll("%20", " ")}</span>
        </div>
        <div style={style.subHeading}>You will be redirected to my.hy.ly in {timer}s</div>
        <div style={style.text}>
          Or, you may{" "}
          <a style={style.link} href={linkMyHyly}>
            click here
          </a>{" "}
          to redirect now
        </div>
      </div>
    </div>
  );
};
