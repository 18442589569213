import graphql from "babel-plugin-relay/macro";

export default graphql`
  query getGtmIdQuery($propertyId: ID!) {
    websiteGtmScript(propertyId: $propertyId) {
      id
      noscript
      script
    }
  }
`;
