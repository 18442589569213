import React from "react";

export default function ChevronIcon({ size = 20, className = "text-[#333333]", opacity = 0.75 }) {
  return (
    <svg
      className={className}
      width={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 8L10 13L5 8"
        stroke="currentColor"
        strokeOpacity={opacity}
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  );
}
