/**
 * @flow
 * @relayHash c08656ea036b3c10f69916abe20eee8e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getPropertyReportStatQueryVariables = {|
  dashboardId: string,
  params?: ?any,
|};
export type getPropertyReportStatQueryResponse = {|
  +tenantOrCampaignStat: {|
    +tenantStat: ?any,
    +id: string,
  |}
|};
export type getPropertyReportStatQuery = {|
  variables: getPropertyReportStatQueryVariables,
  response: getPropertyReportStatQueryResponse,
|};
*/


/*
query getPropertyReportStatQuery(
  $dashboardId: ID!
  $params: Json
) {
  tenantOrCampaignStat(dashboardId: $dashboardId, params: $params) {
    tenantStat
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "dashboardId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "params",
    "type": "Json",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "tenantOrCampaignStat",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "dashboardId",
        "variableName": "dashboardId"
      },
      {
        "kind": "Variable",
        "name": "params",
        "variableName": "params"
      }
    ],
    "concreteType": "TenantOrCampaignStat",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "tenantStat",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getPropertyReportStatQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "getPropertyReportStatQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "getPropertyReportStatQuery",
    "id": null,
    "text": "query getPropertyReportStatQuery(\n  $dashboardId: ID!\n  $params: Json\n) {\n  tenantOrCampaignStat(dashboardId: $dashboardId, params: $params) {\n    tenantStat\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '56ab9b684395ce670ad30417da56e182';
module.exports = node;
