import React from "react";

function ArrowDown({ size = 24, className = "text-[#333333]" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 20 20"
      className={className}
      width={size}
    >
      <path
        stroke="currentColor"
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeOpacity="0.75"
        d="M15 8l-5 5-5-5"
      ></path>
    </svg>
  );
}

export default ArrowDown;
