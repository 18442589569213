import moment from "moment";
import "moment-timezone";

export const capitalize = (input, splittedBy = "_") => {
  var words = input.split(splittedBy);
  words = words.filter((d) => d != "");
  var CapitalizedWords = [];
  words.forEach((element) => {
    CapitalizedWords.push(element[0].toUpperCase() + element.slice(1, element.length));
  });
  return CapitalizedWords.join(" ");
};

export const compare = (first, second, order) => {
  // Handle null values
  if (first === null && second === null) {
    return 0;
  } else if (first === null) {
    return order ? 1 : -1;
  } else if (second === null) {
    return order ? -1 : 1;
  }

  // Convert to uppercase if both values are strings
  let a = typeof first === "string" ? first.toUpperCase() : first;
  let b = typeof second === "string" ? second.toUpperCase() : second;

  if (typeof a === "string" && typeof b === "string" && a.includes("%") && b.includes("%")) {
    a = Number(a.replace("%", ""));
    b = Number(b.replace("%", ""));
  }

  if (a < b) {
    return order ? 1 : -1;
  }
  if (a > b) {
    return order ? -1 : 1;
  }
  return 0;
};

export const sortingTableData = (key, _tableData, orderType, isListView) => {
  let tableData = [..._tableData];
  if (tableData.lenght < 2) return tableData;
  let i = 0;
  while (i < tableData.length - 1) {
    let temp = !isListView ? tableData[i] : tableData[i].node;
    let k = i;
    for (let j = k; j < tableData.length; j++) {
      let _tableItem = !isListView ? tableData[j] : tableData[j].node;
      if (orderType === "asc") {
        if (_tableItem[key] < temp[key]) {
          temp = _tableItem;
          k = j;
        }
      } else {
        if (_tableItem[key] > temp[key]) {
          temp = _tableItem;
          k = j;
        }
      }
    }
    if (i !== k) {
      let copy = tableData[k];
      tableData[k] = tableData[i];
      tableData[i] = copy;
    }
    i++;
  }
  return tableData;
};

export const numberWithCommas = (x) => {
  const numberConvert = Number(x);

  if (x == null) {
    return "";
  }
  return numberConvert ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : x;
};

export const filterBySearchText = (text, filterValue) => {
  return String(text)?.toLowerCase().includes(filterValue.toLowerCase());
};

export const filterByGreaterThan = (number, filterNumber) =>
  parseFloat(number) >= parseFloat(filterNumber);

export const filterByGreaterThanDate = (date, filterDate) => new Date(date) >= new Date(filterDate);

export const filterByMomentGreaterThanDate = (date, filterDate) =>
  moment(date, "MMM DD, YYYY - hh:mm a") >= moment(filterDate, "MMM DD, YYYY - hh:mm a");

export const getDate = (e) => {
  return moment(e).format("D MMM, YYYY - h:mmA");
};

export const getDefaultDateTimeReports = (timezoneString) => {
  return {
    startAt: moment().tz(timezoneString).startOf("month"),
    endAt: moment().tz(timezoneString).endOf("month"),
    label: "This Month",
  };
};

export const getSubOrgOfOrg = (Orgs, orgId) => {
  if (!Orgs) {
    return [];
  }
  let orgDetails = Orgs.find((org) => org.id === orgId);
  if (orgDetails)
    return orgDetails.suborgs.map((suborg) => {
      return {
        id: suborg.id,
        value: suborg.name,
        region: suborg.region,
        regionId: suborg.regionId,
      };
    });
  return [];
};

export const groupByRegions = (Orgs, orgId) => {
  if (!Orgs || !Array.isArray(Orgs)) {
    return [];
  }

  let orgDetails = Orgs.find((org) => org.id === orgId);

  const groupedRegions = {};

  orgDetails.suborgs.forEach((org) => {
    const regionId = org.regionId || "other";
    const regionName = org.region || "Other";

    if (!(regionId in groupedRegions)) {
      groupedRegions[regionId] = {
        id: regionId,
        value: regionName,
        list: [],
      };
    }

    groupedRegions[regionId].list.push({
      id: org.id,
      value: org.name,
    });
  });

  return Object.values(groupedRegions).map((region) => {
    return {
      id: region.id,
      value: region.value,
      list: region.list,
    };
  });
};

export const combineLists = (data) => {
  if (!data || !Array.isArray(data)) {
    return [];
  }

  const combinedList = [];

  data.forEach((region) => {
    combinedList.push(...region.list);
  });

  return combinedList;
};

export const groupedSelectedProperties = (subOrg, selectedProperties) => {
  return subOrg
    .map((item) => {
      return {
        id: item.id,
        list: item.list
          .filter((subItem) => selectedProperties.includes(subItem.id))
          .map((idx) => idx.id),
      };
    })
    .filter((item) => item.list.length > 0);
};

export const filterRegionsByIds = (properties, selectedProp) => {
  return properties
    .filter((obj) => obj.list.some((item) => selectedProp.includes(item.id)))
    .map((idx) => idx.id);
};
