import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";
import environment from "environments/environment.js";

const mutation = graphql`
  mutation scheduleReportMutation($input: scheduleReportInput!) {
    scheduleReport(input: $input) {
      scheduledReport {
        id
        type
        org {
          id
          name
          userRole
        }
        recipients
        day
        range
      }
    }
  }
`;

export default function (input) {
  return new Promise((res, rej) => {
    commitMutation(environment, {
      mutation,
      variables: { input },
      onCompleted: res,
      onError: rej,
    });
  });
}
