import React from "react";
import { withRouter } from "react-router-dom";
import Preview from "storybookComponents/Preview/dialog";
import Loader from "storybookComponents/HylyLoader";
import Error from "error";
import { getListPublicOffers } from "relay/offers";
import {
  popupHtml,
  widgetHtml,
  bannerHtml,
} from "content/Offers/EditOffers/Preview/htmls/index.js";
import moment from "moment";
import Icon from "storybookComponents/Icon";
import withStyles from "@mui/styles/withStyles";
import "./index.css";

const styles = () => ({
  superContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  previewContainer: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    width: "max-content",
  },
  arrow: {
    height: 32,
    width: 32,
    position: "absolute",
    background: "#f9f9f9",
    opacity: 0.8,
    borderRadius: "2px",
    zIndex: 6,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    "&:hover": {
      color: "var(--hylyTheme)",
    },
  },
  arrowLeft: {
    left: 30,
  },
  arrowRight: {
    right: 30,
  },
  hide: {
    display: "none",
  },
});

const OfferPreview = ({ classes, ...props }) => {
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState();
  const [html, setHtml] = React.useState();
  const params = props.match.params;
  const [index, setIndex] = React.useState(0);
  React.useEffect(() => {
    getInitData();
  }, [1]);

  const getMacroResolvedValue = (text, data) => {
    if (!text) {
      return null;
    }
    let endDate = data.endDate ? moment(data.endDate).format("MMM DD, YYYY") : "";
    let str = text.replaceAll("{%concession%}", data.concession);
    str = str.replaceAll("{%value%}", data.value || "");
    str = str.replaceAll("{%end_date%}", endDate);
    str = str.replaceAll("{%property%}", data.propertyName || "");
    return str;
  };

  const getHtml = (campaign) => {
    if (params.view == "popup") {
      setHtml(popupHtml({ ...campaign }));
    } else if (params.view == "banner") {
      setHtml(bannerHtml({ ...campaign }, true));
    } else {
      setHtml(widgetHtml({ ...campaign }));
    }
  };

  const getInitData = async () => {
    try {
      setLoading(true);
      let response = await getListPublicOffers(params.id, [params.propertyId]);
      if (response && response.edges.length > 0) {
        let data = response.edges.map((item) => {
          const designOffer = item.node.designOffer.split(",");
          let macroDict = JSON.parse(item.node.macroDict);
          let obj = {
            endDate: macroDict.end_date,
            concession: macroDict.concession,
            value: macroDict.value,
            propertyName: item.node.propertyName,
          };
          return {
            title: designOffer.includes("title")
              ? getMacroResolvedValue(item.node.offerTitle, obj)
              : null,
            offerCtaText: macroDict.offer_offer_cta,
            image: designOffer.includes("image") ? macroDict.offer_image_url : null,
            subTitle:
              designOffer.includes("title") || designOffer.includes("subtitle")
                ? getMacroResolvedValue(item.node.subTitle, obj)
                : null,
            offer_cta_url: macroDict.offer_cta_url,
          };
        });
        if (data.length > 0) {
          setData(data);
          getHtml(data[0]);
          setLoading(false);
        }
      }
    } catch (err) {
      console.log("Err:", err);
    }
  };
  console.log("data", data);

  const handleNextClick = () => {
    setIndex(index + 1);
    getHtml(data[index + 1]);
  };

  const handlePrevClick = () => {
    setIndex(index - 1);
    getHtml(data[index - 1]);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div id="offer-preview-container" className={classes.superContainer}>
      <div className={classes.previewContainer}>
        {data.length > 1 ? (
          <div
            className={`${classes.arrow} ${classes.arrowLeft} ${index == 0 && classes.hide}`}
            onClick={handlePrevClick}
          >
            <Icon type="icon-left-arrow" size="sm" />
          </div>
        ) : null}
        <div dangerouslySetInnerHTML={{ __html: html }}></div>
        {data.length > 1 ? (
          <div
            className={`${classes.arrow} ${classes.arrowRight} ${
              index == data.length - 1 && classes.hide
            }`}
            onClick={handleNextClick}
          >
            <Icon type="icon-right-arrow" size="sm" />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default withStyles(styles)(OfferPreview);
