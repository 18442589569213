import React from "react";
import withStyles from "@mui/styles/withStyles";

const styles = () => ({
  labelStyle: {
    fontWeight: 600,
    fontSize: 16,
    margin: "0px 12px",
    color: "rgba(51, 51, 51, 0.75)",
    minWidth: 88,
  },
});

const LabelComponent = ({ classes, label }) => {
  return <div className={classes.labelStyle}>{label}</div>;
};

export default withStyles(styles)(LabelComponent);
