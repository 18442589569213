import graphql from "babel-plugin-relay/macro";

export default graphql`
  query mascotQuery($propertyId: ID!) {
    mascot(propertyId: $propertyId) {
      mascotSetting {
        colors {
          primary
          secondary
          tertiary
        }
        animation
        quickActions
        bubbleType
        welcomeMessage
        firstMessage
        disclaimer
        icon
      }
    }
  }
`;
