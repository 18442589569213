import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const initialState = {
  reports: [],
  reportsLoaded: false,
  angelsResList: {},
  selectedProperties: null,
  selectedProperty: [],
  monthRange: {
    startAt: moment().subtract(30, "day"),
    endAt: moment(),
    label: "Last 30 Days",
  },
  propertiesLoaded: false,
  tenantStat: null,
  tenantStatType: null,
  totalSeries: null,
  selectedDrips: [],
  selectedDrip: [],
  allMacrosList: {},
  dripsEmailsData: [],
};

export const dripSlice = createSlice({
  name: "drip",
  initialState,
  reducers: {
    setReports: (state, action) => {
      state.reports = action.payload;
      state.reportsLoaded = true;
    },
    resetReportsLoaded: (state) => {
      state.reportsLoaded = false;
    },
    setAngelsResList: (state, action) => {
      const { angelId, data } = action.payload;
      state.angelsResList[angelId] = data;
    },
    setSelectedProperties: (state, action) => {
      state.selectedProperties = action.payload;
      if (action.payload.length > 0) {
        state.selectedProperty = [state.selectedProperties[0]];
      }
    },
    setSelectedProperty: (state, action) => {
      state.selectedProperty = action.payload;
      state.selectedProperties = action.payload;
    },
    setMonthRange: (state, action) => {
      state.monthRange = action.payload;
    },
    setPropertiesLoaded: (state) => {
      state.propertiesLoaded = true;
    },
    setTenantStat: (state, action) => {
      state.tenantStat = action.payload;
    },
    setTenantStatType: (state, action) => {
      state.tenantStatType = action.payload;
    },
    setTotalSeries: (state, action) => {
      state.totalSeries = action.payload;
    },
    formatPropertiesLoaded: (state) => {
      state.propertiesLoaded = false;
    },
    setSelectedDrips: (state, action) => {
      state.selectedDrips = action.payload;
      if (action.payload.length > 0) {
        state.selectedDrip = [action.payload[0]];
      }
    },
    setSelectedDrip: (state, action) => {
      state.selectedDrip = action.payload;
      state.selectedDrips = action.payload;
    },
    setAllMacrosList: (state, action) => {
      state.allMacrosList = action.payload;
    },
    setDripsEmailsData: (state, action) => {
      state.dripsEmailsData = action.payload;
    },
  },
});

export const {
  setReports,
  setAngelsResList,
  setSelectedProperties,
  setSelectedProperty,
  setMonthRange,
  setPropertiesLoaded,
  setTenantStat,
  setTenantStatType,
  setTotalSeries,
  resetReportsLoaded,
  formatPropertiesLoaded,
  setSelectedDrips,
  setSelectedDrip,
  setAllMacrosList,
  setDripsEmailsData,
} = dripSlice.actions;

export const selectReports = (state) => state.drip.reports;
export const selectReportsLoaded = (state) => state.drip.reportsLoaded;
export const selectAngelsResList = (state) => state.drip.angelsResList;
export const selectSelectedProperties = (state) => state.drip.selectedProperties;
export const selectSelectedProperty = (state) => state.drip.selectedProperty;
export const selectMonthRange = (state) => state.drip.monthRange;
export const selectPropertiesLoaded = (state) => state.drip.propertiesLoaded;
export const selectTenantStat = (state) => state.drip.tenantStat;
export const selectTenantStatType = (state) => state.drip.tenantStatType;
export const selectTotalSeries = (state) => state.drip.totalSeries;
export const selectSelectedDrips = (state) => state.drip.selectedDrips;
export const selectSelectedDrip = (state) => state.drip.selectedDrip;
export const selectMacrosList = (state) => state.drip.allMacrosList;
export const selectDripsEmailsData = (state) => state.drip.dripsEmailsData;

export default dripSlice.reducer;
