/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type ListUnit_item$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type List_suggestions$ref: FragmentReference;
declare export opaque type List_suggestions$fragmentType: List_suggestions$ref;
export type List_suggestions = $ReadOnlyArray<{|
  +$fragmentRefs: ListUnit_item$ref,
  +$refType: List_suggestions$ref,
|}>;
export type List_suggestions$data = List_suggestions;
export type List_suggestions$key = {
  +$data?: List_suggestions$data,
  +$fragmentRefs: List_suggestions$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "List_suggestions",
  "type": "PicklistInterface",
  "metadata": {
    "plural": true
  },
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "ListUnit_item",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '2f65d1ced219c7bbcdd234980a357f96';
module.exports = node;
