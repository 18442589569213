/**
 * @flow
 * @relayHash 5ce2fd43170358e73b5c5888fb857a51
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getDripsMenuQueryVariables = {|
  propertyId: string,
  angelType?: ?string,
  type?: ?string,
  orgId?: ?string,
|};
export type getDripsMenuQueryResponse = {|
  +angels: $ReadOnlyArray<{|
    +id: string,
    +name: ?string,
    +active: ?boolean,
  |}>
|};
export type getDripsMenuQuery = {|
  variables: getDripsMenuQueryVariables,
  response: getDripsMenuQueryResponse,
|};
*/


/*
query getDripsMenuQuery(
  $propertyId: ID!
  $angelType: String
  $type: String
  $orgId: ID
) {
  angels(propertyId: $propertyId, angelType: $angelType, type: $type, orgId: $orgId) {
    id
    name
    active
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "propertyId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "angelType",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "type",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "orgId",
    "type": "ID",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "angels",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "angelType",
        "variableName": "angelType"
      },
      {
        "kind": "Variable",
        "name": "orgId",
        "variableName": "orgId"
      },
      {
        "kind": "Variable",
        "name": "propertyId",
        "variableName": "propertyId"
      },
      {
        "kind": "Variable",
        "name": "type",
        "variableName": "type"
      }
    ],
    "concreteType": "Angel",
    "plural": true,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "name",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "active",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getDripsMenuQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "getDripsMenuQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "getDripsMenuQuery",
    "id": null,
    "text": "query getDripsMenuQuery(\n  $propertyId: ID!\n  $angelType: String\n  $type: String\n  $orgId: ID\n) {\n  angels(propertyId: $propertyId, angelType: $angelType, type: $type, orgId: $orgId) {\n    id\n    name\n    active\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2c4bb656b62829e303aa804975e2ed35';
module.exports = node;
