/**
 * @flow
 * @relayHash 00f43e3ec96000eb7e50416e7543afc8
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getEditorQueryVariables = {|
  id: string,
  appName?: ?string,
|};
export type getEditorQueryResponse = {|
  +hyblastEditor: {|
    +templateId: string,
    +propertyId: string,
    +id: string,
    +name: string,
    +preheader: ?string,
    +gptHistoryId: ?string,
    +subject: string,
    +mms: boolean,
    +mmsBody: string,
    +mmsStatus: string,
    +mmsMediaUrl: ?string,
    +approvalStatus: ?string,
    +editorStatus: string,
    +status: string,
    +sentGroup: ?string,
    +sentTime: ?any,
    +abortedTime: ?any,
    +scheduledTime: ?any,
    +lastTestEmails: string,
    +lastTestPhones: ?string,
    +approvalFeedbacks: ?$ReadOnlyArray<{|
      +id: string,
      +userEmail: string,
      +message: string,
      +createdAt: any,
    |}>,
    +fields: $ReadOnlyArray<{|
      +editable: boolean,
      +dimension: ?string,
      +display: ?string,
      +label: string,
      +field: string,
      +value: ?string,
      +type: string,
      +id: string,
    |}>,
    +document: ?{|
      +createdAt: any,
      +dimension: ?string,
      +fileSize: string,
      +fileType: string,
      +fileUrl: string,
      +id: string,
      +name: string,
      +thumbnailUrl: ?string,
    |},
    +substitutedMacrosHtml: string,
    +senderEmail: ?string,
    +senderEmailVerified: ?boolean,
  |}
|};
export type getEditorQuery = {|
  variables: getEditorQueryVariables,
  response: getEditorQueryResponse,
|};
*/


/*
query getEditorQuery(
  $id: ID!
  $appName: String
) {
  hyblastEditor(id: $id, appName: $appName) {
    templateId
    propertyId
    id
    name
    preheader
    gptHistoryId
    subject
    mms
    mmsBody
    mmsStatus
    mmsMediaUrl
    approvalStatus
    editorStatus
    status
    sentGroup
    sentTime
    abortedTime
    scheduledTime
    lastTestEmails
    lastTestPhones
    approvalFeedbacks {
      id
      userEmail
      message
      createdAt
    }
    fields {
      editable
      dimension
      display
      label
      field
      value
      type
      id
    }
    document {
      createdAt
      dimension
      fileSize
      fileType
      fileUrl
      id
      name
      thumbnailUrl
    }
    substitutedMacrosHtml
    senderEmail
    senderEmailVerified
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "appName",
    "type": "String",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "createdAt",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dimension",
  "args": null,
  "storageKey": null
},
v5 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "hyblastEditor",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "appName",
        "variableName": "appName"
      },
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "HyblastEditor",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "templateId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "propertyId",
        "args": null,
        "storageKey": null
      },
      (v1/*: any*/),
      (v2/*: any*/),
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "preheader",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "gptHistoryId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "subject",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "mms",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "mmsBody",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "mmsStatus",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "mmsMediaUrl",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "approvalStatus",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "editorStatus",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "status",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "sentGroup",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "sentTime",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "abortedTime",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "scheduledTime",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "lastTestEmails",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "lastTestPhones",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "approvalFeedbacks",
        "storageKey": null,
        "args": null,
        "concreteType": "ApprovalFeedback",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "userEmail",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "message",
            "args": null,
            "storageKey": null
          },
          (v3/*: any*/)
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "fields",
        "storageKey": null,
        "args": null,
        "concreteType": "EditorField",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "editable",
            "args": null,
            "storageKey": null
          },
          (v4/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "display",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "label",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "field",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "value",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "type",
            "args": null,
            "storageKey": null
          },
          (v1/*: any*/)
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "document",
        "storageKey": null,
        "args": null,
        "concreteType": "Asset",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "fileSize",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "fileType",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "fileUrl",
            "args": null,
            "storageKey": null
          },
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "thumbnailUrl",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "substitutedMacrosHtml",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "senderEmail",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "senderEmailVerified",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getEditorQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v5/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "getEditorQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v5/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "getEditorQuery",
    "id": null,
    "text": "query getEditorQuery(\n  $id: ID!\n  $appName: String\n) {\n  hyblastEditor(id: $id, appName: $appName) {\n    templateId\n    propertyId\n    id\n    name\n    preheader\n    gptHistoryId\n    subject\n    mms\n    mmsBody\n    mmsStatus\n    mmsMediaUrl\n    approvalStatus\n    editorStatus\n    status\n    sentGroup\n    sentTime\n    abortedTime\n    scheduledTime\n    lastTestEmails\n    lastTestPhones\n    approvalFeedbacks {\n      id\n      userEmail\n      message\n      createdAt\n    }\n    fields {\n      editable\n      dimension\n      display\n      label\n      field\n      value\n      type\n      id\n    }\n    document {\n      createdAt\n      dimension\n      fileSize\n      fileType\n      fileUrl\n      id\n      name\n      thumbnailUrl\n    }\n    substitutedMacrosHtml\n    senderEmail\n    senderEmailVerified\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '90e7330b95fec77d5520fc1216a0a85c';
module.exports = node;
