import React from "react";
import clsx from "clsx";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import makeStyles from "@mui/styles/makeStyles";
import Icon from "storybookComponents/Icon";
import InputBase from "@mui/material/InputBase";
import Button from "storybookComponents/Button";
import Tooltips from "storybookComponents/Tooltip2";
import "./index.css";
import { ArrowDown, ArrowUp } from "components/Icons";

const useStyles = makeStyles({
  selectBoxStyle: (props) => ({
    paddingRight: "4px !important",
    paddingTop: "6px",
    paddingBottom: "7px",
    "& span": {
      "&::before": {
        content: (props) => `"${props.placeholder}"`,
        color: "rgba(51, 51, 51, 0.75)",
        fontSize: 14,
        fontWeight: 600,
      },
    },
    // width: props.width,
  }),
  selectOptionList: {
    padding: 0,
  },
  selectOptionPaper: (props) => ({
    boxShadow: "0px 0px 30px 2px rgba(0, 0, 0, 0.05) !important",
    border: "1px solid #eeeeee",
    background: "white",
    maxWidth: props.width ? props.width : 330,
    marginTop: props.skipMargin ? 0 : 9,
    marginLeft: props.skipMargin ? 0 : -15,
  }),
  selectedCount: {
    color: "rgba(51, 51, 51, 0.5)",
  },
  listTopContainer: {
    height: 48,
    borderBottom: "1px solid #dadada",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "4px 12px",
    outline: "none",
  },
  listItemStyle: {
    height: 36,
    // padding: "4px 12px",
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    cursor: "pointer",
    "&:hover": {
      color: "var(--hylyTheme)",
    },
  },
  allOptionContainer: (props) => ({
    maxHeight: props.height ? props.height : 200,
    overflowY: "auto",
  }),
  clearAllStyle: {
    color: "#4F6FAE",
    cursor: "pointer",
    marginLeft: 16,
  },
  flexBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
  },
  selectedPlaceholder: {
    marginTop: 2,
    fontSize: 14,
  },
  zoomIconStyle: {
    cursor: "pointer",
    "&:hover": {
      color: "var(--hylyTheme)",
    },
  },
  fontStyleCSS: {
    fontSize: "14px !important",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    lineHeight: "20px !important",
  },
  groupPropertyStyle: {
    color: "rgba(51, 51, 51, 0.5)",
    padding: "8px 12px",
  },
  paddingSingleSelect: {
    padding: "8px 12px",
  },
  alignRightIcons: {
    width: "100%",
    textAlignLast: "right",
  },
  labelsStyle: {
    color: "#33333380",
  },
  selectAllStyle: {
    fontWeight: 600,
    marginLeft: -10,
    color: "rgba(51, 51, 51, 0.75)",
    cursor: "pointer",
    "&:hover": {
      color: "var(--hylyTheme)",
    },
  },
  bottomContainer: {
    height: 56,
    boxShadow: "0px 0px 8px #EEEEEE",
    padding: "12px 16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  btnStyle: {
    marginLeft: 12,
    padding: "6px 16px",
    height: 32,
    textTransform: "none",
  },
  selectedValue: {
    fontWeight: 600,
  },
});

const SelectComponent = ({
  value,
  onChange,
  labels,
  groupBy,
  placeholder,
  multiple,
  singleSelect,
  selectAllText,
  searchPlaceHolder,
  optionValue,
  disableSearch,
  disableAllSelect,
  height,
  width,
  getCustomLabel,
  getCustomCount = (p1, p2) => p1,
  mainWidth,
  defaultIcon,
  customIcon,
  name,
  disableButton,
  hidePlaceholder,
  skipMargin,
  showUnderline,
  classes: customClasses,
  isDisabled,
  isTooltip,
  tooltip_Position,
  tooltip_Text,
  suffix = "",
  formControlClassname = "",
}) => {
  const [selectedValue, setSelectedValue] = React.useState(value);
  const [showSearchField, setShowSearchField] = React.useState(singleSelect ? true : false);
  const [dropDownValue, setDropDownValue] = React.useState(optionValue);
  const [open, setOpen] = React.useState(false);
  const [selectedKeyValue, setKeyValue] = React.useState();
  const [count, setCount] = React.useState(0);
  const [ownPlaceholder, setOwnPlaceholder] = React.useState(placeholder ?? "Select");

  const classes = useStyles({
    placeholder: hidePlaceholder ? "" : ownPlaceholder,
    height: height,
    width: width,
    mainWidth: mainWidth,
    skipMargin,
  });

  const function4UseEffect = async () => {
    setKeyValue(null);
    setCount(0);
    setDropDownValue(optionValue);
    // setSelectedValue(value);
    if (singleSelect) {
      await initData(optionValue, value);
    }
  };

  React.useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  React.useEffect(() => {
    function4UseEffect();
  }, [optionValue, value]);

  React.useEffect(() => {
    if (optionValue.length === selectedValue.length) {
      setOwnPlaceholder(name ?? "Select" + " (All)");
    }
  }, [selectedValue]);

  const initData = (optionValue, value) => {
    if (groupBy) {
      for (var i = 0; i < optionValue.length; i++) {
        for (var j = 0; j < optionValue[i].list.length; j++) {
          if (optionValue[i].list[j].id === value[0]) {
            setKeyValue(optionValue[i].list[j].value);
          }
        }
      }
    } else {
      let obj = optionValue.find((e) => e.id === value[0]);
      if (obj) {
        setKeyValue(obj.value);
      }
    }
  };

  const handleClose = () => {
    setSelectedValue(value);
    setOpen(false);
    if (disableButton) {
      if (typeof onChange === "function") {
        onChange(selectedValue);
      }
    }
  };

  const handleApply = () => {
    setOpen(false);
    if (typeof onChange === "function") {
      onChange(selectedValue);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleChange = (item, groupByKey, value) => {
    if (singleSelect) {
      setKeyValue(value);
      setSelectedValue([item]);
      setOpen(false);
      if (typeof onChange === "function") {
        onChange([item]);
      }
    } else {
      if (groupBy) {
        let copyOfState = selectedValue.slice();
        let searchedEventsByID =
          copyOfState.length > 0 && copyOfState.find((ele) => ele.id === groupByKey);
        if (searchedEventsByID) {
          if (searchedEventsByID.list.includes(item)) {
            if (searchedEventsByID.list.length > 1) {
              searchedEventsByID.list = searchedEventsByID.list.filter((e) => e !== item);
            } else {
              copyOfState = selectedValue.filter((ele) => ele.id !== searchedEventsByID.id);
            }
          } else {
            searchedEventsByID.list = [...searchedEventsByID.list, item];
          }
        } else {
          copyOfState.push({
            id: groupByKey,
            list: [item],
          });
        }
        if (copyOfState.length === 0) {
          setCount(0);
        }
        setSelectedValue(copyOfState);
      } else {
        if (selectedValue.includes(item)) {
          let filterValue = selectedValue.filter((e) => e !== item);
          if (filterValue.length === 0) {
            setCount(0);
          }
          setSelectedValue(filterValue);
        } else {
          setSelectedValue([...selectedValue, item]);
        }
      }
    }
  };

  const renderList = (e, index, groupByKey) => {
    let isChecked = false;
    if (multiple) {
      if (groupBy && groupByKey) {
        let searchedObj = selectedValue.find((ele) => ele.id === groupByKey);
        if (searchedObj && searchedObj.list) {
          if (searchedObj.list.includes(e.id)) {
            isChecked = true;
          }
        }
      } else {
        isChecked = selectedValue.indexOf(e.id) > -1 ? true : false;
      }
    } else {
      if (e.id == selectedValue?.[0]) {
        isChecked = true;
      }
    }
    return (
      <div
        onClick={handleChange.bind(this, e.id, groupByKey, e.value)}
        className={clsx(
          classes.listItemStyle,
          customClasses?.option,
          e.disabled ? customClasses?.disableOption : null
        )}
        key={index}
      >
        {multiple && <Checkbox checked={isChecked} />}
        <div
          className={singleSelect && classes.paddingSingleSelect}
          style={isChecked && singleSelect ? { fontWeight: 600 } : null}
        >
          {e.value}
        </div>
      </div>
    );
  };

  const renderGroupByList = (e, index) => {
    if (!e.list || e.list.length < 1) {
      return null;
    }
    return (
      <div key={index}>
        <div className={`${classes.groupPropertyStyle} ${classes.fontStyleCSS}`}>{e.value}</div>
        {e.list && e.list.map((ele, index) => renderList(ele, index, e.id))}
      </div>
    );
  };

  const handleSearchChange = (e) => {
    if (groupBy) {
      let searchResult = optionValue.map((ele) => {
        let searchedValue = ele.list.filter((p) =>
          p.value.toLowerCase().includes(e.target.value.toLowerCase())
        );
        return {
          id: ele.id,
          value: ele.value,
          list: searchedValue,
        };
      });
      setDropDownValue(searchResult);
    } else {
      let searchResult = optionValue.filter((ele) =>
        ele.value.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setDropDownValue(searchResult);
    }
  };
  const handleClearAll = () => {
    setSelectedValue([]);
    setCount(0);
    // if (typeof onChange === "function") {
    //   onChange([]);
    // }
  };
  const compareGroupByValue = () => {
    if (!groupBy) {
      return selectedValue.length == optionValue.length;
    }

    for (var i = 0; i < optionValue.length; i++) {
      let find = selectedValue.find((e) => e.id === optionValue[i].id);
      if (!find) {
        return false;
      }
      if (find.list.length !== optionValue[i].list.length) {
        return false;
      }
    }
    return true;
  };

  const handleSelectAll = (e) => {
    if (compareGroupByValue()) {
      setSelectedValue([]);
      setCount(0);
    } else {
      let allIds = [];
      if (groupBy) {
        for (let i = 0; i < optionValue.length; i++) {
          let listOfIds = [];
          for (let j = 0; j < optionValue[i].list.length; j++) {
            listOfIds.push(optionValue[i].list[j].id);
          }
          if (listOfIds.length > 0) {
            allIds.push({
              id: optionValue[i].id,
              list: listOfIds,
            });
          }
        }
      } else {
        allIds = optionValue.map((e) => e.id);
      }
      setSelectedValue(allIds);
    }
  };

  const handleSearchClick = () => {
    setShowSearchField(!showSearchField);
    setDropDownValue(optionValue);
  };

  const renderValue = (selected) => {
    if (groupBy && !singleSelect) {
      let list = [];
      for (var i = 0; i < selected.length; i++) {
        if (selected[i].list) {
          list.push(selected[i].list);
        }
      }
      setCount(list.length);
    } else {
      setCount(selected.length);
    }

    if (typeof getCustomLabel === "function") {
      return getCustomLabel(selected, value.length, selectedKeyValue, open);
    }
  };

  const getIcon = () => {
    if (!open && !defaultIcon && !customIcon) {
      return (
        <div>
          <ArrowDown />
        </div>
      );
    }

    if (open && !defaultIcon && !customIcon) {
      return (
        <div>
          <ArrowUp />
        </div>
      );
    }

    return (
      <Icon
        className="stroke-[#333333]"
        onClick={open ? handleClose : handleOpen}
        type={
          open
            ? defaultIcon
              ? "icon-triangle-up"
              : customIcon
              ? customIcon
              : "icon-up-arrow"
            : defaultIcon
            ? "icon-triangle-down"
            : customIcon
            ? customIcon
            : "icon-down-arrow"
        }
        size="sm"
      />
    );
  };
  return (
    <FormControl className={formControlClassname} variant="standard" id="global-selector-component">
      {isTooltip ? (
        <Tooltips position={tooltip_Position} text={tooltip_Text}>
          <Select
            multiple
            displayEmpty
            open={open}
            disabled={isDisabled || false}
            IconComponent={getIcon}
            id="demo-simple-select-hycxp"
            value={selectedValue}
            onChange={() => {}}
            onClose={handleClose}
            onOpen={handleOpen}
            classes={{
              select: `${classes.selectBoxStyle} ${customClasses?.capitalText}`,
            }}
            renderValue={renderValue}
            disableUnderline={!showUnderline}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,
              classes: {
                list: classes.selectOptionList,
                paper: classes.selectOptionPaper,
              },
            }}
          >
            {disableAllSelect && disableSearch ? null : (
              <div className={`${classes.listTopContainer} ${classes.fontStyleCSS}`}>
                {showSearchField ? (
                  <InputBase
                    fullWidth
                    onChange={handleSearchChange}
                    className={classes.fontStyleCSS}
                    placeholder={searchPlaceHolder || "Search"}
                    endAdornment={
                      <div
                        onClick={singleSelect ? () => {} : handleSearchClick}
                        className={singleSelect ? "" : classes.zoomIconStyle}
                      >
                        <Icon type={singleSelect ? "icon-zoom" : "icon-e-remove"} size="sm" />
                      </div>
                    }
                  />
                ) : (
                  <>
                    {multiple && !disableAllSelect ? (
                      <div onClick={handleSelectAll} className={`${classes.selectAllStyle}`}>
                        <Checkbox
                          checked={selectedValue.length > 0}
                          indeterminate={selectedValue.length > 0 && !compareGroupByValue()}
                        />
                        {selectAllText}
                      </div>
                    ) : null}
                    {!disableSearch && (
                      <div
                        className={`${classes.zoomIconStyle} ${
                          singleSelect && classes.alignRightIcons
                        }`}
                        onClick={handleSearchClick}
                      >
                        <Icon type="icon-zoom" />
                      </div>
                    )}
                  </>
                )}
              </div>
            )}
            <div className={`${classes.allOptionContainer} ${classes.fontStyleCSS}`}>
              {groupBy ? dropDownValue.map(renderGroupByList) : dropDownValue.map(renderList)}
            </div>
            {!singleSelect && !disableButton ? (
              <div className={classes.bottomContainer}>
                <div className={classes.selectedCount}>
                  {getCustomCount(count, selectedValue)} Selected
                  {/* {countList ? allCountByListItems() : count} Selected */}
                </div>
                <div>
                  <Button
                    variant="tertiary"
                    state={count > 0 ? "active" : "inactive"}
                    className={classes.btnStyle}
                    onClick={handleClearAll}
                  >
                    Clear
                  </Button>
                  <Button onClick={handleApply} className={classes.btnStyle}>
                    Apply
                  </Button>
                </div>
              </div>
            ) : null}
          </Select>
        </Tooltips>
      ) : (
        <Select
          multiple
          displayEmpty
          open={open}
          disabled={isDisabled || false}
          IconComponent={getIcon}
          id="demo-simple-select-hycxp"
          value={selectedValue}
          onChange={() => {}}
          onClose={handleClose}
          onOpen={handleOpen}
          classes={{
            select: `${classes.selectBoxStyle} ${customClasses?.capitalText}`,
          }}
          renderValue={renderValue}
          disableUnderline={!showUnderline}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
            classes: {
              list: classes.selectOptionList,
              paper: classes.selectOptionPaper,
            },
          }}
        >
          {disableAllSelect && disableSearch ? null : (
            <div className={`${classes.listTopContainer} ${classes.fontStyleCSS}`}>
              {showSearchField ? (
                <InputBase
                  fullWidth
                  onChange={handleSearchChange}
                  className={classes.fontStyleCSS}
                  placeholder={searchPlaceHolder || "Search"}
                  endAdornment={
                    <div
                      onClick={singleSelect ? () => {} : handleSearchClick}
                      className={singleSelect ? "" : classes.zoomIconStyle}
                    >
                      <Icon type={singleSelect ? "icon-zoom" : "icon-e-remove"} size="sm" />
                    </div>
                  }
                />
              ) : (
                <>
                  {multiple && !disableAllSelect ? (
                    <div onClick={handleSelectAll} className={`${classes.selectAllStyle}`}>
                      <Checkbox
                        checked={selectedValue.length > 0}
                        indeterminate={selectedValue.length > 0 && !compareGroupByValue()}
                      />
                      {selectAllText}
                    </div>
                  ) : null}
                  {!disableSearch && (
                    <div
                      className={`${classes.zoomIconStyle} ${
                        singleSelect && classes.alignRightIcons
                      }`}
                      onClick={handleSearchClick}
                    >
                      <Icon type="icon-zoom" />
                    </div>
                  )}
                </>
              )}
            </div>
          )}
          <div className={`${classes.allOptionContainer} ${classes.fontStyleCSS}`}>
            {groupBy ? dropDownValue.map(renderGroupByList) : dropDownValue.map(renderList)}
          </div>
          {!singleSelect && !disableButton ? (
            <div className={classes.bottomContainer}>
              <div className={classes.selectedCount}>
                {getCustomCount(count, selectedValue)} Selected
                {/* {countList ? allCountByListItems() : count} Selected */}
              </div>
              <div>
                <Button
                  variant="tertiary"
                  state={count > 0 ? "active" : "inactive"}
                  className={classes.btnStyle}
                  onClick={handleClearAll}
                >
                  Clear
                </Button>
                <Button
                  state={count > 0 ? "active" : "inactive"}
                  onClick={handleApply}
                  className={classes.btnStyle}
                >
                  Apply
                </Button>
              </div>
            </div>
          ) : null}
        </Select>
      )}
    </FormControl>
  );
};

export default SelectComponent;
