import React from "react";

export default function hyIntegrationsIcon({ size = 18, className = "text-[#999999]" }) {
  return (
    <svg
      width={size}
      className={className}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 15L4.62 13.3792"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.7993 8.60001L6.6001 9.80001"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.39995 10.2L8.19995 11.4"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.61995 9.42001C4.09482 9.94513 3.7998 10.6574 3.7998 11.4C3.7998 12.1426 4.09482 12.8549 4.61995 13.38C5.14508 13.9051 5.8573 14.2001 6.59995 14.2001C7.34259 14.2001 8.05482 13.9051 8.57995 13.38L9.37995 12.58L5.39995 8.60001L4.61995 9.42001Z"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.9999 3L13.3799 4.6208"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3801 8.57999C13.9052 8.05486 14.2003 7.34264 14.2003 6.59999C14.2003 5.85735 13.9052 5.14512 13.3801 4.61999C12.855 4.09486 12.1428 3.79985 11.4001 3.79985C10.6575 3.79985 9.94525 4.09486 9.42012 4.61999L8.62012 5.41999L12.6001 9.39999L13.3801 8.57999Z"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
