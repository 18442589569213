import graphql from "babel-plugin-relay/macro";

export default graphql`
  query getAngelsQuery($propertyId: ID!, $id: String, $angelType: String) {
    angels(propertyId: $propertyId, id: $id, angelType: $angelType) {
      id
      name
      active
      thenEmails {
        id
        name
        active
        triggerDays
        originalThumbnailUrl
        whenGroupName
        tourType
      }
    }
  }
`;
