/**
 * @flow
 * @relayHash b9b913b51f13d956340e73a6fa75f91b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getHyreferralCampaignsPropertiesBreakdownQueryVariables = {|
  orgId: string,
  propertyIds?: ?$ReadOnlyArray<string>,
  campaignIds?: ?$ReadOnlyArray<string>,
  startAt: string,
  endAt: string,
  status?: ?string,
|};
export type getHyreferralCampaignsPropertiesBreakdownQueryResponse = {|
  +hyreferralCampaignsPropertiesBreakdown: $ReadOnlyArray<{|
    +id: string,
    +title: string,
    +propertyId: string,
    +propertyName: ?string,
    +active: boolean,
    +friendsCount: ?string,
    +leasedCount: ?string,
    +residentsCount: ?string,
    +reward: ?number,
    +createdAt: any,
  |}>
|};
export type getHyreferralCampaignsPropertiesBreakdownQuery = {|
  variables: getHyreferralCampaignsPropertiesBreakdownQueryVariables,
  response: getHyreferralCampaignsPropertiesBreakdownQueryResponse,
|};
*/


/*
query getHyreferralCampaignsPropertiesBreakdownQuery(
  $orgId: ID!
  $propertyIds: [String!]
  $campaignIds: [String!]
  $startAt: String!
  $endAt: String!
  $status: String
) {
  hyreferralCampaignsPropertiesBreakdown(orgId: $orgId, propertyIds: $propertyIds, campaignIds: $campaignIds, startAt: $startAt, endAt: $endAt, status: $status) {
    id
    title
    propertyId
    propertyName
    active
    friendsCount
    leasedCount
    residentsCount
    reward
    createdAt
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "orgId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "propertyIds",
    "type": "[String!]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "campaignIds",
    "type": "[String!]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "startAt",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "endAt",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "status",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "hyreferralCampaignsPropertiesBreakdown",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "campaignIds",
        "variableName": "campaignIds"
      },
      {
        "kind": "Variable",
        "name": "endAt",
        "variableName": "endAt"
      },
      {
        "kind": "Variable",
        "name": "orgId",
        "variableName": "orgId"
      },
      {
        "kind": "Variable",
        "name": "propertyIds",
        "variableName": "propertyIds"
      },
      {
        "kind": "Variable",
        "name": "startAt",
        "variableName": "startAt"
      },
      {
        "kind": "Variable",
        "name": "status",
        "variableName": "status"
      }
    ],
    "concreteType": "HyreferralCampaign",
    "plural": true,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "title",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "propertyId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "propertyName",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "active",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "friendsCount",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "leasedCount",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "residentsCount",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "reward",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "createdAt",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getHyreferralCampaignsPropertiesBreakdownQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "getHyreferralCampaignsPropertiesBreakdownQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "getHyreferralCampaignsPropertiesBreakdownQuery",
    "id": null,
    "text": "query getHyreferralCampaignsPropertiesBreakdownQuery(\n  $orgId: ID!\n  $propertyIds: [String!]\n  $campaignIds: [String!]\n  $startAt: String!\n  $endAt: String!\n  $status: String\n) {\n  hyreferralCampaignsPropertiesBreakdown(orgId: $orgId, propertyIds: $propertyIds, campaignIds: $campaignIds, startAt: $startAt, endAt: $endAt, status: $status) {\n    id\n    title\n    propertyId\n    propertyName\n    active\n    friendsCount\n    leasedCount\n    residentsCount\n    reward\n    createdAt\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '17372a9108349158761578d42cc33d20';
module.exports = node;
