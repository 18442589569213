import graphql from "babel-plugin-relay/macro";

export default graphql`
query getSavedReportsQuery($propertyId: ID!,$type:String) {
  savedReports(propertyId:$propertyId, type:$type){
    analyzeObject
    createdAt
    id
    name
    params
  }
}
  `
