/**
 * @flow
 * @relayHash eabf7ac9021454392636c5b1fc906d35
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getAppsQueryVariables = {|
  orgId?: ?string,
  propertyId?: ?string,
|};
export type getAppsQueryResponse = {|
  +apps: $ReadOnlyArray<{|
    +id: string,
    +name: string,
    +customName: ?string,
    +url: string,
    +authorized: boolean,
    +appOrHub: string,
    +modules: $ReadOnlyArray<string>,
    +access: ?string,
    +expiredAt: ?string,
  |}>
|};
export type getAppsQuery = {|
  variables: getAppsQueryVariables,
  response: getAppsQueryResponse,
|};
*/


/*
query getAppsQuery(
  $orgId: ID
  $propertyId: ID
) {
  apps(orgId: $orgId, propertyId: $propertyId) {
    id
    name
    customName
    url
    authorized
    appOrHub
    modules
    access
    expiredAt
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "orgId",
    "type": "ID",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "propertyId",
    "type": "ID",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "apps",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "orgId",
        "variableName": "orgId"
      },
      {
        "kind": "Variable",
        "name": "propertyId",
        "variableName": "propertyId"
      }
    ],
    "concreteType": "App",
    "plural": true,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "name",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "customName",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "url",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "authorized",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "appOrHub",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "modules",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "access",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "expiredAt",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getAppsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "getAppsQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "getAppsQuery",
    "id": null,
    "text": "query getAppsQuery(\n  $orgId: ID\n  $propertyId: ID\n) {\n  apps(orgId: $orgId, propertyId: $propertyId) {\n    id\n    name\n    customName\n    url\n    authorized\n    appOrHub\n    modules\n    access\n    expiredAt\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5660b2ad6a6fa2b4953f09aeae8cc472';
module.exports = node;
