import React from "react";

export default function hyCRMIcon({ width = 18, height = 18, className = "text-[#999999]" }) {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.00005 7C11.8719 7 14.2 6.10457 14.2 5C14.2 3.89543 11.8719 3 9.00005 3C6.12817 3 3.80005 3.89543 3.80005 5C3.80005 6.10457 6.12817 7 9.00005 7Z"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.80005 7.79999V13C3.80005 14.1048 6.12805 15 9.00005 15C11.872 15 14.2 14.1048 14.2 13V7.79999"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
