import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const useHayley = (hayleyRef, hayleyPosition, propertyId, isRentOrg, isCustom) => {
  const location = useLocation();
  const [visible, setVisible] = useState(false);

  // Hayley window size handler
  const windowMessageEventListener = (event) => {
    if (event.data.type === "hayley-resize") {
      if (!hayleyRef.current) {
        return;
      }

      if (
        event.data.isOpen &&
        (event.data.isVideoTourScreen ||
          event.data.isPhotoTourScreen ||
          event.data.isUnitTourScreen ||
          event.data.isMobile ||
          event.data.isMaximized)
      ) {
        hayleyRef.current.style.height = "100%";
        hayleyRef.current.style.width = "100%";
        hayleyRef.current.style.top = "0px";
        hayleyRef.current.style.left = "0px";
        hayleyRef.current.style.position = "fixed";
        hayleyRef.current.style.margin = "0px";

        return;
      }

      if (!event.data.isOpen) {
        hayleyRef.current.style.height = event.data.height;
        hayleyRef.current.style.width = event.data.width;

        if (hayleyPosition === "sticky-right") {
          hayleyRef.current.style.bottom = "auto";
          hayleyRef.current.style.right = "0px";
          hayleyRef.current.style.top = isCustom ? "40%" : "50%";
          hayleyRef.current.style.left = "auto";
          hayleyRef.current.style.transform = event.data.isOpen ? "none" : "none";
          hayleyRef.current.style.position = isCustom ? "absolute" : "fixed";
        } else if (hayleyPosition === "bottom-left") {
          hayleyRef.current.style.bottom = "16px";
          hayleyRef.current.style.right = "auto";
          hayleyRef.current.style.top = "auto";
          hayleyRef.current.style.left = "16px";
          hayleyRef.current.style.transform = "translate(0, 0)";
          hayleyRef.current.style.position = "absolute";
        } else {
          hayleyRef.current.style.bottom = "16px";
          hayleyRef.current.style.right = "16px";
          hayleyRef.current.style.top = "auto";
          hayleyRef.current.style.left = "auto";
          hayleyRef.current.style.transform = "translate(0, 0)";
          hayleyRef.current.style.position = isCustom ? "absolute" : "fixed";
        }

        return;
      }

      if (event.data.isOpen && !event.data.noSpace) {
        hayleyRef.current.style.height = event.data.height;
        hayleyRef.current.style.width = event.data.width;
        hayleyRef.current.style.transform = "translate(0, 0)";
        hayleyRef.current.style.bottom = "16px";
        hayleyRef.current.style.top = "auto";

        if (hayleyPosition !== "bottom-left") {
          hayleyRef.current.style.right = "16px";
          hayleyRef.current.style.left = "auto";
          hayleyRef.current.style.position = isCustom ? "absolute" : "fixed";
        } else {
          hayleyRef.current.style.right = "auto";
          hayleyRef.current.style.left = "16px";
          hayleyRef.current.style.position = "absolute";
        }

        return;
      }

      if (event.data.noSpace) {
        hayleyRef.current.style.height = "100vh";
        hayleyRef.current.style.width = event.data.width;
        hayleyRef.current.style.bottom = "0px";
        hayleyRef.current.style.top = "auto";
        hayleyRef.current.style.transform = "translate(0, 0)";

        if (hayleyPosition !== "bottom-left") {
          hayleyRef.current.style.right = "0px";
          hayleyRef.current.style.left = "auto";
          hayleyRef.current.style.position = isCustom ? "absolute" : "fixed";
        } else {
          hayleyRef.current.style.right = "auto";
          hayleyRef.current.style.left = "0px";
          hayleyRef.current.style.position = "absolute";
        }

        return;
      }
    }
  };

  // Attach event listener
  useEffect(() => {
    window.addEventListener("message", windowMessageEventListener);

    return () => {
      window.removeEventListener("message", windowMessageEventListener);
    };
  }, [hayleyPosition, propertyId]);

  // Hayley visibility handler
  useEffect(() => {
    // const zohoChatbot = document.getElementById("zohohc-asap-web-launcherbox");
    const zohoChatbot = document.getElementById("hubspot-messages-iframe-container");

    if (
      location.pathname.includes("/hayley/mascot") ||
      location.pathname.includes("/hayley/preference")
    ) {
      setVisible(true);

      if (zohoChatbot) {
        // zohoChatbot.style.display = "none";
        zohoChatbot.style.setProperty("display", "none", "important");
      }
    } else {
      setVisible(false);

      if (zohoChatbot && !isRentOrg) {
        zohoChatbot.style.display = "flex";
      }
    }
  }, [location]);

  // Hayley appearing animation handler
  useEffect(() => {
    if (hayleyRef.current) {
      setTimeout(() => {
        hayleyRef.current.style.transform = "translateY(0%)";
      }, 500);
    }
  }, [hayleyRef]);

  return visible;
};

export default useHayley;
