import React from "react";

export default function PencilIcon({ size = 24, className = "text-[#999999]" }) {
  return (
    <svg
      className={className}
      width={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M13.3574 7.72461L16.2741 10.6413" stroke="currentColor" stroke-miterlimit="10" />
      <path
        d="M9.66707 17.2503L6.16699 17.8337L6.75034 14.3336L14.5753 6.50858C14.7941 6.28986 15.0908 6.16699 15.4002 6.16699C15.7096 6.16699 16.0063 6.28986 16.225 6.50858L17.4921 7.77561C17.7108 7.9944 17.8337 8.2911 17.8337 8.60046C17.8337 8.90983 17.7108 9.20653 17.4921 9.42531L9.66707 17.2503Z"
        stroke="currentColor"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
    </svg>
  );
}
