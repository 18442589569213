/**
 * @flow
 * @relayHash a10a7a30f7c87444e9a252cf4cf2f943
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type updateHayleyMascotInput = {|
  clientMutationId?: ?string,
  propertyId: string,
  iconUrl?: ?string,
  position?: ?string,
  iconFileType?: ?string,
  imageData?: ?string,
  iconThumbnailUrl?: ?string,
  widgetTitle?: ?string,
  chatbotTitle?: ?string,
  disclaimer: string,
  firstMessage: string,
|};
export type updateHayleyMutationVariables = {|
  input: updateHayleyMascotInput
|};
export type updateHayleyMutationResponse = {|
  +updateHayleyMascot: ?{|
    +response: ?{|
      +iconThumbnailUrl: ?string,
      +iconUrl: ?string,
      +id: string,
      +imageData: ?string,
      +position: ?string,
      +widgetTitle: ?string,
      +chatbotTitle: ?string,
      +supportMultiLanguage: ?boolean,
      +hidePriceRangeFilter: ?boolean,
      +iconFileType: ?string,
      +disclaimer: string,
      +firstMessage: string,
    |}
  |}
|};
export type updateHayleyMutation = {|
  variables: updateHayleyMutationVariables,
  response: updateHayleyMutationResponse,
|};
*/


/*
mutation updateHayleyMutation(
  $input: updateHayleyMascotInput!
) {
  updateHayleyMascot(input: $input) {
    response {
      iconThumbnailUrl
      iconUrl
      id
      imageData
      position
      widgetTitle
      chatbotTitle
      supportMultiLanguage
      hidePriceRangeFilter
      iconFileType
      disclaimer
      firstMessage
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "updateHayleyMascotInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateHayleyMascot",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "updateHayleyMascotPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "response",
        "storageKey": null,
        "args": null,
        "concreteType": "Hayley",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "iconThumbnailUrl",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "iconUrl",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "imageData",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "position",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "widgetTitle",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "chatbotTitle",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "supportMultiLanguage",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "hidePriceRangeFilter",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "iconFileType",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "disclaimer",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "firstMessage",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "updateHayleyMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "updateHayleyMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "updateHayleyMutation",
    "id": null,
    "text": "mutation updateHayleyMutation(\n  $input: updateHayleyMascotInput!\n) {\n  updateHayleyMascot(input: $input) {\n    response {\n      iconThumbnailUrl\n      iconUrl\n      id\n      imageData\n      position\n      widgetTitle\n      chatbotTitle\n      supportMultiLanguage\n      hidePriceRangeFilter\n      iconFileType\n      disclaimer\n      firstMessage\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '98a271b710f35cda3ab10136f45b273a';
module.exports = node;
