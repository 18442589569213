import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Field, getIn } from "formik";

const FormikField = ({
  name: field,
  type: validation_type,
  Component,
  handleChange,
  required,
  variant,
  multiple,
  ...other
}) => {
  function get_field_type(type) {
    return /email|url/.test(validation_type) ? validation_type : "text";
  }

  const type = get_field_type(validation_type);

  const name_from_schema = useMemo(
    () => (type, field, required, variant) => {
      if (variant === "array") {
        return field;
      }

      if (type === "address") {
        return `address__${field}`;
      }

      if (!/^top$|^left$|^outlined$/.test(variant)) {
        return field;
      }

      const validation_type = Boolean(type) ? type : "text",
        required_suffix = Boolean(required) ? "__required" : "";

      return `${validation_type}__${field}${required_suffix}`;
    },
    [validation_type, field, required, variant]
  );

  return (
    <Field type={type} name={name_from_schema(validation_type, field, required, variant)}>
      {(props) => {
        const {
          field: { name, value, onChange, onBlur },
          form: { touched, errors, setErrors, setStatus, setFieldValue },
        } = props;

        const get_error = () => {
          if (variant === "array") {
            const [field_name, idx] = name.split(".");
            return errors[field_name] ? errors[field_name][idx] : "";
          } else if (validation_type === "address" && getIn(touched, name)) {
            return getIn(errors, name);
          }

          //else {
          return errors[name] || "";
          //}

          //  return '';
        };

        return (
          <Component
            type={type}
            name={name}
            value={value}
            required={required}
            variant={variant}
            multiple={multiple}
            onKeyUp={type === "email" ? () => setFieldValue(name, value.toLowerCase()) : undefined}
            onChange={(e) => {
              setStatus(null);
              if (Boolean(handleChange)) {
                handleChange(e, props);
              } else if (errors[name]) {
                delete errors[name];
                setErrors(errors);
              }
              onChange(e);
            }}
            onBlur={onBlur}
            error={get_error()}
            {...props}
            field={field}
            {...other}
          />
        );
      }}
    </Field>
  );
};

FormikField.propTypes = {
  field: PropTypes.string.isRequired,
  Component: React.isValidElement,
  handleChange: PropTypes.func,
};

export default FormikField;
