import React from "react";

export default function ChangeUserIcon({ className = "text-[#999999]", width = 18, height = 18 }) {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.25 9.75C6.838 9.75 5.5855 10.069 4.7625 10.3465C4.155 10.552 3.75 11.1245 3.75 11.766V13.75H7.75"
        stroke="currentColor"
        stroke-miterlimit="10"
      />
      <path
        d="M8.25 9.75C6.8695 9.75 5.75 8.1305 5.75 6.75V6.25C5.75 4.8695 6.8695 3.75 8.25 3.75C9.6305 3.75 10.75 4.8695 10.75 6.25V6.75C10.75 8.1305 9.6305 9.75 8.25 9.75Z"
        stroke="currentColor"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
      <path
        d="M11.25 14.75H9.75V13.25L12.75 10.25L14.25 11.75L11.25 14.75Z"
        stroke="currentColor"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
    </svg>
  );
}
