import graphql from "babel-plugin-relay/macro";

export default graphql`
  query getHayleyUrlQuery($propertyId: ID!) {
    hayleyUrl(propertyId: $propertyId) {
      email
      facebook
      googleMyBusiness
      general
      instagram
    }
  }
`;
