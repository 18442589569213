import * as apis from "relay/main";

export const _getApps = (orgId, propertyId) => {
  return new Promise(function (resolve, reject) {
    apis.getApps(orgId, propertyId).then((res, err) => {
      if (err || res.errors || res.error) {
        resolve(false);
      }
      resolve(res.apps);
    });
  });
};
