// DUCKS pattern
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isShowFavorite: false,
  dashboardType: "",
};

const showFavoriteSlice = createSlice({
  name: "showFavorite",
  initialState,
  reducers: {
    setShowFav: (state) => {
      state.isShowFavorite = true;
    },
    setHideFav: (state) => {
      state.isShowFavorite = false;
    },
    setDashboardType: (state, action) => {
      state.dashboardType = action.payload;
    },
    resetDashboardType: (state) => {
      state.dashboardType = "";
    },
  },
});

export const getIsShowFavorite = (state) => state.showFavorite.isShowFavorite;
export const getDashboardType = (state) => state.showFavorite.dashboardType;

export const { setShowFav, setHideFav, setDashboardType, resetDashboardType } =
  showFavoriteSlice.actions;
export default showFavoriteSlice.reducer;
