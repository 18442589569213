import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: null,
};

export const BlastGptSlice = createSlice({
  name: "gptPrompt",
  initialState,
  reducers: {
    gptPrompt: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { gptPrompt } = BlastGptSlice.actions;
export default BlastGptSlice.reducer;
