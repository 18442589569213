import graphql from "babel-plugin-relay/macro";

export default graphql`
  query getSelfGuidedTourQuery($propertyId: ID!) {
    selfGuidedTour(propertyId: $propertyId) {
      selfGuidedTourUrl
      hylyAppEditUrl
    }
  }
`;
