/**
 * @flow
 * @relayHash d2a864ab36ca011d7c0b1dd9a2fbc6d5
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getMailGptPromptsListQueryVariables = {|
  tenantId: string,
  promptType?: ?string,
|};
export type getMailGptPromptsListQueryResponse = {|
  +listMailGptPrompts: $ReadOnlyArray<?{|
    +id: string,
    +promptId: string,
    +promptType: string,
    +promptTexts: $ReadOnlyArray<?string>,
    +promptTitle: string,
    +numberOfPasses: string,
    +promptDescription: string,
    +uiFields: ?$ReadOnlyArray<string>,
    +useCases: ?$ReadOnlyArray<string>,
    +template: ?{|
      +id: string
    |},
  |}>
|};
export type getMailGptPromptsListQuery = {|
  variables: getMailGptPromptsListQueryVariables,
  response: getMailGptPromptsListQueryResponse,
|};
*/


/*
query getMailGptPromptsListQuery(
  $tenantId: String!
  $promptType: String
) {
  listMailGptPrompts(tenantId: $tenantId, promptType: $promptType) {
    id
    promptId
    promptType
    promptTexts
    promptTitle
    numberOfPasses
    promptDescription
    uiFields
    useCases
    template {
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "tenantId",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "promptType",
    "type": "String",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "listMailGptPrompts",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "promptType",
        "variableName": "promptType"
      },
      {
        "kind": "Variable",
        "name": "tenantId",
        "variableName": "tenantId"
      }
    ],
    "concreteType": "MailGptPrompt",
    "plural": true,
    "selections": [
      (v1/*: any*/),
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "promptId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "promptType",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "promptTexts",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "promptTitle",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "numberOfPasses",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "promptDescription",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "uiFields",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "useCases",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "template",
        "storageKey": null,
        "args": null,
        "concreteType": "Template",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getMailGptPromptsListQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "getMailGptPromptsListQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "getMailGptPromptsListQuery",
    "id": null,
    "text": "query getMailGptPromptsListQuery(\n  $tenantId: String!\n  $promptType: String\n) {\n  listMailGptPrompts(tenantId: $tenantId, promptType: $promptType) {\n    id\n    promptId\n    promptType\n    promptTexts\n    promptTitle\n    numberOfPasses\n    promptDescription\n    uiFields\n    useCases\n    template {\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd82bc431ae925617552602e6d62123c0';
module.exports = node;
