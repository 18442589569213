import React from "react";
import moment from "moment";
import "moment-timezone";
import { IconUpArrow } from "icons";
import { getBestDayLabel } from "..";
import Button from "storybookComponents/Button";

const timeConstraints = {
  hours: {
    min: 0,
    max: 23,
    step: 1,
  },
  minutes: {
    min: 0,
    max: 59,
    step: 1,
  },
  seconds: {
    min: 0,
    max: 59,
    step: 1,
  },
  milliseconds: {
    min: 0,
    max: 999,
    step: 1,
  },
};

var timeOut = null;
var timeInterval = null;

function createConstraints(overrideTimeConstraints) {
  let constraints = {};

  Object.keys(timeConstraints).forEach((type) => {
    constraints[type] = { ...timeConstraints[type], ...(overrideTimeConstraints[type] || {}) };
  });

  return constraints;
}

function formatNum(value) {
  if (value < 10) return "0" + value;
  return value;
}

export default class TimeView extends React.Component {
  constructor(props) {
    super(props);

    this.constraints = createConstraints(props.timeConstraints);

    // This component buffers the time part values in the state
    // while the user is pressing down the buttons
    // and call the prop `setTime` when the buttons are released
    this.default = props.selectedDate ? false : true;

    this.state = this.getTimeParts(
      props.selectedDate ||
        props.minValidDate ||
        (props.displayTimeZone ? moment().tz(props.displayTimeZone) : moment())
    );
  }

  compareTime = (value) => {
    const time = value.split(" ");
    if (this.state.hours == time[0] && this.state.ampm == time[1] && this.state.minutes == 0) {
      return true;
    } else return false;
  };

  renderBestTime(dayData, isLast) {
    return (
      <>
        <Button
          className={this.compareTime(dayData.value) ? "selectedButton" : "slotButtons"}
          variant="secondary"
          size="sm"
          color="primary"
          onClick={this.handleSelectTime.bind(this, dayData.value)}
        >
          {dayData.value}
        </Button>
      </>
    );
  }

  handleSelectTime = (value) => {
    const time = value.split(" ");
    this.props.setTime("time", {
      hours: time[1] == "AM" ? time[0] : Number(time[0]) + 12,
      minutes: 0,
    });
  };

  render() {
    let items = [];
    const timeParts = this.state;

    this.getCounters().forEach((c, i) => {
      if (i && c !== "ampm") {
        items.push(
          <div key={`sep${i}`} className="rdtCounterSeparator">
            :
          </div>
        );
      }

      items.push(this.renderCounter(c, timeParts[c]));
    });

    const { scheduledHourData } = this.props;

    let hoursData = [];
    const date = this.props.selectedDate || this.props.viewDate;
    const dayStr = date.format("YYYY-MM-DD");
    if (scheduledHourData?.excellent_time) {
      let value = "";
      const times = scheduledHourData.excellent_time.split(" OR ");
      if (times.length === 1) {
        value = moment(`${dayStr} ${times[0]}`).format("h A");
      } else {
        if (
          moment(`${dayStr} ${times[0]}`).format("A") ===
          moment(`${dayStr} ${times[1]}`).format("A")
        ) {
          value = `${moment(`${dayStr} ${times[0]}`).format("h")} OR ${moment(
            `${dayStr} ${times[1]}`
          ).format("h A")}`;
        } else {
          value = `${moment(`${dayStr} ${times[0]}`).format("h A")} OR ${moment(
            `${dayStr} ${times[1]}`
          ).format("h A")}`;
        }
      }
      hoursData.push({ value: value });
    }
    if (scheduledHourData?.good_time) {
      let value1 = "";
      let value2 = "";
      const times = scheduledHourData.good_time.split(" OR ");
      if (times.length === 1) {
        value1 = moment(`${dayStr} ${times[0]}`).format("h A");
      } else {
        if (
          moment(`${dayStr} ${times[0]}`).format("A") ===
          moment(`${dayStr} ${times[1]}`).format("A")
        ) {
          value1 = `${moment(`${dayStr} ${times[0]}`).format("h")}`;
          value2 = `${moment(`${dayStr} ${times[1]}`).format("h A")}`;
        } else {
          value1 = `${moment(`${dayStr} ${times[0]}`).format("h A")} `;
          value2 = `${moment(`${dayStr} ${times[1]}`).format("h A")}`;
        }
      }
      hoursData.push({ value: value1 }, { value: value2 });
    }
    if (scheduledHourData?.poor_time) {
      let value = "";
      const times = scheduledHourData.poor_time.split(" OR ");
      if (times.length === 1) {
        value = moment(`${dayStr} ${times[0]}`).format("h A");
      } else {
        if (
          moment(`${dayStr} ${times[0]}`).format("A") ===
          moment(`${dayStr} ${times[1]}`).format("A")
        ) {
          value = `${moment(`${dayStr} ${times[0]}`).format("h")} OR ${moment(
            `${dayStr} ${times[1]}`
          ).format("h A")}`;
        } else {
          value = `${moment(`${dayStr} ${times[0]}`).format("h A")} OR ${moment(
            `${dayStr} ${times[1]}`
          ).format("h A")}`;
        }
      }
      hoursData.push({ value: value });
    }

    return (
      <div
        className={`${this.props.splittedView ? "splitedViewTime" : "rdtTime"} ${
          this.props.dateFormat && "rdtTime-height"
        }`}
      >
        <table>
          {this.renderHeader()}
          <tbody>
            <tr>
              <td>
                <div className={this.props.splittedView ? "splitedViewCounters" : "rdtCounters"}>
                  {items}
                </div>
                {hoursData.length > 0 && (
                  <div className="dayContainer">
                    <div className="dayContainerLabel">Recommended Times for selected date</div>
                    {hoursData.map((dayData, dayIndex) =>
                      this.renderBestTime(dayData, dayIndex === hoursData.length - 1)
                    )}
                  </div>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  renderCounter(type, value) {
    // if (type === 'hours' && this.isAMPM()) {
    // 	value = (value - 1) % 12 + 1;

    // 	if (value === 0) {
    // 		value = 12;
    // 	}
    // }

    return (
      <div key={type} className="rdtCounter">
        {/* <span
          className="icon icon-up-arrow"
          onClick={(e) => this.onStartClicking(e, "increase", type)}
        ></span> */}
        <div
          className="svg-icon rotate-icon"
          onMouseDown={(e) => this.startCount(e, "increase", type)}
          onMouseUp={(e) => this.stopCount()}
        >
          <IconUpArrow />
        </div>
        <input
          className={type === "ampm" ? "rdtTime" : "rdtCount"}
          type="text"
          value={type !== "ampm" ? formatNum(value) : value}
          onChange={type !== "ampm" ? this.manuallyChange.bind(this, type) : null}
          disabled={type === "ampm" ? true : false}
          id={type}
        />
        <div
          className="svg-icon"
          onMouseDown={(e) => this.startCount(e, "decrease", type)}
          onMouseUp={(e) => this.stopCount()}
        >
          <IconUpArrow />
        </div>
        {/* <span
          className="icon icon-down-arrow"
          onClick={(e) => this.onStartClicking(e, "decrease", type)}
        ></span> */}
      </div>
    );
  }

  renderHeader() {
    if (!this.props.dateFormat) return;
    const date = this.props.selectedDate || this.props.viewDate;

    const { scheduledDayData } = this.props;

    return (
      <thead>
        <tr>
          <td colSpan="4" onClick={() => this.props.showView("days")}>
            <div className="rdtSwitch">
              {date.format("MMM DD, YYYY")}
              <div className="dateBestLabel">
                {scheduledDayData && getBestDayLabel(date, scheduledDayData)}
              </div>
            </div>
          </td>
        </tr>
      </thead>
    );
  }

  getHours = (ampm, hours) => {
    if (hours !== 12) return parseInt(ampm.toLowerCase() === "pm" ? hours + 12 : hours, 10);
    else return parseInt(ampm.toLowerCase() === "am" ? hours - 12 : hours, 10);
  };

  startCount = (e, value, type) => {
    this.onStartClicking(e, value, type);
    if (type !== "ampm") {
      timeOut = setTimeout(() => {
        timeInterval = setInterval(() => this.onStartClicking(e, value, type), 200);
      }, 500);
    }
  };

  stopCount = () => {
    clearInterval(timeInterval);
    clearTimeout(timeOut);
  };

  onStartClicking(e, action, type) {
    let { hours, minutes, ampm } = this.state;
    let h = parseInt(hours, 10);
    let m = parseInt(minutes, 10);
    if (isNaN(h)) h = 0;
    if (isNaN(m)) m = 0;
    const date = this.props.selectedDate ? this.props.selectedDate.clone() : "";

    if (type === "hours" && action === "increase") {
      h = h + 1 > 12 ? 1 : h + 1;
      if (date && this.props.isValidDate && typeof this.props.isValidDate == "function") {
        const hours = this.getHours(this.state.ampm, h);
        if (!this.props.isValidDate(date.hours(hours), true)) {
          this.props.handleNotification(
            "fail",
            "You can only select a time that is 15 mins from now"
          );
          return;
        }
      }
      this.setState(
        {
          hours: h,
        },
        this.cbHours
      );
    }

    if (type === "hours" && action === "decrease") {
      h = h - 1 < 1 ? 12 : h - 1;
      if (date && this.props.isValidDate && typeof this.props.isValidDate == "function") {
        const hours = this.getHours(this.state.ampm, h);
        if (!this.props.isValidDate(date.hours(hours), true)) {
          this.props.handleNotification(
            "fail",
            "You can only select a time that is 15 mins from now"
          );
          return;
        }
      }
      this.setState(
        {
          hours: h,
        },
        this.cbHours
      );
    }

    if (type === "minutes" && action === "increase") {
      m = m + 1 > 59 ? 0 : m + 1;
      if (date && this.props.isValidDate && typeof this.props.isValidDate == "function") {
        if (!this.props.isValidDate(date.minutes(m), true)) {
          this.props.handleNotification(
            "fail",
            "You can only select a time that is 15 mins from now"
          );
          return;
        }
      }
      this.setState(
        {
          minutes: m,
        },
        this.cbMinutes
      );
    }

    if (type === "minutes" && action === "decrease") {
      m = m - 1 < 1 ? 59 : m - 1;
      if (date && this.props.isValidDate && typeof this.props.isValidDate == "function") {
        if (!this.props.isValidDate(date.minutes(m), true)) {
          this.props.handleNotification(
            "fail",
            "You can only select a time that is 15 mins from now"
          );
          return;
        }
      }
      this.setState(
        {
          minutes: m,
        },
        this.cbMinutes
      );
    }

    if (type === "ampm") {
      if (this.props.timeFormat.indexOf(" A") !== -1) {
        ampm = ampm === "AM" ? "PM" : "AM";
        if (date && this.props.isValidDate && typeof this.props.isValidDate == "function") {
          const hours = this.getHours(ampm, this.state.hours);
          if (!this.props.isValidDate(date.hours(hours), true)) {
            this.props.handleNotification(
              "fail",
              "You can only select a time that is 15 mins from now"
            );
            return;
          }
        }
        this.setState(
          {
            ampm,
          },
          this.cbamAM
        );
      } else {
        ampm = ampm === "am" ? "pm" : "am";
        if (date && this.props.isValidDate && typeof this.props.isValidDate == "function") {
          const hours = this.getHours(ampm, this.state.hours);
          if (!this.props.isValidDate(date.hours(hours), true)) {
            this.props.handleNotification(
              "fail",
              "You can only select a time that is 15 mins from now"
            );
            return;
          }
        }
        this.setState(
          {
            ampm,
          },
          this.cbamAM
        );
      }
    }
  }

  cbHours = () => {
    // this.props.setTime("hours", parseInt(this.state.hours, 10));
    if (this.state.hours !== 12)
      this.props.setTime(
        "hours",
        parseInt(
          this.state.ampm.toLowerCase() === "pm" ? this.state.hours + 12 : this.state.hours,
          10
        )
      );
    else
      this.props.setTime(
        "hours",
        parseInt(
          this.state.ampm.toLowerCase() === "am" ? this.state.hours - 12 : this.state.hours,
          10
        )
      );
  };

  cbMinutes = () => {
    this.props.setTime("minutes", parseInt(this.state.minutes, 10));
  };

  cbamAM = () => {
    if (this.state.hours !== 12)
      this.props.setTime(
        "hours",
        parseInt(
          this.state.ampm.toLowerCase() === "pm" ? this.state.hours + 12 : this.state.hours,
          10
        )
      );
    else
      this.props.setTime(
        "hours",
        parseInt(
          this.state.ampm.toLowerCase() === "am" ? this.state.hours - 12 : this.state.hours,
          10
        )
      );
  };

  manuallyChange(type, { target: { value } }) {
    // if (type === "hours" && value === "0") return;
    if (isNaN(parseInt(value, 10))) return;
    if (type === "hours" && parseInt(value, 10) > 24) return;
    if (type === "minutes" && parseInt(value, 10) > 59) return;
    let update = {};
    update[type] = !value ? "" : parseInt(value, 10);
    const date = this.props.selectedDate ? this.props.selectedDate.clone() : "";
    if (date && this.props.isValidDate && typeof this.props.isValidDate == "function") {
      if (type == "minutes") {
        if (!this.props.isValidDate(date.minutes(update.minutes), true)) return;
      }
      if (type == "hours") {
        const hours = this.getHours(this.state.ampm, update.hours);
        if (!this.props.isValidDate(date.hours(hours), true)) return;
      }
    }
    this.setState(update);
    this.props.setTime(type, parseInt(value, 10));
  }

  toggleDayPart() {
    let hours = parseInt(this.state.hours, 10);

    if (hours >= 12) {
      hours -= 12;
    } else {
      hours += 12;
    }

    this.props.setTime("hours", hours);
  }

  increase(type) {
    const tc = this.constraints[type];
    let value = parseInt(this.state[type], 10) + tc.step;
    if (value > tc.max) value = tc.min + (value - (tc.max + 1));
    return pad(type, value);
  }

  decrease(type) {
    const tc = this.constraints[type];
    let value = parseInt(this.state[type], 10) - tc.step;
    if (value < tc.min) value = tc.max + 1 - (tc.min - value);
    return pad(type, value);
  }

  getCounters() {
    let counters = [];
    let format = this.props.timeFormat;

    if (format.toLowerCase().indexOf("h") !== -1) {
      counters.push("hours");
      if (format.indexOf("m") !== -1) {
        counters.push("minutes");
        if (format.indexOf("s") !== -1) {
          counters.push("seconds");
          if (format.indexOf("S") !== -1) {
            counters.push("milliseconds");
          }
        }
      }
    }

    if (this.isAMPM()) {
      counters.push("ampm");
    }

    return counters;
  }

  isAMPM() {
    return this.props.timeFormat.toLowerCase().indexOf(" a") !== -1;
  }

  getTimeParts(date) {
    return {
      hours: date.hours() > 12 ? date.hours() - 12 : date.hours(),
      minutes: date.minutes(),
      ampm: date.hours() >= 12 ? "PM" : "AM",
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.selectedDate && nextProps.selectedDate) {
      if (this.props.selectedDate != nextProps.selectedDate) {
        this.setState(this.getTimeParts(nextProps.selectedDate));
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedDate && this.default) {
      this.setState(this.getTimeParts(this.props.selectedDate));
      this.default = false;
    }
    // else if (prevProps.viewDate !== this.props.viewDate) {
    // 		this.setState(this.getTimeParts(this.props.viewDate));
    // }
  }

  componentDidMount() {
    if (!this.props.splittedView) {
      document.getElementById("hours").focus();
    }
  }
}

function pad(type, value) {
  const padValues = {
    hours: 1,
    minutes: 2,
    seconds: 2,
    milliseconds: 3,
  };

  let str = value + "";
  while (str.length < padValues[type]) str = "0" + str;
  return str;
}
