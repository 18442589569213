/**
 * @flow
 * @relayHash cb15a89c3d1416bef7db5642e1b26626
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type updateFairHousingActInput = {|
  orgId: string,
  title: string,
  message?: ?string,
|};
export type updateFairHousingActMutationVariables = {|
  input: updateFairHousingActInput
|};
export type updateFairHousingActMutationResponse = {|
  +updateFairHousingAct: ?{|
    +fairHousingAct: ?any
  |}
|};
export type updateFairHousingActMutation = {|
  variables: updateFairHousingActMutationVariables,
  response: updateFairHousingActMutationResponse,
|};
*/


/*
mutation updateFairHousingActMutation(
  $input: updateFairHousingActInput!
) {
  updateFairHousingAct(input: $input) {
    fairHousingAct
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "updateFairHousingActInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateFairHousingAct",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateFairHousingActPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "fairHousingAct",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "updateFairHousingActMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "updateFairHousingActMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "updateFairHousingActMutation",
    "id": null,
    "text": "mutation updateFairHousingActMutation(\n  $input: updateFairHousingActInput!\n) {\n  updateFairHousingAct(input: $input) {\n    fairHousingAct\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a57f3b66e288a255bb1442202e959dae';
module.exports = node;
