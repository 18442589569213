import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";
import environment from "environments/environment.js";

const mutation = graphql`
  mutation testEblastMutation($input: testEmailInput!) {
    testEmail(input: $input) {
      hyblast {
        id
        name
        editorStatus
        approvalStatus
        mmsStatus
      }
    }
  }
`;

const commit = (input, callback) => {
  return commitMutation(environment, {
    mutation,
    variables: { input },
    onCompleted: callback,
    onError: (err) => callback(null, err),
  });
};

export default commit;
