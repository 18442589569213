import graphql from "babel-plugin-relay/macro";

export default graphql`
  query getSelectedSuborgDetailQuery($orgId: ID!, $propertyIds: [String!]) {
    authorizedSuborgs(orgId: $orgId, propertyIds: $propertyIds) {
      id
      name
      angels {
        id
        name
        active
        createdAt
        lastActivatedAt
        lastDeactivatedAt
        thenEmails {
          id
          name
          active
          createdAt
          triggerDays
          originalThumbnailUrl
          whenGroupName
          tourType
        }
      }
    }
  }
`;
