import React from "react";

export default function EmailForwardedIcon({
  className = "text-[#999999]",
  width = 18,
  height = 18,
}) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.125 13.875H4.125C3.711 13.875 3.375 13.539 3.375 13.125V4.125C3.375 3.711 3.711 3.375 4.125 3.375H13.125C13.539 3.375 13.875 3.711 13.875 4.125V7.125"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.8125 11.8125L12.1875 9.5625V11.0749C10.7696 11.0749 9.1875 11.625 9.1875 14.0625C9.92587 12.867 11.0625 12.5625 12.1875 12.5625V14.0625L14.8125 11.8125Z"
        stroke="currentColor"
        strokeWidth="0.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
