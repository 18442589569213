/**
 * @flow
 * @relayHash e1a8703fc31603125d4ca328226bf1ea
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type hayleyCustomFormQuestionInput = {|
  propertyId: string,
  customQuestions: string,
  clientMutationId?: ?string,
|};
export type hayleyCustomFormQuestionMutationVariables = {|
  input: hayleyCustomFormQuestionInput
|};
export type hayleyCustomFormQuestionMutationResponse = {|
  +hayleyCustomFormQuestion: ?{|
    +hayleyFormQuestions: ?string
  |}
|};
export type hayleyCustomFormQuestionMutation = {|
  variables: hayleyCustomFormQuestionMutationVariables,
  response: hayleyCustomFormQuestionMutationResponse,
|};
*/


/*
mutation hayleyCustomFormQuestionMutation(
  $input: hayleyCustomFormQuestionInput!
) {
  hayleyCustomFormQuestion(input: $input) {
    hayleyFormQuestions
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "hayleyCustomFormQuestionInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "hayleyCustomFormQuestion",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "hayleyCustomFormQuestionPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "hayleyFormQuestions",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "hayleyCustomFormQuestionMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "hayleyCustomFormQuestionMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "hayleyCustomFormQuestionMutation",
    "id": null,
    "text": "mutation hayleyCustomFormQuestionMutation(\n  $input: hayleyCustomFormQuestionInput!\n) {\n  hayleyCustomFormQuestion(input: $input) {\n    hayleyFormQuestions\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '65ec6c8867f9a90876de52e539bd4784';
module.exports = node;
