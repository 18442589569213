import { fetchQuery } from "relay-runtime";
import environment from "environments/environment";
import getCampaignsQuery from "./queries/getCampaigns";
import getCampaignQuery from "./queries/getCampaign";
import getOmniChannelPreviewsQuery from "./queries/getOmichannelPreviews";
import getEnrollmentQuery from "./queries/getEnrollment";
import getCampaignUtmCodeQuery from "./queries/getCampaignUtmCode";
import getHyreferralCampaignsOverviewQuery from "./queries/getHyreferralCampaignsOverview";
import getHyreferralCampaignsPropertiesBreakdownQuery from "./queries/getHyreferralCampaignsPropertiesBreakdown";
import getContactListQuery from "./queries/getContactList";
import getActivateHyreferralCampaignQuery from "./queries/getActivateHyreferralCampaign";
import hyrafSettingQuery from "./queries/hyrafSetting";
import checkEnrollmentOfHyreferralCampaignQuery from "./queries/checkEnrollmentOfHyreferralCampaign";

const getCampaign = (id) => {
  let variables = { id };
  return fetchQuery(environment, getCampaignQuery, variables)
    .then((res) => {
      return res.hyreferralCampaign;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

const getCampaigns = (orgId, propertyId, search, after, first, status) => {
  let variables = { orgId, propertyId, search, after, first, status };
  return fetchQuery(environment, getCampaignsQuery, variables)
    .then((res) => {
      let list = [...(res.hyreferralCampaigns ? res.hyreferralCampaigns.edges : [])];
      let sorted = list.sort(
        (a, b) => new Date(b.node.createdAt).getTime() - new Date(a.node.createdAt).getTime()
      );
      return { sorted, pageInfo: res.hyreferralCampaigns.pageInfo };
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

const getOmniChannelPreviews = (id) => {
  let variables = { id };
  return fetchQuery(environment, getOmniChannelPreviewsQuery, variables)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};
const checkEnrollmentOfHyreferralCampaign = (id, email) => {
  let variables = { id, email };
  return fetchQuery(environment, checkEnrollmentOfHyreferralCampaignQuery, variables)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

const getEnrollment = (enrollmentId) => {
  let variables = { enrollmentId };
  return fetchQuery(environment, getEnrollmentQuery, variables)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

const getCampaignUtmCodeUrls = (id) => {
  let variables = { id };
  return fetchQuery(environment, getCampaignUtmCodeQuery, variables)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

const getHyreferralCampaignsPropertiesBreakdown = (
  orgId,
  propertyIds,
  campaignIds,
  startAt,
  endAt,
  status = "active"
) => {
  let variables = { orgId, propertyIds, campaignIds, startAt, endAt };
  if (status) variables["status"] = status;
  return fetchQuery(environment, getHyreferralCampaignsPropertiesBreakdownQuery, variables)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

const getHyreferralCampaignsOverview = (orgId, propertyId, startAt, endAt) => {
  let variables = { orgId, propertyId, startAt, endAt };
  return fetchQuery(environment, getHyreferralCampaignsOverviewQuery, variables)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

const getContactList = (orgId, campaignIds, type, referred, leased) => {
  let variables = { orgId, campaignIds, type, referred, leased };
  return fetchQuery(environment, getContactListQuery, variables)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

const getActivateHyreferralCampaign = (propertyId) => {
  let variables = { propertyId };
  return fetchQuery(environment, getActivateHyreferralCampaignQuery, variables)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

const hyrafSetting = (orgId, propertyId) => {
  let variables = { orgId, propertyId };
  return fetchQuery(environment, hyrafSettingQuery, variables)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

export {
  getCampaigns,
  getCampaign,
  getOmniChannelPreviews,
  getCampaignUtmCodeUrls,
  getHyreferralCampaignsOverview,
  getHyreferralCampaignsPropertiesBreakdown,
  getContactList,
  getActivateHyreferralCampaign,
  getEnrollment,
  hyrafSetting,
  checkEnrollmentOfHyreferralCampaign,
};
