import graphql from "babel-plugin-relay/macro";

export default graphql`
  query getCampaignListQuery(
    $dashboardId: ID!
    $selectedPropertyIds: [String!]
    $startAt: String
    $endAt: String
    $drip: Boolean
    $type: String
  ) {
    campaignList(
      dashboardId: $dashboardId
      selectedPropertyIds: $selectedPropertyIds
      startAt: $startAt
      endAt: $endAt
      drip: $drip
      type: $type
    ) {
      name
      propertyName
      propertyId
      createdAt
      id
      sentTime
    }
  }
`;
