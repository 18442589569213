import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

const mutation = graphql`
    mutation saveReportMutation($input: saveReportInput!){
        saveReport(input:$input) {
          clientMutationId
          status
        }
    }`;


function commit(environment, input) {
  return new Promise((res, rej) => {
    commitMutation(environment, {
      mutation,
      variables: { input },
      updater: (store) => {

      },
      onCompleted: res,
      onError: rej
    })
  });
}

export default commit;