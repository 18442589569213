import { fetchQuery } from "relay-runtime";

import environment from "environments/environment";

import hayleyDefaultQuery from "./getHayleyDefault";
import getSelfGuidedTourQuery from "./getSelfGuidedTour";
import getHayleyCustomizationQuery from "./getHayleyCustomization";
import mascotQuery from "./mascot";
import mascotDefaultColorsQuery from "./mascotDefaultColors";
import getHayleyUrlQuery from "./getHayleyUrl";
import getHayleyQuestionQuery from "./getHayleyQuestion";
import getHayleyContactsDashboardQuery from "./getHayleyContactsDashboard";
import getHayleyQualificationQuery from "./getHayleyQualification";

export const getSelfGuidedTour = (params) => {
  return fetchQuery(environment, getSelfGuidedTourQuery, params);
};

export const getHayleyCustomization = (params) => {
  return fetchQuery(environment, getHayleyCustomizationQuery, params);
};

export const mascot = (params) => {
  return fetchQuery(environment, mascotQuery, params);
};

export const mascotDefaultColors = (params) => {
  return fetchQuery(environment, mascotDefaultColorsQuery, params);
};

export const getHayleyUrl = (params) => {
  return fetchQuery(environment, getHayleyUrlQuery, params);
};

export const getHayleyQuestion = (params) => {
  return fetchQuery(environment, getHayleyQuestionQuery, params);
};

export const getHayleyDefault = (params) => {
  return fetchQuery(environment, hayleyDefaultQuery, params);
};

export const getHayleyContactsDashboard = (params) => {
  return fetchQuery(environment, getHayleyContactsDashboardQuery, params);
};

export const getHayleyQualification = (params) => {
  return fetchQuery(environment, getHayleyQualificationQuery, params);
};
