import { fetchQuery } from "relay-runtime";
import environment from "environments/environment";

import listPropertyCorpusFaqs from "./listPropertyCorpusFaqs";
import retrieveFaqItemQuery from "./retrieveFaqItem";
import hayleyFaqEnabledQuery from "./hayleyFaqEnabled";
import retrieveFaqItemsQuery from "./retrieveFaqItems";
import retrieveAutofillFaqsByJobIdQuery from "./retrieveAutofillFaqsByJobId";

const FaqsListPropertyCorpus = (propertyId) => {
  let variables = { propertyId };
  return fetchQuery(environment, listPropertyCorpusFaqs, variables)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

const retrieveFaqItem = (propertyId, questionId, itemType) => {
  let variables = { propertyId, questionId, itemType };
  return fetchQuery(environment, retrieveFaqItemQuery, variables)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

const hayleyFaqEnabled = (tenantId) => {
  let variables = { tenantId };
  return fetchQuery(environment, hayleyFaqEnabledQuery, variables)
    .then((res) => {
      return res?.hayleyFaqEnabled?.[0]?.active || false;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

const retrieveFaqItems = (jobId) => {
  let variables = { jobId };
  return fetchQuery(environment, retrieveFaqItemsQuery, variables)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

const retrieveAutofillFaqsByJobId = (jobId) => {
  let variables = { jobId };
  return fetchQuery(environment, retrieveAutofillFaqsByJobIdQuery, variables)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

export {
  FaqsListPropertyCorpus,
  retrieveFaqItem,
  hayleyFaqEnabled,
  retrieveFaqItems,
  retrieveAutofillFaqsByJobId,
};
