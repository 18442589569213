import React from "react";
import { SvgIcon } from "@mui/material";

export default function SocialIcon(props) {
  return (
    <svg
      className={props.className}
      width={props.width}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" fill="white" />
      <path
        d="M11.9997 18C13.4198 18 14.5711 15.3137 14.5711 12C14.5711 8.68629 13.4198 6 11.9997 6C10.5795 6 9.42822 8.68629 9.42822 12C9.42822 15.3137 10.5795 18 11.9997 18Z"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path d="M6 12H18" stroke="currentColor" stroke-linecap="round" strokeLinejoin="round" />
      <path
        d="M12 18C15.3137 18 18 15.3137 18 12C18 8.68629 15.3137 6 12 6C8.68629 6 6 8.68629 6 12C6 15.3137 8.68629 18 12 18Z"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="currentColor" />
    </svg>
  );
}
