import React from "react";

function CheckDoneIcon({ className = "text-[#333]", width = 24, height = 24, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width
      height
      fill="none"
      viewBox="0 0 16 14"
      className
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M.5 8l2-2 3 3 8-8 2 2-10 10-5-5z"
      ></path>
    </svg>
  );
}

export default CheckDoneIcon;
