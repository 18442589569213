import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { setShowFav, setHideFav, setDashboardType, resetDashboardType } from "redux/slices";

const useShowFavorite = (dashboardType = "hydrip_system_dashboard") => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setShowFav());
    dispatch(setDashboardType(dashboardType));

    return () => {
      dispatch(setHideFav());
      dispatch(resetDashboardType());
    };
  }, []);
};

export default useShowFavorite;
