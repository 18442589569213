import graphql from "babel-plugin-relay/macro";

export default graphql`
  query getActivateHyreferralCampaignQuery($propertyId: ID!) {
    activateHyreferralCampaign(propertyId: $propertyId) {
      campaignId
      name
    }
  }
`;
