import { TableCell } from "@mui/material";

import withStyles from "@mui/styles/withStyles";

const StyledTableCell = withStyles({
  head: {
    backgroundColor: "#fff",
    color: "rgba(51, 51, 51, 0.75)",
    fontWeight: 600,
    padding: "14px 16px",
    fontSize: 14,
    borderBottom: "1px solid rgba(218, 218, 218, 1)",
  },
  body: {
    color: "#333",
    fontWeight: 500,
    padding: "22px 16px",
    fontSize: 14,
    borderBottom: "1px solid rgba(218, 218, 218, 1)",
  },
})(TableCell);

export default StyledTableCell;
