import React from "react";
import withStyles from "@mui/styles/withStyles";
import Button from "storybookComponents/Button";
import Modal from "../components/modal";
import Field from "../components/textField";
import { DotIcon, TickIcon, CloseIconRed } from "../icons";
import { Auth } from "aws-amplify";
import { regEx } from "../common";

const styles = () => ({
  button: {
    height: 40,
    padding: "10px 24px",
    fontSize: 14,
    lineHeight: "20px",
    fontWeight: 600,
    marginLeft: 12,
    textTransform: "none",
  },
  bodyContainer: {
    padding: "4px 0px 16px 0px",
  },
  helperText: {
    fontSize: 12,
    lineHeight: "24px",
    color: "rgba(51, 51, 51, 0.75)",
    marginRight: 12,
  },
  passwordMatchHelper: {
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "20px",
    marginLeft: 8,
    color: "#E75F5F",
  },
});

const HelperText = ({ classes, text, validated }) => {
  return (
    <span className={classes.helperText}>
      {validated ? <TickIcon /> : <DotIcon />}
      {text}
    </span>
  );
};

const ConfirmPassword = ({ classes, handleToggle, open, email, showNotification }) => {
  const [active, setActive] = React.useState("active");
  const [values, setValues] = React.useState({
    email: email,
    currentPassword: "",
    password: "",
    confirmPassword: "",
  });

  const handleChangePassword = async () => {
    setActive("inactive");
    await Auth.currentAuthenticatedUser()
      .then((user) => {
        return Auth.changePassword(user, values.currentPassword, values.password);
      })
      .then((data) => {
        showNotification("Your password is updated successfully.");
        handleToggle();
      })
      .catch((err) => {
        console.log("err", err);
        showNotification((err && err.message) || "Something went wrong.", "error");
      });
    setActive("active");
  };

  const handleChangeValue = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const getHelperText = () => {
    return (
      <div>
        <HelperText
          classes={classes}
          validated={values.password.length >= 12}
          text="Min. 12 char"
        />
        <HelperText
          classes={classes}
          validated={values.password.match(regEx)}
          text="Alphanumeric"
        />
      </div>
    );
  };

  const getHelperConfirmText = () => {
    if (values.password === "" || values.confirmPassword === "") {
      return null;
    } else if (values.password != values.confirmPassword) {
      return (
        <div>
          <CloseIconRed />{" "}
          <span className={classes.passwordMatchHelper}>
            Make sure your new passwords are matching
          </span>
        </div>
      );
    }
    return null;
  };

  const getButtonState = () => {
    let lengthValid = values.password.length >= 12;
    let patternValue = values.password.match(regEx);
    return values.password === "" ||
      values.password != values.confirmPassword ||
      !lengthValid ||
      !patternValue ||
      values.currentPassword === ""
      ? "inactive"
      : active;
  };

  return (
    <Modal
      open={open}
      title="Change Password"
      handleClose={handleToggle}
      actionChildren={
        <>
          <Button onClick={handleToggle} variant="tertiary" className={classes.button}>
            Cancel
          </Button>
          <Button
            state={getButtonState()}
            className={classes.button}
            onClick={handleChangePassword}
          >
            Update
          </Button>
        </>
      }
    >
      <div className={classes.bodyContainer}>
        <Field
          label="Email"
          value={values.email}
          disabled
          name="email"
          onChange={handleChangeValue}
          fullWidth
        />
        <Field
          label="Current Password"
          value={values.currentPassword}
          name="currentPassword"
          type="password"
          onChange={handleChangeValue}
          fullWidth
        />
        <Field
          label="New Password"
          value={values.password}
          name="password"
          type="password"
          onChange={handleChangeValue}
          helperText={getHelperText()}
          fullWidth
        />
        <Field
          label="Confirm Password"
          helperText={getHelperConfirmText()}
          value={values.confirmPassword}
          name="confirmPassword"
          type="password"
          onChange={handleChangeValue}
          fullWidth
        />
      </div>
    </Modal>
  );
};

export default withStyles(styles)(ConfirmPassword);
