/* eslint-disable import/no-anonymous-default-export */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import NavDrawer from "storybookComponents/NavDrawer";
import * as path from "constants/path";
import _ from "lodash";
import {
  setProperty,
  getUserRole,
  parsePath,
  getDefaultPathOfTheApp,
  getApprovalTablesTabsDetails,
  checkIfAccessOfApprovalProcess,
  getOrgLevel,
  premiumApps,
  globalTohyMail,
  getAccessDetails,
  jaiSwitcherData,
  checkIfAccess,
} from "common";
import environment from "environments/environment";
import { useCustomEventListener } from "react-custom-events";
import { getSelectedSuborgDetails, getProfile } from "relay/common";
import Content from "./defaultRoute";
import "bootstrap/dist/css/bootstrap.min.css";
import "./icons/style.css";
import { getNavDrawerMenu } from "./navDrawerMenu";
import CacheBuster from "./CacheBuster";
import { getTimeZoneString } from "./data/timezoneList";
import { _getApps } from "components/AppSwitcher/apis";
import { initLoad } from "redux/slices/raf-slice";
import { initBlastLoad } from "redux/slices/blast-slice";
import { initLoadEvents } from "redux/slices/event-slice";
import { setRegionalMList } from "redux/slices/accountListSlice";
import {
  setStates4User,
  setSuborgDetails,
  setDripsMenu,
  getSuborgDetails,
  setAlertsMenu,
  getDripsMenu,
  getAlertsMenu,
  getToursMenu,
  setToursMenu,
  getStates4OrgSuborg,
  getIsHayleyFaqEnabled,
  setIsHayleyFaqEnabled,
  setDripsUploadAccess,
  setBlastsUploadAccess,
} from "redux/slices";
import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "./styles";
import { getUserEmail } from "common";
import * as services from "services/dripsMenuServices";
import * as alertServices from "services/alertsMenuServices";
import { getProductItems } from "relay";
import { hyTourApps } from "content/hyTours/utils";
import { useSnackbar } from "notistack";
import { hayleyFaqEnabled } from "relay/FAQs/queries";
import { ProductFruits } from "react-product-fruits";
import { getAccountsList } from "relay/main";

const accountsLinkAI = process.env.REACT_APP_ACCOUNTS_LINK_AI;

function Application({ appSwitcher, user, userName, userEmail, subOrgData, ...rest }) {
  const classes = useStyles();
  const subOrgDetails = useSelector(getSuborgDetails);

  const email = getUserEmail();

  const userInfo = {
    username: email || "",
    email: email || "",
  };

  const { enqueueSnackbar } = useSnackbar();

  const orgDetails = React.useRef(null);
  const [showOrgPropertySwitchConfirmModal, setShowOrgPropertySwitchConfirmModal] = useState(false);
  const [selectedNavItem4Replace, setSelectedNavItem4Replace] = useState(null);
  const [menuType, setMenuType] = React.useState(null);
  const [orgLevel, setOrgLevel] = React.useState(getOrgLevel());
  const [menuItems, setMenuItems] = React.useState();
  const [apps, setApps] = React.useState([]);
  const [orgName, setOrgName] = React.useState("");
  const [authorizedDetails, setAuthorizedDetails] = React.useState();
  const [appsLoading, setLoading] = React.useState(true);
  const [hyeventsAccessApproval, setHyeventAccessApproval] = React.useState(true);
  const [hyblastsAccessApproval, setBlastsAccessApproval] = React.useState(true);
  const [approvalSubmenuTabs, setApprovalSubmenuTabs] = React.useState();
  const [isRentOrg, setIsRentOrg] = React.useState(null);
  const [appDetails, setAppDetails] = React.useState(null);
  const dispatch = useDispatch();
  const rentOrgNameCheck = React.useRef(null);

  const dripsMenu = useSelector(getDripsMenu);
  const alertsMenu = useSelector(getAlertsMenu);
  const toursMenu = useSelector(getToursMenu);
  const allOrgs = useSelector(getStates4OrgSuborg);
  const isHayleyFaqEnabled = useSelector(getIsHayleyFaqEnabled);
  const _userRole = getUserRole();
  //sets the details of the org-suborg user selected
  const setData = async (property, approvalSubmenuTabs, initialCall, rentOrgCheck, orgName) => {
    let timezoneString = getTimeZoneString(property.timezone);
    dispatch(setSuborgDetails({ ...property, timezoneString }));
    setProperty(property, true);
    setOrgLevel(property.orgLevel);
    await getApps(
      property.org.id,
      property.id,
      property.orgLevel,
      approvalSubmenuTabs,
      initialCall,
      rentOrgCheck,
      orgName
    );
  };

  const getModulesDetailsForAuthorization = (apps, suborgId, orgLevel, rentOrgCheck, orgName) => {
    let journeyAIModules = [];
    for (let i = 0; i < apps.length; i++) {
      /** Add module for hayley */
      if (
        apps[i].name === "HayleyChat" ||
        apps[i].name === "Hayley" ||
        apps[i].name.includes("Hayley")
      ) {
        journeyAIModules.push({
          id: `journeyAIModules_Hayley`,
          name: "HayleyChat",
          title: "Hayley",
          customName: apps[i].customName,
          isAccessibleToUser: apps[i].access !== "none",
          premium: false,
          journeyAIModules: true,
          url: process.env.REACT_APP_hostName + path.getAppPath(suborgId) + "/hayley/mascot",
          authorized: apps[i].authorized,
          isRentOrg: rentOrgCheck,
          hide: apps[i].access === "none" && !apps[i].authorized && rentOrgCheck,
        });
      }

      if (premiumApps.includes(apps[i].name)) {
        const { name, url, orgLevelUrl } = globalTohyMail[apps[i].name];
        journeyAIModules.push({
          id: `journeyAIModules_${name}`,
          name: name === "Raf" ? "Refer-a-Friend" : name,
          title: name,
          isAccessibleToUser: apps[i].access !== "none",
          premium: !apps[i].authorized,
          journeyAIModules: true,
          url:
            process.env.REACT_APP_hostName +
            path.getAppPath(suborgId) +
            `/${orgLevel ? orgLevelUrl : url}`,
        });

        //If an org/suborg is using hyBlast then they are eligible to use Success center Blast automatically
        //It may change later when we will add success center drips
        // if (name === "Blasts" || name === "Drips") {
        //   journeyAIModules.push({
        //     id: `journeyAIModules_Success Center`,
        //     name: `Success Center ${name}`,
        //     title: `Success Center ${name}`,
        //     isAccessibleToUser: apps[i].access !== "none",
        //     premium: !apps[i].authorized,
        //     journeyAIModules: true,
        //     url:
        //       process.env.REACT_APP_hostName +
        //       path.getAppPath(suborgId) +
        //       "/success-center-blast/analysis",
        //     hide: true,
        //   });
        // }
      }
    }

    //If an org/suborg is using hyBlast then they are eligible to use Success center Blast automatically
    //It may change later when we will add success center drips
    let blastAccessType = getAccessDetails("Blasts", journeyAIModules);
    let dripsAccessType = getAccessDetails("Drips", journeyAIModules);
    let bothPremiumOrInaccessible =
      (blastAccessType === "premium" || dripsAccessType === "premium") &&
      (blastAccessType === "unauthorised" || dripsAccessType === "unauthorised");
    journeyAIModules.push({
      id: `journeyAIModules_Success Center`,
      name: "Success Center",
      title: "Success Center",
      isAccessibleToUser: !(
        blastAccessType === "unauthorised" && dripsAccessType === "unauthorised"
      ),
      premium:
        (blastAccessType === "premium" && dripsAccessType === "premium") ||
        bothPremiumOrInaccessible,
      journeyAIModules: true,
      url:
        process.env.REACT_APP_hostName +
        path.getAppPath(suborgId) +
        `${
          blastAccessType !== "authorised"
            ? "/success-center-drips/by-property"
            : "/success-center-blast/analysis"
        }`,
    });

    // Show J.Des ones to the specific people
    const userEmail = getUserEmail();

    if (
      process.env.NODE_ENV === "development" ||
      [
        "cxp@hy.ly",
        "vip@hy.ly",
        "marketing@hy.ly",
        "csm@hy.ly",
        "tn+template@hy.ly",
        "sales@hy.ly",
      ].includes(userEmail)
    ) {
      journeyAIModules.push({
        id: `journeyAIModules_Journey_Freehand`,
        name: "Journey Freehand",
        title: "Journey Freehand",
        isAccessibleToUser: true, //apps[i].access !== "none",
        premium: false, //!apps[i].authorized,
        journeyAIModules: true,
        url: process.env.REACT_APP_hostName + path.getAppPath(suborgId) + "/journey-freehand",
      });

      /** Add the module for journey designer */
      journeyAIModules.push({
        id: `journeyAIModules_Journey_Designer`,
        name: "Journey Designer",
        title: "Journey Designer",
        isAccessibleToUser: true, //apps[i].access !== "none",
        premium: false, //!apps[i].authorized,
        journeyAIModules: true,
        url: process.env.REACT_APP_hostName + path.getAppPath(suborgId) + "/jai",
      });
      // if (
      //   process.env.NODE_ENV === "development" ||
      //   VALID_USERS_FOR_AI_WORKSHOP.includes(userEmail)
      // ) {
      //   /** Add module for AI Workshop */
      //   journeyAIModules.push({
      //     id: `journeyAIModules_AI_Workshop`,
      //     name: "AI Workshop",
      //     title: "AI Workshop",
      //     isAccessibleToUser: true, //apps[i].access !== "none",
      //     premium: false, //!apps[i].authorized,
      //     journeyAIModules: true,
      //     url:
      //       process.env.REACT_APP_hostName +
      //       path.getAppPath(suborgId) +
      //       "/ai-workshop/feeders-playground",
      //   });
      // }
    }
    if (rentOrgCheck && orgName !== "Rent_Demo") {
      return journeyAIModules.filter(
        (d) =>
          d.name === "Tours" ||
          d.name === "HayleyChat" ||
          (d.name === "Drips" && !d.premium) ||
          (d.name === "Alerts" && !d.premium)
      );
    }

    let journeyAIModulesToShow = [];

    for (let i = 0; i < jaiSwitcherData.length; i++) {
      const module = journeyAIModules.find((item) => item.name === jaiSwitcherData[i]);
      if (module !== undefined) {
        journeyAIModulesToShow.push(module);
      }
    }

    return journeyAIModulesToShow;
  };
  // fetch all the apps user is authorized to access for selected org and suborg
  const getApps = async (
    orgId,
    suborgId,
    orgLevel,
    approvalSubmenuTabs,
    initialCall,
    rentOrgCheck,
    orgName
  ) => {
    try {
      // setLoading(true);
      let response = await _getApps(orgId, orgLevel ? null : suborgId);
      if (response.length > 1) {
        setAppDetails(response);
        //access for the Approval Process tab under settings
        let hyeventsAccessApproval = checkIfAccessOfApprovalProcess("Events", _userRole, response);
        let hyblastsAccessApproval = checkIfAccessOfApprovalProcess("Blasts", _userRole, response);
        setHyeventAccessApproval(hyeventsAccessApproval);
        setBlastsAccessApproval(hyblastsAccessApproval);
        let blastsUploadAccess = checkIfAccess("Blasts", response);
        let dripsUploadAccess = checkIfAccess("Drips", response);
        dispatch(setDripsUploadAccess(dripsUploadAccess));
        dispatch(setBlastsUploadAccess(blastsUploadAccess));
        let authorizedDetails = getModulesDetailsForAuthorization(
          response,
          suborgId,
          orgLevel,
          rentOrgCheck,
          orgName
        );

        setAuthorizedDetails(authorizedDetails);
        setApps([...response, ...authorizedDetails]);

        let dripsAccessType = getAccessDetails("Drips", authorizedDetails);
        let dripsMenu = [];
        let alertsAccessType = getAccessDetails("Alerts", authorizedDetails);
        let alertsMenu = [];
        let toursMenu = [];
        if (alertsAccessType === "authorised" && !orgLevel) {
          alertsMenu = await alertServices.getAlertsMenu(suborgId, "alerts");
        }
        let toursAccessType = getAccessDetails("Tours", authorizedDetails);
        if (toursAccessType === "authorised") {
          let resp = await getProductItems(suborgId, "Tours");
          hyTourApps.forEach((app) => {
            const product = resp.productItems.find((product) => product.name === app.name);
            if (product) {
              toursMenu.push({
                ...app,
                mount: product.mount,
              });
            }
          });
        }
        dispatch(setAlertsMenu(alertsMenu));
        dispatch(setToursMenu(toursMenu));
        if (dripsAccessType === "authorised" && !orgLevel) {
          dripsMenu = await services.getDripsMenu(suborgId);
        }
        const trimmedTitle = dripsMenu.map((item) => ({
          ...item,
          title: item.title.replace(/\s+/g, " ").trim(),
        }));
        dispatch(setDripsMenu(trimmedTitle));
        const data = await getNavDrawerMenu(
          menuType,
          path.getAppPath(orgLevel ? orgId : suborgId),
          menuType === "hyEvents"
            ? approvalSubmenuTabs.events === "approver"
            : approvalSubmenuTabs.blasts === "approver",
          orgLevel,
          authorizedDetails,
          orgName,
          menuType === "hyEvents" ? hyeventsAccessApproval : hyblastsAccessApproval,
          toursMenu,
          null,
          dripsMenu,
          alertsMenu,
          rentOrgCheck,
          response,
          orgDetails.current,
          isHayleyFaqEnabled
        );

        setMenuItems(data);

        if (!orgLevel && data.length > 3) {
          let dripsUrl = process.env.REACT_APP_hostName + data[2].to;
          let alertsUrl = process.env.REACT_APP_hostName + data[3].to;
          authorizedDetails = authorizedDetails.map((module) => {
            if (module.name === "Drips") return { ...module, url: dripsUrl };
            if (module.name === "Alerts") return { ...module, url: alertsUrl };
            return module;
          });
        }
        setAuthorizedDetails(authorizedDetails);
        setApps([...response, ...authorizedDetails]);
        rest.history.push({
          pathname: getDefaultPathOfTheApp(
            data,
            window.location.pathname,
            suborgId,
            orgLevel,
            initialCall,
            orgId
          ),
          search: rest.history.location.search,
        });
      } else {
        setApps([]);
      }
      let accountsListResponse = await getAccountsList(orgId, null);
      if (accountsListResponse) {
        const userList = accountsListResponse.accounts.edges;
        let regionalMList = userList.filter(
          (item) => item.node.role.name === "Regional Manager & SVP"
        );
        dispatch(setRegionalMList(regionalMList));
      }
      setLoading(false);
    } catch (err) {
      console.log("Err", err);
    }
  };

  const showNotification = (message, type, unDoAvailable, unDoCallback) => {
    enqueueSnackbar(
      {
        message: message,
        type: type,
        open: true,
        unDoAvailable: unDoAvailable,
        unDoCallback: unDoCallback,
      },
      {
        autoHideDuration: 3000,
      }
    );
  };

  const fetchOrgSuborgData = async (subOrgDetails, initialCall) => {
    try {
      if (Object.keys(subOrgDetails).length === 0) {
        setLoading(false);
        return;
      }
      if (!subOrgDetails.id) {
        setLoading(false);
        showNotification(`${subOrgDetails?.org?.name} does not contain any properties`, "error");
        return;
      }
      orgDetails.current = subOrgDetails;
      let selectedSuborgDetails = await getSelectedSuborgDetails(
        subOrgDetails.orgLevel ? subOrgDetails.org.id : subOrgDetails.id
      );
      if (
        selectedSuborgDetails &&
        selectedSuborgDetails.authorizedSuborgs &&
        selectedSuborgDetails.authorizedSuborgs[0]
      ) {
        const rentOrgCheck =
          selectedSuborgDetails.authorizedSuborgs[0].org.name.split("_")[0] === "Rent";
        // const zohoChatbot = document.getElementById("zohohc-asap-web-launcherbox");
        const zohoChatbot = document.getElementById("hubspot-messages-iframe-container");

        if (rentOrgCheck) {
          if (zohoChatbot) {
            zohoChatbot.style.setProperty("display", "none", "important");
            // zohoChatbot.style.display = "none";
          }
          document.documentElement.style =
            "--hylyTheme: var(--hylySecondary);--hylyThemeDull: var(--hylySecondaryDull);--hylyThemeBg: var(--hylySecondaryBg);";
        } else {
          if (zohoChatbot) {
            zohoChatbot.style.display = "flex";
          }
          document.documentElement.style =
            "--hylyTheme: var(--hylyPrimary);--hylyThemeDull: var(--hylyPrimaryDull);--hylyThemeBg: var(--hylyPrimaryBg);";
        }
        if (rentOrgCheck) {
          var style = document.createElement("style");
          style.id = "hideProductFruits";
          style.innerHTML = ".productfruits--container { display: none !important; }";
          document.head.appendChild(style);
        } else {
          var styleElement = document.getElementById("hideProductFruits");
          if (styleElement) {
            styleElement.parentNode.removeChild(styleElement);
          }
        }
        setIsRentOrg(rentOrgCheck);
        let settingsDetails = {
          hyblastSetting: selectedSuborgDetails.authorizedSuborgs[0].hyblastSetting,
          hyeventsSetting: selectedSuborgDetails.authorizedSuborgs[0].hyeventsSetting,
          org: {
            hyblastSetting: selectedSuborgDetails.authorizedSuborgs[0].org.hyblastSetting,
            hyeventsSetting: selectedSuborgDetails.authorizedSuborgs[0].org.hyeventsSetting,
          },
        };
        let approvalSubmenuTabs = getApprovalTablesTabsDetails(_userRole, settingsDetails);
        setApprovalSubmenuTabs(approvalSubmenuTabs);
        setOrgName(selectedSuborgDetails.authorizedSuborgs[0].org.name);
        await setData(
          {
            ...subOrgDetails,
            ...selectedSuborgDetails.authorizedSuborgs[0],
            userRole: selectedSuborgDetails.authorizedSuborgs[0].org.userRole,
            userEmail: user.username,
          },
          approvalSubmenuTabs,
          initialCall,
          rentOrgCheck,
          selectedSuborgDetails.authorizedSuborgs[0].org.name
        );
      }
    } catch (err) {
      console.log("Err", err);
    }
  };

  const getProfileDetails = async () => {
    try {
      let response = await getProfile();
      if (response && response.profile) {
        const { email, firstName, initials, lastName, avatarUrl } = response.profile;
        let fullName = `${firstName}${lastName ? " " + lastName : ""}`;
        dispatch(
          setStates4User({
            email,
            initialsOfName: initials,
            avatarUrl: avatarUrl,
            fullName,
          })
        );
      }
    } catch (err) {
      console.log("Err: ", err);
    }
  };

  // React.useEffect(() => {
  //   fetchOrgSuborgData(subOrgDetails, true);
  // }, [1]);

  const checkIsFaqEnabled = async (tenantId) => {
    try {
      let isEnabled = await hayleyFaqEnabled(tenantId);
      dispatch(setIsHayleyFaqEnabled(isEnabled));
    } catch (error) {}
  };

  React.useEffect(() => {
    if (subOrgDetails.id) {
      checkIsFaqEnabled(subOrgDetails.id);
    }
  }, [subOrgDetails.id]);

  const fetchSuborgData = async (selectedSuborgDetails, initialCall) => {
    if (
      selectedSuborgDetails &&
      selectedSuborgDetails.authorizedSuborgs &&
      selectedSuborgDetails.authorizedSuborgs[0]
    ) {
      const rentOrgCheck =
        selectedSuborgDetails.authorizedSuborgs[0].org.name.split("_")[0] === "Rent";
      // const zohoChatbot = document.getElementById("zohohc-asap-web-launcherbox");
      const zohoChatbot = document.getElementById("hubspot-messages-iframe-container");

      if (rentOrgCheck) {
        if (zohoChatbot) {
          zohoChatbot.style.setProperty("display", "none", "important");
          // zohoChatbot.style.display = "none";
        }
        document.documentElement.style =
          "--hylyTheme: var(--hylySecondary);--hylyThemeDull: var(--hylySecondaryDull);--hylyThemeBg: var(--hylySecondaryBg);";
      } else {
        if (zohoChatbot) {
          zohoChatbot.style.display = "flex";
        }
        document.documentElement.style =
          "--hylyTheme: var(--hylyPrimary);--hylyThemeDull: var(--hylyPrimaryDull);--hylyThemeBg: var(--hylyPrimaryBg);";
      }
      if (rentOrgCheck) {
        var style = document.createElement("style");
        style.id = "hideProductFruits";
        style.innerHTML = ".productfruits--container { display: none !important; }";
        document.head.appendChild(style);
      } else {
        var styleElement = document.getElementById("hideProductFruits");
        if (styleElement) {
          styleElement.parentNode.removeChild(styleElement);
        }
      }
      setIsRentOrg(rentOrgCheck);
      let settingsDetails = {
        hyblastSetting: selectedSuborgDetails.authorizedSuborgs[0].hyblastSetting,
        hyeventsSetting: selectedSuborgDetails.authorizedSuborgs[0].hyeventsSetting,
        org: {
          hyblastSetting: selectedSuborgDetails.authorizedSuborgs[0].org.hyblastSetting,
          hyeventsSetting: selectedSuborgDetails.authorizedSuborgs[0].org.hyeventsSetting,
        },
      };
      let approvalSubmenuTabs = getApprovalTablesTabsDetails(_userRole, settingsDetails);
      setApprovalSubmenuTabs(approvalSubmenuTabs);
      setOrgName(selectedSuborgDetails.authorizedSuborgs[0].org.name);
      await setData(
        {
          ...subOrgDetails,
          ...selectedSuborgDetails.authorizedSuborgs[0],
          userRole: selectedSuborgDetails.authorizedSuborgs[0].org.userRole,
          userEmail: user.username,
        },
        approvalSubmenuTabs,
        initialCall,
        rentOrgCheck,
        selectedSuborgDetails.authorizedSuborgs[0].org.name
      );
    }
  };

  React.useEffect(() => {
    fetchSuborgData(subOrgData, true);
  }, [subOrgData]);

  // Change Navdrawer content
  const setOriginNavItems = async (type, ol = false, apps = toursMenu, sms, check = true) => {
    if (type === menuType && ol === orgLevel && check) return;
    // Set org level
    setOrgLevel(ol);
    // Set sidebar menu type
    setMenuType(type);
    const menu = await getNavDrawerMenu(
      type,
      path.getAppPath(orgLevel ? subOrgDetails.org.id : subOrgDetails.id),
      type === "Events"
        ? approvalSubmenuTabs.events === "approver"
        : approvalSubmenuTabs.blasts === "approver",
      ol,
      authorizedDetails,
      orgName,
      type === "Events" ? hyeventsAccessApproval : hyblastsAccessApproval,
      apps,
      sms,
      dripsMenu,
      alertsMenu,
      isRentOrg,
      appDetails,
      orgDetails.current,
      isHayleyFaqEnabled
    );
    setMenuItems(menu);
  };

  const getDrawer = async () => {
    const data = await getNavDrawerMenu(
      menuType,
      path.getAppPath(orgLevel ? subOrgDetails.org.id : subOrgDetails.id),
      approvalSubmenuTabs?.blasts === "approver",
      false,
      authorizedDetails,
      orgName,
      hyblastsAccessApproval,
      toursMenu,
      null,
      dripsMenu,
      alertsMenu,
      isRentOrg,
      appDetails,
      orgDetails.current,
      isHayleyFaqEnabled
    );
    setMenuItems(data);
  };

  React.useEffect(() => {
    if (menuType) {
      getDrawer();
    }
  }, [isHayleyFaqEnabled]);

  const handleSignOut = async () => {
    try {
      await window.localStorage.clear();
      window.location.assign(`${accountsLinkAI}/logout?goto=journey-ai`);
    } catch (err) {
      console.error("logout error: ", err);
    }
  };

  // useCustomEventListener("UpdateUserName", handleUserName);
  useCustomEventListener("UserSignOut", handleSignOut);

  const rsvpPageRedirector = (orgType, orgId) => {
    const localPath = window.location.pathname;
    if (orgType === "org" && localPath.search("/events/rsvp") !== -1) {
      const initUrl = orgId ? `/${orgId}` : path.getAppPath(subOrgDetails.id);
      rest.history.push(initUrl + "/events/approvals/my-approval");
    } else if (orgType === "org" && orgId) {
      rest.history.push(`/${orgId}/${localPath.split("/").slice(2).join("/")}`);
    } else if (orgType === "property" && localPath.search("/events/rsvp") !== -1) {
      rest.history.push(path.getAppPath(subOrgDetails.id) + "/events");
    }
  };

  const handleSelectProperty = (row) => {
    setLoading(true);

    let data = {
      ...row[1],
      org: {
        id: row[0].id,
        name: row[0].name,
      },
      orgLevel: false,
      userEmail: user.username,
    };
    fetchOrgSuborgData(data);
    dispatch(initLoad());
    dispatch(initBlastLoad());
    dispatch(initLoadEvents());
    rsvpPageRedirector("property");
  };

  const handleSelectOrg = (org) => {
    setLoading(true);

    const rentOrgCheck = org.name.split("_")[0] === "Rent";
    // const zohoChatbot = document.getElementById("zohohc-asap-web-launcherbox");
    const zohoChatbot = document.getElementById("hubspot-messages-iframe-container");

    if (rentOrgCheck) {
      if (zohoChatbot) {
        // zohoChatbot.style.display = "none";
        zohoChatbot.style.setProperty("display", "none", "important");
      }
      document.documentElement.style =
        "--hylyTheme: var(--hylySecondary);--hylyThemeDull: var(--hylySecondaryDull);--hylyThemeBg: var(--hylySecondaryBg);";
    } else {
      if (zohoChatbot) {
        zohoChatbot.style.display = "flex";
      }
      document.documentElement.style =
        "--hylyTheme: var(--hylyPrimary);--hylyThemeDull: var(--hylyPrimaryDull);--hylyThemeBg: var(--hylyPrimaryBg);";
    }
    if (rentOrgCheck) {
      var style = document.createElement("style");
      style.id = "hideProductFruits";
      style.innerHTML = ".productfruits--container { display: none !important; }";
      document.head.appendChild(style);
    } else {
      var styleElement = document.getElementById("hideProductFruits");
      if (styleElement) {
        styleElement.parentNode.removeChild(styleElement);
      }
    }

    setIsRentOrg(rentOrgCheck);
    if (org.suborgs) {
      let data = {
        ...org.suborgs[0],
        org: {
          id: org.id,
          name: org.name,
        },
        orgLevel: true,
        userEmail: user.username,
      };
      fetchOrgSuborgData(data);
    } else {
      fetchOrgSuborgData({
        ...subOrgDetails,
        orgLevel: true,
      });
    }
    dispatch(initLoad());
    rsvpPageRedirector("org", orgLevel ? org?.id : org?.suborgs?.[0]?.id || null);
  };

  const handleHeaderClick = () =>
    rest.history.push(
      path.getAppPath(orgLevel ? subOrgDetails.org.id : subOrgDetails.id) + "/home"
    );

  const onSwitchLevel = async () => {
    if (selectedNavItem4Replace.level === "property") {
      const selectedOrg = _.find(allOrgs, (v) => v.id === subOrgDetails.org.id);
      if (selectedOrg) {
        dispatch(
          setSuborgDetails({
            ...subOrgDetails,
            orgLevel: false,
            ...selectedOrg.suborgs[0],
          })
        );
      } else {
        dispatch(setSuborgDetails({ ...subOrgDetails, orgLevel: false }));
      }
      await fetchOrgSuborgData({ ...subOrgDetails, orgLevel: false }, true);
    } else if (selectedNavItem4Replace.level === "org") {
      await fetchOrgSuborgData({ ...subOrgDetails, orgLevel: true }, true);
      dispatch(setSuborgDetails({ ...subOrgDetails, orgLevel: true }));
    }
    setShowOrgPropertySwitchConfirmModal(false);
    rest.history.push({
      pathname: selectedNavItem4Replace.to.pathname,
    });
  };

  const handleOpenInNewTab = () => {
    setShowOrgPropertySwitchConfirmModal(false);
    if (selectedNavItem4Replace.level === "property") {
      window.open(`${selectedNavItem4Replace.to.pathname}?propertylevel=1`, "_blank");
    } else {
      window.open(`${selectedNavItem4Replace.to.pathname}?orglevel=1`, "_blank");
    }
  };

  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          refreshCacheAndReload();
        }
        return (
          <>
            {email && (
              <ProductFruits
                workspaceCode={process.env.REACT_APP_PRODUCT_FRUITS}
                language="en"
                user={userInfo}
              />
            )}
            <NavDrawer
              environment={environment}
              org={subOrgDetails.org}
              property={subOrgDetails}
              propertyId={subOrgDetails.id}
              initialIDValue={subOrgDetails.id}
              orgLevel={subOrgDetails.orgLevel}
              menu={menuItems}
              link_component={Link}
              suborg_change_available={true}
              onSelectOrg={handleSelectOrg}
              onSelectProperty={handleSelectProperty}
              is_online={true}
              pathname={parsePath(rest.location.pathname)}
              appName="hyMail"
              productName="Journey AI Suite"
              handleHeaderClick={handleHeaderClick}
              setOriginNavItems={setOriginNavItems}
              isRentOrg={isRentOrg}
              showOrgPropertySwitchConfirmModal={showOrgPropertySwitchConfirmModal}
              setShowOrgPropertySwitchConfirmModal={setShowOrgPropertySwitchConfirmModal}
              setSelectedNavItem4Replace={setSelectedNavItem4Replace}
            >
              <Content
                fetchOrgSuborgData={fetchOrgSuborgData}
                classes={classes}
                user={user}
                setOriginNavItems={setOriginNavItems}
                orgLevel={subOrgDetails.orgLevel}
                propertyId={subOrgDetails.id}
                orgLevelVisible={orgLevel}
                userRole={_userRole}
                authorizedDetails={authorizedDetails}
                apps={apps}
                orgName={orgName}
                appsLoading={appsLoading}
                hyeventsAccessApproval={hyeventsAccessApproval}
                hyblastsAccessApproval={hyblastsAccessApproval}
                approvalSubmenuTabs={approvalSubmenuTabs}
                getProfileDetails={getProfileDetails}
                isRentOrg={isRentOrg}
                appDetails={appDetails}
                showOrgPropertySwitchConfirmModal={showOrgPropertySwitchConfirmModal}
                setShowOrgPropertySwitchConfirmModal={setShowOrgPropertySwitchConfirmModal}
                selectedNavItem4Replace={selectedNavItem4Replace}
                onSwitchLevel={onSwitchLevel}
                handleOpenInNewTab={handleOpenInNewTab}
                {...rest}
              />
            </NavDrawer>
          </>
        );
      }}
    </CacheBuster>
  );
}

export default React.memo(Application);
