import React from "react";
import clsx from "clsx";
import Switch from "@mui/material/Switch";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 30,
    height: 22,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(8px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "rgba(38, 187, 237, 1)",
        opacity: 1,
      },
    },
    "&$checked $thumb": {
      border: "1px solid rgba(38, 187, 237, 1)",
    },
  },
  thumb: {
    border: "1px solid rgba(153, 153, 153, 1)",
    width: 20,
    height: 20,
  },
  track: {
    transform: "translateX(1px)",
    borderRadius: 10,
    backgroundColor: "rgba(153, 153, 153, 1)",
    opacity: 1,
    width: 28,
    height: 10,
    marginTop: 6,
  },
  checked: {},
  focusVisible: {},
  disabled: {
    "&$checked + $track": {
      backgroundColor: "rgba(38, 187, 237, 0.4) !important",
      opacity: 1,
    },
    "&$checked $thumb": {
      border: "1px solid rgba(38, 187, 237, 0.4) !important",
    },
    "& $thumb": {
      border: "1px solid rgba(153, 153, 153, 0.4) !important",
    },
  },
}));

const CustomizedSwitch = ({ classes, ...props }) => {
  const defaultClasses = useStyles();

  return (
    <Switch
      classes={{
        root: clsx(defaultClasses.root, classes?.root ?? ""),
        switchBase: clsx(defaultClasses.switchBase, classes?.switchBase ?? ""),
        thumb: clsx(defaultClasses.thumb, classes?.thumb ?? ""),
        track: clsx(defaultClasses.track, classes?.track ?? ""),
        checked: clsx(defaultClasses.checked, classes?.checked ?? ""),
        disabled: clsx(defaultClasses.disabled, classes?.disabled ?? ""),
      }}
      {...props}
    />
  );
};

export default CustomizedSwitch;
