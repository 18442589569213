import React from "react";

export default function EmailBouncedIcon({
  className = "text-[#999999]",
  width = 18,
  height = 18,
}) {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.125 13.875H4.125C3.711 13.875 3.375 13.539 3.375 13.125V4.125C3.375 3.711 3.711 3.375 4.125 3.375H13.125C13.539 3.375 13.875 3.711 13.875 4.125V7.125"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 13C12.2761 13 12.5 12.7761 12.5 12.5C12.5 12.2239 12.2761 12 12 12C11.7239 12 11.5 12.2239 11.5 12.5C11.5 12.7761 11.7239 13 12 13Z"
        fill="currentColor"
      />
      <path
        d="M9.05655 13.7799L11.5823 9.08923C11.6229 9.01383 11.6832 8.95084 11.7568 8.90693C11.8303 8.86303 11.9143 8.83984 12 8.83984C12.0856 8.83984 12.1697 8.86303 12.2432 8.90693C12.3167 8.95084 12.377 9.01383 12.4176 9.08923L14.9434 13.7799C14.9822 13.8521 15.0017 13.9332 14.9998 14.0153C14.998 14.0973 14.9749 14.1774 14.9328 14.2478C14.8907 14.3183 14.8311 14.3766 14.7598 14.4171C14.6884 14.4576 14.6078 14.4789 14.5257 14.4789H9.4742C9.39216 14.4789 9.31154 14.4576 9.24019 14.4171C9.16885 14.3766 9.10923 14.3183 9.06715 14.2478C9.02507 14.1774 9.00198 14.0973 9.00012 14.0153C8.99826 13.9332 9.01771 13.8521 9.05655 13.7799V13.7799Z"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
