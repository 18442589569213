import React from "react";

export default function TourCompletedIcon({
  className = "text-[#999999]",
  width = 18,
  height = 18,
}) {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3 7H15" stroke="currentColor" stroke-linecap="round" strokeLinejoin="round" />
      <path
        d="M15 9.39961V5.39961C15 5.18744 14.9157 4.98395 14.7657 4.83392C14.6157 4.68389 14.4122 4.59961 14.2 4.59961H3.8C3.58783 4.59961 3.38434 4.68389 3.23431 4.83392C3.08429 4.98395 3 5.18744 3 5.39961V14.1996C3 14.4118 3.08429 14.6153 3.23431 14.7653C3.38434 14.9153 3.58783 14.9996 3.8 14.9996H7.8"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path d="M6.2002 3V4.6" stroke="currentColor" stroke-linecap="round" strokeLinejoin="round" />
      <path
        d="M11.7998 3V4.6"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.2002 13.4008L11.8002 15.0008L15.0002 11.8008"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
