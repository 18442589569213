import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import environment from "environments/environment.js";

const mutation = graphql`
  mutation setSettingsMutation($input: saveHyeventsSettingInput!) {
    saveHyeventsSetting(input: $input) {
      hyeventsSetting {
        approvalRequired
        approverEmails
        agentApprovalEmails
        hyeventsBccEmails
      }
    }
  }
`;

function commit(input) {
  return new Promise((res, rej) => {
    commitMutation(environment, {
      mutation,
      variables: { input },
      onCompleted: res,
      onError: rej,
    });
  });
}

export default commit;
