import graphql from "babel-plugin-relay/macro";

export default graphql`
  query getorgSettingQuery($orgId: ID!, $propertyId: ID) {
    orgSetting(orgId: $orgId, propertyId: $propertyId) {
      orgSetting {
        enableDisclaimer
        disclaimerTitle
        disclaimerMessage
        id
        orgLevelDisclaimer
      }
      propertySetting {
        enableDisclaimer
        disclaimerTitle
        disclaimerMessage
        id
        orgLevelDisclaimer
      }
    }
  }
`;
