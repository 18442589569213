/**
 * @flow
 * @relayHash 7cfe0e8c7b5aea3dabb0b285e54cb409
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type importLogsQueryVariables = {|
  propertyId: string,
  subStatus?: ?string,
|};
export type importLogsQueryResponse = {|
  +importLogs: {|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +createdAt: any,
        +fileName: string,
        +importedAt: ?any,
        +importedBy: string,
        +totalRows: number,
        +successCount: number,
        +failureCount: number,
        +performance: ?string,
        +contactsBeforeLoad: number,
        +contactsAfterLoad: number,
      |}
    |}>
  |}
|};
export type importLogsQuery = {|
  variables: importLogsQueryVariables,
  response: importLogsQueryResponse,
|};
*/


/*
query importLogsQuery(
  $propertyId: ID!
  $subStatus: String
) {
  importLogs(propertyId: $propertyId, subStatus: $subStatus) {
    edges {
      node {
        id
        createdAt
        fileName
        importedAt
        importedBy
        totalRows
        successCount
        failureCount
        performance
        contactsBeforeLoad
        contactsAfterLoad
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "propertyId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "subStatus",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "importLogs",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "propertyId",
        "variableName": "propertyId"
      },
      {
        "kind": "Variable",
        "name": "subStatus",
        "variableName": "subStatus"
      }
    ],
    "concreteType": "ImportLogConnection",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "edges",
        "storageKey": null,
        "args": null,
        "concreteType": "ImportLogEdge",
        "plural": true,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "ImportLog",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "createdAt",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "fileName",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "importedAt",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "importedBy",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "totalRows",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "successCount",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "failureCount",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "performance",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "contactsBeforeLoad",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "contactsAfterLoad",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "importLogsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "importLogsQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "importLogsQuery",
    "id": null,
    "text": "query importLogsQuery(\n  $propertyId: ID!\n  $subStatus: String\n) {\n  importLogs(propertyId: $propertyId, subStatus: $subStatus) {\n    edges {\n      node {\n        id\n        createdAt\n        fileName\n        importedAt\n        importedBy\n        totalRows\n        successCount\n        failureCount\n        performance\n        contactsBeforeLoad\n        contactsAfterLoad\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '03ea32cf26a8f02202055a76e7f93eb2';
module.exports = node;
