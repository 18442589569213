import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  regions: [],
  regionData: [],
};

export const successSlice = createSlice({
  name: "success",
  initialState,
  reducers: {
    setRegions: (state, action) => {
      state.regions = action.payload;
    },
    setRegionData: (state, action) => {
      state.regionData = action.payload;
    },
  },
});

export const { setRegions } = successSlice.actions;
export const { setRegionData } = successSlice.actions;

export const selectRegions = (state) => state.success.regions;
export const selectRegionData = (state) => state.success.regionData;

export default successSlice.reducer;
