import React from "react";

function StarsIcon({ className = "text-[#999999]", size = 16, fill = "#fff" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      className={className}
      fill="none"
      viewBox="0 0 16 16"
    >
      <g
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        clipPath="url(#clip0_1_3477)"
      >
        <path d="M.5 10.5a5 5 0 005-5 5 5 0 005 5 5 5 0 00-5 5 5 5 0 00-5-5zM9.5 3.5a3 3 0 003-3 3 3 0 003 3 3 3 0 00-3 3 3 3 0 00-3-3z"></path>
      </g>
      <defs>
        <clipPath id="clip0_1_3477">
          <path fill={fill} d="M0 0H16V16H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default StarsIcon;
