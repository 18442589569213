import clsx from "clsx";
import React from "react";

import SubRow from "./SubRow";
import StyledTableRow from "../StyledTableRow";
import StyledTableCell from "../StyledTableCell";
import Typography from "storybookComponents/Typography";

const Row = ({
  columns,
  data,
  hasExpandableRow,
  hasSubRows,
  toggleRowCallback,
  toggleRowCellClass,
  classNames,
  selectedRowId,
  selectedRowColor,
}) => {
  const [isCollapsed, setIsCollapsed] = React.useState(true);
  const onToggleRow = () => {
    if (!hasExpandableRow && !hasSubRows) {
      return;
    }

    if (!data.subRows || data.subRows.length < 1) {
      return;
    }

    setIsCollapsed(!isCollapsed);
    toggleRowCallback();
  };

  React.useEffect(() => {
    setIsCollapsed(true);
  }, [data]);

  return (
    <>
      <StyledTableRow className={`table-row  ${classNames}`} onClick={onToggleRow}>
        {hasExpandableRow && (
          <StyledTableCell
            scope="row"
            align="center"
            className={clsx("align-middle select-none", toggleRowCellClass)}
          >
            {data.subRows && data.subRows.length > 0 && (
              <ChevronIcon
                width={24}
                height={24}
                className={`cursor-pointer ${isCollapsed ? "" : "rotate-90"}`}
              />
            )}
          </StyledTableCell>
        )}

        {columns.map((item, idx) => (
          <StyledTableCell
            scope="row"
            key={idx}
            style={selectedRowId === data.id ? { background: selectedRowColor } : {}}
            align={item.align || "left"}
            className={item.cellClassName}
          >
            <span
              style={
                hasSubRows
                  ? { display: "flex", gap: "6px", flexWrap: "wrap", alignItems: "center" }
                  : {}
              }
            >
              {data[item.field]}

              {hasSubRows && data.subRows && idx === columns.length - 1 && (
                <span style={{ color: "#4F6FAE", fontSize: "12px" }}>
                  {isCollapsed ? " See History" : " Hide History"}
                </span>
              )}
            </span>
          </StyledTableCell>
        ))}
      </StyledTableRow>

      {/* If expandabl row */}
      {!isCollapsed &&
        data.subRows &&
        data.subRows.map((row, idx) => (
          <SubRow key={idx} data={row} columns={columns} hasSubRows={hasSubRows} index={idx} />
        ))}
    </>
  );
};

const ChevronIcon = ({
  className = "text-text",
  width = 24,
  height = 24,
  viewBox = "0 0 24 24",
  ...rest
}) => {
  return (
    <div className={`flex-none ${className}`} style={{ width, height }} {...rest}>
      <svg width={width} height={height} viewBox={viewBox} fill="none">
        <path
          d="M9.6001 6L15.6001 12L9.6001 18"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
      </svg>
    </div>
  );
};

export default Row;
