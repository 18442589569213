import React from "react";

export default function ScheduledRunIcon({
  className = "text-[#999999]",
  width = 18,
  height = 18,
}) {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 14.25C11.8995 14.25 14.25 11.8995 14.25 9C14.25 6.10051 11.8995 3.75 9 3.75C6.10051 3.75 3.75 6.10051 3.75 9C3.75 11.8995 6.10051 14.25 9 14.25Z"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path d="M9 6V9H12" stroke="currentColor" stroke-linecap="round" strokeLinejoin="round" />
    </svg>
  );
}
