import graphql from "babel-plugin-relay/macro";

export default graphql`
  query getExpressionCountQuery($propertyId: ID!, $expression: String!) {
    contactExpressionCount(propertyId: $propertyId, expression: $expression) {
      count
      reachableCount
      csvData
    }
  }
`;
