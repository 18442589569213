import graphql from "babel-plugin-relay/macro";

export default graphql`
  query allOrgsGptBlastAnalysisQuery(
    $allOrgs: Boolean
    $endAt: String
    $startAt: String
    $orgIds: [String!]
  ) {
    allOrgsGptBlastAnalysis(allOrgs: $allOrgs, endAt: $endAt, startAt: $startAt, orgIds: $orgIds)
  }
`;
