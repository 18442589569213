import React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import "./tooltip.css";
import { create_path, check_if_selected } from "../utils";
import clsx from "clsx";
import { useLocation } from "react-router-dom";

const SubMenuItem = ({
  to,
  title,
  moduleName,
  icon_type,
  children,
  showBorderLine,
  menu_open,
  pathname,
  link_component,
  parent_to,
  parent_selected,
  swipeable,
  onClose,
  classes,
  onMenuItemClick,
  level,
}) => {
  const {
    subItemSelected,
    subMenuItem,
    nestedText,
    selected,
    remove_padding,
    displayIcon,
    subItemSelectedSVG,
    borderLine,
  } = classes;
  const pathTo = React.useMemo(
    () => create_path(to || parent_to, moduleName),
    [to, parent_to, moduleName]
  );
  const data_selected = React.useMemo(
    () => check_if_selected(children, to, pathname),
    [children, to, pathname]
  );

  const handleClose = (e) => {
    if (menu_open && swipeable) onClose(e);
  };

  const { search } = useLocation();

  return (
    <ListItem
      key={title}
      selected={data_selected}
      component={link_component}
      to={{
        ...pathTo,
        search: pathTo.pathname.includes("success-center-blast/property-") && search,
      }}
      onClick={(event) => {
        onMenuItemClick(event, {
          to: pathTo,
          title,
          moduleName,
          level,
        });
        handleClose(event);
      }}
      classes={{
        root: clsx(subMenuItem, parent_selected && subItemSelected, showBorderLine && borderLine),
        selected: clsx(data_selected && subItemSelectedSVG),
      }}
    >
      <div className={displayIcon}>
        {typeof icon_type !== "string" && icon_type}
        <ListItemText
          inset
          primary={title}
          classes={{
            root: remove_padding,
            primary: clsx(nestedText, data_selected && selected),
          }}
        />
      </div>
    </ListItem>
  );
};

export default React.memo(SubMenuItem);
