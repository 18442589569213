import graphql from "babel-plugin-relay/macro";

export default graphql`
  query getCampaignUtmCodeQuery($id: ID!) {
    campaignUtmCodeUrls(campaignId: $id) {
      directLink
      portalBannerLink
    }
  }
`;
