/**
 * @flow
 * @relayHash 0d7b8ec1fae6e98cf411561cb43bc2f5
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type retrieveFaqItemsQueryVariables = {|
  jobId: string
|};
export type retrieveFaqItemsQueryResponse = {|
  +retrieveFaqItemsByJobId: {|
    +answer: ?$ReadOnlyArray<{|
      +faqItems: ?$ReadOnlyArray<{|
        +id: string,
        +image: ?{|
          +fileUrl: string,
          +id: string,
        |},
        +imageId: ?string,
        +itemDescription: ?string,
        +editable: ?boolean,
        +selected: ?boolean,
        +itemTitle: ?string,
        +section: ?{|
          +sectionName: ?string,
          +sectionType: ?string,
        |},
        +tenantId: ?string,
      |}>,
      +id: string,
      +section: ?string,
    |}>,
    +id: string,
    +question: ?{|
      +faqType: ?string,
      +id: string,
      +orgName: ?string,
      +propertyName: ?string,
      +questionId: ?string,
      +subject: ?string,
      +tenantId: ?string,
      +unorganizedAnswer: ?string,
      +value: ?string,
    |},
  |}
|};
export type retrieveFaqItemsQuery = {|
  variables: retrieveFaqItemsQueryVariables,
  response: retrieveFaqItemsQueryResponse,
|};
*/


/*
query retrieveFaqItemsQuery(
  $jobId: String!
) {
  retrieveFaqItemsByJobId(jobId: $jobId) {
    answer {
      faqItems {
        id
        image {
          fileUrl
          id
        }
        imageId
        itemDescription
        editable
        selected
        itemTitle
        section {
          sectionName
          sectionType
          id
        }
        tenantId
      }
      id
      section
    }
    id
    question {
      faqType
      id
      orgName
      propertyName
      questionId
      subject
      tenantId
      unorganizedAnswer
      value
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "jobId",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "jobId",
    "variableName": "jobId"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "image",
  "storageKey": null,
  "args": null,
  "concreteType": "Asset",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "fileUrl",
      "args": null,
      "storageKey": null
    },
    (v2/*: any*/)
  ]
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "imageId",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "itemDescription",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "editable",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "selected",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "itemTitle",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "sectionName",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "sectionType",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "tenantId",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "section",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "question",
  "storageKey": null,
  "args": null,
  "concreteType": "PropertyCorpusFaq",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "faqType",
      "args": null,
      "storageKey": null
    },
    (v2/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "orgName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "propertyName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "questionId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "subject",
      "args": null,
      "storageKey": null
    },
    (v11/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "unorganizedAnswer",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "value",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "retrieveFaqItemsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "retrieveFaqItemsByJobId",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "OrganizeFaqItem",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "answer",
            "storageKey": null,
            "args": null,
            "concreteType": "FaqAnswer",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "faqItems",
                "storageKey": null,
                "args": null,
                "concreteType": "FaqItem",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "section",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "FaqSection",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/),
                      (v10/*: any*/)
                    ]
                  },
                  (v11/*: any*/)
                ]
              },
              (v2/*: any*/),
              (v12/*: any*/)
            ]
          },
          (v2/*: any*/),
          (v13/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "retrieveFaqItemsQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "retrieveFaqItemsByJobId",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "OrganizeFaqItem",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "answer",
            "storageKey": null,
            "args": null,
            "concreteType": "FaqAnswer",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "faqItems",
                "storageKey": null,
                "args": null,
                "concreteType": "FaqItem",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "section",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "FaqSection",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v2/*: any*/)
                    ]
                  },
                  (v11/*: any*/)
                ]
              },
              (v2/*: any*/),
              (v12/*: any*/)
            ]
          },
          (v2/*: any*/),
          (v13/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "retrieveFaqItemsQuery",
    "id": null,
    "text": "query retrieveFaqItemsQuery(\n  $jobId: String!\n) {\n  retrieveFaqItemsByJobId(jobId: $jobId) {\n    answer {\n      faqItems {\n        id\n        image {\n          fileUrl\n          id\n        }\n        imageId\n        itemDescription\n        editable\n        selected\n        itemTitle\n        section {\n          sectionName\n          sectionType\n          id\n        }\n        tenantId\n      }\n      id\n      section\n    }\n    id\n    question {\n      faqType\n      id\n      orgName\n      propertyName\n      questionId\n      subject\n      tenantId\n      unorganizedAnswer\n      value\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c5484b5f572e0f346a2337be3d767354';
module.exports = node;
