import React from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";

import SvgIcon from "@mui/material/SvgIcon";

import Icon from "../Icon";
import Button from "../Button";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";

/*  icons utilities that relate to the dropdown component */

export const MaterialIcon = (Component, checked_path, unchecked_path) => {
  const Checked = React.memo((props) => (
    <SvgIcon {...props}>
      {" "}
      <path d={checked_path} />{" "}
    </SvgIcon>
  ));
  const Unchecked = React.memo((props) => (
    <SvgIcon {...props}>
      {" "}
      <path d={unchecked_path} />{" "}
    </SvgIcon>
  ));

  Checked.muiName = SvgIcon.muiName;

  Unchecked.muiName = SvgIcon.muiName;

  const checkbox_styles = (theme) => ({
    box_root: {
      color: "#ccc",
    },
    colorSecondary: {
      "&$checked": {
        color: theme.palette.hylyBlue,
      },
      "&$disabled": {
        color: theme.palette.action.disabled,
      },
    },
    checked: {},
    disabled: {},
  });

  return React.memo(
    withStyles(checkbox_styles)(
      ({ classes: { box_root, fontSizeSmall, colorSecondary, checked, disabled }, ...props }) => {
        return (
          <Component
            icon={<Unchecked fontSize="default" style={{ fontSize: 20, ...props.icon_styles }} />}
            checkedIcon={
              <Checked fontSize="default" style={{ fontSize: 20, ...props.icon_styles }} />
            }
            classes={{
              root: box_root,
              colorSecondary,
              checked,
              disabled,
            }}
            {...props}
          />
        );
      }
    )
  );
};

const HylyRadioComponent = MaterialIcon(
  Radio,
  "M12 7c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5zm0-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z",
  "M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
);

const HylyCheckboxComponent = MaterialIcon(
  Checkbox,
  "M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z",
  "M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"
);

export const HylyRadio = (props) => <HylyRadioComponent {...props} />;
export const HylyCheckbox = (props) => <HylyCheckboxComponent {...props} />;

const drop_down_icon_styles = {
  root: {
    "pointer-events": "none",
    transform: "translateY(-1px)",
    // I don't know why but it seemed to be required.
  },
  root_field: {
    transform: "translateY(4px)",
  },
};

const DropDownIcon = withStyles(drop_down_icon_styles)(
  ({
    classes: { root, root_field, ...other_classes },
    select_disabled,
    dropdown_icon,
    size,
    is_button,
    is_field,
    className,
    dropdown_tooltip_text,
    ...other
  }) => {
    return is_button ? (
      <Button
        variant="icon"
        size={size}
        icon_path={dropdown_icon}
        state={select_disabled ? "inactive" : undefined}
      >
        {dropdown_tooltip_text}
      </Button>
    ) : (
      <Icon
        size={size}
        type={dropdown_icon}
        className={`${className} ${is_field ? root_field : root}`}
      />
    );
  }
);
//  className = {`${className} ${root}`}
//className = {`${className} ${root}`}

DropDownIcon.propTypes = {
  select_disabled: PropTypes.bool,
  dropdown_icon: PropTypes.string.isRequired,
  dropdown_tooltip_text: PropTypes.string,
};

DropDownIcon.defaultProps = {
  dropdown_icon: "icon-down-arrow",
  dropdown_tooltip_text: "more",
};

export const DropDownIconComponent = DropDownIcon;
