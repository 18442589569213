import { getOmniChannelPreviews, getEnrollment } from "relay/campaigns";
import addPopup from "./addPopup";
import { Auth } from "aws-amplify";

const getAuthState = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    return user.signInUserSession.idToken.jwtToken;
  } catch (err) {
    console.warn("getAuthState error: ", err.message || err);
    return undefined;
  }
};

export const viewIds = {
  RESIDENTIAL_PORTAL: 2,
  SHARE_OPTION: 3,
  SHARE_PREVIEW: 4,
  EMAIL_SMS: 1,
};
export const tabs = [
  "A. Resident Invite",
  "B. Enroll Form",
  "C. Refer Menu",
  "D. Friend Invite",
  "E. Resident Reward",
];

export const loadEmailPreviews = async (id, removeTourMyCommmunity) => {
  let token = await getAuthState();
  try {
    let res = await getOmniChannelPreviews(id);
    console.log("res: ", res);
    if (res.hyreferralCampaign) {
      let c = res.hyreferralCampaign;
      return {
        ...c,
        smsEnabled: false,
        residentInviteEmailCampaignHtml: c.residentInviteEmailCampaignHtml.replace(
          /<button/g,
          '<button id="enroll-link-button" type= "button"'
        ),
        friendInviteEmailCampaignHtml: addPopup(
          c.friendInviteEmailCampaignHtml,
          process.env.REACT_APP_MyhylyEndpoint,
          "FriendInvite",
          id,
          token,
          c.residentInviteEmailSubject
        ),
        residentRewardInviteEmailCampaignHtml: addPopup(
          c.residentRewardInviteEmailCampaignHtml,
          process.env.REACT_APP_MyhylyEndpoint,
          "ResidentReward",
          id,
          token,
          c.residentDiscountEmailSubject
        ),
        image: c.image.fileUrl,
        residentDiscountImage: c.residentDiscountImage.fileUrl,
        residentInviteImage: c.residentInviteImage.fileUrl,
        residentInviteSms: removeTourMyCommmunity
          ? c.residentInviteSms
          : c.residentInviteSms +
            "<br><br><b>Tour My Community,</b><br><div style='word-break: break-word;'><a href='" +
            c.tourCtaUrl.replace(/http:/g, "https:") +
            "'>" +
            c.shortTourCtaUrl +
            "</a></div>",
        residentDiscountEmailSms:
          c.residentDiscountEmailSms +
          "<br> <br><b>Refer Again,</b><br><a href='" +
          c.shortEnrollUrl +
          "'>" +
          c.shortEnrollUrl +
          "</a><br><br>Best Regards<br><div style='margin-top:10px;'><b>" +
          c.propertyName +
          "</b></div>",
        emailSms:
          c.emailSms +
          "<br> <br><b>Enroll Now,</b><br><a href='" +
          c.shortEnrollUrl +
          "'>" +
          c.shortEnrollUrl +
          "</a><br><br>Best Regards<br><div style='margin-top:10px;'><b>" +
          c.propertyName +
          "</b></div>",
        residentInviteSmsParsed: removeTourMyCommmunity
          ? linkify(c.residentInviteSms)
          : linkify(c.residentInviteSms) +
            "<br><br><b>Tour My Community,</b><br><div style='word-break: break-word;'><a href='" +
            c.tourCtaUrl.replace(/http:/g, "https:") +
            "'>" +
            c.shortTourCtaUrl +
            "</a></div>",
        residentInviteSmsForSms:
          c.residentInviteSms +
          " <br><br><b>Tour My Community,</b><br><div style='word-break: break-word;'>" +
          c.shortTourCtaUrl +
          "</div>",
      };
    }
    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getEnrollmentDetails = async (id) => {
  try {
    const resp = await getEnrollment(id);
    if (resp) return resp;
    else return null;
  } catch (err) {
    console.log("Err", err);
    return null;
  }
};

export const subButtons = [
  {
    index: 1,
    label: "Desktop",
  },
  {
    index: 2,
    label: "Mobile",
  },
  {
    index: 3,
    label: "SMS",
  },
];

export const getRightButtons = (activeTab, subOption) => {
  if (activeTab == 0) {
    if (subOption == 0) {
      return subButtons;
    }
    if (subOption == 1) {
      return subButtons.slice(0, 2);
    }
    if (subOption == 2) {
      return [];
    }
  } else if (activeTab == 1 || activeTab == 2) {
    return subButtons.slice(0, 2);
  } else if (activeTab == 3) {
    return subButtons.slice(0, 2);
  } else {
    return subButtons;
  }
};

// const tabData = {
//   [tabs[0]]: {
//     sub: [{
//       id: viewIds.EMAIL_SMS,
//       name: "1. Via Email/SMS",
//       buttons: subButtons
//     }, {
//       id: viewIds.RESIDENTIAL_PORTAL,
//       name: "2. Via Resident Portal",
//       buttons: subButtons.slice(0, 2)
//     }]
//   },
//   [tabs[1]]: {
//     buttons: subButtons.slice(0, 2)
//   },
//   [tabs[2]]: {
//     sub: [{
//       id: viewIds.SHARE_OPTION,
//       name: "1. Share Options",
//       buttons: subButtons
//     }, {
//       id: viewIds.SHARE_PREVIEW,
//       name: "2. Share Previews (5)",
//       buttons: subButtons.slice(0, 2)
//     }]
//   },
//   [tabs[3]]: { buttons: [{ label: "Email", index: 1 }] },
//   [tabs[4]]: { buttons: [{ label: "Email", index: 1 }] },
// }

// export const getTabarButtons = (index) => {
//   return tabData[tabs[index]]
// }

export const smsPreviewHtml = (image, message, linkColor) => {
  return `
    <style>
       a{
          color: ${linkColor ? linkColor : "var(--hylyTheme)"}!important;
        }
    </style>
   <div style="display: flex; height: 688px; align-items: flex-end;"> <div style = "min-width:309px;font-family:Open Sans, arial, sans-serif" > <div><img style="width:100%; border-radius:10px;" src="${image}" /></div> <div style="background:#f9f9f9; padding:10px; margin-top:10px; border-radius:10px;">${message}</div></div ></div>
  `;
};

export const linkify = (inputText, parseHashtag = true) => {
  if (!inputText) return inputText;

  const httpsurlregexp = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
  const httpurlregexp = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
  const emailegexp = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
  const hashtagregexp = /#(\w+)/g;

  var replacedText, replacePattern1, replacePattern2, replacePattern3, replacePattern4;

  replacePattern1 = httpsurlregexp;
  replacedText = inputText.replace(replacePattern1, '<a href="$1">$1</a>');

  replacePattern2 = httpurlregexp;
  replacedText = replacedText.replace(replacePattern2, '$1<a href="https://$2">$2</a>');

  replacePattern3 = emailegexp;
  replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');
  if (parseHashtag) {
    replacePattern4 = hashtagregexp;
    replacedText = replacedText.replace(replacePattern4, '<a href="#">#$1</a>');
  }
  return replacedText;
};

export const replaceHref = (html, link) => {
  return `<script>
             window.onload = function(){
                let button=document.getElementById("enroll-link-button");
                console.log("anchorTag",button.parentElement)
                button.parentElement.href="${link}"
             };
          </script>
          <div>${html}</div>`;
};
