/* eslint-disable */

const Yup = require("yup");

function urlRgxCreate() {
  const url_rgx =
    "^(?:(?:(?:https?|ftp):)?//)(?:S+(?::S*)?@)?(?:(?!(?:10|127)(?:.d{1,3}){3})(?!(?:169.254|192.168)(?:.d{1,3}){2})(?!172.(?:1[6-9]|2d|3[0-1])(?:.d{1,3}){2})(?:[1-9]d?|1dd|2[01]d|22[0-3])(?:.(?:1?d{1,2}|2[0-4]d|25[0-5])){2}(?:.(?:[1-9]d?|1dd|2[0-4]d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:.(?:[a-z\u00a1-\uffff]{2,})))(?::d{2,5})?(?:[/?#]S*)?$";
  const phone_rgx_with_dash = "^tel:(([0-9]{3})-|[0-9]{3}-)[0-9]{3}-[0-9]{4}$";
  const phone_rgx_without_dash = "^tel:(([0-9]{3})|[0-9]{3})[0-9]{3}[0-9]{4}$";
  const email_rgx =
    /^mailto:((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/;

  let email_rgx_str = new RegExp(email_rgx).toString();
  email_rgx_str = email_rgx_str.slice(1, email_rgx_str.length - 1);

  return new RegExp(
    "(" +
      email_rgx_str +
      ")|(" +
      phone_rgx_with_dash +
      ")|(" +
      phone_rgx_without_dash +
      ")|(" +
      url_rgx +
      ")",
    "i"
  );
}

const url_regex = urlRgxCreate();

const regex = {
  price:
    /(:?^-?|\s)(?=.)((?:0|(?:[1-9](?:\d*|\d{0,2}(?:,\d{3})*)))?(?:\.\d*[1-9])?)((%|\$)?)(?!\S)/,
  phone: /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/,
  number: /^\d+$/,
  zip: /^\d{5}$/,
  url: url_regex,
};

function get_prefix_validation(field, errors) {
  const schema = Yup.string();
  switch (field) {
    case "email":
      return schema[field](errors[field]);
    case "url":
    case "phone":
    case "number":
    case "zip":
    case "price":
      return schema.matches(regex[field], errors[field]);
    case "size":
      return schema.matches(regex.number, errors.size);
    case "text":
    default:
      return schema;
  }
}

const default_errors = {
  email: "Please enter a correct email.",
  url: "Please enter a value in the format <domain>/<path>.",
  phone: "Please enter a value in the format (111)111-1111.",
  number: "Please enter a number.",
  price: "Please enter a price.",
  zip: "Please enter a 5 digit number for zip.",
  required: (field) => `${field.replace(/(^[a-z])/, (o) => o.toUpperCase())} is required.`,
};

export function create_address_validations(field, errors) {
  const required_error = (c) =>
    errors[`required_${field}.${c}`] || (errors.required && errors.required(c));
  return Yup.object().shape({
    ...["street", "city", "state"].reduce(
      (a, c) => ({
        ...a,
        [c]: Yup.string().trim().required(required_error(c)),
      }),
      {}
    ),
    zip: Yup.string().matches(regex.zip, errors.zip),
  });
}

export function create_array_validations(type, required_suffix, label, errors) {
  const schema = get_prefix_validation(type, errors);
  return Yup.array().of(
    Boolean(required_suffix)
      ? schema
          .trim()
          .required(
            errors[`required_array_${type}`] || (errors.required && errors.required(label || type))
          )
      : schema
  );
}

export function create_validation(fields, errors = default_errors) {
  return Yup.object().shape(
    fields.reduce((acc, item) => {
      let type, name, required_suffix, field, label, variant;

      if (typeof item === "string") {
        [type, name, required_suffix] = item.split("__");
        field = item;
      } else if (typeof item === "object" && Object.hasOwnProperty.call(item, "type")) {
        // I am only supporting object validation for array type right now because it gets too messy otherwise
        // Navneet: please talk to me if you need more explanations on This

        variant = item.variant;
        type = item.type;
        name = item.name;
        required_suffix = item.required;
        label = item.label;
        field = `${type}__${name}${required_suffix ? "__required" : ""}`;
      }

      if (variant === "array") {
        const [, type_to_use] = type.split("_");
        return {
          ...acc,
          [field]: create_array_validations(type_to_use, required_suffix, label, errors),
        };
      } else if (type === "address") {
        return {
          ...acc,
          [field]: create_address_validations(field, errors),
        };
      }
      const schema = get_prefix_validation(type, errors);
      return {
        ...acc,
        [field]: Boolean(required_suffix)
          ? schema
              .trim()
              .required(
                errors[`required_${name}`] || (errors.required && errors.required(label || name))
              )
          : schema,
      };
    }, {})
  );
}
