import React from "react";
import { ResponsiveBar } from "@nivo/bar";
import withStyles from "@mui/styles/withStyles";
import { numberWithCommas } from "content/utils/commonParsing";

const styles = () => ({
  superContainer: { height: "110px", width: 660, background: "white", margin: "auto" },
  tooltip: { padding: "5px", border: "1px solid", background: "white" },
});

const BarChart = ({ classes, keys, colors, data }) => {
  const getColor = (bar) => colors[bar.id];

  const getTooltip = (points) => {
    return (
      <div className={classes.tooltip} style={{ color: points.color }}>
        {points.id}: {numberWithCommas(points.value)}
      </div>
    );
  };

  return (
    <div className={classes.superContainer}>
      <ResponsiveBar
        keys={keys}
        indexBy="date"
        groupMode="grouped"
        axisTop={null}
        axisRight={null}
        axisLeft={null}
        axisBottom={{
          tickSize: 0,
          tickPadding: 5,
          tickRotation: 0,
        }}
        innerPadding={0}
        padding={0.5}
        margin={{ top: 35, right: 50, bottom: 25, left: 50 }}
        colors={getColor}
        enableGridY={false}
        enableLabel={false}
        tooltip={getTooltip}
        data={data}
        theme={{
          tooltip: {
            container: {
              boxShadow: "none",
              padding: 0,
            },
          },
          axis: {
            domain: {
              line: {
                stroke: "#c4c4c4",
                strokeWidth: 1,
              },
            },
          },
        }}
        legends={[
          {
            dataFrom: "keys",
            anchor: "top",
            direction: "row",
            justify: false,
            translateX: 0,
            translateY: -25,
            itemsSpacing: 20,
            itemWidth: 100,
            itemHeight: 15,
            itemDirection: "left-to-right",
            itemOpacity: 0.85,
            symbolSize: 15,
          },
        ]}
      />
    </div>
  );
};

export default withStyles(styles)(BarChart);
