/**
 * @flow
 * @relayHash 3499ef0b96bca220d53ef992129e8c4b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type saveReportInput = {|
  clientMutationId?: ?string,
  saveReport?: ?ReportInput,
|};
export type ReportInput = {|
  orgOrPropertyId?: ?string,
  params?: ?any,
  name?: ?string,
  type?: ?string,
  tabName?: ?string,
|};
export type saveReportMutationVariables = {|
  input: saveReportInput
|};
export type saveReportMutationResponse = {|
  +saveReport: ?{|
    +clientMutationId: ?string,
    +status: ?string,
  |}
|};
export type saveReportMutation = {|
  variables: saveReportMutationVariables,
  response: saveReportMutationResponse,
|};
*/


/*
mutation saveReportMutation(
  $input: saveReportInput!
) {
  saveReport(input: $input) {
    clientMutationId
    status
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "saveReportInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "saveReport",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "saveReportPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "clientMutationId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "status",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "saveReportMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "saveReportMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "saveReportMutation",
    "id": null,
    "text": "mutation saveReportMutation(\n  $input: saveReportInput!\n) {\n  saveReport(input: $input) {\n    clientMutationId\n    status\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b052a9c3caf63e838fba78410e0c927c';
module.exports = node;
