import React from "react";

export default function NetworkIcon({ className = "text-[#333333]", width = 24, height = 24 }) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12 4.5V19.5" stroke="currentColor" stroke-linecap="round" strokeLinejoin="round" />
      <path
        d="M19.5 5.5H15.5V9.5H19.5V5.5Z"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path d="M12 7.5H13.5" stroke="currentColor" stroke-linecap="round" strokeLinejoin="round" />
      <path
        d="M19.5 14.5H15.5V18.5H19.5V14.5Z"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path d="M12 16.5H13.5" stroke="currentColor" stroke-linecap="round" strokeLinejoin="round" />
      <path
        d="M4.5 14H8.5V10H4.5V14Z"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path d="M12 12H10.5" stroke="currentColor" stroke-linecap="round" strokeLinejoin="round" />
    </svg>
  );
}
