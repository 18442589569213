import graphql from "babel-plugin-relay/macro";

export default graphql`
  query getcontactGroupsQuery($propertyId: ID!, $refreshGroupName: String) {
    contactGroups(propertyId: $propertyId, refreshGroupName: $refreshGroupName) {
      id
      name
      status
      count
      createdAt
      reachableCount
      mmsCount
      mmsReachableCount
      expression
      customId
    }
  }
`;
