/* eslint-disable react/forbid-prop-types */
import React from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Button from "../Button";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    flexGrow: 1,
  },
  appbarRoot: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    boxShadow: "none",
    background: "#fff",
    padding: 0,
  },
  appbarTransparentRoot: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    boxShadow: "none",
    background: "inherit",
    position: "relative",
    right: "30px",
  },
  tabsRoot: {
    flexGrow: 1,
    minHeight: 40,
  },
  tabsIndicator: {
    backgroundColor: "#333",
    height: 3,
  },
  tabRoot: {
    textTransform: "capitalize",
    color: "#33333380",
    fontSize: 14,
    minHeight: 40,
    fontWeight: "normal",
    padding: 0,
    minWidth: "max-content",
    marginLeft: 30,
    "&:hover": {
      color: "#26BBED",
    },
  },
  tabSelected: {
    color: "#333 !important",
    fontWeight: "bold",
  },
  buttonGroup: {
    display: "flex",
    alignItems: "center",
  },
  activeButton: {
    marginLeft: 10,
    marginRight: 10,
    textTransform: "capitalize",
    color: "#333",
    paddingLeft: 0,
    paddingRight: 0,
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    height: 20,
  },
  button: {
    marginLeft: 10,
    marginRight: 10,
    textTransform: "capitalize",
    paddingLeft: 0,
    paddingRight: 0,
    minWidth: 0,
    color: "#33333380",
    fontWeight: "normal",
  },
}));

const TabBar = ({ tabs, buttons = [], onChange, index, handleClick, type }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(index);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (typeof onChange === "function") onChange(newValue);
  };

  React.useEffect(() => {
    setValue(index);
  }, [index]);

  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        color="default"
        classes={
          type === "secondary"
            ? {
                root: classes.appbarTransparentRoot,
              }
            : {
                root: classes.appbarRoot,
              }
        }
      >
        <Tabs
          value={value}
          onChange={handleChange}
          onClick={handleClick}
          indicatorColor="primary"
          textColor="primary"
          classes={{
            root: classes.tabsRoot,
            indicator: classes.tabsIndicator,
          }}
        >
          {tabs.map((tab, key) => (
            <Tab
              key={key}
              label={tab?.label || tab}
              wrapped
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
          ))}
        </Tabs>
        <Box className={classes.buttonGroup}>
          {buttons.map((button, index) => (
            <Box key={button.index} className={classes.buttonContainer}>
              <Button
                className={button.active ? classes.activeButton : classes.button}
                variant="tertiary"
                state={button.active}
                onClick={button.clickHandler}
              >
                {button.label}
              </Button>
              {index !== buttons.length - 1 && <Divider orientation="vertical" />}
            </Box>
          ))}
        </Box>
      </AppBar>
    </div>
  );
};

TabBar.propTypes = {
  tabs: PropTypes.array,
  buttons: PropTypes.array,
  onChange: PropTypes.func,
};

export default TabBar;
