/* eslint-disable import/no-anonymous-default-export */
import React, { useState } from "react";
import { Route, Switch } from "react-router-dom";
import Amplify, { Auth } from "aws-amplify";
import Loader from "storybookComponents/Loader";
import { Provider as BusProvider } from "react-bus";
import {
  getPropertyId,
  setProperty,
  setPropertyIdLocalStorage,
  getUserEmail,
  shortExpiration,
} from "common";
import { useCustomEventListener } from "react-custom-events";
import { EVENT_CODES } from "constants/index";
import UnAuthorizedUserPage from "./public-routes/unauthorized";
import BlastPreview from "./public-routes/BlastPreview";
import DripPreview from "./public-routes/DripPreview";
import AlertPreview from "./public-routes/AlertPreview";
import OfferPreview from "./public-routes/OfferPreview";
import EnrollPage from "public-routes/enroll";
import ReferPage from "public-routes/refer";
import ResidentialPortalPage from "public-routes/residential-portal";
import ResidentialBannerPage from "public-routes/residential-banner";
import { getDefaultOrg, getProfile, getSelectedSuborgDetails } from "relay/common";
import "bootstrap/dist/css/bootstrap.min.css";
import "./icons/style.css";
import cookieStorage from "common/localStorage";
import Application from "./Application";
import { setSuborgDetails, setStates4User } from "redux/slices";
import { useDispatch } from "react-redux";
import ToastMessage from "storybookComponents/Toast";
import { SnackbarProvider } from "notistack";

const authConfig = {
  Auth: {
    region: process.env.REACT_APP_Region,
    userPoolId: process.env.REACT_APP_UserPool,
    userPoolWebClientId: process.env.REACT_APP_ClientId,
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL,
    authenticationFlowType: "USER_PASSWORD_AUTH",
    cookieStorage: {
      domain: process.env.REACT_APP_domain,
      path: "/",
      expires: 7,
      secure: process.env.NODE_ENV === "production",
    },
    storage: cookieStorage,
    oauth: {
      domain: process.env.REACT_APP_COGNITO_AUTH_DOMAIN,
      redirectSignIn: process.env.REACT_APP_SAML_REDIRECT_SIGN_IN,
      redirectSignOut: process.env.REACT_APP_SAML_REDIRECT_SIGN_OUT,
      responseType: "code",
    },
  },
};

//Amplify.Logger.LOG_LEVEL = "DEBUG";
Amplify.configure(authConfig);

const getAuthState = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    console.log(`qa: getAuthState: ${JSON.stringify(user)}`);
    return user;
  } catch (err) {
    console.warn("getAuthState error: ", err.message || err);
    return undefined;
  }
};

const accountsLinkAI = process.env.REACT_APP_ACCOUNTS_LINK_AI;
const accountsLink = process.env.REACT_APP_ACCOUNTS_LINK;

const App = (props) => {
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({});
  const [subOrgData, setSubOrgData] = useState({});
  const dispatch = useDispatch();

  let currentInterval = null;
  const refreshCurrentToken = async () => {
    const user = await Auth.currentAuthenticatedUser();
    await user.refreshSession(user.signInUserSession.refreshToken, (err, session) => {
      // console.log("session refreshed", session);
      user.setSignInUserSession(session);
      // const mainUser = await Auth.currentAuthenticatedUser();
      let userDetails = getNameEmail(user);
      if (userDetails) {
        user.username = userDetails.email;
        user.name = userDetails.name || userDetails.email;
      }
      setUserData(user);
      // dispatch(action_addUser({ user: user }));

      // console.log("User updated in Context", user);
    });
  };
  const runIntervalToRefreshAccessToken = () => {
    if (window.location.hostname === "localhost") {
      return;
    }
    currentInterval = setInterval(async () => {
      const date = new Date();
      let day = date.getHours() % 12;
      let month = date.getMinutes() + 1;
      let year = date.getSeconds();
      let currentDate = `${day}:${month}:${year} ${day ? "PM" : "AM"}`;
      console.log("Time", currentDate);
      await refreshCurrentToken();
    }, 4.5 * 60 * 1000); // 10 * 60 * 1000
    // setCurrentInterval(currentInterval);
  };

  const onFocusListener = async () => {
    if (window.location.hostname === "localhost") {
      return null;
    }
    await refreshCurrentToken();
    clearInterval(currentInterval);
    currentInterval = null;
    runIntervalToRefreshAccessToken();
  };

  React.useEffect(() => {
    runIntervalToRefreshAccessToken();
    window.addEventListener("focus", onFocusListener);
    return () => window.removeEventListener("focus", onFocusListener);
  }, []);

  const getNameEmail = (user) => {
    if (user && user.attributes) {
      if (user.attributes.email || user.attributes.name) {
        return {
          email: user.attributes.email,
          name: user.attributes.name,
        };
      } else if (user.attributes.identities) {
        let identities = JSON.parse(user.attributes.identities);
        return {
          email: identities[0].userId,
          name: identities[0].userId,
        };
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  React.useEffect(() => {
    if (window.location.hostname === "localhost") {
      loadProperty("cxp@hy.ly");
      return;
    }
    loadUser();
  }, []);

  const removeCookies = () => {
    const loginLoop = Number(cookieStorage.getItem("login_loop"));
    if (loginLoop >= 1) {
      cookieStorage.removeItem("login-redirect");
      cookieStorage.removeItem("login_loop");
    }
  };

  const loadUser = async () => {
    try {
      let user = await getAuthState();
      removeCookies();
      const loginRedirected = cookieStorage.getItem("login-redirect");
      let userDetails = getNameEmail(user);
      if (userDetails) {
        user.username = userDetails.email;
        user.name = userDetails.name || userDetails.email;
      }
      setUserData(user);
      if (user && userDetails && userDetails.email) {
        cookieStorage.removeItem("login-redirect");
        cookieStorage.removeItem("login_loop");
        cookieStorage.setItem("user-authenticated", 1, shortExpiration());
        loadProperty(userDetails.email);
      } else {
        if (loginRedirected) {
          window.location.href = `${accountsLink}?goto=journey-ai`;
          cookieStorage.setItem("login_loop", 1, shortExpiration());
          return;
        } else {
          cookieStorage.setItem("login-redirect", 1, shortExpiration());
          cookieStorage.setItem("login_loop", 0, shortExpiration());
          Auth.federatedSignIn({ provider: process.env.REACT_APP_SAML_PROVIDER });
        }
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const getOrgData = async (userEmail) => {
    await window.localStorage.clear();
    let defaultOrg = await getDefaultOrg();
    if (defaultOrg.authorizedOrgs && defaultOrg.authorizedOrgs.length) {
      let org = defaultOrg.authorizedOrgs[0];
      let property = org.suborgs[0];
      setProperty({
        ...property,
        orgid: org.id,
        userEmail: userEmail,
        userRole: org.userRole,
      });
    }
  };

  const getProfileDetails = async () => {
    try {
      let response = await getProfile();
      if (response && response.profile) {
        const { email, firstName, initials, lastName, avatarUrl } = response.profile;
        let fullName = `${firstName}${lastName ? " " + lastName : ""}`;
        dispatch(
          setStates4User({
            email,
            initialsOfName: initials,
            avatarUrl: avatarUrl,
            fullName,
          })
        );
      }
    } catch (err) {
      console.log("Err: ", err);
    }
  };

  React.useEffect(() => {
    getProfileDetails();
  }, [1]);

  const loadProperty = async (userEmail) => {
    setLoading(true);
    let propertyId = getPropertyId(true);
    let _userEmail = getUserEmail();
    if ((!propertyId && propertyId.length !== 36) || userEmail !== _userEmail) {
      await getOrgData(userEmail);
    } else {
      try {
        let subOrgDetails = await getSelectedSuborgDetails(propertyId);
        setSubOrgData(subOrgDetails);
        if (
          subOrgDetails &&
          subOrgDetails.authorizedSuborgs &&
          subOrgDetails.authorizedSuborgs[0]
        ) {
          setPropertyIdLocalStorage(propertyId);
          localStorage.setItem(
            "orgLevel",
            propertyId === subOrgDetails.authorizedSuborgs[0].org.id
          );
          dispatch(
            setSuborgDetails({
              ...subOrgDetails.authorizedSuborgs[0],
              orgLevel: propertyId === subOrgDetails.authorizedSuborgs[0].org.id,
            })
          );
        } else {
          await getOrgData(userEmail);
        }
      } catch (err) {
        console.log("Err", err);
      }
    }
    setLoading(false);
  };

  const handleUnAuth = (appName) => {
    window.location.href = "/unauthorized/" + appName;
  };

  useCustomEventListener(EVENT_CODES.UNAUTH_USER, handleUnAuth);

  if (window.location.hostname === "localhost") {
    return loading ? (
      <Loader />
    ) : (
      <Application
        user={{
          attributes: {
            email: "cxp@hy.ly",
            email_verified: true,
            name: "IQ Admin",
            sub: "14d603dd-5bc8-4eee-beed-14bd827b6578",
          },
          username: "cxp@hy.ly",
          name: "IQ Admin",
        }}
        subOrgData={subOrgData}
        {...props}
      />
    );
  }

  return loading ? <Loader /> : <Application user={userData} subOrgData={subOrgData} {...props} />;
};

export default () => {
  return (
    <BusProvider>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        content={(key, obj) => (
          <div id={key}>
            <ToastMessage
              message={obj.message}
              variant={obj.type}
              open={obj.open}
              unDoAvailable={obj.unDoAvailable}
              unDoCallback={obj.unDoCallback}
              id={key}
            />
          </div>
        )}
      >
        <Switch>
          <Route path="/residential-portal/:campaignId" component={ResidentialPortalPage} />
          <Route path="/residential-banner/:campaignId" component={ResidentialBannerPage} />
          <Route path="/enroll/:campaignId" component={EnrollPage} />
          <Route path="/refer/:campaignId/:enrollId" component={ReferPage} />
          <Route path="/unauthorized/:appName" component={UnAuthorizedUserPage} />
          <Route path="/:propertyId/offers/:id/:view" component={OfferPreview} />
          <Route path="/:propertyId/blast/:id/preview" component={BlastPreview} />
          <Route path="/:propertyId/drip/:angelId/:emailId/preview" component={DripPreview} />
          <Route path="/:propertyId/alert/:angelId/:emailId/preview" component={AlertPreview} />
          <Route path="/" component={App} />
        </Switch>
      </SnackbarProvider>
    </BusProvider>
  );
};
