import React from "react";
import withStyles from "@mui/styles/withStyles";
import Dialog from "@mui/material/Dialog";
import MuiDialogTitle from "@mui/material/DialogTitle";
import MuiDialogContent from "@mui/material/DialogContent";
import MuiDialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ClosePopupIcon from "components/Icons/ClosePopupIcon";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: 20,
  },
  closeButton: {
    position: "absolute",
    right: 10,
    top: 10,
    color: "#000",
  },
  dialogRoot: {
    borderRadius: 0,
    width: 580,
    background: "white",
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle className={classes.root} {...other}>
      <div className="font-medium font-open-sans">{children}</div>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: "16px 20px",
    borderTop: "1px solid #dadada",
    overflow: "hidden",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: "24px 20px",
  },
}))(MuiDialogActions);

const CustomizedModal = withStyles(styles)(
  ({
    open,
    maxWidth = "md",
    title = "Modal Title",
    children,
    onClose,
    actionSection,
    classes,
    reset,
  }) => {
    return (
      <Dialog
        onClose={onClose}
        open={open}
        maxWidth={maxWidth}
        fullWidth
        PaperProps={{
          classes: { rounded: classes.dialogRoot },
        }}
      >
        {title && (
          <div className="flex justify-between items-center">
            <DialogTitle id="customized-dialog-title" onClose={onClose}>
              {title}
            </DialogTitle>
            {reset && (
              <span onClick={onClose} className="pr-[24px] cursor-pointer">
                <ClosePopupIcon />
              </span>
            )}
          </div>
        )}
        <DialogContent>{children}</DialogContent>
        <DialogActions>{actionSection}</DialogActions>
      </Dialog>
    );
  }
);

export default CustomizedModal;
