import graphql from "babel-plugin-relay/macro";

export default graphql`
  mutation uploadHayleyAssetMutation($input: uploadHayleyAssetInput!) {
    uploadHayleyAsset(input: $input) {
      asset {
        id
        name
        fileUrl
        fileSize
        fileType
        thumbnailUrl
        createdAt
        dimension
      }
    }
  }
`;
