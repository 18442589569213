import React from "react";

export default function MoveLeftIcon({ className = "text-[#999999]", size = 16 }) {
  return (
    <svg
      width={size}
      className={className}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 11L3 8L6 5"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M3.5 8H10.5" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M10.5 13.5V2.5"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
