/**
 * @flow
 * @relayHash 443d77bb76a13c447c950ca440478dd5
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type NavDrawerQueryVariables = {|
  id?: ?string
|};
export type NavDrawerQueryResponse = {|
  +authorizedSuborgs: $ReadOnlyArray<{|
    +id: string,
    +name: string,
    +region: ?string,
    +org: {|
      +id: string,
      +name: string,
    |},
  |}>
|};
export type NavDrawerQuery = {|
  variables: NavDrawerQueryVariables,
  response: NavDrawerQueryResponse,
|};
*/


/*
query NavDrawerQuery(
  $id: String
) {
  authorizedSuborgs(orgId: "any", first: 1, propertyId: $id) {
    id
    name
    region
    org {
      id
      name
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "String",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "authorizedSuborgs",
    "storageKey": null,
    "args": [
      {
        "kind": "Literal",
        "name": "first",
        "value": 1
      },
      {
        "kind": "Literal",
        "name": "orgId",
        "value": "any"
      },
      {
        "kind": "Variable",
        "name": "propertyId",
        "variableName": "id"
      }
    ],
    "concreteType": "Suborg",
    "plural": true,
    "selections": [
      (v1/*: any*/),
      (v2/*: any*/),
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "region",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/)
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "NavDrawerQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "NavDrawerQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "NavDrawerQuery",
    "id": null,
    "text": "query NavDrawerQuery(\n  $id: String\n) {\n  authorizedSuborgs(orgId: \"any\", first: 1, propertyId: $id) {\n    id\n    name\n    region\n    org {\n      id\n      name\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '43c8d387454ed25d0f0b9cf1f9cfdb49';
module.exports = node;
