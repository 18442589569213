import React from "react";
import embedCode from "content/hyRaf/Campaign/OmnichannelPreview/components/enrollForm/embeded-code";
import { loadEmailPreviews } from "content/hyRaf/Campaign/OmnichannelPreview/utils";
import Loader from "storybookComponents/Loader";
import inaccessiblePage from "./inaccessiblePage";
import { addUTMScript } from "../utils";
// eslint-disable-next-line
export default (props) => {
  const [code, setCode] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const init = async () => {
    let params = new URLSearchParams(props.location.search);
    let id = props.match.params.campaignId;
    if (!id) return;
    setLoading(true);
    let campaignData = await loadEmailPreviews(id, true);
    console.log("campaignData", campaignData.active);
    if (campaignData) {
      if (campaignData.active) {
        addUTMScript(campaignData.noscript, campaignData.script);
        let orgColors = {
          linkColor: campaignData.linkColor,
          fontFamily: campaignData.fontFamily,
          textColor: campaignData.textColor,
          buttonBorderColor: campaignData.buttonBorderColor,
          buttonFontColor: campaignData.buttonFontColor,
          buttonBackgroundColor: campaignData.buttonBackgroundColor,
        };
        localStorage.setItem("residentInviteSms", campaignData.residentInviteSms);
        setCode(
          embedCode({
            ...campaignData,
            endpoint: process.env.REACT_APP_MyhylyEndpoint,
            id,
            enrollPath: true,
            orgColors,
            preEmail: params.get("resident_email"),
          })
        );
      } else {
        setCode(inaccessiblePage());
      }
    } else {
      alert("Something went wrong");
    }
    setLoading(false);
  };

  React.useEffect(() => {
    init();
  }, []);

  return (
    <>
      {loading && <Loader />}
      {code ? (
        // eslint-disable-next-line
        <iframe srcDoc={code} style={{ width: "100%", height: "100vh" }} />
      ) : null}
    </>
  );
};
