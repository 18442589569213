import graphql from "babel-plugin-relay/macro";

export default graphql`
  query gethyblastsQuery(
    $propertyId: ID!
    $startDate: String
    $endDate: String
    $status: String
    $name: String
    $first: Int
    $after: String
    $approvalStatus: String
    $mailGpt: Boolean
  ) {
    hyblasts(
      propertyId: $propertyId
      startDate: $startDate
      endDate: $endDate
      status: $status
      name: $name
      after: $after
      first: $first
      approvalStatus: $approvalStatus
      mailGpt: $mailGpt
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          name
          subject
          status
          smallThumbnailUrl
          largeThumbnailUrl
          originalThumbnailUrl
          approvalStatus
          sendsCount
          sentGroupCount
          sentGroup
          scheduledTime
          sentTime
          uniqueOpensCount
          createdAt
          updatedAt
          purpose
          mailGpt
        }
      }
    }
  }
`;
