import React from "react";
import PropTypes from "prop-types";
import { createFragmentContainer, QueryRenderer } from "react-relay";
import graphql from "babel-plugin-relay/macro";

import LocalContainer from "./LocalContainer";

export default createFragmentContainer(LocalContainer, {
  suggestions: graphql`
    fragment LocalContainer_suggestions on PicklistInterface @relay(plural: true) {
      id
      name
      ...List_suggestions
      ...SimpleList_suggestions
    }
  `,
});
