import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import EmptyOffer from "images/emptyOffer.svg";
import SearchIcon from "images/searchIcon.png";
import Button from "storybookComponents/Button";

import { styles } from "./styles";

const useStyles = makeStyles(styles);

const NoEvent = ({ wrapperClassName = "", icon = "", title1, title2, buttonText, handleClick }) => {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      className={wrapperClassName ? wrapperClassName : classes.noPropertyCard}
    >
      {icon === "" ? (
        <img src={EmptyOffer} alt="empty" />
      ) : (
        <img src={SearchIcon} alt="empty" className={classes.searchIcon} />
      )}
      <Typography className={classes.subTitle1}>{title1}</Typography>
      <Typography className={classes.subTitle2}>{title2}</Typography>
      {handleClick && (
        <Button
          color="hylyBlue"
          variant="primary"
          size="md"
          onClick={handleClick}
          style={{ textTransform: "none", marginTop: "24px" }}
        >
          {buttonText}
        </Button>
      )}
    </Box>
  );
};

export default NoEvent;
