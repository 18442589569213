import graphql from "babel-plugin-relay/macro";

export default graphql`
  query getSummaryReportsQuery(
    $orgId: ID!
    $type: String!
    $startDate: String!
    $endDate: String!
    $propertyIds: [ID!]
    $regionIds: [String!]
  ) {
    summaryReports(
      orgId: $orgId
      type: $type
      startDate: $startDate
      endDate: $endDate
      propertyIds: $propertyIds
      regionIds: $regionIds
    ) {
      id
      sendsCount
      uniqueOpensRate
      uniqueClicksRate
      bouncesRate
      unsubscribesRate
      propertyEmailReports {
        id
        name
        sendsCount
        uniqueOpensCount
      }
      thenEmailReports {
        id
        property {
          id
          name
        }
        angel {
          id
          name
        }
        sendsCount
        uniqueOpensCount
        uniqueClicksCount
      }
    }
  }
`;
