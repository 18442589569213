import React from "react";

export default function AlignCenterHorizontalIcon({ size = 24, className = "text-[#333333]" }) {
  return (
    <svg
      className={className}
      width={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="1">
        <path
          d="M12.375 17.625V20.625"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M12.375 10.875V13.875"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M12.375 4.125V7.125"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M17.625 13.875H7.125V17.625H17.625V13.875Z"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M15.375 7.125H9.375V10.875H15.375V7.125Z"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
      </g>
    </svg>
  );
}
