import graphql from "babel-plugin-relay/macro";

export default graphql`
  query getHayleyQualificationQuery($tenantId: ID!) {
    hayleyQualifierButton(tenantId: $tenantId) {
      qualificationButton
      qualificationButtonEnabled
    }
  }
`;
