import graphql from "babel-plugin-relay/macro";

export default graphql`
  query hyrafSettingQuery($orgId: ID!, $propertyId: ID) {
    hyrafSetting(orgId: $orgId, propertyId: $propertyId) {
      hyrafSetting
      id
      tourSchedulerUrl
      formFields
    }
  }
`;
