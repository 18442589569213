/**
 * @flow
 * @relayHash 162d456d90ee7e5d1a4ce1f6ddda5283
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getAngelsOrgQueryVariables = {|
  orgId?: ?string,
  angelType?: ?string,
|};
export type getAngelsOrgQueryResponse = {|
  +angels: $ReadOnlyArray<{|
    +id: string,
    +name: ?string,
    +active: ?boolean,
    +createdAt: ?any,
    +property: ?{|
      +name: string
    |},
    +thenEmails: ?$ReadOnlyArray<{|
      +id: string,
      +name: string,
      +active: boolean,
      +createdAt: any,
      +triggerDays: number,
      +originalThumbnailUrl: ?string,
      +whenGroupName: ?string,
      +tourType: ?string,
    |}>,
  |}>
|};
export type getAngelsOrgQuery = {|
  variables: getAngelsOrgQueryVariables,
  response: getAngelsOrgQueryResponse,
|};
*/


/*
query getAngelsOrgQuery(
  $orgId: ID
  $angelType: String
) {
  angels(orgId: $orgId, angelType: $angelType) {
    id
    name
    active
    createdAt
    property {
      name
      id
    }
    thenEmails {
      id
      name
      active
      createdAt
      triggerDays
      originalThumbnailUrl
      whenGroupName
      tourType
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "orgId",
    "type": "ID",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "angelType",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "angelType",
    "variableName": "angelType"
  },
  {
    "kind": "Variable",
    "name": "orgId",
    "variableName": "orgId"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "active",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "createdAt",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "thenEmails",
  "storageKey": null,
  "args": null,
  "concreteType": "ThenEmail",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/),
    (v4/*: any*/),
    (v5/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "triggerDays",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "originalThumbnailUrl",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "whenGroupName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "tourType",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getAngelsOrgQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "angels",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "Angel",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "property",
            "storageKey": null,
            "args": null,
            "concreteType": "Suborg",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ]
          },
          (v6/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "getAngelsOrgQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "angels",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "Angel",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "property",
            "storageKey": null,
            "args": null,
            "concreteType": "Suborg",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v2/*: any*/)
            ]
          },
          (v6/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "getAngelsOrgQuery",
    "id": null,
    "text": "query getAngelsOrgQuery(\n  $orgId: ID\n  $angelType: String\n) {\n  angels(orgId: $orgId, angelType: $angelType) {\n    id\n    name\n    active\n    createdAt\n    property {\n      name\n      id\n    }\n    thenEmails {\n      id\n      name\n      active\n      createdAt\n      triggerDays\n      originalThumbnailUrl\n      whenGroupName\n      tourType\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '608190d851bc21510a74631dff5084a9';
module.exports = node;
