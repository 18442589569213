export const styles = (theme) => ({
  noPropertyCard: {
    flex: 1,
    flexFlow: "column",
    // background: "#FFFFFF",
    // border: "1px solid #DADADA",
    boxSizing: "border-box",
    margin: "20px",
    height: "100%",
  },
  subTitle1: {
    fontSize: "20px",
    lineHeight: "30px",
    color: "#333333",
    marginTop: "20px",
  },
  subTitle2: {
    fontSize: "14px",
    lineHeight: "20px",
    color: "#333333C0",
    marginTop: "12px",
  },
  searchIcon: {
    width: 56,
    height: 56,
  },
});
