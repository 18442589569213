import { configureStore } from "@reduxjs/toolkit";
import eventReducer from "./slices/event-slice";
import blastReducer from "./slices/blast-slice";
import successCenterReducer from "./slices/success-center-slice";
import rafReducer from "./slices/raf-slice";
import JourneyFreehandReducer from "./slices/journeyFreehandSlice";
import {
  showFavoriteReducer,
  favoriteReportReducer,
  dripReducer,
  orgSuborgDetailsReducer,
  userDetailsReducer,
  expressionsReducer,
  mJAIReducer,
  hayleyReducer,
  showHeaderFavoriteReducer,
  tableSortReducer,
  successReducer,
  accountListReducer,
} from "./slices";
import BlastGptSlice from "./slices/blast-gptPrompt";
import BlastRegenerateSlice from "./slices/blast-gptRegenerateData";
import orgsPropertiesReducer from "./slices/Orgs-properties-slice";
import ai_WorkshopSlice from "./slices/aiWorkShopSlice";

export const store = configureStore({
  reducer: {
    event: eventReducer,
    blast: blastReducer,
    drip: dripReducer,
    raf: rafReducer,
    success_center: successCenterReducer,
    showFavorite: showFavoriteReducer,
    favoriteReport: favoriteReportReducer,
    orgSuborg: orgSuborgDetailsReducer,
    user: userDetailsReducer,
    expressions: expressionsReducer,
    journeyFreehand: JourneyFreehandReducer,
    mJAI: mJAIReducer,
    ai_workshop: ai_WorkshopSlice,
    gptPrompt: BlastGptSlice,
    gptRegenrateText: BlastRegenerateSlice,
    hayley: hayleyReducer,
    OrgProperties: orgsPropertiesReducer,
    showHeaderFavorite: showHeaderFavoriteReducer,
    tableSort: tableSortReducer,
    success: successReducer,
    accountList: accountListReducer,
  },
});
