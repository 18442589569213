import React from "react";
import Select from "@mui/material/Select";
import makeStyles from "@mui/styles/makeStyles";
import MenuItem from "@mui/material/MenuItem";
import clsx from "clsx";

const useStyles = makeStyles({
  super: {
    "&:hover div": {
      color: "var(--hylyTheme)",
    },
    "&:hover path": {
      fill: "var(--hylyTheme)",
    },
  },
  root: (props) => ({
    paddingLeft: 10,
    minWidth: 80,
    paddingRight: "0px important",
    border: "1px solid #dadada",
    background: "white",
    "& span": {
      "&::before": {
        content: "",
        color: "rgba(51, 51, 51, 0.5)",
        fontSize: 14,
      },
    },
    "&:focus": {
      background: "white",
    },
    "&:hover": {
      border: "1px solid var(--hylyTheme)",
    },
  }),
  list: {
    padding: 0,
    maxHeight: 350,
    overflowY: "auto",
  },
  menu: {
    padding: "10px 12px",
    cursor: "pointer",
    "&:hover": {
      color: "var(--hylyTheme)",
      background: "white",
    },
  },
  paper: (props) => ({
    boxShadow: "0px 0px 30px 2px rgba(0, 0, 0, 0.05) !important",
    border: "1px solid #eeeeee",
    background: "white",
    width: "auto",
    minWidth: "auto !important",
    padding: 0,
    borderRadius: 5,
  }),
  icon: {
    fill: "#333333",
  },
  hightlight: {
    fontWeight: 600,
    backgroundColor: "white",
    "&:hover ": {
      backgroundColor: "white!important",
    },
  },
});

const SelectComponent = ({ value, options, onChange, className }) => {
  const [selectedValue, setSelectedValue] = React.useState(value);
  const classes = useStyles();
  React.useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  const handleChange = (e) => {
    setSelectedValue(e.target.value);
    if (typeof onChange === "function") {
      onChange(e.target.value);
    }
  };

  const renderField = (value, index) => {
    return (
      <MenuItem
        key={index}
        value={value}
        className={clsx(
          classes.menu,
          className && className.select_menu,
          value == selectedValue ? classes.hightlight : ""
        )}
      >
        {value.label}
      </MenuItem>
    );
  };

  return (
    <div className={classes.super}>
      <Select
        id="demo-simple-select-hycxp"
        value={selectedValue}
        onChange={handleChange}
        classes={{
          select: clsx(classes.root, className && className.select_root),
          icon: clsx(classes.icon, className && className.select_icon),
        }}
        variant="standard"
        disableUnderline={true}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
          classes: {
            list: clsx(classes.list, className && className.select_list),
            paper: clsx(classes.paper, className && className.select_paper),
          },
        }}
      >
        {options && options.map(renderField)}
      </Select>
    </div>
  );
};

export default SelectComponent;
