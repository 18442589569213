import { fetchQuery } from "relay-runtime";
import { environment } from "environments";
import getReportsStat from "../queries/getReportsStat";
import getChartsSeries from "../queries/getChartsSeries";

const getHayleyReportStat = (params) => {
  let variables = { ...params };

  return fetchQuery(environment, getReportsStat, variables)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

const getHayleyChartSeries = (params) => {
  let variables = { ...params };
  return fetchQuery(environment, getChartsSeries, variables)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

export { getHayleyReportStat, getHayleyChartSeries };
