import React from "react";
import withStyles from "@mui/styles/withStyles";
import Popover from "@mui/material/Popover";

const styles = (theme) => ({
  arrowDiv: {
    position: "absolute",
    top: "18px",
    right: 5,
    height: 10,
    width: 10,
    transform: "rotate(45deg)",
    background: "white",
    borderTop: "1px solid #EEEEEE",
    borderRight: "1px solid #EEEEEE",
  },
  dotDisable: {
    visibility: "hidden",
  },
});

const PopoverComponent = ({
  classes: { dotDisable, arrowDiv },
  children,
  icon,
  dotId,
  setDotId,
}) => {
  const [anchorEl, setAnchor] = React.useState(null);
  const handleArrowRef = (event) => {
    if (!anchorEl) {
      setDotId("id");
      setAnchor(event.currentTarget);
      return false;
    } else {
      handleClose();
      return true;
    }
  };
  const handleClose = () => {
    setDotId("interacted");
    setAnchor(null);
  };

  return (
    <>
      <div
        name="icon-container"
        id={dotId}
        className={`${dotId === "interacted" && dotDisable}`}
        onClick={handleArrowRef}
      >
        {icon}
      </div>
      <Popover
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorEl={anchorEl}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: 150,
            minHeight: 0,
            background: "#ffffff00",
            border: "none",
            boxShadow: "none",
          },
        }}
      >
        <div>
          <div className={arrowDiv} />
          {typeof children === "function" ? children(handleClose) : null}
        </div>
      </Popover>
    </>
  );
};

export default React.memo(withStyles(styles)(PopoverComponent));
