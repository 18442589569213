import React from "react";

import StyledTableRow from "../StyledTableRow";
import StyledTableCell from "../StyledTableCell";

const SubRow = ({ columns, data, hasSubRows, index }) => {
  return (
    <StyledTableRow
      className={`table-row`}
      style={index == 0 && hasSubRows ? { fontWeight: 600 } : { fontWeight: 400 }}
    >
      {!hasSubRows && <StyledTableCell scope="row" align="center" />}
      {columns.map((item, idx) => (
        <StyledTableCell
          scope="row"
          key={idx}
          align={item.align || "left"}
          className={item.cellClassName}
          style={
            hasSubRows
              ? {
                  background: "rgba(217, 217, 217, 0.2)",
                  fontSize: "14px",
                  color: "rgba(51, 51, 51, 0.5)",
                }
              : {}
          }
        >
          {data[item.field]}
        </StyledTableCell>
      ))}
    </StyledTableRow>
  );
};

export default SubRow;
