import graphql from "babel-plugin-relay/macro";

export default graphql`
  query getProductItemsQuery($tenantId: ID!, $productName: String!) {
    productItems(tenantId: $tenantId, productName: $productName) {
      name
      mount
    }
  }
`;
