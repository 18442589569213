import React from "react";

export default function hyMailIcon({ width = 18, height = 18, className = "text-[#999999]" }) {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.46143 4.49789L9.00009 9.53309L14.5388 4.49789"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.8 4.19998H4.19997C3.61086 4.19998 3.1333 4.67754 3.1333 5.26665V12.7333C3.1333 13.3224 3.61086 13.8 4.19997 13.8H13.8C14.3891 13.8 14.8666 13.3224 14.8666 12.7333V5.26665C14.8666 4.67754 14.3891 4.19998 13.8 4.19998Z"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
