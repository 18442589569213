import React from "react";

export default function HomeSearchIcon({ className = "text-[#999999]", width = 18, height = 18 }) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 9.55566V15.1112H9"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3668 7.47156L8.44461 3.44434L2.3335 8.44434"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <g clip-path="url(#clip0_1703_34851)">
        <path
          d="M14.417 14.9712L12.6548 13.209"
          stroke="currentColor"
          stroke-width="0.75"
          stroke-linecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.0836 13.8605C12.3108 13.8605 13.3058 12.8655 13.3058 11.6382C13.3058 10.4109 12.3108 9.41602 11.0836 9.41602C9.85625 9.41602 8.86133 10.4109 8.86133 11.6382C8.86133 12.8655 9.85625 13.8605 11.0836 13.8605Z"
          stroke="currentColor"
          stroke-width="0.75"
          stroke-linecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1703_34851">
          <rect
            width="6.66667"
            height="6.66667"
            fill="white"
            transform="translate(8.16699 8.72168)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
