import graphql from "babel-plugin-relay/macro";

export default graphql`
  query getCampaignQuery($id: ID!) {
    hyreferralCampaign(id: $id) {
      id
      enrollUrl
      propertyId
      title
      reward
      emailSubject
      emailTitle
      disclaimer
      residentThankYouEmailDisclaimer
      landingPageEmailDisclaimer
      residentInviteEmailDisclaimer
      emailBody
      emailSms
      ctaDesign
      ctaText
      bannerText
      pitchMessage
      residentEnrollHeader
      residentEnrollFooter
      emailEnabled
      smsEnabled
      facebookEnabled
      twitterEnabled
      residentInviteEmailSubject
      residentInviteEmailBody
      residentInviteSms
      residentInviteSocialContent
      residentDiscountEmailSubject
      residentDiscountEmailBody
      residentDiscountEmailSms
      tourCtaText
      tourCtaUrl
      contactCtaText
      contactCtaUrl
      active
      inviteScheduleAt
      image {
        thumbnailUrl
      }
      residentInviteImage {
        thumbnailUrl
      }

      residentDiscountImage {
        thumbnailUrl
      }
      shortEnrollUrl
      abortResidentInviteCampaignId
      shortTourCtaUrl
      propertyName
      rewardEmailHeader
      friendInviteEmailHeader
      friendInviteMenuHeader
      friendInviteMenuBody
      residentBannerTitle
      popupMessage
      popupTitle
      residentInviteCampaignTested
      residentThankYouEmailSubject
      residentThankYouEmailTitle
      residentThankYouEmailBody
      residentThankYouImage {
        thumbnailUrl
      }
      facebookShareUrl
      landingPageContent
      landingPageTitle
      landingPageImage {
        thumbnailUrl
      }
      emailValidationStatus
      inviteAlreadySent
      aborted
      isQuarterly
      quarterlyEmailDay
      quarterlyEmailTime
      inviteScheduleHistory
      contactUsFormHtml
    }
  }
`;
