import React from "react";
import { fetchQuery } from "relay-runtime";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Drawer from "@mui/material/Drawer";
import Hidden from "@mui/material/Hidden";
import NavContent from "./NavContent";
import useMediaQuery from "@mui/material/useMediaQuery";
import initialValuesQuery from "./initialValuesQuery";
import { setStates4OrgSuborg, setPropertyCount } from "redux/slices";
import { useDispatch } from "react-redux";
import _ from "lodash";
import { handleResize, isBool } from "./utils";

// const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

const drawer_width = 300;

const styles = makeStyles((theme) => {
  return {
    drawer: {
      width: drawer_width,
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    drawerOpen: {
      width: drawer_width,
      transition: theme.transitions.create(["width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create(["width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: 78,
      border: "none",
      borderRight: "1px solid #EEE",
    },
    container: {
      width: `calc(100% - 78px)`,
      height: "100%",
      margin: 0,
      marginLeft: 78,
      padding: 16,

      minHeight: "100vh",
      background: theme.palette.whiteGrey,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    container_open: {
      marginLeft: drawer_width,
      width: `calc(100% - ${drawer_width}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    screen: { position: "relative", height: "100vh" },
  };
});

const NavDrawer = ({
  children,
  open: controlled_drawer_value,
  onSelectOrg,
  onSelectProperty,
  initialValue,
  initialIDValue,
  setOriginNavItems,
  isRentOrg,
  ...props
}) => {
  const classes = styles();
  const { drawer, drawerClose, drawerOpen, container, container_open, screen } = classes;
  const matches = useMediaQuery("(min-width:1280px)");
  const [selected_org, setOrg] = React.useState();
  const [orgsList, setOrgs] = React.useState([]);
  const [selected_property, setProperty] = React.useState({});
  const [state, set] = React.useState(handleResize(matches, controlled_drawer_value));
  const { big_menu_open, small_menu_open } = state;
  const dispatch = useDispatch();
  const is_controlled = React.useMemo(
    () => isBool(controlled_drawer_value),
    [controlled_drawer_value]
  );
  async function setListOfOrgs(org) {
    const { authorizedOrgs } = await fetchQuery(props.environment, initialValuesQuery, {
      name: "",
      first: 1000,
    });
    const selectedOrg = authorizedOrgs.filter((d) => d.id == org.id);
    const propertyCount = selectedOrg[0].suborgs.length;
    dispatch(setPropertyCount(propertyCount));
    setOrgs(authorizedOrgs);
    dispatch(setStates4OrgSuborg(authorizedOrgs));
  }
  //set the default value
  React.useEffect(() => {
    const { org, ...selected_suborg } = initialValue;
    setOrg(org);
    if (selected_suborg.id === initialIDValue) {
      setProperty(selected_suborg);
    }
    setListOfOrgs(org);
  }, [initialIDValue, initialValue]);

  React.useEffect(() => {
    if (props.orgLevel) {
      setProperty({});
    } else {
      if (props.property.id !== initialIDValue) {
        setProperty(props.property);
      } else {
        const { org, ...selected_suborg } = initialValue;
        setProperty(selected_suborg);
      }
    }
  }, [props.orgLevel]);

  //------------------------------resize - toggle nav------------------------------ //

  const toggleDrawer = React.useCallback(() => {
    if (!is_controlled) {
      const drawer = matches ? "big_menu_open" : "small_menu_open";
      set({ ...state, [drawer]: !state[drawer] });
      localStorage.setItem("drawer", !state[drawer]);
    }
  }, [matches, is_controlled, state]);

  React.useEffect(() => {
    if (state.matches !== matches) {
      set(handleResize(matches, controlled_drawer_value));
    } else if (is_controlled) {
      const drawer = matches ? "big_menu_open" : "small_menu_open";
      set({ ...state, [drawer]: controlled_drawer_value });
    }
  }, [matches, is_controlled, controlled_drawer_value]);
  // -------------------------------------------------------------------------------- //
  // ------------------------------ select_org_property ------------------------------ //

  const select_org_property = (new_value, type) => {
    if (type === "org") {
      const selectedOrg = orgsList.filter((d) => d.id == new_value.id);
      const propertyCount = selectedOrg[0].suborgs.length;
      dispatch(setPropertyCount(propertyCount));
      setOrg(new_value);
      if (!_.isEmpty(selected_property)) setProperty({});
      if (typeof onSelectOrg === "function") {
        onSelectOrg(new_value);
      }
    }

    if (type === "property") {
      const selectedOrg = orgsList.filter((d) => d.id == new_value[0].id);
      const propertyCount = selectedOrg[0].suborgs.length;
      dispatch(setPropertyCount(propertyCount));
      setOrg(new_value[0]);
      setProperty(new_value[1]);

      if (typeof onSelectProperty === "function") {
        onSelectProperty(new_value);
      }
    }

    if (!matches) toggleDrawer(false);
  };

  const handleClose = React.useCallback(
    () => toggleDrawer(false),
    [small_menu_open, big_menu_open]
  );
  const handleOpen = React.useCallback(() => toggleDrawer(true), [small_menu_open, big_menu_open]);

  // -------------------------------------------------------------------------------- //
  return (
    <>
      <div
        className={clsx(container, {
          [container_open]: big_menu_open,
        })}
      >
        <div className={screen}>
          {React.cloneElement(children, {
            toggleDrawer,
          })}
        </div>
      </div>

      <Hidden lgUp implementation="css">
        <SwipeableDrawer
          // disableBackdropTransition={!iOS}
          // disableDiscovery={iOS}
          variant="temporary"
          open={small_menu_open}
          onClose={handleClose}
          onOpen={handleOpen}
        >
          <NavContent
            {...props}
            menu_open={small_menu_open}
            onClose={handleClose}
            select_org_property={select_org_property}
            selected_org={selected_org}
            selected_property={selected_property}
            orgs={orgsList}
            swipeable
            setOriginNavItems={setOriginNavItems}
            isRentOrg={isRentOrg}
          />
        </SwipeableDrawer>
      </Hidden>
      <Drawer
        variant="permanent"
        open
        className={clsx(drawer, {
          [drawerOpen]: big_menu_open,
          [drawerClose]: !big_menu_open,
        })}
        classes={{
          paper: clsx({
            [drawerOpen]: big_menu_open,
            [drawerClose]: !big_menu_open,
          }),
        }}
      >
        <NavContent
          {...props}
          menu_open={big_menu_open}
          onClose={handleClose}
          select_org_property={select_org_property}
          selected_org={selected_org}
          selected_property={selected_property}
          orgs={orgsList}
          setOriginNavItems={setOriginNavItems}
          isRentOrg={isRentOrg}
        />
      </Drawer>
    </>
  );
};
export default React.memo(NavDrawer);
