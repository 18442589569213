import graphql from "babel-plugin-relay/macro";

export default graphql`
  mutation updateHayleySettingMutation($input: updateHayleySettingInput!) {
    updateHayleySetting(input: $input) {
      response {
        hidePriceRangeFilter
        supportMultiLanguage
        promotion
        makePhoneNumberRequired
        showAllUnits
      }
    }
  }
`;
