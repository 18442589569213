/**
 * @flow
 * @relayHash f1e3df2e5827b19f7b1aa8858b8bb4df
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type scheduleReportDeleteInput = {|
  clientMutationId?: ?string,
  srId: string,
  orgId: string,
|};
export type scheduleReportDeleteMutationVariables = {|
  input: scheduleReportDeleteInput
|};
export type scheduleReportDeleteMutationResponse = {|
  +scheduleReportDelete: ?{|
    +success: ?string
  |}
|};
export type scheduleReportDeleteMutation = {|
  variables: scheduleReportDeleteMutationVariables,
  response: scheduleReportDeleteMutationResponse,
|};
*/


/*
mutation scheduleReportDeleteMutation(
  $input: scheduleReportDeleteInput!
) {
  scheduleReportDelete(input: $input) {
    success
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "scheduleReportDeleteInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "scheduleReportDelete",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "scheduleReportDeletePayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "success",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "scheduleReportDeleteMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "scheduleReportDeleteMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "scheduleReportDeleteMutation",
    "id": null,
    "text": "mutation scheduleReportDeleteMutation(\n  $input: scheduleReportDeleteInput!\n) {\n  scheduleReportDelete(input: $input) {\n    success\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4a058adc2e2e912ea6d4ead71453f649';
module.exports = node;
