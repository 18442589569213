import graphql from "babel-plugin-relay/macro";

export default graphql`
  query getDripsMenuQuery($propertyId: ID!, $angelType: String, $type: String, $orgId: ID) {
    angels(propertyId: $propertyId, angelType: $angelType, type: $type, orgId: $orgId) {
      id
      name
      active
    }
  }
`;
