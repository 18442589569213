import React from "react";
import { SvgIcon } from "@mui/material";

const ArrowIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M3.7998 1.2002V10.2002M3.7998 10.2002L0.799805 7.2002M3.7998 10.2002L6.7998 7.2002"
        stroke="#333333"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default ArrowIcon;
