/**
 * @flow
 * @relayHash 21b057a834d0908799c3fe3c64442985
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getReportHistoryQueryVariables = {|
  orgId: string,
  type: string,
  startDate: string,
  endDate: string,
|};
export type getReportHistoryQueryResponse = {|
  +scheduledReportLogs: {|
    +nodes: ?$ReadOnlyArray<?{|
      +id: string,
      +createdAt: any,
      +recipients: $ReadOnlyArray<string>,
      +reportMetrics: string,
    |}>
  |}
|};
export type getReportHistoryQuery = {|
  variables: getReportHistoryQueryVariables,
  response: getReportHistoryQueryResponse,
|};
*/


/*
query getReportHistoryQuery(
  $orgId: ID!
  $type: String!
  $startDate: String!
  $endDate: String!
) {
  scheduledReportLogs(orgId: $orgId, type: $type, startDate: $startDate, endDate: $endDate) {
    nodes {
      id
      createdAt
      recipients
      reportMetrics
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "orgId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "type",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "startDate",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "endDate",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "scheduledReportLogs",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "endDate",
        "variableName": "endDate"
      },
      {
        "kind": "Variable",
        "name": "orgId",
        "variableName": "orgId"
      },
      {
        "kind": "Variable",
        "name": "startDate",
        "variableName": "startDate"
      },
      {
        "kind": "Variable",
        "name": "type",
        "variableName": "type"
      }
    ],
    "concreteType": "ScheduledReportLogConnection",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "nodes",
        "storageKey": null,
        "args": null,
        "concreteType": "ScheduledReportLog",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "createdAt",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "recipients",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "reportMetrics",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getReportHistoryQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "getReportHistoryQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "getReportHistoryQuery",
    "id": null,
    "text": "query getReportHistoryQuery(\n  $orgId: ID!\n  $type: String!\n  $startDate: String!\n  $endDate: String!\n) {\n  scheduledReportLogs(orgId: $orgId, type: $type, startDate: $startDate, endDate: $endDate) {\n    nodes {\n      id\n      createdAt\n      recipients\n      reportMetrics\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8dc2ad8a3df9c4ef296d6100f195ef21';
module.exports = node;
