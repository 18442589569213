import React from "react";
import PropTypes from "prop-types";
import useMediaQuery from "@mui/material/useMediaQuery";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Icon from "../../Icon";
import Popover from "@mui/material/Popover";
import Changer from "./ChangerComponent";
import Typography from "../../Typography";
import packageVersion from "../../../../package.json";
import makeStyles from "@mui/styles/makeStyles";

const getLatestVersion = () => {
  var versions = JSON.parse(localStorage.getItem("version"));
  if (versions) return versions.old;
  else {
    var version = {
      new: packageVersion.version,
      old: packageVersion.version,
    };
    localStorage.setItem("version", JSON.stringify(version));
    return packageVersion.version;
  }
};

const bottom_section_styles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    height: "auto",
    background: theme.palette.white,
    "&:hover #bottomIcon": {
      color: "var(--hylyTheme)",
    },
  },
  zero_margin_right: {
    margin: `0 0 4px`,
    minWidth: 36,
  },
  twenty_min_width: {
    minWidth: 20,
  },
  list_item: {
    padding: theme.margins.sm,
  },
  overflow: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  rotate_icon: {
    webkitTransform: "rotate(270deg)",
    transform: "rotate(270deg)",
  },
  default_icon: {
    webkitTransform: "rotate(90deg)",
    transform: "rotate(90deg)",
  },
  pointer: {
    cursor: "pointer",
  },
  propertyName: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "128px",
    textAlign: "initial",
  },
  orgName: {
    color: "rgba(153, 153, 153, 1)",
    fontSize: "12px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "128px",
    textAlign: "initial",
  },
}));

const BottomSectionComponent = React.memo(
  ({
    suborg_change_available,
    onClick,
    drawer_open,
    selected_org,
    selected_property,
    menu_open,
    swipeable,
  }) => {
    const classes = bottom_section_styles();
    const ref = React.createRef();
    function handle_click(e) {
      onClick(ref.current);
    }

    const orgName = selected_org && selected_org.name;
    const propertyName = selected_property && selected_property.name;

    return (
      <div
        className={classes.root + (suborg_change_available ? " " + classes.pointer : "")}
        onClick={suborg_change_available ? handle_click : null}
      >
        <Divider />
        <div className="n-icon-container n-drawer-tooltip n-drawer-tooltip-width">
          {/* tooltip when closed */}
          <span className={menu_open ? "tooltiptext-hide" : "tooltiptext"}>
            <div className={selected_property?.name ? classes.orgName : classes.propertyName}>
              {selected_org?.name}
            </div>
            <div className={classes.propertyName}>{selected_property?.name}</div>
          </span>
          {/* icon before the  */}
          <ListItem classes={{ root: classes.list_item }}>
            <ListItemIcon className={classes.zero_margin_right}>
              <Icon id={menu_open ? "" : "bottomIcon"} type="icon-apartment" />
            </ListItemIcon>
            <ListItemText
              primary={swipeable ? orgName : menu_open ? orgName : ""}
              secondary={swipeable ? propertyName : menu_open ? propertyName : ""}
              classes={{
                primary: classes.overflow,
                //  secondary: classes.secondary
              }}
            />
            {Boolean(suborg_change_available) ? (
              <div ref={ref}>
                <ListItemIcon
                  className={classes.zero_margin_right + " " + classes.twenty_min_width}
                >
                  <Icon
                    id={menu_open ? "" : "bottomIcon"}
                    type="icon-triangle-up"
                    size="xs"
                    className={drawer_open ? classes.rotate_icon : classes.default_icon}
                  />
                </ListItemIcon>
              </div>
            ) : null}
          </ListItem>
        </div>
      </div>
    );
  }
);

BottomSectionComponent.propTypes = {
  suborg_change_available: PropTypes.bool,
};

const styles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    display: "inline-block",
    flex: 1,
    overflow: "hidden",
    height: "100%",
    maxHeight: "100vh",
    maxWidth: "100vw",
  },
  paper_big_screens: {
    height: 400,
    maxWidth: "600px",
  },
  smallScreen: {
    top: "auto !important",
    bottom: "1px !important",
  },
  version: {
    position: "absolute",
    bottom: 90,
    padding: "8px 20px",
    width: "100%",
  },
}));

const BottomSection = ({ onClick, banner, ...props }) => {
  const classes = styles();
  const [anchorEl, setAnchor] = React.useState(null);

  const handleArrowRef = (el) => {
    if (props.orgs) setAnchor(el);
  };

  const handleClose = () => setAnchor(null);

  const close_bottom_section = () => setAnchor(null);
  const matches = useMediaQuery("(min-width:768px)");

  const paper_class =
    classes.paper +
    (matches ? " " + classes.paper_big_screens : "") +
    (!props.menu_open ? " " + classes.smallScreen : "");
  return (
    <div>
      {props.menu_open && (
        <div
          className={classes.version}
          style={{
            bottom:
              Object.keys(props.selected_property).length === 0 &&
              props.selected_property.constructor === Object
                ? 64
                : 90,
          }}
        >
          {banner}

          <Typography type="body2" color="deepGrey">
            {getLatestVersion() ? `Version v${getLatestVersion()}` : null}
          </Typography>
        </div>
      )}
      <BottomSectionComponent onClick={handleArrowRef} drawer_open={Boolean(anchorEl)} {...props} />
      <Popover
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        marginThreshold={matches ? 16 : 0}
        anchorEl={anchorEl}
        onClose={handleClose}
        PaperProps={{
          style: {
            background: "#ffffff00",
            border: "none",
            boxShadow: "none",
          },
        }}
        classes={{ paper: paper_class }}
      >
        <Changer {...props} close_bottom_section={close_bottom_section} />
      </Popover>
    </div>
  );
};

export default React.memo(BottomSection);
