import React, { useState } from "react";
import { Menu, Typography, IconButton, Drawer } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { MoreVert as MoreVertIcon } from "@mui/icons-material";
import { verticalMenuStyles as styles } from "../styles";
import DrawerBody from "./DrawerBody";
import SaveReport from "../../ReportsComponents/saveReports";

const defaultMenu = [
  "Email Report",
  "Schedule Email",
  "Sent History",
  "Export Report",
  "Duplicate Report",
];

const MenuList = React.memo(
  ({ classes, showNotification, handleSendHistory, reportData, iconSize, ...props }) => {
    const menu = props.scheduleMenu ? defaultMenu.slice(0, 3) : [defaultMenu[3]];
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [open, setOpen] = useState(false);

    const handleToggle = () => {
      setOpen(!open);
    };

    const handleOpenDrawer = (item) => {
      setOpenDrawer(item);
    };

    const toggleDrawer = () => {
      setOpenDrawer(false);
    };

    const openMenu = (event) => {
      setMenuAnchorEl(event.currentTarget);
    };

    const closeMenu = () => {
      setMenuAnchorEl(null);
    };

    const handleClick = (item) => {
      setMenuAnchorEl(null);
      if (item === "Duplicate Report") {
        setOpen(true);
      } else if (item === "Sent History") {
        handleSendHistory(props.type);
      } else {
        handleOpenDrawer(item);
      }
    };

    const renderMenuItem = (item, index) => {
      return (
        <Typography
          onClick={() => handleClick(item)}
          key={index}
          className={`${classes.menutext} ${index === menu.length - 1 ? classes.border : ""}`}
          variant="body1"
        >
          {item}
        </Typography>
      );
    };

    return (
      <>
        <IconButton
          aria-label="more"
          aria-haspopup="true"
          aria-controls="simple-menu"
          onClick={openMenu}
          size={iconSize ? iconSize : "large"}
        >
          <MoreVertIcon className={classes.menuicon} />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={menuAnchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          open={Boolean(menuAnchorEl)}
          onClose={closeMenu}
          classes={{
            paper: classes.menuroot,
            list: classes.list,
          }}
        >
          {menu.map(renderMenuItem)}
        </Menu>
        <SaveReport open={open} handleToggle={handleToggle} reportData={{}} />
        <Drawer anchor={"right"} open={openDrawer} onClose={toggleDrawer}>
          <DrawerBody
            title={openDrawer}
            onClose={toggleDrawer}
            // id={reportData.id}
            showNotification={showNotification}
            {...props}
          />
        </Drawer>
      </>
    );
  }
);

export default withStyles(styles)(MenuList);
