import React from "react";
import PropTypes from "prop-types";

// components
import SearchContainer from "../components/SearchSelect/container";
import SearchList from "../components/SearchSelect/List";
import SearchListRelay from "../components/SearchSelect/List.relay";

// wrappers
import QueryRenderer from "../components/QueryRenderer";
import LocalContainer from "../components/LocalContainer";
import RelayLocalContainer from "../components/LocalContainer.relay";

export const SearchListContainer = SearchContainer;

const EmptyContainer = ({ children, ...props }) => React.cloneElement(children, props);

export default React.memo((props) => {
  const [search_value, set_value] = React.useState("");

  function handleChange(e) {
    set_value(e.target.value);
  }

  const ListUnit = React.useMemo(() => {
    switch (props.fetch_type) {
      case "no-fetch":
        return SearchList;
      case "fetch":
        return SearchListRelay;
      case "re-fetch":
        return (props) => (
          <div>
            <QueryRenderer {...props} Component={SearchListRelay} />
          </div>
        );
      default:
        return SearchList;
    }
  }, [props.fetch_type]);

  const Wrapper = React.useMemo(() => {
    switch (props.fetch_type) {
      case "no-fetch":
        return LocalContainer;
      case "fetch":
        return (props) => <QueryRenderer {...props} Component={RelayLocalContainer} />;
      case "re-fetch":
        return EmptyContainer;
      default:
        console.error(
          `Wrong fetch_type prop provided to 'SearchSelect' component. Expect one of ["fetch","re-fetch","no-fetch"] but got "${props.fetch_type}"`
        );
        return EmptyContainer;
    }
  }, [props.fetch_type]);

  return props.fetch_type === "re-fetch" ? (
    <SearchContainer handleChange={handleChange} search_value={search_value} {...props}>
      <ListUnit {...props} search_value={search_value} />
    </SearchContainer>
  ) : (
    <Wrapper Container={SearchContainer} ListComponent={ListUnit} {...props} />
  );
});
