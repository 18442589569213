import React from "react";

export default function AddUserIcon({ className = "text-[#999999]", width = 18, height = 18 }) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.75304 9.74023C7.35847 9.74023 6.12143 10.0553 5.30859 10.3294C4.70859 10.5323 4.30859 11.0978 4.30859 11.7313V13.6909C4.30859 13.6909 9.22612 13.6909 9.24687 13.6909"
        stroke="currentColor"
        stroke-miterlimit="10"
      />
      <path
        d="M8.75283 9.74038C7.38937 9.74038 6.28369 8.14087 6.28369 6.77742V6.28359C6.28369 4.92013 7.38937 3.81445 8.75283 3.81445C10.1163 3.81445 11.222 4.92013 11.222 6.28359V6.77742C11.222 8.14087 10.1163 9.74038 8.75283 9.74038Z"
        stroke="currentColor"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
      <path
        d="M12.7036 11.2227V14.1856"
        stroke="currentColor"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
      <path
        d="M11.2222 12.7031H14.1851"
        stroke="currentColor"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
    </svg>
  );
}
