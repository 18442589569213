import React from "react";
import Grid from "@mui/material/Grid";
import NavListWithCollapse from "./Menus";
import Header from "./NavHeader";
import BottomSection from "./BottomSection";
import makeStyles from "@mui/styles/makeStyles";

const drawer_width = 300;

const styles = makeStyles((theme) => ({
  root: {
    width: drawer_width,
    background: theme.palette.white,
    flexWrap: "nowrap",
    flex: 1,
    overflow: "hidden",
    [theme.breakpoints.up("lg")]: {
      width: "100%",
      maxWidth: drawer_width,
    },
  },
  headerRoot: {
    cursor: "pointer",
    padding: `${theme.margins.sm}px`,
    userSelect: "none",
  },
  list_item: {
    fontSize: 20,
    color: "#333333",
    fontWeight: 600,
    textOverflow: "ellipsis",
    overflow: "hidden",
    lineHeight: 1.5,
    whiteSpace: "nowrap",
  },
  rentLogo: {
    height: "18px !important",
    marginRight: "10px !important",
  },
  orgLogo: {
    maxHeight: 40,
  },
}));

const NavContent = ({
  app_changer,
  header_props,
  pathname,
  suborg_change_available,
  productName,
  handleHeaderClick,
  setOriginNavItems,
  isRentOrg,
  ...props
}) => {
  const classes = styles();

  return (
    <Grid container direction="column" className={classes.root}>
      <Header
        name={productName}
        onClick={handleHeaderClick}
        menu_open={props.menu_open}
        swipeable={props.swipeable}
        classes={classes}
        isRentOrg={isRentOrg}
      />
      <NavListWithCollapse pathname={pathname} {...props} setOriginNavItems={setOriginNavItems} />
      <BottomSection suborg_change_available={Boolean(suborg_change_available)} {...props} />
    </Grid>
  );
};

export default React.memo(NavContent);
