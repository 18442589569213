/**
 * @flow
 * @relayHash ffeacf6bfa2b31a00ed36be079cf64ef
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getDripsListQueryVariables = {|
  dashboardId: string,
  selectedPropertyIds?: ?$ReadOnlyArray<string>,
  startAt?: ?string,
  endAt?: ?string,
  drip?: ?boolean,
|};
export type getDripsListQueryResponse = {|
  +listProperties: $ReadOnlyArray<{|
    +id: string,
    +name: ?string,
    +campaignsCount: ?string,
  |}>
|};
export type getDripsListQuery = {|
  variables: getDripsListQueryVariables,
  response: getDripsListQueryResponse,
|};
*/


/*
query getDripsListQuery(
  $dashboardId: ID!
  $selectedPropertyIds: [String!]
  $startAt: String
  $endAt: String
  $drip: Boolean
) {
  listProperties(dashboardId: $dashboardId, selectedPropertyIds: $selectedPropertyIds, startAt: $startAt, endAt: $endAt, drip: $drip) {
    id
    name
    campaignsCount
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "dashboardId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "selectedPropertyIds",
    "type": "[String!]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "startAt",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "endAt",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "drip",
    "type": "Boolean",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "listProperties",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "dashboardId",
        "variableName": "dashboardId"
      },
      {
        "kind": "Variable",
        "name": "drip",
        "variableName": "drip"
      },
      {
        "kind": "Variable",
        "name": "endAt",
        "variableName": "endAt"
      },
      {
        "kind": "Variable",
        "name": "selectedPropertyIds",
        "variableName": "selectedPropertyIds"
      },
      {
        "kind": "Variable",
        "name": "startAt",
        "variableName": "startAt"
      }
    ],
    "concreteType": "PropertySummary",
    "plural": true,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "name",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "campaignsCount",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getDripsListQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "getDripsListQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "getDripsListQuery",
    "id": null,
    "text": "query getDripsListQuery(\n  $dashboardId: ID!\n  $selectedPropertyIds: [String!]\n  $startAt: String\n  $endAt: String\n  $drip: Boolean\n) {\n  listProperties(dashboardId: $dashboardId, selectedPropertyIds: $selectedPropertyIds, startAt: $startAt, endAt: $endAt, drip: $drip) {\n    id\n    name\n    campaignsCount\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3ecf00425ee085ce3246a166f3c5a803';
module.exports = node;
