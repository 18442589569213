import React from "react";

export default function HomeCheckIcon({ className = "text-[#999999]", width = 18, height = 18 }) {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 9.55664V15.1122H9"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3668 7.47253L8.44461 3.44531L2.3335 8.44531"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <g clip-path="url(#clip0_1703_35071)">
        <path
          d="M9.375 12.375L10.875 13.875L14.625 10.125"
          stroke="currentColor"
          stroke-width="0.75"
          stroke-linecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1703_35071">
          <rect width="6" height="6" fill="white" transform="translate(9 9)" />
        </clipPath>
      </defs>
    </svg>
  );
}
