import { fetchQuery } from "relay-runtime";
import environment from "environments/environment";
import getAssetCategoryIdQuery from "./queries/getAssetCategoryId";
import getOrgQuery from "./queries/getOrg";
import getSuborgDetailsQuery from "./queries/getSuborgDetails";
import getSelectedSuborgDetailsQuery from "./queries/getSelectedSuborgDetails";
import getPropertiesEditRafQuery from "./queries/getPropertiesEditRaf";
import getNotificationsQuery from "./queries/LoadNotif";
import getProductItemsQuery from "./queries/getProductItems";
import getProfileQuery from "./queries/getProfile";

const getDefaultOrg = () => {
  let variables = {};
  return fetchQuery(environment, getOrgQuery, variables);
};

const getAssetCategoryId = (propertyId, type) => {
  let variables = { propertyId, type };
  return fetchQuery(environment, getAssetCategoryIdQuery, variables)
    .then((res) => {
      if (res.categories) return res.categories[0].id;
      return "";
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

const loadPropertiesForEditRaf = (orgId) => {
  let variables = { orgId };
  return fetchQuery(environment, getPropertiesEditRafQuery, variables)
    .then((res) => {
      if (res.authorizedSuborgs) return res.authorizedSuborgs;
      return [];
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

const getSuborgDetails = (id) => {
  let variables = { id };
  return fetchQuery(environment, getSuborgDetailsQuery, variables);
};

const getSelectedSuborgDetails = (id) => {
  let variables = { id };
  return fetchQuery(environment, getSelectedSuborgDetailsQuery, variables);
};

const getNotifications = (id, scope, status) => {
  let variables = { id, scope, status };
  return fetchQuery(environment, getNotificationsQuery, variables);
};

const getProductItems = (tenantId, productName) => {
  let variables = { tenantId, productName };
  return fetchQuery(environment, getProductItemsQuery, variables);
};

const getProfile = () => {
  let variables = {};
  return fetchQuery(environment, getProfileQuery, variables)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return { errors: [{ message: err.toString() }] };
    });
};

export { default as deleteReport } from "./mutations/deleteReport";
export { default as editSavedReport } from "./mutations/editSavedReport";
export {
  getAssetCategoryId,
  getDefaultOrg,
  getSuborgDetails,
  getSelectedSuborgDetails,
  loadPropertiesForEditRaf,
  getNotifications,
  getProductItems,
  getProfile,
};
