import React from "react";

export default function TourHeadsUpIcon({ className = "text-[#999999]", width = 18, height = 18 }) {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3 7H15" stroke="currentColor" stroke-linecap="round" strokeLinejoin="round" />
      <path
        d="M15 6.99961V5.39961C15 5.18744 14.9157 4.98395 14.7657 4.83392C14.6157 4.68389 14.4122 4.59961 14.2 4.59961H3.8C3.58783 4.59961 3.38434 4.68389 3.23431 4.83392C3.08429 4.98395 3 5.18744 3 5.39961V14.1996C3 14.4118 3.08429 14.6153 3.23431 14.7653C3.38434 14.9153 3.58783 14.9996 3.8 14.9996H7.8"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path d="M6.2002 3V4.6" stroke="currentColor" stroke-linecap="round" strokeLinejoin="round" />
      <path
        d="M11.7998 3V4.6"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 13.4375C12.5178 13.4375 12.9375 13.0178 12.9375 12.5C12.9375 11.9822 12.5178 11.5625 12 11.5625C11.4822 11.5625 11.0625 11.9822 11.0625 12.5C11.0625 13.0178 11.4822 13.4375 12 13.4375Z"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.4999 14.8798C10.0979 14.6265 9.76661 14.2753 9.53702 13.8593C9.30743 13.4432 9.18701 12.9757 9.18701 12.5005C9.18701 12.0252 9.30743 11.5578 9.53702 11.1417C9.76661 10.7256 10.0979 10.3745 10.4999 10.1211"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 10.1211C13.902 10.3745 14.2333 10.7256 14.4629 11.1417C14.6925 11.5578 14.8129 12.0252 14.8129 12.5005C14.8129 12.9757 14.6925 13.4432 14.4629 13.8593C14.2333 14.2753 13.902 14.6265 13.5 14.8798"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
