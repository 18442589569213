const is_parent = (children) => Boolean(children) && Boolean(children.length);

const create_path = (path = "/", moduleName) => {
  return {
    pathname: Boolean(path) && /^\//.test(path) ? path : Boolean(path) ? "/" + path : "/",
    state: { moduleName },
  };
};

const check_if_selected = (children, path, pathname, type) => {
  if (!Boolean(children && children.length)) {
    switch (typeof path) {
      case "string":
        if (path.includes("angels")) return pathname.includes(path.replace("/angels", ""));

        if (path.includes("/drips/reports")) {
          return pathname.includes(path);
        }

        return path === "/"
          ? pathname === path
          : type === "strict"
          ? path === pathname
          : new RegExp(`^/?${path}`).test(pathname);
      case "object":
        if (Array.isArray(path)) {
          return pathname === "/"
            ? path.includes(pathname)
            : Boolean(path.find((o) => new RegExp(`^/?${o}$`).test(pathname)));
        }
        return false;
      case "function":
        return path(pathname, children);
      default:
        return false;
    }
  }
};

const check_if_child_selecetd = (children, pathname) => {
  if (!children) return false;
  for (const child of children) {
    const data_selected = check_if_selected(child.children, child.to, pathname);
    if (data_selected) return true;
  }
  return false;
};

const handleResize = (matches_value, controlled_value) => {
  const is_controlled = isBool(controlled_value);
  const matches = is_controlled ? matches_value && controlled_value : matches_value;
  return {
    small_menu_open: is_controlled && !matches_value ? controlled_value : false,
    big_menu_open: matches,
    matches,
  };
};
const isBool = (value) => Boolean(value !== null && value !== undefined);

export { is_parent, create_path, check_if_selected, check_if_child_selecetd, handleResize, isBool };
