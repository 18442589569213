/**
 * @flow
 * @relayHash dc5ac9c7c668449e8fda65e1540bca5a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type mascotQueryVariables = {|
  propertyId: string
|};
export type mascotQueryResponse = {|
  +mascot: {|
    +mascotSetting: {|
      +colors: ?{|
        +primary: ?string,
        +secondary: ?string,
        +tertiary: ?string,
      |},
      +animation: ?string,
      +quickActions: ?$ReadOnlyArray<string>,
      +bubbleType: ?string,
      +welcomeMessage: ?string,
      +firstMessage: ?string,
      +disclaimer: ?string,
      +icon: ?string,
    |}
  |}
|};
export type mascotQuery = {|
  variables: mascotQueryVariables,
  response: mascotQueryResponse,
|};
*/


/*
query mascotQuery(
  $propertyId: ID!
) {
  mascot(propertyId: $propertyId) {
    mascotSetting {
      colors {
        primary
        secondary
        tertiary
        id
      }
      animation
      quickActions
      bubbleType
      welcomeMessage
      firstMessage
      disclaimer
      icon
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "propertyId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "propertyId",
    "variableName": "propertyId"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "primary",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "secondary",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "tertiary",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "animation",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "quickActions",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "bubbleType",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "welcomeMessage",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "firstMessage",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "disclaimer",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "icon",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "mascotQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "mascot",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "Mascot",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "mascotSetting",
            "storageKey": null,
            "args": null,
            "concreteType": "MascotSettings",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "colors",
                "storageKey": null,
                "args": null,
                "concreteType": "MascotColors",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/)
                ]
              },
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "mascotQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "mascot",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "Mascot",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "mascotSetting",
            "storageKey": null,
            "args": null,
            "concreteType": "MascotSettings",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "colors",
                "storageKey": null,
                "args": null,
                "concreteType": "MascotColors",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v12/*: any*/)
                ]
              },
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/)
            ]
          },
          (v12/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "mascotQuery",
    "id": null,
    "text": "query mascotQuery(\n  $propertyId: ID!\n) {\n  mascot(propertyId: $propertyId) {\n    mascotSetting {\n      colors {\n        primary\n        secondary\n        tertiary\n        id\n      }\n      animation\n      quickActions\n      bubbleType\n      welcomeMessage\n      firstMessage\n      disclaimer\n      icon\n      id\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'fc575a045c0a4ba77f3323824146e63d';
module.exports = node;
