/**
 * @flow
 * @relayHash 3ec72f5a239a46598d8d0b9fa961204a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getorgSettingQueryVariables = {|
  orgId: string,
  propertyId?: ?string,
|};
export type getorgSettingQueryResponse = {|
  +orgSetting: {|
    +orgSetting: ?{|
      +enableDisclaimer: ?boolean,
      +disclaimerTitle: ?string,
      +disclaimerMessage: ?string,
      +id: string,
      +orgLevelDisclaimer: ?boolean,
    |},
    +propertySetting: ?{|
      +enableDisclaimer: ?boolean,
      +disclaimerTitle: ?string,
      +disclaimerMessage: ?string,
      +id: string,
      +orgLevelDisclaimer: ?boolean,
    |},
  |}
|};
export type getorgSettingQuery = {|
  variables: getorgSettingQueryVariables,
  response: getorgSettingQueryResponse,
|};
*/


/*
query getorgSettingQuery(
  $orgId: ID!
  $propertyId: ID
) {
  orgSetting(orgId: $orgId, propertyId: $propertyId) {
    orgSetting {
      enableDisclaimer
      disclaimerTitle
      disclaimerMessage
      id
      orgLevelDisclaimer
    }
    propertySetting {
      enableDisclaimer
      disclaimerTitle
      disclaimerMessage
      id
      orgLevelDisclaimer
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "orgId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "propertyId",
    "type": "ID",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "orgId",
    "variableName": "orgId"
  },
  {
    "kind": "Variable",
    "name": "propertyId",
    "variableName": "propertyId"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "enableDisclaimer",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "disclaimerTitle",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "disclaimerMessage",
    "args": null,
    "storageKey": null
  },
  (v2/*: any*/),
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "orgLevelDisclaimer",
    "args": null,
    "storageKey": null
  }
],
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "orgSetting",
  "storageKey": null,
  "args": null,
  "concreteType": "OrgSetting",
  "plural": false,
  "selections": (v3/*: any*/)
},
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "propertySetting",
  "storageKey": null,
  "args": null,
  "concreteType": "OrgSetting",
  "plural": false,
  "selections": (v3/*: any*/)
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getorgSettingQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "orgSetting",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "DisclaimerSetting",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "getorgSettingQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "orgSetting",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "DisclaimerSetting",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v2/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "getorgSettingQuery",
    "id": null,
    "text": "query getorgSettingQuery(\n  $orgId: ID!\n  $propertyId: ID\n) {\n  orgSetting(orgId: $orgId, propertyId: $propertyId) {\n    orgSetting {\n      enableDisclaimer\n      disclaimerTitle\n      disclaimerMessage\n      id\n      orgLevelDisclaimer\n    }\n    propertySetting {\n      enableDisclaimer\n      disclaimerTitle\n      disclaimerMessage\n      id\n      orgLevelDisclaimer\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '847f39f461ade6a8f2a5114e155694ed';
module.exports = node;
