import graphql from "babel-plugin-relay/macro";

export default graphql`
  query LoadNotifQuery($id: ID!, $scope: String, $status: String) {
    notifications(id: $id, scope: $scope, status: $status) {
      id
      notifications {
        id
        createdAt
        status
        header
        body
        sourceId
        sourceType
        sourceStatus
      }
    }
  }
`;
