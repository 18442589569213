import graphql from "babel-plugin-relay/macro";

export default graphql`
  query getHyreferralCampaignsPropertiesBreakdownQuery(
    $orgId: ID!
    $propertyIds: [String!]
    $campaignIds: [String!]
    $startAt: String!
    $endAt: String!
    $status: String
  ) {
    hyreferralCampaignsPropertiesBreakdown(
      orgId: $orgId
      propertyIds: $propertyIds
      campaignIds: $campaignIds
      startAt: $startAt
      endAt: $endAt
      status: $status
    ) {
      id
      title
      propertyId
      propertyName
      active
      friendsCount
      leasedCount
      residentsCount
      reward
      createdAt
    }
  }
`;
