import graphql from "babel-plugin-relay/macro";

export default graphql`
  query getCampaignsQuery(
    $orgId: ID!
    $propertyId: ID
    $search: String
    $after: String
    $first: Int
    $status: String
  ) {
    hyreferralCampaigns(
      orgId: $orgId
      propertyId: $propertyId
      search: $search
      after: $after
      first: $first
      status: $status
    ) {
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      }
      edges {
        cursor
        node {
          createdAt
          id
          propertyId
          propertyName
          title
          reward
          active
          friendsCount
          leasedCount
          residentsCount
          createdAt
          facebookShareUrl
        }
      }
    }
  }
`;
