import React, { useState } from "react";
import styles from "./style";
import withStyles from "@mui/styles/withStyles";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import { list } from "./common";
import ChangePassword from "./ChangePassword";
import EditAccount from "./EditAccount";
import { emitCustomEvent } from "react-custom-events";
import { Loader } from "storybookComponents";
import { useSelector } from "react-redux";
import { getStates4User } from "redux/slices";
import Tooltip from "storybookComponents/Tooltip2";

const ListItem = ({ classes, title, Icon, onClick, type }) => {
  const handleClick = () => {
    onClick(type);
  };
  return (
    <div className={classes.listbtn} onClick={handleClick}>
      <Icon className={classes.backRefIcon} />
      <div className={classes.listItem}>{title}</div>
    </div>
  );
};

const AccountInfo = ({ classes, showNotification, getProfileDetails }) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const details = useSelector(getStates4User);

  const getDetails = async () => {
    setLoading(true);
    await getProfileDetails();
    if (menuAnchorEl) closeMenu();
    setLoading(false);
  };

  const openMenu = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setMenuAnchorEl(null);
  };

  const handleClick = (type) => {
    if (type === "password" && window.location.hostname.includes(".ai"))
      return window.open(`${process.env.REACT_APP_ACCOUNTS_LINK}/change-password`, "_blank");
    if (type === "signOut") {
      emitCustomEvent("UserSignOut");
    } else {
      setOpen(type);
    }
  };

  const handleToggle = () => {
    setOpen(false);
  };

  const renderlist = (item, index) => (
    <ListItem classes={classes} onClick={handleClick} key={`accounts-list-${index}`} {...item} />
  );

  if (loading)
    return (
      <div className={classes.loaderContainer}>
        <Loader />
      </div>
    );

  return (
    <>
      <Tooltip text={details.fullName}>
        <div>
          <IconButton
            onClick={openMenu}
            aria-controls="account-menu"
            classes={{
              root: classes.iconRoot,
            }}
            size="large"
          >
            {details.avatarUrl ? (
              <img className={classes.accountIcon} src={details.avatarUrl} />
            ) : (
              <div className={classes.accountIcon}>{details.initialsOfName}</div>
            )}
          </IconButton>
        </div>
      </Tooltip>
      <Menu
        id="account-menu"
        anchorEl={menuAnchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        getContentAnchorEl={null}
        keepMounted
        open={Boolean(menuAnchorEl)}
        onClose={closeMenu}
        classes={{
          paper: classes.menuroot,
          list: classes.list,
        }}
      >
        {list.map(renderlist)}
      </Menu>
      {open === "password" && (
        <ChangePassword
          email={details.email}
          open={open}
          handleToggle={handleToggle}
          showNotification={showNotification}
        />
      )}
      {open === "edit" && (
        <EditAccount
          open={open}
          handleToggle={handleToggle}
          showNotification={showNotification}
          details={details}
          getDetails={getDetails}
        />
      )}
    </>
  );
};

export default withStyles(styles)(AccountInfo);
