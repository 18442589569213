/**
 * @flow
 * @relayHash 8c073d78efd8b106025d0ccbe0364f1a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type mailGptPromptTemplatesQueryVariables = {|
  promptType: string,
  audienceType: string,
  prompt_model_id: string,
|};
export type mailGptPromptTemplatesQueryResponse = {|
  +mailGptPromptTemplates: $ReadOnlyArray<{|
    +id: string,
    +name: string,
    +createdAt: any,
    +largeThumbnailUrl: ?string,
    +smallThumbnailUrl: ?string,
  |}>
|};
export type mailGptPromptTemplatesQuery = {|
  variables: mailGptPromptTemplatesQueryVariables,
  response: mailGptPromptTemplatesQueryResponse,
|};
*/


/*
query mailGptPromptTemplatesQuery(
  $promptType: String!
  $audienceType: String!
  $prompt_model_id: String!
) {
  mailGptPromptTemplates(promptType: $promptType, audienceType: $audienceType, prompt_model_id: $prompt_model_id) {
    id
    name
    createdAt
    largeThumbnailUrl
    smallThumbnailUrl
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "promptType",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "audienceType",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "prompt_model_id",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "mailGptPromptTemplates",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "audienceType",
        "variableName": "audienceType"
      },
      {
        "kind": "Variable",
        "name": "promptType",
        "variableName": "promptType"
      },
      {
        "kind": "Variable",
        "name": "prompt_model_id",
        "variableName": "prompt_model_id"
      }
    ],
    "concreteType": "Template",
    "plural": true,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "name",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "createdAt",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "largeThumbnailUrl",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "smallThumbnailUrl",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "mailGptPromptTemplatesQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "mailGptPromptTemplatesQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "mailGptPromptTemplatesQuery",
    "id": null,
    "text": "query mailGptPromptTemplatesQuery(\n  $promptType: String!\n  $audienceType: String!\n  $prompt_model_id: String!\n) {\n  mailGptPromptTemplates(promptType: $promptType, audienceType: $audienceType, prompt_model_id: $prompt_model_id) {\n    id\n    name\n    createdAt\n    largeThumbnailUrl\n    smallThumbnailUrl\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd4a2698ac422bf77b5e132e2cc1259b3';
module.exports = node;
