import React from "react";
import { TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles({
  underline: {
    "&:hover:not($disabled):not($focused):not($error):before": {
      borderBottom: "1px solid var(--hylyTheme)",
    },
    "&:before": {
      borderBottom: "1px solid #dadada",
    },
    "&:after": {
      borderBottom: "1px solid #999999 !important",
    },
  },
  notchedOutline: {
    top: 0,
    borderColor: "#dadada",
    height: "100%",
    borderWidth: "1px !important",
    "& legend": {
      display: "none !important",
    },
  },
  inputStyle: {
    fontSize: "14px",
    lineHeight: "20px",
    borderRadius: "0px",
    border: "1px solid",
    borderColor: "#999999",
    "&:hover": {
      borderColor: "#000000 !important",
    },
    "&:focus-within": {
      borderColor: "#26BBED !important",
    },
  },
});

export default (props) => {
  const classes = useStyles();
  return (
    <div>
      <TextField
        className={`${
          props.noStyle
            ? null
            : props.variant === "outlined"
            ? classes.inputStyle
            : classes.underline
        }  scroll`}
        {...props}
        // InputProps={{
        //   classes: {
        //     notchedOutline: classes.notchedOutline,
        //     root: classes.inputStyle,
        //     focused: classes.focused,
        //     underline: props.variant == "outlined" ? null : classes.underline,
        //   },
        // }}
      />
    </div>
  );
};
