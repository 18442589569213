import SvgIcon from "@mui/material/SvgIcon";

const ArrowIcon = ({ stroke = "#333" }) => (
  <SvgIcon viewBox="0 0 18 18" style={{ width: 18, height: 18, fill: "none", stroke: "#333" }}>
    <path
      d="M10.7998 4.5L6.29981 9L10.7998 13.5"
      strokeMiterlimit="10"
      strokeLinecap="square"
      stroke={stroke}
    />
  </SvgIcon>
);

export default ArrowIcon;
