var emailRegexp =
  /^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
const brToNl = (str) => {
  if (!str) return "";
  str = str.replace(/<\s*\/?br\s*[\/]?>/gi, "\n");
  return str.replace(/(<([^>]+)>)/gi, "");
};

const regex = /\n/;

export default (message, subject, orgColors, email, data = {}) => `
<style>
@import url("https://fonts.googleapis.com/css?family=Raleway");
@import url("https://fonts.googleapis.com/css?family=Open+Sans");

input:focus {outline:0;} 
textarea:focus {outline:0;} 
button:focus {outline:0;} 
a{
  color: ${orgColors ? orgColors.linkColor : "#27bbed"} !important;
}
.hyraf-email-share-form{
  max-width:500px;
  width:100%;
  margin: auto;
  background: white;
  margin-top:140px;
}
.hyraf-email-share-form-header{
  height: 80px;
  background:${orgColors ? orgColors.buttonBackgroundColor : "#27bbed"};
  color:${orgColors ? orgColors.buttonFontColor : "white"};
  border:${orgColors ? orgColors.buttonBorderColor : "#27bbed"};
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
  padding:0 20px;
}
.hyraf-email-share-form-header span{
  font-size: 24px;
  color: white;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
  display:flex;
  align-items:center;
}
.hyraf-email-share-form-header .close-btn{
  background:transparent;
  border:none;
  font-size: 30px;
  color:white;
  font-weight: 100;
}

.hyraf-email-share-form .content{
  padding:20px 20px 30px 20px;
  border: 1px solid #DADADA;
  border-top:none;
}

.hyraf-email-share-form .content .content-heading{
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
}

.hyraf-email-share-form .content .content-subheading{
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  margin-top:30px;
}

.hyraf-email-share-form .content .content-label{
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: rgba(51, 51, 51, 0.75);
  margin-top:30px;
}

.hyraf-email-share-form .content .content-small-label{
  margin-top:30px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #333333;
}

.hyraf-email-share-form .content input{
  border: none;
  border-bottom: 1px solid #dadada;
  width: 100%;
  margin-top: 20px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
}

.hyraf-email-share-form .content input:hover{
   border-bottom: 1px solid var(--hylyTheme);
}

.hyraf-email-share-form .content input:focus{
   border-bottom: 1px solid #999999;
}

.hyraf-email-share-form .content textarea{
  border: 1px solid #dadada;
  width: 100%;
  margin-top: 10px;
  height:300px;

  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
}

.hyraf-email-share-form .content textarea:hover{
  border: 1px solid var(--hylyTheme);
}

.hyraf-email-share-form .content textarea:focus{
  border: 1px solid #999999;
}


.hyraf-email-share-form .content .submit-btn{
  width: 240px;
  height: 48px;
  background:${orgColors ? orgColors.buttonBackgroundColor : "#27bbed"};
  color:${orgColors ? orgColors.buttonFontColor : "white"};
  border:${orgColors ? orgColors.buttonBorderColor : "#27bbed"};
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  cursor:pointer;
}
.hyraf-email-share-form .footer{
  text-align: right;
  margin-top: 20px;
}
.hyraf-copy-link{
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #999999;
  justify-content:space-between;
  border:1px solid #eeeeee;
  height:35px;
  margin-top:10px;
  padding-left:10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.hyraf-copy-link button{
  height: 35px;
  border: none;
  background: transparent;
  width: 80px;
  border-left: 1px solid #eeeeee;
  color: #333333;
  cursor:pointer;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  font-family: "Open Sans", sans-serif;
}

.share-buttons-queue{
  display:flex;
  justify-content:space-between;
  margin-top:10px;
}

.share-buttons-queue button{
  width: 100px;
  height: 86px;
  background: #F9F9F9;
  border-radius: 10px;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor:pointer;
}
.share-buttons-queue button span{
  margin-top:10px;
  color:#333333;
}
.copied-btn{
  background: #66D184 !important;
  box-shadow: inset 1px 0px 0px #DADADA;
  color:white !important;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.copied-btn svg{
  display:block !important;
}
.copy-btn svg{
  display:none;
} 
.share-form-field .guide{
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #999999;
}
.share-form-field label {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
}

.email-text{
  border: 1px solid #dadada;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  padding:10px;
  width:-webkit-fill-available !important;
}

.mt-20{
  margin-top:20px;
}
@media only screen and (max-width: 480px) {
  .hyraf-email-share-form-header{
    height: 60px;
  }
  .hyraf-email-share-form-header span{
    font-size: 18px;
  }
  .hyraf-email-share-form-header .close-btn{
    right: 10px;
    font-size:25px;
  }
  .hyraf-email-share-form .content .content-heading{
    font-size: 16px;
    line-height: 20px;
  }

  .share-buttons-queue{
    display: grid;
    justify-content: space-between;
    margin-top: 10px;
    gap: 10px;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  }
  .share-buttons-queue button{
    width:auto;
    height: 44px;
    background: #F9F9F9;
    border-radius: 10px;
    border: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .share-buttons-queue button span{
    margin-top:0;
    margin-left:5px;
  }
  .hyraf-copy-link{
    font-size: 12px;
  }
  .hyraf-email-share-form .footer{
    text-align: center;
  }
}

.multi-email-input{
  margin: 0;
  max-width: 100%;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  outline: 0;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  text-align: left;
  line-height: 1.21428571em;
  background: #fff;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 0.1s ease, border-color 0.1s ease;
  transition: box-shadow 0.1s ease, border-color 0.1s ease;
  font-size: 13px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: flex-start;
  padding-bottom:10px;
}

.multi-email-input input:hover{
    border-bottom: 1px solid var(--hylyTheme);
}

.multi-email-input input:focus{
    border-bottom: 1px solid #999999;
}

.multi-email-input input{
  margin:0!important;
  border-bottom: 1px solid #dadada;
}
.multi-email-input > span[data-placeholder] {
  display: none;
  position: absolute;
  left: 0.5em;
  top: 0.4em;
  padding: 0.4em;
  line-height: 1.21428571em;
}
.multi-email-input.focused {
  border-color: #85b7d9;
  background: #fff;
}

.multi-email-input.empty > span[data-placeholder] {
  display: inline;
  color: #ccc;
}
.multi-email-input.focused > span[data-placeholder] {
  display: none;
}

.multi-email-input > input {
  outline: none !important;
  display: inline-block !important;
  line-height: 1;
  vertical-align: baseline !important;
  padding: 0.4em 0.1em !important;
}

.multi-email-input [data-tag] {
  line-height: 1;
  vertical-align: baseline;
  margin: 0.14285714em;
  background-color: #f3f3f3;
  background-image: none;
  padding: 0.5833em 0.833em;
  color: rgba(0, 0, 0, 0.6);
  text-transform: none;
  font-weight: 600;
  border: 0 solid transparent;
  border-radius: 0.28571429rem;
  -webkit-transition: background 0.1s ease;
  -o-transition: background 0.1s ease;
  transition: background 0.1s ease;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
  gap:5px;
}
.multi-email-input [data-tag] [data-tag-item] {
  max-width: 100%;
  overflow: hidden;
}
.multi-email-input [data-tag]:first-child {
  margin-left: 0;
}
.multi-email-input [data-tag] [data-tag-handle] {
  margin-left: 0.833em;
  cursor: pointer;
}
.email-tag{
  border: 1px solid #c9c9cb;
  padding: 5px;
  border-radius: 22px;
  display:flex;
  align-items:center;
  justify-content:space-between;
  font-size:14px;
  padding-top:6px;
}
.email-tag:hover{
  border: 1px solid #26BBED;
  cursor:pointer;
  background-color: rgba(0, 0, 0, 0.04);
}
.email-tag svg{
  width: 16px;
  height: 16px;
  stroke-width: 3px;
  margin-top:2px;
}
.email-tag svg:hover path{
  stroke:red;
  cursor:pointer;
}
.error-email-tag {
  border-color:red;
}
.error-email-tag:hover {
  border-color:red;
}

</style>

<script>

  var tester = ${emailRegexp}
  function isValidEmail(email) {
    return ${emailRegexp}.test(email);
  }

  function removeEmail(tag, eml){
    let email = eml || tag.parentElement.previousSibling.innerHTML.trim();
    let all = document.getElementById("all-emails");
    for(let i =0; i < all.children.length - 1; i++){
      if(all.children[i].children[0].innerHTML == email){
        all.removeChild(all.childNodes[i]);
        ifAnyInvalidEmail();
        return;
      }
    }
  }

  function editChipContentEmail(tag,eml){
    let email=eml||tag.innerHTML;
    removeEmail(this,email);
    let input=document.getElementById("emails"); 
    input.value=email;
  }


  function removeAllEmail(){
    let all = document.getElementById("all-emails");
    for(let i =0; i < all.children.length - 1; i++){
      all.removeChild(all.childNodes[i]);
      return;
    }
  }


  function removeLastEmail(){
    let inputValue=document.getElementById("emails").value;
    if(!inputValue){
      let all = document.getElementById("all-emails");
      if(all.children.length > 1)
      all.removeChild(all.childNodes[all.children.length - 2]);
    }
  }


  function getAllEmails(){
    let r =[]
    let all = document.getElementById("all-emails").children;
    for(let i =0; i < all.length - 1; i++){
      r.push(all[i].children[0].innerHTML)
    }
    return r;
  }

  function ifAnyInvalidEmail(){
    let anyInvalidEmail=false;
    let allEmails= getAllEmails();
    if(allEmails.length===0){
      anyInvalidEmail=true
    }
    for(let i=0;i<allEmails.length;i++){
      if(!isValidEmail(allEmails[i])){
        anyInvalidEmail=true;
      }
    }
    if(anyInvalidEmail){
      let button=document.getElementById("email-send-btn");
      button.style.opacity = 0.5;
      button.disabled = true;
    }else{
      let button=document.getElementById("email-send-btn");
      button.style.opacity = 1;
      button.disabled = false;
    }
  }

  function findEmailAddress(value, isEnter){
    let inputValue = '';
    const re = /[ ,;]/g;
    const isEmail = isValidEmail;
    

    const addEmails = (email,isInvalid) => {
      const emails = getAllEmails();
      for (let i = 0, l = emails.length; i < l; i++) {
        if (emails[i] == email) {
          return false;
        }
      }

      let all = document.getElementById("all-emails");
      var newTag = document.createElement("span");
      newTag.className="email-tag";
      if(isInvalid){
        newTag.className=newTag.className + " error-email-tag";
      }

      var newEmail = document.createElement("span");
      newEmail.setAttribute("onclick", "editChipContentEmail(this)");
      newEmail.innerHTML = email;

      newTag.append(newEmail);

      var closeIcon = document.createElement("span");
      closeIcon.innerHTML = '&nbsp; &nbsp;\
        <svg onclick = "removeEmail(this)" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\
          <path d="M19 5L5 19" stroke="#c9c9cb" stroke-miterlimit="10" stroke-linecap="square" />\
          <path d="M19 19L5 5" stroke="#c9c9cb" stroke-miterlimit="10" stroke-linecap="square" />\
        </svg>'.trim();
      newTag.append(closeIcon);

      all.insertBefore(newTag, all.childNodes[all.children.length - 1]);
      all.children[all.children.length-1].value = ""
      ifAnyInvalidEmail();
      return true;
    }

    if (value !== '') {
      let isInvalid=false;
      if (re.test(value)) {
        let splitData = value.split(re).filter(n => {
          return n !== '' && n !== undefined && n !== null;
        });

        const setArr = new Set(splitData);
        let arr = [...setArr];

        do {
          // if (isEmail('' + arr[0])) {
          //   addEmails('' + arr.shift());
          // } else {
          //   if (arr.length === 1) {
          //     inputValue = '' + arr.shift();
          //   } else {
          //     arr.shift();
          //   }
          // }
          if (isEmail('' + arr[0])) {
            isInvalid=false;
          } else {
            isInvalid=true;
          }
          addEmails('' + arr.shift(),isInvalid);
        } while (arr.length);
      } else {
        if (isEnter) {
          // if (isEmail(value)) {
          //   addEmails(value);
          // } else {
          //   inputValue = value;
          // }
          if (isEmail(value)) {
            isInvalid=false;
          } else {
            isInvalid=true;
          }
           addEmails(value,isInvalid);
        } else {
          inputValue = value;
        }
      }
    }
  }

  function handleOnKeyup(e){
    switch (e.which) {
      case 13:
      case 9:
        findEmailAddress(e.target.value, true);
        break;
      case 8:
        //removeLastEmail()
        break;
      default:
    }
  }

  handleOnChange = (e) => {
    findEmailAddress(e.currentTarget.value);
  }
</script>
<div class="hyraf-email-share-form">
  <div class="hyraf-email-share-form-header">
    <button class="close-btn" type="button" onclick="closeSocialPreview('email-popup')">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M22.5 12.5H2.5" stroke="white" stroke-miterlimit="10"/>
        <path d="M9.5 19.5L2.5 12.5L9.5 5.5" stroke="white" stroke-miterlimit="10" stroke-linecap="square"/>
      </svg>
    </button>
    <span class="title">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM20 8L12 13L4 8V6L12 11L20 6V8Z" fill="#FFFFFF"/>
      </svg>
      &nbsp;
      Email
    </span>
    <button class="close-btn" type="button" onclick="closeSocialPreview('email-popup', 'true')">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19 5L5 19" stroke="white" stroke-miterlimit="10" stroke-linecap="square"/>
        <path d="M19 19L5 5" stroke="white" stroke-miterlimit="10" stroke-linecap="square"/>
      </svg>
    </button>
  </div>
  <div class="content">
    <div class="share-form-field">
      <label>To</label>
      <div class="multi-email-input" id="all-emails">
        <input 
          id="emails"  
          onkeyup="handleOnKeyup(event)"
          oninput="handleOnChange(event)"
        />
      </div>
      <div class="guide">Enter emails separated by commas</div>
    </div>
    <div class="share-form-field mt-20">
      <label>Subject</label>
      <input id="input-email-subject" value="${subject}"/>
    </div>
    <div class="share-form-field mt-20">
      <label>Message</label>
      <textarea id="email-body-text" class="email-text">${brToNl(message)}</textarea>
    </div>
    <input id="endpoint" value="${data.endpoint}" type="hidden"/>
    <input id="campaignId" value="${data.id}" type="hidden"/>
    <input id="enrollId" value="${data.enrollId}" type="hidden"/>
    <input id="newlinerejex"  type="hidden" value="${regex}" />
    <div class="footer">
      <button id="email-send-btn"   class="submit-btn" type="button" onclick="openMailTo()">Send Email</button>
    </div>
  </div>
</div>

<script>

  function openMailTo(){
    console.log("Hiii")
    let subject = document.getElementById("input-email-subject").value;
    let body = document.getElementById("email-body-text").value;
    window.location.href = "mailto:?subject="+subject+"&body="+body;
  }

  function sendEmai(){
    let url = document.getElementById('endpoint').value;
      let id = document.getElementById('campaignId').value;
      let subject = document.getElementById("input-email-subject").value;
      let body = document.getElementById("email-body-text").value;//innerHTML.replace(/"/g, "'");
      let regexp = new RegExp(document.getElementById("newlinerejex").value.replace("/","").replace("/",""), 'g');
      body = body.replace(regexp, "<br/>")

      let emails = getAllEmails();
      if(!emails.length)return;
      let enrollmentId = document.getElementById("enrollId").value;
      if(!enrollmentId || enrollmentId == 'undefined'){
        let enrollData = localStorage.getItem('enrollData');
        if(!enrollData)return;
        enrollmentId = JSON.parse(enrollData).id;
      }
      document.getElementById("email-send-btn").style.opacity = 0.5;
      document.getElementById("email-send-btn").style.disabled = true;

      let options = {
        headers: { 'Content-Type': 'application/json' }
      };
      let query = 'mutation{ inviteFriendHyreferralCampaign(input:{enrollmentId: "'+enrollmentId+'", subject : "'+subject+'", bodyMessage: "'+body+'" , email:"'+emails.toString()+'", id:"'+id+'"}){ status }}'
      
      options.body = JSON.stringify({ query });
      options.method = 'POST';
    
      fetch(url,options).then(res => res.json())
      .then(res => {
        document.getElementById("email-send-btn").style.opacity = 1;
        document.getElementById("email-send-btn").style.disabled = false;
        if(res.errors){
          if(res.errors[0]&&res.errors[0].message){
            alert(res.errors[0]&&res.errors[0].message)
          }
        }
        else{
          removeAllEmail();
          closeSocialPreview('email-popup')
          openSocialPreview("thanks-popup")
        }
      })
  }
</script>

`;
