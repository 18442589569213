/**
 * @flow
 * @relayHash aefb19ecd379cf179d07e6c3581a9b97
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getOmichannelPreviewsQueryVariables = {|
  id: string
|};
export type getOmichannelPreviewsQueryResponse = {|
  +hyreferralCampaign: {|
    +id: string,
    +reward: ?number,
    +active: boolean,
    +residentInviteEmailCampaignHtml: string,
    +residentRewardInviteEmailCampaignHtml: string,
    +friendInviteEmailCampaignHtml: string,
    +residentEnrollEmailCampaignHtml: string,
    +emailSubject: ?string,
    +residentInviteEmailSubject: ?string,
    +residentDiscountEmailSubject: ?string,
    +residentThankYouEmailSubject: ?string,
    +residentInviteEmailBody: ?string,
    +residentInviteSms: ?string,
    +residentInviteImage: ?{|
      +fileUrl: string
    |},
    +image: ?{|
      +fileUrl: string
    |},
    +residentDiscountImage: ?{|
      +fileUrl: string
    |},
    +emailSms: ?string,
    +residentDiscountEmailSms: ?string,
    +tourCtaUrl: ?string,
    +contactCtaUrl: ?string,
    +propertyId: string,
    +emailEnabled: ?boolean,
    +facebookEnabled: ?boolean,
    +smsEnabled: ?boolean,
    +twitterEnabled: ?boolean,
    +bannerText: ?string,
    +ctaText: ?string,
    +pitchMessage: ?string,
    +residentEnrollHeader: ?string,
    +residentEnrollFooter: ?string,
    +shortEnrollUrl: ?string,
    +propertyName: ?string,
    +shortTourCtaUrl: ?string,
    +linkColor: ?string,
    +fontFamily: ?string,
    +textColor: ?string,
    +buttonBorderColor: ?string,
    +buttonFontColor: ?string,
    +buttonBackgroundColor: ?string,
    +noscript: ?string,
    +script: ?string,
    +rewardEmailHeader: ?string,
    +friendInviteEmailHeader: ?string,
    +friendInviteMenuHeader: ?string,
    +friendInviteMenuBody: ?string,
    +residentBannerTitle: ?string,
    +popupMessage: ?string,
    +popupTitle: ?string,
    +facebookShareUrl: ?string,
    +propertyLogoUrl: ?string,
    +emailValidationStatus: ?boolean,
  |}
|};
export type getOmichannelPreviewsQuery = {|
  variables: getOmichannelPreviewsQueryVariables,
  response: getOmichannelPreviewsQueryResponse,
|};
*/


/*
query getOmichannelPreviewsQuery(
  $id: ID!
) {
  hyreferralCampaign(id: $id) {
    id
    reward
    active
    residentInviteEmailCampaignHtml
    residentRewardInviteEmailCampaignHtml
    friendInviteEmailCampaignHtml
    residentEnrollEmailCampaignHtml
    emailSubject
    residentInviteEmailSubject
    residentDiscountEmailSubject
    residentThankYouEmailSubject
    residentInviteEmailBody
    residentInviteSms
    residentInviteImage {
      fileUrl
      id
    }
    image {
      fileUrl
      id
    }
    residentDiscountImage {
      fileUrl
      id
    }
    emailSms
    residentDiscountEmailSms
    tourCtaUrl
    contactCtaUrl
    propertyId
    emailEnabled
    facebookEnabled
    smsEnabled
    twitterEnabled
    bannerText
    ctaText
    pitchMessage
    residentEnrollHeader
    residentEnrollFooter
    shortEnrollUrl
    propertyName
    shortTourCtaUrl
    linkColor
    fontFamily
    textColor
    buttonBorderColor
    buttonFontColor
    buttonBackgroundColor
    noscript
    script
    rewardEmailHeader
    friendInviteEmailHeader
    friendInviteMenuHeader
    friendInviteMenuBody
    residentBannerTitle
    popupMessage
    popupTitle
    facebookShareUrl
    propertyLogoUrl
    emailValidationStatus
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "reward",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "active",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "residentInviteEmailCampaignHtml",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "residentRewardInviteEmailCampaignHtml",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "friendInviteEmailCampaignHtml",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "residentEnrollEmailCampaignHtml",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "emailSubject",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "residentInviteEmailSubject",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "residentDiscountEmailSubject",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "residentThankYouEmailSubject",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "residentInviteEmailBody",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "residentInviteSms",
  "args": null,
  "storageKey": null
},
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fileUrl",
  "args": null,
  "storageKey": null
},
v16 = [
  (v15/*: any*/)
],
v17 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "emailSms",
  "args": null,
  "storageKey": null
},
v18 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "residentDiscountEmailSms",
  "args": null,
  "storageKey": null
},
v19 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "tourCtaUrl",
  "args": null,
  "storageKey": null
},
v20 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "contactCtaUrl",
  "args": null,
  "storageKey": null
},
v21 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "propertyId",
  "args": null,
  "storageKey": null
},
v22 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "emailEnabled",
  "args": null,
  "storageKey": null
},
v23 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "facebookEnabled",
  "args": null,
  "storageKey": null
},
v24 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "smsEnabled",
  "args": null,
  "storageKey": null
},
v25 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "twitterEnabled",
  "args": null,
  "storageKey": null
},
v26 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "bannerText",
  "args": null,
  "storageKey": null
},
v27 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ctaText",
  "args": null,
  "storageKey": null
},
v28 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "pitchMessage",
  "args": null,
  "storageKey": null
},
v29 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "residentEnrollHeader",
  "args": null,
  "storageKey": null
},
v30 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "residentEnrollFooter",
  "args": null,
  "storageKey": null
},
v31 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "shortEnrollUrl",
  "args": null,
  "storageKey": null
},
v32 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "propertyName",
  "args": null,
  "storageKey": null
},
v33 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "shortTourCtaUrl",
  "args": null,
  "storageKey": null
},
v34 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "linkColor",
  "args": null,
  "storageKey": null
},
v35 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fontFamily",
  "args": null,
  "storageKey": null
},
v36 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "textColor",
  "args": null,
  "storageKey": null
},
v37 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "buttonBorderColor",
  "args": null,
  "storageKey": null
},
v38 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "buttonFontColor",
  "args": null,
  "storageKey": null
},
v39 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "buttonBackgroundColor",
  "args": null,
  "storageKey": null
},
v40 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "noscript",
  "args": null,
  "storageKey": null
},
v41 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "script",
  "args": null,
  "storageKey": null
},
v42 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rewardEmailHeader",
  "args": null,
  "storageKey": null
},
v43 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "friendInviteEmailHeader",
  "args": null,
  "storageKey": null
},
v44 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "friendInviteMenuHeader",
  "args": null,
  "storageKey": null
},
v45 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "friendInviteMenuBody",
  "args": null,
  "storageKey": null
},
v46 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "residentBannerTitle",
  "args": null,
  "storageKey": null
},
v47 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "popupMessage",
  "args": null,
  "storageKey": null
},
v48 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "popupTitle",
  "args": null,
  "storageKey": null
},
v49 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "facebookShareUrl",
  "args": null,
  "storageKey": null
},
v50 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "propertyLogoUrl",
  "args": null,
  "storageKey": null
},
v51 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "emailValidationStatus",
  "args": null,
  "storageKey": null
},
v52 = [
  (v15/*: any*/),
  (v2/*: any*/)
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getOmichannelPreviewsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "hyreferralCampaign",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "HyreferralCampaign",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "residentInviteImage",
            "storageKey": null,
            "args": null,
            "concreteType": "Asset",
            "plural": false,
            "selections": (v16/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "image",
            "storageKey": null,
            "args": null,
            "concreteType": "Asset",
            "plural": false,
            "selections": (v16/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "residentDiscountImage",
            "storageKey": null,
            "args": null,
            "concreteType": "Asset",
            "plural": false,
            "selections": (v16/*: any*/)
          },
          (v17/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/),
          (v20/*: any*/),
          (v21/*: any*/),
          (v22/*: any*/),
          (v23/*: any*/),
          (v24/*: any*/),
          (v25/*: any*/),
          (v26/*: any*/),
          (v27/*: any*/),
          (v28/*: any*/),
          (v29/*: any*/),
          (v30/*: any*/),
          (v31/*: any*/),
          (v32/*: any*/),
          (v33/*: any*/),
          (v34/*: any*/),
          (v35/*: any*/),
          (v36/*: any*/),
          (v37/*: any*/),
          (v38/*: any*/),
          (v39/*: any*/),
          (v40/*: any*/),
          (v41/*: any*/),
          (v42/*: any*/),
          (v43/*: any*/),
          (v44/*: any*/),
          (v45/*: any*/),
          (v46/*: any*/),
          (v47/*: any*/),
          (v48/*: any*/),
          (v49/*: any*/),
          (v50/*: any*/),
          (v51/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "getOmichannelPreviewsQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "hyreferralCampaign",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "HyreferralCampaign",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "residentInviteImage",
            "storageKey": null,
            "args": null,
            "concreteType": "Asset",
            "plural": false,
            "selections": (v52/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "image",
            "storageKey": null,
            "args": null,
            "concreteType": "Asset",
            "plural": false,
            "selections": (v52/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "residentDiscountImage",
            "storageKey": null,
            "args": null,
            "concreteType": "Asset",
            "plural": false,
            "selections": (v52/*: any*/)
          },
          (v17/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/),
          (v20/*: any*/),
          (v21/*: any*/),
          (v22/*: any*/),
          (v23/*: any*/),
          (v24/*: any*/),
          (v25/*: any*/),
          (v26/*: any*/),
          (v27/*: any*/),
          (v28/*: any*/),
          (v29/*: any*/),
          (v30/*: any*/),
          (v31/*: any*/),
          (v32/*: any*/),
          (v33/*: any*/),
          (v34/*: any*/),
          (v35/*: any*/),
          (v36/*: any*/),
          (v37/*: any*/),
          (v38/*: any*/),
          (v39/*: any*/),
          (v40/*: any*/),
          (v41/*: any*/),
          (v42/*: any*/),
          (v43/*: any*/),
          (v44/*: any*/),
          (v45/*: any*/),
          (v46/*: any*/),
          (v47/*: any*/),
          (v48/*: any*/),
          (v49/*: any*/),
          (v50/*: any*/),
          (v51/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "getOmichannelPreviewsQuery",
    "id": null,
    "text": "query getOmichannelPreviewsQuery(\n  $id: ID!\n) {\n  hyreferralCampaign(id: $id) {\n    id\n    reward\n    active\n    residentInviteEmailCampaignHtml\n    residentRewardInviteEmailCampaignHtml\n    friendInviteEmailCampaignHtml\n    residentEnrollEmailCampaignHtml\n    emailSubject\n    residentInviteEmailSubject\n    residentDiscountEmailSubject\n    residentThankYouEmailSubject\n    residentInviteEmailBody\n    residentInviteSms\n    residentInviteImage {\n      fileUrl\n      id\n    }\n    image {\n      fileUrl\n      id\n    }\n    residentDiscountImage {\n      fileUrl\n      id\n    }\n    emailSms\n    residentDiscountEmailSms\n    tourCtaUrl\n    contactCtaUrl\n    propertyId\n    emailEnabled\n    facebookEnabled\n    smsEnabled\n    twitterEnabled\n    bannerText\n    ctaText\n    pitchMessage\n    residentEnrollHeader\n    residentEnrollFooter\n    shortEnrollUrl\n    propertyName\n    shortTourCtaUrl\n    linkColor\n    fontFamily\n    textColor\n    buttonBorderColor\n    buttonFontColor\n    buttonBackgroundColor\n    noscript\n    script\n    rewardEmailHeader\n    friendInviteEmailHeader\n    friendInviteMenuHeader\n    friendInviteMenuBody\n    residentBannerTitle\n    popupMessage\n    popupTitle\n    facebookShareUrl\n    propertyLogoUrl\n    emailValidationStatus\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6eab6978aaf5092a99c4423ca429274d';
module.exports = node;
