import React from "react";

export default function TourNotClosedIcon({
  className = "text-[#999999]",
  width = 18,
  height = 18,
}) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3 7H15" stroke="currentColor" stroke-linecap="round" strokeLinejoin="round" />
      <path
        d="M15 9.39961V5.39961C15 5.18744 14.9157 4.98395 14.7657 4.83392C14.6157 4.68389 14.4122 4.59961 14.2 4.59961H3.8C3.58783 4.59961 3.38434 4.68389 3.23431 4.83392C3.08429 4.98395 3 5.18744 3 5.39961V14.1996C3 14.4118 3.08429 14.6153 3.23431 14.7653C3.38434 14.9153 3.58783 14.9996 3.8 14.9996H7.8"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path d="M6.2002 3V4.6" stroke="currentColor" stroke-linecap="round" strokeLinejoin="round" />
      <path
        d="M11.7998 3V4.6"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.7935 14.5153L12.4136 11.4526C12.4459 11.3914 12.4944 11.3403 12.5536 11.3046C12.6129 11.2689 12.6808 11.25 12.75 11.25C12.8192 11.25 12.8871 11.2689 12.9464 11.3046C13.0056 11.3403 13.0541 11.3914 13.0864 11.4526L14.7065 14.5153C14.7369 14.5731 14.7518 14.6378 14.7498 14.7031C14.7478 14.7684 14.7289 14.832 14.695 14.8878C14.661 14.9436 14.6132 14.9897 14.5562 15.0215C14.4992 15.0533 14.4349 15.0698 14.3695 15.0694H11.1297C11.0645 15.0697 11.0003 15.0531 10.9433 15.0212C10.8864 14.9893 10.8387 14.9433 10.8049 14.8875C10.771 14.8317 10.7522 14.7681 10.7502 14.7029C10.7482 14.6377 10.7631 14.5731 10.7935 14.5153V14.5153Z"
        stroke="currentColor"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
    </svg>
  );
}
