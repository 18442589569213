import React from "react";

export default function NotificationTextIcon({
  className = "text-[#999999]",
  width = 18,
  height = 18,
}) {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5454 4.0918H3.54541V11.5918H7.04541L9.04541 14.0918L11.0454 11.5918H14.5454V4.0918Z"
        stroke="#999999"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.54541 6.5918H11.5454"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.54541 9.0918H9.54541"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
