import React from "react";

export default function LaunchIcon({ className = "text-[#999999]", size = 20 }) {
  return (
    <svg
      width={size}
      className={className}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.2081 4.79163L9.65259 10.3472"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.3477 4.79163H15.2088V9.65274"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.1251 11.0417V14.1667C13.1251 14.4429 13.0153 14.7079 12.82 14.9032C12.6246 15.0986 12.3597 15.2083 12.0834 15.2083H5.83341C5.55715 15.2083 5.2922 15.0986 5.09685 14.9032C4.90149 14.7079 4.79175 14.4429 4.79175 14.1667V7.91667C4.79175 7.6404 4.90149 7.37545 5.09685 7.1801C5.2922 6.98475 5.55715 6.875 5.83341 6.875H8.95841"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
