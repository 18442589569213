/**
 * @flow
 * @relayHash 7bf80abd2e3020fe803a020ae7fddaac
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type updateHayleySettingInput = {|
  propertyId: string,
  supportMultiLanguage?: ?boolean,
  makePhoneNumberRequired?: ?boolean,
  showAllUnits?: ?boolean,
  offerDescription?: ?string,
  dynamicEndDateText?: ?string,
  offerCtaType?: ?string,
|};
export type updateHayleySettingMutationVariables = {|
  input: updateHayleySettingInput
|};
export type updateHayleySettingMutationResponse = {|
  +updateHayleySetting: ?{|
    +response: ?{|
      +hidePriceRangeFilter: ?boolean,
      +supportMultiLanguage: ?boolean,
      +promotion: ?string,
      +makePhoneNumberRequired: ?boolean,
      +showAllUnits: ?boolean,
    |}
  |}
|};
export type updateHayleySettingMutation = {|
  variables: updateHayleySettingMutationVariables,
  response: updateHayleySettingMutationResponse,
|};
*/


/*
mutation updateHayleySettingMutation(
  $input: updateHayleySettingInput!
) {
  updateHayleySetting(input: $input) {
    response {
      hidePriceRangeFilter
      supportMultiLanguage
      promotion
      makePhoneNumberRequired
      showAllUnits
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "updateHayleySettingInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "hidePriceRangeFilter",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "supportMultiLanguage",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "promotion",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "makePhoneNumberRequired",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "showAllUnits",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "updateHayleySettingMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateHayleySetting",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "updateHayleySettingPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "response",
            "storageKey": null,
            "args": null,
            "concreteType": "Hayley",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "updateHayleySettingMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateHayleySetting",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "updateHayleySettingPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "response",
            "storageKey": null,
            "args": null,
            "concreteType": "Hayley",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "updateHayleySettingMutation",
    "id": null,
    "text": "mutation updateHayleySettingMutation(\n  $input: updateHayleySettingInput!\n) {\n  updateHayleySetting(input: $input) {\n    response {\n      hidePriceRangeFilter\n      supportMultiLanguage\n      promotion\n      makePhoneNumberRequired\n      showAllUnits\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8de2c3285d79e8f4e22543700e167894';
module.exports = node;
