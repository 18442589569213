import React from "react";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import withStyles from "@mui/styles/withStyles";

const styles = (theme) => ({
  root: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    flex: 1,
  },
  loader: {
    color: theme.palette.hylyBlue,
  },
});

const Loader = ({ classes: { root, loader }, size }) => {
  return (
    <Grid container className={root} direction="column" justifyContent="center" alignItems="center">
      <CircularProgress size={size} className={loader} />
    </Grid>
  );
};

const simple_loader_styles = (theme) => ({
  container: {
    textAlign: "center",
  },
  loader: {
    color: theme.palette.hylyBlue,
  },
});

export const SimpleLoader = React.memo(
  withStyles(simple_loader_styles)(({ classes, size }) => (
    <div className={classes.container}>
      <CircularProgress size={size} className={classes.loader} />
    </div>
  ))
);

export default React.memo(withStyles(styles)(Loader));
