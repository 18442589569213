/**
 * @flow
 * @relayHash ae83ea240c50246e25d9ffe2415f0e79
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getReportsStatQueryVariables = {|
  endDate: string,
  startDate: string,
  orgId: string,
  propertyIds?: ?$ReadOnlyArray<string>,
|};
export type getReportsStatQueryResponse = {|
  +hayleyReports: any
|};
export type getReportsStatQuery = {|
  variables: getReportsStatQueryVariables,
  response: getReportsStatQueryResponse,
|};
*/


/*
query getReportsStatQuery(
  $endDate: String!
  $startDate: String!
  $orgId: ID!
  $propertyIds: [String!]
) {
  hayleyReports(endDate: $endDate, startDate: $startDate, orgId: $orgId, propertyIds: $propertyIds)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "endDate",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "startDate",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "orgId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "propertyIds",
    "type": "[String!]",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "hayleyReports",
    "args": [
      {
        "kind": "Variable",
        "name": "endDate",
        "variableName": "endDate"
      },
      {
        "kind": "Variable",
        "name": "orgId",
        "variableName": "orgId"
      },
      {
        "kind": "Variable",
        "name": "propertyIds",
        "variableName": "propertyIds"
      },
      {
        "kind": "Variable",
        "name": "startDate",
        "variableName": "startDate"
      }
    ],
    "storageKey": null
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getReportsStatQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "getReportsStatQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "getReportsStatQuery",
    "id": null,
    "text": "query getReportsStatQuery(\n  $endDate: String!\n  $startDate: String!\n  $orgId: ID!\n  $propertyIds: [String!]\n) {\n  hayleyReports(endDate: $endDate, startDate: $startDate, orgId: $orgId, propertyIds: $propertyIds)\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4546c23971d7f3e17a360e354d5aecef';
module.exports = node;
