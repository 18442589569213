import React from "react";

export default function EmailReceivedIcon({
  className = "text-[#999999]",
  width = 18,
  height = 18,
}) {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33545 4.30469L8.99999 9.45432L14.6645 4.30469"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.9091 4H4.09091C3.48842 4 3 4.48842 3 5.09091V12.7273C3 13.3298 3.48842 13.8182 4.09091 13.8182H13.9091C14.5116 13.8182 15 13.3298 15 12.7273V5.09091C15 4.48842 14.5116 4 13.9091 4Z"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
