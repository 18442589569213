/**
 * @flow
 * @relayHash e1bb94fead74f71639c2641f5cb8ce9e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type LoadNotifQueryVariables = {|
  id: string,
  scope?: ?string,
  status?: ?string,
|};
export type LoadNotifQueryResponse = {|
  +notifications: {|
    +id: string,
    +notifications: $ReadOnlyArray<{|
      +id: string,
      +createdAt: any,
      +status: string,
      +header: string,
      +body: ?string,
      +sourceId: ?string,
      +sourceType: ?string,
      +sourceStatus: ?string,
    |}>,
  |}
|};
export type LoadNotifQuery = {|
  variables: LoadNotifQueryVariables,
  response: LoadNotifQueryResponse,
|};
*/


/*
query LoadNotifQuery(
  $id: ID!
  $scope: String
  $status: String
) {
  notifications(id: $id, scope: $scope, status: $status) {
    id
    notifications {
      id
      createdAt
      status
      header
      body
      sourceId
      sourceType
      sourceStatus
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "scope",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "status",
    "type": "String",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "notifications",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "scope",
        "variableName": "scope"
      },
      {
        "kind": "Variable",
        "name": "status",
        "variableName": "status"
      }
    ],
    "concreteType": "NotificationsContainer",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "notifications",
        "storageKey": null,
        "args": null,
        "concreteType": "Notification",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "createdAt",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "status",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "header",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "body",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "sourceId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "sourceType",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "sourceStatus",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "LoadNotifQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "LoadNotifQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "LoadNotifQuery",
    "id": null,
    "text": "query LoadNotifQuery(\n  $id: ID!\n  $scope: String\n  $status: String\n) {\n  notifications(id: $id, scope: $scope, status: $status) {\n    id\n    notifications {\n      id\n      createdAt\n      status\n      header\n      body\n      sourceId\n      sourceType\n      sourceStatus\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ad67f172cfb2bd9960298480a7f4ae8b';
module.exports = node;
