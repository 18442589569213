import graphql from "babel-plugin-relay/macro";

export default graphql`
  query getPropertyDataQuery($id: ID, $offerId: ID,$propertyIds: [String!],$campaignIds: [String!],$startAt: String,$endAt: String,$concession: [String!],$after: String,$before: String,$first: Int,$last: Int) {
    propertyData(orgId: $id, offerId: $offerId,propertyIds:$propertyIds,campaignIds:$campaignIds,startAt:$startAt,endAt:$endAt,concession:$concession,after:$after,before:$before,first:$first,last:$last) {
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      }
      edges{
        cursor
        node{
          concession
          emailScheduledAt
          endDate
          facebookSharedAt
          id
          includeOrExclude
          offerId
          offerTitle
          orgId
          orgName
          propertyId
          propertyName
          startDate
          value
          facebookShareUrl
          campaignTitle
          clicked
          endingIn
          leased
          propertyDataId
          toured
          macroDict
        }
      }
    }
  }
`;
