/**
 * @flow
 * @relayHash aaae4ba8480699d148d5203fd3369f70
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type saveHyeventsSettingInput = {|
  clientMutationId?: ?string,
  orgId: string,
  propertyId?: ?string,
  approvalRequired: boolean,
  approverEmails: $ReadOnlyArray<?string>,
  agentApprovalEmails: $ReadOnlyArray<?string>,
  hyeventsBccEmails?: ?$ReadOnlyArray<?string>,
|};
export type setSettingsMutationVariables = {|
  input: saveHyeventsSettingInput
|};
export type setSettingsMutationResponse = {|
  +saveHyeventsSetting: ?{|
    +hyeventsSetting: ?{|
      +approvalRequired: boolean,
      +approverEmails: $ReadOnlyArray<string>,
      +agentApprovalEmails: $ReadOnlyArray<string>,
      +hyeventsBccEmails: ?$ReadOnlyArray<string>,
    |}
  |}
|};
export type setSettingsMutation = {|
  variables: setSettingsMutationVariables,
  response: setSettingsMutationResponse,
|};
*/


/*
mutation setSettingsMutation(
  $input: saveHyeventsSettingInput!
) {
  saveHyeventsSetting(input: $input) {
    hyeventsSetting {
      approvalRequired
      approverEmails
      agentApprovalEmails
      hyeventsBccEmails
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "saveHyeventsSettingInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "approvalRequired",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "approverEmails",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "agentApprovalEmails",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "hyeventsBccEmails",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "setSettingsMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "saveHyeventsSetting",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "saveHyeventsSettingPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "hyeventsSetting",
            "storageKey": null,
            "args": null,
            "concreteType": "HyeventsSetting",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "setSettingsMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "saveHyeventsSetting",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "saveHyeventsSettingPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "hyeventsSetting",
            "storageKey": null,
            "args": null,
            "concreteType": "HyeventsSetting",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "setSettingsMutation",
    "id": null,
    "text": "mutation setSettingsMutation(\n  $input: saveHyeventsSettingInput!\n) {\n  saveHyeventsSetting(input: $input) {\n    hyeventsSetting {\n      approvalRequired\n      approverEmails\n      agentApprovalEmails\n      hyeventsBccEmails\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9edf4fbd311716891d0c4925aedbd4a5';
module.exports = node;
