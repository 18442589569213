import React from "react";

export const ReferralIcon = ({ stroke = "#666", fill = "none" }) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path d="M16 13.5455V16.8182L12.5636 19.3545" stroke={stroke} strokeMiterlimit="10" />
    <path d="M16 16.8182L19.4364 19.3545" stroke={stroke} strokeMiterlimit="10" />
    <path
      d="M16 13.5455C17.8075 13.5455 19.2727 12.0802 19.2727 10.2727C19.2727 8.46525 17.8075 7 16 7C14.1925 7 12.7273 8.46525 12.7273 10.2727C12.7273 12.0802 14.1925 13.5455 16 13.5455Z"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M10.2727 25C12.0802 25 13.5455 23.5347 13.5455 21.7273C13.5455 19.9198 12.0802 18.4545 10.2727 18.4545C8.46525 18.4545 7 19.9198 7 21.7273C7 23.5347 8.46525 25 10.2727 25Z"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M21.7273 25C23.5348 25 25 23.5347 25 21.7273C25 19.9198 23.5348 18.4545 21.7273 18.4545C19.9198 18.4545 18.4546 19.9198 18.4546 21.7273C18.4546 23.5347 19.9198 25 21.7273 25Z"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
);

export const DeleteIcon = ({ stroke = "#333333", fill = "none" }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.41699 8.75V14.5833C5.41699 15.5042 6.16283 16.25 7.08366 16.25H13.7503C14.6712 16.25 15.417 15.5042 15.417 14.5833V8.75"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.5835 6.25H16.2502"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.75 6.25V3.75H12.0833V6.25"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.417 9.58301V13.7497"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.917 9.58301V13.7497"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.91699 9.58301V13.7497"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const RefreshIcon = ({ stroke = "#333333", fill = "none" }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.1181 15.3418C16.3556 16.5091 15.2169 17.3806 13.8909 17.8116C12.5649 18.2426 11.1314 18.2072 9.82828 17.7113C8.52516 17.2154 7.4308 16.2889 6.72681 15.0854C6.02282 13.8819 5.75155 12.4738 5.958 11.0949C6.16446 9.71598 6.83623 8.44913 7.86183 7.50459C8.88744 6.56005 10.2052 5.99462 11.5964 5.90214C12.9876 5.80965 14.3686 6.19568 15.5102 6.99616C16.6518 7.79663 17.4854 8.96342 17.8725 10.3029"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.1112 5.88867V10.3331L13.7012 9.78534"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const DuplicateIcon = ({ stroke = "#333333", fill = "none" }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.7242 6.27588H4.5575V16.5884H13.7242V6.27588Z"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M6.27625 3.98438H16.0158V15.4427"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M6.84894 9.14062H11.4323"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M6.84894 11.4321H11.4323"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M6.84894 13.7241H9.1406"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
);

export const EditIcon = ({ stroke = "#333333", fill = "none" }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.7651 5.5957L14.9597 8.79029" stroke={stroke} strokeMiterlimit="10" />
    <path
      d="M7.72212 16.0278L3.88861 16.6668L4.52753 12.8332L13.098 4.2628C13.3376 4.02325 13.6626 3.88867 14.0014 3.88867C14.3402 3.88867 14.6652 4.02325 14.9048 4.2628L16.2926 5.65053C16.5321 5.89016 16.6667 6.21513 16.6667 6.55396C16.6667 6.8928 16.5321 7.21776 16.2926 7.45739L7.72212 16.0278Z"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
);
