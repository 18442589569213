import React from "react";
import { withRouter } from "react-router-dom";
import Preview from "storybookComponents/Preview/dialog";
import Loader from "storybookComponents/HylyLoader";
import Error from "error";
import * as apis from "relay/eBlasts";

class PreviewNewTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: false,
      preview: "",
      preview_open: true,
    };
  }

  componentDidMount() {
    document.title = "Preview < hyBlast";
    const { propertyId, id } = this.props.match.params;
    this._loadData(propertyId, id);
  }

  _loadData = async (propertyId, id) => {
    let preview;
    if (id.endsWith("==")) {
      preview = await this._getTemplatePreview(propertyId, id);
    } else {
      preview = await this._gethyblastEditorPreview(id);
    }
    if (preview) {
      this.success(preview);
    } else {
      this.failed();
    }
  };

  _gethyblastEditorPreview = (id) => {
    return new Promise(function (resolve, reject) {
      apis.gethyblastEditorPreview(id).then((res, err) => {
        if (err || res.errors || res.error) {
          resolve(false);
        }
        resolve(res.hyblastEditor.substitutedMacrosHtml);
      });
    });
  };

  _getTemplatePreview = (propertyId, id) => {
    return new Promise(function (resolve, reject) {
      apis.getTemplatePreview("any", propertyId, id).then((res, err) => {
        if (err || res.errors || res.error) {
          resolve(false);
        }
        resolve(res.templates.edges[0].node.substitutedMacrosHtml);
      });
    });
  };

  success = (preview) => {
    this.setState({
      loading: false,
      preview,
    });
  };

  failed = () => {
    this.setState({
      loading: false,
      error: true,
    });
  };

  render() {
    const { loading, error, preview_open, preview } = this.state;
    if (loading) {
      return <Loader />;
    } else if (error) {
      return <Error />;
    } else {
      return <Preview preview_open={preview_open} preview={preview} />;
    }
  }
}

export default withRouter(PreviewNewTab);
