import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  orgs: {},
  properties: [],
};

const orgsPropertiesSlice = createSlice({
  name: "OrgProperties",
  initialState,
  reducers: {
    setOrgs: (state, action) => {
      state.orgs = action.payload;
    },
    setProperty: (state, action) => {
      state.properties = action.payload;
    },
  },
});

export const getStoredOrgs = (state) => state.OrgProperties.orgs;
export const getStoredProperties = (state) => state.OrgProperties.properties;

export const { setProperty, setOrgs } = orgsPropertiesSlice.actions;
export default orgsPropertiesSlice.reducer;
