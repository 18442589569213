export const analyzeTemplate = (template, num, type) => {
  if (type === "icon") {
    return;
  }

  const re = /([A-Za-z0-9_]+)([+]+[s]+\b)/g;
  return template.replace(/selected/g, num).replace(re, (m, res, m2) => {
    if (num === 1) {
      return res;
    }
    if (res.endsWith("y")) {
      return res.slice(0, res.length - 1) + "ies";
    }
    return res + "s";
  });
};

export const select_class_for_menu = ({
  direction,
  fullWidth,
  classes,
  no_adornment,
  drop_down_menu_button,
}) => {
  if (Boolean(fullWidth)) {
    return direction === "right"
      ? classes.paper_class_with_full_size_on_small_screens_right
      : classes.paper_class_with_full_size_on_small_screens;
  }

  return direction === "right" && no_adornment
    ? classes.paper_class_no_adornment_right
    : direction === "left" && no_adornment
    ? classes.paper_class_no_adornment
    : direction === "right" && drop_down_menu_button
    ? classes.paper_class_right_button
    : direction === "right"
    ? classes.paper_class_right
    : classes.paper_class;
};

const paper_class = {
  //minWidth:'112px',
  //  maxWidth:'300px',
  //  marginLeft: -44,
  //  minWidth:'112px',
  minWidth: 100,
  transform: "translate(-36px, 10px) !important",
  //  transform:'translate(-28px, 16px) !important',
  //  maxWidth:'300px'
};
const paper_class_right = {
  minWidth: 100,
  transform: "translate(28px, 24px) !important",
};

const paper_class_right_button = {
  minWidth: 100,
  transform: "translate(38px, 24px) !important",
};

const paper_class_no_adornment = {
  ...paper_class,
  //marginLeft: 0
  transform: "translateX(0px) !important",
};

const paper_class_no_adornment_right = {
  ...paper_class_right,
  //  marginLeft:0,
  transform: "translateX(0px) !important",
};

export default (theme) => ({
  formControl: {
    //  width:'100%',
    //  margin: '24px 0'
  },

  formControl_with_border: {
    border: `1px solid ${theme.palette.lightGrey}`,
  },
  box_root: {
    fontSize: "5px !important",
    margin: `0 16px 0 0`,
    padding: "0",
  },
  colorSecondary: {
    "&$checked": {
      color: theme.palette.hylyBlue,
    },
    "&$disabled": {
      color: theme.palette.action.disabled,
    },
  },
  root: {
    width: "100%",
    cursor: "pointer",
    fontSize: 16,
  },
  root_field: {
    dispay: "inline-flex",
    flex: 1,
    minWidth: "100px",
    height: 18,
  },
  rootOpen: {
    background: theme.palette.hoverColor,
    color: theme.darkBlack,
    fontWeight: 600,
    /*  '&:hover:not($disabled):not($selected):not($checked)':{
        background: theme.palette.hoverColor
      }*/
  },
  select: {
    //  padding:`${theme.margins.xs}px ${theme.margins.xs}px ${theme.margins.xs}px 0`,
    //  display:'inline-block',
    width: "100%",
    "&:focus": {
      background: "#ffffff00",
    },
  },
  select_field: {
    padding: 0,
  },
  disable_padding_right: {
    //  padding: `${theme.spacing_unit.sm}px 0`
  },
  setBold: {
    fontWeight: theme.fontWeight.bold,
  },
  afterFocus: {
    background: theme.palette.hoverColor, //'rgba(0, 0, 0, 0.05)'//theme.palette.primary.contrustText
  },
  paper_class_with_full_size_on_small_screens: {
    //transform:'translateX(16px) !important',
    [theme.breakpoints.up("sm")]: {
      transform: "translate(-36px, 10px) !important",
      //  maxWidth:'300px'
    },
    [theme.breakpoints.down("lg")]: {
      width: "100%",
      maxWidth: "100%",
    },
  },
  paper_class_with_full_size_on_small_screens_right: {
    [theme.breakpoints.up("sm")]: {
      marginLeft: -16,
      ...paper_class_right,
    },
    [theme.breakpoints.down("lg")]: {
      width: "calc(100% - 48px)",
      marginLeft: -16,
    },
  },
  paper_class,
  paper_class_right,
  paper_class_right_button,
  paper_class_no_adornment,
  paper_class_no_adornment_right,
  listItemText: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    //padding:`0 ${theme.margins.xs}px`,
    [theme.breakpoints.up("sm")]: {
      //padding:`0 ${theme.margins.sm}px`,
    },
  },
  set_icon_pos: {
    transform: "translateY(3px)",
  },
  menuItem: {
    // padding:`${theme.spacing_unit.md/2}px ${theme.margins.xs}px`,
    padding: "8px 16px",
    "&:hover": {
      //'&:hover:not($disabled):not($selected)':{
      background: "#f5f5f5", //theme.palette.hoverColor
    },
  },
  single_text_class: {
    justifySelf: "flex-end",
  },
  disable_margins: {
    minWidth: 0,
    margin: `0 ${theme.margins.xs}px 0 0`,
  },
  disable_padding: {
    padding: 0,
  },
  selected: {},
  checked: {},
  disabled: {},
  focused: {},

  ////////////////////////

  input_field: {
    ...theme.typography.body1,
    color: theme.palette.black,
    padding: `4px 0`,
  },
  underline: {
    "&:before": {
      borderBottom: `1px solid ${theme.palette.lightGrey}`,
    },
    "&:hover:not($disabled):not($focused):not($error):before": {
      borderBottomColor: theme.palette.mediumGrey,
    },
    "&:after": {
      borderBottom: `1px solid ${theme.palette.deepGrey}`,
    },
  },
  error: {},
});
