import React, { useEffect, useState } from "react";
import clsx from "clsx";
import {
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableRow,
  Paper,
  Checkbox,
  Box,
  Typography,
  TableFooter,
  Skeleton,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { EmptyTableIcon } from "icons";

import ArrowIcon from "./ArrowIcon";
import StyledTableRow from "./StyledTableRow";
import StyledTableCell from "./StyledTableCell";
import TitleBar from "./components/TitleBar";
import Row from "./components/Row";
import ToggleRowHead from "./components/ToggleRowHead";
import "./styles.css";
import { Loader } from "storybookComponents";

const useTableStyles = makeStyles({
  tableContainer: {
    width: "100%",
    border: "none",
    borderRadius: 0,
    boxShadow: "none",
  },
  tableHeadRow: {
    //border: "1px solid #dadada",
    // borderBottom: "2px solid #dadada",
    // position: "sticky",
    // top: "20px",
  },
  sort: {
    cursor: "pointer",
    "&:hover": {
      color: "var(--hylyTheme)",
    },
    "&:hover path": {
      stroke: "var(--hylyTheme)",
    },
  },
  sortDown: {
    color: "#333",
    width: 12,
    height: 14,
    fill: "none",
    marginLeft: 7,
  },
  sortUp: {
    transform: "rotate(-180deg)",
    color: "#333",
    width: 12,
    height: 14,
    fill: "none",
    marginLeft: 7,
  },
  stickyBottom: {
    position: "sticky",
    bottom: -1,
    background: "white",
  },
  emptyScreen: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 83,
  },
  centerContainer: {
    textAlignLast: "center",
    position: "relative",
  },
  textMargin: {
    // marginTop: 12,
    color: "#333",
    fontSize: 14,
    marginTop: 12,
    lineHeight: "20px",
    fontWeight: 400,
  },
  header: {
    position: "sticky",
    top: "0px !important",
    background: "white",
    borderTop: "1px solid #dadada",
    borderBottom: "1px solid #dadada",
  },
  defaultOverflow: {
    // overflow: "inherit",
  },
  filterBox: { minHeight: 40, display: "flex", alignItems: "center" },
  totalRow: {
    fontWeight: 600,
  },
  table: {
    border: "1px solid #dadada",
    borderTop: 0,
    borderCollapse: "separate",
  },
  filterHidden: {
    "& i": {
      display: "none",
    },
    visibility: "hidden",
    marginTop: -40,
  },
  paginationItem: (props) => ({
    "& li": {
      "& button": {
        borderRadius: "2px !important",
        display: (props) => props.count < 4 && "none",
        "&:hover": {
          backgroundColor: "#F9F9F9",
          color: "var(--hylyTheme)",
        },
        "&:disabled": {
          display: "none",
        },
      },
    },
    "& .Mui-selected": {
      borderRadius: "2px",
      border: "1px solid #EEE",
      fontWeight: 600,
      backgroundColor: "white",
    },
  }),
  selectBoxStyle: (props) => ({
    paddingLeft: 17,
    height: "19px !important",
    paddingRight: "0px important",
    paddingTop: "3px !important",
    paddingBottom: "7px !important",
    minHeight: "auto !important",
    "& span": {
      "&::before": {
        content: (props) => (props.placeholder ? `"${props.placeholder}"` : ""),
        color: "rgba(51, 51, 51, 0.5)",
        fontSize: 14,
      },
    },
    "&:hover": {
      color: "var(--hylyTheme)",
    },
    "&:focus": {
      background: props.background ? props.background : "white",
    },
  }),
});

const StyledTable = ({
  rows,
  columns,
  sortHandler,
  showFilter = false, // boolean value,
  showPercentage = false, // boolean value,
  defaultSortBy,
  sort = false,
  sortData = null,
  noInitialSort = false,
  total,
  loading,
  classes,
  rowClasses,
  hasSubRows,
  hasExpandableRow = false, // If table has the expanded row
  expandableTableOptions, // Available props: toggleRowCallback, toggleRowHeadClass, toggleRowCellClass
  disableHead = false, // Boolean value for disable / enable TableHead visibility
  emptyBoxHeight = 400,
  autoHeight = true,
  convertToPercentage,
  tableData,
  ...rest
}) => {
  const tableClasses = useTableStyles();
  const [sortOrder, setSortOrder] = useState(sort); // true: sort down, false: sort up
  const [sortBy, setSortBy] = useState(defaultSortBy || "");
  const [search, setSearch] = useState(showFilter);
  const [switcher, setSwitcher] = useState(showPercentage);

  useEffect(() => {
    if (defaultSortBy && !noInitialSort) {
      handleSort(defaultSortBy);
    }
  }, [1]);

  useEffect(() => {
    if (sortData) {
      setSortBy(sortData.sortBy);
      setSortOrder(sortData.sortOrder);
    }
  }, sortData);

  useEffect(() => {
    setSearch(showFilter);
    setSwitcher(showFilter);
  }, [showFilter]);

  useEffect(() => {
    setSwitcher(showPercentage);
  }, [showPercentage]);

  const handleSort = (field) => {
    const order = sortBy === field ? !sortOrder : sortOrder;
    setSortOrder(order);
    setSortBy(field);
    sortHandler(field, order);
  };

  useEffect(() => {
    if (!autoHeight) {
      return;
    }

    try {
      if (rows?.length === 0) {
        const ele = document.getElementById("styled-table-empty-view");
        if (ele) {
          let topOffset = ele.getClientRects()[0].top + 70 + "px";
          ele.style.height = `calc(100vh - ${topOffset})`;
        }
      }
    } catch (err) {
      console.log("Err", err);
    }
  }, [rows?.length]);

  const onToggleSearch = () => {
    setSearch(!search);
  };

  const onToggleSwitcher = () => {
    convertToPercentage(!switcher);
    setSwitcher(!switcher);
  };

  return (
    <div>
      {rest.titlebar && (
        <TitleBar
          search={search}
          switcher={switcher}
          onToggleSearch={onToggleSearch}
          onToggleSwitcher={onToggleSwitcher}
          tableData={tableData}
          {...rest}
        />
      )}
      <TableContainer
        component={Paper}
        className={clsx(tableClasses.tableContainer, tableClasses.defaultOverflow, classes?.root)}
      >
        <Table className={clsx(tableClasses.table, classes?.tableRoot)}>
          {!disableHead && (
            <TableHead>
              <TableRow className={tableClasses.tableHeadRow}>
                {hasExpandableRow && (
                  <ToggleRowHead className={expandableTableOptions?.toggleRowHeadClass ?? ""} />
                )}

                {columns.map((item, index) => (
                  <StyledTableCell
                    align="left"
                    key={index}
                    width={item.width}
                    className={clsx("table-header", tableClasses.header, item.className)}
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      className={index === columns.length - 1 ? classes?.tableCell : ""}
                      style={{
                        justifyContent: item.align === "right" ? "flex-end" : "flex-start",
                      }}
                    >
                      {item.selectable && (
                        <Checkbox
                          onChange={item.selectProps.selectAll}
                          checked={item.selectProps.checked}
                          indeterminate={item.selectProps.itemindeterminate}
                          classes={item.selectProps.classes}
                        />
                      )}
                      <Typography
                        className={clsx(item.className, {
                          [tableClasses.sort]: tableData?.length > 0 || !tableData,
                        })}
                        onClick={item.sortable ? () => handleSort(item.field) : () => {}}
                        noWrap
                      >
                        {(tableData?.length > 0 || !tableData) &&
                          item.sortable &&
                          item.fieldName &&
                          item.align === "right" && (
                            <ArrowIcon
                              onClick={() => handleSort(item.field)}
                              viewBox="0 0 8 11"
                              className={clsx(
                                item.field === sortBy ? "" : "arrow-icon",
                                sortOrder && item.field === sortBy
                                  ? tableClasses.sortDown
                                  : tableClasses.sortUp
                              )}
                            />
                          )}
                        <span className={item.align === "right" && item.fieldName && "ml-2"}>
                          {item.fieldName}
                        </span>
                        {(tableData?.length > 0 || !tableData) &&
                          item.sortable &&
                          item.fieldName &&
                          item.align !== "right" && (
                            <ArrowIcon
                              onClick={() => handleSort(item.field)}
                              viewBox="0 0 8 11"
                              className={clsx(
                                item.field === sortBy ? "" : "arrow-icon",
                                sortOrder && item.field === sortBy
                                  ? tableClasses.sortDown
                                  : tableClasses.sortUp
                              )}
                            />
                          )}
                      </Typography>
                      {item.custom && item.html}
                    </Box>
                    <Box
                      className={clsx(
                        tableClasses.filterBox,
                        !search ? tableClasses.filterHidden : ""
                      )}
                      style={{
                        justifyContent: item.align === "right" ? "flex-end" : "flex-start",
                      }}
                    >
                      {item.filterable && item.filterField}
                    </Box>
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
          )}
          {loading ? (
            <TableBody>
              {[...Array(4)].map((_, rowIndex) => (
                <StyledTableRow key={rowIndex}>
                  {columns.map((item, index) => (
                    <StyledTableCell key={index}>
                      <Skeleton />
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              ))}
            </TableBody>
          ) : !rows || rows?.length === 0 ? (
            <TableBody>
              <StyledTableRow>
                <StyledTableCell colSpan={columns?.length}>
                  <div
                    className={tableClasses.emptyScreen}
                    style={{ height: emptyBoxHeight }}
                    id="styled-table-empty-view"
                  >
                    <div className={tableClasses.centerContainer}>
                      <EmptyTableIcon />
                      <div className={tableClasses.textMargin}>
                        No data found. Try using different filters
                      </div>
                    </div>
                  </div>
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          ) : (
            <TableBody>
              {rows &&
                rows.map((row, idx) => (
                  <Row
                    key={idx}
                    data={row}
                    columns={columns}
                    selectedRowId={rest.selectedRowId}
                    selectedRowColor={rest.selectedRowColor}
                    classNames={rowClasses}
                    hasSubRows={hasSubRows ?? false}
                    hasExpandableRow={hasExpandableRow ?? false}
                    toggleRowCallback={(params) => {
                      if (expandableTableOptions && expandableTableOptions.toggleRowCallback) {
                        expandableTableOptions.toggleRowCallback({ rowIdx: idx, ...params });
                      }
                    }}
                    toggleRowCellClass={expandableTableOptions?.toggleRowCellClass ?? ""}
                  />
                ))}

              {total && (
                <StyledTableRow className="table-row">
                  {columns.map((item, index) => {
                    return (
                      <StyledTableCell
                        scope="row"
                        align={item.align || "left"}
                        key={index}
                        className={clsx(
                          item.cellClassName,
                          tableClasses.totalRow,
                          classes?.root ? tableClasses.stickyBottom : ""
                        )}
                      >
                        {total[item.field] === 0 ? 0 : total[item.field] || "--"}
                      </StyledTableCell>
                    );
                  })}
                </StyledTableRow>
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </div>
  );
};

export default StyledTable;
