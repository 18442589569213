/**
 * @flow
 * @relayHash 2cd179521650baa1639ec9c69e9d1774
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getContactListQueryVariables = {|
  orgId: string,
  campaignIds: $ReadOnlyArray<string>,
  type?: ?string,
  startAt?: ?string,
  endAt?: ?string,
  referred?: ?string,
  leased?: ?string,
|};
export type getContactListQueryResponse = {|
  +contactList: $ReadOnlyArray<{|
    +campaignActive: ?boolean,
    +campaignId: ?string,
    +campaignName: ?string,
    +createdAt: any,
    +friend: ?string,
    +friendCrmUrl: ?string,
    +friendEmail: ?string,
    +friendPhone: ?string,
    +friendsCount: ?string,
    +leased: ?boolean,
    +leasedCount: ?string,
    +leasedDate: ?string,
    +propertyId: ?string,
    +propertyName: ?string,
    +referredStatus: ?boolean,
    +resident: ?string,
    +residentCrmUrl: ?string,
    +residentEmail: ?string,
    +residentPhone: ?string,
    +residentsCount: ?string,
    +utmAssist: ?string,
    +utmCampaign: ?string,
    +utmChannel: ?string,
    +utmMedium: ?string,
    +utmMethod: ?string,
    +utmSource: ?string,
  |}>
|};
export type getContactListQuery = {|
  variables: getContactListQueryVariables,
  response: getContactListQueryResponse,
|};
*/


/*
query getContactListQuery(
  $orgId: ID!
  $campaignIds: [String!]!
  $type: String
  $startAt: String
  $endAt: String
  $referred: String
  $leased: String
) {
  contactList(orgId: $orgId, campaignIds: $campaignIds, type: $type, startAt: $startAt, endAt: $endAt, referred: $referred, leased: $leased) {
    campaignActive
    campaignId
    campaignName
    createdAt
    friend
    friendCrmUrl
    friendEmail
    friendPhone
    friendsCount
    leased
    leasedCount
    leasedDate
    propertyId
    propertyName
    referredStatus
    resident
    residentCrmUrl
    residentEmail
    residentPhone
    residentsCount
    utmAssist
    utmCampaign
    utmChannel
    utmMedium
    utmMethod
    utmSource
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "orgId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "campaignIds",
    "type": "[String!]!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "type",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "startAt",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "endAt",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "referred",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "leased",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "campaignIds",
    "variableName": "campaignIds"
  },
  {
    "kind": "Variable",
    "name": "endAt",
    "variableName": "endAt"
  },
  {
    "kind": "Variable",
    "name": "leased",
    "variableName": "leased"
  },
  {
    "kind": "Variable",
    "name": "orgId",
    "variableName": "orgId"
  },
  {
    "kind": "Variable",
    "name": "referred",
    "variableName": "referred"
  },
  {
    "kind": "Variable",
    "name": "startAt",
    "variableName": "startAt"
  },
  {
    "kind": "Variable",
    "name": "type",
    "variableName": "type"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "campaignActive",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "campaignId",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "campaignName",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "createdAt",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "friend",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "friendCrmUrl",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "friendEmail",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "friendPhone",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "friendsCount",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "leased",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "leasedCount",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "leasedDate",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "propertyId",
  "args": null,
  "storageKey": null
},
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "propertyName",
  "args": null,
  "storageKey": null
},
v16 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "referredStatus",
  "args": null,
  "storageKey": null
},
v17 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "resident",
  "args": null,
  "storageKey": null
},
v18 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "residentCrmUrl",
  "args": null,
  "storageKey": null
},
v19 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "residentEmail",
  "args": null,
  "storageKey": null
},
v20 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "residentPhone",
  "args": null,
  "storageKey": null
},
v21 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "residentsCount",
  "args": null,
  "storageKey": null
},
v22 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "utmAssist",
  "args": null,
  "storageKey": null
},
v23 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "utmCampaign",
  "args": null,
  "storageKey": null
},
v24 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "utmChannel",
  "args": null,
  "storageKey": null
},
v25 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "utmMedium",
  "args": null,
  "storageKey": null
},
v26 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "utmMethod",
  "args": null,
  "storageKey": null
},
v27 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "utmSource",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getContactListQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "contactList",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ContactList",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/),
          (v20/*: any*/),
          (v21/*: any*/),
          (v22/*: any*/),
          (v23/*: any*/),
          (v24/*: any*/),
          (v25/*: any*/),
          (v26/*: any*/),
          (v27/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "getContactListQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "contactList",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ContactList",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/),
          (v20/*: any*/),
          (v21/*: any*/),
          (v22/*: any*/),
          (v23/*: any*/),
          (v24/*: any*/),
          (v25/*: any*/),
          (v26/*: any*/),
          (v27/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "getContactListQuery",
    "id": null,
    "text": "query getContactListQuery(\n  $orgId: ID!\n  $campaignIds: [String!]!\n  $type: String\n  $startAt: String\n  $endAt: String\n  $referred: String\n  $leased: String\n) {\n  contactList(orgId: $orgId, campaignIds: $campaignIds, type: $type, startAt: $startAt, endAt: $endAt, referred: $referred, leased: $leased) {\n    campaignActive\n    campaignId\n    campaignName\n    createdAt\n    friend\n    friendCrmUrl\n    friendEmail\n    friendPhone\n    friendsCount\n    leased\n    leasedCount\n    leasedDate\n    propertyId\n    propertyName\n    referredStatus\n    resident\n    residentCrmUrl\n    residentEmail\n    residentPhone\n    residentsCount\n    utmAssist\n    utmCampaign\n    utmChannel\n    utmMedium\n    utmMethod\n    utmSource\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f25f20d7e0c642d24e1e4469d74f6b72';
module.exports = node;
