import graphql from "babel-plugin-relay/macro";

export default graphql`
  query getScheduledReportsQuery($orgId: String!, $type: String!) {
    scheduledReports(orgId: $orgId, type: $type) {
      active
      day
      frequency
      id
      name
      onceDate
      org {
        id
      }
      pdfUrl
      propertyIds
      range
      recipients
      reportType
      type
    }
  }
`;
