const ChangeUserWithCircle = ({
  className = "text-hyly-blue",
  width = 24,
  height = 24,
  viewBox = "0 0 32 32",
}) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" fill="white" />
      <path
        d="M11.25 12.75C9.838 12.75 8.5855 13.069 7.7625 13.3465C7.155 13.552 6.75 14.1245 6.75 14.766V16.75H10.75"
        stroke="currentColor"
        stroke-miterlimit="10"
      />
      <path
        d="M11.25 12.75C9.8695 12.75 8.75 11.1305 8.75 9.75V9.25C8.75 7.8695 9.8695 6.75 11.25 6.75C12.6305 6.75 13.75 7.8695 13.75 9.25V9.75C13.75 11.1305 12.6305 12.75 11.25 12.75Z"
        stroke="currentColor"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
      <path
        d="M14.25 17.75H12.75V16.25L15.75 13.25L17.25 14.75L14.25 17.75Z"
        stroke="currentColor"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
      <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="currentColor" />
    </svg>
  );
};

export default ChangeUserWithCircle;
