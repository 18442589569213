import React from "react";

export default function hyLeadsIcon({ className = "text-[#666666]", width = 18, height = 18 }) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.0001 8.59999C8.32558 8.59999 9.4001 7.52547 9.4001 6.19999C9.4001 4.8745 8.32558 3.79999 7.0001 3.79999C5.67461 3.79999 4.6001 4.8745 4.6001 6.19999C4.6001 7.52547 5.67461 8.59999 7.0001 8.59999Z"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.6 7.79998C13.4837 7.79998 14.2 7.08363 14.2 6.19998C14.2 5.31632 13.4837 4.59998 12.6 4.59998C11.7163 4.59998 11 5.31632 11 6.19998C11 7.08363 11.7163 7.79998 12.6 7.79998Z"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 10.2C5.93913 10.2 4.92172 10.6214 4.17157 11.3716C3.42143 12.1217 3 13.1391 3 14.2H11C11 13.1391 10.5786 12.1217 9.82843 11.3716C9.07828 10.6214 8.06087 10.2 7 10.2V10.2Z"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.2727 9.79997C11.6658 9.53839 12.1277 9.39919 12.5999 9.39997C13.2364 9.39997 13.8469 9.65282 14.297 10.1029C14.747 10.553 14.9999 11.1634 14.9999 11.8V12.6H12.5999"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
