import React from "react";
import PropTypes from "prop-types";

import withStyles from "@mui/styles/withStyles";

import DropDownSearch from "./DropDownSearch";
import SearchInputIcon from "./Inputs/SearchInputIcon";

const styles = (theme) => ({
  input_auto: {
    width: "100%",
    //  alignSelf:'flex-end',
  },
  small_input: {
    width: 42,
    //alignSelf:'flex-end',
    cursor: "pointer",
    background: "white",
    border: "none",
    transition: "width .5s, border-bottom 2s",
    "&:before": {
      borderBottom: `none`,
    },
    "&:after": {
      borderBottom: `none`,
    },
    "&:hover:not($disabled):not($focused):not($error):before": {
      borderBottom: `none`,
    },
  },
  disabled: {},
  focused: {},
  error: {},
});

export default React.memo(
  withStyles(styles)(({ classes, cancel, ...props }) => {
    const [open, set_open] = React.useState(false);

    function grow() {
      set_open(!open);
    }

    function handle_cancel(e) {
      if (typeof cancel === "function") {
        cancel(e);
      }
      set_open(false);
    }

    return (
      <DropDownSearch
        {...props}
        InputSearchComponent={SearchInputIcon}
        open={open}
        cancel={handle_cancel}
        input_props={{
          input_class: open ? classes.input_auto : classes.small_input,
          grow,
          open,
        }}
      />
    );
  })
);
