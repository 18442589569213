import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const initialState = {
  position: "bottom-right",
  refreshValue: 0,
  fetchingData: true,
  propertiesLoaded: false,
  reports: [],
  reportsLoaded: false,
  monthRange: {
    startAt: moment().subtract(30, "day"),
    endAt: moment(),
    label: "Last 30 Days",
  },
  selectedProperty: [],
  selectedProperties: [],
};

const hayleyDetailsSlice = createSlice({
  name: "hayley",
  initialState,
  reducers: {
    setHayleyPosition: (state, action) => {
      state.position = action.payload;
    },
    refreshHayley: (state) => {
      state.refreshValue = !state.refreshValue;
    },
    setFetchingData: (state, action) => {
      state.fetchingData = action.payload;
    },
    formatPropertiesLoaded: (state) => {
      state.propertiesLoaded = false;
    },
    setReports: (state, action) => {
      state.reports = action.payload;
      state.reportsLoaded = true;
    },
    resetReportsLoaded: (state) => {
      state.reportsLoaded = false;
    },
    setHayleyMonthRange: (state, action) => {
      state.monthRange = action.payload;
    },
    setSelectedProperties: (state, action) => {
      state.selectedProperties = action.payload;
      if (action.payload.length > 0) {
        state.selectedProperty = [state.selectedProperties[0]];
      }
    },
  },
});

export const {
  setHayleyPosition,
  refreshHayley,
  setFetchingData,
  formatPropertiesLoaded,
  setReports,
  resetReportsLoaded,
  setHayleyMonthRange,
  setSelectedProperties,
} = hayleyDetailsSlice.actions;

export const selectSelectedProperty = (state) => state.hayley.selectedProperty;
export const selectHayleyMonthRange = (state) => state.hayley.monthRange;
export const selectSelectedProperties = (state) => state.hayley.selectedProperties;

export default hayleyDetailsSlice.reducer;
