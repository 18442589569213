import React from "react";

export default function AlignCenterVerticalIcon({ className = "text-[#333333]", size = 24 }) {
  return (
    <svg
      width={size}
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="1">
        <path
          d="M7.125 12.375H4.125"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M13.875 12.375H10.875"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M20.625 12.375H17.625"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M10.875 7.125H7.125V17.625H10.875V7.125Z"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M17.625 9.375H13.875V15.375H17.625V9.375Z"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
      </g>
    </svg>
  );
}
