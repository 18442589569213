export const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export const NAVBAR_WIDTH = 260;
export const MINUTES = 5;
export const EVENT_CODES = {
  UNAUTH_USER: "UNAUTH_USER",
  UNAUTH_USER_TOAST: "UNAUTH_USER_TOAST",
};

export const VALID_USERS_FOR_AI_WORKSHOP = ["cxp@hy.ly"];
export const VALID_USERS_FOR_FAQS = ["cxp@hy.ly"];

export const DEFAULT_RSVP_EMAIL_BODY =
  "We can’t wait to see you at the Valentine’s Day Party event. Roses are red, Violets are blue, We love having Residents, Just Like You!\n\nStop by the leasing office for cookies, chocolate, and ice cream.  Let's make sure this Valentine's Day is sweet! We look forward to seeing you there!";
