/**
 * @flow
 * @relayHash f72332fca578398d1e3eae079636df18
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getCampaignListForDropDownQueryVariables = {|
  dashboardId: string,
  selectedPropertyIds?: ?$ReadOnlyArray<string>,
  startAt?: ?string,
  endAt?: ?string,
  drip?: ?boolean,
  type?: ?string,
|};
export type getCampaignListForDropDownQueryResponse = {|
  +campaignList: $ReadOnlyArray<{|
    +name: string,
    +propertyId: string,
    +createdAt: any,
    +id: string,
  |}>
|};
export type getCampaignListForDropDownQuery = {|
  variables: getCampaignListForDropDownQueryVariables,
  response: getCampaignListForDropDownQueryResponse,
|};
*/


/*
query getCampaignListForDropDownQuery(
  $dashboardId: ID!
  $selectedPropertyIds: [String!]
  $startAt: String
  $endAt: String
  $drip: Boolean
  $type: String
) {
  campaignList(dashboardId: $dashboardId, selectedPropertyIds: $selectedPropertyIds, startAt: $startAt, endAt: $endAt, drip: $drip, type: $type) {
    name
    propertyId
    createdAt
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "dashboardId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "selectedPropertyIds",
    "type": "[String!]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "startAt",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "endAt",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "drip",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "type",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "campaignList",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "dashboardId",
        "variableName": "dashboardId"
      },
      {
        "kind": "Variable",
        "name": "drip",
        "variableName": "drip"
      },
      {
        "kind": "Variable",
        "name": "endAt",
        "variableName": "endAt"
      },
      {
        "kind": "Variable",
        "name": "selectedPropertyIds",
        "variableName": "selectedPropertyIds"
      },
      {
        "kind": "Variable",
        "name": "startAt",
        "variableName": "startAt"
      },
      {
        "kind": "Variable",
        "name": "type",
        "variableName": "type"
      }
    ],
    "concreteType": "Hyblast",
    "plural": true,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "name",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "propertyId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "createdAt",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getCampaignListForDropDownQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "getCampaignListForDropDownQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "getCampaignListForDropDownQuery",
    "id": null,
    "text": "query getCampaignListForDropDownQuery(\n  $dashboardId: ID!\n  $selectedPropertyIds: [String!]\n  $startAt: String\n  $endAt: String\n  $drip: Boolean\n  $type: String\n) {\n  campaignList(dashboardId: $dashboardId, selectedPropertyIds: $selectedPropertyIds, startAt: $startAt, endAt: $endAt, drip: $drip, type: $type) {\n    name\n    propertyId\n    createdAt\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1b1442de18c2854a86ec0c9e6cbd75d9';
module.exports = node;
