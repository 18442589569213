export const frequencyDayFilters = [
  {
    id: "monday",
    value: "Monday",
  },
  {
    id: "tuesday",
    value: "Tuesday",
  },
  {
    id: "wednesday",
    value: "Wednesday",
  },
  {
    id: "thursday",
    value: "Thursday",
  },
  {
    id: "friday",
    value: "Friday",
  },
  {
    id: "saturday",
    value: "Saturday",
  },
  {
    id: "sunday",
    value: "Sunday",
  },
];

export const rangeFilters = [
  {
    id: "last_7_Days",
    value: "Last 7 Days",
  },
  {
    id: "last_week",
    value: "Last Week",
  },
  {
    id: "this_month",
    value: "This month",
  },
  {
    id: "last_30_days",
    value: "Last 30 days",
  },
  {
    id: "last_month",
    value: "Last Month",
  },
  {
    id: "last_3_months",
    value: "Last 3 Months",
  },
  {
    id: "last_6_months",
    value: "Last 6 Months",
  },
  {
    id: "this_year",
    value: "This Year",
  },
  {
    id: "last_year",
    value: "Last Year",
  },
];

export const frequencyFilterValue = [
  {
    id: "daily",
    value: "Daily",
  },
  {
    id: "weekly",
    value: "Weekly",
  },
  {
    id: "monthly",
    value: "Monthly",
  },
];

export const dateFilters = [
  {
    id: "1",
    value: "1st",
  },
  {
    id: "2",
    value: "2nd",
  },
  {
    id: "3",
    value: "3rd",
  },
  {
    id: "4",
    value: "4th",
  },
  {
    id: "5",
    value: "5th",
  },
  {
    id: "6",
    value: "6th",
  },
  {
    id: "7",
    value: "7th",
  },
  {
    id: "8",
    value: "8th",
  },
  {
    id: "9",
    value: "9th",
  },
  {
    id: "10",
    value: "10th",
  },
  {
    id: "10",
    value: "10th",
  },
  {
    id: "11",
    value: "11th",
  },
  {
    id: "12",
    value: "12th",
  },
  {
    id: "13",
    value: "13th",
  },
  {
    id: "14",
    value: "14th",
  },
  {
    id: "15",
    value: "15th",
  },
  {
    id: "15th",
    value: "15th",
  },
  {
    id: "16",
    value: "16th",
  },
  {
    id: "17",
    value: "17th",
  },
  {
    id: "18",
    value: "18th",
  },
  {
    id: "19",
    value: "19th",
  },
  {
    id: "20",
    value: "20th",
  },
  {
    id: "21",
    value: "21st",
  },
  {
    id: "22",
    value: "22nd",
  },
  {
    id: "23",
    value: "23rd",
  },
  {
    id: "24",
    value: "24th",
  },
  {
    id: "25",
    value: "25th",
  },
  {
    id: "26",
    value: "26th",
  },
  {
    id: "27",
    value: "27th",
  },
  {
    id: "28",
    value: "28th",
  },
  {
    id: "end_of_the_month",
    value: "End Of Month",
  },
];
