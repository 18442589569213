import { commitMutation } from "react-relay";

import environment from "environments/environment";

import updateSelfGuidedTourMutation from "./updateSelfGuidedTour";
import updateeHayleyMutation from "./updateHayley";
import setMascotColorsMutation from "./setMascotColors";
import setMascotDisclaimerMutation from "./setMascotDisclaimer";
import setMascotFirstMessageMutation from "./setMascotFirstMessage";
import setMascotAnimationMutation from "./setMascotAnimation";
import setMascotTextBubbleMutation from "./setMascotTextBubble";
import updateHayleySettingMutation from "./updateHayleySetting";
import uploadHayleyAssetMutation from "./uploadHayleyAsset";
import toggleFeedbackMutation from "./toggleHayleyFeedback";
import hayleyCustomFormQuestionMutation from "./hayleyCustomFormQuestion";
import toggleTourSchedularMutation from "./toggleTourSchedular";
import updateHayleyQualifierButton from "./updateHayleyQualifierButton";

export const updateSelfGuidedTour = (input) => {
  return new Promise((res, rej) => {
    commitMutation(environment, {
      mutation: updateSelfGuidedTourMutation,
      variables: { input },
      onCompleted: res,
      onError: rej,
    });
  });
};

export const updateHayley = (input) => {
  return new Promise((res, rej) => {
    commitMutation(environment, {
      mutation: updateeHayleyMutation,
      variables: { input },
      onCompleted: res,
      onError: rej,
    });
  });
};

export const setMascotColors = (input) => {
  return new Promise((res, rej) => {
    commitMutation(environment, {
      mutation: setMascotColorsMutation,
      variables: { input },
      onCompleted: res,
      onError: rej,
    });
  });
};

export const setMascotDisclaimer = (input) => {
  return new Promise((res, rej) => {
    commitMutation(environment, {
      mutation: setMascotDisclaimerMutation,
      variables: { input },
      onCompleted: res,
      onError: rej,
    });
  });
};

export const setMascotFirstMessage = (input) => {
  return new Promise((res, rej) => {
    commitMutation(environment, {
      mutation: setMascotFirstMessageMutation,
      variables: { input },
      onCompleted: res,
      onError: rej,
    });
  });
};

export const setMascotAnimation = (input) => {
  return new Promise((res, rej) => {
    commitMutation(environment, {
      mutation: setMascotAnimationMutation,
      variables: { input },
      onCompleted: res,
      onError: rej,
    });
  });
};

export const setMascotTextBubble = (input) => {
  return new Promise((res, rej) => {
    commitMutation(environment, {
      mutation: setMascotTextBubbleMutation,
      variables: { input },
      onCompleted: res,
      onError: rej,
    });
  });
};

export const updateHayleySetting = (input) => {
  return new Promise((res, rej) => {
    commitMutation(environment, {
      mutation: updateHayleySettingMutation,
      variables: { input },
      onCompleted: res,
      onError: rej,
    });
  });
};

export const toggleTourSchedular = (input) => {
  return new Promise((res, rej) => {
    commitMutation(environment, {
      mutation: toggleTourSchedularMutation,
      variables: { input },
      onCompleted: res,
      onError: rej,
    });
  });
};

export const uploadHayleyImageMutation = (input) => {
  return new Promise((res, rej) => {
    commitMutation(environment, {
      mutation: uploadHayleyAssetMutation,
      variables: { input },
      onCompleted: res,
      onError: rej,
    });
  });
};

export const toggleHayleyFeedback = (input) => {
  return new Promise((res, rej) => {
    commitMutation(environment, {
      mutation: toggleFeedbackMutation,
      variables: { input },
      onCompleted: res,
      onError: rej,
    });
  });
};

export const hayleyCustomFormQuestion = (input) => {
  return new Promise((res, rej) => {
    commitMutation(environment, {
      mutation: hayleyCustomFormQuestionMutation,
      variables: { input },
      onCompleted: res,
      onError: rej,
    });
  });
};

export const hayleyQualificationButton = (input) => {
  return new Promise((res, rej) => {
    commitMutation(environment, {
      mutation: updateHayleyQualifierButton,
      variables: { input },
      onCompleted: res,
      onError: rej,
    });
  });
};
