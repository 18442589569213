import React from "react";

import { SvgIcon } from "@mui/material";

export default function ArrowIcon(props) {
  return (
    <svg
      className={props.className}
      width={props.width}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.2511 10.4167L10.0845 3.75V7.91667H2.58447V12.9167H10.0845V17.0833L19.2511 10.4167Z"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
