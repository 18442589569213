import graphql from "babel-plugin-relay/macro";

export default graphql`
  mutation updateHayleyMutation($input: updateHayleyMascotInput!) {
    updateHayleyMascot(input: $input) {
      response {
        iconThumbnailUrl
        iconUrl
        id
        imageData
        position
        widgetTitle
        chatbotTitle
        supportMultiLanguage
        hidePriceRangeFilter
        iconFileType
        disclaimer
        firstMessage
      }
    }
  }
`;
