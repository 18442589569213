/**
 * @flow
 * @relayHash d7ed72fd92177c5ff55090fa99a56321
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getPropertiesEditRafQueryVariables = {|
  orgId: string,
  first?: ?number,
|};
export type getPropertiesEditRafQueryResponse = {|
  +authorizedSuborgs: $ReadOnlyArray<{|
    +id: string,
    +name: string,
    +propertyLogoUrl: ?string,
    +propertyManagerLogoUrl: ?string,
    +contactUsLink: ?string,
    +tourCtaUrl: ?string,
  |}>
|};
export type getPropertiesEditRafQuery = {|
  variables: getPropertiesEditRafQueryVariables,
  response: getPropertiesEditRafQueryResponse,
|};
*/


/*
query getPropertiesEditRafQuery(
  $orgId: ID!
  $first: Int
) {
  authorizedSuborgs(orgId: $orgId, first: $first) {
    id
    name
    propertyLogoUrl
    propertyManagerLogoUrl
    contactUsLink
    tourCtaUrl
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "orgId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "authorizedSuborgs",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
      },
      {
        "kind": "Variable",
        "name": "orgId",
        "variableName": "orgId"
      }
    ],
    "concreteType": "Suborg",
    "plural": true,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "name",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "propertyLogoUrl",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "propertyManagerLogoUrl",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "contactUsLink",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "tourCtaUrl",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getPropertiesEditRafQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "getPropertiesEditRafQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "getPropertiesEditRafQuery",
    "id": null,
    "text": "query getPropertiesEditRafQuery(\n  $orgId: ID!\n  $first: Int\n) {\n  authorizedSuborgs(orgId: $orgId, first: $first) {\n    id\n    name\n    propertyLogoUrl\n    propertyManagerLogoUrl\n    contactUsLink\n    tourCtaUrl\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'fbe2070c7c7db434053ea8dbfc1cd87a';
module.exports = node;
