/**
 * @flow
 * @relayHash 79e058ea5b853ae823e92f62e10a7540
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getPropertyDataQueryVariables = {|
  id?: ?string,
  offerId?: ?string,
  propertyIds?: ?$ReadOnlyArray<string>,
  campaignIds?: ?$ReadOnlyArray<string>,
  startAt?: ?string,
  endAt?: ?string,
  concession?: ?$ReadOnlyArray<string>,
  after?: ?string,
  before?: ?string,
  first?: ?number,
  last?: ?number,
|};
export type getPropertyDataQueryResponse = {|
  +propertyData: {|
    +pageInfo: {|
      +endCursor: ?string,
      +startCursor: ?string,
      +hasPreviousPage: boolean,
      +hasNextPage: boolean,
    |},
    +edges: ?$ReadOnlyArray<?{|
      +cursor: string,
      +node: ?{|
        +concession: ?string,
        +emailScheduledAt: ?any,
        +endDate: ?any,
        +facebookSharedAt: ?any,
        +id: string,
        +includeOrExclude: ?string,
        +offerId: ?string,
        +offerTitle: ?string,
        +orgId: ?string,
        +orgName: ?string,
        +propertyId: ?string,
        +propertyName: ?string,
        +startDate: ?any,
        +value: ?string,
        +facebookShareUrl: ?string,
        +campaignTitle: ?string,
        +clicked: ?string,
        +endingIn: ?string,
        +leased: ?string,
        +propertyDataId: ?string,
        +toured: ?string,
        +macroDict: ?any,
      |},
    |}>,
  |}
|};
export type getPropertyDataQuery = {|
  variables: getPropertyDataQueryVariables,
  response: getPropertyDataQueryResponse,
|};
*/


/*
query getPropertyDataQuery(
  $id: ID
  $offerId: ID
  $propertyIds: [String!]
  $campaignIds: [String!]
  $startAt: String
  $endAt: String
  $concession: [String!]
  $after: String
  $before: String
  $first: Int
  $last: Int
) {
  propertyData(orgId: $id, offerId: $offerId, propertyIds: $propertyIds, campaignIds: $campaignIds, startAt: $startAt, endAt: $endAt, concession: $concession, after: $after, before: $before, first: $first, last: $last) {
    pageInfo {
      endCursor
      startCursor
      hasPreviousPage
      hasNextPage
    }
    edges {
      cursor
      node {
        concession
        emailScheduledAt
        endDate
        facebookSharedAt
        id
        includeOrExclude
        offerId
        offerTitle
        orgId
        orgName
        propertyId
        propertyName
        startDate
        value
        facebookShareUrl
        campaignTitle
        clicked
        endingIn
        leased
        propertyDataId
        toured
        macroDict
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "offerId",
    "type": "ID",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "propertyIds",
    "type": "[String!]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "campaignIds",
    "type": "[String!]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "startAt",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "endAt",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "concession",
    "type": "[String!]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "after",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "before",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "last",
    "type": "Int",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "propertyData",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "after",
        "variableName": "after"
      },
      {
        "kind": "Variable",
        "name": "before",
        "variableName": "before"
      },
      {
        "kind": "Variable",
        "name": "campaignIds",
        "variableName": "campaignIds"
      },
      {
        "kind": "Variable",
        "name": "concession",
        "variableName": "concession"
      },
      {
        "kind": "Variable",
        "name": "endAt",
        "variableName": "endAt"
      },
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
      },
      {
        "kind": "Variable",
        "name": "last",
        "variableName": "last"
      },
      {
        "kind": "Variable",
        "name": "offerId",
        "variableName": "offerId"
      },
      {
        "kind": "Variable",
        "name": "orgId",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "propertyIds",
        "variableName": "propertyIds"
      },
      {
        "kind": "Variable",
        "name": "startAt",
        "variableName": "startAt"
      }
    ],
    "concreteType": "OfferSettingConnection",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "pageInfo",
        "storageKey": null,
        "args": null,
        "concreteType": "PageInfo",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "endCursor",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "startCursor",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "hasPreviousPage",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "hasNextPage",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "edges",
        "storageKey": null,
        "args": null,
        "concreteType": "OfferSettingEdge",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "cursor",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "OfferSetting",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "concession",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "emailScheduledAt",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "endDate",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "facebookSharedAt",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "includeOrExclude",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "offerId",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "offerTitle",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "orgId",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "orgName",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "propertyId",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "propertyName",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "startDate",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "value",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "facebookShareUrl",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "campaignTitle",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "clicked",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "endingIn",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "leased",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "propertyDataId",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "toured",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "macroDict",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getPropertyDataQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "getPropertyDataQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "getPropertyDataQuery",
    "id": null,
    "text": "query getPropertyDataQuery(\n  $id: ID\n  $offerId: ID\n  $propertyIds: [String!]\n  $campaignIds: [String!]\n  $startAt: String\n  $endAt: String\n  $concession: [String!]\n  $after: String\n  $before: String\n  $first: Int\n  $last: Int\n) {\n  propertyData(orgId: $id, offerId: $offerId, propertyIds: $propertyIds, campaignIds: $campaignIds, startAt: $startAt, endAt: $endAt, concession: $concession, after: $after, before: $before, first: $first, last: $last) {\n    pageInfo {\n      endCursor\n      startCursor\n      hasPreviousPage\n      hasNextPage\n    }\n    edges {\n      cursor\n      node {\n        concession\n        emailScheduledAt\n        endDate\n        facebookSharedAt\n        id\n        includeOrExclude\n        offerId\n        offerTitle\n        orgId\n        orgName\n        propertyId\n        propertyName\n        startDate\n        value\n        facebookShareUrl\n        campaignTitle\n        clicked\n        endingIn\n        leased\n        propertyDataId\n        toured\n        macroDict\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3dcbafa001627e19567b102e0ca52437';
module.exports = node;
