import React from "react";

export default function EnableLabelIcon({ size = 24, className = "text-[#26BBED]" }) {
  return (
    <svg
      width={size}
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.4 4H5.6C4.71634 4 4 4.71634 4 5.6V18.4C4 19.2837 4.71634 20 5.6 20H18.4C19.2837 20 20 19.2837 20 18.4V5.6C20 4.71634 19.2837 4 18.4 4Z"
        fill="white"
      />
      <path
        d="M18.4 4H5.6C4.71634 4 4 4.71634 4 5.6V18.4C4 19.2837 4.71634 20 5.6 20H18.4C19.2837 20 20 19.2837 20 18.4V5.6C20 4.71634 19.2837 4 18.4 4Z"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.4287 16.8L12.1999 7.19995H11.7999L8.57109 16.8"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.64766 13.6H14.3517"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
