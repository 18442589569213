import graphql from "babel-plugin-relay/macro";

export default graphql`
  query orgGptBlastAnalysisQuery(
    $endAt: String
    $startAt: String
    $orgId: ID!
    $propertyIds: [String!]
  ) {
    orgGptBlastAnalysis(endAt: $endAt, startAt: $startAt, orgId: $orgId, propertyIds: $propertyIds)
  }
`;
