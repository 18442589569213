import graphql from "babel-plugin-relay/macro";

export default graphql`
  query getGptHistoryByJobIdQuery($jobId: String!) {
    retrieveGptHistoryByJobId(jobId: $jobId) {
      id
      title {
        feedback {
          feedbackableType
          id
          liked
        }
        fieldType
        value
        id
      }
      subTitle {
        feedback {
          feedbackableType
          id
          liked
        }
        fieldType
        value
        id
      }
      preheader {
        feedback {
          feedbackableType
          id
          liked
        }
        fieldType
        value
        id
      }
      subject {
        feedback {
          feedbackableType
          id
          liked
        }
        fieldType
        value
        id
      }
      body {
        feedback {
          feedbackableType
          id
          liked
        }
        fieldType
        value
        id
      }
      ctaText {
        feedback {
          feedbackableType
          id
          liked
        }
        fieldType
        value
        id
      }
      disclaimer {
        feedback {
          feedbackableType
          id
          liked
        }
        fieldType
        value
        id
      }
    }
  }
`;
