import React from "react";
import embedCode from "content/hyRaf/Campaign/OmnichannelPreview/components/residentialPortal/embeded-code";
import {
  loadEmailPreviews,
  tabs,
  getTabarButtons,
  viewIds,
} from "content/hyRaf/Campaign/OmnichannelPreview/utils";
import Loader from "storybookComponents/Loader";
import { addUTMScript } from "../utils";
export default (props) => {
  const [code, setCode] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const init = async () => {
    let id = props.match.params.campaignId;
    let enrollId = props.match.params.enrollId;
    if (!id) return;
    setLoading(true);
    let campaignData = await loadEmailPreviews(id);
    if (campaignData) {
      addUTMScript(campaignData.noscript, campaignData.script);
      let orgColors = {
        linkColor: campaignData.linkColor,
        fontFamily: campaignData.fontFamily,
        textColor: campaignData.textColor,
        buttonBorderColor: campaignData.buttonBorderColor,
        buttonFontColor: campaignData.buttonFontColor,
        buttonBackgroundColor: campaignData.buttonBackgroundColor,
      };
      setCode(
        embedCode({
          ...campaignData,
          endpoint: process.env.REACT_APP_MyhylyEndpoint,
          id,
          enrollId,
          orgColors,
          property: {
            logo: campaignData.propertyLogoUrl,
          },
        })
      );
    } else {
      alert("Something went wrong");
    }
    setLoading(false);
  };

  React.useEffect(() => {
    init();
  }, []);

  return (
    <>
      {loading && <Loader />}
      {code ? <iframe srcDoc={code} style={{ width: "100%", height: "100vh" }} /> : null}
    </>
  );
};
