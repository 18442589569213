/**
 * @flow
 * @relayHash 75ee01f3f41cbf0d864364d38bf6780d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type gethyblastsQueryVariables = {|
  propertyId: string,
  startDate?: ?string,
  endDate?: ?string,
  status?: ?string,
  name?: ?string,
  first?: ?number,
  after?: ?string,
  approvalStatus?: ?string,
  mailGpt?: ?boolean,
|};
export type gethyblastsQueryResponse = {|
  +hyblasts: {|
    +pageInfo: {|
      +endCursor: ?string,
      +hasNextPage: boolean,
    |},
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: string,
        +subject: string,
        +status: string,
        +smallThumbnailUrl: ?string,
        +largeThumbnailUrl: ?string,
        +originalThumbnailUrl: ?string,
        +approvalStatus: ?string,
        +sendsCount: number,
        +sentGroupCount: ?number,
        +sentGroup: ?string,
        +scheduledTime: ?any,
        +sentTime: ?any,
        +uniqueOpensCount: number,
        +createdAt: any,
        +updatedAt: any,
        +purpose: ?string,
        +mailGpt: ?boolean,
      |}
    |}>,
  |}
|};
export type gethyblastsQuery = {|
  variables: gethyblastsQueryVariables,
  response: gethyblastsQueryResponse,
|};
*/


/*
query gethyblastsQuery(
  $propertyId: ID!
  $startDate: String
  $endDate: String
  $status: String
  $name: String
  $first: Int
  $after: String
  $approvalStatus: String
  $mailGpt: Boolean
) {
  hyblasts(propertyId: $propertyId, startDate: $startDate, endDate: $endDate, status: $status, name: $name, after: $after, first: $first, approvalStatus: $approvalStatus, mailGpt: $mailGpt) {
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      node {
        id
        name
        subject
        status
        smallThumbnailUrl
        largeThumbnailUrl
        originalThumbnailUrl
        approvalStatus
        sendsCount
        sentGroupCount
        sentGroup
        scheduledTime
        sentTime
        uniqueOpensCount
        createdAt
        updatedAt
        purpose
        mailGpt
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "propertyId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "startDate",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "endDate",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "status",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "name",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "after",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "approvalStatus",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "mailGpt",
    "type": "Boolean",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "hyblasts",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "after",
        "variableName": "after"
      },
      {
        "kind": "Variable",
        "name": "approvalStatus",
        "variableName": "approvalStatus"
      },
      {
        "kind": "Variable",
        "name": "endDate",
        "variableName": "endDate"
      },
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
      },
      {
        "kind": "Variable",
        "name": "mailGpt",
        "variableName": "mailGpt"
      },
      {
        "kind": "Variable",
        "name": "name",
        "variableName": "name"
      },
      {
        "kind": "Variable",
        "name": "propertyId",
        "variableName": "propertyId"
      },
      {
        "kind": "Variable",
        "name": "startDate",
        "variableName": "startDate"
      },
      {
        "kind": "Variable",
        "name": "status",
        "variableName": "status"
      }
    ],
    "concreteType": "HyblastConnection",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "pageInfo",
        "storageKey": null,
        "args": null,
        "concreteType": "PageInfo",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "endCursor",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "hasNextPage",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "edges",
        "storageKey": null,
        "args": null,
        "concreteType": "HyblastEdge",
        "plural": true,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "Hyblast",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "name",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "subject",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "status",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "smallThumbnailUrl",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "largeThumbnailUrl",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "originalThumbnailUrl",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "approvalStatus",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "sendsCount",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "sentGroupCount",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "sentGroup",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "scheduledTime",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "sentTime",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "uniqueOpensCount",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "createdAt",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "updatedAt",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "purpose",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "mailGpt",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "gethyblastsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "gethyblastsQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "gethyblastsQuery",
    "id": null,
    "text": "query gethyblastsQuery(\n  $propertyId: ID!\n  $startDate: String\n  $endDate: String\n  $status: String\n  $name: String\n  $first: Int\n  $after: String\n  $approvalStatus: String\n  $mailGpt: Boolean\n) {\n  hyblasts(propertyId: $propertyId, startDate: $startDate, endDate: $endDate, status: $status, name: $name, after: $after, first: $first, approvalStatus: $approvalStatus, mailGpt: $mailGpt) {\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n    edges {\n      node {\n        id\n        name\n        subject\n        status\n        smallThumbnailUrl\n        largeThumbnailUrl\n        originalThumbnailUrl\n        approvalStatus\n        sendsCount\n        sentGroupCount\n        sentGroup\n        scheduledTime\n        sentTime\n        uniqueOpensCount\n        createdAt\n        updatedAt\n        purpose\n        mailGpt\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '583f36417b0598abdda3d99bdb9285f2';
module.exports = node;
