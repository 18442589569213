import React from "react";

export default function CandleIcon({ className = "text-[#999999]", width = 18, height = 18 }) {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.25 7.25C9.802 7.25 10.25 6.802 10.25 6.25C10.25 5.698 9.25 4.25 9.25 4.25C9.25 4.25 8.25 5.698 8.25 6.25C8.25 6.802 8.698 7.25 9.25 7.25Z"
        stroke="currentColor"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
      <path
        d="M14.75 10.75H3.75V14.75H14.75V10.75Z"
        stroke="currentColor"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
      <path
        d="M13.25 10.75V8.75H5.25V10.75"
        stroke="currentColor"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
    </svg>
  );
}
