import graphql from "babel-plugin-relay/macro";

export default graphql`
  query getApproverSettingsQuery($orgId: ID!, $propertyId: String) {
    authorizedSuborgs(orgId: $orgId, propertyId: $propertyId) {
      id
      name
      hyblastSetting {
        approvalRequired
        allowUploadImages
        approverEmails
        agentApprovalEmails
        id
        bccEmails
        emailLimits {
          groupType
          weeklyMin
          weeklyMax
          monthlyMin
          monthlyMax
        }
      }
      hyeventsSetting {
        approvalRequired
        approverEmails
        agentApprovalEmails
        hyeventsBccEmails
        id
      }
    }
  }
`;
