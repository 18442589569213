import graphql from "babel-plugin-relay/macro";

export default graphql`
  query getPropertyOfferListQuery($id: ID!) {
    propertyOfferList(orgId: $id) {
      id
      title
      campaignTitle
    }
  }
`;
