import { createSlice, current } from "@reduxjs/toolkit";
import { getNAMEForNode } from "content/JourneyFreehand/common/functions";
import _, { first } from "lodash";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

const stepForGoThrough = localStorage.getItem("timesSteps")
  ? parseInt(localStorage.getItem("timesSteps")) < 3
    ? 0
    : null
  : 0;

const initialState = {
  past: [],
  present: {
    nodes: [],
    edges: [],
  },
  future: [],
  stepForGoThrough: stepForGoThrough,
  showHistoryPanel: false,
  allWhensWithCategories: null,
  allThensWithCategories: null,
  hoverHighlightingStepOrEdgeID: null, // State or Edge to highlight happen when user hover the mouse on the update pannel.
  connectionMode: null,
  selectedNodes: [],
  journeyInfo: null,
  enableLabel: false,
};

const journeyFreehandSlice = createSlice({
  name: "journeyFreehand",
  initialState,
  reducers: {
    addNode: (state, action) => {
      if (action.payload.type === "keep_history") {
        let data = action.payload.data;
        console.log("debug: data", data);
        let messageObj = {
          messages: [
            {
              type: "text",
              content: "Added",
            },
            {
              type: "DOM",
              content: data?.data?.label,
              data: {
                id: data.id,
                type: "node",
              },
            },
            {
              type: "text",
              content: getNAMEForNode(data?.type),
            },
          ],
          time: moment().format(),
          user: action.payload.user,
        };
        let present = Object.assign({}, { ...state.present, messageObj });
        state.past = [...state.past, present];
        state.future = [];
      }
      state.present.nodes = [...state.present.nodes, action.payload.data];
    },

    replaceAllNodes: (state, action) => {
      state.present.nodes = action.payload;
    },
    replaceAllEdges: (state, action) => {
      state.present.edges = action.payload;
    },

    initializeStore: (state, action) => {
      state.present.edges = [];
      state.present.nodes = [];
      state.past = [];
      state.future = [];
    },

    updateNodePosition: (state, action) => {
      state.present.nodes = state.present.nodes.map((node) => {
        if (node.id === action.payload.id) {
          return {
            ...node,
            position: action.payload.data.position,
          };
        } else return node;
      });
    },
    updateNode: (state, action) => {
      if (action.payload.type === "keep_history") {
        let present = Object.assign(
          {},
          action.payload.messageObj
            ? { ...state.present, messageObj: action.payload.messageObj }
            : state.present
        );
        state.past = [...state.past, present];
        state.future = [];
      }
      state.present.nodes = state.present.nodes.map((node) => {
        if (node.id === action.payload.id) {
          const updatedNode = {
            ...node,
            ...action.payload.data,
          };
          return updatedNode;
        } else return node;
      });
    },

    updateNodeGroup: (state, action) => {
      if (action.payload.type === "keep_history") {
        let present = Object.assign({}, state.present);
        state.past = [...state.past, present];
        state.future = [];
      }
      state.present.nodes = state.present.nodes.map((node) => {
        if (node.id === action.payload.id) {
          const updatedNode = {
            ...node,
            ...action.payload.data,
          };
          return updatedNode;
        } else return node;
      });
    },

    updateNodes: (state, action) => {
      if (action.payload.type === "keep_history") {
        let present = Object.assign({}, state.present);
        state.past = [...state.past, present];
        state.future = [];
      }
      state.present.nodes = state.present.nodes.map((node) => {
        if (action.payload.nodes.map((item) => item.id).indexOf(node.id) !== -1) {
          const index = action.payload.nodes.map((item) => item.id).indexOf(node.id);
          return action.payload.nodes[index];
        } else return node;
      });
    },

    updateNodeLabel: (state, action) => {
      if (action.payload.type === "keep_history") {
        const data = action.payload.data;
        let currentOne = _.find(state.present.nodes, (n) => n.id === data.id);
        let messageObj = {
          messages: [
            {
              type: "text",
              content: "Renamed",
            },
            {
              type: "DOM",
              content: currentOne.data.label,
              data: {
                id: data.id,
                type: "node",
              },
            },
            {
              type: "text",
              content: getNAMEForNode(currentOne?.type) + " as",
            },
            {
              type: "DOM",
              content: data.label,
              data: {
                id: data.id,
                type: "node",
              },
            },
          ],
          time: moment().format(),
          user: action.payload.user,
        };
        let present = Object.assign({}, { ...state.present, messageObj });
        state.past = [...state.past, present];
        state.future = [];
      }
      const data = action.payload.data;
      state.present.nodes = state.present.nodes.map((node) => {
        if (node.id === data.id) {
          return {
            ...node,
            data: {
              ...node.data,
              label: data.label,
            },
          };
        } else return node;
      });
    },

    updateGroupNodeLabel: (state, action) => {
      let currentOne = _.find(state.present.nodes, (n) => n.id === action.payload.data.id);
      if (currentOne) {
        const { firstChild, secondChild } = currentOne.data;
        if (action.payload.type === "keep_history") {
          const data = action.payload.data;
          let messageObj = {
            messages: [
              {
                type: "text",
                content: "Renamed Group Node state as",
              },
              {
                type: "DOM",
                content: data.label,
                data: {
                  id: data.id,
                  type: "node",
                },
              },
            ],
            time: moment().format(),
            user: action.payload.user,
          };
          let present = Object.assign({}, { ...state.present, messageObj });
          state.past = [...state.past, present];
          state.future = [];
        }

        if (action.payload.target === "first") {
          firstChild.data.label = action.payload.data.label;
          currentOne.firstChild = firstChild;
        } else if (action.payload.target === "second") {
          secondChild.data.label = action.payload.data.label;
          currentOne.secondChild = secondChild;
        }
        const data = action.payload.data;
        state.present.nodes = state.present.nodes.map((node) => {
          if (node.id === data.id) {
            return currentOne;
          } else return node;
        });
      }
    },

    setAllNodes: (state, action) => {
      state.present.nodes = action.payload;
    },

    setAllEdges: (state, action) => {
      state.present.edges = action.payload;
    },

    addEdge: (state, action) => {
      if (action.payload.type === "keep_history") {
        const data = action.payload.data;
        const source = _.find(state.present.nodes, (node) => node.id === data.source);
        const target = _.find(state.present.nodes, (node) => node.id === data.target);

        let messages = [
          {
            type: "text",
            content: "Added connection between",
          },
          {
            type: "DOM",
            content: source.data.label,
            data: {
              id: source.id,
              type: "node",
            },
          },
          {
            type: "text",
            content: "and",
          },
          {
            type: "DOM",
            content: target.data.label,
            data: {
              id: target.id,
              type: "node",
            },
          },
        ];
        let messageObj = {
          messages: action.payload.data.label
            ? [
                ...messages.concat([
                  {
                    type: "text",
                    content: "with label",
                  },
                  {
                    type: "DOM",
                    content: action.payload.data.label,
                    data: {
                      id: action.payload.data.id,
                      type: "edge",
                    },
                  },
                ]),
              ]
            : messages,
          time: moment().format(),
          user: action.payload.user,
        };
        let present = Object.assign({}, { ...state.present, messageObj });
        state.past = [...state.past, present];
        state.future = [];
      }

      state.present.edges = [...state.present.edges, action.payload.data];
    },

    deleteEdge: (state, action) => {
      if (action.payload.type === "keep_history") {
        let edge = _.find(state.present.edges, (v) => v.id === action.payload.data.id);
        const source = _.find(state.present.nodes, (node) => node.id === edge.source);
        const target = _.find(state.present.nodes, (node) => node.id === edge.target);
        let messageObj = {
          messages: [
            {
              type: "text",
              content: "Deleted connection between",
            },
            {
              type: "DOM",
              content: source.data.label,
              data: {
                id: source.id,
                type: "node",
              },
            },
            {
              type: "text",
              content: "and",
            },
            {
              type: "DOM",
              content: target.data.label,
              data: {
                id: target.id,
                type: "node",
              },
            },
          ],
          time: moment().format(),
          user: action.payload.user,
        };
        let present = Object.assign({}, { ...state.present, messageObj });
        state.past = [...state.past, present];
        state.future = [];
      }
      state.present.edges = state.present.edges.filter(
        (edge) => edge.id !== action.payload.data.id
      );
    },

    updateEdge: (state, action) => {
      if (action.payload.type === "keep_history") {
        const edge = _.find(state.present.edges, (v) => v.id === action.payload.data.id);
        const source = _.find(state.present.nodes, (v) => v.id === edge.source);
        const target = _.find(state.present.nodes, (v) => v.id === edge.target);

        let messages = [];
        if (edge.label) {
          if (!action.payload.data.label) {
            messages = [
              {
                type: "text",
                content: "Removed label",
              },
              {
                type: "DOM",
                content: edge.label,
                data: {
                  id: edge.id,
                  type: "edge",
                },
              },
              {
                type: "text",
                content: "on the connection between",
              },
              {
                type: "DOM",
                content: source.data.label,
                data: {
                  id: source.id,
                  type: "node",
                },
              },
              {
                type: "text",
                content: "and",
              },
              {
                type: "DOM",
                content: target.data.label,
                data: {
                  id: target.id,
                  type: "node",
                },
              },
            ];
          } else {
            messages = [
              {
                type: "text",
                content: "Updated label",
              },
              {
                type: "DOM",
                content: edge.label,
                data: {
                  id: edge.id,
                  type: "edge",
                },
              },
              {
                type: "text",
                content: "to",
              },
              {
                type: "DOM",
                content: action.payload.data.label,
                data: {
                  id: edge.id,
                  type: "edge",
                },
              },
              {
                type: "text",
                content: "on the connection between",
              },
              {
                type: "DOM",
                content: source.data.label,
                data: {
                  id: source.id,
                  type: "node",
                },
              },
              {
                type: "text",
                content: "and",
              },
              {
                type: "DOM",
                content: target.data.label,
                data: {
                  id: target.id,
                  type: "node",
                },
              },
            ];
          }
        } else {
          messages = [
            {
              type: "text",
              content: "Added label",
            },
            {
              type: "DOM",
              content: action.payload.data.label,
              data: {
                id: edge.id,
                type: "edge",
              },
            },
            {
              type: "text",
              content: "on the connection between",
            },
            {
              type: "DOM",
              content: source.data.label,
              data: {
                id: source.id,
                type: "node",
              },
            },
            {
              type: "text",
              content: "and",
            },
            {
              type: "DOM",
              content: target.data.label,
              data: {
                id: target.id,
                type: "node",
              },
            },
          ];
        }

        let messageObj = {
          messages,
          time: moment().format(),
          user: action.payload.user,
        };
        let present = Object.assign({}, { ...state.present, messageObj });
        state.past = [...state.past, present];
        state.future = [];
      }
      if (action.payload.action === "add_new_edge") {
        const edge = _.find(state.present.edges, (v) => v.id === action.payload.data.id);
        if (edge) {
          const source = _.find(state.present.nodes, (node) => node.id === edge.source);
          const target = _.find(state.present.nodes, (node) => node.id === edge.target);
          let messageObj = {
            messages: [
              {
                type: "text",
                content: "Added connection between",
              },
              {
                type: "DOM",
                content: source.data.label,
                data: {
                  id: source.id,
                  type: "node",
                },
              },
              {
                type: "text",
                content: "and",
              },
              {
                type: "DOM",
                content: target.data.label,
                data: {
                  id: target.id,
                  content: "node",
                },
              },
              {
                type: "text",
                content: "with label",
              },
              {
                type: "DOM",
                content: edge.label,
                data: {
                  id: edge.id,
                  type: "edge",
                },
              },
            ],
            time: moment().format(),
            user: action.payload.user,
          };
          let present = Object.assign({}, { ...state.present, messageObj });
          state.past = [...state.past, present];
          state.future = [];
        }
      }
      state.present.edges = state.present.edges.map((edge) => {
        if (edge.id === action.payload.data.id) {
          const updatedEdge = {
            ...edge,
            ...action.payload.data,
          };
          return updatedEdge;
        } else return edge;
      });
    },

    updateEntireEdge: (state, action) => {
      if (action.payload.type === "keep_history") {
        let present = Object.assign({}, state.present);
        state.past = [...state.past, present];
        state.future = [];
      }
      state.present.edges = state.present.edges.map((edge) => {
        if (edge.id === action.payload.id) {
          return action.payload;
        } else return edge;
      });
    },
    setSelectNodeId: (state, action) => {
      if (state.selectedNodes.indexOf(action.payload) !== -1) {
        let nodes = state.selectedNodes;
        const index = nodes.indexOf(action.payload);
        nodes.splice(index, 1);
        state.selectedNodes = nodes;
      } else {
        state.selectedNodes = [...state.selectedNodes, action.payload];
      }
    },
    clearSelectedNodesArray: (state, action) => {
      state.selectedNodes = [];
    },
    updatePositionOfNode: (state, action) => {
      state.present.nodes = state.present.nodes.map((node) => {
        if (node.id === action.payload.id) {
          return {
            ...node,
            position: action.payload.position,
          };
        } else return node;
      });
    },
    updateTargetOfEdge: (state, action) => {
      if (action.payload.type === "keep_history") {
        let present = Object.assign({}, state.present);
        state.past = [...state.past, present];
        state.future = [];
      }
      state.present.edges = state.present.edges.map((edge) => {
        if (edge.id === action.payload.id) {
          return {
            ...edge,
            source: action.payload.source,
          };
        } else return edge;
      });
    },
    deleteNode: (state, action) => {
      if (action.payload.type === "keep_history") {
        let deletedNode = _.find(state.present.nodes, (v) => v.id === action.payload.data.id);
        let messageObj = {
          messages: [
            {
              type: "text",
              content: "Deleted",
            },
            {
              type: "DOM",
              content: deletedNode.data.label,
              data: {
                id: deletedNode.id,
                type: "node",
              },
            },
            {
              type: "text",
              content: deletedNode.type,
            },
          ],
          time: moment().format(),
          user: action.payload.user,
        };
        let present = Object.assign({}, { ...state.present, messageObj });
        state.past = [...state.past, present];
        state.future = [];
      }

      //Delete specific node
      const id = action.payload.data.id;
      state.present.nodes = state.present.nodes.filter((node) => node.id !== id);
      //Delete all the edges connected to this node
      state.present.edges = state.present.edges.filter((edge) => {
        if (edge.source === id || edge.target === id) {
          return false;
        }
        return true;
      });
      //Delete the state if it has been already selected in the selected list
      const index = state.selectedNodes.indexOf(id);
      if (index !== -1) {
        let temp = state.selectedNodes;
        temp.splice(index, 1);
        state.selectedNodes = temp;
      }
    },

    changeConnectionMode: (state, action) => {
      state.connectionMode = action.payload;
    },
    duplicateOneNode: (state, action) => {
      const node = _.find(state.present.nodes, (v) => v.id === action.payload.id);
      if (action.payload.type === "keep_history") {
        let messageObj = {
          messages: [
            {
              type: "text",
              content: "Duplicated from",
            },
            {
              type: "DOM",
              content: node.data.label,
              data: {
                id: node.id,
                type: "node",
              },
            },
            {
              type: "text",
              content: getNAMEForNode(node.type),
            },
          ],
          time: moment().format(),
          user: action.payload.user,
        };
        let present = Object.assign({}, { ...state.present, messageObj });
        state.past = [...state.past, present];
        state.future = [];
      }
      state.present.nodes = [
        ...state.present.nodes,
        { ...node, id: uuidv4(), position: { ...node.position, x: node.position.x + node.width } },
      ];
    },
    addVariantWithNode: (state, action) => {
      const stepNodeFromID = _.find(state.present.nodes, (node) => node.id === action.payload.id);
      if (action.payload.type === "keep_history") {
        let messageObj = {
          messages: [
            {
              type: "text",
              content: "Added a variant for",
            },
            {
              type: "DOM",
              content: stepNodeFromID.data.label,
              data: {
                id: action.payload.id,
                type: "node",
              },
            },
          ],
          time: moment().format(),
          user: action.payload.user,
        };
        let present = Object.assign({}, { ...state.present, messageObj });
        state.past = [...state.past, present];
        state.future = [];
      }
      const groupNodeID = uuidv4();
      state.present.edges = state.present.edges.map((edge) => {
        if (edge.source === stepNodeFromID.id) {
          return {
            ...edge,
            source: groupNodeID,
          };
        } else if (edge.target === stepNodeFromID.id) {
          return {
            ...edge,
            target: groupNodeID,
          };
        } else return edge;
      });
      const groupNode = {
        id: groupNodeID,
        type: "group",
        position: {
          x: stepNodeFromID.position.x,
          y: stepNodeFromID.position.y,
        },
        data: {
          firstChild: stepNodeFromID,
          secondChild: stepNodeFromID,
        },
      };
      state.present.nodes = [...state.present.nodes, groupNode];
      state.present.nodes = state.present.nodes.filter((node) => node.id !== action.payload.id);
    },
    deleteNodeInGroup: (state, action) => {
      const groupNodeFromID = _.find(state.present.nodes, (node) => node.id === action.payload.id);
      const nodeToAdd = groupNodeFromID.data.firstChild;
      if (action.payload.actionType === "keep_history") {
        let messageObj = {
          messages: [
            {
              type: "text",
              content: "Removed variant for",
            },
            {
              type: "DOM",
              content: nodeToAdd.data.label,
              data: {
                id: groupNodeFromID,
                type: "node",
              },
            },
          ],
          time: moment().format(),
          user: action.payload.user,
        };
        let present = Object.assign({}, { ...state.present, messageObj });
        state.past = [...state.past, present];
        state.future = [];
      }
      state.present.nodes = state.present.nodes.filter((node) => node.id !== action.payload.id);
      state.present.edges = state.present.edges.map((edge) => {
        if (edge.source === action.payload.id) {
          return {
            ...edge,
            source: nodeToAdd.id,
          };
        } else if (edge.target === action.payload.id) {
          return {
            ...edge,
            target: nodeToAdd.id,
          };
        } else return edge;
      });
      state.present.nodes = [...state.present.nodes, { ...nodeToAdd }];
    },
    nextStepGoThrough: (state, action) => {
      if (state.stepForGoThrough === 8) {
        const currentAttempts = localStorage.getItem("timesSteps")
          ? parseInt(localStorage.getItem("timesSteps"))
          : 0;
        localStorage.setItem("timesSteps", currentAttempts + 1);
      }
      state.stepForGoThrough = state.stepForGoThrough + 1;
    },
    skipStepGoThrough: (state, action) => {
      state.stepForGoThrough = null;
      const currentAttempts = localStorage.getItem("timesSteps")
        ? parseInt(localStorage.getItem("timesSteps"))
        : 0;
      localStorage.setItem("timesSteps", currentAttempts + 1);
    },
    prevStep: (state, action) => {
      state.stepForGoThrough = state.stepForGoThrough - 1;
    },
    toggleHistoryPanel: (state, action) => {
      state.showHistoryPanel = action.payload;
    },
    setNodeWidthAndHeight: (state, action) => {
      if (action.payload.type === "keep_history") {
        let present = Object.assign({}, state.present);
        state.past = [...state.past, present];
        state.future = [];
      }

      let node = _.find(state.present.nodes, (v) => v.id === action.payload.id);
      if (node) {
        node.width = action.payload.width;
        node.height = action.payload.height;
      }
      state.present.nodes = state.present.nodes.map((v) => {
        if (v.id === node.id) return node;
        else return v;
      });
    },

    setHoverHighlightingStepOrEdge: (state, action) => {
      state.hoverHighlightingStepOrEdgeID = action.payload;
    },

    setAllWhensThens: (state, action) => {
      state.allThensWithCategories = action.payload.thens;
      state.allWhensWithCategories = action.payload.whens;
    },

    undo: (state, action) => {
      const previous = state.past[state.past.length - 1];
      const newPast = state.past.slice(0, state.past.length - 1);
      return {
        ...state,
        past: newPast,
        present: { ...previous },
        future: [state.present, ...state.future],
      };
    },

    redo: (state, action) => {
      const next = state.future[0];
      const newFuture = state.future.slice(1);
      return {
        ...state,
        past: [...state.past, state.present],
        present: next,
        future: newFuture,
      };
    },

    restore: (state, action) => {
      const index = state.past.findIndex((v) => v.messageObj?.time === action.payload.time);
      if (index < state.past.length - 1) {
        let past2Put = state.past.slice(0, index + 1);
        let present = state.past[index + 1];
        let future = [...state.past.slice(index + 2), state.present, ...state.future];
        state.past = past2Put;
        state.present = present;
        state.future = future;
      }
    },

    setJourneyInfo: (state, action) => {
      state.journeyInfo = action.payload;
    },

    setPast: (state, action) => {
      state.past = action.payload;
    },

    toggleEnableLabel: (state, action) => {
      state.enableLabel = action.payload;
    },

    resetAllVariables: (state, action) => {
      state.past = [];
      state.present = {
        nodes: [],
        edges: [],
      };
      state.future = [];
      state.stepForGoThrough = stepForGoThrough;
      state.showHistoryPanel = false;
      state.allWhensWithCategories = null;
      state.allThensWithCategories = null;
      state.hoverHighlightingStepOrEdgeID = null;
      state.connectionMode = null;
      state.selectedNodes = [];
      state.journeyInfo = null;
      state.enableLabel = false;
    },
  },
});

export const getAllWhensWithCategories = (state) => state.journeyFreehand.allWhensWithCategories;
export const getAllThensWithCategories = (state) => state.journeyFreehand.allThensWithCategories;
export const getFutureStates = (state) => state.journeyFreehand.future;
export const getPastStates = (state) => state.journeyFreehand.past;
export const getNodes = (state) => state.journeyFreehand.present.nodes;
export const getEdges = (state) => state.journeyFreehand.present.edges;
export const getSelectedNodes = (state) => state.journeyFreehand.selectedNodes;
export const getJourneyInfo = (state) => state.journeyFreehand.journeyInfo;
export const getConnectionMode = (state) => state.journeyFreehand.connectionMode;
export const getEnableLabel = (state) => state.journeyFreehand.enableLabel;
export const getHighlightingID = (state) => state.journeyFreehand.hoverHighlightingStepOrEdgeID;

export const {
  addNode,
  setAllNodes,
  addEdge,
  updateNodePosition,
  updateNodeLabel,
  // updateNodeDay,
  replaceAllNodes,
  replaceAllEdges,
  updateEdge,
  updateNodes,
  setSelectNodeId,
  updatePositionOfNode,
  updateTargetOfEdge,
  deleteEdge,
  deleteNode,
  updateNode,
  changeConnectionMode,
  duplicateOneNode,
  setAllEdges,
  addVariantWithNode,
  deleteNodeInGroup,
  nextStepGoThrough,
  skipStepGoThrough,
  prevStep,
  toggleHistoryPanel,
  updateEntireEdge,
  setHoverHighlightingStepOrEdge,
  setAllWhensThens,
  setNodeWidthAndHeight,
  redo,
  undo,
  setJourneyInfo,
  setPast,
  restore,
  initializeStore,
  toggleEnableLabel,
  updateNodeGroup,
  updateGroupNodeLabel,
  clearSelectedNodesArray,
  resetAllVariables,
} = journeyFreehandSlice.actions;
export default journeyFreehandSlice.reducer;
