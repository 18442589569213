/**
 * @flow
 * @relayHash 30e3005a1c9b2d9d971f0a83e8601df3
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getOfferQueryVariables = {|
  id: string
|};
export type getOfferQueryResponse = {|
  +offer: {|
    +active: ?boolean,
    +deletedAt: ?any,
    +description: ?string,
    +designOffer: ?string,
    +id: string,
    +image: ?{|
      +fileUrl: string,
      +id: string,
      +thumbnailUrl: ?string,
    |},
    +offerCtaText: ?string,
    +offerCtaUrl: ?string,
    +orgId: string,
    +orgName: ?string,
    +shortTourUrl: ?string,
    +subTitle: ?string,
    +title: ?string,
    +propertyCount: string,
    +campaignTitle: ?string,
    +prospectInviteEmailCampaignHtml: string,
    +prospectInviteEmailCampaignId: string,
    +prospectInviteEmailSubject: string,
    +abortProspectInviteEmailCampaignId: ?string,
    +facebookSharedCount: string,
    +emailScheduledCount: ?string,
    +isSent: ?boolean,
  |}
|};
export type getOfferQuery = {|
  variables: getOfferQueryVariables,
  response: getOfferQueryResponse,
|};
*/


/*
query getOfferQuery(
  $id: ID!
) {
  offer(id: $id) {
    active
    deletedAt
    description
    designOffer
    id
    image {
      fileUrl
      id
      thumbnailUrl
    }
    offerCtaText
    offerCtaUrl
    orgId
    orgName
    shortTourUrl
    subTitle
    title
    propertyCount
    campaignTitle
    prospectInviteEmailCampaignHtml
    prospectInviteEmailCampaignId
    prospectInviteEmailSubject
    abortProspectInviteEmailCampaignId
    facebookSharedCount
    emailScheduledCount
    isSent
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "offer",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "Offer",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "active",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "deletedAt",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "description",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "designOffer",
        "args": null,
        "storageKey": null
      },
      (v1/*: any*/),
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "image",
        "storageKey": null,
        "args": null,
        "concreteType": "Asset",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "fileUrl",
            "args": null,
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "thumbnailUrl",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "offerCtaText",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "offerCtaUrl",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "orgId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "orgName",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "shortTourUrl",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "subTitle",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "title",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "propertyCount",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "campaignTitle",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "prospectInviteEmailCampaignHtml",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "prospectInviteEmailCampaignId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "prospectInviteEmailSubject",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "abortProspectInviteEmailCampaignId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "facebookSharedCount",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "emailScheduledCount",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "isSent",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getOfferQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "getOfferQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "getOfferQuery",
    "id": null,
    "text": "query getOfferQuery(\n  $id: ID!\n) {\n  offer(id: $id) {\n    active\n    deletedAt\n    description\n    designOffer\n    id\n    image {\n      fileUrl\n      id\n      thumbnailUrl\n    }\n    offerCtaText\n    offerCtaUrl\n    orgId\n    orgName\n    shortTourUrl\n    subTitle\n    title\n    propertyCount\n    campaignTitle\n    prospectInviteEmailCampaignHtml\n    prospectInviteEmailCampaignId\n    prospectInviteEmailSubject\n    abortProspectInviteEmailCampaignId\n    facebookSharedCount\n    emailScheduledCount\n    isSent\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2dd0e081925925f70fafc1fa92a08291';
module.exports = node;
