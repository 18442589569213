export const tableCardsStyles = {
  root: {
    height: "100%",
    minHeight: 325,
    // paddingTop: 20,
    // padding: 16,
    "&:hover": {
      // cursor: "pointer",
      // boxShadow: "0px 6px 30px rgba(51, 51, 51, 0.08)",
      // "& $details": {
      //   "& $nexticon": {
      //     color: "#26BBED",
      //   },
      // },
    },
    border: "1px solid #DADADA",
    backgroundColor: "white",
  },
  noDataRoot: {
    height: "100%",
    minHeight: 325,
    // padding: 16,
    paddingBottom: 0,
    border: "1px solid #DADADA",
    display: "flex",
    flexFlow: "column",
    backgroundColor: "white",
  },
  noData: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    marginBottom: 44,
    "&>img": {
      width: 26,
      height: 26,
      marginBottom: 20,
    },
    "&>div": {
      fontSize: 14,
      lineHeight: "20px",
    },
  },
  details: {
    display: "flex",
    justifyContent: "space-between",
    padding: 16,
  },
  title: {
    color: "#333333",
    fontWeight: "400",
    fontFamily: "Open Sans",
    fontSize: 16,
  },
  total: {
    fontWeight: "600",
    marginLeft: 8,
  },
  toolTipContainer: {
    display: "flex",
    alignItems: "center",
  },
  duration: {
    color: "#333333",
    opacity: 0.5,
    fontSize: 12,
    lineHeight: "20px",
  },
  durationContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  noDurationContainer: {
    display: "none",
  },
  fullscreenicon: {
    display: "inline",
    "&:hover": {
      cursor: "pointer",
    },
  },
  nexticon: {
    // transform: "rotate(-90deg)",
    fontSize: 26,
    color: "#999",
  },
  tooltip: {},
  tableCell: {
    border: "1px solid #eee",
  },
  headRow: {
    backgroundColor: "#F8F8F8",
  },
};

export const tableHeatmapStyles = {
  clickable: {
    textDecoration: "none",
    color: "#4F6FAE",
    "&:hover": {
      color: "#26BBED",
    },
  },
  tableCell: {
    borderBottom: "1px solid #DADADA",
    wordBreak: "break-all",
  },
  tableCelltd: {
    border: "1px solid #eee",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  tabelHeadCell: {
    display: "flex",
    fontSize: 14,
    fontWeight: 600,
    borderBottom: "0px !important",
  },
  headRow: {
    backgroundColor: "#F8F8F8",
  },
  showAllPropertiesButton: {
    display: "flex",
    boxSizing: "border-box",
    justifyContent: "center",
    alignItems: "center",
    height: "48px",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "32px",
    color: "#26BBED",
    fontWeight: 400,
    fontSize: 16,
    lineHeight: "21.79px",
    border: "1px solid #DADADA",
    borderRadius: 2,
    "&:hover": {
      background: "rgba(38, 187, 237, 0.1)",
      border: "1px solid #26BBED",
    },
  },
  showAllPropertiesIcon: {
    marginLeft: "0.5rem",
  },
  showAll: {
    marginRight: "0.5rem",
  },
  others: {
    marginLeft: "0.5rem",
  },
  othersContent: {},
};
