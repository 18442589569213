import graphql from "babel-plugin-relay/macro";

export default graphql`
  query gethyblastSettingQuery($orgId: ID!, $propertyId: ID) {
    hyblastSetting(orgId: $orgId, propertyId: $propertyId) {
      hyblastTemplatesUrl
      mediaLibraryUrl
      contactGroupsUrl
      hyblastAudienceUrl
    }
  }
`;
