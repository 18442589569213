import React from "react";

function StarIcon({ className = "text-[#26BBED] w-5 h-5", highlight = true }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" className={className}>
      {highlight ? (
        <rect width="20" height="20" fill="currentColor" fillOpacity="0.06" rx="10"></rect>
      ) : null}

      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M5 10a5 5 0 005-5 5 5 0 005 5 5 5 0 00-5 5 5 5 0 00-5-5z"
      ></path>
    </svg>
  );
}

export default StarIcon;
