/**
 * @flow
 * @relayHash d5beaadc891440b54a7412e6ade5f9d9
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getProductItemsQueryVariables = {|
  tenantId: string,
  productName: string,
|};
export type getProductItemsQueryResponse = {|
  +productItems: ?$ReadOnlyArray<?{|
    +name: string,
    +mount: string,
  |}>
|};
export type getProductItemsQuery = {|
  variables: getProductItemsQueryVariables,
  response: getProductItemsQueryResponse,
|};
*/


/*
query getProductItemsQuery(
  $tenantId: ID!
  $productName: String!
) {
  productItems(tenantId: $tenantId, productName: $productName) {
    name
    mount
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "tenantId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "productName",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "productName",
    "variableName": "productName"
  },
  {
    "kind": "Variable",
    "name": "tenantId",
    "variableName": "tenantId"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "mount",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getProductItemsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "productItems",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ProductItem",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "getProductItemsQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "productItems",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ProductItem",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "getProductItemsQuery",
    "id": null,
    "text": "query getProductItemsQuery(\n  $tenantId: ID!\n  $productName: String!\n) {\n  productItems(tenantId: $tenantId, productName: $productName) {\n    name\n    mount\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b0db8efca07517e29a1354e8e9d15cb4';
module.exports = node;
