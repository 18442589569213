import React from "react";

export default function ReplaceIcon({ className = "text-[#333333]", size = 15 }) {
  return (
    <svg
      className={className}
      width={size}
      viewBox="0 0 15 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.16683 2.75073H10.3335C12.2667 2.75073 13.8335 4.31757 13.8335 6.25073C13.8335 8.1839 12.2667 9.75073 10.3335 9.75073H8.5835L10.3335 11.5007"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.66667 9.75073H4.5C2.56683 9.75073 1 8.1839 1 6.25073C1 4.31757 2.56683 2.75073 4.5 2.75073H6.25L4.5 1.00073"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
