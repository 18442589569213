import React from "react";
import PropTypes from "prop-types";
import { createFragmentContainer } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import SimpleList from "./SimpleList";

export default createFragmentContainer(SimpleList, {
  suggestions: graphql`
    fragment SimpleList_suggestions on PicklistInterface @relay(plural: true) {
      region
      ...ListItem_item
    }
  `,
});
