import React from "react";
import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";

import { debounce } from "lodash";

const MAX_SUGGESTIONS_AT_A_TIME = 10;

let scrollingTimer;

export const ListComponent = ({
  ContainerComponent = Grid,
  container_props,
  InnerContainer = Grid,
  inner_container_props,
  ListComponentUnit,
  suggestions,
  scrollDist,
  maxSuggestions = MAX_SUGGESTIONS_AT_A_TIME,
  ...props
}) => {
  const [feedSuggestions, setFeedsuggestions] = React.useState([]);
  const [pos, setPos] = React.useState(0);
  const [done] = React.useState(new Map());
  let [isScrolling, setScrolling] = React.useState(false);

  React.useEffect(() => {
    if (!suggestions) {
      return;
    }

    setFeedsuggestions(
      (suggestions && suggestions.length && suggestions.slice(0, maxSuggestions)) || []
    );
    setPos(0);
    done.clear();
  }, [suggestions]);

  function onScroll(e) {
    if (!suggestions || !suggestions.length || maxSuggestions >= suggestions.length) {
      return;
    }
    if (done.get(maxSuggestions * (pos + 1))) {
      return;
    }

    const distFromBottom = e.target.scrollHeight - (e.target.clientHeight + e.target.scrollTop);

    if (distFromBottom <= 10) {
      setPos(pos + 1);
      const startIndex = maxSuggestions * (pos + 1);
      setFeedsuggestions([
        ...feedSuggestions,
        ...suggestions.slice(startIndex, startIndex + maxSuggestions),
      ]);
      done.set(startIndex, true);
    }
  }

  return (
    <ContainerComponent {...container_props} onScroll={onScroll}>
      <InnerContainer {...inner_container_props}>
        <ListComponentUnit {...props} suggestions={feedSuggestions} isScrolling={isScrolling} />
      </InnerContainer>
    </ContainerComponent>
  );
};

export default React.memo(ListComponent);
