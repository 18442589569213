import React from "react";

export default function SmsReceivedIcon({ className = "text-[#999999]", width = 18, height = 18 }) {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.875 3.375H4.125C3.711 3.375 3.375 3.711 3.375 4.125V11.625C3.375 12.039 3.711 12.375 4.125 12.375H7.125L9 14.625L10.875 12.375H13.875C14.289 12.375 14.625 12.039 14.625 11.625V4.125C14.625 3.711 14.289 3.375 13.875 3.375Z"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <g clipPath="url(#clip0_1703_35055)">
        <path
          d="M7.5625 9.4375L10.9375 6.0625"
          stroke="currentColor"
          stroke-width="0.75"
          stroke-linecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.8125 9.4375H7.5625V7.1875"
          stroke="currentColor"
          stroke-width="0.75"
          stroke-linecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1703_35055">
          <rect width="4.5" height="4.5" fill="white" transform="translate(11.5 10) rotate(-180)" />
        </clipPath>
      </defs>
    </svg>
  );
}
