import React from "react";
import clsx from "clsx";
import { Box, InputBase, Autocomplete } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {
    height: 32,
    position: "relative",
    backgroundColor: "#fff",
    display: "flex",
    "&:hover": {
      border: "1px solid var(--hylyTheme)",
    },
    border: ({ focusInput }) => {
      return focusInput ? "1px solid #999999" : "1px solid #dadada";
    },
  },
  searchIcon: {
    paddingRight: 8,
    height: "100%",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#fff",
  },
  closeIcon: {
    paddingRight: 8,
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
    background: "#fff",
  },
  inputInput: {
    fontFamily: "Open Sans",
    fontSize: 12,
    paddingLeft: 8,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
  icon: {
    width: 10,
    height: 10,
  },

  inputDivider: {
    width: 1,
    height: 30,
    backgroundColor: "#dadada",
    marginRight: 26,
  },
}));

const SearchAutoComplete = ({
  onSearch,
  className,
  placeholder,
  searchType, // available values: "key", "auto",
  options,
}) => {
  const [searchText, setSearchText] = React.useState("");
  const [isFocused, setIsFocused] = React.useState(false);
  const classes = useStyles({ focusInput: isFocused });

  const handleChange = (_, val) => {
    const value = val?.value || val;
    setSearchText(value);
    if (searchType === "auto") onSearch(value);
  };

  return (
    <Box className={clsx(classes.root, className)}>
      <Autocomplete
        options={options || []}
        value={searchText}
        placeholder={""}
        onChange={handleChange}
        renderInput={(params) => {
          return (
            <InputBase
              {...params.InputProps}
              placeholder={placeholder}
              ref={params.InputProps.ref}
              inputProps={params.inputProps}
              autoFocus
              className={classes.inputBase}
              endAdornment={
                <div>
                  <div className={classes.inputDivider} />
                  {params.InputProps.endAdornment}
                </div>
              }
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
            />
          );
        }}
      />
    </Box>
  );
};

SearchAutoComplete.propTypes = {
  onSearch: PropTypes.func.isRequired,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  searchType: PropTypes.string,
};

SearchAutoComplete.defaultProps = {
  onSearch: () => {},
  className: undefined,
  placeholder: "Search",
  searchType: "key",
};

export default SearchAutoComplete;
