/**
 * @flow
 * @relayHash ffbbb20dfb2d2d6e9ce8454d9ca146c8
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type toggleTourSchedularInput = {|
  propertyId: string,
  tourScheduleEnabled: boolean,
|};
export type toggleTourSchedularMutationVariables = {|
  input: toggleTourSchedularInput
|};
export type toggleTourSchedularMutationResponse = {|
  +toggleTourSchedular: ?{|
    +response: {|
      +tourScheduleEnabled: boolean
    |}
  |}
|};
export type toggleTourSchedularMutation = {|
  variables: toggleTourSchedularMutationVariables,
  response: toggleTourSchedularMutationResponse,
|};
*/


/*
mutation toggleTourSchedularMutation(
  $input: toggleTourSchedularInput!
) {
  toggleTourSchedular(input: $input) {
    response {
      tourScheduleEnabled
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "toggleTourSchedularInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "toggleTourSchedular",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "toggleTourSchedularPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "response",
        "storageKey": null,
        "args": null,
        "concreteType": "ToggleTourSchedularResponse",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "tourScheduleEnabled",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "toggleTourSchedularMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "toggleTourSchedularMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "toggleTourSchedularMutation",
    "id": null,
    "text": "mutation toggleTourSchedularMutation(\n  $input: toggleTourSchedularInput!\n) {\n  toggleTourSchedular(input: $input) {\n    response {\n      tourScheduleEnabled\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '25b5b0926e226153c6692d93a8dc4b3c';
module.exports = node;
