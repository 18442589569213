import React from "react";

export default function hyChatIcon({ className = "text-[#999999]", width = 18, height = 18 }) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.8 3C5.1488 3 3 4.7912 3 7C3 7.8168 3.2952 8.576 3.8 9.2096V12.2L5.8584 10.656C6.452 10.876 7.108 11 7.8 11C10.4512 11 12.6 9.2088 12.6 7C12.6 4.7912 10.4512 3 7.8 3Z"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.77515 12.6032C7.64635 13.3416 8.85915 13.8 10.1999 13.8C10.8263 13.8 11.4231 13.6968 11.9719 13.5152L14.1999 15V12.0096C14.7047 11.376 14.9999 10.6176 14.9999 9.8C14.9999 9.0728 14.7671 8.3912 14.3607 7.804"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
