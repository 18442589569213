import React from "react";
import ViewNavigation from "./parts/ViewNavigation";
import moment from "moment";
import { getBestTimeLabel } from "..";

export default class DaysView extends React.Component {
  static defaultProps = {
    isValidDate: () => true,
    renderDay: (props, date) => <td {...props}>{date.date()}</td>,
  };

  render() {
    return (
      <div className="rdtDays">
        <table>
          <thead>
            {this.renderNavigation()}
            {this.renderDayHeaders()}
          </thead>
          <tbody>{this.renderDays()}</tbody>
          {this.renderFooter()}
        </table>
      </div>
    );
  }
  renderNavigation() {
    let year = this.props.viewDate.year();
    const getMonth = this.props.viewDate.format("MMMM");
    return (
      <ViewNavigation
        onClickMonth={() => this.props.showView("months")}
        onClickYear={() => this.props.showView("years")}
        onTodayClick={() => this.props.showView("today")}
        yearContent={year}
        monthContent={getMonth}
        onClickPrev={() => this.props.navigate(-1, "months")}
        //onClickSwitch={() => this.props.showView('months')}
        onClickNext={() => this.props.navigate(1, "months")}
        //switchContent={locale.months(date) + ' ' + date.year()}
        switchColSpan={2}
        //switchProps={{ 'data-value': this.props.viewDate.month() }}
        updateDate={this.props.updateDate}
        splittedView={this.props.splittedView}
        selectedDate={this.props.selectedDate}
        isValidDate={this.props.isValidDate}
        viewDate={this.props.viewDate}
      />
    );
  }

  renderDayHeaders() {
    const locale = this.props.viewDate.localeData();
    let dayItems = getDaysOfWeek(locale).map((day, index) => (
      <th key={day + index} className="dow">
        <div className="header-margin">{day}</div>
      </th>
    ));

    return <tr>{dayItems}</tr>;
  }

  renderDays() {
    const date = this.props.viewDate;
    const startOfMonth = date.clone().startOf("month");
    const endOfMonth = date.clone().endOf("month");

    // We need 42 days in 6 rows
    // starting in the last week of the previous month
    let rows = [[], [], [], [], [], []];

    let startDate = date.clone().subtract(1, "months");
    startDate.date(startDate.daysInMonth()).startOf("week");

    let endDate = startDate.clone().add(42, "d");
    let i = 0;

    while (startDate.isBefore(endDate)) {
      let row = getRow(rows, i++);
      row.push(this.renderDay(startDate, startOfMonth, endOfMonth));
      startDate.add(1, "d");
    }

    return rows.map((r, i) => <tr key={`${endDate.month()}_${i}`}>{r}</tr>);
  }

  renderDay(date, startOfMonth, endOfMonth) {
    let selectedDate = this.props.selectedDate;
    let dayProps = {
      key: date.format("M_D"),
      "data-value": date.date(),
      "data-month": date.month(),
      "data-year": date.year(),
    };

    let className = "rdtDay";
    if (date.isBefore(startOfMonth)) {
      className += " rdtOld";
    } else if (date.isAfter(endOfMonth)) {
      className += " rdtNew";
    }
    if (selectedDate && date.isSame(selectedDate, "day")) {
      className += " rdtActive";
    }
    if (date.isSame(this.props.moment(), "day")) {
      className += " rdtToday";
    }

    if (this.props.isValidDate(date)) {
      dayProps.onClick = this._setDate;
    } else {
      className += " rdtDisabled";
    }

    dayProps.className = className;

    return this.props.renderDay(dayProps, date.clone(), selectedDate && selectedDate.clone());
  }

  renderBestDay(dayData, isLast) {
    return (
      <>
        <div className="bestDayLabel">{dayData.value}</div>
        {!isLast && <div className="bestDaySplit">&nbsp;</div>}
      </>
    );
  }

  renderFooter() {
    if (!this.props.timeFormat) return;

    const date = this.props.viewDate;
    const { scheduledDayData, scheduledHourData } = this.props;
    let daysData = [];
    if (scheduledDayData) {
      if (scheduledDayData.excellent_day) {
        const value = scheduledDayData.excellent_day
          .split(", ")
          .map((weekday) => moment().day(weekday).format("ddd"))
          .join(", ");
        daysData.push({ label: "Excellent", value: value, color: "#0BCC41" });
      }
      if (scheduledDayData.good_day) {
        const value = scheduledDayData.good_day
          .split(", ")
          .map((weekday) => moment().day(weekday).format("ddd"))
          .join(", ");
        daysData.push({ label: "Good", value: value, color: "#2785DD" });
      }
      if (scheduledDayData.poor_day) {
        const value = scheduledDayData.poor_day
          .split(", ")
          .map((weekday) => moment().day(weekday).format("ddd"))
          .join(", ");
        daysData.push({ label: "Poor", value: value, color: "#F1A066" });
      }
    }
    return (
      <tfoot>
        <tr>
          <td onClick={() => this.props.showView("time")} colSpan={7} className="rdtTimeToggle">
            <div className="dayContainer">
              {daysData.map((dayData, dayIndex) =>
                this.renderBestDay(dayData, dayIndex === daysData.length - 1)
              )}
            </div>
            <div className="timeContainer">
              {date.format(this.props.timeFormat)}
              <div className="timeBestLabel">
                {scheduledHourData && getBestTimeLabel(date, scheduledHourData)}
              </div>
            </div>
          </td>
        </tr>
      </tfoot>
    );
  }

  _setDate = (e) => {
    this.props.updateDate(e);
  };
}

function getRow(rows, day) {
  return rows[Math.floor(day / 7)];
}

/**
 * Get a list of the days of the week
 * depending on the current locale
 * @return {array} A list with the shortname of the days
 */
function getDaysOfWeek(locale) {
  const first = locale.firstDayOfWeek();
  let dow = [];
  let i = 0;

  locale._weekdaysMin.forEach(function (day) {
    dow[(7 + i++ - first) % 7] = day;
  });

  return dow;
}
