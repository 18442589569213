export const styles = () => ({
  flexBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  container: {
    marginTop: 16,
  },
  flexLabel: {
    display: "flex",
    alignItems: "center",
  },
  helperText: {
    marginTop: 8,
    fontSize: 12,
    color: "rgba(0, 0, 0, 0.5)",
    marginLeft: 128,
  },
  marginEditor: {
    marginTop: -11,
  },
  textEditor: {
    position: "relative",
    width: "745px",
    backgroundColor: "#ffffff",
    border: "1px solid #dadada",
    "&:focus-within": {
      borderColor: "#999999 !important",
    },
    "&:hover": {
      borderColor: "#26BBED !important",
      "& i": {
        color: "#26BBED !important",
      },
    },
  },
  bodyPencilIcon: {
    position: "absolute",
    top: 12,
    right: 12,
  },

  popoverPaper: {
    marginTop: "8px",
    overflow: "inherit",
    width: "495px",
  },
  arrowHead: {
    height: "12px",
    width: "12px",
    transform: "rotate(45deg)",
    position: "absolute",
    borderTop: "1px solid #dadada",
    borderLeft: "1px solid #dadada",
    zIndex: "1",
    background: "white",
    top: "-7px",
    left: "16px",
  },
  popoverContent: {
    border: "1px solid #ccc",
    padding: "16px",
    background: "white",
  },
  ctaHeader: {
    fontFamily: "ralewayregular, Arial,sans-serif",
    fontSize: "14px",
    fontWeight: "600",
    lineHeight: "20px",
    color: "#33333380",
  },
  label: {
    display: "flex",
    gap: "10px",
    fontFamily: " ralewayregular, Arial,sans-serif",
    fontSize: " 12px",
    fontWeight: " 400",
    lineWeight: " 18px",
    marginTop: "16px",
    color: "#333333BF",
    paddingLeft: "4px",
    alignItems: " center",
  },
  inputContainer: {
    display: "flex",
    alignItems: "center",
  },
  inputStyle: {
    fontFamily: " ralewayregular, Arial,sans-serif",
    fontSize: " 16px",
    fontWeight: " 400",
    lineHeight: " 24px",
    outline: "none",
    border: "none",
    borderBottom: "1px solid #DADADA",
    marginTop: "6px",
    width: "100%",
    resize: "none",
  },
  buttonContainer: {
    display: " flex",
    gap: " 10px",
    paddingTop: " 20px",
  },
  button: {
    border: "1px solid #999",
    background: "#fff",
    padding: "10px",
    display: "flex",
    gap: "10px",
    alignItems: "center",
    color: "#333",
    cursor: "pointer",
    fontWeight: "600",
    fontSize: "14px",
    "&:hover path": {
      stroke: "#26BBED !important",
    },
  },
  popupFooterContainer: {
    whiteSpace: "pre-wrap",
    marginTop: "20px",
    fontSize: "11px",
    display: "flex",
    "& div": {
      whiteSpace: "nowrap",
    },
    "& span": {
      display: "flex",
      alignItems: "center",
      fontSize: "12px",
      color: "#4F6FAE",
      fontWeight: "600",
      cursor: "pointer",
      gap: "4px",
    },
    "& span:hover": {
      "& svg": {
        stroke: "#4F6FAE !important",
      },
      "& path": {
        stroke: "#4F6FAE !important",
      },
    },
  },
});
