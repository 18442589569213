import React from "react";

export default function FormSubmittedIcon({
  className = "text-[#999999]",
  width = 18,
  height = 18,
}) {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 3H3V10.2H15V3Z"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.9999 12.5996H9.3999V14.9996H14.9999V12.5996Z"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path d="M3 5.40039H15" stroke="currentColor" stroke-linecap="round" strokeLinejoin="round" />
    </svg>
  );
}
