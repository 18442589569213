import { Divider, Slider } from "@mui/material";
import React, { useState } from "react";
import Cropper from "react-easy-crop";
import Typography from "storybookComponents/Typography";
import { Button } from "storybookComponents";

const ImageCropper = ({
  imgBase64,
  setImgBase64,
  dimension_width,
  dimension_height,
  setIsCropper,
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState({ x: 0, y: 0 });

  const getCroppedImg = () => {
    const srcImage = imgBase64;
    const pixelCrop = croppedAreaPixels;
    let img = new Image();
    img.crossOrigin = "anonymous";
    img.src = srcImage;
    const targetX = pixelCrop.x;
    const targetY = pixelCrop.y;
    const targetWidth = pixelCrop.width;
    const targetHeight = pixelCrop.height;
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    img.onload = function () {
      canvas.width = dimension_width;
      canvas.height = dimension_height;
      ctx.imageSmoothingEnabled = true;
      ctx.imageSmoothingQuality = "high";
      ctx.drawImage(
        img,
        targetX,
        targetY,
        targetWidth,
        targetHeight,
        0,
        0,
        dimension_width,
        dimension_height
      );

      let croppedImage = canvas.toDataURL("image/png", 1);

      setImgBase64(croppedImage);
      setIsCropper(false);
    };
  };

  return (
    <>
      <div className="flex justify-between items-center">
        <Typography type="body1" color="black">
          Crop Image
        </Typography>
        <div className="flex justify-end">
          <Button
            variant="tertiary"
            size="sm"
            onClick={() => {
              setImgBase64(null);
              setIsCropper(false);
            }}
            state={"active"}
            color="darkBlack"
          >
            Cancel
          </Button>

          <Button
            variant="primary"
            size="sm"
            onClick={getCroppedImg}
            color="hylyBlue"
            state={"active"}
          >
            Done
          </Button>
        </div>
      </div>

      <Divider />

      <div className="w-full items-center flex flex-col">
        <Typography type="body2" color="deepGrey">
          Drag to reposition photo
        </Typography>
        <div className="w-40">
          <Slider
            value={zoom}
            min={1}
            max={2}
            step={0.1}
            aria-labelledby="Zoom"
            onChange={(e, v) => setZoom(v)}
            classes={{
              container: "slider",
              track: "track",
              thumb: "thumb",
            }}
          />
        </div>
      </div>
      <div className="relative w-full h-full">
        <Cropper
          image={imgBase64}
          crop={crop}
          zoom={zoom}
          aspect={100 / 100}
          onCropChange={(v) => setCrop(v)}
          onCropComplete={(e, v) => setCroppedAreaPixels(v)}
          onZoomChange={(e, v) => setZoom(v)}
          restrictPosition={true}
        />
      </div>
    </>
  );
};

export default ImageCropper;
