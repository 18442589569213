import * as React from "react";
import isEmailFn from "./isEmail";

class ReactMultiEmail extends React.Component {
  state = {
    focused: false,
    emails: [],
    inputValue: "",
  };

  emailInputRef;

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.propsEmails !== nextProps.emails) {
      return {
        propsEmails: nextProps.emails || [],
        emails: nextProps.emails || [],
        inputValue: "",
        focused: false,
      };
    }
    return null;
  }

  constructor(props) {
    super(props);

    this.emailInputRef = React.createRef();
  }

  findEmailAddress = (value, isEnter) => {
    const { validateEmail } = this.props;
    let validEmails = [];
    let inputValue = "";
    const re = /[ ,;]/g;
    const isEmail = validateEmail || isEmailFn;

    const addEmails = (email) => {
      const emails = this.state.emails;
      for (let i = 0, l = emails.length; i < l; i++) {
        if (emails[i] === email) {
          return false;
        }
      }
      validEmails.push(email);
      return true;
    };

    if (value !== "") {
      if (this.props.mask) {
        let masked = this.format(this.props.mask, new String(value).replace(/-/g, ""));
        if (re.test(value)) {
          value = masked + value[value.length - 1];
          re.test(value);
        } else value = masked;
      }

      if (re.test(value)) {
        let splitData = value.split(re).filter((n) => {
          return n !== "" && n !== undefined && n !== null;
        });

        const setArr = new Set(splitData);
        let arr = [...setArr];

        do {
          if (isEmail("" + arr[0])) {
            addEmails("" + arr.shift());
          } else {
            if (arr.length === 1) {
              inputValue = "" + arr.shift();
            } else {
              arr.shift();
            }
          }
        } while (arr.length);
      } else {
        if (isEnter) {
          if (isEmail(value)) {
            addEmails(value);
          } else {
            inputValue = value;
          }
        } else {
          inputValue = value;
        }
      }
    }

    this.setState({
      emails: [...this.state.emails, ...validEmails],
      inputValue: inputValue,
    });

    if (validEmails.length && this.props.onChange) {
      this.props.onChange([...this.state.emails, ...validEmails]);
    }
  };

  onChangeInputValue = (value) => {
    this.findEmailAddress(value);
  };

  removeEmail = (index, isDisabled) => {
    if (isDisabled) {
      return;
    }
    this.setState(
      (prevState) => {
        return {
          emails: [...prevState.emails.slice(0, index), ...prevState.emails.slice(index + 1)],
        };
      },
      () => {
        if (this.props.onChange) {
          this.props.onChange(this.state.emails);
        }
      }
    );
  };

  handleOnKeydown = (e) => {
    switch (e.which) {
      case 13:
      case 9:
        e.preventDefault();
        break;
      case 8:
        if (!e.currentTarget.value) {
          this.removeEmail(this.state.emails.length - 1, false);
        }
        break;
      default:
    }
  };

  format(mask, number) {
    var s = "" + number,
      r = "";
    for (var im = 0, is = 0; im < mask.length && is < s.length; im++) {
      r += mask.charAt(im) == "X" ? s.charAt(is++) : mask.charAt(im);
    }
    return r;
  }

  handleOnKeyup = (e) => {
    switch (e.which) {
      case 13:
      case 9:
        this.findEmailAddress(e.target.value, true);
        break;
      default:
    }
  };

  handleOnChange = (e) => {
    if (this.props.restrictText) {
      if (/[a-zA-Z!@#$%^`&*()_+\=\[\]{};':"\\|.<>\/?]/.test(e.currentTarget.value)) return;
      if (this.props.regex && e.currentTarget.value && /[,]/.test(e.currentTarget.value)) {
        const isEmail = this.props.validateEmail || isEmailFn;
        const re = /[ ,;]/g;
        let splitData = e.currentTarget.value.split(re).filter((n) => {
          return n !== "" && n !== undefined && n !== null;
        });

        const setArr = new Set(splitData);
        let arr = [...setArr];
        if (!isEmail("" + arr[0])) return;
      }
    }
    this.onChangeInputValue(e.currentTarget.value);
  };

  handleOnBlur = (e) => {
    this.setState({ focused: false });
    this.findEmailAddress(e.currentTarget.value, true);
  };

  handleOnFocus = () =>
    this.setState({
      focused: true,
    });

  render() {
    const { focused, emails, inputValue } = this.state;
    const { style, getLabel, className = "", noClass, placeholder } = this.props;

    // removeEmail

    return (
      <div
        className={`${className} ${noClass ? "" : "react-multi-email"} ${
          focused ? "focused" : ""
        } ${inputValue === "" && emails.length === 0 ? "empty" : ""}`}
        style={style}
        onClick={() => {
          if (this.emailInputRef.current) {
            this.emailInputRef.current.focus();
          }
        }}>
        {placeholder ? <span data-placeholder>{placeholder}</span> : null}
        {emails.map((email, index) => getLabel(email, index, this.removeEmail))}
        <input
          ref={this.emailInputRef}
          type="text"
          value={inputValue}
          onFocus={this.handleOnFocus}
          onBlur={this.handleOnBlur}
          onChange={this.handleOnChange}
          onKeyDown={this.handleOnKeydown}
          onKeyUp={this.handleOnKeyup}
        />
      </div>
    );
  }
}

export default ReactMultiEmail;
