/**
 * @flow
 * @relayHash 467d7f1b7fc1b791d99196ca04a986de
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getProfileQueryVariables = {|
  id?: ?string
|};
export type getProfileQueryResponse = {|
  +profile: {|
    +email: string,
    +firstName: ?string,
    +id: string,
    +initials: ?string,
    +lastName: ?string,
    +avatarUrl: ?string,
  |}
|};
export type getProfileQuery = {|
  variables: getProfileQueryVariables,
  response: getProfileQueryResponse,
|};
*/


/*
query getProfileQuery(
  $id: ID
) {
  profile(id: $id) {
    email
    firstName
    id
    initials
    lastName
    avatarUrl
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "profile",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "Account",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "email",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "firstName",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "initials",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "lastName",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "avatarUrl",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getProfileQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "getProfileQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "getProfileQuery",
    "id": null,
    "text": "query getProfileQuery(\n  $id: ID\n) {\n  profile(id: $id) {\n    email\n    firstName\n    id\n    initials\n    lastName\n    avatarUrl\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a1c3ed4cb60d51bca9b68bf64d7c8099';
module.exports = node;
