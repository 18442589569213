import React from "react";

export default function EmailIcon(props) {
  return (
    <svg
      className={props.className}
      width={props.width}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" fill="white" />
      <path
        d="M7.01562 7.94812L12.0004 12.4798L16.9852 7.94812"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.3202 7.68005H7.68021C7.15002 7.68005 6.72021 8.10986 6.72021 8.64005V15.3601C6.72021 15.8902 7.15002 16.3201 7.68021 16.3201H16.3202C16.8504 16.3201 17.2802 15.8902 17.2802 15.3601V8.64005C17.2802 8.10986 16.8504 7.68005 16.3202 7.68005Z"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="currentColor" />
    </svg>
  );
}
