import graphql from "babel-plugin-relay/macro";

export default graphql`
  query getOfferQuery($id:ID!){
    offer(id:$id){
      active
      deletedAt
      description
      designOffer
      id
      image{
        fileUrl
        id
        thumbnailUrl
      }
      offerCtaText
      offerCtaUrl
      orgId
      orgName
      shortTourUrl
      subTitle
      title
      propertyCount
      campaignTitle
      prospectInviteEmailCampaignHtml
      prospectInviteEmailCampaignId
      prospectInviteEmailSubject
      abortProspectInviteEmailCampaignId
      facebookSharedCount
      emailScheduledCount
      isSent
    }
  }
`