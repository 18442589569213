/**
 * @flow
 * @relayHash 7161c24f68ca13f1a4591b84b2b0d5a4
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getOrgQueryVariables = {|
  appName?: ?string
|};
export type getOrgQueryResponse = {|
  +authorizedOrgs: $ReadOnlyArray<{|
    +id: string,
    +name: string,
    +userRole: ?string,
    +suborgs: $ReadOnlyArray<{|
      +id: string,
      +name: string,
    |}>,
  |}>
|};
export type getOrgQuery = {|
  variables: getOrgQueryVariables,
  response: getOrgQueryResponse,
|};
*/


/*
query getOrgQuery(
  $appName: String
) {
  authorizedOrgs(appName: $appName) {
    id
    name
    userRole
    suborgs {
      id
      name
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "appName",
    "type": "String",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "authorizedOrgs",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "appName",
        "variableName": "appName"
      }
    ],
    "concreteType": "Org",
    "plural": true,
    "selections": [
      (v1/*: any*/),
      (v2/*: any*/),
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "userRole",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "suborgs",
        "storageKey": null,
        "args": null,
        "concreteType": "Suborg",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/)
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getOrgQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "getOrgQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "getOrgQuery",
    "id": null,
    "text": "query getOrgQuery(\n  $appName: String\n) {\n  authorizedOrgs(appName: $appName) {\n    id\n    name\n    userRole\n    suborgs {\n      id\n      name\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd9c2def24a9b8519d369a17b50cba37a';
module.exports = node;
