import graphql from "babel-plugin-relay/macro";

export default graphql`
  query gethayleyChatDisclaimerQuery($orgId: ID!, $propertyId: ID!) {
    hayleyChatDisclaimer(orgId: $orgId, propertyId: $propertyId) {
      orgLevelDisclaimer
      orgLevelDisclaimerEnabled
      propertyLevelDisclaimer
      propertyLevelDisclaimerEnabled
      disclaimerType
    }
  }
`;
