import graphql from "babel-plugin-relay/macro";

export default graphql`
  query getAlertsEmailsQuery($orgId: ID!, $propertyId: String, $first: Int = 1000) {
    authorizedSuborgs(orgId: $orgId, propertyId: $propertyId, first: $first) {
      id
      name
      alerts {
        id
        name
        active
        property {
          id
          name
        }
        thenEmails {
          id
          name
          active
          mms
        }
      }
    }
  }
`;
