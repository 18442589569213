import React from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import { Button } from "storybookComponents";
import { getExportDashboard } from "relay";

const ExportReport = ({
  classes,
  onClose,
  id,
  // subOrgDetails,
  showNotification,
  storeParamsPayload,
}) => {
  const [values, setValues] = React.useState({
    pdf: storeParamsPayload ? false : true,
    csv: true,
  });
  const [active, setActive] = React.useState("active");

  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.checked,
    });
  };

  const handleExport = async () => {
    setActive("inactive");
    let type = Object.keys(values).filter((key) => values[key]);
    try {
      let response = await getExportDashboard(id, type.toString());
      if (response.exportDashboard && response.exportDashboard.filePath) {
        showNotification("You have export the report successfully");
        const urls = response.exportDashboard.filePath.split(",");
        window.open(urls[0], "_blank");
        if (urls[1]) {
          window.open(urls[1], "_blank");
        }
      } else {
        showNotification("Something went wrong", "error");
      }
    } catch (err) {
      showNotification("Something went wrong", "error");
      console.log("Err in export Report", err);
    }
    setActive("active");
  };

  const handleExportDripsBlast = async () => {};

  const handleClick = () => {
    if (storeParamsPayload) {
      handleExportDripsBlast();
    } else {
      handleExport();
    }
  };

  return (
    <div>
      <div className={classes.header}>Please select the format in which you want to export.</div>
      <FormControlLabel
        control={
          <Checkbox
            disabled={storeParamsPayload}
            checked={values.pdf}
            name="pdf"
            onChange={handleChange}
          />
        }
        label="PDF"
      />
      <br />
      <FormControlLabel
        control={<Checkbox checked={values.csv} name="csv" onChange={handleChange} />}
        label="CSV"
      />
      <br />
      <div className={classes.subtitle}>Select atleast one format to continue</div>
      <div className={classes.bottomFlexBox}>
        <Button className={classes.btnStyle} variant="tertiary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          className={classes.btnStyle}
          state={!values.pdf && !values.csv ? "inactive" : active}
          onClick={handleClick}
        >
          Export
        </Button>
      </div>
    </div>
  );
};

export default ExportReport;
