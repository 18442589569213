import * as api from "relay/main";

import wmy from "storybookComponents/images/unpurchased/winback/wmy.png";
import na from "storybookComponents/images/unpurchased/winback/na.png";
import itt from "storybookComponents/images/unpurchased/winback/itt.png";

import a from "storybookComponents/images/unpurchased/resident/a.png";
import moc from "storybookComponents/images/unpurchased/resident/moc.png";
import nr from "storybookComponents/images/unpurchased/resident/nr.png";
import rr from "storybookComponents/images/unpurchased/resident/rr.png";
import rs from "storybookComponents/images/unpurchased/resident/rs.png";
import st from "storybookComponents/images/unpurchased/resident/st.png";

import sa from "storybookComponents/images/unpurchased/prospect/sa.png";
import sf from "storybookComponents/images/unpurchased/prospect/sf.png";
import sn from "storybookComponents/images/unpurchased/prospect/sn.png";

import aa from "storybookComponents/images/unpurchased/new/aa.png";
import d from "storybookComponents/images/unpurchased/new/d.png";
import fas from "storybookComponents/images/unpurchased/new/fas.png";
import pic from "storybookComponents/images/unpurchased/new/pic.png";
import rw from "storybookComponents/images/unpurchased/new/rw.png";
import sit from "storybookComponents/images/unpurchased/new/sit.png";

import { getPropertyId } from "common";
import { arrDiff } from "services";
import { LayersIcon2 } from "icons";

export const unpurchasedAngels = [
  {
    id: "prospect_angel",
    name: "Prospect Nurture Angel",
    active: false,
    thenEmails: [
      {
        id: "1",
        name: "Showcase Amenities",
        active: false,
        triggerDays: 3,
        originalThumbnailUrl: sa,
        preview: "https://s3.amazonaws.com/m.hy.ly/hyALM/angel-emails/Showcase-Amenities-min.jpg",
        whenGroupName: null,
      },
      {
        id: "2",
        name: "Showcase Features",
        active: false,
        triggerDays: 6,
        originalThumbnailUrl: sf,
        preview: "https://s3.amazonaws.com/m.hy.ly/hyALM/angel-emails/Showcase-Features-min.jpg",
        whenGroupName: null,
      },
      {
        id: "3",
        name: "Showcase Neighborhood",
        active: false,
        triggerDays: 9,
        originalThumbnailUrl: sn,
        preview:
          "https://s3.amazonaws.com/m.hy.ly/hyALM/angel-emails/Showcase-Neighborhood-min.jpg",
        whenGroupName: null,
      },
    ],
  },
  {
    id: "new_resident_angel",
    name: "Resident Welcome Angel",
    active: false,
    thenEmails: [
      {
        id: "1",
        name: "Application Approved",
        active: false,
        triggerDays: 0,
        originalThumbnailUrl: aa,
        preview: "https://s3.amazonaws.com/m.hy.ly/hyALM/angel-emails/Application-Approved-min.jpg",
        whenGroupName: null,
      },
      {
        id: "2",
        name: "PreMove In Checklist",
        active: false,
        triggerDays: 3,
        originalThumbnailUrl: pic,
        preview: "https://s3.amazonaws.com/m.hy.ly/hyALM/angel-emails/PreMove-In-Checklist-min.jpg",
        whenGroupName: null,
      },
      {
        id: "3",
        name: "Resident Welcome",
        active: false,
        triggerDays: 1,
        originalThumbnailUrl: rw,
        preview: "https://s3.amazonaws.com/m.hy.ly/hyALM/angel-emails/Resident-Welcome-min.jpg",
        whenGroupName: null,
      },
      {
        id: "4",
        name: "Stay In Touch",
        active: false,
        triggerDays: 4,
        originalThumbnailUrl: sit,
        preview: "https://s3.amazonaws.com/m.hy.ly/hyALM/angel-emails/Stay-In-Touch-min.jpg",
        whenGroupName: null,
      },
      {
        id: "5",
        name: "Fitness Amenities Services",
        active: false,
        triggerDays: 5,
        originalThumbnailUrl: fas,
        preview:
          "https://s3.amazonaws.com/m.hy.ly/hyALM/angel-emails/Fitness-Amenities-Services-min.jpg",
        whenGroupName: null,
      },
      {
        id: "6",
        name: "Dining",
        active: false,
        triggerDays: 14,
        originalThumbnailUrl: d,
        preview: "https://s3.amazonaws.com/m.hy.ly/hyALM/angel-emails/Dining-min.jpg",
        whenGroupName: null,
      },
    ],
  },
  {
    id: "resident_retention_angel",
    name: "Resident Retention Angel",
    active: false,
    thenEmails: [
      {
        id: "1",
        name: "Anniversary",
        active: false,
        triggerDays: 30,
        originalThumbnailUrl: a,
        preview: "https://s3.amazonaws.com/m.hy.ly/hyALM/angel-emails/Anniversary-min.jpg",
        whenGroupName: null,
      },
      {
        id: "2",
        name: "Resident Survey",
        active: false,
        triggerDays: 60,
        originalThumbnailUrl: rs,
        preview: "https://s3.amazonaws.com/m.hy.ly/hyALM/angel-emails/Resident-Survey-min.jpg",
        whenGroupName: null,
      },
      {
        id: "3",
        name: "Resident Referral",
        active: false,
        triggerDays: 90,
        originalThumbnailUrl: rr,
        preview: "https://s3.amazonaws.com/m.hy.ly/hyALM/angel-emails/Resident-Referral-min.jpg",
        whenGroupName: null,
      },
      {
        id: "4",
        name: "Soft Touch",
        active: false,
        triggerDays: 90,
        originalThumbnailUrl: st,
        preview: "https://s3.amazonaws.com/m.hy.ly/hyALM/angel-emails/Soft-Touch-min.jpg",
        whenGroupName: null,
      },
      {
        id: "5",
        name: "Notice Reminder",
        active: false,
        triggerDays: 60,
        originalThumbnailUrl: nr,
        preview: "https://s3.amazonaws.com/m.hy.ly/hyALM/angel-emails/Notice-Reminder-min.jpg",
        whenGroupName: null,
      },
      {
        id: "6",
        name: "Move Out Checklist",
        active: false,
        triggerDays: 30,
        originalThumbnailUrl: moc,
        preview: "https://s3.amazonaws.com/m.hy.ly/hyALM/angel-emails/Move-Out-Checklist-min.jpg",
        whenGroupName: null,
      },
    ],
  },
  {
    id: "winback_angel",
    name: "Prospect WinBack Angel",
    active: false,
    thenEmails: [
      {
        id: "1",
        name: "We Miss You",
        active: false,
        triggerDays: 330,
        originalThumbnailUrl: wmy,
        preview: "https://s3.amazonaws.com/m.hy.ly/hyALM/angel-emails/We-Miss-You-min.jpg",
        whenGroupName: null,
      },
      {
        id: "2",
        name: "Neighborhood Amenities",
        active: false,
        triggerDays: 365,
        originalThumbnailUrl: na,
        preview:
          "https://s3.amazonaws.com/m.hy.ly/hyALM/angel-emails/Neighborhood-Amenities-min.jpg",
        whenGroupName: null,
      },
      {
        id: "3",
        name: "Invite to Tour",
        active: false,
        triggerDays: 380,
        originalThumbnailUrl: itt,
        preview: "https://s3.amazonaws.com/m.hy.ly/hyALM/angel-emails/Invite-to-Tour-min.jpg",
        whenGroupName: null,
      },
    ],
  },
];

export const learnLinks = {
  prospect_angel: "https://hy.ly/multifamily-lifecycle-marketing/angels/prospect-marketing/",
  new_resident_angel: "https://hy.ly/multifamily-lifecycle-marketing/angels/new-resident-email/",
  resident_retention_angel:
    "https://hy.ly/multifamily-lifecycle-marketing/angels/resident-retention-strategy/",
  winback_angel: "https://hy.ly/multifamily-lifecycle-marketing/angels/win-back/",
};

export const contactLinks = {
  mail: "mailto:sales@hy.ly",
};

export const utm_code = `utm_medium=email&utm_source=Hyly&utm_campaign=ALM&utm_content={%email_name_encoded%}`;

export const getAngels = (propertyId, angelType) => {
  return new Promise((resolve, reject) => {
    api.getDripsMenu(propertyId, angelType).then((res, err) => {
      if (err || res.errors || res.error) {
        resolve(false);
      } else {
        resolve(res.angels);
      }
    });
  });
};

export const getFinalMenu = async (propertyId, angels) => {
  let menu;
  let [createdMenu, filteredMenu] = await Promise.all([
    createMenu(propertyId, angels),
    getFilteredMenu(propertyId, angels),
  ]);
  if (angels.length > 0) menu = createdMenu.concat(filteredMenu);
  else menu = createdMenu;

  return menu;
};

export const createMenu = async (propertyId, angels) => {
  let createdMenu;
  if (angels.length > 0) createdMenu = await getServerMenu(propertyId, angels);
  else createdMenu = await getLocalMenu(propertyId);

  return createdMenu;
};

export const getServerMenu = (propertyId, angels) => {
  var menu = angels.reduce((result, currentValue) => {
    result.push({
      to: "/" + propertyId + "/drips/" + currentValue.id + "/angels",
      path: "/" + propertyId + "/drips/" + currentValue.id + "/angels",
      title: currentValue.name,
      icon_type: (
        <LayersIcon2
          viewBox="0 0 32 32"
          style={{ width: 32, height: 32, fill: "none", stroke: "#333" }}
        />
      ),
    });
    return result;
  }, []);
  return menu;
};

export const getLocalMenu = (propertyId) => {
  var menu = unpurchasedAngels.reduce((result, currentValue) => {
    result.push({
      to: "/" + propertyId + "/drips/" + currentValue.id + "/angels",
      path: "/" + propertyId + "/drips/" + currentValue.id + "/angels",
      title: currentValue.name,
      icon_type: (
        <LayersIcon2
          viewBox="0 0 32 32"
          style={{ width: 32, height: 32, fill: "none", stroke: "#333" }}
        />
      ),
    });
    return result;
  }, []);
  return menu;
};

export const getFilteredMenu = (propertyId, angels) => {
  let filteredMenu = arrDiff(angels, unpurchasedAngels);
  let menu = [];
  for (let i = 0; i < filteredMenu.length; i++) {
    let angel = unpurchasedAngels.find(
      (e) => e.name.toUpperCase().replace(/ /g, "").replace(/-/g, "") === filteredMenu[i]
    );
    if (angel) {
      let localMenu = {
        to: "/" + propertyId + "/drips/" + angel.id + "/angels",
        path: "/" + propertyId + "/drips/" + angel.id + "/angels",
        title: angel.name,
        icon_type: (
          <LayersIcon2
            viewBox="0 0 32 32"
            style={{ width: 32, height: 32, fill: "none", stroke: "#333" }}
          />
        ),
      };
      menu.push(localMenu);
    }
  }
  return menu;
};

export const getDripsMenu = async (propertyId, angelType) => {
  let angels = await getAngels(propertyId || getPropertyId(), angelType);
  let dripsMenu = await getFinalMenu(propertyId || getPropertyId(), angels);

  return dripsMenu;
};
