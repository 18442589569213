/* eslint-disable react/jsx-filename-extension */
import { CssBaseline, StyledEngineProvider, ThemeProvider } from "@mui/material";
import StylesProvider from "@mui/styles/StylesProvider";
import jssPreset from "@mui/styles/jssPreset";
import "bootstrap/dist/css/bootstrap.min.css";
import { create } from "jss";
import jssTemplate from "jss-plugin-template";
import React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { store } from "redux/store";
import App from "./App";
import "./icons/style.css";
import "./index.css";
import theme from "./theme";
// import "@hyly/global-components/dist/index.cjs.css";

const jss = create({
  plugins: [jssTemplate(), ...jssPreset().plugins],
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Router
            getUserConfirmation={() => {
              /* Empty callback to block the default browser prompt */
            }}
          >
            <StylesProvider jss={jss}>
              <DndProvider backend={HTML5Backend}>
                <App />
              </DndProvider>
            </StylesProvider>
          </Router>
        </ThemeProvider>
      </StyledEngineProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
