/* eslint-disable react/require-default-props */
import React from "react";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";

const useStyles = makeStyles(() => ({
  tooltip: {
    fontSize: 14,
    background: "#333",
    borderRadius: 0,
    padding: "6px 10px",
    maxWidth: 200,
  },
  arrow: {
    color: "#333",
  },
  button: {
    color: "#333",
  },
}));

export default function Tooltips({ position, text, textLimit, children, customClasses }) {
  const classes = useStyles();

  const renderText = () => {
    if (typeof text === "string") {
      return text.length > textLimit ? `${text.substring(0, textLimit)}...` : text;
    }

    return text;
  };

  if (!text) {
    return (
      children || <Button className={classes.button}>hover mouse pointer to see the tooltip</Button>
    );
  }

  return (
    <Tooltip
      title={renderText()}
      placement={position}
      arrow
      classes={{
        tooltip: customClasses ? customClasses.tooltip : classes.tooltip,
        arrow: customClasses ? customClasses.arrow : classes.arrow,
      }}
    >
      {children || (
        <Button className={classes.button}>hover mouse pointer to see the tooltip</Button>
      )}
    </Tooltip>
  );
}

Tooltips.propTypes = {
  position: PropTypes.oneOf(["right", "left", "top", "bottom"]),
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  textLimit: PropTypes.number,
  children: PropTypes.node,
};

Tooltips.defaultProps = {
  position: "bottom",
  text: "Tooltip",
  open: false,
  textLimit: 200,
};
