import graphql from "babel-plugin-relay/macro";

export default graphql`
  query getEnrollmentQuery($enrollmentId: ID!) {
    enrollment(enrollmentId: $enrollmentId) {
      id
      email
      referralLink
    }
  }
`;
