import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  expression: "",
};

const expressionSlice = createSlice({
  name: "expressions",
  initialState,
  reducers: {
    setExpression: (state, action) => {
      state.expression = action.payload;
    },
  },
});

export const getExpression = (state) => state.expressions.expression;

export const { setExpression } = expressionSlice.actions;
export default expressionSlice.reducer;
