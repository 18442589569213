/**
 * @flow
 * @relayHash 11026ff9b37cc1d36a18af98c5bd7657
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type toggleHayleyFeedbackInput = {|
  propertyId: string,
  showFeedbackFilter?: ?boolean,
  clientMutationId?: ?string,
|};
export type toggleHayleyFeedbackMutationVariables = {|
  input: toggleHayleyFeedbackInput
|};
export type toggleHayleyFeedbackMutationResponse = {|
  +toggleHayleyFeedback: ?{|
    +response: ?{|
      +showFeedbackFilter: ?boolean
    |}
  |}
|};
export type toggleHayleyFeedbackMutation = {|
  variables: toggleHayleyFeedbackMutationVariables,
  response: toggleHayleyFeedbackMutationResponse,
|};
*/


/*
mutation toggleHayleyFeedbackMutation(
  $input: toggleHayleyFeedbackInput!
) {
  toggleHayleyFeedback(input: $input) {
    response {
      showFeedbackFilter
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "toggleHayleyFeedbackInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "showFeedbackFilter",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "toggleHayleyFeedbackMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "toggleHayleyFeedback",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "toggleHayleyFeedbackPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "response",
            "storageKey": null,
            "args": null,
            "concreteType": "Hayley",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "toggleHayleyFeedbackMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "toggleHayleyFeedback",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "toggleHayleyFeedbackPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "response",
            "storageKey": null,
            "args": null,
            "concreteType": "Hayley",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "toggleHayleyFeedbackMutation",
    "id": null,
    "text": "mutation toggleHayleyFeedbackMutation(\n  $input: toggleHayleyFeedbackInput!\n) {\n  toggleHayleyFeedback(input: $input) {\n    response {\n      showFeedbackFilter\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'dc7fbafa00507abb868ce2fed5cefc2a';
module.exports = node;
