import graphql from "babel-plugin-relay/macro";

export default graphql`
  query getOrgOverviewQuery($orgId:ID,$propertyIds: [String!],$campaignIds: [String!],$startAt: String,$endAt: String,$concession: [String!]){
    orgOverview(orgId:$orgId,propertyIds:$propertyIds,campaignIds:$campaignIds,startAt:$startAt,endAt:$endAt,concession:$concession){
        clicked
        id
        leased
        leasedCount
        topOfferId
        totalCampaigns
        totalOffers
        toured
        touredCount
    }
  }

`