import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { rangeShape } from "../DayCell";
import Month from "../Month";
import DateInput from "../DateInput";
import { calcFocusDate, generateStyles, getMonthDisplayRange } from "../../utils";
import classnames from "classnames";
import ReactList from "react-list";
import { shallowEqualObjects } from "shallow-equal";
import {
  addMonths,
  format,
  eachDayOfInterval,
  startOfWeek,
  endOfWeek,
  isSameDay,
  addYears,
  setYear,
  setMonth,
  differenceInCalendarMonths,
  startOfMonth,
  endOfMonth,
  addDays,
  isSameMonth,
  differenceInDays,
  min,
  max,
} from "date-fns";
import defaultLocale from "date-fns/locale/en-US";
import coreStyles from "../../styles";
import { ariaLabelsShape } from "../../accessibility";

class Calendar extends PureComponent {
  constructor(props, context) {
    super(props, context);
    this.dateOptions = { locale: props.locale };
    this.dateOptions.weekStartsOn = 1;
    this.styles = generateStyles([coreStyles, props.classNames]);
    this.listSizeCache = {};
    this.isFirstRender = true;
    this.state = {
      yearView: false,
      monthView: false,
      monthNames: this.getMonthNames(),
      year: parseInt(new Date().getFullYear()),
      focusedDate: calcFocusDate(null, props),
      drag: {
        status: false,
        range: { startDate: null, endDate: null },
        disablePreview: false,
      },
      scrollArea: this.calcScrollArea(props),
    };
  }
  getMonthNames() {
    return [...Array(12).keys()].map((i) => this.props.locale.localize.month(i));
  }

  calcScrollArea(props) {
    const { direction, months, scroll } = props;
    if (!scroll.enabled) return { enabled: false };

    const longMonthHeight = scroll.longMonthHeight || scroll.monthHeight;
    if (direction === "vertical") {
      return {
        enabled: true,
        monthHeight: scroll.monthHeight || 220,
        longMonthHeight: longMonthHeight || 260,
        calendarWidth: "auto",
        calendarHeight: (scroll.calendarHeight || longMonthHeight || 240) * months,
      };
    }
    return {
      enabled: true,
      monthWidth: scroll.monthWidth || 332,
      calendarWidth: (scroll.calendarWidth || scroll.monthWidth || 332) * months,
      monthHeight: longMonthHeight || 300,
      calendarHeight: longMonthHeight || 300,
    };
  }
  focusToDate = (date, props = this.props, preventUnnecessary = true) => {
    if (!props.scroll.enabled) {
      this.setState({ focusedDate: date });
      return;
    }
    const targetMonthIndex = differenceInCalendarMonths(date, props.minDate, this.dateOptions);
    const visibleMonths = this.list.getVisibleRange();
    if (preventUnnecessary && visibleMonths.includes(targetMonthIndex)) return;
    this.isFirstRender = true;
    this.list.scrollTo(targetMonthIndex);
    this.setState({ focusedDate: date });
  };
  updateShownDate = (props = this.props) => {
    const newProps = props.scroll.enabled
      ? {
          ...props,
          months: this.list.getVisibleRange().length,
        }
      : props;
    const newFocus = calcFocusDate(this.state.focusedDate, newProps);
    this.focusToDate(newFocus, newProps);
  };
  updatePreview = (val) => {
    if (!val) {
      this.setState({ preview: null });
      return;
    }
    const preview = {
      startDate: val,
      endDate: val,
      color: this.props.color,
    };
    this.setState({ preview });
  };
  componentDidMount() {
    if (this.props.scroll.enabled) {
      // prevent react-list's initial render focus problem
      setTimeout(() => this.focusToDate(this.state.focusedDate));
    }
  }

  componentDidUpdate(prevProps) {
    const propMapper = {
      dateRange: "ranges",
      date: "date",
    };
    const targetProp = propMapper[this.props.displayMode];
    if (this.props[targetProp] !== prevProps[targetProp]) {
      this.updateShownDate(this.props);
    }

    if (
      prevProps.locale !== this.props.locale ||
      prevProps.weekStartsOn !== this.props.weekStartsOn
    ) {
      this.dateOptions = { locale: this.props.locale };
      if (this.props.weekStartsOn !== undefined)
        this.dateOptions.weekStartsOn = this.props.weekStartsOn;
      this.setState({
        monthNames: this.getMonthNames(),
      });
    }

    if (!shallowEqualObjects(prevProps.scroll, this.props.scroll)) {
      this.setState({ scrollArea: this.calcScrollArea(this.props) });
    }
  }

  changeShownDate = (value, mode = "set") => {
    const { focusedDate } = this.state;
    const { onShownDateChange, minDate, maxDate } = this.props;
    if (mode === "goToToday") {
      const newDate = new Date();
      this.focusToDate(newDate, this.props, true);
      onShownDateChange && onShownDateChange(newDate);
    } else {
      const modeMapper = {
        monthOffset: () => addMonths(focusedDate, value),
        setMonth: () => setMonth(focusedDate, value),
        setYear: () => setYear(focusedDate, value),
        set: () => value,
      };
      const newDate = min([max([modeMapper[mode](), minDate]), maxDate]);
      this.focusToDate(newDate, this.props, false);
      onShownDateChange && onShownDateChange(newDate);
    }
  };
  handleRangeFocusChange = (rangesIndex, rangeItemIndex) => {
    this.props.onRangeFocusChange && this.props.onRangeFocusChange([rangesIndex, rangeItemIndex]);
  };
  handleScroll = () => {
    const { onShownDateChange, minDate } = this.props;
    const { focusedDate } = this.state;
    const { isFirstRender } = this;

    const visibleMonths = this.list.getVisibleRange();
    // prevent scroll jump with wrong visible value
    if (visibleMonths[0] === undefined) return;
    const visibleMonth = addMonths(minDate, visibleMonths[0] || 0);
    const isFocusedToDifferent = !isSameMonth(visibleMonth, focusedDate);
    if (isFocusedToDifferent && !isFirstRender) {
      this.setState({ focusedDate: visibleMonth });
      onShownDateChange && onShownDateChange(visibleMonth);
    }
    this.isFirstRender = false;
  };

  onClickToday = () => {
    this.setState({ monthView: false, yearView: false });
    this.changeShownDate("", "goToToday");
  };

  showMonthsView = () => {
    this.setState({ monthView: true, yearView: false });
  };
  showYearsView = () => {
    this.setState({ yearView: true, monthView: false });
  };

  onPrevClick = () => {
    if (this.state.monthView) {
      return;
    }
    if (this.state.yearView) {
      this.setState({ year: this.state.year - 10 });
    } else {
      this.changeShownDate(-1, "monthOffset");
    }
  };

  onNextClick = () => {
    if (this.state.monthView) {
      return;
    }
    if (this.state.yearView) {
      this.setState({ year: this.state.year + 10 });
    } else {
      this.changeShownDate(+1, "monthOffset");
    }
  };
  renderMonthAndYear = (focusedDate, changeShownDate, props) => {
    const { showMonthArrow, minDate, maxDate, showMonthAndYearPickers } = props;
    const styles = this.styles;
    return (
      <div onMouseUp={(e) => e.stopPropagation()} className={styles.monthAndYearWrapper}>
        {showMonthAndYearPickers ? (
          <span className={styles.monthAndYearPickers}>
            <span onClick={this.showMonthsView} className={styles.monthPicker}>
              {this.state.monthNames[focusedDate.getMonth()]}
              <span className="iconMonthCSS" />
            </span>
            <span className={styles.monthAndYearDivider} />
            <span onClick={this.showYearsView} className={styles.yearPicker}>
              {focusedDate.getFullYear()}
              <span className="iconMonthCSS" />
            </span>
          </span>
        ) : (
          <span className={styles.monthAndYearPickers}>
            {this.state.monthNames[focusedDate.getMonth()]} {focusedDate.getFullYear()}
          </span>
        )}
        {showMonthArrow ? (
          <>
            <span onClick={this.onPrevClick} className="prevButtonCSS" />
            <div className="todayDiv" onClick={this.onClickToday}></div>
            <span onClick={this.onNextClick} class="nextButtonCSS" />
          </>
        ) : null}
      </div>
    );
  };
  renderWeekdays() {
    const now = new Date();
    return (
      <div className={this.styles.weekDays}>
        {eachDayOfInterval({
          start: startOfWeek(now, this.dateOptions),
          end: endOfWeek(now, this.dateOptions),
        }).map((day, i) => (
          <span className={this.styles.weekDay} key={i}>
            {format(day, this.props.weekdayDisplayFormat, this.dateOptions)}
          </span>
        ))}
      </div>
    );
  }

  renderDateDisplay = () => {
    const {
      focusedRange,
      color,
      ranges,
      rangeColors,
      dateDisplayFormat,
      editableDateInputs,
      startDatePlaceholder,
      endDatePlaceholder,
      ariaLabels,
    } = this.props;

    const defaultColor = rangeColors[focusedRange[0]] || color;
    const styles = this.styles;

    return (
      <div className={styles.dateDisplayWrapper}>
        {ranges.map((range, i) => {
          if (range.showDateDisplay === false || (range.disabled && !range.showDateDisplay))
            return null;
          return (
            <div
              className={styles.dateDisplay}
              key={i}
              style={{ color: range.color || defaultColor }}>
              <DateInput
                className={classnames(styles.dateDisplayItem, {
                  [styles.dateDisplayItemActive]: focusedRange[0] === i && focusedRange[1] === 0,
                })}
                label="Start Date"
                readOnly={!editableDateInputs}
                disabled={range.disabled}
                value={range.startDate}
                placeholder={startDatePlaceholder}
                dateOptions={this.dateOptions}
                dateDisplayFormat={dateDisplayFormat}
                ariaLabel={
                  ariaLabels.dateInput &&
                  ariaLabels.dateInput[range.key] &&
                  ariaLabels.dateInput[range.key].startDate
                }
                onChange={this.onDragSelectionEnd}
                onFocus={() => this.handleRangeFocusChange(i, 0)}
              />
              <DateInput
                className={classnames(styles.dateDisplayItem, {
                  [styles.dateDisplayItemActive]: focusedRange[0] === i && focusedRange[1] === 1,
                })}
                label="End Date"
                readOnly={!editableDateInputs}
                disabled={range.disabled}
                value={range.endDate}
                placeholder={endDatePlaceholder}
                dateOptions={this.dateOptions}
                dateDisplayFormat={dateDisplayFormat}
                ariaLabel={
                  ariaLabels.dateInput &&
                  ariaLabels.dateInput[range.key] &&
                  ariaLabels.dateInput[range.key].endDate
                }
                onChange={this.onDragSelectionEnd}
                onFocus={() => this.handleRangeFocusChange(i, 1)}
              />
            </div>
          );
        })}
      </div>
    );
  };
  onDragSelectionStart = (date) => {
    const { onChange, dragSelectionEnabled } = this.props;

    if (dragSelectionEnabled) {
      this.setState({
        drag: {
          status: true,
          range: { startDate: date, endDate: date },
          disablePreview: true,
        },
      });
    } else {
      onChange && onChange(date);
    }
  };

  onDragSelectionEnd = (date) => {
    const { updateRange, displayMode, onChange, dragSelectionEnabled } = this.props;

    if (!dragSelectionEnabled) return;

    if (displayMode === "date" || !this.state.drag.status) {
      onChange && onChange(date);
      return;
    }
    const newRange = {
      startDate: this.state.drag.range.startDate,
      endDate: date,
    };
    if (displayMode !== "dateRange" || isSameDay(newRange.startDate, date)) {
      this.setState({ drag: { status: false, range: {} } }, () => onChange && onChange(date));
    } else {
      this.setState({ drag: { status: false, range: {} } }, () => {
        updateRange && updateRange(newRange);
      });
    }
  };
  onDragSelectionMove = (date) => {
    const { drag } = this.state;
    if (!drag.status || !this.props.dragSelectionEnabled) return;
    this.setState({
      drag: {
        status: drag.status,
        range: { startDate: drag.range.startDate, endDate: date },
        disablePreview: true,
      },
    });
  };

  estimateMonthSize = (index, cache) => {
    const { direction, minDate } = this.props;
    const { scrollArea } = this.state;
    if (cache) {
      this.listSizeCache = cache;
      if (cache[index]) return cache[index];
    }
    if (direction === "horizontal") return scrollArea.monthWidth;
    const monthStep = addMonths(minDate, index);
    const { start, end } = getMonthDisplayRange(monthStep, this.dateOptions);
    const isLongMonth = differenceInDays(end, start, this.dateOptions) + 1 > 7 * 5;
    return isLongMonth ? scrollArea.longMonthHeight : scrollArea.monthHeight;
  };

  onMonthClick = (month) => {
    this.changeShownDate(month, "setMonth");
    this.setState({ monthView: false });
  };

  renderMonths = () => {
    return (
      <div className="yearContainer">
        {this.state.monthNames.map((monthName, i) => (
          <div key={i} className="yearView" onClick={() => this.onMonthClick(i)}>
            {monthName.slice(0, 3)}
          </div>
        ))}
      </div>
    );
  };

  onYearClick = (year) => {
    this.changeShownDate(year, "setYear");
    this.setState({ yearView: false });
  };

  renderYears = () => {
    const { year } = this.state;
    const yearsArray = [];
    for (let i = year; i <= year + 11; i++) {
      yearsArray.push(i);
    }
    return (
      <div className="yearContainer">
        {yearsArray.map((year) => (
          <div key={year} className="yearView" onClick={() => this.onYearClick(year)}>
            {year}
          </div>
        ))}
      </div>
    );
  };
  render() {
    const {
      showDateDisplay,
      onPreviewChange,
      scroll,
      direction,
      disabledDates,
      disabledDay,
      maxDate,
      minDate,
      rangeColors,
      color,
      navigatorRenderer,
      className,
      preview,
    } = this.props;
    const { scrollArea, focusedDate, yearView, monthView } = this.state;
    const isVertical = direction === "vertical";
    const monthAndYearRenderer = navigatorRenderer || this.renderMonthAndYear;

    const ranges = this.props.ranges.map((range, i) => ({
      ...range,
      color: range.color || rangeColors[i] || color,
    }));
    return (
      <div
        className={classnames(this.styles.calendarWrapper, className)}
        onMouseUp={() => this.setState({ drag: { status: false, range: {} } })}
        onMouseLeave={() => {
          this.setState({ drag: { status: false, range: {} } });
        }}>
        {showDateDisplay && this.renderDateDisplay()}
        {monthAndYearRenderer(focusedDate, this.changeShownDate, this.props)}
        {yearView ? (
          this.renderYears()
        ) : monthView ? (
          this.renderMonths()
        ) : (
          <div
            className={classnames(
              this.styles.months,
              isVertical ? this.styles.monthsVertical : this.styles.monthsHorizontal
            )}>
            {new Array(this.props.months).fill(null).map((_, i) => {
              const monthStep = addMonths(this.state.focusedDate, i);
              return (
                <Month
                  {...this.props}
                  onPreviewChange={onPreviewChange || this.updatePreview}
                  preview={preview || this.state.preview}
                  ranges={ranges}
                  key={i}
                  drag={this.state.drag}
                  dateOptions={this.dateOptions}
                  disabledDates={disabledDates}
                  disabledDay={disabledDay}
                  month={monthStep}
                  onDragSelectionStart={this.onDragSelectionStart}
                  onDragSelectionEnd={this.onDragSelectionEnd}
                  onDragSelectionMove={this.onDragSelectionMove}
                  onMouseLeave={() => onPreviewChange && onPreviewChange()}
                  styles={this.styles}
                  showWeekDays={!isVertical || i === 0}
                  showMonthName={!isVertical || i > 0}
                />
              );
            })}
          </div>
        )}
      </div>
    );
  }
}

Calendar.defaultProps = {
  showMonthArrow: true,
  showMonthAndYearPickers: true,
  disabledDates: [],
  disabledDay: () => {},
  classNames: {},
  locale: defaultLocale,
  ranges: [],
  focusedRange: [0, 0],
  dateDisplayFormat: "d MMM yyyy",
  monthDisplayFormat: "MMM yyyy",
  weekdayDisplayFormat: "E",
  dayDisplayFormat: "d",
  showDateDisplay: true,
  showPreview: true,
  displayMode: "date",
  months: 1,
  color: "#3d91ff",
  scroll: {
    enabled: false,
  },
  direction: "vertical",
  maxDate: addYears(new Date(), 1000),
  minDate: addYears(new Date(), -1000),
  rangeColors: ["#3d91ff", "#3ecf8e", "#fed14c"],
  startDatePlaceholder: "--",
  endDatePlaceholder: "--",
  editableDateInputs: false,
  dragSelectionEnabled: true,
  fixedHeight: false,
  ariaLabels: {},
};

Calendar.propTypes = {
  showMonthArrow: PropTypes.bool,
  showMonthAndYearPickers: PropTypes.bool,
  disabledDates: PropTypes.array,
  disabledDay: PropTypes.func,
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
  date: PropTypes.object,
  onChange: PropTypes.func,
  onPreviewChange: PropTypes.func,
  onRangeFocusChange: PropTypes.func,
  classNames: PropTypes.object,
  locale: PropTypes.object,
  shownDate: PropTypes.object,
  onShownDateChange: PropTypes.func,
  ranges: PropTypes.arrayOf(rangeShape),
  preview: PropTypes.shape({
    startDate: PropTypes.object,
    endDate: PropTypes.object,
    color: PropTypes.string,
  }),
  dateDisplayFormat: PropTypes.string,
  monthDisplayFormat: PropTypes.string,
  weekdayDisplayFormat: PropTypes.string,
  weekStartsOn: PropTypes.number,
  dayDisplayFormat: PropTypes.string,
  focusedRange: PropTypes.arrayOf(PropTypes.number),
  initialFocusedRange: PropTypes.arrayOf(PropTypes.number),
  months: PropTypes.number,
  className: PropTypes.string,
  showDateDisplay: PropTypes.bool,
  showPreview: PropTypes.bool,
  displayMode: PropTypes.oneOf(["dateRange", "date"]),
  color: PropTypes.string,
  updateRange: PropTypes.func,
  scroll: PropTypes.shape({
    enabled: PropTypes.bool,
    monthHeight: PropTypes.number,
    longMonthHeight: PropTypes.number,
    monthWidth: PropTypes.number,
    calendarWidth: PropTypes.number,
    calendarHeight: PropTypes.number,
  }),
  direction: PropTypes.oneOf(["vertical", "horizontal"]),
  startDatePlaceholder: PropTypes.string,
  endDatePlaceholder: PropTypes.string,
  navigatorRenderer: PropTypes.func,
  rangeColors: PropTypes.arrayOf(PropTypes.string),
  editableDateInputs: PropTypes.bool,
  dragSelectionEnabled: PropTypes.bool,
  fixedHeight: PropTypes.bool,
  ariaLabels: ariaLabelsShape,
};

export default Calendar;
