/* eslint-disable react/jsx-wrap-multilines */
import React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import Button from "../Button";
import { useSnackbar } from "notistack";

import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > * + *": {
      marginTop: theme.spacing(2),
      position: "inherit",
    },
  },
  snackbar: {
    width: "650px",
    position: "relative",
  },
  Success: {
    width: "100%",
    backgroundColor: "#66D184",
    borderRadius: 0,
    fontWeight: 600,
    fontSize: 14,
    lineHeight: "20px",
    color: "#FFFFFF",
  },
  Warning: {
    width: "100%",

    backgroundColor: "#EDB526",
    borderRadius: 0,
    fontWeight: 600,
    fontSize: 14,
    lineHeight: "20px",
    color: "#FFFFFF",
  },
  Errors: {
    width: "100%",

    backgroundColor: "#E75F5F",
    borderRadius: 0,
    fontWeight: 600,
    fontSize: 14,
    lineHeight: "20px",
    color: "#FFFFFF",
  },
  undo: {
    color: "white",
    "&:hover": {
      color: "white !important",
      background: "rgba(0, 0, 0, 0.04) !important",
    },
  },
}));

const ToastMessage = ({
  message,
  variant = "success",
  open,
  onCloseCallback,
  onForceCloseCallback,
  autoHideDuration,
  unDoCallback,
  unDoAvailable,
  id,
}) => {
  const classes = useStyles();
  const { closeSnackbar } = useSnackbar();

  return (
    <div className={classes.root}>
      <Snackbar
        open={open}
        autoHideDuration={autoHideDuration || 1000}
        onClose={onCloseCallback}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        className={classes.snackbar}
      >
        <MuiAlert
          elevation={0}
          variant="filled"
          onClose={onCloseCallback}
          severity={variant}
          icon={false}
          action={
            <React.Fragment>
              {unDoAvailable && (
                <Button
                  className={classes.undo}
                  size="sm"
                  variant="tertiary"
                  onClick={() => {
                    unDoCallback();
                    closeSnackbar(id);
                  }}
                >
                  UNDO
                </Button>
              )}
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={closeSnackbar.bind(this, id)}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
          classes={{
            filledSuccess: classes.Success,
            filledWarning: classes.Warning,
            filledError: classes.Errors,
          }}
        >
          {message}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

ToastMessage.propTypes = {
  message: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(["success", "warning", "error"]).isRequired,
  open: PropTypes.bool,
  /** CAllback required to close the popup . it changes open state*/
  onCloseCallback: PropTypes.func,
  unDoCallback: PropTypes.func,
  unDoAvailable: PropTypes.bool,
};

ToastMessage.defaultProps = {
  message: "",
  variant: "success",
  open: false,
  onCloseCallback: undefined,
  unDoCallback: undefined,
  unDoAvailable: false,
};
export default ToastMessage;
