import React from "react";
import withStyles from "@mui/styles/withStyles";
import Button from "storybookComponents/Button";
import Modal from "components/Modal";
import Field from "components/InputField/CommonTextField";
import saveReport from "relay/offers/mutations/saveReport";
import { getErrorMessage, checkUncatchableError } from "environments/uncatchableErrorStore";
import environment from "environments/environment";

const styles = () => ({
  reportHeader: {
    fontFamily: "Open Sans",
    fontSize: 20,
    lineHeight: "30px",
  },
  dialogRoot: {
    width: 500,
    background: "white",
  },
  reportText: {
    fontFamily: "Open Sans",
    fontSize: 16,
    lineHeight: "24px",
    marginBottom: 12,
  },
  bold: {
    fontWeight: 600,
  },
  button: {
    height: 40,
    padding: "10px 24px",
    fontSize: 14,
    lineHeight: "20px",
    fontWeight: 600,
    marginLeft: 12,
    textTransform: "none",
  },
  label: {
    fontSize: 12,
    color: "#333333",
    opacity: 0.7,
    lineHeight: "24px",
  },
});

const SaveReport = ({
  classes,
  handleToggle,
  open,
  reportData,
  showNotification,
  subOrgDetails,
  duplicate,
}) => {
  const [active, setActive] = React.useState("active");
  const [value, setValue] = React.useState();

  const handleSaveOffer = async () => {
    const payload = {
      orgOrPropertyId: subOrgDetails.id,
      params: reportData.params,
      tabName: reportData.tabName,
      type: reportData.type,
      name: value,
    };
    try {
      setActive("inactive");
      const res = await saveReport(environment, {
        saveReport: payload,
      });
      if (!res.saveReport) {
        const uncachebleError = getErrorMessage(checkUncatchableError("saveReportMutation"));
        showNotification(uncachebleError || "Something went wrong.", "error");
      } else {
        showNotification("Report is saved successfully.");
      }
    } catch (err) {
      console.log("err", err);
      const uncachebleError = getErrorMessage(checkUncatchableError("saveReportMutation"));
      showNotification(uncachebleError || "Something went wrong.", "error");
    }
    setActive("active");
    handleToggle();
  };

  const handleChangeValue = (e) => {
    setValue(e.target.value);
  };

  return (
    <Modal
      open={open}
      title={
        <div className={classes.reportHeader}>{duplicate ? "Duplicate Report" : "Save Report"}</div>
      }
      handleClose={handleToggle}
      className={{
        dialogRoot: classes.dialogRoot,
      }}
      actionChildren={
        <>
          <Button onClick={handleToggle} variant="tertiary" className={classes.button}>
            Cancel
          </Button>
          <Button
            state={!value ? "inactive" : active}
            className={classes.button}
            onClick={handleSaveOffer}
          >
            Save
          </Button>
        </>
      }
    >
      <div className={classes.reportText}>
        {duplicate ? "A copy of this Report will be created in " : "This Report will be saved in "}
        <span className={classes.bold}>My Analytics</span>
      </div>
      <div className={classes.label}>Report Name</div>
      <Field
        variant="outlined"
        onChange={handleChangeValue}
        fullWidth
        placeholder={`${reportData.tabName} (${reportData.month})`}
      />
    </Modal>
  );
};

export default withStyles(styles)(SaveReport);
