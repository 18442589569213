import moment from "moment";

const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
const ranges = {
  "This Month": [moment().startOf("month").unix(), moment().endOf("month").unix()],
  "Last 30 Days": [moment().subtract(30, "day").unix(), moment().unix()],
  "Last Month": [
    moment().subtract(1, "month").startOf("month").unix(),
    moment().subtract(1, "month").endOf("month").unix(),
  ],
  "Last 3 Months": [
    moment().subtract(3, "month").startOf("month").unix(),
    moment().subtract(1, "month").endOf("month").unix(),
  ],
  "Last 6 Months": [
    moment().subtract(6, "month").startOf("month").unix(),
    moment().subtract(1, "month").endOf("month").unix(),
  ],
  "This Year": [moment().startOf("year").unix(), moment().endOf("year").unix()],
  "Last Year": [
    moment().subtract(1, "year").startOf("year").unix(),
    moment().subtract(1, "year").endOf("year").unix(),
  ],
};

const getLabelFromDate = (start_date, end_date) => {
  let object = null;

  for (const label in ranges) {
    const range = ranges[label];
    if (range[0] < moment(start_date).unix() && moment(end_date).unix() < range[1]) {
      object = {
        startAt: moment.unix(range[0]),
        endAt: moment.unix(range[1]),
        label: label,
      };
      break;
    }
  }

  if (!object && moment(start_date).unix() > moment().unix()) {
    object = {
      startAt: moment(start_date),
      endAt: moment(end_date),
      label: "Custom Range",
    };
  }
  return object;
};

const getDate = (value) => {
  let d = new Date(value);
  return d.getDate() + " " + monthNames[d.getMonth()] + " " + d.getFullYear();
};

export const getRangeLabel = (startDate, endDate) => {
  return moment(startDate).format("D MMM YYYY") + " - " + moment(endDate).format("D MMM YYYY");
};

export const getMonthRange = (label) => {
  let startDate;
  let endDate;
  if (label === "Last 7 Days") {
    startDate = moment().subtract(7, "day").toDate();
    endDate = moment().toDate();
  }
  if (label === "Last Week") {
    startDate = moment().subtract(1, "weeks").startOf("isoWeek");
    endDate = moment().subtract(1, "weeks").endOf("isoWeek");
  }
  if (label === "This Month") {
    startDate = moment().startOf("month").toDate();
    endDate = moment().endOf("month").toDate();
  }
  if (label === "Last Month") {
    startDate = moment().subtract(1, "month").startOf("month").toDate();
    endDate = moment().subtract(1, "month").endOf("month").toDate();
  }
  if (label === "Last 30 Days") {
    startDate = moment().subtract(30, "day").toDate();
    endDate = moment().toDate();
  }
  if (label === "Last 3 Months") {
    startDate = moment().subtract(3, "month").startOf("month").toDate();
    endDate = moment().subtract(1, "month").endOf("month").toDate();
  }
  if (label === "Last 6 Months") {
    startDate = moment().subtract(6, "month").startOf("month").toDate();
    endDate = moment().subtract(1, "month").endOf("month").toDate();
  }
  if (label === "This Year") {
    startDate = moment().startOf("year").toDate();
    endDate = moment().endOf("year").toDate();
  }
  if (label === "Last Year") {
    startDate = moment().subtract(1, "year").startOf("year").toDate();
    endDate = moment().subtract(1, "year").endOf("year").toDate();
  }

  return {
    startAt: startDate,
    endAt: endDate,
    label: label,
  };
};

export { getDate, getLabelFromDate };
