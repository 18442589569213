/**
 * @flow
 * @relayHash 9df7449aa3fc1a7da897d031ade27688
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getCampaignsQueryVariables = {|
  orgId: string,
  propertyId?: ?string,
  search?: ?string,
  after?: ?string,
  first?: ?number,
  status?: ?string,
|};
export type getCampaignsQueryResponse = {|
  +hyreferralCampaigns: {|
    +pageInfo: {|
      +endCursor: ?string,
      +startCursor: ?string,
      +hasPreviousPage: boolean,
      +hasNextPage: boolean,
    |},
    +edges: ?$ReadOnlyArray<?{|
      +cursor: string,
      +node: ?{|
        +createdAt: any,
        +id: string,
        +propertyId: string,
        +propertyName: ?string,
        +title: string,
        +reward: ?number,
        +active: boolean,
        +friendsCount: ?string,
        +leasedCount: ?string,
        +residentsCount: ?string,
        +facebookShareUrl: ?string,
      |},
    |}>,
  |}
|};
export type getCampaignsQuery = {|
  variables: getCampaignsQueryVariables,
  response: getCampaignsQueryResponse,
|};
*/


/*
query getCampaignsQuery(
  $orgId: ID!
  $propertyId: ID
  $search: String
  $after: String
  $first: Int
  $status: String
) {
  hyreferralCampaigns(orgId: $orgId, propertyId: $propertyId, search: $search, after: $after, first: $first, status: $status) {
    pageInfo {
      endCursor
      startCursor
      hasPreviousPage
      hasNextPage
    }
    edges {
      cursor
      node {
        createdAt
        id
        propertyId
        propertyName
        title
        reward
        active
        friendsCount
        leasedCount
        residentsCount
        facebookShareUrl
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "orgId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "propertyId",
    "type": "ID",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "search",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "after",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "status",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "hyreferralCampaigns",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "after",
        "variableName": "after"
      },
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
      },
      {
        "kind": "Variable",
        "name": "orgId",
        "variableName": "orgId"
      },
      {
        "kind": "Variable",
        "name": "propertyId",
        "variableName": "propertyId"
      },
      {
        "kind": "Variable",
        "name": "search",
        "variableName": "search"
      },
      {
        "kind": "Variable",
        "name": "status",
        "variableName": "status"
      }
    ],
    "concreteType": "HyreferralCampaignConnection",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "pageInfo",
        "storageKey": null,
        "args": null,
        "concreteType": "PageInfo",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "endCursor",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "startCursor",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "hasPreviousPage",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "hasNextPage",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "edges",
        "storageKey": null,
        "args": null,
        "concreteType": "HyreferralCampaignEdge",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "cursor",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "HyreferralCampaign",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "createdAt",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "propertyId",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "propertyName",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "title",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "reward",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "active",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "friendsCount",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "leasedCount",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "residentsCount",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "facebookShareUrl",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getCampaignsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "getCampaignsQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "getCampaignsQuery",
    "id": null,
    "text": "query getCampaignsQuery(\n  $orgId: ID!\n  $propertyId: ID\n  $search: String\n  $after: String\n  $first: Int\n  $status: String\n) {\n  hyreferralCampaigns(orgId: $orgId, propertyId: $propertyId, search: $search, after: $after, first: $first, status: $status) {\n    pageInfo {\n      endCursor\n      startCursor\n      hasPreviousPage\n      hasNextPage\n    }\n    edges {\n      cursor\n      node {\n        createdAt\n        id\n        propertyId\n        propertyName\n        title\n        reward\n        active\n        friendsCount\n        leasedCount\n        residentsCount\n        facebookShareUrl\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '08e7127044dc4c1d591f1f70a70457b6';
module.exports = node;
