import graphql from "babel-plugin-relay/macro";

export default graphql`
  query retrieveFaqItemQuery($propertyId: String!, $questionId: String!, $itemType: String!) {
    retrieveFaqItem(propertyId: $propertyId, questionId: $questionId, itemType: $itemType) {
      id
      summary
      faqItems {
        id
        itemTitle
        image {
          fileUrl
          id
        }
        imageId
        itemDescription
        distance
        editable
        selected
        questionId
        preDefined
        section {
          id
          sectionName
          sectionType
        }
      }
      section {
        sectionType
        sectionName
        id
      }
    }
  }
`;
