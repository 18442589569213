/**
 * @flow
 * @relayHash 5f2678c7ce6f23135a8960ac071ed820
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getOfferEmailQueryVariables = {|
  id: string,
  propertyId?: ?string,
  offerSettingId?: ?string,
|};
export type getOfferEmailQueryResponse = {|
  +offer: {|
    +prospectInviteEmailCampaignHtml: string,
    +emailSubject: ?string,
    +prospectInviteEmailSubject: string,
  |}
|};
export type getOfferEmailQuery = {|
  variables: getOfferEmailQueryVariables,
  response: getOfferEmailQueryResponse,
|};
*/


/*
query getOfferEmailQuery(
  $id: ID!
  $propertyId: ID
  $offerSettingId: ID
) {
  offer(id: $id, propertyId: $propertyId, offerSettingId: $offerSettingId) {
    prospectInviteEmailCampaignHtml
    emailSubject
    prospectInviteEmailSubject
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "propertyId",
    "type": "ID",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "offerSettingId",
    "type": "ID",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  },
  {
    "kind": "Variable",
    "name": "offerSettingId",
    "variableName": "offerSettingId"
  },
  {
    "kind": "Variable",
    "name": "propertyId",
    "variableName": "propertyId"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "prospectInviteEmailCampaignHtml",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "emailSubject",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "prospectInviteEmailSubject",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getOfferEmailQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "offer",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "Offer",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "getOfferEmailQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "offer",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "Offer",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "getOfferEmailQuery",
    "id": null,
    "text": "query getOfferEmailQuery(\n  $id: ID!\n  $propertyId: ID\n  $offerSettingId: ID\n) {\n  offer(id: $id, propertyId: $propertyId, offerSettingId: $offerSettingId) {\n    prospectInviteEmailCampaignHtml\n    emailSubject\n    prospectInviteEmailSubject\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e4bdc5e3c0eb02bc6b5b392fc4923eec';
module.exports = node;
