import React, { useRef, useState, cloneElement, Children } from "react";
import Tooltip from "storybookComponents/Tooltip2";

const TooltipIfEllipse = ({ text, position = "top", children, showTooltip }) => {
  const childrenRef = useRef(null);
  const [show, setShow] = useState(false);

  if (!children || Children.count(children) !== 1) {
    return null;
  }

  const childElement = Children.only(children);

  const handleRef = (ref) => {
    childrenRef.current = ref;
    if (ref) {
      setShow(ref.scrollWidth > ref.offsetWidth || showTooltip);
    }
  };

  return (
    <Tooltip text={show ? text : ""} position={position}>
      {cloneElement(childElement, { ref: handleRef })}
    </Tooltip>
  );
};

export default TooltipIfEllipse;
