import graphql from "babel-plugin-relay/macro";

export default graphql`
  query initialValuesQueryQuery($name: String, $first: Int, $appName: String) {
    authorizedOrgs(name: $name, first: $first, appName: $appName) {
      id
      name
      regions {
        id
        name
      }
      suborgs {
        id
        name
        region
        regionId
      }
    }
  }
`;
