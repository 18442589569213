import React, { Fragment } from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import Typography from "../Typography";
import { RefreshIcon } from "storybookComponents/CustomContactGroup/Icons/svgIcon";
import Tooltip from "storybookComponents/Tooltip2";
import Button from "storybookComponents/Button";
import Icon from "storybookComponents/Icon";
import { getPropertyId } from "common";

import { HylyCheckbox, HylyRadio } from "../dropDown/icons";

const SelectorsGroup = withStyles((theme) => ({
  "selector-radio": {
    padding: 0,
    margin: `4px 0 4px -10px`,
    minWidth: 300,
  },
  "selector-checkbox": {
    padding: 0,
    margin: `8px 0 8px -10px`,
    minWidth: 300,
  },
  label: {
    marginBottom: "12px",
    borderBottom: "none",
    //  color: theme.palette.darkBlack,
    "&$labe_focused": {
      //    color: theme.palette.darkBlack,
    },
  },
  labe_focused: {},
  control: {
    fontSize: "16px !important",
    padding: theme.spacing_unit.xs,
  },
  contacts: {
    display: "flex",
    marginLeft: "20px",
  },
  showContacts: {
    display: "block",
  },
  hideContacts: {
    display: "none",
  },
  backgroundStyle: {
    background: "#F9F9F9",
    marginLeft: 8,
    height: 26,
    minWidth: 27,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 12,
    lineHeight: "18px",
    textAlign: "center",
    color: "#000",
  },
  flexBoxLabel: {
    display: "flex",
    alignItems: "center",
  },
  refreshIcon: {
    marginLeft: "10px",
    "&:hover path": {
      stroke: "var(--hylyTheme)",
    },
  },
  brokerButton: {
    marginTop: 16,
    height: 32,
    width: 177,
    display: "flex",
    alignItems: "center",
    textTransform: "none",
    padding: "6px 12px 6px 16px",
    fontSize: 14,
    fontWeight: "400",
    justifyContent: "space-between",
  },
}))(
  ({
    value,
    onChange,
    classes,
    values,
    Control,
    FormGroupComponent,
    label,
    name,
    variant,
    disabled_fields,
    mms,
    contacts,
    addBackground,
    handleContactRefresh,
    addBroker,
    addStudent,
  }) => {
    const [isRefreshing, setIsRefreshing] = React.useState(false);

    const handleContactUpload = (name) => {
      window.open(`/${getPropertyId()}/blast/settings/${name.toLowerCase()}-upload`, "_blank");
    };

    const handle_change = (e, checked) => {
      let { value: target_value } = e.target;
      if (checked) {
        onChange({
          ...e,
          target: { name, value: value.filter((v) => v !== target_value) },
        });
      } else {
        onChange({ ...e, target: { name, value: [...value, e.target.value] } }, target_value);
      }
    };
    const getContacts = async (name, e) => {
      e.preventDefault();
      setIsRefreshing(name);
      await handleContactRefresh(name);
      setIsRefreshing(false);
    };

    return (
      <FormControl component="fieldset">
        <FormLabel
          component="legend"
          classes={{
            root: classes.label,
            focused: classes.labe_focused,
          }}
        >
          {label}
        </FormLabel>
        <FormGroupComponent
          aria-label={label}
          name={name}
          value={value}
          onChange={variant === "selector-radio" ? onChange : undefined}
        >
          {values.map((name, index) => {
            const checked = variant === "selector-checkbox" ? value.indexOf(name) > -1 : undefined;

            return (
              <Fragment key={`name-${index}`}>
                <FormControlLabel
                  className={classes[variant]}
                  value={name}
                  control={
                    <Control
                      checked={checked}
                      onChange={
                        variant === "selector-checkbox"
                          ? (e) => handle_change(e, checked)
                          : undefined
                      }
                      className={classes.control}
                      icon_styles={{ fontSize: 24 }}
                    />
                  }
                  label={
                    <div>
                      {contacts &&
                      contacts.find(
                        (c, i) => c.label === name && (c.showPreCount || c.show === true)
                      ) ? (
                        <div className={classes.flexBoxLabel}>
                          {name}{" "}
                          {!addBackground ? (
                            <div>:&nbsp;{contacts.find((c, i) => c.label === name).count}</div>
                          ) : (
                            <div className={classes.backgroundStyle}>
                              {" "}
                              {contacts.find((c, i) => c.label === name).count}
                            </div>
                          )}{" "}
                          <Tooltip position="bottom" text={`Click to refresh ${name} list`}>
                            <div
                              onClick={getContacts.bind(this, name)}
                              className={`${classes.refreshIcon} ${
                                isRefreshing === name && "rotate"
                              }`}
                            >
                              <RefreshIcon />
                            </div>
                          </Tooltip>
                        </div>
                      ) : (
                        name
                      )}
                    </div>
                  }
                  disabled={new RegExp(`(^${name}|,${name})+(,(?!$)|$)+`, "gi").test(
                    disabled_fields
                  )}
                />
                <div
                  className={
                    contacts && contacts.find((c, i) => c.label === name && c.show === true)
                      ? `${classes.showContacts}`
                      : `${classes.hideContacts}`
                  }
                >
                  <div className={classes.contacts}>
                    <Typography type="body1" color="black">
                      - Blast reachable
                    </Typography>
                    {addBackground ? (
                      <div className={classes.backgroundStyle}>
                        {contacts && contacts.find((c, i) => c.label === name).eBlastReachableCount}
                      </div>
                    ) : (
                      <Typography type="body1" color="deepGrey">
                        :&nbsp;
                        {contacts && contacts.find((c, i) => c.label === name).eBlastReachableCount}
                      </Typography>
                    )}
                  </div>

                  <div className={mms ? `${classes.showContacts}` : `${classes.hideContacts}`}>
                    <div className={classes.contacts}>
                      <Typography type="body1" color="black">
                        - SMS Reachable
                      </Typography>
                      {addBackground ? (
                        <div className={classes.backgroundStyle}>
                          {contacts && contacts.find((c, i) => c.label === name).mmsReachableCount}
                        </div>
                      ) : (
                        <Typography type="body1" color="deepGrey">
                          :&nbsp;
                          {contacts && contacts.find((c, i) => c.label === name).mmsReachableCount}
                        </Typography>
                      )}
                    </div>
                  </div>
                  {(name === "Student" || name === "Broker") && (
                    <div>
                      <Button
                        variant="secondary"
                        size="lg"
                        state={
                          (name === "Broker" && addBroker) || (name === "Student" && addStudent)
                            ? "active"
                            : "inactive"
                        }
                        onClick={handleContactUpload.bind(this, name)}
                        className={classes.brokerButton}
                      >
                        {"Import" + " " + name + " " + "List"}{" "}
                        <Icon size="sm" type="icon-frame-s-top-right" />
                      </Button>
                    </div>
                  )}
                </div>
              </Fragment>
            );
          })}
        </FormGroupComponent>
      </FormControl>
    );
  }
);

SelectorsGroup.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  onChange: PropTypes.func,
  values: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  Control: PropTypes.oneOf([HylyRadio, HylyCheckbox]),
  FormGroupComponent: PropTypes.oneOf([RadioGroup, FormGroup]),
  disabled_fields: PropTypes.string,
};

SelectorsGroup.defaultProps = {
  variant: "checkbox",
  value: [],
  Control: HylyRadio,
  FormGroupComponent: RadioGroup,
  disabled_fields: "",
};

export default React.memo((props) => {
  switch (props.variant) {
    case "selector-radio":
      return <SelectorsGroup Control={HylyRadio} FormGroupComponent={RadioGroup} {...props} />;
    case "selector-checkbox":
    default:
      return <SelectorsGroup Control={HylyCheckbox} FormGroupComponent={FormGroup} {...props} />;
  }
});
