import React from "react";
import PropTypes from "prop-types";
import Datetime from "./react-datetime";
import "./index.css";
import moment from "moment";
import makeStyles from "@mui/styles/makeStyles";
import "moment-timezone";
import SearchIcon from "storybookComponents/SearchBox/Icons/SearchIcon";
import { CloseIcon } from "icons/index";
import { Box } from "@mui/material";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    height: 32,
    position: "relative",
    backgroundColor: "#fff",
    display: "flex",
    "&:hover": {
      border: "1px solid var(--hylyTheme)",
    },
    border: ({ focusInput }) => {
      return focusInput ? "1px solid #999999" : "1px solid #dadada";
    },
  },
  searchIcon: {
    paddingRight: 8,
    height: "100%",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#fff",
  },
  closeIcon: {
    paddingRight: 8,
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
    background: "#fff",
  },
  inputInput: {
    fontFamily: "Open Sans",
    fontSize: 12,
    paddingLeft: 8,
    transition: theme.transitions.create("width"),
    width: "100%",
    border: "none",
  },
  icon: {
    width: 10,
    height: 10,
  },
}));
var yesterday = moment().subtract(1, "day");
var valid = function (current) {
  return current.isAfter(yesterday);
};

const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const SearchByDate = React.memo((props) => {
  const [value, setValue] = React.useState(props.defaultValue);
  React.useEffect(() => {
    setValue(props.defaultValue);
  }, [props.defaultValue]);

  const classes = useStyles();

  const { type, icon, format, ...rest } = props;

  const handleChange = (e) => {
    setValue(e);
    props.handleChange(e);
  };

  const handleFocus = (e) => {
    if (typeof rest.onFocus === "function") {
      rest.onFocus(e);
    }
  };

  const customValidation = (current, _isSameOrAfter) => {
    var minValidDate = moment(props.minValidDate);
    if (_isSameOrAfter) {
      return current.isSameOrAfter(minValidDate);
    }
    return current.isAfter(moment(minValidDate).subtract(1, "day"));
  };

  return (
    <div
      style={
        props.gptPrompt
          ? {}
          : {
              position: props.relativePos ? "relative" : "absolute",
            }
      }
    >
      <Datetime
        showButton={props.showButton}
        dateFormat={type === "date" || type === "datetime"}
        timeFormat={type === "time" || type === "datetime"}
        onChange={handleChange}
        value={value}
        isValidDate={
          props.allowPast
            ? () => {
                return true;
              }
            : props.minValidDate
            ? customValidation
            : valid
        }
        scale={props.scale ? props.scale : 1}
        displayTimeZone={props.timezoneString}
        closeOnSelect={props.gptPrompt ? true : false}
        alignCalendarPopup={props.alignCalendarPopup}
        minValidDate={props.minValidDate}
        splittedView={props.splittedView}
        secondaryView={props.secondaryView}
        defaultValue={props.defaultValue}
        onClose={props.onClose}
        renderInput={(data) => {
          return (
            <Box className={clsx(classes.root, props.className)}>
              <input
                {...data}
                type="text"
                variant="top"
                no_label={false}
                required={false}
                multiline={false}
                readOnly
                {...rest}
                autoComplete="off"
                onFocus={handleFocus}
                className={classes.inputInput}
              />

              <SearchIcon viewBox="0 0 24 10" style={{ width: 24, height: 24, fill: "none" }} />
            </Box>
          );
        }}
      />
    </div>
  );
});

SearchByDate.propTypes = {
  type: PropTypes.oneOf(["date", "time", "datetime", "timeframe"]).isRequired,
  handleChange: PropTypes.func,
};

SearchByDate.defaultProps = {
  type: "date",
};

export default SearchByDate;
