import React from "react";

export default function RunTriangleIcon({ className = "text-[#26BBED]", width = 24, height = 24 }) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.04999 5.25L17.85 12L7.04999 18.75V5.25Z"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
