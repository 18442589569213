import React from "react";
import { TextField, InputAdornment } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Visibility, VisibilityOff } from "../icons";

const useStyles = makeStyles({
  mainRoot: {
    marginTop: 16,
  },
  underline: {
    "&:hover:not($disabled):not($focused):not($error):before": {
      borderBottom: "1px solid var(--hylyTheme)",
    },
    "&:before": {
      borderBottom: "1px solid #dadada",
    },
    "&:after": {
      borderBottom: "1px solid #999999 !important",
    },
  },
  notchedOutline: {
    borderColor: "#dadada",
    borderWidth: "1px !important",
  },
  inputStyle: {
    fontSize: "16px",
    lineHeight: "24px",
    borderRadius: "0px",
    "&:hover $notchedOutline": {
      borderColor: "var(--hylyTheme)",
    },
    "&$focused $notchedOutline": {
      borderColor: "#999999 !important",
    },
  },
  focused: {
    // borderColor: "red !important"
  },
  disabled: {
    "&:before": {
      borderBottom: "1px solid #dadada !important",
    },
  },
  marginRight: {
    marginRight: 12,
    cursor: "pointer",
  },
});

export default (props) => {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleIconClick = () => {
    setShowPassword(!showPassword);
  };
  const classes = useStyles();
  return (
    <div>
      <TextField
        className={classes.mainRoot}
        {...props}
        InputProps={{
          ...props.InputProps,
          endAdornment:
            props.type === "password" && props.value ? (
              <InputAdornment position="end">
                <div className={classes.marginRight} onClick={handleIconClick}>
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </div>
              </InputAdornment>
            ) : null,
          classes: {
            notchedOutline: classes.notchedOutline,
            root: classes.inputStyle,
            focused: classes.focused,
            underline: classes.underline,
            disabled: classes.disabled,
          },
        }}
        type={showPassword ? "text" : props.type}
      />
    </div>
  );
};
