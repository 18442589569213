/**
 * @flow
 * @relayHash c1d8071688bba88ee695e73eece752f8
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getReportStatQueryVariables = {|
  dashboardId: string,
  params?: ?any,
|};
export type getReportStatQueryResponse = {|
  +tenantOrCampaignStat: {|
    +campaignStat: ?any,
    +tenantStat: ?any,
    +angelStat: ?any,
    +data: ?$ReadOnlyArray<{|
      +annotation: ?string,
      +audienceCounts: ?string,
      +blacklistedCount: ?string,
      +botId: ?string,
      +bouncesCount: ?string,
      +clicksCount: ?string,
      +complaintsCount: ?string,
      +eblastCount: ?string,
      +id: string,
      +linkClicks: ?string,
      +previewUrl: ?string,
      +publishedAt: ?string,
      +sendsCount: ?string,
      +sentAt: ?string,
      +tenantId: ?string,
      +thumbnailUrl: ?string,
      +uniqueClicksCount: ?string,
      +uniqueOpensCount: ?string,
      +unsubscribesCount: ?string,
      +audiences: ?string,
    |}>,
  |}
|};
export type getReportStatQuery = {|
  variables: getReportStatQueryVariables,
  response: getReportStatQueryResponse,
|};
*/


/*
query getReportStatQuery(
  $dashboardId: ID!
  $params: Json
) {
  tenantOrCampaignStat(dashboardId: $dashboardId, params: $params) {
    campaignStat
    tenantStat
    angelStat
    data {
      annotation
      audienceCounts
      blacklistedCount
      botId
      bouncesCount
      clicksCount
      complaintsCount
      eblastCount
      id
      linkClicks
      previewUrl
      publishedAt
      sendsCount
      sentAt
      tenantId
      thumbnailUrl
      uniqueClicksCount
      uniqueOpensCount
      unsubscribesCount
      audiences
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "dashboardId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "params",
    "type": "Json",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "dashboardId",
    "variableName": "dashboardId"
  },
  {
    "kind": "Variable",
    "name": "params",
    "variableName": "params"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "campaignStat",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "tenantStat",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "angelStat",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "data",
  "storageKey": null,
  "args": null,
  "concreteType": "CampaignDataStats",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "annotation",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "audienceCounts",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "blacklistedCount",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "botId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "bouncesCount",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "clicksCount",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "complaintsCount",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "eblastCount",
      "args": null,
      "storageKey": null
    },
    (v5/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "linkClicks",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "previewUrl",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "publishedAt",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "sendsCount",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "sentAt",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "tenantId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "thumbnailUrl",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "uniqueClicksCount",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "uniqueOpensCount",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "unsubscribesCount",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "audiences",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getReportStatQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "tenantOrCampaignStat",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "TenantOrCampaignStat",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v6/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "getReportStatQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "tenantOrCampaignStat",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "TenantOrCampaignStat",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v6/*: any*/),
          (v5/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "getReportStatQuery",
    "id": null,
    "text": "query getReportStatQuery(\n  $dashboardId: ID!\n  $params: Json\n) {\n  tenantOrCampaignStat(dashboardId: $dashboardId, params: $params) {\n    campaignStat\n    tenantStat\n    angelStat\n    data {\n      annotation\n      audienceCounts\n      blacklistedCount\n      botId\n      bouncesCount\n      clicksCount\n      complaintsCount\n      eblastCount\n      id\n      linkClicks\n      previewUrl\n      publishedAt\n      sendsCount\n      sentAt\n      tenantId\n      thumbnailUrl\n      uniqueClicksCount\n      uniqueOpensCount\n      unsubscribesCount\n      audiences\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '154d8eb75871a18aa9774ee925724866';
module.exports = node;
