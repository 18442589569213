/**
 * @flow
 * @relayHash 193e86a94312abe41cd765e909923a31
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getCampaignMetricsQueryVariables = {|
  params?: ?any
|};
export type getCampaignMetricsQueryResponse = {|
  +campaignMetrics: {|
    +breakdown: ?string,
    +id: string,
    +total: ?string,
  |}
|};
export type getCampaignMetricsQuery = {|
  variables: getCampaignMetricsQueryVariables,
  response: getCampaignMetricsQueryResponse,
|};
*/


/*
query getCampaignMetricsQuery(
  $params: Json
) {
  campaignMetrics(params: $params) {
    breakdown
    id
    total
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "params",
    "type": "Json",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "campaignMetrics",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "params",
        "variableName": "params"
      }
    ],
    "concreteType": "CampaignMetrics",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "breakdown",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "total",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getCampaignMetricsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "getCampaignMetricsQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "getCampaignMetricsQuery",
    "id": null,
    "text": "query getCampaignMetricsQuery(\n  $params: Json\n) {\n  campaignMetrics(params: $params) {\n    breakdown\n    id\n    total\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6dd5795103b25b2c81d475b946077232';
module.exports = node;
