import React from "react";

export default function CallMadeIcon({ className = "text-[#999999]", width = 18, height = 18 }) {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.88365 10.896L9.0894 11.8897C7.85883 11.1661 6.83324 10.1405 6.10965 8.90995L7.1034 8.1157C7.21983 8.02249 7.30211 7.89334 7.33739 7.74843C7.37267 7.60351 7.35896 7.451 7.2984 7.3147L6.39315 5.2762C6.32826 5.12997 6.21353 5.01154 6.06944 4.94203C5.92534 4.87253 5.76124 4.85646 5.6064 4.8967L3.8814 5.3467C3.7201 5.38792 3.57973 5.4873 3.48725 5.62574C3.39477 5.76418 3.35672 5.93191 3.3804 6.0967C3.68335 8.25011 4.67914 10.2463 6.21723 11.7836C7.75532 13.3209 9.75208 14.3156 11.9057 14.6175C12.0704 14.6411 12.2382 14.6031 12.3766 14.5106C12.515 14.4181 12.6144 14.2778 12.6557 14.1165L13.1057 12.3915C13.1457 12.2367 13.1295 12.0728 13.06 11.9289C12.9905 11.7849 12.8722 11.6703 12.7262 11.6055L10.6847 10.7017C10.5484 10.6411 10.396 10.6272 10.2511 10.6624C10.1062 10.6975 9.97696 10.7796 9.88365 10.896V10.896Z"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <g clip-path="url(#clip0_1703_34981)">
        <path
          d="M12.9375 5.0625L9.5625 8.4375"
          stroke="currentColor"
          stroke-width="0.75"
          stroke-linecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.6875 5.0625H12.9375V7.3125"
          stroke="currentColor"
          stroke-width="0.75"
          stroke-linecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1703_34981">
          <rect width="4.5" height="4.5" fill="white" transform="translate(9 4.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}
