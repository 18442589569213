import graphql from "babel-plugin-relay/macro";

export default graphql`
  query getPlatformsReportsQuery($dashboardId: ID!, $params: Json) {
    platformStatsDashboard(dashboardId: $dashboardId, params: $params) {
      browserSeries
      deviceSeries
      id
      osSeries
      total
    }
  }
`;
