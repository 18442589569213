import React from "react";
import { SvgIcon } from "@mui/material";

export default function RedoIcon({ size = 16, className = "text-[#333333]" }) {
  return (
    <svg
      className={className}
      width={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1_7807)">
        <path
          d="M0.666504 6.00004H10.6665C11.9042 6.00004 13.0912 6.49171 13.9663 7.36688C14.8415 8.24205 15.3332 9.42903 15.3332 10.6667C15.3332 11.9044 14.8415 13.0914 13.9663 13.9665C13.0912 14.8417 11.9042 15.3334 10.6665 15.3334H5.99984"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.33317 10.6667L0.666504 6.00008L5.33317 1.33342"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_7807">
          <rect width="16" height="16" fill="white" transform="matrix(1 0 0 -1 0 16)" />
        </clipPath>
      </defs>
    </svg>
  );
}
