import graphql from "babel-plugin-relay/macro";

export default graphql`
  query getMailGptPromptsListQuery($tenantId: String!, $promptType: String) {
    listMailGptPrompts(tenantId: $tenantId, promptType: $promptType) {
      id
      promptId
      promptType
      promptTexts
      promptTitle
      numberOfPasses
      promptDescription
      uiFields
      useCases
      template {
        id
      }
    }
  }
`;
