/**
 * @flow
 * @relayHash 4609bf4198961bb90d2db0fdef0e7489
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type orgGptBlastAnalysisQueryVariables = {|
  endAt?: ?string,
  startAt?: ?string,
  orgId: string,
  propertyIds?: ?$ReadOnlyArray<string>,
|};
export type orgGptBlastAnalysisQueryResponse = {|
  +orgGptBlastAnalysis: any
|};
export type orgGptBlastAnalysisQuery = {|
  variables: orgGptBlastAnalysisQueryVariables,
  response: orgGptBlastAnalysisQueryResponse,
|};
*/


/*
query orgGptBlastAnalysisQuery(
  $endAt: String
  $startAt: String
  $orgId: ID!
  $propertyIds: [String!]
) {
  orgGptBlastAnalysis(endAt: $endAt, startAt: $startAt, orgId: $orgId, propertyIds: $propertyIds)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "endAt",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "startAt",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "orgId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "propertyIds",
    "type": "[String!]",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "orgGptBlastAnalysis",
    "args": [
      {
        "kind": "Variable",
        "name": "endAt",
        "variableName": "endAt"
      },
      {
        "kind": "Variable",
        "name": "orgId",
        "variableName": "orgId"
      },
      {
        "kind": "Variable",
        "name": "propertyIds",
        "variableName": "propertyIds"
      },
      {
        "kind": "Variable",
        "name": "startAt",
        "variableName": "startAt"
      }
    ],
    "storageKey": null
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "orgGptBlastAnalysisQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "orgGptBlastAnalysisQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "orgGptBlastAnalysisQuery",
    "id": null,
    "text": "query orgGptBlastAnalysisQuery(\n  $endAt: String\n  $startAt: String\n  $orgId: ID!\n  $propertyIds: [String!]\n) {\n  orgGptBlastAnalysis(endAt: $endAt, startAt: $startAt, orgId: $orgId, propertyIds: $propertyIds)\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8081c226e637c4ca49ecf50523f54725';
module.exports = node;
