import React from "react";
import ToolTip from "storybookComponents/Tooltip2";
import Icon from "storybookComponents/Icon";
import withStyles from "@mui/styles/withStyles";
import styles from "./style.js";
import { IconDownArrowSM, IconLeftArrowLG } from "icons";
import moment from "moment";
import Popover from "@mui/material/Popover";
const ViewNavigation = ({
  classes,
  onTodayClick,
  onClickMonth,
  monthContent,
  onClickYear,
  yearContent,
  onClickPrev,
  onClickSwitch,
  onClickNext,
  switchContent,
  switchColSpan,
  switchProps,
  updateDate,
  splittedView,
  selectedDate,
  isValidDate,
  viewDate,
}) => {
  const [openMonthList, setOpenMonthList] = React.useState(false);
  const [openYearList, setOpenYearList] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleMonthClick = (event) => {
    if (splittedView) {
      setAnchorEl(event.currentTarget);
      setOpenMonthList((openMonthList) => !openMonthList);
      setOpenYearList(false);
    }
    onClickMonth();
  };
  const handleSelect = (e) => {
    if (openMonthList) {
      setOpenMonthList((openMonthList) => !openMonthList);
    } else {
      setOpenYearList((openYearList) => !openYearList);
    }
    updateDate(e);
  };
  const handleYearClick = (event) => {
    setAnchorEl(event.currentTarget);
    if (splittedView) {
      setOpenYearList((openYearList) => !openYearList);
      setOpenMonthList(false);
    }
    onClickYear();
  };
  const renderMonths = (item, index) => {
    let className = "rdtMonth rdtList";
    let onClick;

    if (isDisabledMonth(index)) {
      className += " rdtDisabled";
    } else {
      onClick = handleSelect;
    }

    if (selectedDate && selectedDate.year() === viewDate.year() && selectedDate.month() === index) {
      className += " rdtActive";
    }

    if (index === moment().month()) {
      className += " rdtToday";
    }
    let props = { key: index, className, "data-value": index, onClick };
    const monthStr = viewDate?.localeData().months(viewDate.month(index));
    return <div {...props}>{monthStr}</div>;
  };

  const isDisabledMonth = (month) => {
    if (!isValidDate) {
      // If no validator is set, all days are valid
      return false;
    }

    // If one day in the month is valid, the year should be clickable
    let date = viewDate.clone().set({ month });
    let day = date.endOf("month").date() + 1;

    while (day-- > 1) {
      if (isValidDate(date.date(day))) {
        return false;
      }
    }
    return true;
  };

  const renderYears = (item, index) => {
    let year = moment().add(index, "years").year();
    return (
      <div className="rdtList" data-value={year} key={index} onClick={handleSelect}>
        {year}
      </div>
    );
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenYearList(false);
    setOpenMonthList(false);
  };
  return (
    <tr>
      <th colSpan={7}>
        <div className={classes.topContainer}>
          <div className={classes.leftContainer}>
            {monthContent ? (
              <div className="month-view">
                <div
                  aria-describedby="monthContent"
                  className={classes.monthView}
                  onClick={handleMonthClick}
                >
                  <span className={classes.leftMargin}>{monthContent}</span> <IconDownArrowSM />
                </div>
                <Popover
                  anchorEl={anchorEl}
                  id="monthContent"
                  open={openMonthList}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <div className="month-list">{new Array(12).fill(1).map(renderMonths)}</div>
                </Popover>
              </div>
            ) : null}
            {yearContent ? (
              <div id="yearContent">
                <div
                  aria-describedby="yearContent"
                  className={classes.yearView}
                  onClick={handleYearClick}
                >
                  <span className={classes.leftMargin}> {yearContent} </span>
                  <IconDownArrowSM />
                </div>
                <Popover
                  anchorEl={anchorEl}
                  id="yearContent"
                  open={openYearList}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <div className="month-list">{new Array(2).fill(1).map(renderYears)}</div>
                </Popover>
              </div>
            ) : null}
          </div>
          <div className={classes.rightContainer}>
            {yearContent ? (
              <div className="rdtPrev" onClick={onClickPrev}>
                <ToolTip
                  text={monthContent ? "Previous month" : "Previous decade"}
                  position="bottom"
                >
                  <div>
                    <IconLeftArrowLG />
                  </div>
                </ToolTip>
              </div>
            ) : null}
            <div className={`rdtPrev ${classes.alignMent}`} onClick={onTodayClick}>
              <ToolTip text="Today" position="bottom">
                <div className={classes.dimondIcon}></div>
              </ToolTip>
            </div>
            {yearContent ? (
              <div className="rdtNext" onClick={onClickNext}>
                <ToolTip text={monthContent ? "Next month" : "Next decade"} position="bottom">
                  <div className={classes.iconRotate}>
                    <IconLeftArrowLG />
                  </div>
                </ToolTip>
              </div>
            ) : null}
          </div>
        </div>
      </th>
    </tr>
  );
};

export default withStyles(styles)(ViewNavigation);
