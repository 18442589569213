import graphql from "babel-plugin-relay/macro";

export default graphql`
  query getDripEditorQuery($angelId: ID!, $id: ID!, $appName: String) {
    thenEmailEditor(angelId: $angelId, id: $id, appName: $appName) {
      angel {
        name
      }
      name
      subject
      active
      mms
      mmsBody
      mmsMediaUrl
      mmsStatus
      lastTestEmails
      lastTestPhones
      fields {
        editable
        label
        field
        dimension
        value
        type
        display
        sliceId
        sliceName
        elementId
        elementPart
      }
      rawMacrosHtml
      substitutedMacrosHtml
    }
  }
`;
