import React from "react";
import withStyles from "@mui/styles/withStyles";
import "./index.css";

const styles = () => ({
  icon: {
    cursor: "pointer",
  },
});

const Icon = ({ classes, onHoverColor, Component, onClick, rotate, className, id }) => {
  const [iconStroke, setIconStroke] = React.useState("#333");
  const [rotateIcon, setRotate] = React.useState(false);

  const handleClick = async (ev) => {
    try {
      if (rotate) {
        setRotate(true);
      }
      await onClick(ev);
      if (rotate) {
        setRotate(false);
      }
    } catch (err) {
      console.log("Err", err);
    }
  };

  const onMouseEnter = () => {
    setIconStroke(onHoverColor);
  };
  const onMouseLeave = () => {
    setIconStroke("#333");
  };
  return (
    <div
      id={id}
      className={`${classes.icon} ${rotateIcon && "rotate"} ${className}`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={handleClick}
    >
      <Component stroke={iconStroke} fill="none" />
    </div>
  );
};

export default withStyles(styles)(Icon);
