import React from "react";
import { Button } from "@mui/material";

const ButtonComp = ({
  label,
  icon,
  handleClick,
  className = "",
  isDisabled = false,
  rounded = false,
  selected = false,
  defaultButton = false,
  type = "save",
}) => {
  const classes =
    type === "save"
      ? "bg-hyly-blue py-[10px] px-6 text-white text-sm font-semibold capitalize disabled:bg-hyly-blue/50"
      : "bg-white normal-case py-[10px] px-6 text-text text-sm font-semibold disabled:text-text/50";
  let text = "Reset To Default";
  if (type === "save") text = "Save";
  if (type === "resetButton") text = "Reset";

  return (
    <Button
      className={
        defaultButton
          ? `${rounded ? "rounded-full" : "rounded-none"} ${
              selected ? "!border-black !text-black" : "border-text-3"
            } items-center border border-solid bg-white py-[10px] px-6 font-opensans normal-case ${className}`
          : `${classes} rounded-none ${className}`
      }
      onClick={() => handleClick()}
      disabled={isDisabled}
    >
      {icon && <div className="mr-2">{icon}</div>}
      {label ? label : text}
    </Button>
  );
};

export default ButtonComp;
