import React from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";

import Grid from "@mui/material/Grid";

import ListUnit from "./ListUnit";
import RelayListUnit from "./ListUnit.relay";
import { debounce } from "lodash";

const styles = (theme) => ({
  inner_container: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    height: "100%",
    width: "100%",
    overflowY: "scroll",
    background: theme.palette.white,
  },
});

export const ListComponentUnit_ = React.memo(
  ({ suggestions, ListItemComponent, selected_value, handle_select, icon_type, search_value }) => (
    <>
      {suggestions &&
        suggestions.map((item, index) => (
          <ListItemComponent
            key={item.id || `list-itme-${index}`}
            item={item}
            selected_value={selected_value}
            handle_select={handle_select}
            icon_type={icon_type}
            search_value={search_value}
          />
        ))}
    </>
  )
);

const MAX_SUGGESTIONS_AT_A_TIME = 10;

export const ListComponent = ({
  classes,
  icon_type,
  selected_value,
  search_value,
  suggestions,
  handle_select,
  ContainerComponent = Grid,
  InnerContainer = Grid,
  ListComponentUnit = ListComponentUnit_,
  fetch_type,
  minHeight,
  ...other
}) => {
  const [feedSuggestions, setFeedsuggestions] = React.useState(
    suggestions && suggestions.length && suggestions.slice(0, MAX_SUGGESTIONS_AT_A_TIME)
  );
  const [pos, setPos] = React.useState(0);
  const [done] = React.useState(new Map());

  const ListItemComponent = React.useMemo(() => {
    return fetch_type === "no-fetch" ? ListUnit : RelayListUnit;
  }, [fetch_type]);

  React.useEffect(() => {
    setFeedsuggestions(
      suggestions && suggestions.length && suggestions.slice(0, MAX_SUGGESTIONS_AT_A_TIME)
    );
    setPos(0);
    done.clear();
  }, [suggestions]);

  if (!suggestions || !suggestions.length) {
    return null;
  }

  const listComponentProps = {
    suggestions: feedSuggestions,
    ListItemComponent,
    selected_value,
    handle_select,
    icon_type,
    search_value,
  };

  function onScroll(e) {
    if (
      e.target.scrollHeight - e.target.scrollTop <= 300 &&
      !done.get(MAX_SUGGESTIONS_AT_A_TIME * (pos + 1))
    ) {
      setPos(pos + 1);
      const startIndex = MAX_SUGGESTIONS_AT_A_TIME * (pos + 1);
      setFeedsuggestions([
        ...feedSuggestions,
        ...suggestions.slice(startIndex, startIndex + MAX_SUGGESTIONS_AT_A_TIME),
      ]);
      done.set(startIndex, true);
    }
  }

  return (
    <ContainerComponent
      className={other.className || ""}
      onScroll={onScroll}
      style={{
        position: "relative",
        minHeight:
          minHeight || Math.min(((feedSuggestions && feedSuggestions.length) || 0) * 56, 300),
      }}
    >
      <InnerContainer className={classes.inner_container}>
        <ListComponentUnit {...listComponentProps} />
      </InnerContainer>
    </ContainerComponent>
  );
};

ListComponent.propTypes = {
  icon_type: PropTypes.string,
  selected_value: PropTypes.string,
  search_value: PropTypes.string,
  suggestions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  handle_select: PropTypes.func,
  ContainerComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  InnerContainer: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  fetch_type: PropTypes.oneOf(["no-fetch", "fetch", "re-fetch"]),
};

ListComponent.defaultProps = {
  ContainerComponent: Grid,
  InnerContainer: Grid,
};

export default React.memo(withStyles(styles)(ListComponent));
