import React from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
import Fab from "@mui/material/Fab";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Tooltip from "../Tooltip2";
import Icon from "../Icon";
import SaveButton from "./SAVE-xs";

export function create_classes_based_on_settings(name, settings) {
  const { active, hover, inactive } = settings[name];
  return {
    ...active,
    cursor: "pointer",
    "&:hover:not($disabled), &.active:hover:not($disabled)": hover,
    "&$disabled": inactive,
  };
}

export function create_override_classes_based_on_settings(name, settings) {
  const { active, hover, inactive } = settings[name];
  return {
    [`${name}-active`]: active,
    [`${name}-hover`]: hover,
    [`${name}-inactive`]: inactive,
  };
}

const palette = {
  white: "#fff",
  whiteGrey: "#F9F9F9",
  black: "#000",
  darkBlack: "#333",
  deepGrey: "#999",
  mediumGrey: "#CCC",
  lightGrey: "#EEE",
  hylyBlue: "var(--hylyTheme)",
  deepBlue: "#10A8DB",
  greenTeal: "#66D184",
  red: "#FF5151",
  errorRed: "#FF0000",
  orange: "#FF9900",
  divider: "#EEE",
  hoverColor: "#f5f5f5",
};

const colors_settings = () => ({
  primary: {
    active: {
      background: palette.hylyBlue,
      color: palette.white,
    },
    hover: {
      background: palette.deepBlue,
      color: palette.white,
    },
    inactive: {
      background: palette.hylyBlue,
      color: palette.white,
      opacity: 0.5,
    },
  },
  tertiary: {
    active: {
      background: "#ffffff00",
      color: palette.darkBlack,
    },
    hover: {
      color: palette.hylyBlue,
      background: "#ffffff00",
    },
    inactive: {
      background: "#ffffff00",
      color: "#333333",
      opacity: 0.5,
    },
  },
  "tertiary-negative": {
    active: {
      background: "#ffffff00",
      color: palette.darkBlack,
    },
    hover: {
      color: "#E75F5F",
      background: "#ffffff00",
    },
    inactive: {
      background: "#ffffff00",
      color: "#333333",
      opacity: 0.5,
    },
  },
  secondary: {
    active: {
      color: "#333333",
      borderColor: "#999999",
    },
    hover: {
      background: "#ffffff00",
      color: palette.hylyBlue,
      borderColor: palette.hylyBlue,
    },
    inactive: {
      color: "#333333",
      borderColor: "#999999",
      opacity: 0.5,
    },
  },
  "secondary-negative": {
    active: {
      color: "#333333",
      borderColor: "#999999",
      background: "#ffffff00",
    },
    hover: {
      background: "#ffffff00",
      color: "#E75F5F",
      borderColor: "#E75F5F",
    },
    inactive: {
      color: "#333333",
      borderColor: "#999999",
      opacity: 0.5,
    },
  },
  floating: {
    active: {
      background: palette.hylyBlue,
      color: palette.white,
    },
    hover: {
      background: palette.deepBlue,
      color: palette.white,
    },
    inactive: {
      background: palette.mediumGrey,
      color: palette.white,
    },
  },
  icon: {
    active: {
      background: "#ffffff00",
      color: palette.darkBlack,
    },
    hover: {
      background: palette.hoverColor,
      color: palette.black,
    },
    inactive: {
      background: "#ffffff00",
      color: palette.mediumGrey,
    },
  },
});

const styles = () => {
  const settings = colors_settings();
  return {
    primary: {
      ...create_classes_based_on_settings("primary", settings),
      fontWeight: 500,
      color: palette.white,
    },
    tertiary: {
      ...create_classes_based_on_settings("tertiary", settings),
      minWidth: 40,
      fontWeight: 400,
      fontSize: 14,
      maxWidth: 300,
      overflow: "hidden",
      textOverflow: "ellipsis",
      flexWrap: "nowrap",
    },
    "tertiary-negative": {
      ...create_classes_based_on_settings("tertiary-negative", settings),
      minWidth: 40,
      fontWeight: 400,
      fontSize: 14,
      maxWidth: 300,
      overflow: "hidden",
      textOverflow: "ellipsis",
      flexWrap: "nowrap",
    },
    secondary: {
      border: `1px solid`,
      ...create_classes_based_on_settings("secondary", settings),
      fontSize: 14,
      maxWidth: 300,
      overflow: "hidden",
      textOverflow: "ellipsis",
      flexWrap: "nowrap",
    },
    "secondary-negative": {
      border: `1px solid`,
      ...create_classes_based_on_settings("secondary-negative", settings),
      fontSize: 14,
      maxWidth: 300,
      overflow: "hidden",
      textOverflow: "ellipsis",
      flexWrap: "nowrap",
    },
    floating: {
      ...create_classes_based_on_settings("floating", settings),
      boxShadow: "none",
      color: palette.white,
      fontWeight: 500,
      fontSize: 16,
    },
    icon: create_classes_based_on_settings("icon", settings),
    ...create_override_classes_based_on_settings("floating", settings),
    ...create_override_classes_based_on_settings("icon", settings),

    sm: {
      fontWeight: 600,
      fontSize: 14,
      padding: "6px 12px",
      maxWidth: 300,
      overflow: "hidden",
      textOverflow: "ellipsis",
      flexWrap: "nowrap",
      height: 32,
      lineHeight: "14px",
    },
    md: {
      fontWeight: 600,
      fontSize: 14,
      height: 40,
      padding: "10px 32px",
    },
    lg: {
      fontWeight: 600,
      fontSize: 16,
      height: 48,
      padding: "12px 52px",
    },
    margin_right: {
      marginRight: 8,
    },
    disabled: {},
    label: {
      minWidth: 0,
      maxWidth: 250,
      // overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    common: {
      borderRadius: 0,
      boxShadow: "none !important",
      textTransform: "capitalize",
    },
  };
};

const IconButtonComponent = ({
  classes: { center, icon_class, icon_class_inactive },
  icon_path,
  disabled,
  className,
  icon_props,
  IconComponent,
  state,
  ...rest
}) => {
  return (
    <IconButton
      aria-label="Menu"
      classes={{ root: `${center} ${className}` }}
      disabled={disabled}
      {...rest}
      size="large"
    >
      <IconComponent
        type={icon_path}
        className={disabled ? icon_class_inactive : icon_class}
        state={state}
        {...icon_props}
      />
    </IconButton>
  );
};

const ButtonIconComponentWithTooltip = ({ children, show_tooltip, ...rest }) => {
  return (
    <Tooltip position={"bottom"} text={children} show_tooltip={show_tooltip}>
      <div>
        <IconButtonComponent {...rest} />
      </div>
    </Tooltip>
  );
};

const ButtonIconHover = React.memo((props) => (
  <ButtonIconComponentWithTooltip {...props} show_tooltip />
));
const ButtonIconDefault = React.memo((props) => <ButtonIconComponentWithTooltip {...props} />);
const floating_button_styles = () => ({});

const FloatingButtonWithTooltipComponent = React.memo(
  withStyles(floating_button_styles)(
    ({ show_tooltip, classname, disabled, icon_path, ...props }) => (
      <Tooltip
        position={"bottom"}
        text={props.children || ""}
        show_tooltip={show_tooltip}
        disabled={disabled}
      >
        <div>
          <Fab
            variant="circular"
            {...props}
            className={classname}
            size={"small"}
            disabled={disabled}
          >
            <Icon type={icon_path} size={"lg"} />
          </Fab>
        </div>
      </Tooltip>
    )
  )
);

const FloatingButtonWithTooltipHover = React.memo((props) => (
  <FloatingButtonWithTooltipComponent {...props} show_tooltip />
));
const FloatingButtonWithTooltip = React.memo((props) => (
  <FloatingButtonWithTooltipComponent {...props} />
));

const ButtonIcon = withStyles({
  center: {
    alignSelf: "center",
    minHeight: 0,
    cursor: "default",
    "&:hover": {
      background: "#ffffff00",
    },
  },
  icon_class: {
    alignSelf: "center",
  },
  icon_class_inactive: {
    alignSelf: "center",
    cursor: "default",
  },
})(({ children, IconComponent = Icon, disabled, state, ...rest }) => {
  return Boolean(children && state === "hover") ? (
    <ButtonIconHover children={children} IconComponent={IconComponent} state={state} {...rest} />
  ) : Boolean(children && !disabled) ? (
    <ButtonIconDefault children={children} IconComponent={IconComponent} state={state} {...rest} />
  ) : (
    <IconButtonComponent
      {...rest}
      state={state}
      disabled={disabled}
      IconComponent={IconComponent}
    />
  );
});

const ButtonComponent = ({
  classes,
  className,
  variant,
  size,
  color: button_color,
  icon_path,
  state,
  extended,
  IconComponent,
  negative,
  reference,
  ...props
}) => {
  const type_to_use = /secondary|tertiary/.test(variant)
    ? negative
      ? `${variant}-negative`
      : variant
    : variant;
  const classname_base = `${classes.common} ${classes[type_to_use] || ""} ${
    classes[`${type_to_use}-${state}`] || ""
  }`;
  const button_classname = `${classname_base} ${classes[size] || ""} ${className || ""}`;
  const classname = `${classname_base} ${className || ""}`;

  if (size === "xs" && variant === "primary") {
    const { children = "save", icon_path, ...rest } = props;

    return (
      <ButtonIcon
        className={classname}
        disabled={state === "inactive"}
        IconComponent={IconComponent || SaveButton}
        state={state}
        children={children}
        {...rest}
      />
    );
  }

  switch (variant) {
    case "floating":
      return extended ? (
        <Fab
          variant={"extended"}
          {...props}
          className={classname}
          classes={{ label: classes.label }}
          size={"medium"}
          disabled={state === "inactive"}
        >
          <Icon type={icon_path} size={"md"} span_class={classes.margin_right} />
          {props.children || ""}
        </Fab>
      ) : state === "hover" ? (
        <FloatingButtonWithTooltipHover
          icon_path={icon_path}
          disabled={state === "inactive"}
          classname={classname}
          {...props}
        />
      ) : (
        <FloatingButtonWithTooltip
          icon_path={icon_path}
          disabled={state === "inactive"}
          classname={classname}
          {...props}
        />
      );
    case "icon":
      return (
        <ButtonIcon
          disabled={state === "inactive"}
          className={classname}
          icon_path={icon_path}
          state={state}
          IconComponent={IconComponent}
          {...props}
        />
      );
    default:
      const children =
        (typeof props.children === "string" && props.children.length > 25
          ? props.children.slice(0, 25)
          : props.children) || "";
      return (
        <Button
          disabled={state === "inactive"}
          {...props}
          variant={
            variant === "secondary" ? "outlined" : variant === "tertiary" ? "text" : "contained"
          }
          className={button_classname}
          classes={{
            contained: classes.primary,
            outlined: classes.secondary,
            text: classes.tertiary,
            disabled: classes.disabled,
            label: classes.label,
          }}
          ref={reference}
        >
          {children}
        </Button>
      );
  }
};

function valid_button_children(props, propName, componentName) {
  if (!props[propName]) {
    return null;
  }
  if (typeof props[propName] !== "string" && !React.isValidElement(props[propName])) {
    return new Error(
      `Invalid prop '${propName}' supplied to '${componentName}'. Expected a react component or a string but got ${typeof props[
        propName
      ]}.`
    );
  } else if (typeof props[propName] === "string" && props[propName].length > 25) {
    return new Error(
      `${componentName} - prop '${propName}' should have a length of no more than 25 characters. Received a string with length of ${props[propName].length} chars.`
    );
  }
  return null;
}

ButtonComponent.propTypes = {
  variant: PropTypes.oneOf(["primary", "secondary", "tertiary", "floating", "icon"]),
  size: PropTypes.oneOf(["xs", "sm", "md", "lg"]),
  icon_path: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  state: PropTypes.oneOf(["active", "hover", "inactive"]),
  children: valid_button_children,
  negative: PropTypes.bool,
};

ButtonComponent.defaultProps = {
  variant: "primary",
  size: "sm",
};

export default withStyles(styles)(ButtonComponent);
