/**
 * @flow
 * @relayHash 98cd0bb90155380adb43aeb0db85fbae
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type uploadOfferCsvInput = {|
  clientMutationId?: ?string,
  orgId: string,
  name: string,
  csvData: string,
|};
export type uploadOfferCsvMutationVariables = {|
  input: uploadOfferCsvInput
|};
export type uploadOfferCsvMutationResponse = {|
  +uploadOfferCsv: ?{|
    +csvFile: ?{|
      +id: string,
      +columnNames: $ReadOnlyArray<string>,
      +rowsCount: number,
      +tempfile: string,
    |}
  |}
|};
export type uploadOfferCsvMutation = {|
  variables: uploadOfferCsvMutationVariables,
  response: uploadOfferCsvMutationResponse,
|};
*/


/*
mutation uploadOfferCsvMutation(
  $input: uploadOfferCsvInput!
) {
  uploadOfferCsv(input: $input) {
    csvFile {
      id
      columnNames
      rowsCount
      tempfile
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "uploadOfferCsvInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "uploadOfferCsv",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "uploadOfferCsvPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "csvFile",
        "storageKey": null,
        "args": null,
        "concreteType": "CsvFile",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "columnNames",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "rowsCount",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "tempfile",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "uploadOfferCsvMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "uploadOfferCsvMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "uploadOfferCsvMutation",
    "id": null,
    "text": "mutation uploadOfferCsvMutation(\n  $input: uploadOfferCsvInput!\n) {\n  uploadOfferCsv(input: $input) {\n    csvFile {\n      id\n      columnNames\n      rowsCount\n      tempfile\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '49dd8d5be420c79b3ac029197071eddb';
module.exports = node;
