import React from "react";

export default function TagIcon(props) {
  return (
    <svg
      className={props.className}
      width={props.width}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" fill="white" />
      <path
        d="M13.8298 17.3239L17.3244 13.8293C17.4217 13.732 17.4989 13.6165 17.5515 13.4894C17.6042 13.3623 17.6313 13.2261 17.6313 13.0885C17.6313 12.9509 17.6042 12.8147 17.5515 12.6876C17.4989 12.5605 17.4217 12.445 17.3244 12.3477L11.6075 6.63086H6.63135V11.6071L12.3482 17.3239C12.4455 17.4212 12.561 17.4984 12.6881 17.551C12.8152 17.6037 12.9514 17.6308 13.089 17.6308C13.2266 17.6308 13.3628 17.6037 13.4899 17.551C13.617 17.4984 13.7325 17.4212 13.8298 17.3239V17.3239Z"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.51197 10.5595C10.0906 10.5595 10.5596 10.0904 10.5596 9.51185C10.5596 8.93327 10.0906 8.46423 9.51197 8.46423C8.93339 8.46423 8.46436 8.93327 8.46436 9.51185C8.46436 10.0904 8.93339 10.5595 9.51197 10.5595Z"
        stroke="currentColor"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="currentColor" />
    </svg>
  );
}
