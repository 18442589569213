import React from "react";

function Preview({ className = "text-[#333]", width = 24, height = 24, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width
      height
      fill="none"
      viewBox="0 0 32 32"
      className
      {...props}
    >
      <rect width="32" height="32" fill="#26BBED" fillOpacity="0.1" rx="4"></rect>
      <path
        stroke="currentColor"
        strokeLinecap="square"
        strokeMiterlimit="10"
        d="M8.688 16.188s2.962-4.5 7.5-4.5c4.5 0 7.5 4.5 7.5 4.5s-3.038 4.5-7.5 4.5c-4.5 0-7.5-4.5-7.5-4.5z"
      ></path>
      <path
        stroke="currentColor"
        strokeLinecap="square"
        strokeMiterlimit="10"
        d="M16.188 17.688a3 3 0 100-6 3 3 0 000 6z"
      ></path>
    </svg>
  );
}

export default Preview;
