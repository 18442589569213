/**
 * @flow
 * @relayHash e622c1755aa3f08c590620bb9d8926f9
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getDripsListByEmailQueryVariables = {|
  dashboardId: string,
  selectedPropertyIds?: ?$ReadOnlyArray<string>,
  after?: ?string,
  before?: ?string,
  first?: ?number,
  last?: ?number,
|};
export type getDripsListByEmailQueryResponse = {|
  +dripList: {|
    +pageInfo: {|
      +endCursor: ?string,
      +startCursor: ?string,
      +hasPreviousPage: boolean,
      +hasNextPage: boolean,
    |},
    +edges: ?$ReadOnlyArray<?{|
      +cursor: string,
      +node: ?{|
        +id: string,
        +name: ?string,
        +active: ?boolean,
        +thenEmails: ?$ReadOnlyArray<{|
          +id: string,
          +name: string,
          +active: boolean,
          +triggerDays: number,
        |}>,
        +property: ?{|
          +id: string,
          +name: string,
        |},
      |},
    |}>,
  |}
|};
export type getDripsListByEmailQuery = {|
  variables: getDripsListByEmailQueryVariables,
  response: getDripsListByEmailQueryResponse,
|};
*/


/*
query getDripsListByEmailQuery(
  $dashboardId: ID!
  $selectedPropertyIds: [String!]
  $after: String
  $before: String
  $first: Int
  $last: Int
) {
  dripList(dashboardId: $dashboardId, selectedPropertyIds: $selectedPropertyIds, after: $after, before: $before, first: $first, last: $last) {
    pageInfo {
      endCursor
      startCursor
      hasPreviousPage
      hasNextPage
    }
    edges {
      cursor
      node {
        id
        name
        active
        thenEmails {
          id
          name
          active
          triggerDays
        }
        property {
          id
          name
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "dashboardId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "selectedPropertyIds",
    "type": "[String!]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "after",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "before",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "last",
    "type": "Int",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "active",
  "args": null,
  "storageKey": null
},
v4 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "dripList",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "after",
        "variableName": "after"
      },
      {
        "kind": "Variable",
        "name": "before",
        "variableName": "before"
      },
      {
        "kind": "Variable",
        "name": "dashboardId",
        "variableName": "dashboardId"
      },
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
      },
      {
        "kind": "Variable",
        "name": "last",
        "variableName": "last"
      },
      {
        "kind": "Variable",
        "name": "selectedPropertyIds",
        "variableName": "selectedPropertyIds"
      }
    ],
    "concreteType": "AngelConnection",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "pageInfo",
        "storageKey": null,
        "args": null,
        "concreteType": "PageInfo",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "endCursor",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "startCursor",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "hasPreviousPage",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "hasNextPage",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "edges",
        "storageKey": null,
        "args": null,
        "concreteType": "AngelEdge",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "cursor",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "Angel",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "thenEmails",
                "storageKey": null,
                "args": null,
                "concreteType": "ThenEmail",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "triggerDays",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "property",
                "storageKey": null,
                "args": null,
                "concreteType": "Suborg",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "getDripsListByEmailQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v4/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "getDripsListByEmailQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v4/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "getDripsListByEmailQuery",
    "id": null,
    "text": "query getDripsListByEmailQuery(\n  $dashboardId: ID!\n  $selectedPropertyIds: [String!]\n  $after: String\n  $before: String\n  $first: Int\n  $last: Int\n) {\n  dripList(dashboardId: $dashboardId, selectedPropertyIds: $selectedPropertyIds, after: $after, before: $before, first: $first, last: $last) {\n    pageInfo {\n      endCursor\n      startCursor\n      hasPreviousPage\n      hasNextPage\n    }\n    edges {\n      cursor\n      node {\n        id\n        name\n        active\n        thenEmails {\n          id\n          name\n          active\n          triggerDays\n        }\n        property {\n          id\n          name\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7ceb025c3c4a3c50ae04a9e793daa743';
module.exports = node;
