import React from "react";
import withStyles from "@mui/styles/withStyles";
import emailPlaceholder from "images/email_placeholder.png";
const styles = () => ({
  superContainer: {
    display: "flex",
    border: "1px solid #EBEBEB",
    justifyContent: "center",
    marginTop: 24,
  },
  leftPart: {
    background: "#333333",
    padding: 12,
    color: "#FFFFFF",
    fontFamily: "Open sans",
    width: 220,
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
  },
  imagesContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "6px",
    marginBottom: 8,
    flex: 1,
  },
  rightContainner: {
    width: "100%",
  },
  rightPart: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  title: {
    fontSize: 16,
    lineHeight: "24px",
    opacity: 0.8,
    color: "#000",
  },
  valueText: {
    fontSize: 14,
    lineHeight: "24px",
    fontWeight: 600,
  },
  cardContainerWithHover: {
    padding: "16px",
    color: "#333333",
    width: "100%",
    background: "white",
    cursor: "pointer",
    "&:hover": {
      color: "var(--hylyTheme)",
    },
  },
  cardContainer: {
    padding: "16px",
    color: "#333333",
    width: "100%",
    background: "white",
    cursor: "pointer",
  },
  cardValue: {
    fontSize: 32,
    lineHeight: "48px",
    fontWeight: 600,
  },
  cardBottom: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline",
    marginTop: 6,
  },
  border: {
    borderRight: "1px solid #EBEBEB",
  },
  horizontalLine: {
    width: "100%",
    height: 2,
    background: "#EBEBEB",
  },
  imageStyle: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  bottomChild: {
    flex: "1 1 0px",
  },
  bottomChildChart: {
    flex: "3 1 0px",
    background: "white",
  },
  imageContainerStyle: {
    flex: 1,
    height: "190px",
    overflow: "hidden",
  },
  imageContainerWithCountStyle: {
    flex: 1,
    height: "164px",
    overflow: "hidden",
  },
});

export const Card = withStyles(styles)(({ item, classes, border, ...props }) => {
  const handleClick = () => {
    if (typeof props.onClick == "function" && item.label === "#Sends") {
      props.onClick(item, "6");
    } else {
      props.onClick(item, "4");
    }
  };
  return (
    <div
      className={`${item.callback ? classes.cardContainer : classes.cardContainerWithHover} ${
        border && classes.border
      }`}
      onClick={item.callback ? null : handleClick}
    >
      <div className={classes.title}>{item.label}</div>
      <div className={classes.cardBottom}>
        <div className={classes.cardValue}>{item.percentage}</div>
        <div className={classes.valueText}>{item.value}</div>
      </div>
    </div>
  );
});

const OverViewBanner = ({ classes, show, data, children, ...props }) => {
  const errImageLoaded = (e) => (e.target.src = { emailPlaceholder });

  const renderData = (item, index) => (
    <Card item={item} key={index} {...props} border={index !== 3} />
  );

  const renderImages = (item) => (
    <div className={show ? classes.imageContainerWithCountStyle : classes.imageContainerStyle}>
      <img
        alt="thumbnail"
        src={item || emailPlaceholder}
        className={classes.imageStyle}
        onError={errImageLoaded}
      />
    </div>
  );

  if (!data) return null;

  const midPos = Math.ceil(data.summaryData.length / 2);

  return (
    <div className={classes.superContainer}>
      <div className={classes.leftPart}>
        <div className={classes.imagesContainer}>{data.thumbnailUrls.map(renderImages)}</div>
        {show && <div className={classes.valueText}>{data.count}</div>}
      </div>
      {children ? (
        <div className={classes.rightContainner}>
          <div className={classes.rightPart}>{data.summaryData.slice(0, 4).map(renderData)}</div>
          <div className={classes.horizontalLine}></div>
          <div className={classes.rightPart}>
            {data.summaryData.length > 4 && (
              <div className={classes.bottomChild}>
                <Card item={data.summaryData[4]} key="5" border={true} {...props} />
              </div>
            )}
            <div className={classes.bottomChildChart}>{children}</div>
          </div>
        </div>
      ) : (
        <div className={classes.rightContainner}>
          <div className={classes.rightPart}>
            {data.summaryData.slice(0, midPos).map(renderData)}
          </div>
          <div className={classes.horizontalLine}></div>
          <div className={classes.rightPart}>{data.summaryData.slice(midPos).map(renderData)}</div>
        </div>
      )}
    </div>
  );
};

export default withStyles(styles)(OverViewBanner);
